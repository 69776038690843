<template>
  <StatisticsBase :language="statisticsModule.course.teaching_language" :sub-title="`Модуль ${$route.query.number}`">
    <div class="course-statistics">
      <div class="statistics">
        <div class="title">Cтатистика за Модуль {{$route.query.number}}</div>
        <div class="statistics-info">
          <div class="item">
            <p>Пройдено уроков:</p>
            <span>{{statisticsModule.lessons_done}}</span>
          </div>
          <div class="item">
            <p>Средний бал за модуль:</p>
            <span>{{statisticsModule.abs_lessons_point}}</span>
          </div>
        </div>
      </div>
      <Progress
        :route-params="{name: 'StatisticsLesson', query:{}}"
        query-key="lesson"
        id-key="lesson_id"
        type-key="lesson_id"
        :finishModule="statisticsModule.lessons_done"
        :statisticsCount="statisticsModule.lessons_count"
        :progress-data="statisticsModule.lessons"
      />
    </div>
    <div class="completed">
      <div class="item">
        <div class="item-info">
          <div class="title">Пройдено уроков</div>
          <p><span>{{statisticsModule.lessons_done}}</span> из {{statisticsModule.lessons_count}}</p>
        </div>
        <router-link :to="{name: 'StatisticsLesson', query:{module_id:$route.params.id, module:$route.query.number}}">
          <img src="../../../../assets/images/icons/arrow-red.svg" alt="">
        </router-link>
      </div>
      <div class="item">
        <div class="item-info">
          <div class="title">Выполнено заданий</div>
          <p><span>{{statisticsModule.tasks_done}}</span> из {{statisticsModule.tasks_count}}</p>
        </div>
        <router-link :to="{name: 'StatisticsTask', query:{module_id:$route.params.id, module:$route.query.number}}">
          <img src="../../../../assets/images/icons/arrow-red.svg" alt="">
        </router-link>
      </div>
      <div class="item">
        <div class="item-info">
          <div class="title">Пройдено тестов</div>
          <p><span>{{statisticsModule.tests_done}}</span> из {{statisticsModule.tests_count}}</p>
        </div>
        <router-link :to="{name: 'StatisticsTest', query:{module_id:$route.params.id, module:$route.query.number}}">
          <img src="../../../../assets/images/icons/arrow-red.svg" alt="">
        </router-link>
      </div>
    </div>
  </StatisticsBase>
</template>
<script>
import Progress from './../../../components/Profile/Progress'
import { mapActions, mapGetters } from 'vuex'
import StatisticsBase from '../../../components/StatisticsBase'
export default {
  name: 'StatisticsModule',
  components: {
    StatisticsBase,
    Progress
  },
  data () {
    return {
      statisticsCount: 5,
      finishModule: 4.8
    }
  },
  created () {
    this.getStatisticsModule(this.$route.params.id)
  },
  computed: {
    ...mapGetters({
      'statisticsModule': 'statistics/statisticsModule'
    }),
  },
  methods: {
    ...mapActions({
      'getStatisticsModule': 'statistics/getStatisticsModule'
    }),

    back(){
      this.$router.push({name: 'Statistics', params: {id: this.statisticsModule.course.id}})
    }
  },
}
</script>
