<template>
  <button type="button" @click="openTask">
    <img src="~@/assets/images/profile/test.svg" alt="">
    <span>ПЕРЕЙТИ К ЗАДАНИЯМ</span>
  </button>
</template>

<script>
export default {
  name: 'GoToTasks',
  props: {
    lesson: {
      type: Object,
      require: true
    }
  },
  methods: {
    openTask () {
      const lesson = this.lesson
      let routeName,
        data = {}

      console.log('lesson', lesson)

      lesson.lesson_blocks.every(block => {
        if (block.show) {
          routeName = 'block'
          data = { block_id: block.id }
          return false
        }

        if (block.tasks.length) {
          routeName = 'task'
          data = block.tasks[0]
          return false
        }

        return true
      })

      console.log('data', data)

      if (routeName === 'block') {
        this.$router.push({
          name: 'block',
          params: { blockId: data.block_id },
          query: { is_free: 'true' }
        })
      } else if (routeName === 'task') {
        this.$router.push('/profile/task/' + data.task_type_id + '/' + data.id + '?lesson=' + lesson.id + '&course=' + lesson.course_module.course.id + '&module=' + lesson.course_module.id + '&is_free=true')
      } else {
        this.$router.push({ name: 'noteFound' })
      }
    }
  }
}
</script>

<style scoped>

</style>
