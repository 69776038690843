import { http } from '../../../api'

const courseModuleTest = {
  namespaced: true,
  state: {
    courseModuleTest: {},
    pagination: ''
  },
  actions: {
    getCourseModuleTest: ({ commit }, params) => {
      const q = new URLSearchParams(params)
      return http.get('/user-course-module-test?' + q.toString()).then(res => {
        commit('setCourseModuleTest', res.data)
        return res.data
      })
    }
  },
  mutations: {
    setCourseModuleTest (state, data) {
      state.courseModuleTest = data.data
      state.pagination = data.pagination
    }
  },
  getters: {
    courseModuleTest: state => state.courseModuleTest,
    pagination: state => state.pagination
  }
}

export default courseModuleTest
