<template>
  <b-modal hide-footer hide-header ref="modal" content-class="congratulation">
    <div class="close-modal" @click.prevent="closeCongratulationsModal">
      <img src="../../assets/images/icons/close.svg" alt="Close">
    </div>
    <div class="modal-block">
      <div class="title">Поздравляем с регистрацией</div>
      <div class="text">
        На ваш e-mail адрес было отправлено письмо для подтверждения вашего e-mail адреса.
        Подтвердить e-mail адрес можно в любой момент, сделав запрос в личном кабинете.
        Подтверждение e-mail адреса необходимо для возможности восстановить
        свой аккаунт в дальнейшем для необходимости.
      </div>
      <button type="button" @click="goToOffice">перейти в кабинет</button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props:{
    show: {
      type: Boolean,
      default: false
    }
  },
  name: 'CongratulationModal',
  methods:{
    closeCongratulationsModal () {
      this.$refs.modal.hide()
    },
    goToOffice () {
      this.$refs.modal.hide()
      this.$router.push('/profile/profile-details')
    },
  },
  watch:{
    show(newVal){
      if (newVal){
        this.$refs.modal.show()
      }else {
        this.$refs.modal.hide()
      }
    }
  }
}
</script>

<style scoped>

</style>
