<template>
  <div>
    <div class="personal-details books audio">
      <div class="additional">
        ДОПОЛНИТЕЛЬНЫЕ МАТЕРИАЛЫ
      </div>
      <div class="tabs-title">
        Аудио
      </div>
      <div class="tabs-info" v-if="!loading">
        {{allAudiosList.length ? 'Аудио для дополнительного обучения': 'Данный раздел доступен только после покупки курса'}}
      </div>
      <div class="action" v-if="!loading">
        <button @click="openModal" type="button" class="add-course">ВЫБРАТЬ КУРС</button>
      </div>
      <Loading v-if="loading" />
      <div v-else class="books-content">
        <img v-if="!allAudiosList.length" src="~@/assets/images/profile/no-audio.svg" alt="notes">
        <div v-else class="books-content-column row">
          <div class="col-md-6">
            <div class="books-content-item" v-for="(audio, index) in allAudiosList" :key="index" v-if="index % 2 === 0">
              <div class="books-item-title">{{audio.name[$i18n.locale]}}</div>
              <div class="item-content">
                <div class="description">
                  <div class="text">{{audio.description[$i18n.locale]}}</div>
                </div>
              </div>
              <mini-audio
                :audio-source="audio.audio"
              ></mini-audio>
            </div>
          </div>
          <div class="col-md-6">
            <div class="books-content-item" v-for="(audio, index) in allAudiosList" :key="index" v-if="index % 2 === 1">
              <div class="books-item-title">{{audio.name[$i18n.locale]}}</div>
              <div class="item-content">
                <div class="description">
                  <div class="text">{{audio.description[$i18n.locale]}}</div>
                </div>
              </div>
              <mini-audio
                :audio-source="audio.audio"
              ></mini-audio>
            </div>
          </div>

        </div>
      </div>
      <div class="mt-3" v-if="pagination.count_pages > 1">
        <b-pagination
          v-model="currentPage"
          :total-rows="pagination.count_pages"
          :per-page="1"
          first-number
          last-number
          prev-text="«"
          next-text="»"
          @input="clickPagination"
        ></b-pagination>
      </div>
    </div>
    <CoursesModal :show="showModal" v-model="activeCourseId" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Loading from '../../../components/Loading'
import CoursesModal from '../../../components/Materials/CoursesModal'
export default {
  name: 'Audio',
  components: { CoursesModal, Loading },
  data () {
    return {
      currentPage: 1,
      showModal: false,
      activeCourseId: null,
      loading: false
    }
  },
  computed: {
    ...mapState({
      allAudiosList: state => state.audio.allAudiosList,
      pagination: state => state.audio.pagination
    })
  },
  watch: {
    activeCourseId(){
      this.getAudios()
      this.showModal = false
    }
  },
  methods: {
    ...mapActions({
      getAllAudiosList: 'audio/getAllAudiosList'
    }),
    getAudios(){
      this.loading = true
      const params = {
        page: this.currentPage,
        course_id: this.activeCourseId
      }
      this.getAllAudiosList(params).then(() => {
        window.scrollTo(0, 0)
      }).finally(() => {
        this.loading = false
      })
    },
    openModal(){
      this.showModal = true
    },
    clickPagination () {
      this.getAudios()
    }
  }
}
</script>
<style scoped lang="scss">
.auth-user {
  .main-content {
    .books-content-column {
      @media all and (max-width: 576px) {
        margin: 0 !important;
      }

      .col-md-6{
        @media all and (max-width: 576px) {
          padding: 0 !important;
        }
      }

      .books-content-item {
        min-width: calc(100% - 20px) !important;
        max-width: calc(100% - 20px) !important;

        @media all and (max-width: 576px) {
          max-width: 100% !important;
        }
      }
    }
  }
}
</style>
