<template>
  <div class="how-work">
    <div class="how-work-container" :class="`${isAuthenticated ? 'pb-0' : ''}`">
      <div class="how-work-container-title">{{ $t('how_work.title') }}</div>
      <div class="how-work-container-subtitle">Начать очень просто:</div>
      <div class="how-work-container-group how-work__items row">
        <div class=" how-work-container-group-card">
          <div class="how-work-container-group-card-content d-block">
            <div class="how-work-container-group-card-content-image">
              <img src="../../assets/images/how-work/registration.svg" alt="registration" />
            </div>
            <div class="how-work-container-group-card-content-info-block">
              <div class="how-work-container-group-card-content-info-block-title">
                {{ $t('how_work.registration') }}
              </div>
              <div class="how-work-container-group-card-content-info-block-text">
                Выбирайте интересующий язык и регистрируйтесь
              </div>
            </div>
            <div class="how-work-container-group-card-content-number">1</div>
          </div>
        </div>
        <div class=" how-work-container-group-card">
          <div class="how-work-container-group-card-content d-block">
            <div class="how-work-container-group-card-content-image">
              <img
                src="../../assets/images/how-work/language-selection.svg"
                alt="language-selection"
              />
            </div>
            <div class="how-work-container-group-card-content-info-block">
              <div class="how-work-container-group-card-content-info-block-title">
                Определение уровня знаний
              </div>
              <div class="how-work-container-group-card-content-info-block-text">
                В личном кабинете пройдите короткий тест и получите рекомендацию курса для вашего
                уровня
              </div>
            </div>
            <div
              class="how-work-container-group-card-content-number line-to-bottom line-to-bottoma"
            >
              2
            </div>
          </div>
        </div>
        <div class=" how-work-container-group-card">
          <div class="how-work-container-group-card-content d-block">
            <div class="how-work-container-group-card-content-image">
              <img src="../../assets/images/how-work/training.svg" alt="training" />
            </div>
            <div class="how-work-container-group-card-content-info-block">
              <div class="how-work-container-group-card-content-info-block-title">
                Обучение
              </div>
              <div class="how-work-container-group-card-content-info-block-text">
                Всё! Приступайте к занятиям.
              </div>
            </div>
            <div class="how-work-container-group-card-content-number line-to-top line-to-bottoma">
              3
            </div>
          </div>
        </div>
      </div>
      <div class="how-work-container-text">
        Первый урок для любого уровня и языка абсолютно бесплатен!
      </div>
      <div class="button">
        <button type="button" class="btn-purple-blue-gradient-outline" @click="clickFirstStep">
          {{ $t('how_work.take_first_step') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { indexRoutes } from '../../router/routes'

export default {
  name: 'HowWork',
  computed: {
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated
    })
  },
  methods: {
    clickFirstStep() {
      this.$router.push(indexRoutes.courses_all)
      // this.$root.$emit("bv::show::modal", "login-modal");
    }
  }
}
</script>
