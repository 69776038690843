<template>
  <div class="page-container header-container" id="header">
    <b-navbar
      toggleable="xl"
      type="light"
      variant="faded"
      class="h-100  mt-0 justify-content-between"
    >
      <b-navbar-brand to="/">
        <img
          src="@/assets/images/logo.png"
          alt="logo"
          :class="{ 'website-logo': isShowSearchInput === true }"
        />
      </b-navbar-brand>
      <b-navbar-toggle ref="navbar" target="nav-collapse" aria-expanded="false">
        <template #default="{ expanded }">
          <div v-if="expanded">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
            >
              <path
                d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"
              ></path>
            </svg>
          </div>
          <div v-else>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="19" viewBox="0 0 30 19">
              <g>
                <g>
                  <g>
                    <path fill="#040404" d="M1.5 0h27a1.5 1.5 0 1 1 0 3h-27a1.5 1.5 0 1 1 0-3z" />
                  </g>
                  <g>
                    <path fill="#040404" d="M1.5 8h17a1.5 1.5 0 0 1 0 3h-17a1.5 1.5 0 1 1 0-3z" />
                  </g>
                  <g>
                    <path fill="#040404" d="M1.5 16h27a1.5 1.5 0 1 1 0 3h-27a1.5 1.5 0 1 1 0-3z" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </template>
        <!--  -->
      </b-navbar-toggle>
      <b-collapse v-model="openMenu" id="nav-collapse" is-nav class="justify-content-center">
        <b-navbar-nav>
          <b-nav-item to="/" class="home-navlink">Главная</b-nav-item>
          <b-nav-item to="/about">О нас</b-nav-item>
          <b-nav-item-dropdown
            text="Курсы"
            right
            class="courses-submenu"
            active-class="courses-submenu-active"
          >
            <b-dropdown-item
              :to="`/courses/${language.id}?page=1`"
              v-for="(language, index) in teachinglangs"
              :key="index"
              >{{ language.name[$i18n.locale] }}
            </b-dropdown-item>
            <b-dropdown-item to="/courses/all">Все курсы</b-dropdown-item>
          </b-nav-item-dropdown>
          <!-- <b-nav-item-dropdown text="Интересное" right class="courses-submenu" active-class="courses-submenu-active">
            <b-dropdown-item :to="'/interesting/' + category.slug" v-for="category in interestingCategories"
                             :key="category.slug">{{ category.title[$i18n.locale] }}
            </b-dropdown-item>
            <b-dropdown-item :to="'/interesting/all'">Все</b-dropdown-item>
          </b-nav-item-dropdown> -->
          <!-- <b-nav-item to="/news">Новости</b-nav-item> -->
          <b-nav-item to="/video">Видео</b-nav-item>
          <b-nav-item to="/reviews">Отзывы</b-nav-item>
          <b-nav-item to="/faq">FAQ</b-nav-item>
          <b-nav-item to="/contacts">Контакты</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <div class=" col-2 d-none d-md-block d-lg-none empty-block"></div>
      <div class="d-flex header-search-section">
        <div
          class="d-flex header-search-section-content"
          :class="{ 'search-opened': isShowSearchInput }"
          v-click-outside="onClickOutside"
        >
          <div class="position-relative">
            <input
              type="text"
              placeholder="Введите что хотите найти…"
              v-model.trim="searchValue"
              v-on:keyup.enter="search"
            />
            <button type="button" @click="isShowSearchInput ? search() : showSearchInput()">
              <img src="@/assets/images/icons/search.svg" alt="search" />
            </button>
            <div class="search-results shadow" v-if="Object.keys(results).length">
              <template v-for="(result, name) in results">
                <div class="search-content" v-if="result.items.length" :key="name">
                  <div class="search-header">
                    <span>{{ name }}</span>
                    <div @click="clearSearch">
                      <router-link :to="result.route" tag="a">Все</router-link>
                    </div>
                  </div>
                  <div class="search-body">
                    <router-link
                      v-for="item in result.items"
                      :to="item.route"
                      tag="a"
                      :key="item.id"
                    >
                      <div class="search-item" @click="clearSearch">
                        <div class="search-details">
                          <div class="search-image" v-if="item.image">
                            <img :src="item.image" alt="image" />
                          </div>
                          <div class="search-info">
                            <h4 class="item-title">{{ item.name }}</h4>
                            <div class="item-desc">
                              <span class="item-text">{{ item.description }}</span>
                              <div class="item-price" v-if="item.price">{{ item.price }} руб.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex header-registration-section" v-if="!isAuthenticated">
        <div class="header-registration-section-content">
          <button class="my-2 my-sm-0 btn-purple" type="button" @click="openAuthModal">
            регистрация
          </button>
        </div>
      </div> -->
    </b-navbar>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import vClickOutside from 'v-click-outside'
import IconBase from './IconBase'
import IconWrite from '../icons/IconWrite'
import IconFacebook from '../icons/IconFacebook'

export default {
  name: 'Header',
  components: {
    IconWrite,
    IconBase,
    IconFacebook
  },
  data() {
    return {
      isShowSearchInput: false,
      searchValue: '',
      openMenu: false
    }
  },
  computed: {
    ...mapState({
      teachinglangs: state => state.teachingLang.langs,
      interestingCategories: state => state.interesting.interestingCategories,
      isAuthenticated: state => state.auth.isAuthenticated,
      results: state => state.search.results
    })
  },
  created() {
    this.getTeachingLangs().then(res => {})
    this.getInterestingCategories().then(res => {})
  },
  watch: {
    searchValue(search) {
      if (!search.length) return

      this.ajaxSearch({ search })
    },
    openMenu(newVal) {
      if (!newVal) {
        document.body.style.overflowY = null
      } else {
        document.body.style.overflowY = 'hidden'
      }
    }
  },
  methods: {
    ...mapActions({
      getTeachingLangs: 'teachingLang/get',
      getInterestingCategories: 'interesting/getInterestingCategories',
      setLoginModal: 'auth/setLoginModal',
      ajaxSearch: 'search/search'
    }),
    ...mapMutations({
      setResults: 'search/setResults'
    }),
    clearSearch() {
      this.setResults({})
      this.searchValue = ''
    },
    showSearchInput() {
      this.isShowSearchInput = !this.isShowSearchInput
    },
    search() {
      if (!this.searchValue.length) {
        return false
      }
      this.$router.push('/search?value=' + this.searchValue)
    },
    onClickOutside() {
      this.isShowSearchInput = false
      this.clearSearch()
    },
    openAuthModal() {
      this.setLoginModal(true).then(() => {
        this.$root.$emit('bv::show::modal', 'login-modal')
      })
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  }
}
</script>
<style lang="scss">
.dropdown-menu {
  padding: 0.5rem 0 0 0;
}

.search {
  &-body {
    a,
    a:hover,
    a:visited {
      text-decoration: none;
    }
  }

  &-results {
    position: absolute;
    width: 100%;
    height: auto;
    background-color: white;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    max-height: calc(100vh - 231px);
    overflow-y: auto;
    top: 60px;
  }

  &-header {
    border-bottom: 1px solid #dce0e5;
    color: #6a7a8c;
    font-size: 16px;
    line-height: 160%;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
  }

  &-item {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    min-height: 36px;
    padding: 10px 16px;
    text-decoration: none;

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
      outline: none;
    }
  }

  &-details {
    align-items: stretch;
    display: flex;
    overflow: hidden;
    pointer-events: none;

    .item-title {
      color: #333;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
    }

    .item-desc {
      line-height: 1;
      margin-top: 4px;
      font-size: 15px;
      color: gray;
    }

    .item-price {
      color: #78258d;
      font-weight: bold;
      margin-top: 5px;
    }
  }

  &-image {
    flex-shrink: 0;
    height: 48px;
    margin-right: 14px;
    overflow: hidden;
    width: 32px;
  }

  &-image,
  &-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
