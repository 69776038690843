<template>
  <div class="form-group row">
    <label :for="id" :class="`col-sm-${labelCol}`" class="col-form-label">{{ label }}</label>
    <div :class="`col-sm-${inputCol}`" class="d-flex">

      <input ref="input" :type="type" class="form-control" :id="id" :value="value" :readonly="readonly"
             :required="required">

      <div class="col-1 d-flex align-items-center btn-copy">
        <i class="fas fa-copy cursor-pointer" @click="copy"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CopyInput',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    labelCol: {
      type: Number,
      default: 2
    },
    inputCol: {
      type: Number,
      default: 9
    }
  },
  data () {
    return {
      id: ''
    }
  },
  created () {
    this.id = this.makeid(15)
  },
  methods: {
    makeid (length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
          charactersLength))
      }
      return result
    },
    copy () {
      const input = this.$refs.input
      input.focus()
      input.select()
      document.execCommand('copy')
      this.$toasted.success('Успешно скопирован').goAway(1500)
    },
  }
}
</script>

<style scoped lang="scss">
.btn-copy {
  font-size: 28px;
}
</style>
