import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      courses: 'course/boughtCoursesList'
    }),
  },
  methods:{
    can(permission){
      return this.courses.find(({course_tariff}) => course_tariff[permission] === 1)
    }
  }
}
