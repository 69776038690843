<template>
  <div class="nav-link">
    <b-dropdown variant="link" toggle-class="text-decoration-none" ref="noteFormDropdown" no-caret>
      <template #button-content>
        <img :height="imgHeight" src="@/assets/images/profile/notes-lesson.png" alt="notes">
      </template>
      <div class="top-title">
        БЫСТРЫЕ ЗАМЕТКИ
      </div>
      <b-dropdown-form>
        <div class="support-content">
          <ValidationObserver action="" ref="noteAddPc"  id="note-add" tag="form"
                              @submit.prevent="sendNote()">
            <ValidationProvider name="Название заметки" rules="required|max:255" tag="div" v-slot="{ classes, errors }" class="form-group select">
              <label>Название заметки</label>
              <input type="text" class="form-control w-100" v-model="noteFormData.title" :class="classes"
                     placeholder="Название заметки"/>
              <div class="invalid-feedback">{{ errors[0] }}</div>
            </ValidationProvider>
            <ValidationProvider name="Заметка" rules="required" tag="div" v-slot="{ classes, errors }" class="form-group">
              <label>Заметка</label>
              <textarea placeholder="Ваша заметка" v-model="noteFormData.text" :class="classes"
                        class="form-control w-100"></textarea>
              <div class="invalid-feedback">{{ errors[0] }}</div>
            </ValidationProvider>
            <button class="support-send-btn" type="submit" :disabled="buttonDisable"
                    :class="buttonDisable ? 'btn-disabled' : ''">сохранить
            </button>
          </ValidationObserver>
        </div>
      </b-dropdown-form>
      <b-dropdown-item to="/profile/notes" active-class="active-more">
        <span>СМОТРЕТЬ ВСЕ ОБРАЩЕНИЯ</span>
        <img src="@/assets/images/gray-go-to.svg" alt="">
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Notes',
  props: {
    imgHeight: {
      type: String,
      default: '20px',
    },
    imgWidth: {
      type: String,
      default: '20px',
    }
  },
  data () {
    return {
      noteFormData: {},
      buttonDisable: false,
    }
  },
  methods: {
    ...mapActions({
      newNote: 'note/newNote',
    }),
    sendNote () {
      this.$refs.noteAddPc.validate().then((success) => {
        console.log('refs', this.$refs.noteAddPc)
        console.log('success', success)
        if (success) {
          this.buttonDisable = true
          this.newNote(this.noteFormData).then(() => {
            this.$toasted.success('Новая заметка успешно отправлена!').goAway(1500)
            this.noteFormData = {}
            this.$refs.noteAddPc.reset()
            this.$refs.noteFormDropdown.hide(true)
          }).catch(() => {
            this.$toasted.error('Что-то пошло не так!').goAway(1500)
          }).finally(() => {
            this.buttonDisable = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.dropdown-menu {
  max-width: 440px !important;
  left: unset !important;
  right: -30px !important;
  border: 0 !important;
  border-radius: 20px !important;
  padding: 0 !important;
  box-shadow: 0 0 30px rgba(0, 1, 1, 20%) !important;
  margin-top: 27px !important;

  @media (max-width: 767px) {
    transform: none !important;
  }

  li {
    max-height: 100% !important;

    .support-send-btn {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 2px solid #78258D;
      border-radius: 30px;
      font: bold 12px/24px "Open Sans", sans-serif;
      letter-spacing: 1px;
      color: #78258D;
      text-transform: uppercase;

      &:hover{
        background-image: linear-gradient(to right, #2e90d1 0%, #78258d 100%);
        border: unset;
        color: #ffffff;
      }
    }
    &:last-child{
      a{
        padding-top: 0;
        display: flex;
        align-items: center;
        font: bold 14px/24px "Open Sans", sans-serif;
        color: #9D9D9D;
        letter-spacing: 1px;
      }
    }
  }

  .top-title {
    background-color: #78258D;
    border-radius: 20px 20px 0 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: bold 14px/24px "Open Sans", sans-serif;
    color: #fff;
    letter-spacing: .5px;
    position: relative;
  }
}
</style>
