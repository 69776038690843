<template>
  <div class="reviews col-12 px-0">
    <bread-crumbs :pages="pages"/>
    <div class="reviews-block page-container">
      <div class="title">Отзывы</div>
      <div class="subtitle">За что пользователи любят Languages to Go</div>
      <div class="reviews">
        <div class="review" v-for="(review, index) in reviews" v-bind:key="index">
          <div class="review-top">
            <div class="name">
              <div>{{review.full_name}}</div>
            </div>
            <div class="stars">
              <template v-for="(number, i) in [1, 2 , 3 , 4 , 5]">
                <img v-if="number <= review.rating" v-bind:key="i" alt="review" src="../../../assets/images/icons/review.svg">
                <img v-else alt="review" v-bind:key="i" src="../../../assets/images/icons/empty-review.svg">
              </template>
            </div>
          </div>
          <div class="text">{{review.text}}</div>
          <div class="review-answer" v-if="review.answer">
            <div class="answer-name">{{review.admin.name}}</div>
            <div class="text">
              {{review.answer}}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3" v-if="pagination.count_pages > 1">
        <b-pagination
          v-model="currentPage"
          :total-rows="pagination.count_pages"
          :per-page="1"
          first-number
          last-number
          prev-text="«"
          next-text="»"
          @input="clickPagination"
        ></b-pagination>
      </div>
    </div>
    <div class="add-review" v-if="isAuthenticated">
      <div class="page-container">
        <div class="title">Оставить отзыв</div>
          <ValidationObserver  @submit.prevent="sendReview" tag="form" ref="reviewForm" class="row" method="POST">
          <div class="add-block col-12 col-sm-5 col-lg-4 col-xl-3">
            <div class="input-block">
              <div  class="review-block">
                <div class="rate">
                  <p>Поставьте оценку</p>
                  <div class="stars">
                    <vue-stars
                      name="demo"
                      v-model="userReview.rating"
                      :max="5"
                      class="stars-content">
                      <img slot="activeLabel" src="../../../assets/images/icons/white-star.svg" alt="review">
                      <img slot="inactiveLabel" src="../../../assets/images/icons/empty-white-star.svg" alt="review">
                    </vue-stars>
                  </div>
                </div>
                <div class="inputs">
                  <ValidationProvider tag="div" rules="required" class="form-group" v-slot="{ classes }">
                      <input type="text" placeholder="Ваше имя и чем занимаетесь" v-model="userReview.full_name" autocomplete="off" name="name" :class="classes">
                  </ValidationProvider>
                  <ValidationProvider tag="div" rules="required|email" class="form-group" v-slot="{ classes }">
                      <input type="email" placeholder="E-mail" v-model="userReview.email" autocomplete="off" name="email" :class="classes">
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-7 col-lg-8 col-xl-9">
              <ValidationProvider tag="div" rules="required" class="text-block" v-slot="{ classes }">
                <textarea name="review" id="review-textarea" v-model="userReview.text" placeholder="Ваш отзыв" :class="classes"></textarea>
              </ValidationProvider>
            <div class="text-block">
              <button  type="submit"  :disabled="disabledSubmitBtn" class="btn-white-outline">оставить отзыв</button>
            </div>
          </div>
          </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from '../../components/BreadCrumbs'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Reviews',
  components: {
    BreadCrumbs
  },
  data () {
    return {
      currentPage: 1,
      userReview: {
        full_name: '',
        rating: 0,
        email: '',
        text: ''
      },
      disabledSubmitBtn: false,
      pages: [
        { path: '/', rout: 'Главная', active: false },
        { path: '', rout: 'Отзывы', active: true }
      ]
    }
  },
  mounted () {
    this.getReviews({ page: this.currentPage, count: 10 })
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated,
      reviews: state => state.review.reviews,
      pagination: state => state.review.pagination
    })
  },
  metaInfo () {
    const title = this.$t('reviews.title')
    return {
      title: title,
      meta: [
        {
          name: 'og:title',
          content: title
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      addReview: 'review/addReview',
      getReviews: 'review/getReviews'
    }),
    sendReview () {
      this.$refs.reviewForm.validate().then(success => {
        if (success) {
          if (this.userReview.rating === 0) {
            this.$toasted.error('Заполните рейтинг, пожалуйста').goAway(2000)
            return false
          }
          this.disabledSubmitBtn = true
          this.addReview(this.userReview).then(res => {
            this.$toasted.success('Ваш отзыв успешно отправлен!').goAway(2000)
            this.disabledSubmitBtn = false
            this.userReview.full_name = ''
            this.userReview.email = ''
            this.userReview.rating = 0
            this.userReview.text = ''
            this.$nextTick(() => {
              this.$refs.reviewForm.reset()
            })
          }).catch(err => {
            if (err.response.status === 400) {
              const data = err.response.data
              const keys = Object.keys(data)
              const msg = data[keys[0]][0]
              this.$toasted.error(msg).goAway(2000)
            } else {
              this.$toasted.error('Ошибка сервера!').goAway(2000)
            }
          }).finally(() => {
            this.disabledSubmitBtn = false
          })
        }
      })
    },
    clickPagination () {
      this.getReviews({ page: this.currentPage, count: 10 }).then(() => {
        window.scrollTo(0, 0)
      })
    }
  }
}
</script>
