<template>
  <div class="bread-crumbs">
    <div class="page-container">
      <div class="page">
        <router-link v-for="(page,index) in pages" :to="page.path" :class="!page.active ? 'active-link' : ''" :key="index">
          {{page.rout}}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumbs',
  props: ['pages']
}
</script>
