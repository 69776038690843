import { http } from '../../../api'

const supportTicket = {
  namespaced: true,
  state: {
    supportTicketCategoryList: [],
    supportTicketList: [],
    supportListPagination: {},
    supportTicketItem: {}
  },
  actions: {
    getSupportList: ({ commit }, params) => {
      return http.get('support-ticket?page=' + params.page).then(res => {
        commit('setSupportTicketList', res.data)
      })
    },
    getSupportTicketCategoryList: ({ commit }) => {
      return http.get('support-ticket-category').then(res => {
        commit('setSupportTicketCategoryList', res.data)
      })
    },
    getSupportTicketItem: ({ commit }, params) => {
      return http.get('support-ticket/' + params.id).then(res => {
        commit('setSupportTicketItem', res.data)
      })
    },
    messagesSeen: ({ commit }, params) => {
      return http.get('support-ticket/mark-as-read/' + params.id).then(res => {
      })
    },
    newSupportTicket: ({ commit }, params) => {
      return http.post('support-ticket', params).then(res => {
        commit('setCreateSupportTicket', res.data)
        return res.data.data
      })
    },
    replySupportTicket: ({ commit }, params) => {
      return http.post('support-ticket/message/' + params.id, params.formData).then(res => {
      })
    }
  },
  mutations: {
    setSupportTicketCategoryList (state, data) {
      state.supportTicketCategoryList = data.data
    },
    setSupportTicketList (state, data) {
      state.supportTicketList = data.data
      state.supportListPagination = data.pagination
    },
    setSupportTicketItem (state, data) {
      state.supportTicketItem = data.data
    },
    setMessageList (state, data) {
      state.supportTicketItem.support_ticket_messages.push(data)
    },
    setCreateSupportTicket (state, data) {
      state.supportTicketList.unshift(data.data)
    }
  },
  getters: {
    supportTicketCategoryList: state => state.supportTicketCategoryList,
    supportTicketList: state => state.supportTicketList,
    supportTicketItem: state => state.supportTicketItem,
    supportListPagination: state => state.supportListPagination
  }
}

export default supportTicket
