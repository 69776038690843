<template>
  <div class="btn-border">
    <div class="btn-rotate-wheel">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FortuneBtn',
}
</script>

<style scoped lang="scss">
$border: 5px;
$size: 55px;

.btn-border{
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  background: linear-gradient(135deg, #F9DF7B 15.99%, #B57E10 30.36%, #B78114 34.67%, #BF8C1F 38.98%, #CB9E33 43.29%, #DDB64E 47.6%, #F3D671 51.19%, #F9DF7B 52.63%, #FFF3A6 53.35%, #F9DF7B 58.38%, #B57E10 76.34%, #E5C35C 84.24%, #F9DF7B 87.83%);

  @media (min-width: 768px) {
    width: $size + 5;
    height: $size + 5;
  }

  @media (min-width: 1440px) {
    width: $size + 5 - 10;
    height: $size + 5 - 10;
  }

  @media (min-width: 1920px) {
    width: $size + 5;
    height: $size + 5;
  }
}

.btn-rotate-wheel{
  width: 38px;
  height: 38px;
  background: radial-gradient(#333333, #000000);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: $border solid;
  cursor: pointer;

  @media (min-width: 768px) {
    width: $size;
    height: $size;
  }

  @media (min-width: 1440px) {
    width: $size - 10;
    height: $size - 10;
  }

  @media (min-width: 1920px) {
    width: $size;
    height: $size;
  }

  img{
    width: 24px;
    height: 24px;

    @media (min-width: 768px) {
      width: 34px;
      height: 34px;
    }

    @media (min-width: 1440px) {
      width: 26px;
      height: 26px;
    }

    @media (min-width: 1920px) {
      width: 34px;
      height: 34px;
    }
  }
}
</style>
