<template>
  <div class="task-column">
    <div class="d-flex align-items-center">
      <LessonTaskButton height="30px" width="30px" width-icon="10px" height-icon="14px"  :showed="true"/>
      <div class="task-name">{{ taskName }}</div>
    </div>
    <div class="task-name font-weight-normal">{{ typeTask }}</div>
    <div class="test-balls cursor-pointer" @click="open">{{ point }}</div>
  </div>
</template>

<script>
import LessonTaskButton from './LessonTaskButton'

export default {
  name: 'TaskColumn',
  components: { LessonTaskButton },
  props: {
    taskName: {
      type: String,
      default: ''
    },
    point: {
      type: String,
      default: 0
    },
    typeTask: {
      type: String,
      default: ''
    },
    action: {
      type: [String, Object],
      default: ''
    },
  },
  methods: {
    open(){
      this.$router.push(this.action)
    }
  }
}
</script>

<style scoped lang="scss">
.task-column {
  background-color: #ffffff;
  box-shadow: 0 0 50px 0 rgba(1, 1, 1, 0.05);
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;

  .task-name {
    font-size: 18px;
    margin-left: 10px;
    font-weight: 700;
  }

  .test-balls {
    padding: 5px 23px;
    height: 30px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E7EAFD;
    font-size: 14px;
    font-weight: 700;
  }
}
</style>
