<template>
  <b-modal hide-footer hide-header ref="anonsVideoModal" content-class="video-modal video-full">
    <div class="close-modal" @click="closeVideoModal">
      <img src="../../assets/images/icons/close.svg" alt="Close" />
    </div>
    <div class="modal-block p-1" v-if="courseAnnouncement">
      <div class="video">
        <div class="video" v-if="courseAnnouncement.video_iframe">
          <div
            v-if="courseAnnouncement && courseAnnouncement.video_iframe !== null"
            v-html="courseAnnouncement.video_iframe"
            class="video-iframe"
          ></div>
        </div>
        <div class="video" v-if="courseAnnouncement.video">
          <video width="100%" :src="courseAnnouncement.video" controls></video>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalAnons',
  props: {
    courseAnnouncement: {
      type: Object
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal) {
        this.openVideoModal()
      }
    }
  },
  methods: {
    openVideoModal() {
      this.$refs.anonsVideoModal.show()
    },
    closeVideoModal() {
      this.$emit('close')
      this.$refs.anonsVideoModal.hide()
    }
  }
}
</script>
