<template>
  <div class="align-word-wrapper">
    <div
      ref="content"
      :style="`min-height:${height}px`"
      :class="{
    'font-weight-bold':isMain,
    'empty-item': isAnswer && phrase.type === 'empty',
    'success': success,
    'invalid': invalid,
    'align-word-item': type === 'text',
    'align-image-item': type === 'image',
  }"
    >
      <div :class="{'text-image':phrase.type === 'text', 'text-empty': phrase.type === 'empty'}"
           v-if="phrase.type === 'text' || phrase.type === 'empty'">
        <div ref="text">
          <span ref="span">{{ phrase.value }}</span>
        </div>
      </div>

      <div v-else-if="types.includes(phrase.type)" class="item-image position-relative"
           :style="`background-image: url(${phrase.type === 'image' ? phrase.value : phrase.default})`">
        <img v-if="withPlayer.includes(phrase.type)" class="cursor-pointer play-icon"
             @click="$emit('playVideo', phrase)"
             src="@/assets/images/tasks/pick-task.png"
             alt="image">
        <Zoom v-if="phrase.type === 'image'" :image="phrase.value" name="zoom-white" class="zoom-image"/>
      </div>
      <div v-else class="audio-image position-relative">
        <img src="@/assets/images/tasks/Music.png" alt="music" class="music-image"/>
        <img @click="playAudio(phrase.value)" class="cursor-pointer play-icon" src="@/assets/images/tasks/pick-task.png"
             alt="image">
      </div>
    </div>
  </div>
</template>

<script>
import Zoom from '../Common/Zoom'

export default {
  name: 'AlignWordItem',
  components: { Zoom },
  props: {
    phrase: {
      type: Object,
      required: false
    },
    isMain: {
      type: Boolean,
      default: false
    },
    isAnswer: {
      type: Boolean,
      default: false
    },
    isTranslate: {
      type: Boolean,
      default: false
    },
    height: {
      type: [String, Number],
      default: ''
    },
    success: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    stopAudio: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      types: ['image', 'video', 'iframe'],
      withPlayer: ['video', 'iframe'],
      audioPlay: false
    }
  },
  watch: {
    stopAudio () {
      if (this.stopAudio) {
        this.audio.pause()
      }
    },
    phrase () {
      this.calcFontSize()
    }
  },
  mounted () {
    this.calcFontSize()
  },
  destroyed () {
    if (this.audio) {
      this.audio.pause()
    }
  },
  methods: {
    calcFontSize () {
      if (this.type === 'text') return

      this.$nextTick(() => {
        if (!this.$refs.text) return

        const span = this.$refs.text.children[0]

        let fontSize = window.getComputedStyle(span, null).getPropertyValue('font-size')
        fontSize = parseInt(fontSize)

        while (this.$refs.text.clientHeight < this.$refs.span.clientHeight) {
          fontSize--
          this.$refs.span.style.fontSize = `${fontSize}px`
        }
      })
    },
    playAudio (mp3) {
      if (!this.audio) {
        this.audio = new Audio(mp3)
        this.audio.onended = () => {
          this.audioPlay = !this.audioPlay
          this.$emit('update:stopAudio', this.audioPlay)
        }
      }
      if (this.audioPlay) {
        this.audio.pause()
      } else {
        this.audio.play()
      }
      this.audioPlay = !this.audioPlay
      this.$emit('update:stopAudio', this.audioPlay)
    }
  },
}
</script>

<style scoped lang="scss">

@import "../../../../scss/variables";

.align-word-item {
  width: 100%;
  min-height: 80px;
  background-color: #e6eafc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  padding: 20px;
  line-height: 24px;

  @media (min-width: $md) {
    width: 220px;
  }

  @media (min-width: $lg) {
    width: 300px;
  }

  &.invalid {
    background-color: #CD3B45;
    color: white;
    border: 1px solid transparent;
  }

  &.success {
    background-color: #219653;
    color: white !important;
    border: 1px solid transparent;
  }
}

.empty-item {
  background-color: transparent;
  border: 2px dashed #ced6f7;
}


.left-border-change {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

.right-border-radius-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.card__content {
  width: 100%;
  color: white;
  min-height: 50px;
  padding: 20px;
  position: absolute;
  display: flex;
  bottom: 0;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);
}

.file-container {
  min-height: 184px;
}

.align-word-wrapper {

  @media (min-width: $md) {
    padding-bottom: 1.5rem;
  }

}

.align-image-item {
  width: 280px;
  height: 168px;
  border-radius: 10px;

  @media (min-width: $md) {
    width: 220px;
    height: 131.8px;
  }

  @media (min-width: $lg) {
    width: 300px;
    height: 180px;
  }

  &.invalid {
    border: 4px solid #EB0C08;
  }

  &.success {
    border: 4px solid #219653;
  }

  .text-image {
    background-color: #E7EAFD;
    width: 100%;
    height: 100%;
    padding: 15px;
    border-radius: 10px;

    div {
      border: 3px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 10px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        word-break: break-all;
      }
    }
  }

  .item-image {
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .audio-image {
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #E7EAFD;

    .music-image {
      position: absolute;
      right: 2.18%;
      top: 2.78%;
      bottom: 21.16%;
      width: 95.36px;
      height: 91.27px;


      @media (min-width: $md) {
        width: 104.88px;
        height: 100.26px;
        top: 12.78%;
      }

      @media (min-width: $lg) {
        width: auto;
        height: 89%;
        right: 2.18%;
        top: 5.78%;
      }

    }
  }

  .zoom-image {
    position: absolute;
    bottom: 16px;
    right: 10px;

    @media (min-width: $md) {
      display: none;
    }

  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 26px;
    height: 31px;

    @media (min-width: $md) {
      width: 32px;
      height: 37px;
    }

    @media (min-width: $lg) {
      width: 50px;
      height: 55px;
    }
  }
}

</style>
