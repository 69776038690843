<template>
  <div :style="style" class="rounded-circle button-circle bg-white d-flex justify-content-center align-items-center">
    <slot>
      <img :src="`@/assets/images/icons/${iconName}`" alt="icon"/>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'CircleButton',
  props: {
    iconName: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '60px'
    },
    width: {
      type: String,
      default: '60px'
    },
  },
  data(){
    return {
      style:{
        height: this.height,
        width: this.width
      }
    }
  }
}
</script>

<style scoped lang="scss">
.button-circle{
  cursor: pointer;
}
</style>
