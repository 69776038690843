<template>
  <TaskBase :check-task="check" :task="task" :correct-answer="correctAnswer" :promptTooltip="promptTooltip">
    <template #desktop>
      <div class="row fl-mob-words" :class="{'fl-mob-fixed': wordsFixed, 'd-none':!wordsFixed}">
        <span class="lg-4">{{ coupleWords.length > 0 ? coupleWords[0].word : '' }}</span>
        <div class="lg-4 d-flex justify-content-center align-items-center">
          <img src="@/assets/images/tasks/card-word-check.png"/>
        </div>
        <span class="lg-4">{{ coupleWords.length > 1 ? coupleWords[1].word : '' }}</span>
      </div>
      <div class="row fl-mob-words" :class="{'invisible': wordsFixed}" ref="words">
        <span class="lg-4">{{ coupleWords.length > 0 ? coupleWords[0].word : '' }}</span>
        <div class="lg-4 d-flex justify-content-center align-items-center">
          <img src="@/assets/images/tasks/card-word-check.png"/>
        </div>
        <span class="lg-4">{{ coupleWords.length > 1 ? coupleWords[1].word : '' }}</span>
      </div>

      <div class="row">
        <template v-for="(word, index) in words">
          <transition name="flip">
            <div class="lg-lg-2 lg-md-4 mb-4 only-pc" :key="`${codeExists(word.code)}${index}`">
              <div class="flip-card"
                   @click="openCard(word)"
                   :class="codeExists(word.code) ? 'front' : 'back'">
                <div class="flip-card-inner" :class="{
                  'success-card': codeExists(word.code, 'success'),
                  'error-card': codeExists(word.code, 'error'),
                }">
                  {{ codeExists(word.code) ? word.word : '' }}
                </div>
              </div>
            </div>
          </transition>
          <div class="lg-4 fl-mob-cards" :key="`${index}`">
            <div class="fl-mob-card" @click="openCard(word)">
              <img :src="getIconStatus(word.code)" alt="card"/>
            </div>
          </div>
        </template>
      </div>
    </template>
  </TaskBase>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import TaskAnswerModal from '../../../../components/TaskAnswerModal'
import VideoAudioGallery from '../../../../components/VideoAudioGallery'
import TaskBase from '../../../../components/TaskBase'
import TaskCheckParamsMixin from '../../../../../mixins/TaskCheckParamsMixin'
import CheckWidthMixin from '../../../../../mixins/CheckWidthMixin'

export default {
  name: 'FindRemember',
  mixins: [TaskCheckParamsMixin, CheckWidthMixin],
  data () {
    return {
      data: null,
      words: [],
      openCardArr: [],
      wordCodeArr: [],
      rightOpenCard: [],
      coupleWords: [],
      disabledCard: false,
      wordsFixed: false
    }
  },
  computed: {
    ...mapState({
      task: state => state.task.task,
      nextTask: state => state.course.nextTask
    }),
    isFinish () {
      return this.rightOpenCard.filter(card => card.open === false).length === 0
    },
    promptTooltip () {
      return this.coupleWords.length > 0 ? '' : 'Нужно выбрать карточку чтобы воспользоваться подсказкой'
    }
  },
  components: {
    TaskBase,
    VideoAudioGallery,
    TaskAnswerModal
  },
  beforeRouteUpdate (to, from, next) {
    if (to.fullPath !== from.fullPath) {
      Object.assign(this.$data, this.$options.data())
      this.taskInit(to)
    }
    next()
  },
  watch: {
    data(){
      this.$nextTick(() => {
        this.visible()
        window.addEventListener('scroll', () => {
          this.visible()
        }, true)
      })
    }
  },
  created () {
    this.taskInit(this.$route)
  },
  methods: {
    ...mapActions({
      getTask: 'task/getTask',
      checkTestTask: 'task/checkTestTask',
      checkedFindRemember: 'task/checkedFindRemember',
      setTaskAnswerModal: 'task/setTaskAnswerModal'
    }),
    visible () {
      if (this.$refs.words.getBoundingClientRect().bottom - this.$refs.words.clientHeight > window.scrollY) {
        this.wordsFixed = false
      } else {
        this.wordsFixed = true
      }
    },
    getIconStatus (code) {
      let status = 'primary'
      if (this.codeExists(code, 'success')) {
        status = 'success'
      } else if (this.codeExists(code, 'error') && this.codeExists(code)) {
        status = 'error'
      }

      return require(`../../../../../assets/images/tasks/card-${status}.png`)
    },
    codeExists (code, key = 'open') {
      return this.rightOpenCard.find(word => word.code === code && word[key])
    },
    taskInit (route) {
      const query = {}
      if (route.query.type) {
        query.type = route.query.type
      }

      this.getTask({
        id: route.params.taskId,
        query
      }).then(res => {
        this.data = res.data
        this.words = this.task.task.words

        this.words.forEach(word => {
          this.rightOpenCard.push({
            code: word.code,
            success: false,
            error: false,
            open: !this.isMobile
          })
        })

        if (this.isMobile) return

        setTimeout(() => {
          this.rightOpenCard = this.rightOpenCard.map(card => {
            return {
              ...card,
              open: false
            }
          })
        }, 5000)
      })
    },
    correctAnswer () {
      if (this.coupleWords.length !== 1) return

      const word = this.coupleWords[0]
      const findWord = this.words.find(value => value.id === word.id && value.code !== word.code)
      this.checkCard(findWord)
    },
    check () {
      this.checkedFindRemember({
        card: this.coupleWords,
        uuid: this.task.uuid,
        remember_find_id: this.task.task.id,
        isFinish: this.isFinish,
        ...this.checkParams()
      }).then(res => {
        if (res.data.answer) {
          if (this.isFinish) {
            this.setTaskAnswerModal(res.data).then(() => {
              this.$root.$emit('bv::show::modal', 'modal-task')
            })
          }
        }

        this.coupleWords.forEach(word => {
          this.changeValue(word.code, 'success', res.data.answer)
          this.changeValue(word.code, 'error', !res.data.answer)
        })

        setTimeout(() => {
          this.coupleWords.forEach(word => {
            this.changeValue(word.code, 'open', res.data.answer)
            this.changeValue(word.code, 'error', false)
          })
          this.disabledCard = false
          this.coupleWords = []
        }, 1000)
      })
    },
    changeValue (code, key = 'open', value = true) {
      const index = this.rightOpenCard.findIndex(card => card.code === code)

      this.$set(this.rightOpenCard[index], key, value)
    },
    openCard (word) {
      if (this.codeExists(word.code) || this.disabledCard) return

      this.checkCard(word)
    },
    checkCard (word) {
      this.coupleWords.push(word)

      this.changeValue(word.code)

      if (this.coupleWords.length < 2) return

      this.disabledCard = true
      this.check()
    }
  },
}
</script>

<style scoped lang="scss">
.flip-card {
  cursor: pointer;
  height: 80px;
  width: 80px;
  border-radius: 16px;
  will-change: transform;
  background-color: white;
  font-size: 18px;
  padding: 10px;

  @media (min-width: 768px) {
    width: 225px;
    height: 128px;
    font-size: 24px;
  }

  @media (min-width: 1440px) {
    width: 166px;
    height: 94px;
    font-size: 20px;
  }

  @media (min-width: 1920px) {
    width: 225px;
    height: 128px;
    font-size: 24px;
  }

  .flip-card-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    word-break: break-word;
  }

  .success-card {
    border: 3px solid #00a651;
  }

  .error-card {
    border: 3px solid #CF454E;
  }
}

.fl-mob {
  &-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background-color: white;
    background-image: url("../../../../../assets/images/tasks/footer_card.png");
    background-repeat: no-repeat;
    background-position: bottom;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  &-cards {
    display: none;

    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
    }
  }

  &-words {
    min-height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: none;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
    overflow-wrap: anywhere;

    @media (max-width: 767px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 20px;
    }
  }

  &-fixed {
    position: fixed;
    width: 100%;
    top: 60px;
  }
}


.back {
  background-image: url("../../../../../assets/images/tasks/back-flip-card.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter, .flip-leave {
  transform: rotateY(180deg);
  opacity: 0;

}

</style>
