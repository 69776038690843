var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"align-word-wrapper"},[_c('div',{ref:"content",class:{
  'font-weight-bold':_vm.isMain,
  'empty-item': _vm.isAnswer && _vm.phrase.type === 'empty',
  'success': _vm.success,
  'invalid': _vm.invalid,
  'align-word-item': _vm.type === 'text',
  'align-image-item': _vm.type === 'image',
},style:(("min-height:" + _vm.height + "px"))},[(_vm.phrase.type === 'text' || _vm.phrase.type === 'empty')?_c('div',{class:{'text-image':_vm.phrase.type === 'text', 'text-empty': _vm.phrase.type === 'empty'}},[_c('div',{ref:"text"},[_c('span',{ref:"span"},[_vm._v(_vm._s(_vm.phrase.value))])])]):(_vm.types.includes(_vm.phrase.type))?_c('div',{staticClass:"item-image position-relative",style:(("background-image: url(" + (_vm.phrase.type === 'image' ? _vm.phrase.value : _vm.phrase.default) + ")"))},[(_vm.withPlayer.includes(_vm.phrase.type))?_c('img',{staticClass:"cursor-pointer play-icon",attrs:{"src":require("@/assets/images/tasks/pick-task.png"),"alt":"image"},on:{"click":function($event){return _vm.$emit('playVideo', _vm.phrase)}}}):_vm._e(),(_vm.phrase.type === 'image')?_c('Zoom',{staticClass:"zoom-image",attrs:{"image":_vm.phrase.value,"name":"zoom-white"}}):_vm._e()],1):_c('div',{staticClass:"audio-image position-relative"},[_c('img',{staticClass:"music-image",attrs:{"src":require("@/assets/images/tasks/Music.png"),"alt":"music"}}),_c('img',{staticClass:"cursor-pointer play-icon",attrs:{"src":require("@/assets/images/tasks/pick-task.png"),"alt":"image"},on:{"click":function($event){return _vm.playAudio(_vm.phrase.value)}}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }