import { http } from '../../../api'

const vocabulary = {
  namespaced: true,
  state: {
    lessonVocabularies: [],
  },
  actions: {
    getLessonVocabularies: ({ commit }, params) => {
      commit('loading/setLoading', true, {root: true})
      return http.get('/getLessonsVocabularies/', {params}).then(res => {
        commit('setLessonVocabularies', res.data.data)
        return res.data.data
      }).finally(() => {
        commit('loading/setLoading', false, {root: true})
      })
    },
  },
  mutations: {
    setLessonVocabularies (state, data) {
      state.lessonVocabularies = data
    },
  },
  getters: {
    lessonVocabularies: state => state.lessonVocabularies
  }
}

export default vocabulary
