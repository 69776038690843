<template>
  <ReplyTasksBase :language="language" :title="title">
    <img src="../../../../assets/images/no-task.png" alt="" v-if="!notEmpty">
    <TaskColumn
      v-else
      v-for="task in tasks"
      :task-name="task.name[$i18n.locale]"
      point="Пройти повторно"
      :action="`/profile/task/${task.task_type_id}/${task.id}?type=reply`"
      :key="task.id"
    />
  </ReplyTasksBase>
</template>

<script>
import TaskColumn from '../../../components/TaskColumn'
import { mapActions, mapGetters } from 'vuex'
import ReplyTasksBase from '../../../components/ReplyTasksBase'

export default {
  name: 'Tasks',
  components: {
    ReplyTasksBase,
    TaskColumn
  },
  data () {
    return {
      language: null,
      title: 'Задания',
      actionConfig: {
        route: '',
        params: [{
          'paramsKey': '',
          'dataKey': ''
        }]
      }
    }
  },
  computed: {
    ...mapGetters({
      tasks: 'task/doneTasks'
    }),
    notEmpty () {
      return this.tasks.length > 0
    }
  },
  created () {
    this.tasksInit(this.$route.params.id)
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.id !== from.params.id) {
      this.tasksInit(to.params.id)
    }
    next()
  },
  methods: {
    ...mapActions({
      getDoneTasks: 'task/getDoneTasks'
    }),
    async tasksInit (id) {
      await this.getDoneTasks(id)
      if (this.notEmpty) {
        this.title = 'Список заданий'
        this.language = this.tasks[0].lesson_block.lesson.course_module.course.teaching_language
      }
    }
  },
}
</script>
