<template>
  <div>
    <div class="personal-details statistics">
      <div>
        <Loading v-if="loading"/>
        <Translateable v-else :lang="block.lang" :lesson_id="block.lesson_block.lesson_id">
          <div class="statistics-content">
            <div class="task-container task-choose-translation">
              <div class="task-choose-translation-title-block" v-if="block.lesson_block">
                <div class="task-choose-translation-title-block-title">{{ block.lesson_block.name[$i18n.locale] }}</div>

                <div class="task-choose-translation-title-block-description lesson-block-description"
                     v-html="block.lesson_block.description[$i18n.locale]"></div>

              </div>
              <VideoAudioGallery v-if="Object.keys(data).length" :data="data" :video-multiple="true" class="mb-5"/>
              <EncyclopediaTable v-if="block.lesson_block.encyclopediaList.length" :vocabularies="vocabularies"
                                 :columns="block.lesson_block.encyclopediaList"
                                 :store="store" :destroy="destroy" class="mt-80"/>
              <div class="task-choose-translation-button-block justify-content-end">
                <button v-if="nextTaskId"
                        @click="next"
                        class="task-choose-translation-button-block-button-right" type="button">
                  Продолжить
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <g id="hover" transform="translate(-1040 -500)">
                      <circle cx="15" cy="15" r="15" transform="translate(1040 500)" fill="#cc3d44"/>
                      <g>
                        <g transform="matrix(0, -1, 1, 0, 765.814, 1056.932)">
                          <g>
                            <path
                              d="M541.953,292.157a.789.789,0,0,1-.606-.246q-1.577-1.572-3.158-3.142a.748.748,0,0,1,0-1.129l.193-.192a.759.759,0,0,1,1.142,0l2.312,2.3c.117.117.117.117.235,0l2.3-2.291a.759.759,0,0,1,1.149,0l.208.207a.744.744,0,0,1,0,1.1q-1.59,1.583-3.181,3.164A.776.776,0,0,1,541.953,292.157Z"
                              fill="#040404"/>
                          </g>
                        </g>
                        <path
                          d="M1053.454,518.741l-.192-.193a.758.758,0,0,1,0-1.142l2.3-2.312c.116-.117.116-.117,0-.235l-2.291-2.3a.76.76,0,0,1,0-1.15l.208-.208a.744.744,0,0,1,1.1,0l3.163,3.182a.776.776,0,0,1,.24.566v.035a.789.789,0,0,1-.247.606q-1.571,1.577-3.142,3.158a.748.748,0,0,1-1.13,0Z"
                          fill="#fff"/>
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </Translateable>
      </div>
    </div>
    <TaskAnswerModal ref="taskModal"/>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import VideoAudioGallery from '../../../components/VideoAudioGallery'
import EncyclopediaTable from '../../../components/EncyclopediaTable'
import Loading from '../../../components/Loading'
import Translateable from '../../../components/Translateable'
import TaskAnswerModal from '../../../components/TaskAnswerModal'

export default {
  name: 'Block',
  components: {
    TaskAnswerModal,
    Translateable,
    Loading,
    EncyclopediaTable,
    VideoAudioGallery
  },
  data () {
    return {
      data: {},
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      block: 'block/block',
      vocabularies: 'user/vocabularies',
      nextTaskId: 'nextTaskId/nextTaskId'
    })
  },
  watch: {
    $route () {
      this.blockInit()
    }
  },
  created () {
    this.blockInit()
  },
  methods: {
    ...mapActions({
      getBlock: 'block/getBlock',
      showedLessonBlock: 'block/showedLessonBlock',
      getVocabularies: 'user/getVocabularies',
      storeVocabulary: 'user/storeVocabulary',
      destroyVocabulary: 'user/destroyVocabulary',
      setTaskAnswerModal: 'task/setTaskAnswerModal'
    }),
    blockInit () {
      const videos = []
      this.loading = true
      const params = {
        id: this.$route.params.blockId,
        params: { is_free: this.$route.query.is_free }
      }
      this.getBlock(params).then(() => {
        const params = { ids: this.block.lesson_block.encyclopediaList.map(item => item.id) }
        this.getVocabularies(params)
        this.block.lesson_block.files.forEach(file => {
          if (file.type === 'video') {
            const videoObject = {
              video_path: file.extension === 'iframe' ? null : file.path,
              video_iframe: file.extension === 'video' ? null : file.path
            }
            videos.push(videoObject)
          } else if (file.type === 'audio') {
            this.$set(this.data, 'audio', file.path)
          }
        })
        if (videos.length) {
          this.$set(this.data, 'videos', videos)
        }

        if (this.block.lesson_block.gallery.length) {
          this.$set(this.data, 'gallery', this.block.lesson_block.gallery)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    async next () {
      const params = {
        id: this.$route.params.blockId,
        params: {
          lesson_id: this.block.lesson_block.lesson_id,
          module_id: this.block.course_module.id,
          course_id: this.block.course_module.course_id
        }
      }

      const res = await this.showedLessonBlock(params)

      if (this.nextTaskId.next_block_id === (this.$route.params.blockId || (!this.nextTaskId.next_task_type_id && !this.nextTaskId.next_block_id))) {
        this.setTaskAnswerModal({
          ...res.data,
          module_id: this.block.course_module.id
        }).then(() => {
          this.$root.$emit('bv::show::modal', 'modal-task')
        })
      } else {
        if (this.nextTaskId.next_task_id) {
          this.$router.push('/profile/task/' + this.nextTaskId.next_task_type_id + '/' + this.nextTaskId.next_task_id + '?lesson=' + this.nextTaskId.next_lesson_id + '&course=' + this.nextTaskId.next_course_id + '&module=' + this.nextTaskId.next_module_id + (this.$route.query.is_free ? '&is_free=' + this.$route.query.is_free : '')
          )
        } else {
          this.$router.push({
            name: 'block',
            params: { blockId: this.nextTaskId.next_block_id },
            query: this.$route.query
          })
        }
      }
    },
    store (vocabularyId) {
      console.log(vocabularyId)
      const params = {
        vocabulary_id: vocabularyId,
        lesson_block_id: this.block.id
      }
      this.storeVocabulary(params)
    },
    destroy (vocabularyId) {
      this.destroyVocabulary({ vocabulary_id: vocabularyId })
    }
  }
}
</script>

<style scoped lang="scss">
.mt-80 {
  margin-top: 80px;
}
</style>
