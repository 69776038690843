<template>
  <div class="video col-12 px-0">
    <bread-crumbs :pages="pages"/>
    <div class="video-block page-container">
      <div class="video-block-title-block">
        <div class="video-block-title-block-title">Видео</div>
        <div class="video-block-title-block-subtitle">Место для подзаголовка раздела</div>
      </div>
      <div class="video-block-videos row" v-if="allVideos.length">
        <div class="col-12 col-md-6">
          <template v-for="(video, index) in allVideos">
            <div class="col-12 video-page-column" v-if="index % 2 === 0"  :key="video.id">
              <div class="video-block-videos-video-container">
                <div class="video-block-videos-video-container-title">{{ video.title[$i18n.locale] }}</div>
                <div class="video-block-videos-video-container-video-image" @click="openVideoModal(video)" v-if="video && video.image_paths">
                  <img :src="video.image_paths.full" :alt="video.image_alt">
                  <div class="video-play">
                    <img src="../../../assets/images/icons/play-video.svg" alt="play">
                  </div>
                </div>
                <div class="video-block-videos-video-container-text" v-html="video.description[$i18n.locale]"></div>
              </div>
            </div>
          </template>
        </div>
        <div class="col-12 col-md-6">
          <template v-for="(video, index) in allVideos">
            <div class="col-12 video-page-column" v-if="index % 2 === 1"  :key="video.id">
              <div class="video-block-videos-video-container">
                <div class="video-block-videos-video-container-title">{{ video.title[$i18n.locale] }}</div>
                <div class="video-block-videos-video-container-video-image" @click="openVideoModal(video)" v-if="video && video.image_paths">
                  <img :src="video.image_paths.full" :alt="video.image_alt">
                  <div class="video-play">
                    <img src="../../../assets/images/icons/play-video.svg" alt="play">
                  </div>
                </div>
                <div class="video-block-videos-video-container-text" v-html="video.description[$i18n.locale]"></div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="mt-3" v-if="pagination.count_pages > 1">
        <b-pagination
          v-model="currentPage"
          :total-rows="pagination.count_pages"
          :per-page="1"
          first-number
          last-number
          prev-text="«"
          next-text="»"
          @input="clickPagination"
        ></b-pagination>
      </div>
    </div>

    <b-modal hide-footer scrollable hide-header ref="videoModal" content-class="video-modal">
      <div class="close-modal" @click="closeVideoModal">
        <img src="../../../assets/images/icons/close.svg" alt="Close">
      </div>
      <div class="modal-block" v-if="videoModal">
        <div class="title">{{ videoModal.title[$i18n.locale]}}</div>
        <div class="video">
          <div class="video" v-if="videoModal.video_path">
            <video
              width="100%"
              :src="videoModal.video_path"
              controls
            >
            </video>
          </div>
          <div class="video" v-if="videoModal.video_iframe" v-html="videoModal.video_iframe"></div>
        </div>
        <div class="video-block-videos-video-container-modal-text" v-html="videoModal.description[$i18n.locale]"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import BreadCrumbs from '../../components/BreadCrumbs'
import { mapState, mapActions } from 'vuex'
import VideoModal from '../../components/Videos/VideoModal'
import Template from '../Profile/TechnicalSupport/Template'

export default {
  name: 'Video',
  components: {
    Template,
    VideoModal,
    BreadCrumbs
  },
  data () {
    return {
      currentPage: 1,
      perPage: 1,
      rows: 26,
      videoModal: null,
      pages: [
        { path: '/', rout: 'Главная', active: false },
        { path: '', rout: 'Видео', active: true }
      ],
      showModal: false,
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.query.page) {
      this.currentPage = to.query.page
      this.getAllVideos({ page: to.query.page, count: 10 })
    } else {
      this.currentPage = 1
      this.getAllVideos({ page: 1, count: 10 })
    }
    next()
  },
  created () {
    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page
      this.getAllVideos({ page: this.$route.query.page, count: 10 })
    } else {
      this.getAllVideos({ page: this.currentPage, count: 10 })
    }
  },
  computed: {
    ...mapState({
      allVideos: state => state.videos.allVideos,
      pagination: state => state.videos.pagination
    })
  },
  metaInfo () {
    const title = this.$t('videos.title')
    return {
      title: title,
      meta: [
        {
          name: 'og:title',
          content: title
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getAllVideos: 'videos/getAllVideos'
    }),
    openVideoModal (video) {
      this.videoModal = video
      this.$refs.videoModal.show()
    },
    closeVideoModal () {
      this.$refs.videoModal.hide()
    },
    clickPagination () {
      window.scrollTo(0, 0)
      this.$router.push('/video?page=' + this.currentPage)
    },
    transformToLink (link) {
      const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|v(?:i)?=))([^#]*).*/
      const linkArray = link.match(rx)
      const id = linkArray[1]
      const youtubeLink = 'https://www.youtube.com/embed/' + id
      return youtubeLink
    }
  }
}
</script>
