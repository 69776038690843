import { http } from '../../../api'

const chatExpert = {
  namespaced: true,
  state: {
    messages: []
  },
  actions: {
    sendMessage: ({ commit }, params) => {
      return http.post('/chat-course-expert', params).then(res => {
        return res.data
      })
    },
    callExpert: ({ commit }, params) => {
      return http.post('course-expert/call-expert', params).then(res => {
        return res.data
      })
    },
    acceptCall: ({ commit }, params) => {
      return http.post('course-expert/accept-call', params).then(res => {
        return res.data
      })
    },
    getMessages: ({ commit }, params) => {
      return http.get('/chat-course-expert/' + params.id).then(res => {
        commit('setMessages', res.data)
        return res.data
      })
    }
  },
  mutations: {
    setMessages (state, data) {
      state.messages = data.data
    }
  },
  getters: {
    messages: state => state.messages
  }
}

export default chatExpert
