import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      'testTasks': 'task/testTasks'
    })
  },
  methods: {
    ...mapActions({
      'generateTestTask': 'task/generateTestTask'
    }),
    openTest (module, course_id) {
      if (module.lessons.findIndex(lesson => lesson.status_lesson !== 'done') !== -1 || module.module_user.test !== 0) {
        return false
      }

      const params = {
        course_module_id: module.id,
        type: 'test'
      }

      this.generateTestTask(params)
        .then(() => {
          this.$router.push(`/profile/task/${this.testTasks.task_type_id}/${this.testTasks.task_id}?lesson=${this.testTasks.lesson_id}&course=${course_id}&module=${module.id}`)
        })
    },
    openCourseTest (course) {
      if (course.course_modules.findIndex(module => module.module_user.test === 0) !== -1 || course.users.findIndex(user => user.pivot.test === 1) !== -1) {
        return false
      }

      const params = {
        course_module_id: course.course_modules[course.course_modules.length - 1].id,
        type: 'course_test'
      }
      this.generateTestTask(params)
        .then(() => {
          this.$router.push(`/profile/task/${this.testTasks.task_type_id}/${this.testTasks.task_id}?lesson=${this.testTasks.lesson_id}&course=${course.id}&module=${params.course_module_id}`)
        })

    }
  }
}
