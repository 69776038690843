<template>
  <div class="video col-12 px-0">
    <bread-crumbs :pages="pages"/>
    <div class="video-block page-container">
      <div class="video-block-title-block">
        <div class="video-block-title-block-title">Интересное</div>
        <div class="video-block-title-block-subtitle">Факты о странах и полезные материалы</div>
      </div>
      <div class="btn-group">
        <button type="button" class="btn-transparent" :class="activeLang === 'all' ? 'btn-transparent-active' : ''"
                @click="getInterestingList('all')">Все
        </button>
        <button type="button" class="btn-transparent"
                :style="activeLang == category.slug ? ('background-image:' + category.color) : ''"
                :class="activeLang == category.slug ? 'btn-transparent-active' : ''"
                v-for="(category, ind) in interestingCategories" :key="ind" @click="getInterestingList(category.slug)">
          {{ category.title[$i18n.locale] }}
        </button>
      </div>
      <div class="video-block-videos row">
        <div class="col-12 col-md-6">
          <template v-for="(interesting,ind) in allInterestings">
            <InterestingCard :key="ind" :interesting="interesting" v-if="ind % 2 === 0" />
          </template>
        </div>
        <template>
          <div class="col-12 col-md-6">
            <template v-for="(interesting,ind) in allInterestings">
              <InterestingCard :key="ind" :interesting="interesting" v-if="ind % 2 === 1" />
            </template>
          </div>
        </template>
      </div>
      <div class="mt-3" v-if="showData && pagination.count_pages > 1">
        <b-pagination
          @input="clickPagination"
          v-model="currentPage"
          :total-rows="countPages"
          :per-page="1"
          first-number
          last-number
          prev-text="«"
          next-text="»"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>

import BreadCrumbs from '../../components/BreadCrumbs'
import { mapActions, mapState } from 'vuex'
import Template from '../Profile/TechnicalSupport/Template'
import InterestingCard from '../../components/Interesting/Card'

export default {
  name: 'Interesting',
  data () {
    return {
      showData: false,
      currentPage: 1,
      perPage: 0,
      countPages: 1,
      rows: 26,
      activeLang: 'all',
      pages: [
        {
          path: '/',
          rout: 'Главная',
          active: false
        },
        {
          path: '',
          rout: 'Интересное',
          active: true
        }
      ]
    }
  },
  components: {
    InterestingCard,
    Template,
    BreadCrumbs
  },
  computed: {
    ...mapState({
      interestingCategories: state => state.interesting.interestingCategories,
      pagination: state => state.interesting.pagination,
      allInterestings: state => state.interesting.allInterestings
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.query.page) {
      this.currentPage = to.query.page
      this.getInterestings({
        page: to.query.page,
        category_slug: to.params.id
      }).then(res => {
        this.showData = true
        this.countPages = res.pagination.count_pages
      })
    } else {
      this.currentPage = 1
      this.getInterestings({
        page: 1,
        category_slug: to.params.id
      })
    }
    this.activeLang = to.params.id
    next()
  },
  mounted () {
    this.activeLang = this.$route.params.id
    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page
      this.getInterestings({
        page: this.$route.query.page,
        category_slug: this.$route.params.id
      }).then(res => {
        this.showData = true
        this.countPages = res.pagination.count_pages
      })
    } else {
      this.getInterestings({
        page: this.currentPage,
        category_slug: this.$route.params.id
      }).then(res => {
        this.showData = true
        this.countPages = res.pagination.count_pages
      })
    }
  },
  metaInfo () {
    const title = this.$t('interesting.title')
    return {
      title: title,
      meta: [
        {
          name: 'og:title',
          content: title
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getInterestings: 'interesting/getInterestings'
    }),
    clickPagination () {
      this.$router.push('/interesting/' + this.activeLang + '?page=' + this.currentPage)
      window.scrollTo(0, 0)
    },
    getInterestingList (type) {
      this.$router.push('/interesting/' + type)
      this.activeLang = type
      this.getInterestings({
        page: this.currentPage,
        category_slug: type
      }).then(res => {
        this.countPages = res.pagination.count_pages
      })
    }
  }
}
</script>
