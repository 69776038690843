<template>
  <img @click="zoomImage" :height="height" :width="width" class="zoom" :src="require(`@/assets/images/tasks/${name}.png`)" alt="zoom">
</template>

<script>
import { api as viewerApi } from 'v-viewer'

export default {
  name: 'Zoom',
  props: {
    image: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: '33px'
    },
    width: {
      type: String,
      default: '33px'
    },
    name: {
      type: String,
      default: 'zoom'
    }
  },
  methods: {
    zoomImage () {
      const $viewer = viewerApi({
        options: {
          toolbar: false,
        },
        images: [this.image]
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .zoom{
    cursor: pointer;
  }
</style>
