<template>
  <div class="video-wrapper position-relative">
    <video ref="videoMan"></video>
    <div class="position-absolute video-call-info" :class="{'me':me}">
      <div class="d-flex">
        <div class="position-relative icon-wrapper" @click="stopVideo">
          <i v-if="videoMute || userVideoMute" class="fas fa-video-slash"></i>
          <i v-else class="fas fa-video"></i>
          <div class="rounded-circle icon">
          </div>
        </div>
        <div class="position-relative icon-wrapper ml-2" @click="stopAudio">
          <i v-if="audioMute || userAudioMute" class="fas fa-microphone-slash"></i>
          <i v-else class="fas fa-microphone"></i>
          <div class="rounded-circle icon">
          </div>
        </div>
        <div @click="close" class="position-relative icon-wrapper ml-2" v-if="me">
          <i class="fas fa-phone-slash"></i>
          <div class="rounded-circle icon"/>
        </div>

      </div>
      <div>{{ login }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoCallView',
  props: {
    avatar: {
      type: URL,
      default: null
    },
    login: {
      type: String,
      default: null
    },
    me: {
      type: Boolean,
      default: false
    },
    stream: {
      default: null
    },
    userVideoMute: {
      type: Boolean,
      default: false
    },
    userAudioMute: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      videoMute: false,
      audioMute: false
    }
  },
  mounted () {
    this.$refs.videoMan.srcObject = this.stream
    if (this.me){
      this.$refs.videoMan.muted = true
    }
    this.$refs.videoMan.addEventListener('loadedmetadata', () => {
      this.$refs.videoMan.play()
    })
  },
  methods: {
    close () {
      this.$emit('closeCall')
    },
    stopVideo () {
      if (!this.me) {
        return false
      }
      if (!this.videoMute) {
        this.stream.getVideoTracks().map(trac => {
          trac.enabled = false
        })
        this.videoMute = true
      } else {
        this.stream.getVideoTracks().map(trac => {
          trac.enabled = true
        })
        this.videoMute = false
      }
      this.$emit('muteVideo', this.videoMute)
    },
    stopAudio () {
      if (!this.me) {
        return false
      }

      if (!this.audioMute) {
        this.stream.getAudioTracks().map(trac => {
          trac.enabled = false
        })
        this.audioMute = true
      } else {
        this.stream.getAudioTracks().map(trac => {
          trac.enabled = true
        })
        this.audioMute = false
      }
      this.$emit('muteAudio', this.audioMute)
    }
  }
}
</script>

<style scoped lang="scss">
.video-wrapper {
  width: 100%;
  position: relative;
  max-height: calc(30.7vh);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 2px #F5F6FB;
  border-right: solid 2px #F5F6FB;
  background-color: black;

  &:nth-last-child(-n+2) {
    border-bottom: none;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }

  video {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    max-height: 100%;
  }

  .video-call-info {
    bottom: 22px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 18px;

    .icon-wrapper {
      .icon {
        background-color: #1E1E1E;
        position: relative;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.4;

      }

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        z-index: 9999;
      }

    }

    &.me {
      .icon-wrapper {
        .icon {
          background-color: #6D6E71;
        }

        &:hover {
          cursor: pointer;

          .icon {
            background-color: #FFFFFF;
            opacity: 1;
          }

          i {
            color: #57A5D6;
          }
        }
      }

    }
  }

  &.col-md-12 {
    .video-call-info {
      font-size: 16px;

      .icon {
        width: 60px;
        height: 60px;
      }
    }
  }

  &.col-md-6 {
    .video-call-info {
      font-size: 10px;

      .icon {
        width: 26px;
        height: 26px;
      }
    }

  }
}
</style>
