<template>
  <div>
    <div class="personal-details books videos">
      <div class="title-block">
        <div class="d-flex align-items-center">
          <div class="flag">
            <img :src="courseTeachingLanguage.flag_path" alt="">
          </div>
          <div>
            <div class="tabs-title">
              Видео материалы
            </div>
            <div class="subtitle">
              {{courseTeachingLanguage.name[$i18n.locale]}}
            </div>
          </div>
        </div>
        <div class="tabs-info mt-2" v-if="courseVideoList && !courseVideoList.length">
          У курса нету видео материалов
        </div>
      </div>
      <div class="books-content">
        <loading v-if="loading" />
        <div v-else class="books-content-column w-100">
          <div class="books-content-item" v-for="(video, index) in courseVideoList" :key="index">
            <div class="books-item-title" v-if="video.name">{{video.name[$i18n.locale]}} </div>
            <div class="d-flex justify-content-center">
              <div class="video-content cursor-pointer material-video-image" @click="openVideoModal(video)">
                <img :src="video.image ? video.image : require('@/assets/images/default.png')" class="w-100" alt="video-image">
                <div class="play-icon">
                  <img src="~@/assets/images/profile/play.svg" alt="">
                </div>
              </div>
            </div>
            <div class="item-content">
              <div class="description">
                <div class="text" v-if="video.description">{{video.description[$i18n.locale]}}</div>
              </div>
            </div>
          </div>
        </div>
        <img v-if="courseVideoList.length === 0 && !loading" src="~@/assets/images/profile/no-video.svg" alt="notes">
      </div>
      <div class="mt-3" v-if="pagination.count_pages > 1 && !loading">
        <b-pagination
          v-model="currentPage"
          :total-rows="pagination.count_pages"
          :per-page="1"
          first-number
          last-number
          prev-text="«"
          next-text="»"
          @input="clickPagination"
        ></b-pagination>
      </div>

      <b-modal id="video-modal" ref="videoModal">
        <template #modal-header="{ close }">
          <b-button @click="close()">
            X
          </b-button>
          <h3 v-if="modalVideo.name">{{modalVideo.name[$i18n.locale]}}</h3>
        </template>
        <div class="video-modal-content">
          <div class="video">
            <div class="video" v-if="modalVideo.video">
              <video
                width="100%"
                :src="modalVideo.video"
                controls
              >
              </video>
            </div>
            <div class="video" v-if="modalVideo.video_iframe" v-html="modalVideo.video_iframe">
            </div>
          </div>
          <div class="text" v-if="modalVideo.description">{{modalVideo.description[$i18n.locale]}}</div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import Loading from '../../../components/Loading'
export default {
  name: 'VideoMaterials',
  components: { Loading },
  data () {
    return {
      currentPage: 1,
      modalVideo: {}
    }
  },
  computed: {
    ...mapState({
      courseVideoList: state => state.course.courseVideoList,
      pagination: state => state.course.pagination,
      courseTeachingLanguage: state => state.course.courseTeachingLanguage,
      loading: state => state.loading.loading
    })
  },
  created () {
    this.getCourseVideo({ id: this.$route.params.courseId, page: this.currentPage }).then(res => {})
    this.getCourseTeachingLanguage({ id: this.$route.params.courseId }).then(res => {})
  },
  methods: {
    ...mapActions({
      getCourseVideo: 'course/getCourseVideo',
      getCourseTeachingLanguage: 'course/getCourseTeachingLanguage'
    }),
    openVideoModal (video) {
      this.modalVideo = video
      this.$refs.videoModal.show()
    },
    clickPagination () {
      this.getCourseVideo({ id: this.$route.params.courseId, page: this.currentPage }).then(() => {
        window.scrollTo(0, 0)
      })
    }
  }
}
</script>
<style scoped>

</style>
