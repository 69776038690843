import { http } from '../../../api'
import Peer from 'peerjs'

const courseChat = {
  namespaced: true,
  state: {
    chatList: [],
    chatItem: [],
    chatModalClose: false,
    videoChat: {},
    peer: null,
    videoCallUsers: [],
    stream: null,
    mediaConnection: null,
    channel: null,
    callPlaced: false,
    videoChatKey: null,
    videoChatUsers: {},
  },
  actions: {
    getChatList: ({ commit }, params) => {
      commit('loading/setLoading', true, { root: true })
      const q = new URLSearchParams(params)
      return http.get('/course-chat?' + q.toString()).then(res => {
        commit('setChatList', res.data.data)
        return res.data
      }).finally(() => {
        commit('loading/setLoading', false, { root: true })
      })
    },
    createChat: ({ commit }, params) => {
      return http.post('/course-chat', params.data).then(res => {
      })
    },
    acceptInvitation: ({ commit }, params) => {
      const q = new URLSearchParams(params.params)
      return http.get('course-chat/' + params.id + '/accept-invitation?' + q.toString()).then(res => {
        return res
      })
    },
    declineInvitation: ({ commit }, params) => {
      return http.post('course-chat/' + params.id + '/decline-invitation', params.params).then(res => {
        return res
      })
    },
    leaveChat: ({ commit }, params) => {
      return http.post('/course-chat-leave', params).then(res => {
        commit('leaveChat', params)
      })
    },
    sendMessage: ({ commit }, params) => {
      return http.post('/course-chat/' + params.id, params.data).then(res => {
        commit('addChatMessage', res.data.data)
      })
    },
    showChat: ({ commit }, params) => {
      commit('loading/setLoading', true, { root: true })
      return http.get('course-chat/' + params.id).then(res => {
        commit('setChat', res.data.data)
        return res.data
      }).finally(() => {
        commit('loading/setLoading', false, { root: true })
      })
    },
    startVideoCall: ({ commit }, params) => {
      return http.post('startVideoCall', params).then(res => {
        commit('setVideoChatKey', res.data.video_chat_key)
        return res.data
      })
    },
    mute({commit}, params){
      return http.put('mute', params).then(res => res.data)
    },
    closeCall({state}){
      return http.put('closeCall', {
          'video_chat_key':state.videoChatKey,
          'peer_id': state.peer.id
        }).then(res => res.data)
    }
  },
  mutations: {
    setChatList (state, data) {
      state.chatList = data
    },
    leaveChat (state, data) {
      state.chatList = state.chatList.filter((i) => i.id !== data.id)
    },
    addChatMessage (state, data) {
      state.chatItem.course_chat_messages.push(data)
    },
    setChat (state, data) {
      state.chatItem = data
    },
    setModalClose (state, data) {
      state.chatModalClose = data
    },
    setVideoChat (state, data) {
      state.videoChat = data
    },
    setPeer (state) {
      if (!state.peer) {
        state.peer = new Peer(null, {
          host: process.env.VUE_APP_PEER_HOST,
          port: process.env.VUE_APP_PEER_PORT,
          path: process.env.VUE_APP_PEER_PATH,
          config: {
            'iceServers': [
              { url: process.env.VUE_APP_STUN_SERVER },
              {
                url: process.env.VUE_APP_TURN_SERVER,
                credential: process.env.VUE_APP_TURN_CREDETINALS,
                username: process.env.VUE_APP_TURN_USERNAME
              }
            ]
          }
        })
      }
    },
    setStream (state, data) {
      state.stream = data
    },
    setVideoCallUser (state, {
      data,
      type
    }) {
      if (type === 'push') {
        if (state.videoCallUsers.findIndex(video => video.peer_id === data.peer_id) === -1) {
          state.videoCallUsers.push(data)
        }
      } else {
        state.videoCallUsers = []
      }
    },
    setMediaConnection (state, data) {
      state.mediaConnection = data
    },
    setChannel(state, data){
      state.channel = data
    },
    setCallPlaced(state, data){
      state.callPlaced = data
    },
    setVideoChatKey(state, data){
      state.videoChatKey = data
    },
    setVideoChatUsers(state, data){
      state.videoChatUsers = data
    }
  },
  getters: {
    chatList: state => state.chatList,
    chatItem: state => state.chatItem,
    chatModalClose: state => state.chatModalClose,
    videoChat: state => state.videoChat,
    peer: state => state.peer,
    stream: state => state.stream,
    videoCallUsers: state => state.videoCallUsers,
    mediaConnection: state => state.mediaConnection,
    channel: state => state.channel,
    callPlaced: state => state.callPlaced,
    videoChatKey: state => state.videoChatKey,
    videoChatUsers: state => state.videoChatUsers
  }
}

export default courseChat
