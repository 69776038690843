<template>
  <ReplyTasksBase :language="language" :title="title">
    <img v-if="!notEmpty" src="../../../../assets/images/no-task.png" alt="flag">
    <template v-else v-for="(course_module, index) in course_modules">
      <TaskColumn
        :task-name="`Тест: Модуль ${index + 1}`"
        point="Пройти повторно"
        :action="`/profile/task/${course_module.lessons[0].lesson_blocks[0].tasks[0].task_type_id}/${course_module.lessons[0].lesson_blocks[0].tasks[0].id}?type=replyTest&lesson=${course_module.lessons[0].id}`"
        :key="course_module.id"
      />
    </template>
    <TaskColumn
      v-if="courseTest"
      :task-name="`Тест: Курс`"
      point="Пройти повторно"
      :action="`/profile/task/${courseTest.lesson_blocks[0].tasks[0].task_type_id}/${courseTest.lesson_blocks[0].tasks[0].id}?type=replyTest&lesson=${courseTest.id}`"
      :key="courseTest.id"
    />

  </ReplyTasksBase>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ReplyTasksBase from '../../../components/ReplyTasksBase'
import TaskColumn from '../../../components/TaskColumn'

export default {
  name: 'Test',
  components: {
    ReplyTasksBase,
    TaskColumn
  },
  data () {
    return {
      title: null,
      language: null,
      courseTest: null
    }
  },
  computed: {
    ...mapGetters({
      course_modules: 'lesson/lessonsTest'
    }),
    notEmpty () {
      return this.course_modules.length > 0
    }
  },
  created () {
    this.tasksInit(this.$route.params.id)
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.id !== from.params.id) {
      this.getLessonsTest({ course_id: to.params.id })
    }
    next()
  },
  methods: {
    ...mapActions({
      getLessonsTest: 'lesson/getLessonsTest'
    }),
    async tasksInit (course_id) {
      await this.getLessonsTest({ course_id })
      if (this.notEmpty) {
        this.language = this.course_modules[0].course.teaching_language
        this.title = 'Тесты'

        this.courseTest = this.course_modules[this.course_modules.length - 1].lessons.find(lesson => lesson.lesson_type.name === 'course_test')

      }
    }
  }
}
</script>
