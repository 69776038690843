<template>
  <div class="note-found">
    <div class="note-found-container page-container">
      <div class="note-found-container-info">
        <div class="note-found-container-info-error">ой...</div>
        <div class="note-found-container-info-error">что-то пошло не так</div>
        <div class="note-found-container-info-status">404</div>
        <div class="note-found-container-info-error text-uppercase">ошибка</div>
        <div class="note-found-container-info-text">
          К сожалению, страница, которую вы искали, не найдена или была удалена.
          Попробуйте вернуться на главную страницу и найти необходимую информацию.
        </div>
        <button type="button" class="btn-purple-outline" @click="$router.push('/')">вернуться на главную</button>
      </div>
      <div class="note-found-container-image">
        <img src="../../assets/images/error-image.png" alt="404">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '404'
}
</script>
