<template>
  <div>
    <!-- Video Chat when there is no video yet -->
    <div class="personal-details chat video-chat d-none">
      <div class="chat-items">
        <div class="top-line">
          <div class="d-flex align-items-center justify-content-between">
            <div class="name">Чаты</div>
            <button class="add-btn" type="button">+</button>
          </div>
          <div class="search">
            <input type="text" placeholder="Поиск чата">
            <img src="~@/assets/images/icons/search.svg" alt="search">
          </div>
        </div>
        <div class="chat-list">
          <ul class="list">
            <router-link to="/profile/chat" tag="li">
              <p>Китайский для новичков</p><span class="badge">2</span>
            </router-link>
            <router-link to="/profile/video" tag="li">
              <p>Чат видео название</p>
            </router-link>
            <router-link to="/profile/video-chat" tag="li">
              <p>Чат видео + переписка</p>
            </router-link>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="chat-content">
        <div class="top-line">
          <div class="left">
            <div class="chat-name">Чат видео название</div>
            <div class="people-number">4 человека</div>
          </div>
          <div class="right">
            <a href="#">
              <img src="~@/assets/images/icons/video.svg" alt="">
            </a>
          </div>
          <div class="right">
            <a href="#">
              <img src="~@/assets/images/icons/out.svg" alt="">
            </a>
          </div>
        </div>
        <div class="content">
          <img src="~@/assets/images/profile/video-chat.svg" alt="">
          <div class="description">
            Этот чат только для видео связи.
            Нажмите на кнопку чтобы сделать вызов участникам чата.
          </div>
          <button type="button" class="start-btn">начать видео звонок</button>
        </div>
      </div>
    </div>

    <!-- Video chat -->
    <div class="personal-details chat video-chat filled">
      <div class="chat-items">
        <div class="top-line">
          <div class="d-flex align-items-center justify-content-between">
            <div class="name">Чаты</div>
            <button class="add-btn" type="button">+</button>
          </div>
          <div class="search">
            <input type="text" placeholder="Поиск чата">
            <img src="~@/assets/images/icons/search.svg" alt="search">
          </div>
        </div>
        <div class="chat-list">
          <ul class="list">
            <router-link to="/profile/chat" tag="li">
              <p>Китайский для новичков</p><span class="badge">2</span>
            </router-link>
            <router-link to="/profile/video" tag="li">
              <p>Чат видео название</p>
            </router-link>
            <router-link to="/profile/video-chat" tag="li">
              <p>Чат видео + переписка</p>
            </router-link>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
            <li>
              <p>Название чата</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="chat-content">
        <div class="top-line">
          <div class="left">
            <div class="mobile-visible">
              <a href="#">
                <img src="~@/assets/images/icons/menu-burger.svg" alt="menu burger">
              </a>
            </div>
            <div class="wrapper">
              <div class="chat-name">Чат видео название</div>
              <div class="people-number">4 человека</div>
            </div>
          </div>
          <div class="right">
            <a href="#">
              <img src="~@/assets/images/icons/video.svg" alt="">
            </a>
          </div>
          <div class="right">
            <a href="#">
              <img src="~@/assets/images/icons/out.svg" alt="">
            </a>
          </div>
        </div>
        <div class="content">
          <div class="person">
            <img src="~@/assets/images/user.png" alt="">
            <div class="top-overlay">
              <div class="bottom">
                <div class="bottom-actions">
                  <a href="#" class="item">
                    <img src="~@/assets/images/icons/microphone.svg" alt="">
                  </a>
                  <a href="#" class="item">
                    <img src="~@/assets/images/icons/video-white.svg" alt="">
                  </a>
                </div>
                <div class="name">User_nicname/login</div>
              </div>
            </div>
          </div>
          <div class="person">
            <span>Нет видео</span>
            <div class="top-overlay">
              <div class="bottom">
                <div class="bottom-actions">
                  <a href="#" class="item">
                    <img src="~@/assets/images/icons/microphone.svg" alt="">
                  </a>
                  <a href="#" class="item">
                    <img src="~@/assets/images/icons/video-white.svg" alt="">
                  </a>
                </div>
                <div class="name">User_nicname/login</div>
              </div>
            </div>
          </div>
          <div class="person">
            <img src="~@/assets/images/user.png" alt="">
            <div class="top-overlay">
              <div class="bottom">
                <div class="bottom-actions">
                  <a href="#" class="item">
                    <img src="~@/assets/images/icons/microphone.svg" alt="">
                  </a>
                  <a href="#" class="item">
                    <img src="~@/assets/images/icons/video-white.svg" alt="">
                  </a>
                </div>
                <div class="name">User_nicname/login</div>
              </div>
            </div>
          </div>
          <div class="person">
            <img src="~@/assets/images/user.png" alt="">
            <div class="top-overlay">
              <div class="bottom">
                <div class="bottom-actions">
                  <a href="#" class="item">
                    <img src="~@/assets/images/icons/microphone.svg" alt="">
                  </a>
                  <a href="#" class="item">
                    <img src="~@/assets/images/icons/video-white.svg" alt="">
                  </a>
                  <a href="#" class="item" v-b-modal.end-call>
                    <img src="~@/assets/images/icons/end-call.svg" alt="">
                  </a>
                  <b-modal id="end-call">
                    <template #modal-header="{ close }">
                      <b-button @click="close()">
                        X
                      </b-button>
                      <h3>Покинуть чат</h3>
                    </template>
                    <div>
                      Вы собираетесь покинуть чат. После данного действия
                      вся история чата будет удалена. Вы уверены что хотите покинуть чат?
                    </div>
                    <div class="actions">
                      <button type="button" class="cancel">Отмена</button>
                      <button type="button" class="remove">покинуть чат</button>
                    </div>
                  </b-modal>
                </div>
                <div class="name">User_nicname/login</div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'VideoChat'
}
</script>
