<template>
  <TaskBase :task="task" :check-task="categorizeCheck" :correct-answer="correctAnswer">
    <template #desktop>
      <WordModal
        :words="availableWords"
        :label="isImagesType ? 'image' : 'name'"
        success="success"
        error="success"
        :positions="positions"
        :show.sync="showModal"
        @pickWord="pickWordModal"
        :type="task.task.type.name"
      >
        <div
          class="mb-5 d-flex"
          :class="{'categorize-container': !isImagesType, 'categorize-container-images' : isImagesType}"
          ref="scroll"
        >
          <Words
            :type="task.task.type.name"
            :words="availableWords"
            :positions="positions"
            :label="isImagesType ? 'image' : 'name'"
            :title="isImagesType ? 'Доступные изображения:': 'Доступные слова:'"
          />
          <div class="d-flex flex-column categorize-wrapper">
            <div class="categorize-images only-pc" v-if="task.task.type.name === 'images'">
              <template v-for="(group,index) in groups">
                <draggable
                  class="d-flex flex-wrap"
                  v-bind="dragOptions"
                  group="answers"
                  :move="handleMove"
                  @end="handleDragEnd"
                  v-model="group.items"
                >
                  <CategorizeItem
                    :type="task.task.type.name"
                    class="categorize-image-answer"
                    data-name="groups"
                    v-for="(item,i) in group.items"
                    :data-category-index="index"
                    :data-index="i"
                    :image="item.image"
                    :name="item.name"
                    :zoom="true"
                    :zoom-class="'md-zoom'"
                  />
                </draggable>
              </template>
            </div>
            <div class="d-md-flex flex-wrap categorize-width only-pc" v-else>
              <template v-for="(group,index) in groups">
                <draggable
                  class="d-flex margin"
                  v-bind="dragOptions"
                  group="answers"
                  :move="handleMove"
                  @end="handleDragEnd"
                  v-model="group.items"
                  v-for="(item,i) in group.items"
                >
                  <CategorizeItem
                    :type="task.task.type.name"
                    data-name="groups"
                    :data-category-index="index"
                    :data-index="i"
                    :image="item.image"
                    :name="item.name"
                  />
                </draggable>
              </template>
            </div>
          </div>
          <div class="d-flex flex-column categorize-answers" :class="{'answers-images': isImagesType}">
            <template v-for="(group, index) in answers">
              <div class="w-100 columns-name my-md-2">{{ group.name }}</div>
              <draggable
                class="d-flex flex-wrap"
                :class="{'flex-column flex-md-row': !isImagesType}"
                v-bind="dragOptions"
                group="answers"
                :move="handleMove"
                @end="handleDragEnd"
                v-model="group.items"
              >
                <CategorizeItem
                  v-for="(item,i) in group.items"
                  @click.native="() => pickWord(item, index, i)"
                  :type="task.task.type.name"
                  data-name="answers"
                  :data-category-index="index"
                  :data-index="i"
                  class="categorize-answer wordable"
                  :class="{
                    'categorize-image-answer': isImagesType,
                     'categorize-answer': !isImagesType
                  }"
                  :name="item.name"
                  :image="item.image"
                  :dashed="!item.id"
                  :error="results[index][i] === false && (isMobile && item.id) || !isMobile && results[index][i] === false"
                  :success="results[index][i]"
                />
              </draggable>
            </template>
          </div>
        </div>
      </WordModal>
    </template>
  </TaskBase>
</template>
<script>
import TaskAnswerModal from '../../../../components/TaskAnswerModal'
import { mapActions, mapState } from 'vuex'
import VideoAudioGallery from '../../../../components/VideoAudioGallery'
import TaskBase from '../../../../components/TaskBase'
import TaskCheckParamsMixin from '../../../../../mixins/TaskCheckParamsMixin'
import draggable from 'vuedraggable'
import CategorizeItem from '../../../../components/Tasks/Categorize/CategorizeItem'
import ArrayShuffleMixin from '../../../../../mixins/ArrayShuffleMixin'
import ScrollMixins from '../../../../../mixins/ScrollMixins'
import Words from '../../../../components/Tasks/Words'
import WordModal from '../../../../components/Tasks/WordModal'
import CheckWidthMixin from '../../../../../mixins/CheckWidthMixin'

export default {
  name: 'Categorize',
  mixins: [TaskCheckParamsMixin, ArrayShuffleMixin, ScrollMixins, CheckWidthMixin],
  components: {
    WordModal,
    Words,
    CategorizeItem,
    TaskBase,
    VideoAudioGallery,
    TaskAnswerModal,
    draggable
  },
  data () {
    return {
      groups: [],
      dragOptions: {
        animation: 200,
        disabled: false,
      },
      correctAnswers: [],
      draggedContext: null,
      relatedContext: null,
      dragged: null,
      related: null,
      answers: [],
      results: [],
      textGroup: [],
      availableWords: [],
      positions: {},
      showModal: false,
      activeItem: {}
    }
  },
  computed: {
    ...mapState({
      task: state => state.task.task,
      nextTask: state => state.course.nextTask
    }),
    isImagesType () {
      return this.task.task.type.name === 'images'
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.fullPath !== from.fullPath) {
      Object.assign(this.$data, this.$options.data())
      this.taskInit(to)
    }
    next()
  },
  created () {
    this.taskInit(this.$route)
  },
  methods: {
    ...mapActions({
      getTask: 'task/getTask',
      missingTask: 'course/missingTask',
      getMissingTask: 'course/getMissingTask',
      missCategorizeTask: 'task/missCategorizeTask',
      setTaskAnswerModal: 'task/setTaskAnswerModal'
    }),
    pickWordModal (word, itemIndex) {
      this.showModal = !this.showModal

      let {
        groupIndex: itemGroupIndex,
        index
      } = this.activeItem

      let wordGroupIndex,
        activeGroupIndex,
        tempData

      if (this.positions[word.id]) {
        const {
          groupIndex,
          index: itemPosIndex
        } = this.positions[word.id]

        tempData = this.answers[itemGroupIndex].items[index]
        this.$set(this.answers[groupIndex].items, itemPosIndex, tempData)
        this.$set(this.answers[itemGroupIndex].items, index, word)
        wordGroupIndex = itemGroupIndex
        activeGroupIndex = groupIndex
        itemIndex = itemPosIndex
      } else {
        tempData = this.answers[itemGroupIndex].items[index]
        this.$set(this.answers[itemGroupIndex].items, index, word)
        this.$set(this.groups[word.groupIndex].items, word.index, tempData)
        wordGroupIndex = itemGroupIndex
        activeGroupIndex = word.groupIndex
        itemIndex = word.index
      }

      this.setPositions(word, wordGroupIndex, index)
      this.setPositions(tempData, activeGroupIndex, itemIndex)
    },

    pickWord (word, groupIndex, index) {
      if (!this.isMobile) return false

      this.activeItem = {
        groupIndex,
        index
      }

      this.showModal = true
    },
    taskInit (route) {
      const query = {}
      if (route.query.type) {
        query.type = route.query.type
      }
      this.getTask({
        id: route.params.taskId,
        query
      }).then(() => {

        this.task.task.categories.forEach((category, index) => {

          this.groups.push({
            id: category.id,
            name: category.name,
            image: category.image,
            items: category.items
          })

          this.answers.push({
            id: category.id,
            name: category.name,
            image: category.image,
            items: []
          })

          this.results[index] = []

          category.items.forEach((item, i) => {
            this.availableWords.push({
              ...item,
              success: null,
              groupIndex: index,
              used: false,
              index: i
            })

            this.answers[index].items.push({
              id: '',
              image: '',
              name: ''
            })
            this.results[index].push(null)
          })
        })
      })
    },
    correctAnswer () {
      this.answers.forEach((group, index) => {
        this.$set(this.answers, index, {
          ...this.answers[index],
          items: this.task.task.categories[index].category_items.map((item, i) => {
            this.setPositions(item, index, i)
            const word = this.availableWords.find(word => word.id === item.id)
            this.$set(word, 'success', true)
            return item
          })
        })
        this.$set(this.groups, index, {
          ...this.groups[index],
          items: this.task.task.categories[index].category_items.map(item => {
            return {
              id: '',
              image: '',
              name: '',
            }
          })
        })
        this.results = this.results.map(result => {
          return result.map(() => true)
        })
      })

    },
    handleDragEnd (e) {
      const futureIndex = this.related.dataset.index

      const relatedObject = this[this.related.dataset.name][this.related.dataset.categoryIndex].items
      const draggedObject = this[this.dragged.dataset.name][this.dragged.dataset.categoryIndex].items

      const tempData = relatedObject[futureIndex]
      const draggedData = draggedObject[this.dragged.dataset.index]

      this.$set(relatedObject, futureIndex, draggedData)
      this.$set(draggedObject, this.dragged.dataset.index, tempData)

      this.setPositions(draggedData, this.related.dataset.categoryIndex, futureIndex)
      this.setPositions(tempData, this.dragged.dataset.categoryIndex, this.dragged.dataset.index)
    },

    setPositions (data, groupIndex, index) {
      if (!data.id) return false

      this.$set(this.positions, data.id, {
        groupIndex,
        index,
      })
    },
    handleMove (e) {
      this.onScroll(e.originalEvent.x, e.originalEvent.y)
      this.dragged = e.dragged
      this.related = e.related
      this.draggedContext = e.draggedContext
      this.relatedContext = e.relatedContext
      return false
    },
    getRandomIntInclusive (min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min //Максимум и минимум включаются
    },
    missTask () {
      this.missingTask({ task_id: this.$route.params.taskId })
    },
    categorizeCheck () {
      this.missCategorizeTask({
        categorize: this.answers,
        ...this.checkParams()
      }).then(res => {
        this.results = res.data.results
        this.results.forEach((result, i) => {
          result.forEach((status, j) => {
            const item = this.answers[i].items[j]

            if (item && this.positions[item.id]) {
              const word = this.availableWords.find(word => word.id === item.id)
              this.$set(word, 'success', status)
            }
          })
        })
        if (res.data && res.data.answer) {
          this.setTaskAnswerModal(res.data).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        } else {
          this.setTaskAnswerModal(false).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        }
      })
    },
    getMax (items, key) {
      let max = 0
      items.forEach(item => {
        if (max < item[key].length) {
          max = item[key].length
        }
      })
      return max
    },
    onGroupsChange (e) {
      this.resetDrag = false
      this.groups = e
      this.resetDrag = true
    }
  },
}
</script>

<style lang="scss">
@import "../../../../../scss/variables";

.auth-user .main-content {
  padding-left: 20px !important;
  padding-right: 32px !important;

  @media (min-width: $md) {
    padding-left: 39px !important;
    padding-right: 44px !important;
  }

  @media (min-width: $lg) {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
}
.md-zoom{
  @media (min-width: $lg) {
    display: none;
  }
}
.columns-name {
  @media (max-width: 767px) {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.mr-160 {
  margin-right: 160px;
}

.margin {
  margin-bottom: 10px;

  @media (min-width: $md) {
    &:not(:nth-child(3n+3)) {
      margin: 0 10px 10px 0;
    }
  }

  @media (min-width: 998px) {
    &:not(:nth-child(4n+4)) {
      margin: 0 10px 10px 0;
    }

    &:nth-child(4n+4) {
      margin: 0;
    }
  }

  @media (min-width: $lg) {
    &:nth-child(2n+1) {
      margin: 0 10px 10px 0;
    }

    &:not(:nth-child(2n+1)) {
      margin: 0;
    }
  }
}

.categorize {
  padding-left: 0 !important;
  padding-bottom: 0 !important;

  &-container {
    flex-direction: column;

    @media (min-width: $lg) {
      flex-direction: row;
    }
  }

  &-width {

    @media (min-width: $lg) {
      width: 390px;
    }

    @media (min-width: $xl) {
      width: 450px;
    }
  }

  &-answers {

    @media (min-width: $md) {
      margin-top: 20px;

      &.answers-images{
        width: 320px;
      }
    }

    @media (min-width: $lg) {
      width: 590px;

      &.answers-images{
        width: 470px;
      }
    }

    @media (min-width: $xl) {
      width: 680px;

      &.answers-images{
        width: 560px;
      }
    }
  }

  &-answer {
    margin-bottom: 10px;

    @media (min-width: $md) {
      &:not(:nth-child(3n+3)) {
        margin: 0 10px 10px 0;
      }
    }

    @media (min-width: 998px) {
      &:not(:nth-child(4n+4)) {
        margin: 0 10px 10px 0;
      }

      &:nth-child(4n+4) {
        margin: 0;
      }
    }

    @media (min-width: $lg) {
      &:not(:nth-child(3n+3)) {
        margin: 0 10px 10px 0;
      }

      &:nth-child(3n+3) {
        margin: 0;
      }
    }

  }

  &-image-answer {
    margin: 0 20px 20px 0;

    @media (min-width: $md) {
      &:not(:nth-child(3n+3)) {
        margin: 0 10px 10px 0;
      }

      &:nth-child(3n+3) {
        margin: 0;
      }
    }
  }

  &-container-images {
    @media (max-width: 767px) {
      flex-direction: column;
    }
    .categorize-wrapper {
      margin-right: 160px;

      @media (min-width: $md) {
        margin-right: 40px;
      }

      @media (min-width: 778px) {
        margin-right: 50px;
      }

      @media (min-width: $lg) {
        margin-right: 70px;
      }

      @media (min-width: $xl) {
        margin-right: 270px;
      }
    }
  }

  &-container {
    .categorize-wrapper {
      margin-right: 160px;

      @media (min-width: $md) {
        margin-right: 0;
      }

      @media (min-width: $lg) {
        margin-right: 70px;
      }

      @media (min-width: $xl) {
        margin-right: 270px;
      }
    }
  }

  &-wrapper {
    margin-right: 160px;

    @media (min-width: $md) {
      margin-right: 0;
    }

    @media (min-width: $lg) {
      margin-right: 70px;
    }

    @media (min-width: $xl) {
      margin-right: 270px;
    }
  }

  &-images {
    @media (min-width: $md) {
      width: 320px;
    }

    @media (min-width: $lg) {
      width: 470px;
    }

    @media (min-width: $xl) {
      width: 560px;
    }
  }

  &-list {
    border-bottom: 1px solid #EB0C08;
    padding-bottom: 58px;
  }
}


.list-group-item div {
  width: 300px;
  background: #E7EAFD;
  border-radius: 25px;
  padding: 10px 10px 16px 10px;
}

.list-group-item p {
  margin-top: 86px;
  margin-bottom: 0;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #000000;
}

.list-group-item {
  border: none;
}

.drag-inner-list {
  list-style: none;
  padding: 0;
}

.drag-image {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.drag-item {
  position: relative;
}

.mw-500 {
  min-width: 500px;
}

.note-image {
  div {
    padding: 20px 20px 20px 20px;
  }

  p {
    margin-top: 0;
  }
}
</style>
