<template>
  <div class="personal-details course">
    <div class="tabs-title mb-4">
      Доступные курсы
    </div>
    <div class="back-to">
      <img src="~@/assets/images/profile/back-icon.svg" alt="">
      <span>НАЗАД КО ВСЕМ БЕСПЛАТНЫМ УРОКАМ</span>
    </div>
    <div class="top-details">
      <div class="title">
        <img src="~@/assets/images/flags/de.png" alt="">
        <div class="text">
          <div>Курс немецкого языка</div>
          <span>Уроков в курсе: 2</span>
        </div>
      </div>
      <div class="action d-flex align-items-center">
        <div class="price">5 000 руб</div>
        <button type="button" class="buy-course-btn">купить курс</button>
      </div>
    </div>
    <div class="course-content">
      <div class="video">
        <video width="100%" height="380" controls>
          <source src="https://www.youtube.com/watch?v=FlRwssZYRM0" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <div class="action">
          <button type="button" class="buy-course-btn">
            <span>ПРОЙТИ БЕСПЛАТНЫЙ УРОК</span>
          </button>
        </div>
      </div>
      <div class="description">
        <div class="course-block-container-description-block-description">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis faucibus mauris ac sagittis tincidunt.
            Nulla pharetra et mi in tincidunt. Nunc eleifend, ex sed elementum venenatis, arcu sapien interdum magna,
            non feugiat sem ante id elit. Mauris eget ultricies neque, elementum mollis felis. Morbi est elit, vestibulum
            eu molestie id, suscipit sodales massa. Sed semper ligula nibh. Curabitur sit amet nibh vel ligula posuere
            malesuada vitae at odio. Sed ac ante elit. Nullam vitae luctus nisi, ac consequat justo. Ut accumsan purus
            non tellus mattis pharetra sit amet in enim. Nullam a urna eu nisl semper porta a sed nisi. Mauris libero arcu,
            egestas at congue vel, pretium ac sapien. Nam rhoncus sem ac augue lobortis laoreet. Morbi sit amet nunc vel
            felis tempor accumsan. Vestibulum a diam sed ipsum tincidunt congue pretium non libero. Mauris dictum
            porttitor libero ut cursus.
          </p>
          <p>
            Morbi sollicitudin id tellus sed rhoncus. Praesent vulputate dui purus, sit amet sagittis massa mollis ac.
            Suspendisse eget ultricies lorem, sodales congue massa. Ut sed purus urna. Aliquam bibendum erat nec efficitur
            ullamcorper. Cras et augue sodales, pulvinar dolor blandit, pulvinar dui. Etiam et gravida velit, in molestie
            dolor.
          </p>
          <p>
            Aenean laoreet tempus purus id scelerisque. Vestibulum ornare, lorem dignissim viverra tempus, lorem metus
            suscipit mauris, quis iaculis mauris massa eget risus. Vestibulum iaculis elit sit amet justo suscipit varius.
            Quisque rutrum felis dolor, in congue tortor suscipit et. Pellentesque ornare justo non consequat malesuada.
            Donec ac lobortis nisi. Sed dapibus quam in nulla dignissim interdum. Morbi non turpis quis risus gravida
            ultricies.
          </p>
        </div>
      </div>
    </div>
    <div class="course-block-container course-block-container-bottom">
      <div class="course-block-container-course-prices">
        <div class="course-title">Пакеты и Цены</div>
        <div class="course-subtitle">Languages to Go предлагает обучния</div>
        <div class="table d-flex" :class="{'mobile-invisible': is_mobile}" v-if="!is_mobile">
          <div class="table-left-header">
            <div>Доступ к учебным материалам курса</div>
            <div>Возможность продления доступа к курсу</div>
            <div>Автоматическая проверка заданий</div>
            <div>Возможность заморозки</div>
            <div>Доступ к самостоятельной работе</div>
            <div>Доступ ко всем дополнительным материалам</div>
            <div>Доступ к словарю</div>
            <div>Доступ к разделу «Грамматика»</div>
            <div>Доступ к заметкам</div>
            <div>Доступ к внутреннему чату курса</div>
            <div>Обратная связь от экспертов школы</div>
            <div>Доступ к группам на ФБ и в мессенджерах</div>
            <div>Возможность «апгрейда» пакета</div>
            <div>Сохранение доступа к материалам при покупке следующего курса по одному языку</div>
            <div>Скидка на любую следующую покупку в школе для себя или члена семьи</div>
            <div>Любой дополнительный курс в подарок (на выбор) в течение 6 месяцев</div>
            <div>Индивидуальная консультация с экспертом</div>
            <div class="price">Цена, руб.</div>
          </div>
          <div class="table-content">
            <div class="tariff-name">«Базовый»</div>
            <div> дней</div>
            <div> руб. на  месяцев</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div class="price">
              <p>10 900</p>
              <p>(121 руб./день)</p>
            </div>
            <div class="button-group">
              <button type="button">купить курс</button>
            </div>
          </div>
          <div class="table-content">
            <div class="tariff-name">«Серебряный»</div>
            <div> дней</div>
            <div> руб. на  месяцев</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div class="price">
              <p>10 900</p>
              <p>(121 руб./день)</p>
            </div>
            <div class="button-group">
              <button type="button">купить курс</button>
            </div>
          </div>
          <div class="table-content">
            <div class="tariff-name">«Золотой»</div>
            <div> дней</div>
            <div> руб. на  месяцев</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div>+</div>
            <div class="price">
              <p>10 900</p>
              <p>(121 руб./день)</p>
            </div>
            <div class="button-group">
              <button type="button">купить курс</button>
            </div>
          </div>
        </div>
        <div :class="{'mobile-accordion': is_mobile}" v-if="is_mobile">
          <div class="accordion" role="tablist">
            <div class="mb-2">
              <div role="tab">
                <b-button block v-b-toggle.accordion-1 class="accordion-btn">
                  <template>
                    <span>«Базовый»</span>
                  </template>
                </b-button>
              </div>
              <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <div class="item">
                  <div>Доступ к учебным материалам курса</div>
                  <span>90 дней</span>
                </div>
                <div class="item">
                  <div>Возможность продления доступа к курсу</div>
                  <span>+</span>
                </div>
                <div class="item">
                  <div>Автоматическая проверка заданий</div>
                  <span>-</span>
                </div>
                <div class="item">
                  <div>Доступ к словарю</div>
                  <span>-</span>
                </div>
              </b-collapse>
              <div class="price">
                <span>Цена, руб.</span>
                <div>
                  <p>34 900 </p>
                  <p>(193 руб./день)</p>
                </div>
              </div>
              <div class="button-group">
                <button type="button">купить курс</button>
              </div>
            </div>
            <div class="mb-2">
              <div role="tab">
                <b-button block v-b-toggle.accordion-2 class="accordion-btn">
                  <template>
                    <span>«Серебряный»</span>
                  </template>
                </b-button>
              </div>
              <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <div class="item">
                  <div>Доступ к учебным материалам курса</div>
                  <span>90 дней</span>
                </div>
                <div class="item">
                  <div>Возможность продления доступа к курсу</div>
                  <span>+</span>
                </div>
                <div class="item">
                  <div>Автоматическая проверка заданий</div>
                  <span>-</span>
                </div>
                <div class="item">
                  <div>Доступ к словарю</div>
                  <span>-</span>
                </div>
              </b-collapse>
              <div class="price">
                <span>Цена, руб.</span>
                <div>
                  <p>34 900 </p>
                  <p>(193 руб./день)</p>
                </div>
              </div>
              <div class="button-group">
                <button type="button">купить курс</button>
              </div>
            </div>
            <div class="mb-2">
              <div role="tab">
                <b-button block v-b-toggle.accordion-3 class="accordion-btn">
                  <template>
                    <span>«Золотой»</span>
                  </template>
                </b-button>
              </div>
              <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
                <div class="item">
                  <div>Доступ к учебным материалам курса</div>
                  <span>90 дней</span>
                </div>
                <div class="item">
                  <div>Возможность продления доступа к курсу</div>
                  <span>+</span>
                </div>
                <div class="item">
                  <div>Автоматическая проверка заданий</div>
                  <span>-</span>
                </div>
                <div class="item">
                  <div>Доступ к словарю</div>
                  <span>-</span>
                </div>
              </b-collapse>
              <div class="price">
                <span>Цена, руб.</span>
                <div>
                  <p>34 900 </p>
                  <p>(193 руб./день)</p>
                </div>
              </div>
              <div class="button-group">
                <button type="button">купить курс</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvailableCourseDetails',
  computed: {
    is_mobile () {
      const isMobile = window.matchMedia('only screen and (max-width: 576px)')
      return !!isMobile.matches
    }
  }
}
</script>
