import { http } from '../../../api'

const lesson = {
  namespaced: true,
  state: {
    lessons: {},
    lesson: {},
    pagination: {},
    lessonsTest: [],
    availableLessons: [],
  },
  actions: {
    getLessons: ({ commit }, params) => {
      commit('loading/setLoading', true, { root: true })
      const q = new URLSearchParams(params)
      return http.get('/lessons?' + q.toString()).then(res => {
        commit('setLessons', res.data)
        return res.data
      }).finally(() => {
        commit('loading/setLoading', false, { root: true })
      })
    },
    getAvailableLessons ({ commit }, params) {
      commit('loading/setLoading', true, { root: true })
      const q = new URLSearchParams(params)
      return http.get('/lessons/available?' + q.toString()).then(res => {
        commit('setAvailableLessons', res.data)
        return res.data
      }).finally(() => {
        commit('loading/setLoading', false, { root: true })
      })
    },
    getLessonFree: ({ commit }, params) => {
      return http.get('/free-lesson/' + params.id).then(res => {
        commit('setLesson', res.data)
      })
    },
    getLessonsTest ({ commit }, params) {
      commit('loading/setLoading', true, { root: true })
      return http.get('getLessonsTest', { params }).then(res => {
        commit('setLessonsTest', res.data)
      }).finally(() => {
        commit('loading/setLoading', false, { root: true })
      })
    }
  },
  mutations: {
    setLessons (state, data) {
      state.lessons = data.data
      state.pagination = data.pagination
    },
    setLesson (state, data) {
      state.lesson = data.data
    },
    setLessonsTest (state, data) {
      state.lessonsTest = data
    },
    setAvailableLessons(state, data){
      state.availableLessons = data.data
    }
  },
  getters: {
    lessons: state => state.lessons,
    lesson: state => state.lesson,
    pagination: state => state.pagination,
    lessonsTest: state => state.lessonsTest,
    availableLessons: state => state.availableLessons,
  }
}

export default lesson
