<template>
  <div class="w-100">
    <bread-crumbs :pages="pages"/>
    <div class="payments page-container">
      <div class="benefits row">
        <div class="benefits-container col-12">
          <div class="benefits-container-title">Условия оплаты</div>
          <div class="benefits-container-subtitle">Выбирайте удобный способ оплаты для вас</div>
          <div class="benefits-container-card-group row" v-if="paymentMethodList && paymentMethodList.length">
            <div class="col-6 benefits-container-content" v-for="(payment, index) in paymentMethodList" :key="index">
              <div class="benefits-container-card-group-card">
                <div class="benefits-container-card-group-card-image">
                  <img :src="payment.page_image" :alt="payment.name[$i18n.locale]">
                </div>
                <div class="benefits-container-card-group-card-info-block">
                  <div class="benefits-container-card-group-card-info-block-title">{{payment.name[$i18n.locale]}}</div>
                  <div class="benefits-container-card-group-card-info-block-text">{{payment.description[$i18n.locale]}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BreadCrumbs from '../../components/BreadCrumbs'
import { mapState } from 'vuex'
export default {
  name: 'Payments',
  components: {
    BreadCrumbs
  },
  data () {
    return {
      pages: [
        { path: '/', rout: 'Главная', active: false },
        { path: '', rout: 'Условия оплаты', active: true }
      ]
    }
  },
  computed: {
    ...mapState({
      paymentMethodList: state => state.paymentMethod.paymentMethodList
    })
  },
  mounted () {
    document.title = 'Условия оплаты'
  }
}
</script>
