<template>

</template>
<script>

export default {
  methods: {
    log(e){
      console.log('e', e)
    }
  },
  mounted () {
    this.$peer.on('open', id => {
      console.log('own id '+id)
      this.ownId = id

      navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      }).then(stream => {

    })

    socket.emit('join-room', this.$route.params.id, id)
  })



  }
};
</script>
<style>
.video-container {
  width: 500px;
  height: 380px;
  margin: 8px auto;
  border: 3px solid #000;
  position: relative;
  box-shadow: 1px 1px 1px #9e9e9e;
}
.video-here {
  width: 130px;
  position: absolute;
  left: 10px;
  bottom: 16px;
  border: 1px solid #000;
  border-radius: 2px;
  z-index: 2;
}
.video-there {
  width: 100%;
  height: 100%;
  z-index: 1;
}
.text-right {
  text-align: right;
}
</style>
