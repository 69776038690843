import { http } from '../../../api'

const course = {
  namespaced: true,
  state: {
    menuCourses: [],
    allCoursesList: [],
    nextTask: {},
    missingTasks: [],
    lessonTasks: [],
    boughtCoursesList: [],
    subscriptionCoursesList: [],
    course: {},
    lesson: {},
    pagination: {},
    boughtCourse: {},
    courseTeachingLanguage: {},
    courseVideoList: [],
    additionalCourses: [],
    courses: [],
    additionalCoursesCount: 0
  },
  actions: {
    getMenuCourses: ({ commit }) => {
      return http.get('/menu-courses').then(res => {
        commit('setMenuCourses', res.data)
      })
    },
    getCourses({ commit }, params){
      return http.get('/courses/get', {params}).then(res => {
        commit('setCourses', res.data.data)
        commit('setPagination', res.data.pagination)
      })
    },
    getCourseVideo: ({ commit }, params) => {
      commit('loading/setLoading', true, {root:true})
      const q = new URLSearchParams(params)
      return http.get('/course-video/list?' + q.toString()).then(res => {
        commit('setCourseVideo', res.data)
      }).finally(() => {
        commit('loading/setLoading', false, {root:true})
      })
    },
    getAllCoursesList: ({ commit }, params) => {
      const q = new URLSearchParams(params)
      return http.get('/course?' + q.toString()).then(res => {
        commit('setAllCoursesList', res.data)
      })
    },
    getAvailableCourses: ({ commit }, params) => {
      const q = new URLSearchParams(params)
      return http.get('/available-course?' + q.toString()).then(res => {
        commit('setAllCoursesList', res.data)
        return res.data
      })
    },
    getBoughtCoursesList: ({ commit }) => {
      return http.get('/course/bought').then(res => {
        commit('setBoughtCoursesList', res.data)
      })
    },
    getSubscriptionList: ({ commit }) => {
      return http.get('/course/subscription').then(res => {
        commit('setSubscriptionList', res.data)
      })
    },
    updateExpiryDate: ({ commit }, params) => {
      return http.post('/course/subscription-update-expiry', params).then(res => {
        commit('setSubscriptionList', res.data)
      })
    },
    getBoughtCourse: ({ commit }, params) => {
      return http.get('/course-buy/' + params + '/module').then(res => {
        commit('setBoughtCourse', res.data)
      })
    },
    getCourseTeachingLanguage: ({ commit }, params) => {
      return http.get('/course-teaching-language/' + params.id).then(res => {
        commit('setCourseTeachingLanguage', res.data)
      })
    },
    getCourse: ({ commit }, {id, params}) => {
      return http.get('/course/' + id, {params}).then(res => {
        commit('setCourse', res.data)
      })
    },
    getLesson: ({ commit }, params) => {
      return http.get('/lesson/' + params.id).then(res => {
        commit('setLesson', res.data)
      })
    },
    buyCourse: ({ commit }, params) => {
      return http.post('buy-course', params).then(res => {
        commit('setCourse', res.data)
        return res.data
      })
    },
    getAdditionalCourses({commit},params){
      return http.get('courses/additional', {params}).then(res =>{
          commit('setAdditionalCourses', res.data)
          commit('setAdditionalCoursesCount', res.data.count)
      })
    },
    taskMissing: ({ commit }, params) => {
      console.log('missTasks', params)
      commit('missTasks', params)
    },
    missingTask: ({ commit }, params) => {
      commit('missTask', params)
    },
    getMissingTask: ({ commit }, params) => {
      commit('setMissTask', params)
    },
    setCourseFreeLesson({commit}, {id}) {
      return http.post(`course/${id}/setFreeLesson`).then(res => {
        return res.data
      })
    }
  },
  mutations: {
    setMenuCourses (state, data) {
      state.menuCourses = data.data
    },
    setAdditionalCoursesCount (state, data) {
      state.additionalCoursesCount = data
    },
    setPagination(state, data){
      state.pagination = data
    },
    setCourses(state,data){
      state.courses = data
    },
    setCourseTeachingLanguage (state, data) {
      state.courseTeachingLanguage = data.data
    },
    setCourseVideo (state, data) {
      state.courseVideoList = data.data
      state.pagination = data.pagination
    },
    setAllCoursesList (state, data) {
      state.allCoursesList = data.data
      state.pagination = data.pagination
    },
    setBoughtCoursesList (state, data) {
      state.boughtCoursesList = data.data
    },
    setSubscriptionList (state, data) {
      state.subscriptionCoursesList = data.data
    },
    setCourse (state, data) {
      state.course = data.data
    },
    setLesson (state, data) {
      state.lesson = data.data
    },
    setBoughtCourse (state, data) {
      state.boughtCourse = data.data
    },
    setAdditionalCourses(state, data){
      state.additionalCourses = data.data
    },
    missTask (state, data) {
      if (localStorage.getItem('missingTasks')) {
        state.missingTasks = JSON.parse(localStorage.getItem('missingTasks'))
      }
      for (let i = 0; i < state.missingTasks.length; i++) {
        if (state.missingTasks[i].id === data.task_id) {
          const a = state.missingTasks.splice(i, 1)
          console.log('missingTasks',state.missingTasks )
          console.log('a',a)
          state.missingTasks.push(a[0])
          // if (state.missingTasks[i + 1]) {
          //   state.nextTask = {
          //     id: state.missingTasks[i + 1].id,
          //     task_type_id: state.missingTasks[i + 1].task_type_id
          //   }
          // }
        }
      }
      if (localStorage.getItem('missingTasks')) {
        localStorage.removeItem('missingTasks')
      }
      localStorage.setItem('missingTasks', JSON.stringify(state.missingTasks))
    },
    setMissTask (state, data) {
      if (localStorage.getItem('missingTasks')) {
        state.missingTasks = JSON.parse(localStorage.getItem('missingTasks'))
      }
      state.nextTask = {}
      for (let i = 0; i < state.missingTasks.length; i++) {
        if (state.missingTasks[i].id === data) {
          if (state.missingTasks[i + 1]) {
            state.nextTask = {
              id: state.missingTasks[i + 1].id,
              task_type_id: state.missingTasks[i + 1].task_type_id,
              task_count: state.missingTasks.length,
              task_number: i + 1
            }
          } else {
            state.nextTask = {
              id: false,
              task_count: state.missingTasks.length,
              task_number: i + 1
            }
          }
        }
      }
    },
    missTasks (state, data) {
      for (let i = 0; i < data.data.length; i++) {
        if (+data.data[i].id === data.task.id) {
          const a = data.data.splice(i, 1)
          data.data.unshift(a[0])
        }
      }
      if (localStorage.getItem('missingTasks')) {
        localStorage.removeItem('missingTasks')
      }
      localStorage.setItem('missingTasks', JSON.stringify(data.data))
    }
  },
  getters: {
    menuCourses: state => state.menuCourses,
    allCoursesList: state => state.allCoursesList,
    boughtCoursesList: state => state.boughtCoursesList,
    subscriptionCoursesList: state => state.subscriptionCoursesList,
    courseTeachingLanguage: state => state.courseTeachingLanguage,
    course: state => state.course,
    lesson: state => state.lesson,
    boughtCourse: state => state.boughtCourse,
    nextTask: state => state.nextTask,
    additionalCourses: state => state.additionalCourses,
    courses: state => state.courses,
    additionalCoursesCount: state => state.additionalCoursesCount
  }
}

export default course
