<template>
  <div class="personal-details free-lesson" ref="content" @contextmenu="click">
    <div class="free-lesson-title" v-if="boughtCourse">
      <img v-if="boughtCourse.teaching_language" :src="boughtCourse.teaching_language.flag" alt="flag">
      <div class="text" v-if="boughtCourse.name">
        {{ boughtCourse.name[$i18n.locale] }}
        <span>{{ boughtCourse.teaching_language.name[$i18n.locale] }}</span>
      </div>
    </div>
    <div class="tabs-info">
      После каждого урока вам нужно будет выполнить задания
    </div>
    <Loading v-if="loading"/>
    <div v-else class="tasks-content">
      <div class="item" v-for="(module, index) in boughtCourse.course_modules" :key="index">
        <b-card no-body>
          <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle="'accordion-' + module.id" class="task-item"
                      :class="module.status_module === 'inactive' ? 'incomplete' : 'completed'">
              <div class="left">
                <div class="count" v-if="module.status_module === 'in_progress'">
                  <span>{{ index + 1 }}</span>
                </div>

                <div class="circle" v-if="module.status_module === 'done'">
                  <div>
                    <img src="../../../../assets/images/icons/galochka-big.svg" alt="">
                  </div>
                </div>

                <div class="circle" v-if="module.status_module === 'inactive'">
                  <div>
                    <img src="../../../../assets/images/icons/lock-icon.svg" alt="">
                  </div>
                </div>

                <div class="info">
                  <div class="lesson-name" v-if="module.name">
                    <span>Модуль {{ index + 1 }}:</span>
                    {{ module.name[$i18n.locale] }}
                  </div>
                  <span class="module-description" v-if="module.description"
                        v-html="module.description[$i18n.locale]"></span>
                </div>
              </div>
              <div class="right" v-if="module.status_module === 'inactive'">
                <div class="progress-bar">
                  <div class="completed-percent" style="width: 0"></div>
                </div>
                <div class="details">
                  <span class="percent">0%</span>
                </div>
              </div>
              <div class="right" v-else>
                <div class="progress-bar">
                  <div class="completed-percent"
                       :style="'width:' + (module.task_points ? Math.floor((module.task_points_done * 100) / module.task_points) : 0) + '%'"></div>
                </div>
                <div class="details">
                  <span
                    class="percent">{{
                      module.task_points ? Math.floor((module.task_points_done * 100) / module.task_points) : 0
                    }} %</span>
                  <span class="points">{{ modulePoint(module) }} баллов</span>
                </div>
              </div>

              <img src="../../../../assets/images/icons/arrow-bottom.svg" class="arrow" alt="">
            </b-button>
          </b-card-header>
        </b-card>
        <b-collapse :id="'accordion-' + module.id" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <form :id="'lessonForm' + lesson.id" class="lesson-item" v-for="(lesson , i) in module.lessons" :key="i"
                  :class="lesson.status_lesson === 'inactive' ? 'lesson-item-lock' : ''">
              <router-link class="lesson-left cursor-pointer" tag="div"
                           :to="lesson.status_lesson === 'in_progress' || lesson.status_lesson === 'done' ? '/profile/course/'+ boughtCourse.id + '/lesson/' + lesson.id + `?number=${i + 1}` : ''">
                <div class="circle circle-lock" v-if="lesson.status_lesson === 'inactive'">
                  <img src="../../../../assets/images/icons/lock-icon.svg" alt="">
                </div>
                <div class="circle" v-if="lesson.status_lesson === 'done'">
                  <img src="../../../../assets/images/icons/galochka-big.svg" alt="">
                </div>
                <div class="circle circle-outline" v-if="lesson.status_lesson === 'in_progress'">
                  <img src="../../../../assets/images/icons/hourglass.svg" alt="">
                </div>
                <div class="title-block">
                  <div class="lesson-number">Урок {{ i + 1 }}:</div>
                  <div class="lesson-title">{{ lesson.name[$i18n.locale] }}</div>
                </div>
              </router-link>
              <div class="lesson-right">
                <div class="points">{{ lesson.user_lesson.point }} баллов</div>
                <div class="lessons d-flex">
                  <div v-for="(block, j) in lesson.lesson_blocks" :key="j" class="d-flex lesson-item-block">
                    <div
                      v-if="block.show === 1"
                      class="lesson-task"
                      :class="`${userShowedLessonBlocks.findIndex(value => value.id === block.id) !== -1 ? 'showed':''}`"
                      @click="openBlock(block, lesson)">
                      <img src="../../../../assets/images/camera.png" alt="block"/>
                    </div>
                    <div v-for="(task, k) in block.tasks" :key="k"
                         @click="openTask(lesson, task, module,index,i)"
                         :class="task.user_task  && task.user_task.status === 'done' ? 'showed' : ''"
                         class="lesson-task">
                      <img src="../../../../assets/images/medal.png" alt="lesson"/>
                      <input type="hidden" :name="task.id" v-model="task.task_type_id">
                    </div>
                    <div
                      v-if="module.lessons.length === (i + 1) && lesson.lesson_blocks.length === (j + 1)"
                      class="lesson-task"
                      @click="openTest(module, $route.params.id)"
                      :class="module.module_user.test === 1 ? 'showed' : ''"
                    >
                      <img src="../../../../assets/images/test-list.png" alt="test"/>
                    </div>
                    <div
                      v-if="boughtCourse.course_modules.length === (index + 1) && module.lessons.length === (i + 1) && lesson.lesson_blocks.length === (j + 1)"
                      class="lesson-task"
                      @click="openCourseTest(boughtCourse)"
                      :class="boughtCourse.user.pivot.test === 1 ? 'showed' : ''"
                    >
                      <img src="../../../../assets/images/test-list.png" alt="test"/>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </b-card-body>
        </b-collapse>
      </div>
    </div>
    <ul id="right-click-menu" tabindex="-1000" ref="right" v-if="viewMenu" v-click-outside="closeMenu"
        v-on:blur="closeMenu" :style="'top:' + top + ';left:' + left">
      <li>First list item</li>
      <li>Second list item</li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import vClickOutside from 'v-click-outside'
import TestGenerateMixin from '../../../../mixins/TestGenerateMixin'
import Loading from '../../../components/Loading'

export default {
  name: 'CourseLessons',
  mixins: [TestGenerateMixin],
  components: { Loading },
  data () {
    return {
      x: [],
      viewMenu: false,
      top: '0px',
      left: '0px',
      loading: true
    }
  },
  computed: {
    ...mapState({
      boughtCourse: state => state.course.boughtCourse
    }),
    ...mapGetters({
      userShowedLessonBlocks: 'block/userShowedLessonBlocks',
      boughtCoursesList: 'course/boughtCoursesList'
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.fullPath !== from.fullPath) {
      this.loading = true
      this.getCourses(to.params.id)
    }

    next()
  },
  async created () {
    this.loading = true
    if (this.$route.query.key) {
      const data = await this.checkPaidCourse(this.$route.query.key)
      if (data.status === 'success') {
        this.boughtCoursesList.push(data.course)
      }
    }
    this.getCourses(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      getBoughtCourse: 'course/getBoughtCourse',
      taskMissing: 'course/taskMissing',
      checkPaidCourse: 'paymentHistory/checkPaidCourse',
      getUserShowedLessonBlocks: 'block/getUserShowedLessonBlocks'
    }),
    modulePoint (module) {
      let point = 0

      module.lessons.forEach(lesson => {
        point += parseFloat(lesson.user_lesson.point)
      })

      return point.toFixed(1)
    },
    getCourses (courseId) {
      this.getBoughtCourse(courseId).finally(() => {
        this.loading = false
      })
      this.getUserShowedLessonBlocks({ course_id: courseId })
    },
    generateParams (block, lesson, module) {
      if (lesson.status_lesson === 'inactive') {
        return { status: 'inactive' }
      }

      if (block.tasks.length) {
        let task = block.tasks.filter(task => task.user_task.status !== 'done')
        if (task.length === 0) {
          return false
        }
        task = task[0]
        return {
          task_type: task.task_type_id,
          task_id: task.id,
          lesson_id: lesson.id,
          course: this.$route.params.id,
          module: module
        }
      }
    },
    openTask ({ id: lessonId }, { task_type_id: taskTypeId, id: taskId }, module, index, i) {
      const query = {
        lesson: lessonId,
        course: this.$route.params.id,
        module: this.boughtCourse.course_modules[index].id
      }
      const nextLesson = module.lessons[i + 1]?.id

      if (nextLesson) {
        query.next_lesson = nextLesson
      } else {
        query.next_module = this.boughtCourse.course_modules[index + 1]?.id
        query.next_module_lesson = this.boughtCourse.course_modules[index + 1]?.lessons[0]?.id
      }

      this.$router.push({
        path: `/profile/task/${taskTypeId}/${taskId}`,
        query
      })
    },
    openBlock (block, lesson) {
      if (this.userShowedLessonBlocks.find(value => value.id === block.id) || lesson.user_lesson.status !== 'in_progress') return

      this.$router.push({ name: 'block', params: { blockId: block.id } })
    },
    setMenu (top, left) {
      const largestHeight = this.$refs.content.offsetHeight
      const largestWidth = this.$refs.content.offsetWidth
      if (top > largestHeight) top = top - this.$refs.right.offsetHeight
      if (left > largestWidth) left = left - this.$refs.right.offsetWidth
      this.top = (top - this.$refs.content.offsetParent.offsetTop) + 'px'
      this.left = (left - this.$refs.content.offsetParent.offsetLeft) + 'px'
    },

    closeMenu () {
      this.viewMenu = false
    },
    click (e) {
      if (window.getSelection().toString()) {
        e.preventDefault()
        this.$nextTick(function () {
          this.viewMenu = true
          this.setMenu(e.clientY, e.clientX)
        }.bind(this))
      }
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  }
}
</script>

<style scoped lang="scss">
#right-click-menu {
  background: #FAFAFA;
  border: 1px solid #BDBDBD;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 250px;
  z-index: 999999;
}

#right-click-menu li {
  border-bottom: 1px solid #E0E0E0;
  margin: 0;
  padding: 5px 35px;
}

#right-click-menu li:last-child {
  border-bottom: none;
}

#right-click-menu li:hover {
  background: #1E88E5;
  color: #FAFAFA;
}

.lesson-task {
  &.showed {
    background-color: #D3434C !important;
  }
}
</style>
