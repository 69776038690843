import { http } from '../../../api'

const task = {
  namespaced: true,
  state: {
    task: {},
    taskPresentation: {
      content_output_method: 0, // 0 - list, 1-listCarousel, 2-carousel
      content_output_count: 3
    },
    answerModal: null,
    testTasks: {},
    doneTasks: [],
    tasks: [],
    taskLoading: true,
    try: 0
  },
  actions: {
    getTask: async ({ commit }, params) => {
      commit('setTaskLoading', true)
      return http.get('/task/' + params.id, { params: params.query }).then(res => {
        commit('setTask', res.data)
        commit('nextTaskId/setNextTaskId', res.data.next_task, { root: true })
        commit('setTry', 0)
        commit('setTaskPresentation', res.data.data)
        return res.data
      }).finally(() => {
        commit('setTaskLoading', false)
      })
    },
    getTasks ({ commit }, params) {
      commit('loading/setLoading', true, { root: true })
      return http.get('/getTasks', { params }).then(res => {
        commit('setTasks', res.data)
      }).finally(() => {
        commit('loading/setLoading', false, { root: true })
      })
    },
    generateTestTask: ({ commit }, params) => {
      return http.post('/test-generate', params).then(res => {
        commit('setTestTasks', res.data)
      })
    },
    repeatTask: ({ commit }, params) => {
      console.log(params)
      return http.get('/drop-task', { params }).then(res => {
        return res
      })
    },
    checkGallowsLetter: ({ commit }, params) => {
      return http.post('/check-gallows-task-letter', params).then(res => {
        commit('setTry', res.data.try)
        return res
      })
    },
    checkFieldDreamsLetter: ({ commit }, params) => {
      return http.post('/check-field-dreams-task-letter', params).then(res => {
        commit('setTry', res.data.try)
        return res
      })
    },
    checkTestTask: ({ commit }, params) => {
      return http.post('/check-test-task-letter', params).then(res => {
        commit('setTry', res.data.try)
        return res
      })
    },
    missAlignWordsTask: ({ commit }, params) => {
      return http.post('/check-test-align-words-letter', params).then(res => {
        commit('setTry', res.data.try)
        return res
      })
    },
    missingWordTask: ({ commit }, params) => {
      return http.post('/check-test-missing-words-letter', params).then(res => {
        commit('setTry', res.data.try)
        return res
      })
    },
    // missingTask: ({ commit }, params) => {
    //   commit('missTask', params)
    // },
    missCategorizeTask: ({ commit }, params) => {
      return http.post('/check-test-categorize-letter', params).then(res => {
        commit('setTry', res.data.try)
        return res
      })
    },
    missTyposTask: ({ commit }, params) => {
      return http.post('/check-test-typos-letter', params).then(res => {
        commit('setTry', res.data.try)
        return res
      })
    },
    missComposeTextTask: ({ commit }, params) => {
      return http.post('/check-test-compose-text-letter', params).then(res => {
        commit('setTry', res.data.try)
        return res
      })
    },
    offerTask: ({ commit }, params) => {
      return http.post('/check-task-offer', params).then(res => {
        commit('setTry', res.data.try)
        return res
      })
    },
    checkChooseTranslationTask: ({ commit }, params) => {
      return http.post('/check-test-choose-translation-letter', params).then(res => {
        commit('setTry', res.data.try)
        commit('chooseTranslation', res.data)
        return res
      })
    },
    checkedFindRemember: ({ commit }, params) => {
      return http.post('/check-test-find-remember-letter', params).then(res => {
        commit('setTry', res.data.try)
        return res
      })
    },
    checkWheelFortuneTask: ({ commit }, params) => {
      return http.post('/check-test-wheel-fortune-letter', params).then(res => {
        commit('setTry', res.data.try)
        return res
      })
    },
    checkRepeatTextTask: ({ commit }, params) => {
      return http.post('/check-test-repeat-text-letter', params).then(res => {
        commit('setTry', res.data.try)
        return res
      })
    },
    checkCrosswordTask: ({ commit }, params) => {
      return http.post('/check-crossword-task', params).then(res => {
        commit('setTry', res.data.try)
        return res
      })
    },
    getDoneTasks ({ commit }, courseId) {
      commit('loading/setLoading', true, { root: true })
      return http.get('/getDoneTasks', { params: { course_id: courseId } }).then(res => {
        commit('setDoneTasks', res.data)
      }).finally(() => {
        commit('loading/setLoading', false, { root: true })
      })
    },
    missTestTask: ({ commit }, params) => {
      commit('missTask', params)
    },
    missTask: ({ commit }, params) => {
      commit('missingTask', params)
    },
    missQuestionTask: ({ commit }, params) => {
      commit('missQuestion', params)
    },
    setTaskAnswerModal: ({ commit }, params) => {
      commit('TaskAnswerModal', params)
    }
  },
  mutations: {
    setTry (state, data) {
      if (data !== null) {
        state.try = data
      }
    },
    TaskAnswerModal (state, data) {
      state.answerModal = data
    },
    setTaskLoading (state, data) {
      state.taskLoading = data
    },
    setTasks (state, data) {
      state.tasks = data
    },
    setTask (state, data) {
      state.task = data
    },
    chooseTranslation (state, data) {
      if (data.answer) {
        data.question_id.map(question => {
          state.task.task = state.task.task.filter((item) => item.id !== question.id)
        })
      }
    },
    missTask (state, data) {
      const a = state.task.task.splice(data, 1)
      state.task.task.push(a[0])
    },
    missQuestion (state, data) {
      for (let i = 0; i < state.task.task.length; i++) {
        if (state.task.task[i].id === data) {
          const a = state.task.task.splice(i, 1)
          state.task.task.push(a[0])
        }
      }
    },
    missingTask (state, data) {
      for (let i = 0; i < state.task.task.length; i++) {
        if (state.task.task[i].id === data) {
          const a = state.task.task.splice(i, 1)
          state.task.task.push(a[0])
        }
      }
    },
    setTestTasks (state, data) {
      state.testTasks = data
    },
    setDoneTasks (state, data) {
      state.doneTasks = data
    },
    setTaskPresentation (state, data) {
      state.taskPresentation = {
        content_output_method: data.content_output_method || 0,
        content_output_count: data.content_output_count || 3
      }
    }
  },
  getters: {
    task: state => state.task,
    answerModal: state => state.answerModal,
    testTasks: state => state.testTasks,
    doneTasks: state => state.doneTasks,
    tasks: state => state.tasks,
    taskLoading: state => state.taskLoading,
    try: state => state.try,
    taskPresentation: state => state.taskPresentation
  }
}

export default task
