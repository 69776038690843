<template>
  <div class="faq col-12 px-0">
    <bread-crumbs :pages="pages"/>
    <div class="faq-section page-container">
      <div class="faq-section-title-block">
        <div class="faq-section-title-block-title text-uppercase">Faq</div>
        <div class="faq-section-title-block-subtitle">Часто задаваемые вопросы</div>
      </div>
      <div class="header-buttons btn-group flex-wrap">
        <button type="button" class="btn-transparent mb-3" :class="activeClass === 'All' ? 'btn-transparent-active' : ''"
                @click="filterFaqByCategory('All')">Все
        </button>
        <button v-for="(faqCategory, i) in faqCategories" :key="i" type="button" class="btn-transparent mb-3"
                :class="activeClass === faqCategory.id ? 'btn-transparent-active ' : ''"
                @click="filterFaqByCategory(faqCategory.id)">{{ faqCategory.name[$i18n.locale] }}
        </button>
      </div>
      <div class="faq-questions-section">
        <div class="faq-questions-section-question accordion" role="tablist">
          <b-card no-body class="faq-questions-section-question-content" v-for="(faq, i) in faqList" :key="i">
            <b-card-header header-tag="header"
                           class="faq-questions-section-question-content-header d-flex justify-content-between"
                           role="tab">
              <b-button block v-b-toggle="faq.id.toString()"
                        class="faq-questions-section-question-content-header-button flex-wrap text-left d-flex justify-content-between align-items-center">
                <div class="question col-10">{{ faq.question[$i18n.locale] }}</div>
                <div class="button-image "><img src="@/assets/images/icons/arrow-down.svg" alt=""></div>
                <b-collapse :id="faq.id.toString()" accordion="my-accordion" role="tabpanel"
                            class="col-12 faq-questions-section-question-content-question-answer">
                  <b-card-body class="question-answer">
                    <div v-html="faq.answer[$i18n.locale].replaceAll('\\n', '<br>')"></div>
                  </b-card-body>
                </b-collapse>
              </b-button>
            </b-card-header>
          </b-card>
        </div>
      </div>
      <div class="leave-request-section d-flex  ">
        <div class="leave-request-section-imageSide">
          <img src="@/assets/images/contacts/support.png" alt="">
        </div>
        <div class="leave-request-section-textInfo">
          <div class="leave-request-section-textInfo-title">Не нашли что искали?</div>
          <div class="leave-request-section-textInfo-subtitle">Оставьте заявку и мы свяжемся с Вами в ближайшее время.
          </div>
          <button class=" btn-purple-outline send-request" @click="openLeaveRequestModal">оставить заявку</button>
        </div>
      </div>
      <b-modal hide-footer hide-header ref="leaveRequest" content-class="leave-request">
        <div class="close-modal" @click.prevent="closeLeaveRequestModal">
          <img src="../../../assets/images/icons/close.svg" alt="Close">
        </div>
        <ValidationObserver tag="form" ref="applicationForm" class="modal-block" @submit.prevent="formSubmit()">
          <div class="modal-title">Оставить заявку</div>
          <div class="modal-subtitle">Не нашли на сайте что искали? Оставьте своии контакты и мы свяжемся с вами в
            ближайшее время
          </div>
          <div class="input-group row p-0 m-0 justify-content-between">
            <div class="input-group-content col-lg-12  p-0 ">
              <ValidationProvider v-slot="{ classes, errors }" rules="required|max:255" tag="div" name="Ваше имя">
                <input type="text" name="Name" :class="classes" v-model="formData.name" placeholder="Ваше имя"
                       class="rounded-input mb-0">
                <div class="text-danger mb-14">{{ errors[0] }}</div>
              </ValidationProvider>
              <ValidationProvider v-slot="{ classes, errors }" rules="required|max:255" tag="div" name="Телефон">
                <input type="tel" name="Phone" :class="classes" v-model="formData.phone_number" placeholder="Телефон"
                       class="rounded-input mb-0">
                <div class="text-danger mb-14">{{ errors[0] }}</div>
              </ValidationProvider>
              <ValidationProvider v-slot="{ classes, errors }" rules="required|max:255" tag="div" name="E-mail">
                <input type="email" name="E-mail" :class="classes" v-model="formData.email" placeholder="E-mail"
                       class="rounded-input mb-0">
                <div class="text-danger mb-14">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
            <ValidationProvider v-slot="{ classes, errors }" rules="required|max:500" tag="div" class="textarea col-lg-12 p-0" name="Ваш вопрос">
              <textarea name="text" placeholder="Ваш вопрос" :class="classes" v-model="formData.text"
                        class="rounded-input mb-0"></textarea>
              <div class="text-danger">{{ errors[0] }}</div>
            </ValidationProvider>
          </div>
          <div class="button-group">
            <div class="checkbox">
              <label for="privacy" class="remember">
                <input type="checkbox" v-model="privacyCheck" id="privacy" name="checkbox">
                <span></span>
                <div>Отправляя заявку, я соглашаюсь с <a target="_blank" href="/privacy-policy">политикой
                  конфиденциальности</a> и <a target="_blank" href="/terms-of-use">пользовательским соглашением</a>
                  сайта
                </div>
              </label>
              <small v-if="errorMessage" class="text-danger font-italic position-absolute d-conte">Это поле обязательно
                к заполнению.</small>
            </div>
            <button type="submit" class="btn-purple">отправить заявку</button>
          </div>
        </ValidationObserver>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BreadCrumbs from '../../components/BreadCrumbs'

export default {
  name: 'Interesting',
  components: {
    BreadCrumbs
  },
  data () {
    return {
      text: 'test',
      currentPage: 1,
      perPage: 1,
      rows: 26,
      activeClass: 'All',
      pages: [
        {
          path: '/',
          rout: 'Главная',
          active: false
        },
        {
          path: '',
          rout: 'Faq',
          active: true
        }
      ],
      formData: {
        phone_number: null,
      },
      privacyCheck: false,
      errorMessage: false
    }
  },
  computed: {
    ...mapState({
      faqCategories: state => state.faqCategory.faqCategories,
      faqList: state => state.faq.faqList
    }),
    phoneNumber(){
     return  this.formData.phone_number
    }
  },
  watch: {
    phoneNumber(newVal){
      this.$set(this.formData, 'phone_number', newVal.replace(/[^+\d]/g, ''))
    }
  },
  created () {
    this.getFaqCategories().catch(() => {
      this.$toasted.error('Что-то пошло не так').goAway(1500)
    })
    this.getFaqList().catch(() => {
      this.$toasted.error('Что-то пошло не так').goAway(1500)
    })
  },
  metaInfo () {
    const title = this.$t('faq.title')
    return {
      title: title,
      meta: [
        {
          name: 'og:title',
          content: title
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      sendApplication: 'faq/sendApplication',
      getFaqCategories: 'faqCategory/getFaqCategories',
      getFaqList: 'faq/getFaqList'
    }),
    openLeaveRequestModal () {
      this.$refs.leaveRequest.show()
      this.$refs.applicationForm.reset()
    },
    closeLeaveRequestModal () {
      this.$refs.leaveRequest.hide()
    },
    filterFaqByCategory (id) {
      if (id === 'All') {
        this.activeClass = 'All'
      } else {
        this.activeClass = id
      }
      this.getFaqList({ category_id: id })
    },
    formSubmit () {
      this.$refs.applicationForm.validate().then(success => {
        if (success) {
          if (!this.privacyCheck) {
            this.errorMessage = true
            return false
          }
          this.sendApplication(this.formData).then(() => {
            this.closeLeaveRequestModal()
            this.$toasted.success('Ваша заявка была отправлена!').goAway(1500)
            this.formData = {}
            this.privacyCheck = false
            this.errorMessage = false
          }).catch(err => {
            if (err.response.status === 400) {
              const data = err.response.data
              const keys = Object.keys(data)
              const msg = data[keys[0]][0]
              this.$toasted.error(this.$t(msg)).goAway(1500)
            } else {
              this.$toasted.error('Что-то пошло не так').goAway(1500)
            }
          })
        } else {
          this.$toasted.error('Что-то пошло не так').goAway(1500)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.faq-section-title-block-subtitle {
  font-weight: bold;
}
.mb-14{
  margin-bottom: 14px;
}
</style>
