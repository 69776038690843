<template>
  <div class="course-list page-container" v-if="allCoursesList.length">
    <div class="course-list__title text-40 text-bold">Курсы для любого уровня</div>

    <div class="course-list__content">
      <div class="course-list__list">
        <ul>
          <li v-for="item in allCoursesList">
            <button class="button button_outline course-list__item" @click="clickCourse(item.id)">
              <div v-if="!!item.teaching_language" class="flag">
                <img :src="item.teaching_language.flag" alt="flag" />
              </div>
              <span class="text-18 text-weight-600">{{ item.name[$i18n.locale] }}</span>
            </button>
          </li>
        </ul>
      </div>

      <div class="course-list__button">
        <button class="button button_outline-primary" @click="clickAllCurses">Все курсы</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { indexRoutes } from '../../../router/routes'

export default {
  name: 'CourseList',
  computed: {
    ...mapState({
      allCoursesList: state => state.course.courses
    })
  },
  created() {
    this.getAllCoursesList({ page: 1, count: 3 })
  },
  methods: {
    ...mapActions({
      getAllCoursesList: 'course/getCourses'
    }),
    clickAllCurses() {
      this.$router.push(indexRoutes.courses_all)
    },
    clickCourse(id) {
      this.$router.push(`${indexRoutes.course}/${id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables/media';
.course-list {
  margin-top: 72px;

  &__title {
    margin-bottom: 27px;
  }

  &__content {
    // display: flex;
    // flex-direction: row;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr auto;
    grid-gap: var(--spacing-16);
    gap: var(--spacing-16);
  }

  &__button {
    .button {
      width: 100%;
      padding: 20px var(--spacing-32);
      border-radius: 16px;
      min-width: 150px;
    }
  }

  &__list {
    overflow: auto;
    ul {
      list-style-type: none;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: var(--spacing-16);
      gap: var(--spacing-16);
      margin-bottom: 0;
      padding-left: 0;
    }
  }

  &__item {
    padding: var(--spacing-8) 32px;
    width: 100%;
    min-height: 65px;
    min-width: 200px;
    max-width: 300px;

    display: grid;
    grid-template-columns: repeat(2, minmax(min-content, max-content));
    align-items: center;
    justify-content: center;
    gap: 16px;
    grid-gap: 16px;

    span {
      flex: 1 1 100%;
    }
    .flag {
      width: 48px;
      height: 48px;
      img {
        border-radius: 100%;
        width: 48px;
        height: 48px;
      }
    }
  }
}

@media (max-width: $media-s) {
  .course-list {
    &__content {
      grid-template-columns: 1fr;
    }
    ul {
      grid-template-columns: 1fr;
    }
    &__item {
      min-width: 0;
      max-width: none;
    }

    &__button {
      .button {
        min-width: none;
      }
    }
  }
}
</style>
