import { http } from '../../../api'

const supportTicket = {
  namespaced: true,
  state: {
    notesList: [],
    notesPagination: {},
    noteItem: {}
  },
  actions: {
    getNotesList: ({ commit }, params) => {
      const q = new URLSearchParams(params)
      return http.get('note?' + q.toString()).then(res => {
        commit('setNotesList', res.data)
      })
    },
    getNoteItem: ({ commit }, params) => {
      return http.get('note/' + params.id).then(res => {
        commit('setNoteItem', res.data)
      })
    },
    newNote: ({ commit }, params) => {
      return http.post('note', params).then(res => {
        commit('setNewNote', res.data)
      })
    },
    updateNote: ({ commit }, params) => {
      return http.put('note/' + params.id, params.data).then(res => {
        commit('setUpdateNotesList', res.data)
      })
    },
    deleteNote: ({ commit }, params) => {
      return http.delete('note/' + params.id).then(res => {
        commit('setDeleteNote', params.id)
      })
    }
  },
  mutations: {
    setNotesList (state, data) {
      state.notesList = data.data
      state.notesPagination = data.pagination
    },
    setDeleteNote (state, id) {
      state.notesList = state.notesList.filter(item => item.id !== id)
    },
    setUpdateNotesList (state, data) {
      state.notesList = state.notesList.map(note => {
        if (note.id === data.data.id) {
          note = data.data
        }
        return note
      })
    },
    setNewNote (state, data) {
      state.notesList.push(data.data)
    },
    setNoteItem (state, data) {
      state.noteItem = data.data
    }
  },
  getters: {
    notesList: state => state.notesList,
    notesPagination: state => state.notesPagination,
    supportTicketItem: state => state.supportTicketItem,
    supportListPagination: state => state.supportListPagination
  }
}

export default supportTicket
