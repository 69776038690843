<template>
  <div>
    <b-modal id="modal-task" ref="taskModal" class="done-task" hide-footer hide-header>
      <div class="task-answer-modal">
        <div class="task-answer-modal-header">
          <div class="task-answer-modal-header-title-block">
            <div class="task-answer-modal-header-title-block-title" v-if="answerModal === false">Вы не справились...
            </div>
            <div class="task-answer-modal-header-title-block-title" v-else>Поздравляем!!!</div>
            <div class="task-answer-modal-header-title-block-subtitle" v-if="answerModal === false">Попробуйте еще раз
            </div>
            <div class="task-answer-modal-header-title-block-subtitle" v-else>Задание успешно пройдено</div>
          </div>
          <button type="button" class="task-answer-modal-header-close" @click="$refs.taskModal.hide()">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M30 2.625L27.375 0L15 12.375L2.625 0L0 2.625L12.375 15L0 27.375L2.625 30L15 17.625L27.375 30L30 27.375L17.625 15L30 2.625Z"
                fill="#EB0C08"/>
            </svg>
          </button>
        </div>
        <div class="task-answer-modal-body">
          <img src="../../assets/images/task-error.png" alt="" v-if="answerModal === false">
          <img src="../../assets/images/task-success.png" alt="" v-else>
          <div class="task-answer-modal-body-points" v-if="answerModal !== false && this.answerModal">
            {{ answerModal.task_point }}
          </div>
        </div>
        <button v-if="answerModal === false" type="button" class="task-answer-modal-button"
                @click="$refs.taskModal.hide()">Попробовать еще раз
        </button>
        <button v-else-if="$route.query.type === 'reply'" type="button" @click="$router.back()"
                class="task-answer-modal-button">ПРОДОЛЖИТЬ
        </button>
        <button v-else type="button" @click="next" class="task-answer-modal-button">ПРОДОЛЖИТЬ</button>
      </div>
    </b-modal>

    <b-modal id="module-modal" ref="moduleModal" class="done-task" hide-footer hide-header>
      <div class="task-answer-modal">
        <div class="task-answer-modal-header">
          <div class="task-answer-modal-header-title-block">
            <div class="task-answer-modal-header-title-block-title">Поздравляем!!!</div>
            <div class="task-answer-modal-header-title-block-subtitle">
              <template v-if="endModule">Модуль пройден</template>
              <template v-else-if="endModuleTasks">Последняя задача пройдена</template>
              <template v-else-if="endCourseTasks">Последный модуль пройден</template>
              <template v-else>Курс успешно пройден</template>
            </div>
          </div>
          <button type="button" class="task-answer-modal-header-close" @click="$refs.moduleModal.hide()">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M30 2.625L27.375 0L15 12.375L2.625 0L0 2.625L12.375 15L0 27.375L2.625 30L15 17.625L27.375 30L30 27.375L17.625 15L30 2.625Z"
                fill="#EB0C08"/>
            </svg>
          </button>
        </div>
        <div class="task-answer-modal-module-body">
          <img src="../../assets/images/task-module-modal.png" alt="">
          <div class="task-answer-modal-module-body-text">
            <template v-if="endModule">
              Примите наши поздравления, Вы успешно
              закончили модуль. Но не стоит останавливаться на достигнутом, совершенствуйте свое мастерство и повышайте
              Вашу личную ценность.
            </template>
            <template v-else-if="endModuleTasks">
              Примите наши поздравления, Вы успешно
              закончили модуль. Но не стоит останавливаться на достигнутом, совершенствуйте свое мастерство и повышайте
              Вашу личную ценность.
            </template>
            <template v-else-if="endCourseTasks">
              Примите наши поздравления, Вы успешно
              закончили модуль. Но не стоит останавливаться на достигнутом, совершенствуйте свое мастерство и повышайте
              Вашу личную ценность.
            </template>
            <template v-else>
              Примите наши поздравления, Вы успешно прошли курс. Но
              не стоит останавливаться на достигнутом, совершенствуйте свое мастерство и повышайте Вашу личную ценность.
            </template>
          </div>
        </div>
        <button v-if="endModule" type="button" @click="nextModul" class="task-answer-modal-button"
        >ПРОДОЛЖИТЬ ОБУЧЕНИЕ
        </button>
        <button v-else-if="endModuleTasks" type="button" @click="openTest(courseModule, $route.query.course)"
                class="task-answer-modal-button"
        >НАЧАТЬ ТЕСТИРОВАНИЕ
        </button>
        <button v-else-if="endCourseTasks" type="button" @click="nextCourseTest"
                class="task-answer-modal-button"
        >НАЧАТЬ ТЕСТИРОВАНИЕ
        </button>
        <button v-else type="button" @click="nextCourse" class="task-answer-modal-button">ПРОДОЛЖИТЬ ОБУЧЕНИЕ</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import TestGenerateMixin from '../../mixins/TestGenerateMixin'
import { uuid } from 'vue-uuid'

export default {
  name: 'TaskAnswerModal',
  mixins: [TestGenerateMixin],
  data () {
    return {
      module: true
    }
  },
  computed: {
    ...mapState({
      answerModal: state => state.task.answerModal,
      nextTask: state => state.course.nextTask
    }),
    ...mapGetters({
      nextTaskId: 'nextTaskId/nextTaskId',
      courseModule: 'courseModule/courseModule',
      course: 'course/course'
    }),
    endModule () {
      return this.module && this.answerModal && this.answerModal.course_module_test && !this.answerModal.course_finish
    },
    endModuleTasks () {
      return this.module && this.answerModal && !this.answerModal.course_module_test && !this.answerModal.course_finish
    },
    endCourseTasks () {
      return !this.module && this.answerModal && !this.answerModal.course_test && !this.answerModal.course_finish
    },
    isCourseTest () {
      return this.answerModal && this.answerModal.next_course_test
    }
  },
  methods: {
    ...mapActions({
      getCourseModule: 'courseModule/getCourseModule',
      getCourse: 'course/getCourse'
    }),
    async nextCourseTest () {
      if (!Object.keys(this.course).length) await this.getCourseById()
      this.openCourseTest(this.course)
    },
    getCourseById () {
      const params = {
        id: this.$route.query.course,
        params: { forTest: true }
      }
      return this.getCourse(params)
    },
    async next () {
      if (!this.$route.query.type) {
        if (this.endModuleTasks) {
          await this.getCourseModule(this.answerModal.module_id || this.$route.query.module)
        } else if (this.endCourseTasks) {
          await this.getCourseById()
        }
      }

      if (this.answerModal.course_module) {
        this.module = true
        this.$refs.taskModal.hide()
        this.$refs.moduleModal.show()
      } else {
        if (this.$route.query.lesson) {
          if (this.$route.query.moduleId) {
            this.$router.push('/profile/module-tests/' + this.$route.query.courseId)
          } else {
            if (this.nextTaskId.next_task_id) {
              this.$router.push('/profile/task/' + this.nextTaskId.next_task_type_id + '/' + this.nextTaskId.next_task_id + '?lesson=' + this.nextTaskId.next_lesson_id + '&course=' + this.nextTaskId.next_course_id + '&module=' + this.nextTaskId.next_module_id + `&uuid=${uuid.v4()}` + (this.$route.query.type ? '&type=' + this.$route.query.type : ''))
            } else if (this.nextTaskId.next_block_id) {
              this.$router.push({
                name: 'block',
                params: { blockId: this.nextTaskId.next_block_id },
                query: { uuid: uuid.v4() }
              })
            } else {
              if (this.$route.query.type === 'replyTest') {
                this.$router.push({
                  name: 'Tests',
                  params: { id: this.$route.query.course }
                })
              } else {
                this.$router.push('/profile/course/' + this.$route.query.course + '/lesson')
              }
            }
          }
        } else {
          this.$router.push('/profile/course/' + this.$route.query.course)
        }
      }
    },
    nextModul () {
      if (this.isCourseTest) {
        this.$refs.moduleModal.hide()
        this.module = false
        this.$refs.moduleModal.show()
      } else {
        if (this.$route.query.lesson) {
          if (this.$route.query.moduleId) {
            this.$router.push('/profile/module-tests/' + this.$route.query.courseId)
          } else {
            this.$router.push('/profile/course/' + this.$route.query.course + '/lesson')
          }
        } else {
          this.$router.push('/profile/course/' + this.$route.query.course)
        }
      }
    },
    nextCourse () {
      if (this.$route.query.lesson) {
        if (this.$route.query.moduleId) {
          this.$router.push('/profile/module-tests/' + this.$route.query.courseId)
        } else {
          this.$router.push('/profile/course/' + this.$route.query.course + '/lesson')
        }
      } else {
        this.$router.push('/profile/course/' + this.$route.query.course)
      }
    }
  }
}
</script>
<style lang="scss">
.task-answer-modal-button {
  &:hover {
    background: linear-gradient(
        266.63deg, #EB0C08 0%, #FEB400 100%);
    border: unset !important;
    padding-top: 15px !important;
    padding-bottom: 14px !important;
    color: white !important;
  }
}
</style>
