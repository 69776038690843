export default {
  data () {
    return {
      prevLeft: null,
      prevTop: null,
      LeftMove: null,
      TopMove: null,
      speedTop: 1.36,
      speedLeft: 1,
    }
  },
  methods: {
    onScroll (x, y) {
      this.scrolling(this.$refs.scroll, 'Left', x)

      const main = document.getElementsByClassName('main-content')
      this.scrolling(main[0], 'Top', y)

      this.prevLeft = x
      this.prevTop = y
    },
    scrolling (element, position, value) {
      if (this[`prev${position}`]) {
        const pos = this[`prev${position}`] - value
        this[`${position}Move`] = pos === 0 ? pos : pos > 0 ? (-pos * this[`speed${position}`]) : Math.abs(pos * this[`speed${position}`])
      }
      if (this[`${position}Move`]) {
        element[`scroll${position}`] += this[`${position}Move`]
      }

    }
  }
}
