<template>
<span
  :class="{'words-used': used}"
>
     {{ number === (length - 1) ? `${word}.` : `${word},` }}
 </span>
</template>

<script>
export default {
  name: 'WordStatus',
  props: {
    used: {
      type: Boolean,
      default: false
    },
    length: {
      type: Number,
      default: 0
    },
    number: {
      type: Number,
      default: 0
    },
    word: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.words-used {
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    border-top: 1px solid #333333;
    position: absolute;
    top: 50%;
  }
}
</style>
