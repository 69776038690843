<template>
  <div>
    <div class="personal-details books">
      <div class="additional">
        ДОПОЛНИТЕЛЬНЫЕ МАТЕРИАЛЫ
      </div>
      <div class="tabs-title">
        Книги
      </div>
      <div class="tabs-info" v-if="!loading">
        {{
          allBooksList.length ? 'Книги для дополнительного обучения' : 'Данный раздел доступен только после покупки курса'
        }}
      </div>
      <div class="action">
        <button type="button" @click="openModal" class="add-course">ВЫБРАТЬ КУРС</button>
      </div>
      <Loading v-if="loading"/>
      <div v-else class="books-content">
        <img v-if="!allBooksList.length" src="~@/assets/images/profile/books.svg" alt="notes">
        <div v-else class="books-content-column row">
          <div class="col-md-6">
            <div class="books-content-item" v-for="(book, index) in allBooksList" :key="book.id" v-if="index % 2 === 0">
              <div class="books-item-title">{{ book.name[$i18n.locale] }}</div>
              <div class="item-content">
                <div class="image">
                  <img :src="book.image" alt="">
                </div>
                <div class="description">
                  <div class="text">{{ book.description[$i18n.locale] }}</div>
                  <div class="actions">
                    <a :href="book.file" target="_blank" download class="action-item">
                      <div>
                        <img src="~@/assets/images/icons/download.svg" alt="download">
                      </div>
                      <span>СКАЧАТЬ КНИГУ</span>
                    </a>
                    <div class="action-item cursor-pointer" @click="openAudioModal(book)">
                      <div>
                        <img src="~@/assets/images/icons/audio.svg" alt="audio">
                      </div>
                      <span>СЛУШАТЬ АУДИО</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="books-content-item" v-for="(book, index) in allBooksList" :key="book.id" v-if="index % 2 === 1">
              <div class="books-item-title">{{ book.name[$i18n.locale] }} </div>
              <div class="item-content">
                <div class="image">
                  <img :src="book.image" alt="">
                </div>
                <div class="description">
                  <div class="text">{{ book.description[$i18n.locale] }}</div>
                  <div class="actions">
                    <a :href="book.file" target="_blank" download class="action-item">
                      <div>
                        <img src="~@/assets/images/icons/download.svg" alt="download">
                      </div>
                      <span>СКАЧАТЬ КНИГУ</span>
                    </a>
                    <div class="action-item cursor-pointer" @click="openAudioModal(book)">
                      <div>
                        <img src="~@/assets/images/icons/audio.svg" alt="audio">
                      </div>
                      <span>СЛУШАТЬ АУДИО</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3" v-if="pagination.count_pages > 1">
        <b-pagination
          v-model="currentPage"
          :total-rows="pagination.count_pages"
          :per-page="1"
          first-number
          last-number
          prev-text="«"
          next-text="»"
          @input="clickPagination"
        ></b-pagination>
      </div>

      <b-modal id="listen-audio" ref="listenAudio">
        <template #modal-header="{ close }">
          <b-button @click="close()">
            X
          </b-button>
          <h3>{{ modalBook.name[$i18n.locale] }}</h3>
        </template>
        <!--        <a :href="modalBook.audio" download class="action-item">-->
        <!--          <div>-->
        <!--            <img src="~@/assets/images/icons/download.svg" alt="download">-->
        <!--          </div>-->
        <!--          <span>СКАЧАТЬ АУДИОКНИГУ</span>-->
        <!--        </a>-->
        <mini-audio
          :audio-source="modalBook.audio"
        ></mini-audio>
      </b-modal>
    </div>
    <CoursesModal v-model="activeCourseId" :show="showModal" />
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import Loading from '../../../components/Loading'
import CoursesModal from '../../../components/Materials/CoursesModal'

export default {
  name: 'Books',
  components: {
    CoursesModal,
    Loading
  },
  data () {
    return {
      currentPage: 1,
      modalBook: {},
      activeCourseId: null,
      loading: false,
      showModal: false,
    }
  },
  computed: {
    ...mapState({
      allBooksList: state => state.book.allBooksList,
      pagination: state => state.book.pagination,
      courses: state => state.course.boughtCoursesList
    })
  },
  watch: {
    activeCourseId () {
      this.getBooks()
      this.showModal = false
    }
  },
  methods: {
    ...mapActions({
      getAllBooksList: 'book/getAllBooksList',
    }),
    openModal(){
      this.showModal = true
    },
    openAudioModal (book) {
      this.modalBook = book
      this.$refs.listenAudio.show()
    },
    getBooks () {
      this.loading = true

      const params = {
        page: this.currentPage,
        course_id: this.activeCourseId
      }

      return this.getAllBooksList(params).finally(() => {
        this.loading = false
      })
    },
    clickPagination () {
      this.getBooks().then(() => {
        window.scrollTo(0, 0)
      })
    }
  }
}
</script>
<style scoped lang="scss">
.auth-user {
  .main-content {
    .books-content-column {
      @media all and (max-width: 576px) {
        margin: 0 !important;
      }

      .col-md-6 {
        @media all and (max-width: 576px) {
          padding: 0 !important;
        }
      }

      .books-content-item {
        min-width: calc(100% - 20px) !important;
        max-width: calc(100% - 20px) !important;

        @media all and (max-width: 576px) {
          max-width: 100% !important;
        }
      }
    }
  }

}

</style>
