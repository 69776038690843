import { http } from '../../../api'

const paymentHistory = {
  namespaced: true,
  state: {
    paymentHistory: [],
    pagination: {}
  },
  actions: {
    getPaymentHistory: ({ commit }) => {
      return http.get('/payment-history').then(res => {
        commit('setPaymentHistory', res.data)
      })
    },
    checkPaidCourse (context,key) {
      return http.post('/check-paid-course', { key }).then(res => {
        return res.data
      })
    }
  },
  mutations: {
    setPaymentHistory (state, data) {
      state.paymentHistory = data.data
      state.pagination = data.pagination
    }
  },
  getters: {
    paymentHistory: state => state.paymentHistory
  }
}

export default paymentHistory
