<template>

  <div>
    <!-- Chat page -->
    <div class="personal-details chat" v-if="chatList && chatList.length || serchChatActive || chatsLoading">
      <div class="chat-items" :class="{'d-block':showMenu}">
        <div class="top-line">
          <div class="d-flex align-items-center justify-content-between">
            <div class="name">Чаты</div>
            <button v-show="!isExpertChat" v-b-modal.choose-course-modal class="add-btn" type="button">+</button>
          </div>
          <div class="search" v-show="!isExpertChat">
            <input type="text" @input="searchChat($event)" placeholder="Поиск чата">
            <img src="~@/assets/images/icons/search.svg" alt="search">
          </div>
        </div>
        <div class="chat-list">
          <ul class="list" v-on:scroll.prevent="handleScroll">
            <li @click.prevent="clickChat(chat.id)" v-for="(chat, i) in chatList" :key="chat.id"
                :class="{'active': chat.id === selectedChatId}" class="d-flex justify-content-between">
              <div class="chat-name" :class="{'chat-active': chat.id === selectedChatId}">
                {{ chat.name }}
                <template v-if="isExpert">. Пользователь: {{ chat.users[0].first_name }}</template>
              </div>
              <div class="ml-3 "><span v-if="chat.course_chat_messages_count > 0"
                                       class="chat-badge">{{ chat.course_chat_messages_count }}</span></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="chat-content">
        <div class="top-line d-flex justify-content-between">
          <div class="left w-100">
            <div class="mobile-visible">
              <a href="#" @click.prevent="() => showMenu = !showMenu">
                <img src="~@/assets/images/icons/menu-burger.svg" alt="menu burger">
              </a>
            </div>
            <div class="wrapper">
              <div class="chat-name">{{ chatMessages.name }}
                <template v-if="isExpert">. Пользователь: {{ chatMessages.users[0].first_name }}</template>
              </div>
            </div>
            <div class="people-number" v-if="isExpertChat">
              <template v-if="!isExpertChat">
                {{ chatMessages.users ? chatMessages.users.length : 0 }}
              </template>
              <template v-else>
                2
              </template>
              человек
            </div>
          </div>
          <div class="col-md-2 d-flex">
            <div
              v-if="chatMessages.chat_type !== null && chatMessages.chat_type !== 'chat' && chatMessages.chat_with !== 'expert_chat' || isExpert"
              class="right cursor-pointer video-call" @click="videoCall">
              <svg height="472pt" viewBox="0 -87 472 472" width="28px" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m467.101562 26.527344c-3.039062-1.800782-6.796874-1.871094-9.898437-.179688l-108.296875 59.132813v-35.480469c-.03125-27.601562-22.398438-49.96875-50-50h-248.90625c-27.601562.03125-49.96875 22.398438-50 50v197.421875c.03125 27.601563 22.398438 49.96875 50 50h248.90625c27.601562-.03125 49.96875-22.398437 50-50v-34.835937l108.300781 59.132812c3.097657 1.691406 6.859375 1.625 9.894531-.175781 3.039063-1.804688 4.898438-5.074219 4.898438-8.601563v-227.816406c0-3.53125-1.863281-6.796875-4.898438-8.597656zm-138.203124 220.898437c-.015626 16.5625-13.4375 29.980469-30 30h-248.898438c-16.5625-.019531-29.980469-13.4375-30-30v-197.425781c.019531-16.558594 13.4375-29.980469 30-30h248.90625c16.558594.019531 29.980469 13.441406 30 30zm123.101562-1.335937-103.09375-56.289063v-81.535156l103.09375-56.285156zm0 0"/>
              </svg>
            </div>
            <div class="right">
              <a v-show="!isExpertChat" href="#" v-b-modal.leave-chat-course-modal>
                <img src="~@/assets/images/icons/out.svg" alt="">
              </a>
            </div>
          </div>
        </div>
        <loading v-if="chatsLoading"/>
        <div v-else class="row no-gutters">
          <template v-if="callPlaced">
            <div v-if="chatMessages.chat_type !== 'chat' || chatMessages.chat_with === 'expert_chat'"
                 class="row no-gutters video-chat-container"
                 :class="`${chatMessages.chat_type === 'video'? 'col-md-12': 'col-md-6'}`"
            >
              <VideoCallView
                v-for="video in videoCallUsers"
                :class="`${fourGrid?'col-md-6':'col-md-12'}`"
                :login="video.user.login"
                :key="video.peer_id"
                :stream="video.stream"
                :user-audio-mute="video.audioMute"
                :user-video-mute="video.videoMute"
                ref="video"/>
              <VideoCallView
                v-if="stream"
                key="meVideoStream"
                :stream="stream"
                @closeCall="closeCall"
                :login="user.login"
                :class="`${fourGrid?'col-md-6':'col-md-12'}`"
                ref="myVideo"
                @muteVideo="muteVideo"
                @muteAudio="muteAudio"
                :me="true"/>
            </div>
          </template>
          <div v-if="chatMessages.chat_type !== 'video'" class="content position-relative"
               :class="`${callPlaced ?'col-md-6 call-placed': 'col-md-12'}`">
            <ul v-chat-scroll ref="ul">
              <li v-for="(message, i) in chatMessages.course_chat_messages"
                  :class="`${message.user_id === user.id ? 'sent justify-content-end' : 'received'} ${message.viewed === 0 ? 'scrollTo': ''}`"
                  :key="i" v-if="message.user">
                <div class="general" v-if="message.user_id === user.id">
                  <div class="username">
                    <span class="mr-2">{{ message.user.first_name + ' ' + message.user.last_name }}</span>
                    <span
                      class="date">{{
                        message.created_at | moment('MM.DD.YYYY')
                      }} | {{ message.created_at | moment('H:mm') }} </span>
                  </div>
                  <div class="message">
                    {{ message.message }}
                  </div>
                </div>
                <div v-if="message.user_id === user.id" class="avatar">
                  <img v-if="message.user.avatar" :src="message.user.avatar_path" alt="">
                  <img v-else src="~@/assets/images/profile/add-image.svg" alt="avatar">
                </div>
                <div class="avatar" v-if="message.user_id !== user.id">
                  <img v-if="message.user.avatar" :src="message.user.avatar_path" alt="">
                  <img v-else src="~@/assets/images/profile/add-image.svg" alt="avatar">
                </div>
                <div class="general" v-if="message.user_id !== user.id">
                  <div class="username">
                    <span class="mr-2">{{ message.user.first_name + ' ' + message.user.last_name }} </span>
                    <span
                      class="date">{{
                        message.created_at | moment('MM.DD.YYYY')
                      }} | {{ message.created_at | moment('H:mm') }} </span>
                  </div>
                  <div class="message">
                    {{ message.message }}
                  </div>
                </div>
              </li>
            </ul>
            <form class="new-message" @submit.prevent="submitSendMessageForm">
              <input type="text" v-model.trim="chatFormData.message" placeholder="Написать сообщение"/>
              <div>
                <button type="submit" class="send-btn">
                  <span>отправить</span>
                  <img src="~@/assets/images/icons/send.svg" alt="">
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- When there is no chat -->
    <div class="support personal-details" v-else>
      <div class="tabs-title">
        Чаты
      </div>
      <div class="tabs-info">
        У вас еще нет чатов. Данный раздел доступен только после покупки любого курса.
      </div>
      <button type="button" v-b-modal.choose-course-modal class="new-template">Создать новый чат</button>
      <div class="notifications-content">
        <img src="~@/assets/images/profile/chat.svg" class="notifications-content-no-notifications" alt="">
      </div>
    </div>
    <b-modal id="choose-course-modal" ref="createChatModal">
      <template #modal-header="{ close }">
        <b-button @click="close()">
          X
        </b-button>
        <h3>Создать новый чат</h3>
      </template>
      <div class="content">
        <ValidationObserver tag="form" ref="createChatForm" action="" @submit.prevent="sendCreateChatForm">
          <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
            <label for="name">Название чата</label>
            <input
              :class="classes"
              id="name"
              name="name"
              v-model="createChatFormData.name"
              type="text"
              class="form-control"
              placeholder="Название чата"
            >
          </ValidationProvider>
          <ValidationProvider tag="div" v-slot="{ errors }" name="количество человек" rules="required"
                              class="people-number">
            <label>Количество человек</label>
            <div class="d-flex align-items-center">
              <div class="radio cursor-pointer" @click="choseTotal(2)">
                <input v-model="createChatFormData.count_persons" value="2" type="radio" name="count_persons">
                <span>2</span>
              </div>
              <div class="radio cursor-pointer" @click="choseTotal(3)">
                <input v-model="createChatFormData.count_persons" value="3" type="radio" name="count_persons">
                <span>3</span>
              </div>
              <div class="radio cursor-pointer" @click="choseTotal(4)">
                <input v-model="createChatFormData.count_persons" value="4" type="radio" name="count_persons">
                <span>4</span>
              </div>
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          <ValidationProvider tag="div" v-slot="{ errors }" name="пол" rules="required">
            <div class="radio-container">
              <label>Пол:</label>
              <div class="form-group checkbox-group">
                <label class="checkbox-container">
                  <input v-model="createChatFormData.gender" value="not_required" type="radio" name="gender"/>
                  <span class="checkbox"/>
                  <span>Не важно</span>
                </label>
                <label class="checkbox-container">
                  <input v-model="createChatFormData.gender" value="male" type="radio" name="gender"/>
                  <span class="checkbox"/>
                  <span>Мужской</span>
                </label>
                <label class="checkbox-container">
                  <input v-model="createChatFormData.gender" value="female" type="radio" name="gender"/>
                  <span class="checkbox"/>
                  <span>Женский</span>
                </label>
              </div>
            </div>
            <div>
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider tag="div" v-slot="{ errors }" name="Возраст:" rules="required">
            <div class="radio-container">
              <label>Возраст</label>
              <div class="form-group checkbox-group">
                <label class="checkbox-container">
                  <input v-model="createChatFormData.age" value="not_required" type="radio" name="age"/>
                  <span class="checkbox"/>
                  <span>Не важно</span>
                </label>
                <label class="checkbox-container">
                  <input v-model="createChatFormData.age" value="18-25" type="radio" name="age"/>
                  <span class="checkbox"/>
                  <span>18 - 25</span>
                </label>
                <label class="checkbox-container">
                  <input v-model="createChatFormData.age" value="26-30" type="radio" name="age"/>
                  <span class="checkbox"/>
                  <span>26 - 30</span>
                </label>
                <label class="checkbox-container">
                  <input v-model="createChatFormData.age" value="31-35" type="radio" name="age"/>
                  <span class="checkbox"/>
                  <span>31 - 35</span>
                </label>
                <label class="checkbox-container">
                  <input v-model="createChatFormData.age" value="36-40" type="radio" name="age"/>
                  <span class="checkbox"/>
                  <span>36 - 40</span>
                </label>
                <label class="checkbox-container">
                  <input v-model="createChatFormData.age" value="40" type="radio" name="age"/>
                  <span class="checkbox"/>
                  <span>Старше 40</span>
                </label>
              </div>
            </div>
            <div>
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider tag="div" v-slot="{ errors }" name="Тип чат" rules="required">
            <div class="radio-container">
              <label>Тип чат:</label>
              <div class="form-group checkbox-group">
                <label class="checkbox-container">
                  <input v-model="createChatFormData.chat_type" value="chat" type="radio" name="chat_type"/>
                  <span class="checkbox"/>
                  <span>Переписка</span>
                </label>
                <label class="checkbox-container">
                  <input v-model="createChatFormData.chat_type" value="video_and_chat" type="radio" name="chat_type"/>
                  <span class="checkbox"/>
                  <span>Переписка + видеоконференция</span>
                </label>
                <label class="checkbox-container">
                  <input v-model="createChatFormData.chat_type" value="video" type="radio" name="chat_type"/>
                  <span class="checkbox"/>
                  <span>Видеоконференция</span>
                </label>
              </div>
            </div>
            <div>
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <div class="actions">
            <button type="submit" :disabled="disabledSubmitBtn">СОЗДАТЬ НОВЫЙ ЧАТ</button>
          </div>
        </ValidationObserver>
      </div>
    </b-modal>
    <b-modal id="wait-response" ref="chatCreatedModal">
      <template #modal-header="{ close }">
        <b-button @click="close()">
          X
        </b-button>
        <h3>Ожидайте подключение</h3>
      </template>
      <div class="info-text">
        Ожидаем подключения пользователей к чату. После подключения всех участников откроется чат
      </div>
      <div class="actions">
        <button type="button" class="btn btn-info cancel" @click.prevent="$refs.chatCreatedModal.hide()">закрыть
        </button>
      </div>
    </b-modal>
    <b-modal id="leave-chat-course-modal" ref="leaveChatCourseModal">
      <template #modal-header="{ close }">
        <b-button @click="close()">
          X
        </b-button>
        <h3>Покинуть чат</h3>
      </template>
      <div class="content">
        <p>Вы собираетесь покинуть чат. После данного действия
          вся история чата будет удалена. Вы уверены что хотите покинуть чат?</p>
        <div class="d-flex w-100">
          <div class="actions-cancel w-100 mr-2" @click="leaveChatCourseModalHide">
            <button type="button">отмена</button>
          </div>
          <div class="actions w-100 ml-2">
            <button type="submit" @click="leaveChatCourseModal">покинуть чат</button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { getPermissions } from '../../../../helpers'
import VideoCallView from '../../../components/VideoCallView'
import Swal from 'sweetalert2'
import Loading from '../../../components/Loading'
import VideoCallMixin from '../../../../mixins/VideoCallMixin'

export default {
  name: 'Chat',
  mixins: [VideoCallMixin],
  components: {
    Loading,
    VideoCallView,
  },
  data () {
    return {
      createChatFormData: {},
      selectedChatId: null,
      serchChatActive: false,
      disabledSubmitBtn: false,
      scroll: true,
      chatFormData: {},
      chatMessages: [],
      errorMsg: '',
      count: 10,
      showMenu: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      chatList: state => state.courseChat.chatList,
      chatItem: state => state.courseChat.chatItem,
      chatsLoading: state => state.loading.loading
    }),
    ...mapGetters({
      users: 'user/users',
      videoChat: 'courseChat/videoChat',
      videoChatKey: 'courseChat/videoChatKey'
    }),
    isExpertChat () {
      return this.$route.query.chat_with === 'expert_chat'
    },
    fourGrid () {
      return (this.videoCallUsers.length + 1) > 2
    },
    isExpert () {
      return this.user.roles.findIndex(role => role.name === 'expert') !== -1
    }
  },
  created () {
    this.getChats(this.$route.query).then(res => {
      if (res.data.length > 0) {
        if (this.$route.query.open_chat) {
          this.setCallPlaced(true)
          this.openChat(this.$route.query.open_chat)
          this.selectedChatId = parseInt(this.$route.query.open_chat)
        } else {
          this.openChat(res.data[0].id)
        }
      }
    })
    this.listenNewMessage()
  },
  beforeRouteLeave (to, from, next) {
    this.leaveCourseChat()
    if (this.stream) {
      this.closeVideoCallModal().then((result) => {
        if (result.value) {
          this.closeCall(true)
          next()
        } else {
          next(false)
        }
      })
    } else {
      next()
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.leaveCourseChat()
    this.count = 10
    this.getChats(to.query)
      .then(res => {
        this.openChat(res.data[0].id)
        this.selectedChatId = res.data[0].id
      })
    this.listenNewMessage()
    next()
  },
  watch: {
    videoChat (newVal, oldVal) {
      this.setCallPlaced(true)
      this.openChat(newVal.course_chat_id)
    },
  },
  methods: {
    ...mapActions({
      createChat: 'courseChat/createChat',
      sendMessage: 'courseChat/sendMessage',
      showChat: 'courseChat/showChat',
      leaveChat: 'courseChat/leaveChat',
      getChatList: 'courseChat/getChatList',
      startVideoCall: 'courseChat/startVideoCall',
      mute: 'courseChat/mute'
    }),
    ...mapMutations({
      setPeer: 'courseChat/setPeer',
      setMediaConnection: 'courseChat/setMediaConnection',
      setChannel: 'courseChat/setChannel'
    }),
    closeVideoCallModal () {
      return Swal.fire({
        text: 'Отключить видео вызов?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет'
      })
    },
    getRandomInt (min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min)) + min //Максимум не включается, минимум включается
    },
    async clickChat (chat_id) {
      if (this.stream) {
        const result = await this.closeVideoCallModal()
        if (!result.value) {
          return false
        }
        this.closeCall()
      }

      this.openChat(chat_id)
    },
    muteVideo (event) {
      this.mute({
        peer_id: this.peer.id,
        mute: event,
        type: 'videoMute',
        video_chat_key: this.videoChatKey
      })

      this.channel.whisper('muteVideo', {
        peer_id: this.peer.id,
        mute: event,
      })
    },
    muteAudio (event) {
      this.mute({
        peer_id: this.peer.id,
        mute: event,
        type: 'audioMute',
        video_chat_key: this.videoChatKey
      })

      this.channel.whisper('muteAudio', {
        peer_id: this.peer.id,
        mute: event
      })
    },
    myVideoPlay (stream) {
      if (this.$refs.myVideo) {
        const myVideo = this.$refs.myVideo.$el.firstChild
        myVideo.srcObject = stream
        myVideo.addEventListener('loadedmetadata', () => {
          myVideo.play()
          myVideo.muted = true
        })
      }
    },
    getMediaPermission () {
      return getPermissions()
        .then((stream) => {
          this.setStream(stream)
          this.myVideoPlay(stream)
          return stream
        })
        .catch((error) => {
          console.log(error)
        })
    },
    videoCall () {
      try {
        this.closeCall()
        this.getMediaPermission()
        const params = {
          users: this.users.map(user => user.user.id),
          course_chat_id: this.selectedChatId,
          peer_id: this.peer.id,
        }
        this.startVideoCall(params)
      } catch (e) {
        console.log('error', e)
      }
      this.setCallPlaced(true)
    },
    choseTotal (number) {
      this.$set(this.createChatFormData, 'count_persons', number)
    },
    getChats (query, name = '', count = this.count) {
      const params = {
        count,
        name,
      }

      if (query.chat_with) {
        params.chat_with = query.chat_with
      }

      if (query.course_id) {
        params.course_id = query.course_id
      }

      return this.getChatList(params)
    },
    leaveChatCourseModalHide () {
      this.$bvModal.hide('leave-chat-course-modal')
    },
    leaveChatCourseModal () {
      this.$bvModal.hide('leave-chat-course-modal')
      this.leaveChat({ id: this.selectedChatId })
      const chat_id = this.chatList.find(chat => chat.id > this.selectedChatId)
      console.log('chat_id', chat_id)
      if (chat_id) {
        this.openChat(chat_id.id)
      }

    },
    scrollToElement (options = {}) {
      const el = this.$refs.ul.getElementsByClassName('scrollTo')[0]
      //
      if (el) {
        el.scrollIntoView(options)
      }
    },
    handleScroll (e) {
      if (this.scroll) {
        const element = e.target
        if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
          this.count += 10
          this.getChats(this.$route.query)
        }
      }
    },
    searchChat (event) {
      if (event.target.value) {
        this.scroll = false
        this.getChats(this.$route.query, event.target.value, null)
        this.serchChatActive = true
      } else {
        this.getChats(this.$route.query)
        this.scroll = true
        this.serchChatActive = false
      }
    },
    listenNewMessage () {
      this.$echo.private('App.Models.User.' + this.user.id).notification((notification) => {
        if (notification.type === 'new_message') {
          console.log('notfy', notification)
          const {chat_id} = notification.data
          this.chatList.map(chat => {
            if (chat.id == chat_id) {
              console.log('new_message')
              chat.course_chat_messages_count++
            }
          })
        } else if (notification.type === 'accepted_invite') {
          this.$refs.chatCreatedModal.hide()
        }
      })
    },
    listenForCourseChatNewMessage (courseChatId) {
      console.log('courseChatId', courseChatId)
      const channel = this.$echo.join(`course-chat.${courseChatId}`)
        .here((users) => {
          console.log('here', users)
          this.chatUsers = users.map(user => user.user.id)
        })
        .leaving((user) => {
          console.log('leaving', user)
          this.chatUsers = this.chatUsers.filter(chatUser => chatUser !== user.user.id)
          const userIndex = this.videoCallUsers.findIndex(video => video.user.id === user.user.id)
          if (userIndex !== -1) {
            this.videoCallUsers.splice(userIndex, 1)
          }
        })
        .joining((user) => {
          console.log('joining', user)
          this.chatUsers.push(user.user.id)
        })
        .listen('CourseChatMessageSent', (e) => {
          if (e.data.user_id !== this.user.id) {
            this.chatMessages.course_chat_messages.push(e.data)
          }
        })
        .listen('EnteredVideoChat', e => {
          this.openVideoChat(e)
          console.log('EnteredVideoChat', e)
        })
        .listenForWhisper('closeCall', e => {
          const userCallIndex = this.videoCallUsers.findIndex(video => video.peer_id === e.peer_id)
          if (userCallIndex !== - 1){
            this.videoCallUsers.splice(userCallIndex, 1)
          }
        })
        .listenForWhisper('muteVideo', e => {
          const userIndex = this.videoCallUsers.findIndex(video => video.peer_id === e.peer_id)
          this.$set(this.videoCallUsers[userIndex], 'videoMute', e.mute)
        })
        .listenForWhisper('muteAudio', e => {
          const userIndex = this.videoCallUsers.findIndex(video => video.peer_id === e.peer_id)
          this.$set(this.videoCallUsers[userIndex], 'audioMute', e.mute)
        })

      this.setChannel(channel)
    },
    leaveCourseChat () {
      this.$echo.leave('course-chat.' + this.selectedChatId)
    },
    openChat (id) {
      if (this.selectedChatId) {
        this.leaveCourseChat()
      }
      this.selectedChatId = id
      this.showChat({ id: id }).then(res => {
        this.chatMessages = res.data
        this.listenForCourseChatNewMessage(id)
        this.chatFormData = {}
        this.chatList.map(chat => {
          if (chat.id === id) {
            chat.course_chat_messages_count = 0
          }
        })
      })
    },
    submitSendMessageForm () {
      if (!this.chatFormData.message.length || this.chatFormData.message.length > 1000) {
        return
      }

      this.sendMessage({
        data: {
          ...this.chatFormData,
          users: this.chatUsers
        },
        id: this.selectedChatId
      }).then(res => {
        this.chatFormData = {}
      })
    },
    sendCreateChatForm () {
      this.$refs.createChatForm.validate().then(success => {
        if (success) {
          const vm = this
          vm.disabledSubmitBtn = true
          const data = {
            ...vm.createChatFormData,
            users: this.users.map(user => user.user.id)
          }
          vm.createChat({ data }).then(() => {
            vm.$refs.createChatModal.hide()
            vm.$refs.chatCreatedModal.show()
            vm.createChatFormData = {}
          }).catch(() => {
            vm.errorMsg = 'Server error!'
          }).finally(() => {
            vm.disabledSubmitBtn = false
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.chat-badge {
  background-color: #F5F6FC;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  font-size: 14px;
}

.video-call {
  &:hover {
    background-color: #2C8ECD;

    svg {
      fill: white;
    }
  }
}

.video-chat-container {
  max-height: calc(100vh - 362px);
  overflow-y: auto;
}

</style>
