<template>
  <TaskBase
    :task="task"
    :correct-answer="correctAnswer"
    :check-try="true"
    :check-task="checkTask"
    check-button-text="Продолжить"
  >
    <template #desktop>
      <div class="row">
        <div class="lg-xl-8 lg-lg-11 lg-md-12 lg-12 row">
          <div class="col-md-6 only-pc">
            <div class="d-flex flex-column align-items-center">
              <Card @click.native="pickCard" class="card-size"/>
              <div class="d-flex justify-content-center mt-4 task-numbers">
              <span
                class="text-red mr-1">{{ !start ? 0 : activeQuestion + 1 }}</span><span>/ {{ task.task.length }}</span>
              </div>
              <div class="d-flex justify-content-center mt-3" v-if="start && !finish">
                <button :disabled="disabledButton" class="btn-pass" @click="passQuestion">Пропустить слово</button>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12 d-flex flex-column align-items-center" v-if="start">
            <Card class="card-size card-space" :show-front="true" :text="task.task[activeQuestion].question[$i18n.locale]"/>
            <div class="only-mob">
              <div class="d-flex justify-content-center mt-4 task-numbers">
              <span
                class="text-red mr-1">{{ !start ? 0 : activeQuestion + 1 }}</span><span>/ {{ task.task.length }}</span>
              </div>
              <div class="d-flex justify-content-center mt-3" v-if="start && !finish">
                <button :disabled="disabledButton" class="btn-pass" @click="passQuestion">Пропустить слово</button>
              </div>
            </div>
            <TaskItem v-for="(answer, index) in task.task[activeQuestion].answers"
                      :text="answer.answer[$i18n.locale]"
                      class="answer-item"
                      :success="answers[activeQuestion].success && answers[activeQuestion].answer_id === answer.id"
                      :error="answers[activeQuestion].error && answers[activeQuestion].answer_id === answer.id"
                      :key="answer.id"
                      @click.native="pickAnswer(answer.id)"
            />
          </div>
        </div>
      </div>
    </template>
  </TaskBase>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import TaskAnswerModal from '../../../../components/TaskAnswerModal'
import { mapActions, mapState } from 'vuex'
import VideoAudioGallery from '../../../../components/VideoAudioGallery'
import TaskBase from '../../../../components/TaskBase'
import TestGenerateMixin from '../../../../../mixins/TestGenerateMixin'
import TaskCheckParamsMixin from '../../../../../mixins/TaskCheckParamsMixin'
import Card from '../../../../components/Tasks/Card'
import TaskItem from '../../../../components/Tasks/TaskItem'

export default {
  name: 'ChooseTranslation',
  mixins: [TestGenerateMixin, TaskCheckParamsMixin],
  data () {
    return {
      clickedPrompt: false,
      uuid: null,
      activeQuestion: 0,
      start: false,
      answers: [],
      disabledButton: false,
    }
  },
  components: {
    TaskItem,
    Card,
    TaskBase,
    VideoAudioGallery,
    VueSlickCarousel,
    TaskAnswerModal,
  },
  computed: {
    ...mapState({
      task: state => state.task.task,
      nextTask: state => state.course.nextTask,
    }),
    finish () {
      return this.task.task.length - this.answers.filter(answer => answer.answered === true).length <= 1
    },
  },
  beforeRouteUpdate (to, from, next) {
    if (to.fullPath !== from.fullPath) {
      Object.assign(this.$data, this.$options.data())
      this.taskInit(to)
    }
    next()
  },
  created () {
    this.taskInit(this.$route)
  },
  mounted () {
    this.pickCard()
  },
  methods: {
    ...mapActions({
      getTask: 'task/getTask',
      missingTask: 'course/missingTask',
      getMissingTask: 'course/getMissingTask',
      checkChooseTranslationTask: 'task/checkChooseTranslationTask',
      missQuestionTask: 'task/missQuestionTask',
      setTaskAnswerModal: 'task/setTaskAnswerModal',
      checkTestTask: 'task/checkTestTask',
    }),
    pickAnswer (answer_id) {
      const question = this.answers[this.activeQuestion]

      if (this.disabledButton || !answer_id || question.success) return

      this.$set(question, 'answer_id', answer_id)
      this.disabledButton = true
      this.checkTask()
    },
    answersInit () {
      this.task.task.forEach(question => {
        this.answers.push({
          question_id: question.id,
          answer_id: null,
          success: false,
          error: false,
          try: 1,
          answered: false,
        })
      })
    },
    taskInit (route) {
      const query = {}
      if (route.query.type) {
        query.type = route.query.type
      }
      this.getTask({
        id: route.params.taskId,
        query
      }).then(res => {
        this.answersInit()
      })
    },
    pickCard () {
      if (!this.start) return this.start = true

      if (this.disabledButton) return

      this.disabledButton = true
      this.checkTask()
    },
    passQuestion () {
      let questionIndex = this.answers.findIndex((answer, index) => {
        if (index > this.activeQuestion) {
          return answer.success === false
        }
      })

      if (questionIndex !== -1) {
        this.activeQuestion = questionIndex
        return
      }

      questionIndex = this.answers.findIndex((answer, index) => {
        if (index < this.activeQuestion) {
          return answer.success === false
        }
      })

      if (questionIndex !== -1) {
        this.activeQuestion = questionIndex
      }

    },
    correctAnswer () {
      let answer = this.answers[this.activeQuestion]
      if (answer.try < 3) {
        return
      }
      const correctAnswer = this.task.task[this.activeQuestion].answers.find(item => item.correct_answer === true)
      this.$set(this.answers, this.activeQuestion, {
        ...answer,
        success: true,
        error: false,
        answer_id: correctAnswer.id
      })
      this.clickedPrompt = true
    },
    missTask () {
      this.missingTask({ task_id: this.$route.params.taskId })
    },
    checkTask () {
      let params = {
        answer_id: this.answers[this.activeQuestion].answer_id,
        question_id: this.answers[this.activeQuestion].question_id,
        type_model: 'TaskDeckCardQuestion',
        uuid: this.task.uuid,
        ...this.checkParams(),
      }

      if (this.finish) {
        params = {
          ...params,
          answers: this.answers
        }
      }

      this.checkTestTask(params).then(res => {
        if (res.data.answer) {
          if (this.finish) {
            this.setTaskAnswerModal(res.data).then(() => {
              this.$root.$emit('bv::show::modal', 'modal-task')
            })
          } else {
            this.$toasted.success('Ответ был выбран правильно').goAway(1500)
          }
          this.$set(this.answers[this.activeQuestion], 'answered', true)
        }else {
          this.setTaskAnswerModal(false).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        }
        this.changeSuccessAndError(res.data.answer, res.data.try)
      }).finally(() => {
        this.disabledButton = false
      })
    },
    checkChooseTranslation (questionId, answerId) {
      this.$set(this.answerChecked, 0, answerId)
      if (this.task.task.length === 1) {
        this.finish = true
      } else {
        this.finish = false
      }
      this.checkTask()
    },
    changeSuccessAndError (state, tryNumber = null) {
      if (state) {
        this.$set(this.answers[this.activeQuestion], 'error', false)
        this.$set(this.answers[this.activeQuestion], 'success', true)
        setTimeout(() => {
          this.passQuestion()
        }, 500)
      } else {
        this.$set(this.answers[this.activeQuestion], 'error', true)
      }

      if (tryNumber) {
        this.$set(this.answers[this.activeQuestion], 'try', tryNumber)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.card-size {
  height: 158.81px;
  width: 280px;
  font-size: 24px;

  @media (min-width: 768px) {
    width: 335px;
    height: 190px;
    font-size: 36px;
  }

  @media (min-width: 1440px) {
    width: 440px;
    height: 250px;

  }

  @media (min-width: 1920px) {

  }

}

.card-space{
  margin-bottom: 10px;
  background: #FFFFFF;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    margin-bottom: 50px;
  }

}

.task-numbers {
  font-size: 18px;
  font-weight: bold;
}

.text-red {
  color: #CF464E

}

.btn-pass {
  width: 280px;
  height: 50px;
  border: 2px solid #cb3c44;
  border-radius: 25px;
  color: #cb3c44;
  text-transform: uppercase;
  font-size: 12px;
  background-color: transparent;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.mb-50 {
  margin-bottom: 50px;
}

.answer-item {
  width: 280px;
  border-radius: 30px !important;
  margin-bottom: 10px;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 334px;
  }

  @media (min-width: 1440px) {
    width: 440px;
  }
}

</style>
