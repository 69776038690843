var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video col-12 px-0"},[_c('bread-crumbs',{attrs:{"pages":_vm.pages}}),_c('div',{staticClass:"video-block page-container"},[_vm._m(0),(_vm.allNews.length > 0)?_c('div',{staticClass:"video-block-videos row"},_vm._l((_vm.allNews),function(news){return _c('NewsVideoCard',{key:news.id,attrs:{"news":news}})}),1):_vm._e(),(_vm.pagination.count_pages > 1)?_c('div',{staticClass:"mt-3"},[_c('b-pagination',{attrs:{"total-rows":_vm.pagination.count_pages,"per-page":1,"first-number":"","last-number":"","prev-text":"«","next-text":"»"},on:{"input":_vm.clickPagination},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()]),_c('div',{staticClass:"news-form"},[_c('div',{staticClass:"page-container news-form-container"},[_c('div',{staticClass:"title"},[_vm._v("Хотите быть в курсе новостей?")]),_c('div',{staticClass:"subtitle"},[_vm._v("Подпишитесь на рассылку наших новостей.")]),_c('ValidationObserver',{ref:"subscribeAddForm",staticClass:"form-block",attrs:{"id":"subscribeAddForm","tag":"div"}},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-8 row"},[_c('ValidationProvider',{staticClass:"col-12 col-sm",attrs:{"name":"name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],staticClass:"col-12 col-sm",class:classes,attrs:{"type":"text","name":"name","autocomplete":"off","placeholder":"Ваше имя"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}})]}}])}),_c('ValidationProvider',{staticClass:"col-12 col-sm",attrs:{"name":"email","rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],staticClass:"col-12 col-sm",class:classes,attrs:{"type":"email","name":"email","autocomplete":"off","placeholder":"E-mail"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}})]}}])})],1),_c('button',{staticClass:"btn-white-outline col-4",class:_vm.disabledSubmitBtn ? 'btn-disabled' : '',attrs:{"type":"button","disabled":_vm.disabledSubmitBtn},on:{"click":_vm.formSubmit}},[_vm._v("подписаться на новости")])]),_c('ValidationProvider',{staticClass:"checkbox",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('label',{staticClass:"remember",attrs:{"for":"privacy"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.privacy),expression:"formData.privacy"}],attrs:{"true-value":1,"false-value":0,"type":"checkbox","id":"privacy","name":"checkbox"},domProps:{"checked":Array.isArray(_vm.formData.privacy)?_vm._i(_vm.formData.privacy,null)>-1:_vm._q(_vm.formData.privacy,1)},on:{"change":[function($event){var $$a=_vm.formData.privacy,$$el=$event.target,$$c=$$el.checked?(1):(0);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "privacy", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "privacy", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "privacy", $$c)}},function($event){return validate(false)}]}}),_c('span'),_c('div',[_vm._v("Отправляя заявку, я соглашаюсь с "),_c('a',{attrs:{"target":"_blank","href":"/privacy-policy"}},[_vm._v("политикой конфиденциальности")]),_vm._v(" и "),_c('a',{attrs:{"target":"_blank","href":"/terms-of-use"}},[_vm._v("пользовательским соглашением")]),_vm._v(" сайта")])]),(errors[0] || _vm.formData.privacy === 0)?_c('small',{staticClass:"text-danger font-italic position-absolute d-conte"},[_vm._v("Вы не согласились с Правилами")]):_vm._e()]}}])})],1)],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-block-title-block"},[_c('div',{staticClass:"video-block-title-block-title"},[_vm._v("Новости Language to go")]),_c('div',{staticClass:"video-block-title-block-subtitle subtitle-blue"},[_vm._v("Будьте в курсе наших событий. Следите за нашими новостями")])])}]

export { render, staticRenderFns }