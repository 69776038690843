<template>
  <StatisticsBase :language="statisticsTasks.language" :sub-title="subTitle">
    <div class="task-content">

      <div class="tasks" v-for="(block, i) in statisticsTasks.data" :key="block.id">
        <div class="title">{{ block.name }}</div>
        <template v-for="(task, index) in block.tasks">
          <div class="task" :key="task.id">
            <div>
              <div class="task-title">Урок {{ index + 1 }}:</div>
              <div class="task-name">{{ task.name[$i18n.locale] }}</div>
            </div>
            <div class="task-balls">{{ task.user_task.point }} бал</div>
          </div>
          <div class="task" v-for="child in task.user_task.childs" :key="`child${child.id}`">
            <div>
              <div class="task-title">Урок {{ index + 1 }}:</div>
              <div class="task-name">{{ task.name[$i18n.locale] }}</div>
            </div>
            <div class="task-balls">{{ child.point }} бал</div>
          </div>
        </template>
      </div>
    </div>
  </StatisticsBase>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import StatisticsBase from '../../../components/StatisticsBase'

export default {
  name: 'StatisticsTask',
  components: { StatisticsBase },
  data () {
    return {
      subTitle: ''
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.getStats()
    next()
  },
  created () {
    this.getStats()
  },
  computed: {
    ...mapState({
      statisticsTasks: state => state.statistics.statisticsTasks
    })
  },
  methods: {
    ...mapActions({
      getStatisticsTasks: 'statistics/getStatisticsTasks'
    }),
    getStats () {
      const params = {}
      if (this.$route.query.lesson_id) {
        params.lesson_id = this.$route.query.lesson_id
      } else if (this.$route.query.course_id) {
        params.course_id = this.$route.query.course_id
      } else if (this.$route.query.module_id) {
        params.module_id = this.$route.query.module_id
      }
      this.getStatisticsTasks(params).then(res => {
        if (this.$route.query.lesson_id) {
          this.subTitle = `Подробная статистика заданий. Модуль ${this.statisticsTasks.module}. Урок ${this.$route.query.lesson}`
        } else if (this.$route.query.course_id) {
          this.subTitle = 'Подробная статистика заданий.'
        } else if (this.$route.query.module_id) {
          this.subTitle = `Подробная статистика заданий. Модуль ${this.$route.query.module}.`
        }
      })
    }
  }
}
</script>
