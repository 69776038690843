<template>
  <TaskBase :task="task" :check-task="checkRepeatText" :correct-answer="correctAnswer">
    <template #desktop>
      <div v-if="taskPresentation.content_output_method === 0">
        <div v-for="(question, index) in questions" :key="index">
          <RepeatTextTemplate
            :question="question"
            :answer="getAnswerByQuestionId(question.id)"
            :lang="task.course_teaching_language.code"
          />
        </div>
      </div>

      <div v-else-if="taskPresentation.content_output_method === 1">
        <VueSlickCarousel
          v-if="questions.length"
          v-bind="settings"
          @beforeChange="beforeChange"
          ref="carouselTask"
          class="task-choose-translation-cards"
        >
          <div
            v-for="(item, innerCarouselIndex) in carouselListQuestions"
            :key="innerCarouselIndex"
          >
            <div v-for="(question, index) in item" :key="index">
              <RepeatTextTemplate
                :question="question"
                :answer="getAnswerByQuestionId(question.id)"
                :lang="task.course_teaching_language.code"
              />
            </div>
            <TaskButtons
              class="d-flex flex-row justify-content-end"
              :showSkipButton="true"
              :hasAnswer="carouselListAnswers[innerCarouselIndex].has_answer"
              @skipButtonClick="changePage"
            />
          </div>
        </VueSlickCarousel>
        <DotsTemplate
          :pageCount="sliderPageCount"
          :currentIndex="carouselIndex"
          :answers="carouselListAnswers"
          @changePage="changePage($event)"
        />
      </div>

      <div v-else-if="taskPresentation.content_output_method === 2">
        <VueSlickCarousel
          v-if="questions.length"
          v-bind="settings"
          @beforeChange="beforeChange"
          ref="carouselTask"
          class="task-choose-translation-cards"
        >
          <div class="carousel-custom" v-for="(question, index) in questions" :key="index">
            <RepeatTextTemplate
              :question="question"
              :answer="getAnswerByQuestionId(question.id)"
              :lang="task.course_teaching_language.code"
              :taskPresentation="taskPresentation.content_output_method"
            />
            <TaskButtons
              class="d-flex flex-row justify-content-end"
              :showSkipButton="true"
              :hasAnswer="getAnswerByQuestionId(question.id).has_answer"
              @skipButtonClick="changePage"
            />
          </div>
        </VueSlickCarousel>
        <DotsTemplate
          :pageCount="questions.length"
          :currentIndex="carouselIndex"
          :answers="answers"
          @changePage="changePage($event)"
        />
      </div>
    </template>
  </TaskBase>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import { mapActions, mapState } from 'vuex'
import RepeatTextTemplate from './RepeatTextTemplate'
import TaskBase from '../../../../components/TaskBase'
import TaskCheckParamsMixin from '../../../../../mixins/TaskCheckParamsMixin'
import DotsTemplate from './DotsTemplate.vue'
import TaskButtons from '../../../../components/Tasks/TaskButtons'

export default {
  name: 'RepeatText',
  mixins: [TaskCheckParamsMixin],
  components: {
    TaskBase,
    RepeatTextTemplate,
    VueSlickCarousel,
    DotsTemplate,
    TaskButtons
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: false
      },
      carouselIndex: 0,
      questions: [],
      answers: [], // question_id
      carouselListQuestions: [],
      carouselListAnswers: [],
      words: {},
      taskReady: false
    }
  },
  watch: {
    answers: {
      handler(val) {
        // only forListCarousel
        if (this.taskPresentation.content_output_method !== 1) {
          return
        }
        for (let index = 0; index < this.sliderPageCount; index++) {
          const startIndex = index * this.taskPresentation.content_output_count
          const endIndex = startIndex + this.taskPresentation.content_output_count
          const indexAnswers = this.answers.slice(startIndex, endIndex)
          const res = {
            has_answer: indexAnswers.every(el => el.has_answer),
            error: indexAnswers.find(el => el.error)?.error || false,
            success: indexAnswers.every(el => el.success)
          }
          this.$set(this.carouselListAnswers, index, res)
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      task: state => state.task.task,
      nextTask: state => state.course.nextTask,
      taskPresentation: state => state.task.taskPresentation
    }),
    sliderPageCount() {
      return Math.ceil(this.questions.length / this.taskPresentation.content_output_count)
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath !== from.fullPath) {
      Object.assign(this.$data, this.$options.data())
      this.taskInit(to)
    }
    next()
  },
  created() {
    this.taskInit(this.$route)
  },
  methods: {
    ...mapActions({
      getTask: 'task/getTask',
      missingTask: 'course/missingTask',
      getMissingTask: 'course/getMissingTask',
      checkRepeatTextTask: 'task/checkRepeatTextTask',
      setTaskAnswerModal: 'task/setTaskAnswerModal'
    }),
    loadData() {
      const query = {}
      if (this.$route.query.type) {
        query.type = this.$route.query.type
      }
      return this.getTask({ id: this.$route.params.taskId })
    },
    taskInit() {
      this.loadData().then(() => {
        // answers - back error. contains a question
        this.task.task.answers.forEach((question, answerIndex) => {
          const matches = []

          let start = false
          let word = ''
          question.answer.split('').forEach(char => {
            if (char === '}') {
              start = false
              matches.push(word)
              word = ''
            }

            if (start) {
              word += char
            }

            if (char === '{') {
              start = true
            }
          })

          const text = question.answer.replaceAll(/\{(.+?)\}/gis, '|')

          this.questions.push({
            ...question,
            chars: [],
            voice: ''
          })

          let i = 0
          text.split('').forEach((char, charIndex) => {
            if (char === '|') {
              this.words[question.id] = {
                ...this.words[question.id],
                [charIndex]: matches[i]
              }
              i++
            }

            this.questions[answerIndex].chars.push(char)
          })
          // TODO: new question model, need testing
          this.answers.push({
            question_id: question.id,
            success: false,
            error: false,
            has_answer: false,
            text_answer: '',
            voice_answer: ''
          })
        })
        if (this.taskPresentation.content_output_method === 1) {
          this.getItemsSlide()
        }
        this.taskReady = true
      })
    },
    getItemsSlide(slideIndex) {
      for (let index = 0; index < this.sliderPageCount; index++) {
        const startIndex = index * this.taskPresentation.content_output_count
        const endIndex = startIndex + this.taskPresentation.content_output_count
        this.carouselListQuestions.push(this.questions.slice(startIndex, endIndex))
      }
    },
    beforeChange(prevIndex, newIndex) {
      this.carouselIndex = newIndex
    },
    changePage(index) {
      if (typeof index === 'number') {
        this.$refs.carouselTask.goTo(index)
      } else {
        this.$refs.carouselTask.next()
      }
    },
    getAnswerByQuestionId(questionId) {
      return this.answers.find(answer => answer.question_id === questionId)
    },
    correctAnswer() {
      //   this.questions.forEach((question, answerIndex) => {
      //     question.chars.forEach((char, charIndex) => {
      //       if (this.words[question.id][charIndex]) {
      //         this.$set(this.questions[answerIndex].chars, charIndex, this.words[answer.id][charIndex])
      //       }
      //     })
      //     // this.$set(this.errors, answerIndex, false)
      //   })
    },
    checkRepeatText() {
      this.checkRepeatTextTask({
        answers: this.answers.map(answer => {
          return {
            id: answer.question_id,
            answer: answer.text_answer || '|',
            voice: answer.voice_answer || '|'
          }
        }),
        skip: false,
        ...this.checkParams()
      }).then(res => {
        if (res.data && res.data.answer) {
          this.answers.forEach(answer => {
            answer.success = true
            answer.error = false
          })
          this.setTaskAnswerModal(res.data).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
          this.loadData()
        } else {
          const errors = res.data.errors
          this.answers.forEach((answer, index) => {
            answer.success = !errors[index]
            answer.error = errors[index]
          })
          this.setTaskAnswerModal(false).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel-custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
</style>
