import { http } from '../../../api'

const language = {
  namespaced: true,
  state: {
    languages: []
  },
  actions: {
    getLanguages: ({ commit }) => {
      return http.get('/language').then(res => {
        commit('setLanguages', res.data.data)
      })
    }
  },
  mutations: {
    setLanguages (state, data) {
      state.languages = data
    }
  },
  getters: {
    languages: state => state.languages
  }
}

export default language
