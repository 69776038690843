import { http } from '../../../api'

const faqCategory = {
  namespaced: true,
  state: {
    faqCategories: []
  },
  actions: {
    getFaqCategories: ({ commit }) => {
      return http.get('/faq-category').then(res => {
        commit('setFaqCategories', res.data.data)
      })
    }
  },
  mutations: {
    setFaqCategories (state, data) {
      state.faqCategories = data
    }
  },
  getters: {
    faqCategories: state => state.faqCategories
  }
}

export default faqCategory
