<template>
  <div class="row justify-content-center up-header">
    <div class="page-container">
      <div class="row h-100 justify-content-between row-minus">
        <div class="col-auto currency-button">
          <span>{{ $t('header.currency') }}:</span>
          <b-dropdown
            size="lg"
            variant="link"
            toggle-class="text-decoration-none"
            :class="{ 'currency-disabled': currency.length === 1 }"
            no-caret
            :disabled="currency.length === 1"
          >
            <template #button-content>
              <span class="text-white selected-value text-capitalize"
                ><span v-if="currencySelected && currencySelected.name">{{
                  currencySelected.symbol
                }}</span>
                <img
                  v-if="currency.length > 1"
                  src="@/assets/images/icons/arrow-bottom.svg"
                  alt="arrow-bottom"
              /></span>
            </template>
            <b-dropdown-item
              v-for="(item, index) in currency"
              :key="index"
              @click="selectCurrency(item)"
              class="text-capitalize"
              >{{ item.name[$i18n.locale] }} {{ item.symbol }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div
          class="align-items-center d-flex d-none d-lg-flex tel header-tel"
          v-if="settings && settings.phone"
        >
          <a :href="'tel:' + settings.phone[0]" class="text-white d-none d-xl-block">{{
            settings.phone[0]
          }}</a>
        </div>
        <div class="d-none col-1 d-md-block col-md-6 col-lg-6 col-xl-6">
          <div class="row h-100 align-items-center justify-content-between">
            <div class="d-none col-4   d-xl-block" v-if="settings && settings.email">
              <a :href="'mailto:' + settings.email[0]" class="text-white">{{
                settings.email[0]
              }}</a>
            </div>
            <div class="social-links col-sm-12 col-xl-7 d-none d-md-block p-0">
              <ul class="social-links-list justify-content-lg-center justify-content-end">
                <li class="social-links-list-item" v-if="settings.facebook">
                  <a :href="settings.facebook">
                    <i class="fab fa-facebook-f text-white social-icon"></i>
                  </a>
                </li>
                <li class="social-links-list-item" v-if="settings.instagram">
                  <a :href="settings.instagram">
                    <i class="fab fa-instagram text-white fs-20"></i>
                  </a>
                </li>
                <li class="social-links-list-item" v-if="settings.youtube">
                  <a :href="settings.youtube">
                    <i class="fab fa-youtube text-white fs-20"></i>
                  </a>
                </li>
                <li class="social-links-list-item" v-if="settings.vk">
                  <a :href="settings.vk">
                    <i class="fab fa-vk text-white fs-20"></i>
                  </a>
                </li>
                <li class="social-links-list-item" v-if="settings.ok">
                  <a :href="settings.ok">
                    <i class="fab fa-odnoklassniki text-white fs-20"></i>
                  </a>
                </li>
                <li class="social-links-list-item" v-if="settings.twitter">
                  <a :href="settings.twitter">
                    <i class="fab fa-twitter text-white fs-20"></i>
                  </a>
                </li>
                <li class="social-links-list-item" v-if="settings.tiktok">
                  <a :href="settings.tiktok">
                    <i class="fab fa-tiktok text-white fs-20"></i>
                  </a>
                </li>
                <li class="social-links-list-item" v-if="settings.telegram">
                  <a :href="settings.telegram">
                    <i class="fab fa-telegram text-white fs-20"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <!-- <div class="col-auto p-0">
            <router-link v-if="isAuthenticated" to="/profile" class="login-btn"
              >Мой профиль</router-link
            >
            <button
              v-else
              type="button"
              class="login-btn"
              @click="openAuthModal"
            >
              {{ $t("header.login") }}
            </button>
          </div> -->
          <div class="col-auto p-0">
            <b-dropdown
              size="lg"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              :disabled="languages.length === 1"
              :class="{ 'opacity-100': languages.length === 1 }"
            >
              <template #button-content>
                <span class="text-white selected-value font"
                  ><span class="text-uppercase font-weight-bold">{{ $i18n.locale }}</span>
                  <img
                    v-if="languages.length > 1"
                    src="@/assets/images/icons/arrow-bottom.svg"
                    alt="arrow-bottom"
                /></span>
              </template>
              <b-dropdown-item
                v-for="(language, i) in languages"
                :key="i"
                @click="changeLanguage(language.code)"
              >
                <span class="text-uppercase">{{ language.code }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <AuthModal />
    <b-modal hide-footer hide-header ref="forgotPasswordModal" content-class="forgot-password">
      <div class="close-modal" @click.prevent="closeForgotPasswordModal">
        <img src="../../assets/images/icons/close.svg" alt="Close" />
      </div>
      <div class="modal-block">
        <div class="title">Восстановить пароль</div>
        <div class="text">
          Введите e-mail адрес который вы указывали при регистрации, чтобы восстановить пароль. Вы
          получите письмо с инструкциями по восстановлению пароля. Если у вас возникли проблемы с
          восстановлением пароля,
          <a @click.prevent="openContacts" href="#">свяжитесь с нами</a>
        </div>
        <div class="form-block">
          <div class="form-group">
            <label for="e-mail">E-mail</label>
            <input type="text" id="e-mail" placeholder="E-mail" />
          </div>
        </div>
        <button class="submit" type="button">войти</button>
        <div class="login-registration">
          <div class="login-text" @click="openLoginModal">Войти</div>
          <div class="registration-text">
            Нет аккаунта?
            <div @click="openLoginModalRegistration">Зарегистрироваться</div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal hide-footer hide-header ref="congratulationsModal" content-class="congratulation">
      <div class="close-modal" @click.prevent="closeCongratulationsModal">
        <img src="../../assets/images/icons/close.svg" alt="Close" />
      </div>
      <div class="modal-block">
        <div class="title">Поздравляем с регистрацией</div>
        <div class="text">
          На ваш e-mail адрес было отправлено письмо для подтверждения вашего e-mail адреса.
          Подтвердить e-mail адрес можно в любой момент, сделав запрос в личном кабинете.
          Подтверждение e-mail адреса необходимо для возможности восстановить свой аккаунт в
          дальнейшем для необходимости.
        </div>
        <button type="button">перейти в кабинет</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AuthModal from '../components/AuthModal'
import i18n, { loadLanguageAsync } from '../../localization/i18n'

export default {
  name: 'UpHeader',
  data() {
    return {
      currencySelected: {}
    }
  },
  components: {
    AuthModal
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      isAuthenticated: state => state.auth.isAuthenticated,
      settings: state => state.setting.settings,
      currency: state => state.setting.currency
    })
  },
  created() {
    this.getLanguages().catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
    this.getCurrency()
      .then(() => {
        if (!localStorage.currencySelected) {
          for (let i = 0; i < this.currency.length; i++) {
            if (this.currency[i].code === 'rub') {
              this.currencySelected = this.currency[i]
            } else {
              this.currencySelected = this.currency[0]
            }
            localStorage.setItem('currencySelected', JSON.stringify(this.currencySelected))
          }
        } else {
          this.currencySelected = JSON.parse(localStorage.currencySelected)
        }
      })
      .catch(() => {
        this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
      })
  },
  methods: {
    ...mapActions({
      getLanguages: 'language/getLanguages',
      login: 'auth/login',
      logOut: 'auth/logOut',
      register: 'auth/register',
      getCurrency: 'setting/getCurrency',
      setLoginModal: 'auth/setLoginModal'
    }),
    selectCurrency(currency) {
      this.currencySelected = currency
      localStorage.setItem('currencySelected', JSON.stringify(this.currencySelected))
    },
    openContacts() {
      this.closeForgotPasswordModal()
      this.$router.push('/contacts')
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang
      loadLanguageAsync(i18n.locale).then(() => localStorage.setItem('lang', lang))
    },
    openAuthModal() {
      this.setLoginModal(false).then(() => {
        this.$root.$emit('bv::show::modal', 'login-modal')
      })
    },
    openLoginModal() {
      this.closeForgotPasswordModal()
      this.setLoginModal(false).then(() => {
        this.$refs.loginModal.show()
      })
    },
    openLoginModalRegistration() {
      this.closeForgotPasswordModal()
      this.setLoginModal(true).then(() => {
        this.$refs.loginModal.show()
      })
    },
    closeLoginModal() {
      this.$refs.loginModal.hide()
    },
    openForgotPasswordModal() {
      this.$refs.loginModal.hide()
      this.$refs.forgotPasswordModal.show()
    },
    openCongratulationsModal() {
      this.$refs.loginModal.hide()
      this.$refs.congratulationsModal.show()
    },
    closeCongratulationsModal() {
      this.$refs.congratulationsModal.hide()
    },
    closeForgotPasswordModal() {
      this.$refs.forgotPasswordModal.hide()
    },
    loginFormSubmit() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.disabledSubmitBtn = true
          this.errorMsg = ''
          this.login(this.loginFormData)
            .then(() => {
              this.$refs.loginModal.hide()
              this.$router.push('/profile')
            })
            .catch(err => {
              if (err.response.data.error === 'incorrect_credentials') {
                this.errorMsg = 'Неправильный логин или пароль!'
                return false
              } else {
                this.errorMsg = 'Что то пошло не так!'
              }
            })
            .finally(() => {
              this.disabledSubmitBtn = false
            })
        }
      })
    },
    registerFormSubmit() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.disabledSubmitBtn = true
          this.errorMsg = ''
          const data = { ...this.registerFormData }
          data.birthday =
            this.registerFormData.birthday.year +
            '/' +
            this.registerFormData.birthday.month +
            '/' +
            this.registerFormData.birthday.day
          this.register(data)
            .then(() => {
              this.$refs.loginModal.hide()
              this.$router.push('/profile')
            })
            .catch(() => {})
            .finally(() => {
              this.disabledSubmitBtn = false
            })
        }
      })
    }
  }
}
</script>
<style scoped>
.currency-button {
  padding-right: 0;
}
.social-icon {
  font-size: 18px;
}

.currency-disabled {
  opacity: 1 !important;
}

.fs-20 {
  font-size: 20px;
}

.fab:hover {
  color: #78258d !important;
}

.login-btn:hover {
  color: #78258d !important;
}

.selected-value:hover {
  color: #78258d !important;
}

.up-header .currency-button button span:hover {
  color: #78258d !important;
}
</style>
