<template>
  <div class="gallery-item">
    <img class="img-fluid" :src="image" alt="gallery">
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    }
  }
}
</script>

<style scoped>
.gallery-item {
  display: flex !important;
  width: 320px;
  height: 240px;
  padding: 10px;
}

@media (max-width: 768px) {
  .gallery-item {
    width: 180px;
    height: 135px;
  }
}

@media (max-width: 306px) {
  .gallery-item {
    width: 120px;
    height: 90px;
  }
}
</style>
