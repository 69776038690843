<template>
  <div class="payment personal-details">
    <div class="tabs-title">
      История оплат
    </div>
    <div class="tabs-info">
      История оплат отсутствует
    </div>
    <!-- When there are payments -->
    <div class="payment-content" v-if="paymentHistory && paymentHistory.length">
      <div class="payment-content-table">
        <div class="payment-content-table-header">
          <div class="item small">ДАТА</div>
          <div class="item big">ОПЛАТА ЗА</div>
          <div class="item middle">СПОСОБ ОПЛАТЫ</div>
          <div class="item small">СУММА</div>
          <div class="item small">СТАТУС</div>
        </div>
        <div class="payment-content-table-body">
          <div class="payment-content-table-body-line" :class="paymentHistoryItem.status == 1 ? '' : 'declined'"
               v-for="(paymentHistoryItem, index) in paymentHistory" :key="index">
            <div class="item small d-flex">
              <div class="date">{{ paymentHistoryItem.created_at | moment('DD.MM.YYYY') }}</div>
              <div class="time ml-1">{{ paymentHistoryItem.created_at | moment('HH:mm') }}</div>
            </div>
            <div class="item big">
              <p class="m-0">{{ paymentHistoryItem.course.name[$i18n.locale] }}</p>
              <div class="package">
                Пакет: {{ paymentHistoryItem.course_tariff.name[$i18n.locale] }}
              </div>
            </div>
            <div class="item middle d-flex">
              <img :src="paymentHistoryItem.payment_method.image_footer_path"
                   :alt="paymentHistoryItem.payment_method.name[$i18n.locale]">
              <div class="method">
                {{ paymentHistoryItem.payment_method.name[$i18n.locale] }}
              </div>
            </div>
            <div class="item small d-flex">
              <div class="price">
                {{ paymentHistoryItem.price }} руб.
              </div>
            </div>
            <div v-if="paymentHistoryItem.status == 1" class="item small d-flex">
              <img src="~@/assets/images/profile/success.svg" alt="">
              <div class="status success">
                УСПЕШНАЯ
              </div>
            </div>
            <div v-else class="item small d-flex">
              <img src="~@/assets/images/profile/declined.svg" alt="">
              <div class="status declined">
                ОШИБКА ОПЛАТЫ
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="payment-content-mobile">
        <div
          class="item"
          v-for="(paymentHistoryItem, index) in paymentHistory"
          :class="{'declined': paymentHistoryItem.status == 0}"
          :key="paymentHistoryItem.id"
        >
          <div class="status">
            <img v-if="paymentHistoryItem.status == 1" src="~@/assets/images/profile/success.svg" alt="success">
            <img v-else src="~@/assets/images/profile/declined.svg" alt="declined">
          </div>
          <div class="info">
            <div class="date-info">
              <div class="date">
                {{ paymentHistoryItem.created_at | moment('DD.MM.YYYY') }}
              </div>
              <div class="time ml-2">
                {{ paymentHistoryItem.created_at | moment('HH:mm') }}
              </div>
            </div>
            <div class="title">
              {{ paymentHistoryItem.course.name[$i18n.locale] }} Пакет:
              {{ paymentHistoryItem.course_tariff.name[$i18n.locale] }}
            </div>
            <div class="payment-info">
              <img :src="paymentHistoryItem.payment_method.image_footer_path"
                   :alt="paymentHistoryItem.payment_method.name[$i18n.locale]" alt="yandex">
              <div class="price">
                {{ paymentHistoryItem.price }} руб.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- When there is no payment history   -->
    <div class="payment-content" v-else>
      <img src="~@/assets/images/profile/no-payment.svg" class="payment-content-no-payment" alt="no-payment-history">
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PaymentHistory',
  computed: {
    ...mapState({
      paymentHistory: state => state.paymentHistory.paymentHistory
    })
  },
  created () {
    this.getPaymentHistory()
  },
  methods: {
    ...mapActions({
      getPaymentHistory: 'paymentHistory/getPaymentHistory'
    })
  }
}
</script>
