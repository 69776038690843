<template>
  <div class="col py-5">
    <div class="text-center email-verified">
      <img class="email-verified-image" src="../../../assets/images/email-verified/email-verified.png" alt="">
      <div class="title">Регистрация прошла успешно!</div>
      <div class="text">Присоединяйтесь к нам в соцсетя, чтобы ваше обучение проходило еще успешнее</div>
      <div class="social">
        <a target="_blank" href="https://www.facebook.com/"><img src="../../../assets/images/email-verified/facebook.svg" alt="facebook"></a>
        <a target="_blank" href="https://vk.com/"><img src="../../../assets/images/email-verified/vk.svg" alt="vk"></a>
        <a target="_blank" href="https://ok.ru/"><img src="../../../assets/images/email-verified/ok.svg" alt="ok"></a>
        <a target="_blank" href="https://www.instagram.com/"><img src="../../../assets/images/email-verified/instagram.svg" alt="instagram"></a>
        <a target="_blank" href="https://www.youtube.com/"><img src="../../../assets/images/email-verified/youtube.svg" alt="youtube"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailVerified'
}
</script>
