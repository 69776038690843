<template>
  <svg :class="className" xmlns="http://www.w3.org/2000/svg" :stroke="stroke" :fill="fill" :width="`${width}px`" :height="`${height}px`">
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink" :fill="fill" />
  </svg>
</template>

<script>
export default {
  name: 'svg-icon',

  props: {
    name: {
      type: String,
      required: true
    },
    stroke: {
      type: String,
      default: null
    },

    fill: {
      type: String,
      default: null
    },

    width: {
      type: [String, Number],
      default: 18
    },

    height: {
      type: [String, Number],
      default: 18
    }
  },

  computed: {
    iconPath() {
      let icon = require(`@/assets/images/icons/${this.name}.svg`);

      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default;
      }

      return `@/assets/images/icons/${this.name}.svg`
    },

    className() {
      return 'svg-icon svg-icon--' + this.name;
    }
  }
};
</script>

<style>
  .svg-icon {
    fill: currentColor;
  }
</style>
