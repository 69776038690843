import { http } from '../../../api'

const referralCoupons = {
  namespaced: true,
  state: {
    coupons: [],
    bonuses: []
  },
  actions: {
    getCoupons: ({ commit }) => {
      return http.get('profile/coupons').then(res => {
        commit('setCoupons', res.data.data)
        return res.data.data
      })
    },
    addCoupon({commit}){
      return http.post('profile/coupons').then(res => {
        commit('pushCoupon', res.data.data)
      })
    },
    getAccrualBonuses({commit}){
      return http.get('profile/coupons/bonuses').then(res => {
        commit('setBonuses', res.data.data)
      })
    },
    deleteCoupon({commit}, id){
      return http.delete(`profile/coupons/${id}`).then(res => {
          commit('deleteCoupon', id)
      })
    }
  },
  mutations: {
    setCoupons (state, data) {
      state.coupons = data
    },
    pushCoupon(state, data){
      state.coupons.push(data)
    },
    setBonuses(state, data){
      state.bonuses = data
    },
    deleteCoupon(state, data){
      state.coupons = state.coupons.filter(coupon => coupon.id !== data)
    }
  },
  getters: {
    coupons: state => state.coupons,
    bonuses: state => state.bonuses
  }
}

export default referralCoupons
