<template>
  <div class="profile-sidebar" :class="{ 'main-sidebar-hide': isOpenMenu }" v-click-outside="hide">
    <div class="profile-sidebar-logo">
      <router-link to="/">
        <img src="@/assets/images/logo.png" alt="logo" />
      </router-link>
    </div>
    <div class="profile-sidebar-menu">
      <ul class="profile-sidebar-menu-general">
        <template v-if="isCustomer">
          <router-link tag="li" to="/profile" class="profile-sidebar-menu-item">
            <p>Главная</p>
          </router-link>
          <router-link tag="li" to="/profile/courses/main" class="profile-sidebar-menu-item">
            <p>Доступные курсы</p>
          </router-link>
          <!-- <router-link tag="li" to="/profile/news" class="profile-sidebar-menu-item">
            <p>Новости</p>
          </router-link> -->
          <router-link tag="li" to="/profile/lessons/all" class="profile-sidebar-menu-item">
            <p href="#">Бесплатные уроки</p>
          </router-link>
        </template>
        <router-link
          tag="li"
          to="/profile/chat"
          class="profile-sidebar-menu-item"
          v-if="isCustomer"
        >
          <p>Чаты</p>
        </router-link>
        <router-link
          tag="li"
          to="/profile/chat?chat_with=expert_chat"
          class="profile-sidebar-menu-item"
          v-else
        >
          <p>Чаты</p>
        </router-link>
      </ul>
      <template v-if="isCustomer">
        <div class="profile-sidebar-menu-sub" v-if="boughtCoursesList.length">
          <span>МОЕ ОБУЧЕНИЕ</span>
          <ul>
            <router-link
              v-if="can('access_notes')"
              tag="li"
              to="/profile/notes"
              class="profile-sidebar-menu-item"
            >
              <p>Заметки</p>
            </router-link>
            <li
              class="profile-sidebar-menu-item"
              v-for="(boughtCourse, index) in boughtCoursesList"
              :key="boughtCourse.id"
            >
              <div
                v-b-toggle="'collapse-' + boughtCourse.course.id"
                class="d-flex align-items-center justify-content-between"
              >
                <a href="#">{{ boughtCourse.course.name[$i18n.locale] }}</a>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g transform="translate(-256 -407)">
                    <circle cx="12" cy="12" r="12" transform="translate(256 407)" fill="#d3d7e5" />
                    <g>
                      <g transform="translate(-270.9 128.186)">
                        <g>
                          <path
                            d="M538.9,293.813a.78.78,0,0,1-.6-.248q-1.568-1.581-3.141-3.16a.758.758,0,0,1,0-1.136l.192-.193a.749.749,0,0,1,1.136,0l2.3,2.311c.117.117.117.117.234,0l2.292-2.3a.749.749,0,0,1,1.143,0l.207.209a.754.754,0,0,1,0,1.105q-1.581,1.592-3.163,3.182A.767.767,0,0,1,538.9,293.813Z"
                          />
                        </g>
                      </g>
                      <path
                        d="M268,422a.78.78,0,0,1-.6-.247q-1.567-1.581-3.141-3.16a.759.759,0,0,1,0-1.136l.193-.193a.749.749,0,0,1,1.136,0q1.149,1.156,2.3,2.311c.117.117.117.117.234,0l2.291-2.3a.749.749,0,0,1,1.143,0c.07.069.139.14.208.209a.755.755,0,0,1,0,1.105q-1.581,1.592-3.164,3.181a.764.764,0,0,1-.562.241Z"
                        fill="#000"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <b-collapse :id="'collapse-' + boughtCourse.course.id" class="lang-collapse">
                <ul class="collapse-2-ul">
                  <router-link
                    :to="'/profile/course/' + boughtCourse.course.id + '/lesson'"
                    tag="li"
                    >Уроки</router-link
                  >
                  <template v-if="boughtCourse.status.name === 'Куплен'">
                    <router-link :to="`/profile/tasks/${boughtCourse.course.id}`" tag="li"
                      >Задания</router-link
                    >
                    <router-link :to="'/profile/tests/' + boughtCourse.course.id" tag="li"
                      >Тесты</router-link
                    >
                    <router-link
                      v-if="boughtCourse.course_tariff.access_independent_work"
                      :to="'/profile/independent-work/' + boughtCourse.course.id"
                      tag="li"
                      >Самостоятельная работа
                    </router-link>
                    <router-link :to="'/profile/statistics/' + boughtCourse.course.id" tag="li"
                      >Статистика</router-link
                    >
                    <router-link
                      :to="
                        `/profile/chat?chat_with=expert_chat&course_id=${boughtCourse.course_id}`
                      "
                      tag="li"
                      v-if="boughtCourse.course_tariff_id === 3"
                      >Чат с экспертом
                    </router-link>
                    <router-link :to="'/profile/video-materials/' + boughtCourse.course.id" tag="li"
                      >Видео материалы
                    </router-link>
                    <router-link
                      v-if="boughtCourse.course_tariff.access_grammar"
                      :to="'/profile/grammar/' + boughtCourse.course.id"
                      tag="li"
                      >Грамматика</router-link
                    >
                    <router-link
                      v-if="boughtCourse.course_tariff.access_dictionary"
                      :to="`/profile/vocabulary/${boughtCourse.course.id}`"
                      tag="li"
                      >Словарь</router-link
                    >
                  </template>
                </ul>
              </b-collapse>
            </li>
          </ul>
        </div>
        <div class="profile-sidebar-menu-sub">
          <span>ДОП. МАТЕРИАЛЫ</span>
          <ul>
            <router-link tag="li" to="/profile/books" class="profile-sidebar-menu-item">
              <p>Книги</p>
            </router-link>
            <router-link tag="li" to="/profile/videos" class="profile-sidebar-menu-item">
              <p>Видео</p>
            </router-link>
            <router-link tag="li" to="/profile/audio" class="profile-sidebar-menu-item">
              <p>Аудио</p>
            </router-link>
            <router-link
              tag="li"
              to="/profile/additional-materials"
              class="profile-sidebar-menu-item"
            >
              <p>Другие материалы</p>
            </router-link>
          </ul>
        </div>
        <div class="profile-sidebar-menu-sub">
          <ul>
            <router-link tag="li" to="/profile/technical-support" class="profile-sidebar-menu-item">
              <p>Тех. поддержка</p>
            </router-link>
            <router-link tag="li" to="/profile/reviews" class="profile-sidebar-menu-item">
              <p>Написать отзыв</p>
            </router-link>
            <li @click="$router.push('/')" class="profile-sidebar-menu-item">
              <p>Перейти на сайт</p>
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import EventBus from '../../../../utility/event-bus'
import HasPermissionMixin from '../../../mixins/HasPermissionMixin'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'ProfileSidebar',
  mixins: [HasPermissionMixin],
  data() {
    return {
      isOpenMenu: false
    }
  },
  computed: {
    ...mapState({
      boughtCoursesList: state => state.course.boughtCoursesList
    }),
    ...mapGetters({
      user: 'auth/user'
    }),
    isCustomer() {
      return this.user.roles.findIndex(role => role.name === 'customer') !== -1
    }
  },
  directives: {
    ClickOutside
  },
  created() {
    this.getBoughtCoursesList().then(res => {})
    const _this = this
    EventBus.$on('toggleMenu', function(payLoad) {
      _this.isOpenMenu = payLoad
    })
  },
  methods: {
    ...mapActions({
      getBoughtCoursesList: 'course/getBoughtCoursesList'
    }),
    hide() {
      this.isOpenMenu = false
      document.getElementsByTagName('body')[0].style.overflowY = 'scroll'
    }
  }
}
</script>
