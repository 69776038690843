<template>
  <div class="task-buttons">
    <button
      v-if="showCheckButton"
      :disabled="buttonDisabled"
      class="btn-skip"
      type="button"
      @click="$emit('chechButtonClick')"
    >
      <span class="btn-text">Проверить</span>

      <img src="../../../assets/images/icons/arrow-right.svg" alt="">
    </button>
    <button
      v-if="showSkipButton"
      type="button"
      @click="$emit('skipButtonClick')"
    >
      <span class="btn-text">{{ hasAnswer ? 'Дальше' : 'Пропустить' }}</span>
      <img src="../../../assets/images/icons/arrow-right.svg" alt="">
    </button>
  </div>
</template>

<script>
export default {
  name: 'TaskButtons',
  props: {
    showCheckButton: { type: Boolean, default: false },
    showSkipButton: { type: Boolean, default: false },
    hasAnswer: { type: Boolean, default: false },

    buttonDisabled: { type: Boolean, default: true }
  }
}
</script>

<style scoped lang="scss">
$media-sm: 576px;
.task-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 12px;
  padding: 2px;

  button{
    // TODO: fonts and btn styles to global
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    background: var(--light-blue-2);

    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    max-height: 55px;
    min-width: 200px;
    border-radius: 24px;

    &.btn-skip{
      background: transparent;
      border: 2px solid var(--success);
      padding: 14px 22px;

      &:disabled,
      &[disabled]{
        border-color: var(--light-blue-2);
      }
    }

    .btn-text{
      flex-basis: 90%;
    }

    img{
      width: 24px;
      height: 24px;
    }
  }

}
@media (max-width: $media-sm) {
  button{
    flex-grow: 1;
  }
}
</style>
