<template>
  <div class="content">
  <div class="content__container">
    <ul class="content__container__list">
      <transition  name="fade">
        <li
          class="content__container__list__item"
          v-for="(text, idx) in texts"
          v-if="idx === slider.index"
          :key="idx"
        >{{ text }}</li>
      </transition>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Array,
      required: true
    }
  },
  data(){
    return {
      slider: {
        index: 0,
        position: 0,
        maxCount: this.texts.length - 1
      },
    }
  },
  created () {
    this.initSlider()
  },
  methods: {
    initSlider() {
      setInterval(() => {
        if (this.slider.index === this.slider.maxCount) {
          this.slider.index = 0;
          return;
        }

        this.slider.index++;
      }, 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  overflow:hidden;
  font-size: 40px;
  line-height: 40px;
  color: #ecf0f1;
  &__container {
    font-weight: 600;
    overflow: hidden;
    height: 40px;
    &__list {
      padding: 0;
      margin: 0;
      animation-name: change;
      animation-duration: 10s;
      animation-iteration-count: infinite;
      &__item {
        list-style: none;
        line-height:40px;
        margin:0;
      }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
}

</style>
