<template>
  <TaskBase
    :task="task"
    :check-task="checkTask"
    :correct-answer="correctAnswer"
  >
    <template #desktop>
      <div class="position-relative row task-choose-wrapper">
        <div
          class="task-choose-translation-cards col-md-9"
          v-if="task.task && task.task.words && words.length"
        >
          <CrosswordGame
            @onResults="value => (results = value)"
            :isCheckClicked="isCheckBtnClicked"
            :correctAnswer="showCorrectAnswer"
            @answersIncorrect="checkTaskAnswers('incorrect')"
            @answersCorrect="checkTaskAnswers('correct')"
            :wordArray="words"
            :zoom.sync="zoom"
            @ready="() => ready = true"
          />
          <div class="control">
            <button
              class="control-btn control-btn-minus"
              @click="zoomMinus"
            ></button>
            <ul class="control-list">
              <li
                v-for="n in 8"
                :class="{ active: n * 12.5 <= zoom }"
                class="control-list-item"
              ></li>
            </ul>
            <button
              class="control-btn control-btn-plus"
              @click="zoomPlus"
            ></button>
          </div>
        </div>
        <ul class="list-group col-md-3">
          <li
            class="list-group-item border-0 crossword-answer"
            v-for="(word, index) in words"
            :key="index"
            :class="{
              success: results.success.includes(word.number),
              error: results.errors.includes(word.number)
            }"
          >
            {{ word.number }}.
            {{ word.question }}
          </li>
        </ul>
      </div>
    </template>
  </TaskBase>
</template>
<script>
import { mapActions, mapState } from "vuex";
import CrosswordGame from "../../../../components/crosswordGame/index";
import TaskAnswerModal from "../../../../components/TaskAnswerModal";
import VideoAudioGallery from "../../../../components/VideoAudioGallery";
import TaskBase from "../../../../components/TaskBase";
import TaskCheckParamsMixin from "../../../../../mixins/TaskCheckParamsMixin";
import CheckWidthMixin from "../../../../../mixins/CheckWidthMixin";

export default {
  name: "Crossword",
  mixins: [TaskCheckParamsMixin, CheckWidthMixin],
  components: {
    TaskBase,
    VideoAudioGallery,
    CrosswordGame,
    TaskAnswerModal
  },
  data() {
    return {
      data: null,
      openPrompt: false,
      isCheckBtnClicked: false,
      words: [],
      results: { success: [], errors: [] },
      showCorrectAnswer: false,
      zoom: 100,
      ready: false
    };
  },
  computed: {
    ...mapState({
      task: state => state.task.task,
      nextTask: state => state.course.nextTask
    })
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath !== from.fullPath) {
      Object.assign(this.$data, this.$options.data())
      this.taskInit(to);
    }
    next();
  },
  created() {
    this.taskInit(this.$route);
    if (this.isMobile) {
      this.zoom = 40;
    }
  },
  methods: {
    ...mapActions({
      getTask: "task/getTask",
      missingTask: "course/missingTask",
      getMissingTask: "course/getMissingTask",
      checkCrosswordTask: "task/checkCrosswordTask",
      setTaskAnswerModal: "task/setTaskAnswerModal"
    }),
    zoomPlus() {
      if (this.zoom === 100 || this.zoom > 100) return;

      this.zoom++;
    },
    zoomMinus() {
      if (this.zoom === 0 || this.zoom < 0) return;

      this.zoom--;
    },
    taskInit(route) {
      const query = {};
      if (route.query.type) {
        query.type = route.query.type;
      }

      this.getTask({
        id: route.params.taskId,
        query
      }).then(res => {
        this.data = res.data;

        this.words = this.task.task.words.map((word, index) => {
          this.results.success.push(null);
          this.results.errors.push(null);
          return {
            ...word,
            number: index + 1
          };
        });
      });
    },
    correctAnswer() {
      this.showCorrectAnswer = true;
      setInterval(() => {
        this.showCorrectAnswer = false;
      }, 1000);
    },
    missTask() {
      this.missingTask({ task_id: this.$route.params.taskId });
    },
    checkTask() {
      this.isCheckBtnClicked = !this.isCheckBtnClicked;
    },
    components: {
      TaskAnswerModal
    },
    checkTaskAnswers(type) {
      if (!this.ready) return

      this.checkCrosswordTask({
        ...this.checkParams(),
        answer: type,
        type: this.$route.query.type
      }).then(res => {
        if (res.data && res.data.answer) {
          this.setTaskAnswerModal(res.data).then(() => {
            this.$root.$emit("bv::show::modal", "modal-task");
          });
        } else {
          this.setTaskAnswerModal(false).then(() => {
            this.$root.$emit("bv::show::modal", "modal-task");
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.task-choose-translation-cards {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

@media (min-width: 1800px) {
  .task-choose-wrapper {
    flex-basis: calc(75% + 10px);
    max-width: calc(75% + 10px);
  }
  .list-group {
    padding-right: 0;
  }
  .crossword-answer {
    max-width: 100% !important;
  }
}

.crossword-answer {
  background-color: #e6eafc;
  border-radius: 10px !important;
  margin-bottom: 10px;
  padding: 24px 20px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  max-width: 300px;
  min-height: 60px;
  color: #555555;
}

.error {
  background-color: #cd3b45;
  color: white;
}

.success {
  background-color: #219653;
  color: white;
}
</style>
