import { http } from '../../../api'

const statistics = {
  namespaced: true,
  state: {
    statisticsCourse: '',
    statisticsLessons: '',
    statisticsTasks: '',
    statisticsModule: {},
    statisticsTest: {},
    statLoading: true,

  },
  actions: {
    getStatisticsCourse: ({ commit }, params) => {
      commit('setStatLoading', true)
      const q = new URLSearchParams(params)
      return http.get('/statistics-course?' + q.toString()).then(res => {
        commit('setCourseStatistics', res.data)
      }).finally(() => {
        commit('setStatLoading', false)
      })
    },
    getStatisticsLessons: ({ commit }, params) => {
      commit('setStatLoading', true)
      const q = new URLSearchParams(params)
      return http.get('/statistics-lessons?' + q.toString()).then(res => {
        commit('setLessonsStatistics', res.data)
      }).finally(() => {
        commit('setStatLoading', false)
      })
    },
    getStatisticsTasks: ({ commit }, params) => {
      commit('setStatLoading', true)
      const q = new URLSearchParams(params)
      return http.get('/statistics-tasks?' + q.toString()).then(res => {
        commit('setTasksStatistics', res.data)
      }).finally(() => {
        commit('setStatLoading', false)
      })
    },
    getStatisticsModule: ({ commit }, id) => {
      commit('setStatLoading', true)
      return http.get('/statistics-module/' + id).then(res => {
        commit('setModuleStatistics', res.data)
      }).finally(() => {
        commit('setStatLoading', false)
      })
    },
    getStatisticsTest: ({ commit }, params) => {
      commit('setStatLoading', true)
      const q = new URLSearchParams(params)
      return http.get('/statistics-test?' + q.toString()).then(res => {
        commit('setTestStatistics', res.data)
      }).finally(() => {
        commit('setStatLoading', false)
      })
    },
  },
  mutations: {
    setCourseStatistics (state, data) {
      state.statisticsCourse = data.data
    },
    setLessonsStatistics (state, data) {
      state.statisticsLessons = data
    },
    setTasksStatistics (state, data) {
      state.statisticsTasks = data.data
    },
    setModuleStatistics(state, data){
      state.statisticsModule = data
    },
    setTestStatistics(state, data){
      state.statisticsTest = data
    },
    setStatLoading(state, data){
      state.statLoading = data
    }
  },
  getters: {
    statisticsCourse: state => state.statisticsCourse,
    statisticsLessons: state => state.statisticsLessons,
    statisticsTasks: state => state.statisticsLessons,
    statisticsModule: state => state.statisticsModule,
    statisticsTest: state => state.statisticsTest,
    statLoading: state => state.statLoading,

  }
}

export default statistics
