<template>
  <b-modal id="choose-course-modal" ref="choose-course-modal" hide-header hide-footer>
    <div class="form-group">
      <label for="courses">Курс</label>
      <select id="courses" :value="value" class="form-control select-courses" @input="($event) => $emit('input', $event.target.value)">
        <option
          v-for="course in courses"
          :key="course.course.id"
          :value="course.course.id"
          :selected="value === course.id"
        >
          {{course.course.name[$i18n.locale]}}
        </option>
      </select>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CoursesModal',
  props: {
    value: {
      type: String,
      required: false
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      courses: 'course/boughtCoursesList'
    })
  },
  watch: {
    show(newVal){
      if (newVal){
        this.$refs['choose-course-modal'].show()
      }else {
        this.$refs['choose-course-modal'].hide()
      }
    }
  }
}
</script>

<style scoped>

</style>
