var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageCount > 1)?_c('div',{staticClass:"slick-dots__container"},[_c('ul',{staticClass:"slick-dots"},_vm._l((_vm.pageCount),function(num,index){return _c('li',{key:index,class:{
        'slick-active': _vm.currentIndex === index,
        'slick-selected': _vm.answers[index]
          ? _vm.answers[index].answer_id || _vm.answers[index].answered
          : false,
        'slick-correct': _vm.answers[index] ? _vm.answers[index].success : false,
        'slick-incorrect': _vm.answers[index] ? _vm.answers[index].error : false
      },on:{"click":function($event){return _vm.$emit('changePage', index)}}},[_c('button',[_vm._v(_vm._s(index))])])}),0),_c('div',{staticClass:"task-choose-translation-cards-div"})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }