const loading = {
  namespaced: true,
  state: {
    loading: true,
  },
  mutations: {
    setLoading(state,data){
      state.loading = data
    }
  },
  getters: {
    loading: state => state.loading,
  }
}

export default loading
