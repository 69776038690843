<template>
  <div>
    <div class="personal-details statistics">
      <div>
        <div class="top-line">
          <div class="">
            <div class="title-block" v-if="course">
              <div class="d-flex align-items-center">
                <div class="flag">
                  <img :src="course.teaching_language.flag" :alt="course.teaching_language.name[$i18n.locale]">
                </div>
                <div>
                  <div class="tabs-title mb-0">
                    Словарь
                  </div>
                  <div class="subtitle">
                    {{ course.teaching_language.name[$i18n.locale] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="statistics-content">
          <loading v-if="loading"/>
          <div v-else class="vocabulary" v-for="(lesson, index) in lessonsDictionaries">
            <b-card no-body class="mb-1" v-if="lesson.dictionaries.length">
              <b-card-header header-tag="header" role="tab">
                <b-button block v-b-toggle="`accordion${lesson.id}`">
                  <div class="left">
                    <div class="lesson-number">Урок {{ index + 1 }}:</div>
                    <div class="title">{{ lesson.name[$i18n.locale] }}</div>
                  </div>
                  <div class="right">
                    <div class="word-count">{{ lesson.dictionaries.length }} слов</div>
                    <img src="../../../../assets/images/icons/arrow-bottom.svg" alt="">
                  </div>
                </b-button>
              </b-card-header>
              <b-collapse :id="`accordion${lesson.id}`" accordion="my-accordion" role="tabpanel">
                <DictionaryTable
                  @onDestroy="destroy"
                  :columns="lesson.dictionaries"
                  :fields="fields"
                  :example="example"
                />
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import Loading from '../../../components/Loading'
import DictionaryTable from '../../../components/DictionaryTable'

export default {
  name: 'Vocabulary',
  components: {
    DictionaryTable,
    Loading
  },
  data () {
    return {
      modalVideo: {
        youtube: 'https://www.youtube.com/watch?v=RQW3zC5QaY4'
      },
      fields: [
        {
          label: '',
          action: true,
          actionType: 'speak',
          key: 'word',
          language: 'language.code'
        },
        {
          label: '',
          key: 'word'
        },
        {
          label: 'Транскрипция',
          key: 'transcription'
        },
        {
          label: 'Русский',
          key: 'translate'
        },
        {
          label: '',
          action: true,
          actionType: 'delete',
          key: 'id'
        },
      ],
      example: {
        key: 'example',
        type: 'array',
        wordKey: 'text',
        translateKey: 'translate'
      }
    }
  },
  created () {
    this.init(this.$route.params.courseId)
  },
  computed: {
    ...mapGetters({
      course: 'course/course',
      loading: 'loading/loading',
      lessonsDictionaries: 'dictionary/lessonsDictionaries'
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.courseId !== from.params.courseId) {
      this.init(to.params.courseId)
    }
    next()
  },
  methods: {
    ...mapActions({
      getCourse: 'course/getCourse',
      delete: 'dictionary/delete',
      getByCourseId: 'dictionary/getByCourseId'
    }),
    async init (id) {
      await this.getCourse({ id })
      this.fields = this.fields.map(field => field.key === 'word' && !field.action ? {
        ...field,
        label: this.course.teaching_language.name[this.$i18n.locale]
      } : field)
      this.getByCourseId(id)
    },
    destroy (id) {
      Swal.fire({
        text: 'Вы точно хотите удалить?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.delete({
            setter: 'deleteLessonDictionary',
            id
          })
        }
      })
    }
  }
}
</script>
<style>
.statistics .statistics-content .vocabulary .card .card-body table tr td, .statistics .statistics-content .vocabulary .card-header .card-body table tr td {
  padding: 10px;
  border-bottom: none;
}

.statistics .statistics-content .vocabulary .card .not-collapsed, .statistics .statistics-content .vocabulary .card-header .not-collapsed {
  border-radius: 0;
}
</style>
