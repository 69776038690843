<template v-for="(newLine, index) in breaks">
    <div class="break" :key="index"></div>
</template>

<script>
export default {
  name: 'Break',
  props: {
    breaks: {
      type: Array,
      default: []
    }
  }

}
</script>

<style >
.ml-1 + .break{
  height: 0
}
.break{
  flex-basis: 100%;
  height: 22px;
}
</style>
