<template>
    <div class="container-fluid">
      <UpHeader/>
      <header class="row justify-content-center header">
        <Header/>
      </header>
      <main class="main-content row">
          <router-view/>
      </main>
      <Footer/>
    </div>
</template>

<script>
import Header from '../components/Header'
import UpHeader from '../components/UpHeader'
import Footer from '../components/Footer'

export default {
  name: 'IndexLayout',
  components: {
    Footer,
    Header,
    UpHeader
  }

}
</script>
<style>
</style>
