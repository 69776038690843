import { http } from '../../../api'

const dynamicPage = {
  namespaced: true,
  state: {
    page: {}
  },
  actions: {
    dynamicPageByKey: ({ commit }, params) => {
      return http.get('/dynamic-page-by-key/' + params.key).then(res => {
        commit('setDynamicPage', res.data.data)
      })
    }
  },
  mutations: {
    setDynamicPage (state, data) {
      state.page = data
    }
  },
  getters: {
    dynamicPage: state => state.page
  }
}

export default dynamicPage
