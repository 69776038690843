import { mapGetters } from 'vuex'

export default {
  data(){
    return {
      tariffActive: {
        name: {}
      },
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated'
    })
  },
  methods: {
    showBuyingCourseModal(tariff = {}) {
      if (!this.isAuthenticated) {
        this.setLoginModal(false).then(() => {
          this.$root.$emit('bv::show::modal', 'login-modal')
        })
        return
      }
      this.tariffActive = tariff
      this.$refs.chooseCoursePlan.$children[0].show()
    },
    selectTariff (tariff) {
      this.tariffActive = tariff
    },
  }
}
