import { http } from '../../../api'

const news = {
  namespaced: true,
  state: {
    allNews: [],
    news: {},
    pagination: {}
  },
  actions: {
    getNews: ({ commit }, params) => {
      commit('loading/setLoading', true, { root: true })
      const q = new URLSearchParams(params)
      return http.get('news?' + q.toString()).then(res => {
        commit('setNews', res.data)
      }).finally(() => {
        commit('loading/setLoading', false, { root: true })
      })
    },
    getNewsDetail: ({ commit }, params) => {
      return http.get('news/' + params.id).then(res => {
        commit('setNewsDetail', res.data.data)
        return res
      })
    }
  },
  mutations: {
    setNews (state, data) {
      state.allNews = data.data
      state.pagination = data.pagination
    },
    setNewsDetail (state, data) {
      state.news = data
    }
  },
  getters: {
    allNews: state => state.allNews,
    news: state => state.news,
    pagination: state => state.pagination
  }
}

export default news
