<template>
  <div>
    <div class="personal-details statistics">
      <div>
        <div class="top-line">
          <div class="">
            <div class="title-block">
              <div class="d-flex align-items-center">
                <div class="flag">
                  <img src="../../../../assets/images/flags/de.png" alt="">
                </div>
                <div>
                  <div class="tabs-title">
                    Грамматика
                  </div>
                  <div class="subtitle">
                    Немецкий язык
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="statistics-content">
          <div class="grammar">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <b-button block v-b-toggle.accordion-1>
                  <div>
                    <div class="title">Личные местоимения</div>
                  </div>
                  <div>
                    <img src="../../../../assets/images/icons/arrow-bottom.svg" alt="">
                  </div>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <table>
                    <tr>
                      <th>Кто</th>
                      <th>Кому</th>
                      <th>Кого</th>
                    </tr>
                    <tr>
                      <td>
                        <div class="td">
                          <div class="item">я</div>
                          <div class="item item-audio">
                            <div>ich</div>
                            <div class="circle">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10">
                                <g transform="translate(-657 -678)">
                                  <g transform="translate(495.144 568.243)">
                                    <g>
                                      <path d="M168.378,114.773c0,1.393,0,2.786,0,4.18a.676.676,0,0,1-.346.7.638.638,0,0,1-.738-.166c-.82-.7-1.649-1.377-2.469-2.072a.675.675,0,0,0-.479-.175c-.592.01-1.186.006-1.779,0-.5,0-.712-.224-.713-.742q0-1.741,0-3.483c0-.518.214-.738.714-.741.593,0,1.186-.007,1.778,0a.676.676,0,0,0,.479-.177c.827-.7,1.663-1.389,2.491-2.09a.619.619,0,0,1,.716-.146.658.658,0,0,1,.344.669C168.374,111.948,168.378,113.361,168.378,114.773Zm-1.2-3.052c-.662.553-1.278,1.062-1.887,1.579a.875.875,0,0,1-.61.227c-.481-.008-.962,0-1.443-.005-.156,0-.206.048-.2.214.008.676.009,1.353,0,2.03,0,.178.048.233.218.23.49-.01.981,0,1.471,0a.816.816,0,0,1,.558.214c.566.483,1.139.957,1.71,1.433.052.044.1.126.188.085Z" fill="#fff"/>
                                      <path d="M174.857,114.953a6.84,6.84,0,0,1-1.716,4.458,1.86,1.86,0,0,1-.2.2.567.567,0,0,1-.785-.032.632.632,0,0,1-.059-.818c.119-.16.265-.3.389-.454a5.62,5.62,0,0,0-.223-7.366.919.919,0,0,1-.274-.427.639.639,0,0,1,.278-.674.566.566,0,0,1,.736.118,6.539,6.539,0,0,1,1.482,2.491A7.691,7.691,0,0,1,174.857,114.953Z" fill="#fff"/>
                                      <path d="M171.893,114.763a3.786,3.786,0,0,1-.888,2.5.605.605,0,0,1-.923.143.646.646,0,0,1,.036-.966,2.435,2.435,0,0,0-.016-3.388.645.645,0,0,1-.144-.788.59.59,0,0,1,.638-.3.68.68,0,0,1,.388.261A3.79,3.79,0,0,1,171.893,114.763Z" fill="#fff"/>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="td">
                          <div class="item">мне</div>
                          <div class="item item-audio">
                            <div>mir</div>
                            <div class="circle">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10">
                                <g transform="translate(-657 -678)">
                                  <g transform="translate(495.144 568.243)">
                                    <g>
                                      <path d="M168.378,114.773c0,1.393,0,2.786,0,4.18a.676.676,0,0,1-.346.7.638.638,0,0,1-.738-.166c-.82-.7-1.649-1.377-2.469-2.072a.675.675,0,0,0-.479-.175c-.592.01-1.186.006-1.779,0-.5,0-.712-.224-.713-.742q0-1.741,0-3.483c0-.518.214-.738.714-.741.593,0,1.186-.007,1.778,0a.676.676,0,0,0,.479-.177c.827-.7,1.663-1.389,2.491-2.09a.619.619,0,0,1,.716-.146.658.658,0,0,1,.344.669C168.374,111.948,168.378,113.361,168.378,114.773Zm-1.2-3.052c-.662.553-1.278,1.062-1.887,1.579a.875.875,0,0,1-.61.227c-.481-.008-.962,0-1.443-.005-.156,0-.206.048-.2.214.008.676.009,1.353,0,2.03,0,.178.048.233.218.23.49-.01.981,0,1.471,0a.816.816,0,0,1,.558.214c.566.483,1.139.957,1.71,1.433.052.044.1.126.188.085Z" fill="#fff"/>
                                      <path d="M174.857,114.953a6.84,6.84,0,0,1-1.716,4.458,1.86,1.86,0,0,1-.2.2.567.567,0,0,1-.785-.032.632.632,0,0,1-.059-.818c.119-.16.265-.3.389-.454a5.62,5.62,0,0,0-.223-7.366.919.919,0,0,1-.274-.427.639.639,0,0,1,.278-.674.566.566,0,0,1,.736.118,6.539,6.539,0,0,1,1.482,2.491A7.691,7.691,0,0,1,174.857,114.953Z" fill="#fff"/>
                                      <path d="M171.893,114.763a3.786,3.786,0,0,1-.888,2.5.605.605,0,0,1-.923.143.646.646,0,0,1,.036-.966,2.435,2.435,0,0,0-.016-3.388.645.645,0,0,1-.144-.788.59.59,0,0,1,.638-.3.68.68,0,0,1,.388.261A3.79,3.79,0,0,1,171.893,114.763Z" fill="#fff"/>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="td">
                          <div class="item">меня</div>
                          <div class="item item-audio">
                            <div>mich</div>
                            <div class="circle">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10">
                                <g transform="translate(-657 -678)">
                                  <g transform="translate(495.144 568.243)">
                                    <g>
                                      <path d="M168.378,114.773c0,1.393,0,2.786,0,4.18a.676.676,0,0,1-.346.7.638.638,0,0,1-.738-.166c-.82-.7-1.649-1.377-2.469-2.072a.675.675,0,0,0-.479-.175c-.592.01-1.186.006-1.779,0-.5,0-.712-.224-.713-.742q0-1.741,0-3.483c0-.518.214-.738.714-.741.593,0,1.186-.007,1.778,0a.676.676,0,0,0,.479-.177c.827-.7,1.663-1.389,2.491-2.09a.619.619,0,0,1,.716-.146.658.658,0,0,1,.344.669C168.374,111.948,168.378,113.361,168.378,114.773Zm-1.2-3.052c-.662.553-1.278,1.062-1.887,1.579a.875.875,0,0,1-.61.227c-.481-.008-.962,0-1.443-.005-.156,0-.206.048-.2.214.008.676.009,1.353,0,2.03,0,.178.048.233.218.23.49-.01.981,0,1.471,0a.816.816,0,0,1,.558.214c.566.483,1.139.957,1.71,1.433.052.044.1.126.188.085Z" fill="#fff"/>
                                      <path d="M174.857,114.953a6.84,6.84,0,0,1-1.716,4.458,1.86,1.86,0,0,1-.2.2.567.567,0,0,1-.785-.032.632.632,0,0,1-.059-.818c.119-.16.265-.3.389-.454a5.62,5.62,0,0,0-.223-7.366.919.919,0,0,1-.274-.427.639.639,0,0,1,.278-.674.566.566,0,0,1,.736.118,6.539,6.539,0,0,1,1.482,2.491A7.691,7.691,0,0,1,174.857,114.953Z" fill="#fff"/>
                                      <path d="M171.893,114.763a3.786,3.786,0,0,1-.888,2.5.605.605,0,0,1-.923.143.646.646,0,0,1,.036-.966,2.435,2.435,0,0,0-.016-3.388.645.645,0,0,1-.144-.788.59.59,0,0,1,.638-.3.68.68,0,0,1,.388.261A3.79,3.79,0,0,1,171.893,114.763Z" fill="#fff"/>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="td">
                          <div class="item">ты</div>
                          <div class="item item-audio">
                            <div>du</div>
                            <div class="circle">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10">
                                <g transform="translate(-657 -678)">
                                  <g transform="translate(495.144 568.243)">
                                    <g>
                                      <path d="M168.378,114.773c0,1.393,0,2.786,0,4.18a.676.676,0,0,1-.346.7.638.638,0,0,1-.738-.166c-.82-.7-1.649-1.377-2.469-2.072a.675.675,0,0,0-.479-.175c-.592.01-1.186.006-1.779,0-.5,0-.712-.224-.713-.742q0-1.741,0-3.483c0-.518.214-.738.714-.741.593,0,1.186-.007,1.778,0a.676.676,0,0,0,.479-.177c.827-.7,1.663-1.389,2.491-2.09a.619.619,0,0,1,.716-.146.658.658,0,0,1,.344.669C168.374,111.948,168.378,113.361,168.378,114.773Zm-1.2-3.052c-.662.553-1.278,1.062-1.887,1.579a.875.875,0,0,1-.61.227c-.481-.008-.962,0-1.443-.005-.156,0-.206.048-.2.214.008.676.009,1.353,0,2.03,0,.178.048.233.218.23.49-.01.981,0,1.471,0a.816.816,0,0,1,.558.214c.566.483,1.139.957,1.71,1.433.052.044.1.126.188.085Z" fill="#fff"/>
                                      <path d="M174.857,114.953a6.84,6.84,0,0,1-1.716,4.458,1.86,1.86,0,0,1-.2.2.567.567,0,0,1-.785-.032.632.632,0,0,1-.059-.818c.119-.16.265-.3.389-.454a5.62,5.62,0,0,0-.223-7.366.919.919,0,0,1-.274-.427.639.639,0,0,1,.278-.674.566.566,0,0,1,.736.118,6.539,6.539,0,0,1,1.482,2.491A7.691,7.691,0,0,1,174.857,114.953Z" fill="#fff"/>
                                      <path d="M171.893,114.763a3.786,3.786,0,0,1-.888,2.5.605.605,0,0,1-.923.143.646.646,0,0,1,.036-.966,2.435,2.435,0,0,0-.016-3.388.645.645,0,0,1-.144-.788.59.59,0,0,1,.638-.3.68.68,0,0,1,.388.261A3.79,3.79,0,0,1,171.893,114.763Z" fill="#fff"/>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="td">
                          <div class="item">тебе</div>
                          <div class="item item-audio">
                            <div>dir</div>
                            <div class="circle">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10">
                                <g transform="translate(-657 -678)">
                                  <g transform="translate(495.144 568.243)">
                                    <g>
                                      <path d="M168.378,114.773c0,1.393,0,2.786,0,4.18a.676.676,0,0,1-.346.7.638.638,0,0,1-.738-.166c-.82-.7-1.649-1.377-2.469-2.072a.675.675,0,0,0-.479-.175c-.592.01-1.186.006-1.779,0-.5,0-.712-.224-.713-.742q0-1.741,0-3.483c0-.518.214-.738.714-.741.593,0,1.186-.007,1.778,0a.676.676,0,0,0,.479-.177c.827-.7,1.663-1.389,2.491-2.09a.619.619,0,0,1,.716-.146.658.658,0,0,1,.344.669C168.374,111.948,168.378,113.361,168.378,114.773Zm-1.2-3.052c-.662.553-1.278,1.062-1.887,1.579a.875.875,0,0,1-.61.227c-.481-.008-.962,0-1.443-.005-.156,0-.206.048-.2.214.008.676.009,1.353,0,2.03,0,.178.048.233.218.23.49-.01.981,0,1.471,0a.816.816,0,0,1,.558.214c.566.483,1.139.957,1.71,1.433.052.044.1.126.188.085Z" fill="#fff"/>
                                      <path d="M174.857,114.953a6.84,6.84,0,0,1-1.716,4.458,1.86,1.86,0,0,1-.2.2.567.567,0,0,1-.785-.032.632.632,0,0,1-.059-.818c.119-.16.265-.3.389-.454a5.62,5.62,0,0,0-.223-7.366.919.919,0,0,1-.274-.427.639.639,0,0,1,.278-.674.566.566,0,0,1,.736.118,6.539,6.539,0,0,1,1.482,2.491A7.691,7.691,0,0,1,174.857,114.953Z" fill="#fff"/>
                                      <path d="M171.893,114.763a3.786,3.786,0,0,1-.888,2.5.605.605,0,0,1-.923.143.646.646,0,0,1,.036-.966,2.435,2.435,0,0,0-.016-3.388.645.645,0,0,1-.144-.788.59.59,0,0,1,.638-.3.68.68,0,0,1,.388.261A3.79,3.79,0,0,1,171.893,114.763Z" fill="#fff"/>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="td">
                          <div class="item">тебя</div>
                          <div class="item item-audio">
                            <div>dich</div>
                            <div class="circle">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10">
                                <g transform="translate(-657 -678)">
                                  <g transform="translate(495.144 568.243)">
                                    <g>
                                      <path d="M168.378,114.773c0,1.393,0,2.786,0,4.18a.676.676,0,0,1-.346.7.638.638,0,0,1-.738-.166c-.82-.7-1.649-1.377-2.469-2.072a.675.675,0,0,0-.479-.175c-.592.01-1.186.006-1.779,0-.5,0-.712-.224-.713-.742q0-1.741,0-3.483c0-.518.214-.738.714-.741.593,0,1.186-.007,1.778,0a.676.676,0,0,0,.479-.177c.827-.7,1.663-1.389,2.491-2.09a.619.619,0,0,1,.716-.146.658.658,0,0,1,.344.669C168.374,111.948,168.378,113.361,168.378,114.773Zm-1.2-3.052c-.662.553-1.278,1.062-1.887,1.579a.875.875,0,0,1-.61.227c-.481-.008-.962,0-1.443-.005-.156,0-.206.048-.2.214.008.676.009,1.353,0,2.03,0,.178.048.233.218.23.49-.01.981,0,1.471,0a.816.816,0,0,1,.558.214c.566.483,1.139.957,1.71,1.433.052.044.1.126.188.085Z" fill="#fff"/>
                                      <path d="M174.857,114.953a6.84,6.84,0,0,1-1.716,4.458,1.86,1.86,0,0,1-.2.2.567.567,0,0,1-.785-.032.632.632,0,0,1-.059-.818c.119-.16.265-.3.389-.454a5.62,5.62,0,0,0-.223-7.366.919.919,0,0,1-.274-.427.639.639,0,0,1,.278-.674.566.566,0,0,1,.736.118,6.539,6.539,0,0,1,1.482,2.491A7.691,7.691,0,0,1,174.857,114.953Z" fill="#fff"/>
                                      <path d="M171.893,114.763a3.786,3.786,0,0,1-.888,2.5.605.605,0,0,1-.923.143.646.646,0,0,1,.036-.966,2.435,2.435,0,0,0-.016-3.388.645.645,0,0,1-.144-.788.59.59,0,0,1,.638-.3.68.68,0,0,1,.388.261A3.79,3.79,0,0,1,171.893,114.763Z" fill="#fff"/>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="grammar">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <b-button block v-b-toggle.accordion-2>
                  <div>
                    <div class="title">Суфиксы</div>
                    <div class="subtitle">Помощники про определения рода существительного</div>
                  </div>
                  <div>
                    <img src="../../../../assets/images/icons/arrow-bottom.svg" alt="">
                  </div>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  Суфиксы
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="grammar">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <b-button block v-b-toggle.accordion-3>
                  <div>
                    <div class="title">Спряжение глаголов</div>
                    <div class="subtitle">Настоящее время</div>
                  </div>
                  <div>
                    <img src="../../../../assets/images/icons/arrow-bottom.svg" alt="">
                  </div>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  Спряжение глаголов
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Grammar',
  data () {
    return {
    }
  }
}
</script>
