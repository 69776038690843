import { http } from '../../../api'

const encyclopedia = {
  namespaced: true,
  state: {
    phones: [],
  },
  actions: {
    get: ({ commit }, params) => {
      return http.get('/phones', params).then(res => {
        commit('setPhones', res.data)
      })
    },
  },
  mutations: {
    setPhones (state, data) {
      state.phones = data
    },
  },
  getters: {
    phones: state => state.phones,
  }
}

export default encyclopedia
