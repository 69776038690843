<template>
  <div class="course-item-carousel">
    <div class="course-item">
      <div class="background">
        <img v-if="lesson.image_path" :src="lesson.image_path"
             alt="">
        <img v-else src="@/assets/images/defaul-image.png" alt="">
      </div>
      <div class="content">
        <div class="flag">
          <img v-if="lesson.flag_path"
               :src="lesson.flag_path" alt="Cn_flag">
        </div>
        <div class="course-name">{{ lesson.name[$i18n.locale] }}</div>
        <div class="short-description">{{ lesson.course_name[$i18n.locale] }}</div>
        <div class="action">
          <button type="button" @click="$router.push('/profile/free-lesson/' + lesson.id)">ПРОЙТИ УРОК
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LessonCard',
  props: {
    lesson: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
