<template>
  <div ref="translate" class="position-relative">
    <div v-if="lang" class="position-absolute bg-white translate-modal" ref="modal" :style="styleObject">
      <PlayerButton
          :play="play"
          :answer="word"
          :lang="lang.code"
          height="40px"
          width="40px"
          color="#D3D7E5"
          class="sound-button"
      >
        <template #play>
          <img src="@/assets/images/icons/sound.svg" width="24px" alt="play"/>
        </template>
      </PlayerButton>
      <div class="translate-text">{{ translatedText }}</div>
      <button class="save-word" :disabled="loading" @click="storeTranslate">добавить</button>
    </div>
    <slot/>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import PlayerButton from './PlayerButton'

export default {
  name: 'Translateable',
  components: { PlayerButton },
  props: {
    lang: {
      type: Object
    },
    lesson_id: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      styleObject: {
        display: 'none'
      },
      translatedText: '',
      left: 0,
      needed: false,
      translate: null,
      loading: false,
      play: true,
      word: null,
      startListener: false
    }
  },
  computed: {
    ...mapGetters({
      task: 'task/task',
      storeLoading: 'task/taskLoading'
    })
  },
  async mounted () {
    this.$nextTick(() => {
      this.listener()
    })
  },
  watch: {
    storeLoading () {
      this.$nextTick(() => {
        this.listener()
      })
    }
  },
  methods: {
    ...mapActions({
      store: 'dictionary/store'
    }),
    listener () {
      const translate = this.$refs.translate
      if (!translate) {
        return
      }

      translate.addEventListener('mousedown', e => {
        this.left = e.pageX
      })
      translate.addEventListener('mouseup', e => {
        if (this.$refs.modal.contains(e.target)) {
          return
        }

        const parent = translate.getBoundingClientRect()
        const left = this.left < e.pageX ? this.left : e.pageX

        const y = e.pageY - parent.top + 30
        const x = left - parent.left - 50

        this.$set(this.styleObject, 'left', x + 'px')
        this.$set(this.styleObject, 'top', y + 'px')
        const text = window.getSelection().toString().replace(/\n/g, '')

        if (text.length) {
          this.getTranslate(text)
          this.$set(this.styleObject, 'display', 'flex')
        } else {
          this.$set(this.styleObject, 'display', 'none')
        }
      })
    },
    async getTranslate (text) {
      try {
        const { data } = await axios.get('https://dictionary.yandex.net/api/v1/dicservice.json/lookup', {
          params: {
            key: process.env.VUE_APP_YANDEX_KEY,
            lang: `${this.lang.code}-ru`,
            text: text,
            ui: 'ru',
            flags: 0x0004
          }
        })

        console.log('def', data)

        if (!data.def.length) {
          this.translatedText = 'Перевод не найден'
          this.translate = this.word = null
          this.needed = false
          return
        }

        this.translate = data.def[0]
        this.translatedText = this.translate.tr[0].text
        this.word = this.translate.text
      } catch (e) {
        console.error(e.message)
      }
    },
    async storeTranslate () {
      if (!this.translate) {
        return
      }

      this.loading = true

      const def = this.translate
      let examples = []
      if (def.tr[0].ex) {
        examples = def.tr[0].ex.map(item => {
          return {
            text: item.text,
            translate: item.tr[0].text
          }
        })
      }

      const params = {
        translate: def.tr[0].text,
        lesson_id: this.lesson_id,
        lang_id: this.lang.id,
        word: def.text,
        transcription: def.ts,
        example: examples
      }

      try {
        await this.store(params)
        this.$toasted.success('Слово успешно добавлен').goAway(1500)
      } catch (e) {
        this.$toasted.error('Такое значение поля \"Слова\" уже существует.').goAway(2500)
      }
      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
.translate-modal {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 44px;
  padding: 10px;
  z-index: 1;
  position: relative;

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid white;
    top: -9px;
    left: 50%;
  }

  .sound-button:hover {
    background-color: #CC3C44 !important;
  }

  .translate-text {
    margin: 0 20px;
  }

  .save-word {
    height: 40px;
    padding: 15px 20px;
    font-size: 10px;
    background-color: #CC3C44;
    border: none;
    border-radius: 44px;
    color: white;
  }
}
</style>
