import { http } from '../../../api'

const contact = {
  namespaced: true,
  state: {
  },
  actions: {
    store({commit}, params){
      return http.post('contact', params).then(res => {
        return res.data
      })
    },
  },
  mutations: {

  },
  getters: {

  }
}

export default contact
