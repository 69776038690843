<template>
  <div class="reset-password page-container">
    <div class="reset-password-form">
      <div class="title">Восстановление пароля</div>
      <div class="subtitle">Введите новый пароль</div>
      <div class="reset-password-image">
        <img src="../../../assets/images/reset-password.png" alt="">
      </div>
      <ValidationObserver tag="form" class="form" ref="resetPasswordForm" @submit.prevent="resetPasswordFormSubmit()">
        <ValidationProvider tag="div" v-slot="{ classes }" rules="required|min:8" class="form-group">
          <label for="reg-password">Новый пароль</label>
          <input type="password" class="form-control" v-model="resetPasswordFormData.new_password" id="reg-password" :class="classes" placeholder="Заполнить поле">
        </ValidationProvider>
        <ValidationProvider tag="div" v-slot="{ classes }" rules="required|min:8" class="form-group">
          <label for="repeatPassword">Подтвердите новый пароль</label>
          <input type="password" class="form-control" v-model="resetPasswordFormData.new_password_confirmation" id="repeatPassword" :class="classes" placeholder="Заполнить поле">
        </ValidationProvider>
        <div class="login-registration">
          <button type="submit" class="btn-hover">ИЗМЕНТЬ ПАРОЛЬ</button>
        </div>
        <div class="mt-1 text-center">
          <small class="text-danger">{{errorMsg}}</small>
        </div>
      </ValidationObserver>
    </div>
    <div class="reset-password-image">
      <img src="../../../assets/images/reset-password.png" alt="">
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'ResetPassword',
  data () {
    return {
      errorMsg: '',
      resetPasswordFormData: {}
    }
  },
  created () {
    if (this.$route.query.token && this.$route.query.token) {
      this.resetPasswordFormData.token = this.$route.query.token
      this.resetPasswordFormData.email = this.$route.query.email
    } else {
      this.$router.push('/404')
    }
  },
  components: {

  },
  computed: {
    ...mapState({
    })
  },
  methods: {
    ...mapActions({
      resetPassword: 'auth/resetPassword'
    }),
    resetPasswordFormSubmit () {
      this.errorMsg = ''
      this.$refs.resetPasswordForm.validate().then(success => {
        if (success) {
          this.resetPassword(this.resetPasswordFormData).then(res => {
            this.$toasted.success('Пароль успешно изменен!').goAway(1500)
            setTimeout(() => {
              this.$router.push('/')
            }, 500)
          }).catch(err => {
            if (err.response.status === 422) {
              if (err.response.data.errors) {
                const data = err.response.data.errors
                const keys = Object.keys(data)
                const msg = data[keys[0]][0]
                this.errorMsg = msg
                return false
              } else if (err.response.data.message) {
                this.errorMsg = err.response.data.message
                return false
              }
            } else {
              this.$toasted.error('Что-то пошло не так').goAway(1500)
            }
          })
        }
      })
    }
  }
}
</script>
