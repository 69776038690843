import { http } from '../../../api'

const search = {
  namespaced: true,
  state: {
    searchAnswersList: {},
    pagination: {},
    results: {}
  },
  actions: {
    getSearchAnswersList: ({ commit }, params) => {
      const q = new URLSearchParams(params)
      return http.get('/search?' + q.toString()).then(res => {
        commit('setSearchAnswersList', res.data)
      })
    },
    search({commit}, params){
      return http.get('/search/ajax', {params}).then(res =>{
        commit('setResults', res.data)
      })
    }
  },
  mutations: {
    setSearchAnswersList (state, data) {
      state.searchAnswersList = Array.isArray(data.data) ? null : data.data
      state.pagination = data.pagination
    },
    setResults(state, data){
      state.results = data
    }
  },
  getters: {
    searchAnswersList: state => state.searchAnswersList,
    pagination: state => state.pagination,
    results: state => state.results
  }
}

export default search
