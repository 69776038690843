import { http } from '../../../api'

const dictionary = {
  namespaced: true,
  state: {
    dictionaries: [],
    lessonsDictionaries: []
  },
  actions: {
    store ({ commit }, params) {
      return new Promise((resolve, reject) => {
        http.post('/dictionary', params).then(({ data }) => {
          commit('pushDictionary', data.data)
          resolve(data)
        }).catch(e => {
          reject(e)
        })
      })
    },
    get ({ commit }, params) {
      return http.get('/dictionary', { params }).then(({ data }) => {
        commit('setDictionaries', data)
      })
    },
    delete ({ commit }, {setter, id}) {
      return http.delete(`/dictionary/${id}`).then(() => {
        commit(setter, id)
      })
    },
    getByCourseId ({ commit }, id) {
      commit('loading/setLoading', true, {root: true})
      return http.get(`/dictionary/course/${id}`).then(({ data }) => {
        commit('setLessonDictionaries', data)
      }).finally(() => {
        commit('loading/setLoading', false, {root: true})
      })
    }
  },
  mutations: {
    setDictionaries (state, data) {
      state.dictionaries = data
    },
    deleteDictionary (state, data) {
      state.dictionaries = state.dictionaries.filter(dictionary => dictionary.id !== data)
    },
    pushDictionary (state, data) {
      state.dictionaries.push(data)
    },
    setLessonDictionaries (state, data) {
      state.lessonsDictionaries = data
    },
    deleteLessonDictionary(state, data){
      state.lessonsDictionaries = state.lessonsDictionaries.filter(value => {
        return value.dictionaries = value.dictionaries.filter(item => item.id !== data)
      })
    }

  },
  getters: {
    dictionaries: state => state.dictionaries,
    lessonsDictionaries: state => state.lessonsDictionaries
  }
}

export default dictionary

