<template>
  <div>
    <div class="personal-details statistics">
      <div>
        <div class="top-line" v-if="language">
          <div class="">
            <div class="title-block">
              <div class="d-flex align-items-center">
                <div class="flag">
                  <img :src="language.flag_path" :alt="language.code">
                </div>
                <div>
                  <div class="tabs-title">
                    {{title}}
                  </div>
                  <div class="subtitle">
                    {{ language.name[$i18n.locale] }}
                  </div>
                </div>
              </div>
              <div class="tabs-info">
                {{ subTitle }}
              </div>
            </div>
          </div>
        </div>
        <Loading v-if="$store.getters['statistics/statLoading']" />
        <div v-else class="statistics-content">
          <button class="cancle" type="button" @click="$router.back()">
            <img src="../../assets/images/icons/arrow-red.svg" alt="back">
            {{ textBack }}
          </button>
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading'
export default {
  name: 'StatisticsBase',
  components: { Loading },
  props: {
    language: {
      type: [Object],
      default: null
    },
    subTitle: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Статистика обучения'
    }
  },
  data () {
    return {
      textBack: '',
    }
  },
  created () {
    this.backText()
  },
  methods: {
    backText () {
      if (this.$route.query.module_id) {
        this.textBack = 'Назад к статистике модуля '
      } else if (this.$route.query.lesson_id) {
        this.textBack = 'Назад к статистике уроков '
      } else {
        this.textBack = 'Назад к общей статистике'
      }
    }
  },
}
</script>

