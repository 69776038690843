import { http } from '../../../api'

const courseModule = {
  namespaced: true,
  state: {
    courseModule: {},
  },
  actions: {
    getCourseModule: ({commit}, id) => {
      return http.get(`/courseModule/${id}`).then(res => {
        commit('setCourseModule', res.data)
      })
    },
  },
  mutations: {
    setCourseModule (state, data) {
      state.courseModule = data
    },
  },
  getters: {
    courseModule: state => state.courseModule
  }
}

export default courseModule
