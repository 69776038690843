import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  computed:{
    ...mapState({
      videoCallUsers: state => state.courseChat.videoCallUsers,
    }),
    ...mapGetters({
      peer: 'courseChat/peer',
      stream: 'courseChat/stream',
      mediaConnection: 'courseChat/mediaConnection',
      channel: 'courseChat/channel',
      callPlaced: 'courseChat/callPlaced'
    }),
  },
  methods: {
    ...mapMutations({
      setVideoCallUsers: 'courseChat/setVideoCallUser',
      setStream: 'courseChat/setStream',
      setCallPlaced: 'courseChat/setCallPlaced'
    }),
    ...mapActions({
      videoCallClose: 'courseChat/closeCall'
    }),
    removeTrack (mediaStream) {
      mediaStream.getTracks().map(track => {
        track.stop()
        mediaStream.removeTrack(track)
      })
    },
    closeCall (withoutEvent = false) {
      if (!this.stream) {
        return false
      }

      this.videoCallClose()

      if (!withoutEvent) {
        this.channel.whisper('closeCall', {
          peer_id: this.peer.id,
        })
      }

      this.removeTrack(this.stream)
      this.videoCallUsers.map(video => {
        this.removeTrack(video.stream)
      })

      if (this.videoCallUsers.length) {
        this.setVideoCallUsers({
          data: null,
          type: 'clear'
        })
      }

      this.setStream(null)

      if (this.mediaConnection) {
        this.mediaConnection.close()
      }

      this.setCallPlaced(false)
    },
    openVideoChat(userData, defMute = true){
      this.setMediaConnection(this.peer.call(userData.peer_id, this.stream))
      this.mediaConnection.on('stream', stream => {
        const videoObject = {
          stream, ...userData,
        }

        if (defMute){
          videoObject.videoMute = false
          videoObject.audioMute = false
        }

        this.setVideoCallUsers({
          data: videoObject,
          type: 'push'
        })
      })
      this.mediaConnection.on('close', e => {
        this.setCallPlaced(false)
      })
    }
  }
}
