import { http } from '../../../api'

const tariff = {
  namespaced: true,
  state: {
    tariffs: [],
  },
  actions: {
    getTariffs: ({ commit }) => {
      return http.get('tariffs').then(res => {
        commit('setTariffs', res.data.data)
        return res.data.data
      })
    },
  },
  mutations: {
    setTariffs (state, data) {
      state.tariffs = data
    },
  },
  getters: {
    tariffs: state => state.tariffs
  }
}

export default tariff
