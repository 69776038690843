<template>
  <div :class="{showed: showed}"
       class="lesson-task" :style="`height:${height};width:${width}`">
    <img :height="heightIcon" :width="widthIcon" :src="cover(icon)" alt="lesson"/>
  </div>
</template>

<script>
export default {
  name: 'LessonTaskButton',
  props: {
    showed: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'medal.png'
    },
    height: {
      type: String,
      default: '40px'
    },
    width: {
      type: String,
      default: '40px'
    },
    heightIcon: {
      type: String,
      default: '24px'
    },
    widthIcon: {
      type: String,
      default: '17px'
    }
  },
  methods: {
    cover (url) {
      return require('../../assets/images/' + url)
    }
  }
}
</script>

<style scoped lang="scss">
.lesson-task {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6eafc;
  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;

  &.showed {
    background-color: #D3434C !important;
  }
}

</style>
