<template>
  <div class="description grid-container page-container ">
    <div class="description__content flex-container__content">
      <div class="description__text">
        <div class="text-24">
          Новая автоматизированная онлайн-школа
        </div>

        <div class="description__title">
          <span class="text-64 text-bold">Изучайте иностранные языки</span>
          <span class="text-48 text-bold">в удобное время</span>
        </div>

        <div class="border"></div>

        <div class="text-24">
          Достигайте своих целей с помощью авторских игровых методик от носителей языка, которые
          превращают обучение в развлечение
        </div>
      </div>

      <div class="description__buttons">
        <button class="button button_primary" @click="clickWantToLearn">
          <div class="button-content learn-now">
            <span>Учиться сейчас!</span>
            <img src="@/assets/images/index-page/arrow-right.svg" class="arrow" alt="arrow-right" />
          </div>
        </button>
        <button class="button button_outline" @click="$emit('openVideoModal')">
          <div class="button-content watch-video">
            <div class="play">
              <img src="@/assets/images/index-page/play.svg" alt="play" />
            </div>
            <span>
              Смотреть видео
            </span>
          </div>
        </button>
      </div>
    </div>

    <div class="description__img flex-container__content">
      <img src="../../../assets/images/index-page/index-description.png" alt="img" />
    </div>
  </div>
</template>

<script>
import { indexRoutes } from '../../../router/routes'

export default {
  name: 'Description',
  methods: {
    clickWantToLearn() {
      this.$router.push(indexRoutes.courses_all)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';
@import '../../../scss/variables/media';

.description {
  margin-top: 130px;

  &__content {
    display: grid;
    gap: var(--spacing-32);
    grid-gap: var(--spacing-32);

    // display: flex;
    // flex-direction: column;
  }

  &__text {
    display: grid;
    gap: var(--spacing-32);
    grid-gap: var(--spacing-32);
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
  }

  &__img {
    display: grid;
    position: relative;
    justify-content: center;
    z-index: -1;
    img {
      position: absolute;
      min-width: 680px;
      width: 100%;
      height: auto;
      pointer-events: none;
    }
  }

  &__buttons {
    display: grid;
    gap: var(--spacing-8);
    grid-gap: var(--spacing-8);
    grid-template-columns: 1fr 1fr;
    // TODO:
    .button-content {
      position: relative;
      display: flex;
      gap: var(--spacing-16);
      justify-content: center;
      align-items: center;

      min-width: 230px;
      height: 65px;
    }

    .learn-now {
      padding: var(--spacing-12) var(--spacing-32);
      .arrow {
        // position: absolute;
        opacity: 0.5;
        right: 24px;
      }
    }

    .watch-video {
      padding: var(--spacing-8) var(--spacing-32);
      span {
        margin-left: 16px;
      }
      .play {
        position: absolute;
        padding: var(--spacing-16);
        left: 8px;
        border-radius: 24px;
        background-color: var(--light-blue);
        width: 48px;
        height: 48px;
      }
    }
  }
}

@media (max-width: $media-m) {
  .description {
    margin-top: 50px;
  }
}

@media (max-width: $media-s) {
  .description {
    &__content {
      gap: var(--spacing-24);
      order: 2;
    }

    &__text {
      gap: var(--spacing-16);
    }

    &__img {
      order: 0;
      img {
        position: static;
        max-height: 200px;
        width: auto;
        min-width: 0;
      }
    }
    &__buttons {
      // flex-direction: column;
      gap: var(--spacing-8);
      grid-gap: var(--spacing-8);

      grid-template-columns: 1fr;

      // &* {
      //   flex-grow: 1;
      // }
    }

    .watch-video {
      span {
        margin-left: 0;
      }
    }
  }
}
</style>
