<template>
  <div>
    <div class="personal-details books videos">
      <div class="additional">
        ДОПОЛНИТЕЛЬНЫЕ МАТЕРИАЛЫ
      </div>
      <div class="tabs-title">
        Видео
      </div>
      <div class="tabs-info">
        Дополнительные видео материалы для обучения
      </div>
      <div class="action" v-if="!loading">
        <button @click="openModal" type="button" class="add-course">ВЫБРАТЬ КУРС</button>
      </div>
      <Loading v-if="loading"/>
      <div v-else class="books-content">
        <div class="books-content-column row" v-if="allTeachingVideosList.length">
          <div class="col-md-6 pp-col-video">
            <div class="books-content-item" v-for="(video, index) in allTeachingVideosList" :key="index"
                 v-if="index % 2 === 0">
              <div class="books-item-title" v-if="video.title">{{ video.title[$i18n.locale] }}</div>
              <div
                class="video-content cursor-pointer material-video-image d-flex justify-content-center align-items-center w-100"
                @click="openVideoModal(video)">
                <img :src="video.image_paths.full" class="h-100 mx-auto" :alt="video.image_alt">
                <div class="play-icon">
                  <img src="~@/assets/images/profile/play.svg" alt="">
                </div>
              </div>
              <div class="item-content">
                <div class="description">
                  <div class="text" v-if="video.description">{{ video.description[$i18n.locale] }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="books-content-item" v-for="(video, index) in allTeachingVideosList" :key="index"
                 v-if="index % 2 === 1">
              <div class="books-item-title" v-if="video.title">{{ video.title[$i18n.locale] }}</div>
              <div
                class="video-content cursor-pointer material-video-image d-flex justify-content-center align-items-center w-100"
                @click="openVideoModal(video)">
                <img :src="video.image_paths.full" class="h-100 mx-auto" :alt="video.image_alt">
                <div class="play-icon">
                  <img src="~@/assets/images/profile/play.svg" alt="">
                </div>
              </div>
              <div class="item-content">
                <div class="description">
                  <div class="text" v-if="video.description">{{ video.description[$i18n.locale] }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img v-else src="~@/assets/images/profile/no-video.svg" alt="notes">
      </div>
      <div class="mt-3" v-if="pagination.count_pages > 1">
        <b-pagination
          v-model="currentPage"
          :total-rows="pagination.count_pages"
          :per-page="1"
          first-number
          last-number
          prev-text="«"
          next-text="»"
          @input="clickPagination"
        ></b-pagination>
      </div>

      <b-modal id="modal-tall-video" ref="videoModal">
        <template #modal-header="{ close }">
          <b-button @click="close()">
            X
          </b-button>
          <h3 v-if="modalVideo.title">{{ modalVideo.title[$i18n.locale] }}</h3>
        </template>
        <div class="video-modal-content">
          <div class="video">
            <div class="video" v-if="modalVideo.video_path">
              <video
                width="100%"
                :src="modalVideo.video_path"
                controls
              >
              </video>
            </div>
            <div class="video" v-if="modalVideo.video_iframe">
              <div class="video" v-if="modalVideo.video_iframe" v-html="modalVideo.video_iframe"></div>
            </div>
          </div>
          <div class="text" v-if="modalVideo.description">{{ modalVideo.description[$i18n.locale] }}</div>
        </div>
      </b-modal>
      <CoursesModal v-model="activeCourseId" :show="showModal" />
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import Loading from '../../../components/Loading'
import CoursesModal from '../../../components/Materials/CoursesModal'

export default {
  name: 'Videos',
  components: { CoursesModal, Loading },
  data () {
    return {
      currentPage: 1,
      modalVideo: {},
      activeCourseId: null,
      showModal: false,
      loading: false
    }
  },
  computed: {
    ...mapState({
      allTeachingVideosList: state => state.teachingVideo.allTeachingVideosList,
      pagination: state => state.teachingVideo.pagination
    })
  },
  watch: {
    activeCourseId(){
      this.getVideos()
      this.showModal = false
    }
  },
  methods: {
    ...mapActions({
      getAllTeachingVideos: 'teachingVideo/getAllTeachingVideos'
    }),
    openModal(){
      this.showModal = true
    },
    getVideos(){
      this.loading = true

      const params = {
        page: this.currentPage,
        course_id: this.activeCourseId
      }

      this.getAllTeachingVideos(params).then(() => {
        window.scrollTo(0, 0)
      }).finally(() => {
        this.loading = false
      })
    },
    openVideoModal (video) {
      this.modalVideo = video
      this.$refs.videoModal.show()
    },
    clickPagination () {
      this.getVideos()
    }
  }
}
</script>
<style lang="scss">
.material-video-image {
  height: 348px;
}

.auth-user .main-content .books-content-column .books-content-item {
  min-width: calc(100% - 20px) !important;
  max-width: calc(100% - 20px) !important;
}

#modal-tall-video{
  .modal-content{
    @media all and (max-width: 576px) {
      top: 0 !important;
      border-radius: 0 !important;
      max-height: 100% !important;
      margin-top: 90px !important;
      padding: 40px 20px !important;

      .modal-body{
        max-height: 100% !important;
        overflow: visible !important;
        .video-modal-content{

          .text{
            margin-top: 24px !important;
          }
        }
      }
    }
  }
}
</style>
