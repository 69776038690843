<template>
<span class="status-word-border" :class="{
                'success-word': success ,
                'error-word': error,
              }">
                  <span class="status-word"></span>
              </span>
</template>

<script>
export default {
  name: 'StatusBox',
  props: {
    success: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.status-word-border {
  height: 20px;
  width: 20px;
  border: 2px solid #E7EAFD;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .status-word {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: transparent;
  }

  &.error-word {
    border: 2px solid #CD3B45;

    .status-word {
      background-color: #CD3B45;
    }
  }

  &.success-word {
    border: 2px solid #00a651;

    .status-word {
      background-color: #00a651;
    }
  }
}
</style>
