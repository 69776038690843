import { http } from '../../../api'

const review = {
  namespaced: true,
  state: {
    reviews: [],
    myReviews: [],
    pagination: {},
    myReviewsPagination: {}
  },
  actions: {
    getReviews: ({ commit }, params) => {
      const q = new URLSearchParams(params)
      return http.get('/review?' + q.toString()).then((res) => {
        commit('setReviews', res.data)
      })
    },
    getMyReviews: ({ commit }, params) => {
      const query = new URLSearchParams(params)
      return http.get('/my-review?' + query.toString()).then((res) => {
        commit('setMyReviews', res.data)
      })
    },
    addReview: ({ commit }, params) => {
      return http.post('/review', params).then((res) => {
        commit('setNewMyReviews', res.data)
        return res
      })
    }
  },
  mutations: {
    setNewMyReviews (state, data) {
      state.myReviews.unshift(data.data)
    },
    setReviews (state, data) {
      state.reviews = data.data
      state.pagination = data.pagination
    },
    setMyReviews (state, data) {
      state.myReviews = data.data
      state.myReviewsPagination = data.pagination
    }
  },
  getters: {
    reviews: state => state.reviews,
    pagination: state => state.pagination
  }
}

export default review
