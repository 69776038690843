import { http } from '../../../api'

const otherMaterial = {
  namespaced: true,
  state: {
    otherMaterialList: [],
    pagination: {}
  },
  actions: {
    getOtherMaterialList: ({ commit }, params) => {
      const q = new URLSearchParams(params)
      return http.get('/other-material?' + q.toString()).then(res => {
        commit('setOtherMaterialList', res.data)
      })
    }
  },
  mutations: {
    setOtherMaterialList (state, data) {
      state.otherMaterialList = data.data
      state.pagination = data.pagination
    }
  },
  getters: {
    otherMaterialList: state => state.otherMaterialList,
    pagination: state => state.pagination
  }
}

export default otherMaterial
