import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import IndexLayout from '../components/layouts/IndexLayout'
import AuthLayout from '../components/layouts/AuthLayout'

import Home from '../components/pages/Home'
import Search from '../components/pages/Search'
import Courses from '../components/pages/Courses'
import CourseDetail from '../components/pages/Courses/courseDetail'
import AboutUs from '../components/pages/AboutUs'
import Video from '../components/pages/Video'
import News from '../components/pages/News'
import NewsDetail from '../components/pages/News/NewsDetail'
import Reviews from '../components/pages/Reviews'
import Contacts from '../components/pages/Contacts'
import Payments from '../components/pages/Payments'
import PrivacyPolicy from '../components/pages/PrivacyPolicy'
import TermsOfUse from '../components/pages/TermsOfUse'
import DynamicPage from '../components/pages/DynamicPage'
import LegalDisclaimer from '../components/pages/LegalDisclaimer'
import Interesting from '../components/pages/Interesting'
import InterestingDetail from '../components/pages/Interesting/InterestingDetail'
import Faq from '../components/pages/Faq'
import ResetPassword from '../components/pages/ResetPassword'
import noteFound from '../components/pages/404'

// Profile
import ProfileDetails from '../components/pages/Profile/Details'
import PaymentHistory from '../components/pages/Profile/PaymentHistory'
import Notifications from '../components/pages/Profile/Notifications'
import Support from '../components/pages/Profile/TechnicalSupport'
import Template from '../components/pages/Profile/TechnicalSupport/Template'
import NewTemplate from '../components/pages/Profile/TechnicalSupport/Template/New'
import Chat from '../components/pages/Profile/Chat'
import Dashboard from '../components/pages/Profile/Dashboard'
import VideoChat from '../components/pages/Profile/Chat/Video'
import VideoAndChat from '../components/pages/Profile/Chat/VideoChat'
import Notes from '../components/pages/Profile/Notes'
import AuthReviews from '../components/pages/Profile/Reviews'
import Books from '../components/pages/Profile/Books'
import Videos from '../components/pages/Profile/Videos'
import Audio from '../components/pages/Profile/Audio'
import AdditionalMaterials from '../components/pages/Profile/AdditionalMaterials'
import ProfileNews from '../components/pages/Profile/News'
import NewsView from '../components/pages/Profile/News/Open'
import ProfileCourses from '../components/pages/Profile/Courses'
import ProfileCourseDetails from '../components/pages/Profile/Courses/courseDetail'
import ProfileLessons from '../components/pages/Profile/Lessons'
import FreeLesson from '../components/pages/Profile/Lessons/FreeLesson'
import ProfileCourseLessons from '../components/pages/Profile/Lessons/CourseLessons'
import ProfileCourseLesson from '../components/pages/Profile/CourseLesson'
import AvailableCourseDetails from '../components/pages/Profile/Courses/availableCourseDetails'
import VideoMaterials from '../components/pages/Profile/VideoMaterials'
import ChatExpert from '../components/pages/Profile/ChatExpert'
import Tasks from '../components/pages/Profile/Tasks'
import Grammar from '../components/pages/Profile/Grammar'
import Vocabulary from '../components/pages/Profile/Vocabulary'
import Statistics from '../components/pages/Profile/Statistics'
import StatisticsModule from '../components/pages/Profile/StatisticsModule'
import StatisticsLesson from '../components/pages/Profile/StatisticsLesson'
import StatisticsTask from '../components/pages/Profile/StatisticsTask'
import StatisticsTest from '../components/pages/Profile/StatisticsTest'
import ChooseTranslation from '../components/pages/Profile/LessonTasks/ChooseTranslation'
import FindRemember from '../components/pages/Profile/LessonTasks/FindRemember'
import WheelFortune from '../components/pages/Profile/LessonTasks/WheelFortune'
import Typos from '../components/pages/Profile/LessonTasks/Typos'
import Gallows from '../components/pages/Profile/LessonTasks/Gallows'
import FieldDreams from '../components/pages/Profile/LessonTasks/FieldDreams'
import Test from '../components/pages/Profile/LessonTasks/Test'
import MissingWords from '../components/pages/Profile/LessonTasks/MissingWords'
import ComposeText from '../components/pages/Profile/LessonTasks/ComposeText'
import Crossword from '../components/pages/Profile/LessonTasks/Crossword'
import RepeatText from '../components/pages/Profile/LessonTasks/RepeatText'
import Categorize from '../components/pages/Profile/LessonTasks/Categorize'
import AlignWords from '../components/pages/Profile/LessonTasks/AlignWords'
import MakeAnOffer from '../components/pages/Profile/LessonTasks/MakeAnOffer'
import EmailVerified from '../components/pages/Profile/EmailVerified'
import IndependentWork from '../components/pages/Profile/IndependentWork'
import Tests from '../components/pages/Profile/Tests'
import Testing from '../components/pages/Test/index'
import Block from '../components/pages/Profile/Block'

import middlewarePipeline from './middlewarePipeline'
import auth from './middleware/auth'
import { indexRoutes } from './routes'

Vue.use(VueRouter)

const index = new VueRouter({
  routes: [
    {
      path: indexRoutes.home,
      component: IndexLayout,
      children: [
        {
          path: indexRoutes.home,
          name: 'home',
          component: Home
        },
        {
          path: indexRoutes.search,
          name: 'search',
          component: Search
        },
        {
          path: indexRoutes.courses,
          name: 'courses',
          component: Courses
        },
        {
          path: indexRoutes.course_detail,
          name: 'CourseDetail',
          component: CourseDetail
        },
        {
          path: indexRoutes.about_us,
          name: 'AboutUs',
          component: AboutUs
        },
        {
          path: indexRoutes.video,
          name: 'Video',
          component: Video
        },
        {
          path: indexRoutes.news,
          name: 'News',
          component: News
        },
        {
          path: indexRoutes.news_detail,
          name: 'NewsDetail',
          component: NewsDetail
        },
        {
          path: indexRoutes.reviews,
          name: 'Reviews',
          component: Reviews
        },
        {
          path: indexRoutes.contacts,
          name: 'Contacts',
          component: Contacts
        },
        {
          path: indexRoutes.payments,
          name: 'Payments',
          component: Payments
        },
        {
          path: indexRoutes.privacy_policy,
          name: 'privacy_policy',
          component: PrivacyPolicy
        },
        {
          path: indexRoutes.terms_of_use,
          name: 'terms_of_use',
          component: TermsOfUse
        },
        {
          path: indexRoutes.legal_disclaimer,
          name: 'LegalDisclaimer',
          component: LegalDisclaimer
        },
        {
          path: indexRoutes.interesting,
          name: 'Interesting',
          component: Interesting
        },
        {
          path: indexRoutes.faq,
          name: 'Faq',
          component: Faq
        },
        {
          path: indexRoutes.interesting_detail,
          name: 'InterestingDetail',
          component: InterestingDetail
        },
        {
          path: indexRoutes.reset_password,
          name: 'ResetPassword',
          component: ResetPassword
        },
        {
          path: indexRoutes.email_verified,
          name: 'EmailVerified',
          component: EmailVerified
        },
        {
          path: indexRoutes.not_found,
          name: 'noteFound',
          component: noteFound
        }
      ]
    },
    {
      path: '/testing',
      name: 'Testing',
      component: Testing
    },
    {
      path: '/profile',
      component: AuthLayout,
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: Dashboard,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: '/lesson-block/:blockId',
          name: 'block',
          component: Block
        },
        {
          path: 'profile-details',
          name: 'profile details',
          component: ProfileDetails,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'payment-history',
          name: 'PaymentHistory',
          component: PaymentHistory,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'notifications',
          name: 'notifications',
          component: Notifications,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'technical-support',
          name: 'technical support',
          component: Support,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'technical-support/template/:id',
          name: 'template',
          component: Template,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'technical-support/new-template',
          name: 'new-template',
          component: NewTemplate,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'chat',
          name: 'chat',
          component: Chat,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'video',
          name: 'VideoChat',
          component: VideoChat,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'video-chat',
          name: 'VideoAndChat',
          component: VideoAndChat,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'notes',
          name: 'Notes',
          component: Notes,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'reviews',
          name: 'AuthReviews',
          component: AuthReviews,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'books',
          name: 'Books',
          component: Books,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'videos',
          name: 'Videos',
          component: Videos,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'audio',
          name: 'Audio',
          component: Audio,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'additional-materials',
          name: 'AdditionalMaterials',
          component: AdditionalMaterials,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'video-materials/:courseId',
          name: 'VideoMaterials',
          component: VideoMaterials,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: ':id/chat-expert/:userCourseID',
          name: 'ChatExpert',
          component: ChatExpert,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'tasks/:id',
          name: 'Tasks',
          component: Tasks,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'task/1/:taskId',
          name: 'Task',
          component: Test,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'task/2/:taskId',
          name: 'MissingWords',
          component: MissingWords,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'task/3/:taskId',
          name: 'AlignWords',
          component: AlignWords,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'task/4/:taskId',
          name: 'Categorize',
          component: Categorize,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'task/5/:taskId',
          name: 'ComposeText',
          component: ComposeText,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'task/6/:taskId',
          name: 'RepeatText',
          component: RepeatText,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'task/7/:taskId',
          name: 'Crossword',
          component: Crossword,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'task/8/:taskId',
          name: 'FieldDreams',
          component: FieldDreams,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'task/9/:taskId',
          name: 'Gallows',
          component: Gallows,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'task/10/:taskId',
          name: 'ChooseTranslation',
          component: ChooseTranslation,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'task/11/:taskId',
          name: 'WheelFortune',
          component: WheelFortune,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'task/12/:taskId',
          name: 'FindRemember',
          component: FindRemember,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'task/13/:taskId',
          name: 'MakeAnOffer',
          component: MakeAnOffer,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'task/14/:taskId',
          name: 'Typos',
          component: Typos,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'grammar/:id',
          name: 'Grammar',
          component: Grammar,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'vocabulary/:courseId',
          name: 'Vocabulary',
          component: Vocabulary,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'statistics/:id',
          name: 'Statistics',
          component: Statistics,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'independent-work/:id',
          name: 'IndependentWork',
          component: IndependentWork,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'tests/:id',
          name: 'Tests',
          component: Tests,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'statistics-module/:id',
          name: 'StatisticsModule',
          component: StatisticsModule,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'statistics-lesson',
          name: 'StatisticsLesson',
          component: StatisticsLesson,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'statistics-task',
          name: 'StatisticsTask',
          component: StatisticsTask,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'statistics-test',
          name: 'StatisticsTest',
          component: StatisticsTest,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'news',
          name: 'ProfileNews',
          component: ProfileNews,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'news/:id',
          name: 'NewsView',
          component: NewsView,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'courses/:id',
          name: 'ProfileCourses',
          component: ProfileCourses,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'course/:id',
          name: 'ProfileCourseDetails',
          component: ProfileCourseDetails,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'lessons/:id',
          name: 'ProfileLessons',
          component: ProfileLessons,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'free-lesson/:id',
          name: 'FreeLesson',
          component: FreeLesson,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'course/:id/lesson',
          name: 'ProfileCourseLessons',
          component: ProfileCourseLessons,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'course/:course_id/lesson/:id',
          name: 'ProfileCourseLesson',
          component: ProfileCourseLesson,
          meta: {
            middleware: [auth]
          }
        },
        {
          path: 'available-course/:id',
          name: 'AvailableCourseDetails',
          component: AvailableCourseDetails,
          meta: {
            middleware: [auth]
          }
        }
      ]
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      path: '/page/:name',
      name: 'dynamic_page',
      component: DynamicPage
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
      document.body.scrollTo(0, 0)
      document.getElementById('main').scrollTo(0, 0)
    }
  },
  history: true,
  mode: 'history',
  linkExactActiveClass: 'active'
})

index.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

export default index
