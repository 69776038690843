<template>
  <div class="personal-details">
    <h3>Персональные данные</h3>
    <div class="personal-details-tabs">
      <b-tabs :class="{ 'mobile-tabs': is_mobile }" v-if="!is_mobile">
        <b-tab active>
          <template #title>
            <img src="~@/assets/images/profile/user.svg" alt="user"/>
            <span>Мой аккаунт</span>
          </template>
          <div class="personal-details-tabs-account">
            <div class="tabs-title">
              Мой аккаунт
            </div>
            <div class="tabs-info">
              Информация о ваших персональных данных
            </div>
            <ValidationObserver
              id="userForm"
              tag="b-form"
              ref="form"
              @submit.prevent="updateUser"
            >
              <ValidationProvider name="Фамилия" rules="required">
                <b-form-group
                  tag="b-form-group"
                  slot-scope="{ classes, errors }"
                  label="Фамилия"
                  class="position-relative"
                  label-for="lastName"
                >
                  <b-form-input
                    v-model="userFormData.last_name"
                    :class="classes"
                    id="lastName"
                    maxlength="30"
                    name="LastName"
                    type="text"
                  ></b-form-input>
                  <b-form-invalid-feedback :tooltip="true">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
              <ValidationProvider name="Имя" rules="required">
                <b-form-group
                  slot-scope="{ classes, errors }"
                  tag="b-form-group"
                  class="position-relative"
                  label="Имя"
                  label-for="firstName"
                >
                  <b-form-input
                    id="firstName"
                    :class="classes"
                    v-model="userFormData.first_name"
                    type="text"
                    maxlength="30"
                  ></b-form-input>
                  <b-form-invalid-feedback :tooltip="true">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
              <div class="d-flex email-content">
                <ValidationProvider
                  name="E-mail"
                  rules="required|email"
                >
                  <b-form-group
                    slot-scope="{ classes, errors }"
                    tag="b-form-group"
                    class="position-relative"
                    label="E-mail"
                    label-for="email"
                  >
                    <b-form-input
                      :class="classes"
                      v-model="userFormData.email"
                      id="email"
                      type="email"
                    ></b-form-input>
                    <b-form-invalid-feedback :tooltip="true">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
                <div class="personal-details-tabs-account-not-verified">
                  <!-- When email is verified -->
                  <div
                    v-if="userFormData.email_verified_at"
                    class="personal-details-tabs-account-not-verified-content"
                  >
                    <div
                      class="personal-details-tabs-account-not-verified-content-text"
                    >
                      <img
                        src="~@/assets/images/profile/verified-email.svg"
                        alt=""
                      />
                      <div>Ваш e-mail подтвержден</div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="personal-details-tabs-account-not-verified-content"
                  >
                    <div
                      class="personal-details-tabs-account-not-verified-content-text"
                    >
                      <img
                        src="~@/assets/images/profile/not-verified-email.svg"
                        alt=""
                      />
                      <div class="error-message">
                        Вы еще не прошли верификацию. Ваш e-mail не подтвержден
                      </div>
                    </div>
                    <div
                      class="personal-details-tabs-account-not-verified-content-action"
                    >
                      <button
                        type="button"
                        @click="verifyEmail"
                        :disabled="emailDisableBtn"
                        :class="emailDisableBtn ? 'btn-disabled' : ''"
                      >
                        ПОДТВЕРДИТЬ E-MAIL
                      </button>
                      <b-modal id="verify-modal" ref="verifyModal">
                        <template
                          #modal-header="{ close }"
                          ref="emailVerifyModal"
                        >
                          <b-button @click="close()">
                            X
                          </b-button>
                          <h3>Подтвердить E-mail</h3>
                        </template>
                        <div>
                          Вам на почту отправлено письмо для подверждение вашего
                          e-mail адреса. Перейдите по ссылке в письме для
                          подтверждения вашего e-mail адреса.
                        </div>
                        <div class="actions">
                          <button
                            type="button"
                            class="close"
                            style="font-family: 'Open Sans', sans-serif;"
                            @click="$refs.verifyModal.hide()"
                          >
                            Закрыть
                          </button>
                        </div>
                      </b-modal>
                    </div>
                  </div>
                </div>
              </div>
              <PhoneInput @onValid="(val) => validPhone = val" :country-code.sync="userFormData.country_code"
                          label="Телефон" placeholder="Номер телефона" v-model="userFormData.phone"
                          class="form-group w-35 profile-phone-input"/>
              <ValidationProvider name="Логин" rules="required">
                <b-form-group
                  tag="b-form-group"
                  class="position-relative"
                  slot-scope="{ classes, errors }"
                  label="Логин"
                  label-for="login"
                >
                  <b-form-input
                    id="login"
                    :class="classes"
                    v-model="userFormData.login"
                    type="text"
                    pattern="^[a-zA-Z0-9\-]+$"
                    placeholder="Заполните поле"
                  ></b-form-input>
                  <b-form-invalid-feedback :tooltip="true">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
              <ValidationProvider name="Дата рождения" rules="required">
                <b-form-group
                  tag="b-form-group"
                  class="position-relative"
                  slot-scope="{ classes, errors }"
                  label="Дата рождения"
                  label-for="birthday"
                >
                  <b-form-input
                    id="birthday"
                    :class="classes"
                    v-model="userFormData.birthday"
                    type="date"
                    placeholder="Заполните поле"
                  ></b-form-input>
                  <b-form-invalid-feedback :tooltip="true">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
              <div class="form-group checkbox-group">
                <label class="form-label">Пол:</label>
                <label class="checkbox-container cursor-pointer">
                  <input
                    value="male"
                    type="radio"
                    name="gender"
                    v-model="userFormData.gender"
                  />
                  <span class="checkbox"/>
                  <span>Мужской</span>
                </label>
                <label class="checkbox-container cursor-pointer">
                  <input
                    value="female"
                    type="radio"
                    name="gender"
                    v-model="userFormData.gender"
                  />
                  <span class="checkbox"/>
                  <span>Женский</span>
                </label>
              </div>
              <div class="actions">
                <button
                  type="submit"
                  :disabled="disableBtn"
                  :class="disableBtn ? 'btn-disabled' : ''"
                >
                  СОХРАНИТЬ
                </button>
              </div>
            </ValidationObserver>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <img src="~@/assets/images/profile/camera.svg" alt="user"/>
            <span>Фотографии</span>
          </template>
          <div class="personal-details-tabs-photo">
            <div class="tabs-title">
              Добавь фотографию профиля
            </div>
            <div class="tabs-info">
              Персонализируйте свой личный профиль
            </div>
            <div
              v-if="user.avatar"
              class="personal-details-tabs-photo-content added"
            >
              <div class="personal-details-tabs-photo-content-image">
                <img :src="user.avatar_path" alt="user"/>
                <a href="#" v-b-modal.remove-photo-modal>X</a>
              </div>
              <div class="personal-details-tabs-photo-content-add">
                <input
                  type="file"
                  @change="uploadAvatar($event.target.files)"
                />
                <button type="button">изменить фото</button>
              </div>
              <b-modal id="remove-photo-modal" ref="deleteImage">
                <template #modal-header="{ close }">
                  <b-button @click="close()">
                    X
                  </b-button>
                  <h3>Удалить фото</h3>
                </template>
                <div>
                  Вы действительно хотите удалить фотографию вашего профиля?
                  После удалия в Вашем профиле будет отображаться стандартный
                  аватар.
                </div>
                <div class="actions">
                  <button
                    type="button"
                    class="cancel"
                    @click="$refs.deleteImage.hide()"
                  >
                    Отмена
                  </button>
                  <button type="button" class="remove" @click="deleteAvatar">
                    удалить фото
                  </button>
                </div>
              </b-modal>
            </div>
            <div v-else class="personal-details-tabs-photo-content">
              <img src="~@/assets/images/profile/add-image.svg" alt="add"/>
              <div class="personal-details-tabs-photo-content-add">
                <input
                  type="file"
                  @change="uploadAvatar($event.target.files)"
                />
                <button
                  type="button"
                  :disabled="disableBtn"
                  :class="disableBtn ? 'btn-disabled' : ''"
                >
                  ВЫБРАТЬ ФАЙЛ
                </button>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <img src="~@/assets/images/profile/lock.svg" alt="user"/>
            <span>Изменить пароль</span>
          </template>
          <div class="personal-details-tabs-account">
            <div class="tabs-title">
              Изменить пароль
            </div>
            <div class="tabs-info">
              Для изменения текущего пароля воспользуйтесь формой ниже
            </div>
            <ValidationObserver
              tag="b-form"
              ref="formPassword"
              @submit.prevent="updateUserPassword"
            >
              <PasswordInput
                input-div-class="w-510"
                v-model="userPasswordFormData.current_password"
                eye-icon-class="eye-icon-details"
                label="Текущий пароль"
                field-name="&quot;Текущий пароль&quot;"
                >
                <template v-slot:afterInput="{errors}">
                  <b-form-invalid-feedback :tooltip="true" class="tooltip-position">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  <div
                    v-if="passwordError.current_password"
                    class="invalid-tooltip block tooltip-position"
                  >
                    {{ passwordError.current_password[0] }}
                  </div>
                </template>
              </PasswordInput>

              <PasswordInput
                input-div-class="w-510"
                v-model="userPasswordFormData.new_password"
                eye-icon-class="eye-icon-details"
                label="Новый пароль"
                field-name="&quot;Новый пароль&quot;"
              >
                <template v-slot:afterInput="{errors}">
                  <b-form-invalid-feedback :tooltip="true" class="tooltip-position">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  <div
                    v-if="passwordError.new_password"
                    class="invalid-tooltip block tooltip-position"
                  >
                    {{ passwordError.new_password[0] }}
                  </div>
                </template>
              </PasswordInput>

              <PasswordInput
                input-div-class="w-510"
                v-model="userPasswordFormData.new_password_confirmation"
                eye-icon-class="eye-icon-details"
                label="Подтвердить новый пароль"
                field-name="&quot;Подтвердить новый пароль&quot;"
              >
                <template v-slot:afterInput="{errors}">
                  <b-form-invalid-feedback :tooltip="true" class="tooltip-position">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  <div
                    v-if="passwordError.new_password_confirmation"
                    class="invalid-tooltip block tooltip-position"
                  >
                    {{ passwordError.new_password_confirmation[0] }}
                  </div>
                </template>
              </PasswordInput>

              <div class="actions">
                <button
                  type="submit"
                  :disabled="disableBtn"
                  :class="disableBtn ? 'btn-disabled' : ''"
                >
                  изменить пароль
                </button>
              </div>
            </ValidationObserver>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <img src="~@/assets/images/profile/star.svg" alt="user"/>
            <span>Подписки на курсы</span>
          </template>
          <div class="personal-details-tabs-account">
            <div class="tabs-title">
              Подписки на курсы
            </div>
            <!-- When there are courses  -->
            <div
              v-if="subscriptionCoursesList && subscriptionCoursesList.length"
            >
              <div class="tabs-info">
                Информация о приобретенных курсах
              </div>
              <div class="personal-details-tabs-courses">
                <div
                  class="personal-details-tabs-courses-item"
                  :class="subCourse.active ? 'blue' : 'purple'"
                  v-for="(subCourse, index) in subscriptionCoursesList"
                  :key="index"
                >
                  <div class="personal-details-tabs-courses-item-title">
                    {{ subCourse.course.name[$i18n.locale] }}
                  </div>
                  <div class="personal-details-tabs-courses-item-details">
                    <div class="item">
                      <div class="key">Дата приобретения:</div>
                      <div class="value">
                        {{ subCourse.created_at | moment('DD.MM.YYYY') }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="key">Длительность доступа:</div>
                      <div class="value">
                        {{ subCourse.course_tariff.duration }} дней
                      </div>
                    </div>
                    <div class="item">
                      <div class="key">Дата окончая доступа:</div>
                      <div class="value">
                        {{ subCourse.expiry_date | moment('DD.MM.YYYY') }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="key">Уровень курса:</div>
                      <div class="value">
                        {{ subCourse.course.course_level.name[$i18n.locale] }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="key">Приобретенный пакет:</div>
                      <div class="value">
                        {{ subCourse.course_tariff.name[$i18n.locale] }}
                      </div>
                    </div>
                  </div>
                  <div class="personal-details-tabs-courses-item-info">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                    >
                      <g transform="translate(-403.571 -376.24)">
                        <path
                          d="M423.576,376.24a20,20,0,1,1-20,19.912A20.028,20.028,0,0,1,423.576,376.24Zm16.126,20.1a15.655,15.655,0,0,0-.342-3.4,16.154,16.154,0,0,0-17.613-12.728,15.683,15.683,0,0,0-6.738,2.366c-.82.518-.806.508-.266,1.3.176.258.283.246.522.086a14.47,14.47,0,0,1,11.1-2.287,14.842,14.842,0,0,1,9.737,22.5c-.438.69-.429.678.257,1.192.255.192.375.21.573-.086A16.141,16.141,0,0,0,439.7,396.339ZM424.038,412.37a15.7,15.7,0,0,0,8.061-2.446c.866-.54.854-.536.275-1.354-.158-.223-.258-.211-.464-.074a14.519,14.519,0,0,1-11.134,2.309,14.849,14.849,0,0,1-9.759-22.471c.148-.234.464-.476.4-.7s-.43-.332-.632-.525c-.275-.262-.409-.161-.591.117a15.816,15.816,0,0,0-2.4,12.349A16.242,16.242,0,0,0,424.038,412.37Zm1.468-14.814h0c0-1.41-.006-2.821.006-4.23,0-.238-.046-.322-.306-.319q-2.276.02-4.553,0c-.241,0-.322.05-.317.307.018.846.006,1.692.006,2.538,0,.322-.117.737.039.942.184.242.623.083.95.081.257,0,.308.078.306.32-.012,1.275,0,2.551-.006,3.827,0,.322.113.742-.046.938-.187.231-.626.074-.955.076-.225,0-.3.062-.293.291q.017,1.652,0,3.3c0,.23.068.29.293.289q2.941-.012,5.882,0c.232,0,.29-.07.288-.294-.012-.9,0-1.8-.006-2.7,0-.881,0-.893-.9-.877-.3,0-.408-.056-.4-.387C425.519,400.3,425.506,398.926,425.506,397.556Zm-4.517-8.412a2.581,2.581,0,1,0,2.59-2.582A2.581,2.581,0,0,0,420.99,389.144Z"
                          fill="#2e90d1"
                        />
                        <path
                          d="M424.214,398.811c0,1.4.006,2.8,0,4.193,0,.252.052.382.326.333a.23.23,0,0,1,.041,0c1.038.008,1.051.03.934,1.094-.018.164-.078.2-.222.2-1.142,0-2.284,0-3.426,0-.175,0-.25-.053-.226-.229a.228.228,0,0,0,0-.041c-.04-1.009-.04-1.018.962-1.016.26,0,.336-.064.335-.331q-.016-3.547,0-7.094c0-.268-.077-.331-.335-.33-.982,0-.972-.008-.97-.978,0-.25.066-.318.314-.311.658.018,1.317.02,1.974,0,.266-.009.3.088.3.322C424.209,396.017,424.214,397.414,424.214,398.811Z"
                          fill="#2e90d1"
                        />
                        <path
                          d="M423.548,390.43a1.289,1.289,0,1,1,1.31-1.255A1.294,1.294,0,0,1,423.548,390.43Z"
                          fill="#2e90d1"
                        />
                      </g>
                    </svg>
                    <div>
                      Вы можете продлить пакет.
                      <br/>
                      Стоимость продления пакета ХХ за 30 календарных дней
                    </div>
                  </div>
                  <div class="actions mb-0">
                    <button
                      type="button"
                      @click="
                        openTariffPaymentModal(
                          subCourse.course_tariff,
                          subCourse.id,
                          subCourse.course.tariffs,
                          false
                        )
                      "
                    >
                      продлить пакет
                    </button>
                    <button
                      type="button"
                      v-if="!subCourse.active"
                      @click="
                        openTariffPaymentModal(
                          subCourse.course_tariff,
                          subCourse.id,
                          subCourse.course.tariffs,
                          true
                        )
                      "
                    >
                      Изменить пакет
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="tabs-info">
                Вы еще не приобрели ни одного курса. Подписки отсутствуют.
              </div>
              <div class="actions">
                <button
                  type="button"
                  class="choose-btn"
                  @click="$router.push('/profile/courses/main')"
                >
                  Выбрать курс
                </button>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <img src="~@/assets/images/profile/star.svg" alt="user"/>
            <span>Реферальная система</span>
          </template>
          <ReferralCoupons />
        </b-tab>
      </b-tabs>
      <div :class="{ 'mobile-accordion': is_mobile }" v-if="is_mobile">
        <div class="accordion" role="tablist">
          <div class="mb-1">
            <div role="tab">
              <b-button block v-b-toggle.accordion-1 class="accordion-btn">
                <template>
                  <img src="~@/assets/images/profile/user.svg" alt="user"/>
                  <span>Мой аккаунт</span>
                </template>
              </b-button>
            </div>
            <b-collapse
              id="accordion-1"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="personal-details-tabs-account">
                <div class="tabs-title">
                  Мой аккаунт
                </div>
                <div class="tabs-info">
                  Информация о ваших персональных данных
                </div>
                <ValidationObserver
                  id="userForm"
                  tag="b-form"
                  ref="form"
                  @submit.prevent="updateUser"
                >
                  <ValidationProvider name="Фамилия" rules="required">
                    <b-form-group
                      tag="b-form-group"
                      slot-scope="{ classes, errors }"
                      label="Фамилия"
                      class="position-relative"
                      label-for="lastName"
                    >
                      <b-form-input
                        v-model="userFormData.last_name"
                        :class="classes"
                        id="lastName"
                        name="LastName"
                        maxlength="30"
                        type="text"
                      ></b-form-input>
                      <b-form-invalid-feedback :tooltip="true">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Имя" rules="required">
                    <b-form-group
                      slot-scope="{ classes, errors }"
                      tag="b-form-group"
                      class="position-relative"
                      label="Имя"
                      label-for="firstName"
                    >
                      <b-form-input
                        id="firstName"
                        :class="classes"
                        v-model="userFormData.first_name"
                        maxlength="30"
                        type="text"
                      ></b-form-input>
                      <b-form-invalid-feedback :tooltip="true">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                  <div class="d-flex email-content">
                    <ValidationProvider
                      name="E-mail"
                      class="w-50"
                      rules="required|email"
                    >
                      <b-form-group
                        slot-scope="{ classes, errors }"
                        tag="b-form-group"
                        class="position-relative"
                        label="E-mail"
                        label-for="email"
                      >
                        <b-form-input
                          :class="classes"
                          v-model="userFormData.email"
                          id="email"
                          type="email"
                        ></b-form-input>
                        <b-form-invalid-feedback :tooltip="true">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                    <div class="personal-details-tabs-account-not-verified">
                      <!-- When email is verified -->
                      <div
                        v-if="userFormData.email_verified_at"
                        class="personal-details-tabs-account-not-verified-content"
                      >
                        <div
                          class="personal-details-tabs-account-not-verified-content-text"
                        >
                          <img
                            src="~@/assets/images/profile/verified-email.svg"
                            alt=""
                          />
                          <div>Ваш e-mail подтвержден</div>
                        </div>
                      </div>
                      <div
                        v-else
                        class="personal-details-tabs-account-not-verified-content"
                      >
                        <div
                          class="personal-details-tabs-account-not-verified-content-text"
                        >
                          <img
                            src="~@/assets/images/profile/not-verified-email.svg"
                            alt=""
                          />
                          <div class="error-message">
                            Вы еще не прошли верификацию. Ваш e-mail не
                            подтвержден
                          </div>
                        </div>
                        <div
                          class="personal-details-tabs-account-not-verified-content-action"
                        >
                          <button
                            type="button"
                            @click="verifyEmail"
                            :disabled="emailDisableBtn"
                            :class="emailDisableBtn ? 'btn-disabled' : ''"
                          >
                            ПОДТВЕРДИТЬ E-MAIL
                          </button>
                          <b-modal id="verify-modal" ref="verifyModal">
                            <template
                              #modal-header="{ close }"
                              ref="emailVerifyModal"
                            >
                              <b-button @click="close()">
                                X
                              </b-button>
                              <h3>Подтвердить E-mail</h3>
                            </template>
                            <div>
                              Вам на почту отправлено письмо для подверждение
                              вашего e-mail адреса. Перейдите по ссылке в письме
                              для подтверждения вашего e-mail адреса.
                            </div>
                            <div class="actions">
                              <button
                                type="button"
                                class="close"
                                style="font-family: 'Open Sans', sans-serif;"
                                @click="$refs.verifyModal.hide()"
                              >
                                Закрыть
                              </button>
                            </div>
                          </b-modal>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ValidationProvider name="Телефон" rules="required">
                    <b-form-group
                      tag="b-form-group"
                      class="position-relative"
                      slot-scope="{ classes, errors }"
                      label="Телефон"
                      label-for="telephone"
                    >
                      <b-form-input
                        :class="classes"
                        id="telephone"
                        type="number"
                        class="input-number"
                        max="30"
                        v-model="userFormData.phone"
                      ></b-form-input>
                      <b-form-invalid-feedback :tooltip="true">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Логин" rules="required">
                    <b-form-group
                      tag="b-form-group"
                      class="position-relative"
                      slot-scope="{ classes, errors }"
                      label="Логин"
                      label-for="login"
                    >
                      <b-form-input
                        id="login"
                        :class="classes"
                        v-model="userFormData.login"
                        type="text"
                        pattern="^[a-zA-Z0-9\-]+$"
                        placeholder="Заполните поле"
                      ></b-form-input>
                      <b-form-invalid-feedback :tooltip="true">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                  <div class="personal-details-tabs-account-bday">
                    <label>Дата рождения</label>
                    <ValidationProvider name="День" rules="required">
                      <b-form-group
                        tag="b-form-group"
                        class="position-relative"
                        slot-scope="{ classes, errors }"
                        label-for="birthdayDay"
                      >
                        <b-form-input
                          :class="classes"
                          id="birthdayDay"
                          type="text"
                          max="31"
                          v-model="userFormData.birthdayDay"
                          placeholder="День"
                        ></b-form-input>
                        <b-form-invalid-feedback :tooltip="true">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Месяц" rules="required">
                      <b-form-group
                        tag="b-form-group"
                        class="position-relative"
                        slot-scope="{ classes, errors }"
                        label-for="birthdayMonth"
                      >
                        <b-form-input
                          :class="classes"
                          id="birthdayMonth"
                          v-model="userFormData.birthdayMonth"
                          type="text"
                          max="12"
                          placeholder="Месяц"
                        ></b-form-input>
                        <b-form-invalid-feedback :tooltip="true">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Год" rules="required">
                      <b-form-group
                        tag="b-form-group"
                        class="position-relative"
                        slot-scope="{ classes, errors }"
                        label-for="birthdayYear"
                      >
                        <b-form-input
                          :class="classes"
                          id="birthdayYear"
                          type="text"
                          :max="year"
                          v-model="userFormData.birthdayYear"
                          placeholder="Год"
                        ></b-form-input>
                        <b-form-invalid-feedback :tooltip="true">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="form-group checkbox-group">
                    <label class="form-label">Пол:</label>
                    <label class="checkbox-container cursor-pointer">
                      <input
                        value="male"
                        type="radio"
                        name="gender"
                        v-model="userFormData.gender"
                      />
                      <span class="checkbox"/>
                      <span>Мужской</span>
                    </label>
                    <label class="checkbox-container cursor-pointer">
                      <input
                        value="female"
                        type="radio"
                        name="gender"
                        v-model="userFormData.gender"
                      />
                      <span class="checkbox"/>
                      <span>Женский</span>
                    </label>
                  </div>
                  <div class="actions">
                    <button
                      type="submit"
                      :disabled="disableBtn"
                      :class="disableBtn ? 'btn-disabled' : ''"
                    >
                      СОХРАНИТЬ
                    </button>
                  </div>
                </ValidationObserver>
              </div>
            </b-collapse>
          </div>
          <div class="mb-1">
            <div role="tab">
              <b-button block v-b-toggle.accordion-2 class="accordion-btn">
                <template>
                  <img src="~@/assets/images/profile/camera.svg" alt="user"/>
                  <span>Фотографии</span>
                </template>
              </b-button>
            </div>
            <b-collapse
              id="accordion-2"
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="personal-details-tabs-photo">
                <div class="tabs-title">
                  Добавь фотографию профиля
                </div>
                <div class="tabs-info">
                  Персонализируйте свой личный профиль
                </div>
                <div
                  v-if="user.avatar"
                  class="personal-details-tabs-photo-content added"
                >
                  <div class="personal-details-tabs-photo-content-image">
                    <img :src="user.avatar_path" alt="user"/>
                    <a href="#" v-b-modal.remove-photo-modal>X</a>
                  </div>
                  <div class="personal-details-tabs-photo-content-add">
                    <input
                      type="file"
                      @change="uploadAvatar($event.target.files)"
                    />
                    <button type="button">изменить фото</button>
                  </div>
                  <b-modal id="remove-photo-modal" ref="deleteImage">
                    <template #modal-header="{ close }">
                      <b-button @click="close()">
                        X
                      </b-button>
                      <h3>Удалить фото</h3>
                    </template>
                    <div>
                      Вы действительно хотите удалить фотографию вашего профиля?
                      После удалия в Вашем профиле будет отображаться
                      стандартный аватар.
                    </div>
                    <div class="actions">
                      <button
                        type="button"
                        class="cancel"
                        @click="$refs.deleteImage.hide()"
                      >
                        Отмена
                      </button>
                      <button
                        type="button"
                        class="remove"
                        @click="deleteAvatar"
                      >
                        удалить фото
                      </button>
                    </div>
                  </b-modal>
                </div>
                <div v-else class="personal-details-tabs-photo-content">
                  <img src="~@/assets/images/profile/add-image.svg" alt="add"/>
                  <div class="personal-details-tabs-photo-content-add">
                    <input
                      type="file"
                      @change="uploadAvatar($event.target.files)"
                    />
                    <button
                      type="button"
                      :disabled="disableBtn"
                      :class="disableBtn ? 'btn-disabled' : ''"
                    >
                      ВЫБРАТЬ ФАЙЛ
                    </button>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="mb-1">
            <div role="tab">
              <b-button block v-b-toggle.accordion-3 class="accordion-btn">
                <template>
                  <img src="~@/assets/images/profile/lock.svg" alt="user"/>
                  <span>Изменить пароль</span>
                </template>
              </b-button>
            </div>
            <b-collapse
              id="accordion-3"
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="personal-details-tabs-account">
                <div class="tabs-title">
                  Изменить пароль
                </div>
                <div class="tabs-info">
                  Для изменения текущего пароля воспользуйтесь формой ниже
                </div>
                <ValidationObserver
                  tag="b-form"
                  ref="formPassword"
                  @submit.prevent="updateUserPassword"
                >
                  <ValidationProvider
                    name="'&quot;Текущий пароль&quot;'"
                    rules="required"
                  >
                    <b-form-group
                      tag="b-form-group"
                      class="position-relative"
                      slot-scope="{ classes, errors }"
                      label-for="currentPassword"
                      label="Текущий пароль"
                    >
                      <b-form-input
                        :class="classes"
                        id="currentPassword"
                        type="password"
                        v-model="userPasswordFormData.current_password"
                        placeholder="Заполните поле"
                      ></b-form-input>
                      <b-form-invalid-feedback :tooltip="true">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                      <div
                        v-if="passwordError.current_password"
                        class="invalid-tooltip block"
                      >
                        {{ passwordError.current_password[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider
                    :name="'&quot;Новый пароль&quot;'"
                    rules="required"
                  >
                    <b-form-group
                      tag="b-form-group"
                      class="position-relative"
                      slot-scope="{ classes, errors }"
                      label-for="newPassword"
                      label="Новый пароль"
                    >
                      <b-form-input
                        :class="classes"
                        id="newPassword"
                        type="password"
                        v-model="userPasswordFormData.new_password"
                        placeholder="Заполните поле"
                      ></b-form-input>
                      <b-form-invalid-feedback :tooltip="true">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                      <div
                        v-if="passwordError.new_password"
                        class="invalid-tooltip block"
                      >
                        {{ passwordError.new_password[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider
                    :name="'&quot;Подтвердить новый пароль&quot;'"
                    rules="required"
                  >
                    <b-form-group
                      tag="b-form-group"
                      class="position-relative"
                      slot-scope="{ classes, errors }"
                      label-for="confirmPassword"
                      label="Подтвердить новый пароль"
                    >
                      <b-form-input
                        :class="classes"
                        id="confirmPassword"
                        type="password"
                        v-model="userPasswordFormData.new_password_confirmation"
                        placeholder="Заполните поле"
                      ></b-form-input>
                      <b-form-invalid-feedback :tooltip="true">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                      <div
                        v-if="passwordError.new_password_confirmation"
                        class="invalid-tooltip block"
                      >
                        {{ passwordError.new_password_confirmation[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <div class="actions">
                    <button
                      type="submit"
                      :disabled="disableBtn"
                      :class="disableBtn ? 'btn-disabled' : ''"
                    >
                      изменить пароль
                    </button>
                  </div>
                </ValidationObserver>
              </div>
            </b-collapse>
          </div>
          <div class="mb-1">
            <div role="tab">
              <b-button block v-b-toggle.accordion-4 class="accordion-btn">
                <template>
                  <img src="~@/assets/images/profile/star.svg" alt="user"/>
                  <span>Подписки на курсы</span>
                </template>
              </b-button>
            </div>
            <b-collapse
              id="accordion-4"
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="personal-details-tabs-account">
                <div class="tabs-title">
                  Подписки на курсы
                </div>
                <!-- When there are courses  -->
                <div
                  v-if="
                    subscriptionCoursesList && subscriptionCoursesList.length
                  "
                >
                  <div class="tabs-info">
                    Информация о приобретенных курсах
                  </div>
                  <div class="personal-details-tabs-courses">
                    <div
                      class="personal-details-tabs-courses-item"
                      :class="subCourse.active ? 'blue' : 'purple'"
                      v-for="(subCourse, index) in subscriptionCoursesList"
                      :key="index"
                    >
                      <div class="personal-details-tabs-courses-item-title">
                        {{ subCourse.course.name[$i18n.locale] }}
                      </div>
                      <div class="personal-details-tabs-courses-item-details">
                        <div class="item">
                          <div class="key">Дата приобретения:</div>
                          <div class="value">
                            {{ subCourse.created_at | moment('DD.MM.YYYY') }}
                          </div>
                        </div>
                        <div class="item">
                          <div class="key">Длительность доступа:</div>
                          <div class="value">
                            {{ subCourse.course_tariff.duration }} дней
                          </div>
                        </div>
                        <div class="item">
                          <div class="key">Дата окончая доступа:</div>
                          <div class="value">
                            {{ subCourse.expiry_date | moment('DD.MM.YYYY') }}
                          </div>
                        </div>
                        <div class="item">
                          <div class="key">Уровень курса:</div>
                          <div class="value">
                            {{
                              subCourse.course.course_level.name[$i18n.locale]
                            }}
                          </div>
                        </div>
                        <div class="item">
                          <div class="key">Приобретенный пакет:</div>
                          <div class="value">
                            {{ subCourse.course_tariff.name[$i18n.locale] }}
                          </div>
                        </div>
                      </div>
                      <div class="personal-details-tabs-courses-item-info">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                        >
                          <g transform="translate(-403.571 -376.24)">
                            <path
                              d="M423.576,376.24a20,20,0,1,1-20,19.912A20.028,20.028,0,0,1,423.576,376.24Zm16.126,20.1a15.655,15.655,0,0,0-.342-3.4,16.154,16.154,0,0,0-17.613-12.728,15.683,15.683,0,0,0-6.738,2.366c-.82.518-.806.508-.266,1.3.176.258.283.246.522.086a14.47,14.47,0,0,1,11.1-2.287,14.842,14.842,0,0,1,9.737,22.5c-.438.69-.429.678.257,1.192.255.192.375.21.573-.086A16.141,16.141,0,0,0,439.7,396.339ZM424.038,412.37a15.7,15.7,0,0,0,8.061-2.446c.866-.54.854-.536.275-1.354-.158-.223-.258-.211-.464-.074a14.519,14.519,0,0,1-11.134,2.309,14.849,14.849,0,0,1-9.759-22.471c.148-.234.464-.476.4-.7s-.43-.332-.632-.525c-.275-.262-.409-.161-.591.117a15.816,15.816,0,0,0-2.4,12.349A16.242,16.242,0,0,0,424.038,412.37Zm1.468-14.814h0c0-1.41-.006-2.821.006-4.23,0-.238-.046-.322-.306-.319q-2.276.02-4.553,0c-.241,0-.322.05-.317.307.018.846.006,1.692.006,2.538,0,.322-.117.737.039.942.184.242.623.083.95.081.257,0,.308.078.306.32-.012,1.275,0,2.551-.006,3.827,0,.322.113.742-.046.938-.187.231-.626.074-.955.076-.225,0-.3.062-.293.291q.017,1.652,0,3.3c0,.23.068.29.293.289q2.941-.012,5.882,0c.232,0,.29-.07.288-.294-.012-.9,0-1.8-.006-2.7,0-.881,0-.893-.9-.877-.3,0-.408-.056-.4-.387C425.519,400.3,425.506,398.926,425.506,397.556Zm-4.517-8.412a2.581,2.581,0,1,0,2.59-2.582A2.581,2.581,0,0,0,420.99,389.144Z"
                              fill="#2e90d1"
                            />
                            <path
                              d="M424.214,398.811c0,1.4.006,2.8,0,4.193,0,.252.052.382.326.333a.23.23,0,0,1,.041,0c1.038.008,1.051.03.934,1.094-.018.164-.078.2-.222.2-1.142,0-2.284,0-3.426,0-.175,0-.25-.053-.226-.229a.228.228,0,0,0,0-.041c-.04-1.009-.04-1.018.962-1.016.26,0,.336-.064.335-.331q-.016-3.547,0-7.094c0-.268-.077-.331-.335-.33-.982,0-.972-.008-.97-.978,0-.25.066-.318.314-.311.658.018,1.317.02,1.974,0,.266-.009.3.088.3.322C424.209,396.017,424.214,397.414,424.214,398.811Z"
                              fill="#2e90d1"
                            />
                            <path
                              d="M423.548,390.43a1.289,1.289,0,1,1,1.31-1.255A1.294,1.294,0,0,1,423.548,390.43Z"
                              fill="#2e90d1"
                            />
                          </g>
                        </svg>
                        <div>
                          Вы можете продлить пакет.
                          <br/>
                          Стоимость продления пакета ХХ за 30 календарных дней
                        </div>
                      </div>
                      <div class="actions mb-0">
                        <button
                          type="button"
                          @click="
                            openTariffPaymentModal(
                              subCourse.course_tariff,
                              subCourse.id,
                              subCourse.course.tariffs,
                              false
                            )
                          "
                        >
                          продлить пакет
                        </button>
                        <button
                          type="button"
                          v-if="!subCourse.active"
                          @click="
                            openTariffPaymentModal(
                              subCourse.course_tariff,
                              subCourse.id,
                              subCourse.course.tariffs,
                              true
                            )
                          "
                        >
                          Изменить пакет
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="tabs-info">
                    Вы еще не приобрели ни одного курса. Подписки отсутствуют.
                  </div>
                  <div class="actions">
                    <button
                      type="button"
                      class="choose-btn"
                      @click="$router.push('/profile/courses/main')"
                    >
                      Выбрать курс
                    </button>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="choose-course" ref="chooseCoursePlan">
      <template #modal-header="{ close }">
        <b-button @click="close()">
          X
        </b-button>
        <h3>Выберите способ опаты</h3>
      </template>
      <div class="content">
        <ValidationObserver
          tag="form"
          ref="buyCourseTariffForm"
          @submit.prevent="submitBuyTariff"
        >
          <div v-if="editTariff">
            <label>Пакет</label>
            <div class="b-dropdown">
              <b-dropdown
                class="select-dropdown"
                :text="tariffActive.name[$i18n.locale]"
              >
                <b-dropdown-item
                  href="#"
                  @click="selectTariff(tariff)"
                  v-for="(tariff, index) in tariffs"
                  :key="index"
                >{{ tariff.name[$i18n.locale] }}
                </b-dropdown-item
                >
              </b-dropdown>
            </div>
            <span class="text-danger" v-if="courseTariffError">{{
                courseTariffError
              }}</span>
          </div>
          <div class="form-group" v-else>
            <!--            <div>-->
            <!--              <label>Пакет</label>-->
            <!--              {{ courseTariff.name[$i18n.locale] }}-->
            <!--            </div>-->
          </div>
          <div class="price-info">К оплате: <span>15 900 руб</span></div>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            v-if="paymentMethodList && paymentMethodList.length"
          >
            <div class="radio-container">
              <label>Способ оплаты:</label>
              <div class="form-group checkbox-group">
                <label
                  v-for="(paymentMethod, index) in paymentMethodList"
                  :key="index"
                  class="checkbox-container"
                >
                  <input
                    v-model="formData.payment_method_id"
                    :value="paymentMethod.id"
                    type="radio"
                    name="Способ оплаты"
                  />
                  <span class="checkbox"/>
                  <span>{{ paymentMethod.name[$i18n.locale] }}</span>
                </label>
              </div>
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          <div class="actions">
            <button
              type="submit"
              :disabled="btnDisableBuyCourseForm"
              :class="btnDisableBuyCourseForm ? 'btn-disabled' : ''"
            >
              ПЕРЕЙТИ К ОПЛАТЕ
            </button>
          </div>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import PhoneInput from '../../../components/PhoneInput'
import PasswordInput from '../../../components/PasswordInput'
import CopyInput from '../../../components/Profile/CopyInput'
import ReferralCoupons from '../../../components/Profile/ReferralCoupons'

export default {
  name: 'PersonalDetails',
  components: { ReferralCoupons, CopyInput, PasswordInput, PhoneInput },
  data () {
    return {
      formData: {
        course_tariff_id: '',
        payment_method_id: '',
        subscription_id: ''
      },
      tariffs: [],
      editTariff: false,
      courseTariff: {
        name: {}
      },
      tariffActive: {
        name: {}
      },
      year: '',
      userFormData: {},
      userPasswordFormData: {},
      passwordError: {},
      disableBtn: false,
      emailDisableBtn: false,
      btnDisableBuyCourseForm: false,
      courseTariffError: '',
      validPhone: false,
    }
  },
  mounted () {
    this.getSubscriptionList().then(() => {
    })
    this.year = new Date().getFullYear()
    this.getMe().then(() => {
      this.userFormData = { ...this.user }
      this.userFormData.birthdayDay = moment(this.user.birthday).format('DD')
      this.userFormData.birthdayMonth = moment(this.user.birthday).format('MM')
      this.userFormData.birthdayYear = moment(this.user.birthday).format('YYYY')
    })
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      paymentMethodList: state => state.paymentMethod.paymentMethodList,
      subscriptionCoursesList: state => state.course.subscriptionCoursesList
    }),
    is_mobile () {
      const isMobile = window.matchMedia('only screen and (max-width: 576px)')
      return !!isMobile.matches
    }
  },
  methods: {
    ...mapActions({
      updateUserPersonalData: 'user/updateUserPersonalData',
      updatePassword: 'user/updatePassword',
      updateAvatar: 'user/updateAvatar',
      deleteUserAvatar: 'user/deleteUserAvatar',
      veryficationEmail: 'user/veryficationEmail',
      getSubscriptionList: 'course/getSubscriptionList',
      updateExpiryDate: 'course/updateExpiryDate',
      getMe: 'auth/authUser',
    }),
    selectTariff (tariff) {
      this.courseTariffError = ''
      this.tariffActive = tariff
      this.formData.course_tariff_id = tariff.id
    },
    openTariffPaymentModal (tariff, id, courseTariffs, editTariff) {
      this.editTariff = editTariff
      this.tariffs = courseTariffs
      this.formData.subscription_id = id
      this.courseTariff = tariff
      this.formData.payment_method_id = tariff.id
      this.$refs.chooseCoursePlan.show()
    },
    submitBuyTariff () {
      this.$refs.buyCourseTariffForm.validate().then(success => {
        if (success) {
          if (
            (this.editTariff && this.formData.course_tariff_id) ||
            !this.editTariff
          ) {
            this.courseTariffError = ''
            this.btnDisableBuyCourseForm = true
            if (!this.editTariff) {
              this.formData.course_tariff_id = ''
            } else {
              if (!this.formData.course_tariff_id) {
                this.courseTariffError = 'Выберите тариф'
                return false
              }
            }
            this.updateExpiryDate(this.formData)
              .then(res => {
                this.$refs.chooseCoursePlan.hide()
                this.formData = {
                  payment_method_id: '',
                  course_tariff_id: '',
                  subscription_id: ''
                }
              })
              .catch(() => {
                this.$toasted.error('Something went wrong!').goAway(1500)
              })
              .finally(() => {
                this.btnDisableBuyCourseForm = false
              })
          } else {
            this.courseTariffError = 'Please select course tariff!'
          }
        }
      })
    },
    updateUser () {
      this.$refs.form.validate().then(success => {
        if (success && this.validPhone) {
          this.disableBtn = true
          this.updateUserPersonalData(this.userFormData)
            .then(res => {
              this.userFormData = res.data.data

              this.$toasted
                .success('Вашы персональные данные были изменены!')
                .goAway(1500)
            })
            .catch((e) => {
              const response = e.response
              let errorMessage = ''
              if (response.status === 400 || response.status === 422) {
                for (const key in response.data) {
                  this.$toasted.error(errorMessage = response.data[key][0]).goAway(1500)
                }
              } else {
                this.$toasted.error('Ошибка сервера!').goAway(1500)
              }

            })
            .finally(() => {
              this.disableBtn = false
            })
        } else {
          this.$toasted.error('Ваше сообщение содержит ошибки').goAway(1500)
        }
      })
    },
    verifyEmail () {
      this.emailDisableBtn = true
      this.veryficationEmail({ email: this.userFormData.email })
        .then(() => {
          this.$refs.verifyModal.show()
        })
        .catch(err => {
          this.$toasted.error(err.response.data).goAway(1500)
        })
        .finally(() => {
          this.emailDisableBtn = false
        })
    },
    updateUserPassword () {
      this.passwordError = {}
      this.$refs.formPassword.validate().then(success => {
        if (success) {
          this.disableBtn = true
          this.updatePassword(this.userPasswordFormData)
            .then(() => {
              this.$toasted.success('Ваш пароль был изменён!').goAway(1500)
              this.userPasswordFormData = {}
              this.$refs.formPassword.reset()
            })
            .catch(err => {
              this.passwordError = err.response.data
            })
            .finally(() => {
              this.disableBtn = false
            })
        } else {
          this.$toasted.error('Ваше сообщение содержит ошибки!').goAway(1500)
        }
      })
    },
    uploadAvatar (file) {
      const data = new FormData()
      data.append('avatar', file[0])
      this.disableBtn = true
      this.updateAvatar(data)
        .then(() => {
          this.$toasted.success('Ваш аватар был изменён!').goAway(1500)
        })
        .catch(error => {
          if (error.response.status === 400) {
            const data = error.response.data
            const keys = Object.keys(data)
            const msg = data[keys[0]][0]
            this.$toasted.error(msg).goAway(1500)
          } else {
            this.$toasted.error('Server error!').goAway(1500)
          }
        })
        .finally(() => {
          this.disableBtn = false
        })
    },
    deleteAvatar () {
      this.deleteUserAvatar()
        .then(() => {
          this.$toasted.success('Ваш аватар был удален!').goAway(1500)
        })
        .catch(() => {
          this.$toasted.error('Server error!').goAway(1500)
        })
    }
  }
}
</script>
<style lang="scss">
.w-35 {
  width: 35%;
}
.eye-icon-details {
  top: 32% !important;
}

.tooltip-position{
  top: 0 !important;
}

.w-510{
  width: 510px;
}

.border-none {
  border: none;
}
</style>
