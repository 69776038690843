<template>
  <div class="statistics-progress">
    <div class="line">
      <div class="circle circle-start">
        <div class="circle-background" :style="finishModule > 0 ? 'width: 100%' : ''"></div>
        <div class="count">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="26.001" viewBox="0 0 21 26.001">
            <g transform="translate(0 0)">
              <g>
                <g transform="translate(-542.897 -222.2)">
                  <g>
                    <path d="M543.883,248.2c-.526,0-.723-.305-.9-.84-.069-.205.137-.411.137-.576v-13.2c0-2.824-.206-5.647-.218-8.47a1.187,1.187,0,0,1,.536-1.175,9.739,9.739,0,0,1,5.338-1.737,8.616,8.616,0,0,1,2.835.494,7.08,7.08,0,0,1,2.174,1.174,5.762,5.762,0,0,0,3.738,1.346,8.315,8.315,0,0,0,2.44-.4,14.713,14.713,0,0,0,2.739-1.106,1.171,1.171,0,0,1,.557-.109h.193l.236.105a.931.931,0,0,1,.215.7c-.018,4.105-.014,8.2,0,12.3a1.334,1.334,0,0,1-.5,1.08,7.965,7.965,0,0,1-5.318,1.963,8.607,8.607,0,0,1-5.287-1.821,6.144,6.144,0,0,0-3.231-1.5,5.639,5.639,0,0,0-.607-.033,9.393,9.393,0,0,0-4.2,1.131l-.249,5.593v3.416c0,.2.308.4.287.59-.067.607-.315,1.007-.92,1.069Zm5.057-14.205a8.243,8.243,0,0,1,5.331,1.973,6.012,6.012,0,0,0,3.77,1.416,5.662,5.662,0,0,0,3.207-1.033c.188-.129.061-.216.06-.445-.011-2.15-.182-4.3-.182-6.448v-2.575c0,.02.053.04-.007.061a11.224,11.224,0,0,1-3.724.7,8.1,8.1,0,0,1-5.076-1.76,5.817,5.817,0,0,0-3.563-1.3,7.211,7.211,0,0,0-3.5,1.027c-.143.079-.467.109-.466.305.012,2.187-.29,4.372-.29,6.559V234.8c0-.436,2.407-.688,3.4-.771C548.153,234.005,548.7,233.995,548.94,233.995Z"/>
                    <path d="M543.158,235.919c0-3.6.005-7.206-.01-10.81a.8.8,0,0,1,.425-.773,8.715,8.715,0,0,1,7.86-1.2,6.746,6.746,0,0,1,2.051,1.1,6.735,6.735,0,0,0,6.6,1.028,16.31,16.31,0,0,0,2.818-1.209c.38-.2.535-.222.532.312q-.025,6.162,0,12.323a.889.889,0,0,1-.344.732,7.915,7.915,0,0,1-10.024.13,6.73,6.73,0,0,0-3.464-1.594,8.294,8.294,0,0,0-4.758,1.15c-.254.131-.2.354-.2.56q0,4.35,0,8.7a6.576,6.576,0,0,1-.013.7.68.68,0,0,1-.659.654.692.692,0,0,1-.77-.52,1.353,1.353,0,0,1-.046-.425q0-2.73,0-5.459Zm1.491-.438c.216-.085.377-.146.535-.209a10.348,10.348,0,0,1,3.062-.777,7.8,7.8,0,0,1,5.741,1.843,6.08,6.08,0,0,0,7.519.4.9.9,0,0,0,.434-.837c-.015-2.937-.007-5.872-.008-8.809,0-.875,0-.866-.813-.588-2.977,1.019-5.822,1.017-8.482-1-2.437-1.847-5-1.754-7.59-.306a.7.7,0,0,0-.406.72c.015,2.864.008,5.728.008,8.592Z"/>
                  </g>
                </g>
                <path d="M.081,25.16c-.069-.206.138-.411.138-.576v-13.2C.219,8.558.012,5.734,0,2.911A1.186,1.186,0,0,1,.537,1.737,9.733,9.733,0,0,1,5.875,0,8.616,8.616,0,0,1,8.71.494a7.065,7.065,0,0,1,2.174,1.174,5.765,5.765,0,0,0,3.739,1.345,8.308,8.308,0,0,0,2.44-.4A14.749,14.749,0,0,0,19.8,1.509a1.175,1.175,0,0,1,.557-.11h.193l.236.105a.931.931,0,0,1,.214.7c-.018,4.106-.014,8.2,0,12.3a1.334,1.334,0,0,1-.5,1.081,7.968,7.968,0,0,1-5.317,1.963A8.613,8.613,0,0,1,9.89,15.728a6.143,6.143,0,0,0-3.231-1.5,5.758,5.758,0,0,0-.607-.032,9.4,9.4,0,0,0-4.2,1.131l-.1,2.279q0,3.282,0,6.565c0,.152.005.306,0,.458a.473.473,0,0,1,.139.3c-.067.607-.315,1.007-.92,1.068L.986,26C.46,26,.263,25.694.081,25.16ZM11.374,13.768a6.016,6.016,0,0,0,3.77,1.416,5.664,5.664,0,0,0,3.208-1.033c.188-.129.061-.216.059-.445-.011-2.151-.182-4.3-.182-6.449V4.741l-.006,0a11.24,11.24,0,0,1-3.724.7,8.1,8.1,0,0,1-5.077-1.76,5.817,5.817,0,0,0-3.563-1.3,7.212,7.212,0,0,0-3.5,1.027c-.143.079-.467.109-.465.305.006,1.1-.067,2.19-.14,3.284q0,2.654,0,5.31v.1a11.991,11.991,0,0,1,3.255-.588c.248-.021.8-.031,1.035-.031A8.243,8.243,0,0,1,11.374,13.768Z" :style="finishModule > 0 ? 'fill: #fff;' : 'fill: #333333;'"/>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div class="line-active" :style="'width:' + finishPercent + '%'"></div>
      <div class="circle circle-finish">
        <div class="circle-background" :style="finishModule >= statisticsCount ? 'width: 100%' : ''"></div>
        <div class="count">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" viewBox="0 0 19 24">
            <path id="Color_Overlay" d="M1746.073,356.225c-.062-.19.124-.38.124-.532V343.506c0-2.606-.186-5.213-.2-7.818a1.106,1.106,0,0,1,.485-1.085,8.693,8.693,0,0,1,4.83-1.6,7.662,7.662,0,0,1,2.565.456,6.358,6.358,0,0,1,1.966,1.084,5.15,5.15,0,0,0,3.382,1.242,7.38,7.38,0,0,0,2.208-.367,13.185,13.185,0,0,0,2.478-1.022,1.047,1.047,0,0,1,.5-.1h.175l.213.1a.87.87,0,0,1,.194.647c-.016,3.79-.012,7.565,0,11.354a1.243,1.243,0,0,1-.455,1,7.124,7.124,0,0,1-4.811,1.812,7.684,7.684,0,0,1-4.783-1.681,5.507,5.507,0,0,0-2.923-1.384,4.919,4.919,0,0,0-.549-.031,8.369,8.369,0,0,0-3.8,1.044l-.092,2.107q0,3.028,0,6.057c0,.14,0,.283,0,.423a.442.442,0,0,1,.124.275c-.06.56-.284.93-.833.986l.014,0C1746.415,357,1746.238,356.718,1746.073,356.225Zm8.342-11.7v-4.3c-1.49-.792-5.118-.636-6.828-.3q0,2.225,0,4.448v.091a10.665,10.665,0,0,1,2.946-.543c.224-.02.72-.029.937-.029A7.024,7.024,0,0,1,1754.415,344.52Zm1.084-2.716c1.294.846,5.2.524,7.051.314-.031-.806-.057-1.613-.057-2.419v-2.323l-.006,0a9.976,9.976,0,0,1-3.37.649,7.036,7.036,0,0,1-3.618-.967Z" transform="translate(-1746 -332.999)" :style="finishModule >= statisticsCount ? 'fill: #fff;' : 'fill: #333333;'"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="progress-circle">
      <div class="circle" v-for="(data, index) in progressData" :key="data.id" @click="openProgressData(data, index + 1)" :class="`${data.status === 'done' ? 'cursor-pointer': ''}`">
        <div class="circle-background" :style="data.status === 'done' ? 'width: 100%' : ''"></div>
        <div class="count" :style="data.status === 'done' ? 'color: #fff;' : 'color: #333333;'">{{index +1}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Progress',
  props: {
    finishModule: {
      type: Number,
      required: true
    },
    statisticsCount: {
      type: Number,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    },
    queryKey:{
      type: String,
      default: ''
    },
    progressData: {
      type: [Object, Array],
      required: true
    },
    idKey: {
      type: String,
      default: 'id'
    },
    typeKey: {
      type: String,
      default: null
    }
  },
  computed: {
    finishPercent(){
      return (100 / this.statisticsCount) * this.finishModule
    }
  },
  methods: {
    openProgressData(data, number){
      if (data.status !== 'done'){
        return false
      }
      this.routeParams.query[this.queryKey] = number
      if (this.routeParams.params){
        this.routeParams.params['id'] = data[this.idKey]
      }
      if (this.typeKey){
        this.routeParams.query[this.typeKey] = data[this.idKey]
      }
      this.$router.push(this.routeParams)
    },
  }
}
</script>
