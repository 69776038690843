<template>
  <div class="task-item-img wordable">
    <img :src="require(`@/assets/images/tasks/${name}.png`)"  class="wordable" alt="word"/>
  </div>
</template>

<script>
export default {
  name: 'MobileIcon',
  props: {
    name: {
      type: String,
      default: 'pick-word'
    }
  }
}
</script>

<style scoped lang="scss">
.task-item-img {
  width: 38px;
  height: 38px;
  background-color: #FFFFFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    display: none;
  }
}
</style>
