<template>
  <footer class="row footer justify-content-center">
    <div class="up-footer col-12">
      <div class="row justify-content-center page-container">
        <div class="col-lg-12 col-sm-12 p-0">
          <div class="row d-flex flex-sm-column flex-lg-row">
            <div class="col-md-12 col-lg-4 d-flex flex-wrap  footer-item" style="min-height: 440px">
              <div class="up-footer-logo col-md-12">
                <div class="up-footer-logo-content ">
                  <img src="@/assets/images/logo-white.png" alt="logo-white" />
                </div>
              </div>
              <div class="up-footer-info-text col-md-6 col-lg-12">
                Language2Go — интерактивная платформа для легкого, удобного и быстрого изучения
                иностранных языков. Авторские методики разработаны опытными преподавателями
                зарубежных университетов. Проходить обучение вы можете в любом месте, в удобное
                время и с различных устройств.
              </div>
              <div class="footer-bottom-wrapper">
                <div class="up-footer-social-link col-md-6 col-lg-12 p-0 align-items-center">
                  <ul class="up-footer-social-link-list justify-content-around">
                    <li class="up-footer-social-link-list-item" v-if="settings.facebook">
                      <a :href="settings.facebook">
                        <img src="@/assets/images/icons/facebook.svg" alt="facebook" />
                      </a>
                    </li>
                    <li class="up-footer-social-link-list-item" v-if="settings.instagram">
                      <a :href="settings.instagram">
                        <img src="@/assets/images/icons/instagram.svg" alt="instagram" />
                      </a>
                    </li>
                    <li class="up-footer-social-link-list-item" v-if="settings.youtube">
                      <a :href="settings.youtube">
                        <img src="@/assets/images/icons/youtube.svg" alt="youtube" />
                      </a>
                    </li>
                    <li class="up-footer-social-link-list-item" v-if="settings.vk">
                      <a :href="settings.vk">
                        <img src="@/assets/images/icons/vk.svg" alt="vk" />
                      </a>
                    </li>
                    <li class="up-footer-social-link-list-item" v-if="settings.ok">
                      <a :href="settings.ok">
                        <img src="@/assets/images/icons/ok.svg" alt="vk" />
                      </a>
                    </li>
                    <li class="up-footer-social-link-list-item" v-if="settings.twitter">
                      <a :href="settings.twitter">
                        <img src="@/assets/images/icons/twitter.svg" alt="twitter" />
                      </a>
                    </li>
                    <li class="up-footer-social-link-list-item" v-if="settings.tiktok">
                      <a :href="settings.tiktok">
                        <img src="@/assets/images/icons/tiktok.svg" alt="tiktok" />
                      </a>
                    </li>
                    <li class="up-footer-social-link-list-item" v-if="settings.telegram">
                      <a :href="settings.telegram">
                        <img src="@/assets/images/icons/telegram.svg" alt="telegram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="up-footer-privacy-policy col-md-6 col-lg-12">
                  <ul class="up-footer-privacy-policy-list ">
                    <li class="up-footer-privacy-policy-list-item ">
                      <router-link to="/privacy-policy">Политика конфиденциальности</router-link>
                    </li>
                    <li class="up-footer-privacy-policy-list-item">
                      <router-link to="/terms-of-use">Пользовательское соглашение</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="footer-item-wrapper col-sm-12 col-md-12 col-lg-8 d-flex flex-md-row flex-sm-column flex-wrap align-items-center align-items-lg-start"
            >
              <div class="footer-item-1 col-sm-6 col-lg-6 col-xl-5">
                <div class="up-footer-site-map">
                  <h4 class="up-footer-site-map-title">Навигация по сайту</h4>
                  <div class="d-flex">
                    <div class="mr-5 my-class">
                      <ul class="up-footer-site-map-list">
                        <li class="up-footer-site-map-list-item">
                          <router-link to="/">Главная</router-link>
                        </li>
                        <li class="up-footer-site-map-list-item">
                          <router-link to="/about">О нас</router-link>
                        </li>
                        <li class="up-footer-site-map-list-item">
                          <router-link to="/courses/all">Курсы</router-link>
                          <ul class="up-footer-site-map-sub-list">
                            <li
                              class="up-footer-site-map-sub-list-item"
                              v-for="(language, index) in teachinglangs"
                              :key="index"
                            >
                              <router-link :to="'/courses/' + language.id"
                                >{{ language.name[$i18n.locale] }}
                              </router-link>
                            </li>
                            <li class="up-footer-site-map-sub-list-item">
                              <router-link to="/courses/all">Все курсы</router-link>
                            </li>
                          </ul>
                        </li>
                        <li class="up-footer-site-map-list-item">
                          <!-- <router-link to="/interesting/all">Интересное</router-link> -->
                          <ul class="up-footer-site-map-sub-list">
                            <li
                              class="up-footer-site-map-sub-list-item"
                              v-for="(category, i) in interestingCategories"
                              :key="i"
                            >
                              <router-link :to="'/interesting/' + category.slug"
                                >{{ category.title[$i18n.locale] }}
                              </router-link>
                            </li>
                            <li class="up-footer-site-map-sub-list-item">
                              <router-link to="/interesting/all">Все</router-link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <ul class="up-footer-site-map-list">
                        <!-- <li class="up-footer-site-map-list-item">
                          <router-link to="/news">Новости</router-link>
                        </li> -->
                        <li class="up-footer-site-map-list-item">
                          <router-link to="/video">Видео</router-link>
                        </li>
                        <li class="up-footer-site-map-list-item">
                          <router-link to="/reviews">Отзывы</router-link>
                        </li>
                        <li class="up-footer-site-map-list-item">
                          <router-link to="/faq">FAQ</router-link>
                        </li>
                        <li class="up-footer-site-map-list-item">
                          <router-link to="/contacts">Контакты</router-link>
                        </li>
                        <li class="up-footer-site-map-list-item">
                          <router-link to="/payments">Условия оплаты</router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer-item-2 col-sm-6 col-md-6 col-lg-6 ml-0 ml-sm-5">
                <div class="up-footer-contacts">
                  <h4 class="up-footer-contacts-title">Контакты</h4>
                  <div class="up-footer-contacts-list">
                    <!-- <div class="up-footer-contacts-list-item">
                      <div class="up-footer-contacts-list-item-name">
                        <span>Тел.:</span>
                      </div>
                      <div class="up-footer-contacts-list-item-info">
                        <div v-for="(phone, index) in settings.phone" v-bind:key="index">
                          <a href="tel:+7 000 555 55 55">{{ phone }}</a>
                        </div>
                      </div>
                    </div> -->
                    <div class="up-footer-contacts-list-item">
                      <div class="up-footer-contacts-list-item-name">
                        <span>E-mail:</span>
                      </div>
                      <div class="up-footer-contacts-list-item-info">
                        <div v-for="(email, index) in settings.email" v-bind:key="index">
                          <a :href="'mailto:' + email">{{ email }}</a>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="up-footer-contacts-list-item">
                      <div class="up-footer-contacts-list-item-name">
                        <span>Адрес:</span>
                      </div>
                      <div class="up-footer-contacts-list-item-info w-50">
                        <p class="m-0">{{ settings.address }}</p>
                      </div>
                    </div> -->
                  </div>
                  <div class="up-footer-cards" v-if="paymentMethodList && paymentMethodList.length">
                    <ul class="up-footer-cards-list">
                      <li
                        class="up-footer-cards-list-item"
                        v-for="(payment, index) in paymentMethodList"
                        :key="index"
                      >
                        <img :src="payment.footer_image" :alt="payment.name[$i18n.locale]" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 under-footer">
      <div class="under-footer-container page-container">
        <div class="website-under d-flex justify-content-between w-100">
          <div class="website-under-copyright">2020 | © Language to go | All rights reserved</div>
          <div class="website-under-logo">
            Site development by <img src="@/assets/images/logo-itway.png" alt="img" />
          </div>
        </div>
      </div>
    </div>
    <div class="anchor" v-show="anchor" @click="scrollToTop">
      <i class="fas fa-chevron-up"></i>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Footer',
  data() {
    return {
      anchor: false
    }
  },
  computed: {
    ...mapState({
      teachinglangs: state => state.teachingLang.teachinglangs,
      interestingCategories: state => state.interesting.interestingCategories,
      settings: state => state.setting.settings,
      paymentMethodList: state => state.paymentMethod.paymentMethodList
    })
  },
  created() {
    this.getSettings().then(() => {})
    this.getPaymentMethodList()
  },
  mounted() {
    window.addEventListener('load', this.scrollTop, false)
    window.addEventListener('scroll', this.scrollTop, false)
  },
  methods: {
    ...mapActions({
      getSettings: 'setting/getSettings',
      getPaymentMethodList: 'paymentMethod/getPaymentMethodList'
    }),
    scrollTop() {
      this.anchor = document.body.scrollTop > 44 || document.documentElement.scrollTop > 44
    },
    scrollToTop() {
      const c = document.documentElement.scrollTop || document.body.scrollTop
      if (c > 0) {
        window.requestAnimationFrame(this.scrollToTop)
        window.scrollTo(0, c - c / 9)
      }
    }
  }
}
</script>

<style lang="scss">
.up-footer-contacts-list-item-name {
  font-weight: 600;
}
.up-footer-site-map-list-item {
  margin-bottom: 20px !important;
  @media (max-width: 575px) {
    a {
      font-weight: 500 !important;
    }
  }
}
.anchor {
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 40px;
  right: 40px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  border: 1px solid black;

  &:hover {
    color: #78258d;
    background-color: rgb(185, 185, 185);
  }
}
</style>
