<template>
  <div>
    <!-- Video chat -->
    <div class='personal-details chat video-chat filled'>
      <div class='chat-content expert-chat'>
        <div class='top-line'>
          <div class='left'>
            <div class='d-flex align-items-center title-block'>
              <div class='flag'>
                <img src='../../../../assets/images/flags/de.png' alt=''>
              </div>
              <div>
                <div class='tabs-title'>
                  Видео занятие с Экспертом
                </div>
                <div class='subtitle'>
                  Немецкий язык
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <button
              type="button"
              class="btn btn-primary mr-2"
              @click="placeVideoCall()"
            >
              <img src='~@/assets/images/icons/video-white.svg' alt=''>
            </button>
          </div>
        </div>
        <div class='content video-chat-content'>
          <div class="row" v-if="incomingCallDialog">
            <div class="col">
              <p>
                Incoming Call From <strong>{{ callerDetails.id }}</strong>
              </p>
              <div class="btn-group" role="group">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="declineCall"
                >
                  Decline
                </button>
                <button
                  type="button"
                  class="btn btn-success ml-5"
                  @click="acceptCall"
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
          <div class='left-side' v-if="callPlaced">
            <div class='video-container person'>
              <video
                ref="partnerVideo"
                playsinline
                autoplay
                class="cursor-pointer"
                :class="isFocusMyself === true ? 'partner-video' : 'user-video'"
                @click="toggleCameraArea"
                v-if="videoCallParams.callAccepted"
              />
              <span v-if="!videoCallParams.callAccepted">Calling...</span>
              <video
                ref="userVideo"
                muted
                playsinline
                autoplay
                class="cursor-pointer"
                :class="isFocusMyself === true ? 'user-video' : 'partner-video'"
                @click="toggleCameraArea"
              />
              <div class='top-overlay'>
                <div class='bottom'>
                  <div class='bottom-actions'>
                    <a href='#' class='item' :class="mutedAudio ? 'active' : ''" @click.prevent="toggleMuteAudio">
                      <img src='~@/assets/images/icons/microphone.svg' alt=''>
                    </a>
                    <a href='#' class='item' :class="mutedVideo ? 'active' : ''" @click.prevent="toggleMuteVideo">
                      <img src='~@/assets/images/icons/video-white.svg' alt=''>
                    </a>
                    <a href='#' class='item item-close' @click.prevent="endCall">
                      <img src='~@/assets/images/icons/close-video-chat.svg' alt=''>
                    </a>
                  </div>
                  <div class='name'>{{user.first_name + ' ' + user.last_name}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class='right-side' :class="!incomingCallDialog ? 'flex-grow-1' : ''">
            <div class='chat-content'>
              <div class='content'>
                <ul v-chat-scroll>
                  <li :class="message.sender_is_user ? 'sent' : 'received'" v-for='(message, index) in messages' :key='index'>
                    <div class='d-flex' v-if='message.sender_is_user'>
                      <div class='general'>
                        <div class='username'>
                          <span class='date mr-2'>01.04.2020 | 15:22</span>
                          <span>{{ message.user.first_name + ' ' + message.user.last_name }}</span>
                        </div>
                        <div class='message'>
                          {{message.message}}
                        </div>
                      </div>
                      <div class='avatar'>
                        <img v-if='message.user.avatar' :src='message.user.avatar_path' alt=''>
                        <img v-else src='~@/assets/images/profile/add-image.svg' alt='avatar'>
                      </div>
                    </div>
                    <div class='d-flex' v-else>
                      <div class='avatar'>
                        <img v-if='message.admin.avatar' :src='message.admin.avatar_path' alt=''>
                        <img v-else src='~@/assets/images/profile/add-image.svg' alt='avatar'>
                      </div>
                      <div class='general'>
                        <div class='username'>
                          <span class='date mr-2'>01.04.2020 | 15:22</span>
                          <span>{{ message.admin.name }}</span>
                        </div>
                        <div class='message'>
                          {{message.message}}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <form ref='chatExpertForm' class='new-message' @submit.prevent='submitChatExpertForm'>
                <textarea v-model='formData.message' placeholder='Написать сообщение'></textarea>
                <button type='submit' class='send-btn'>
                  <span>отправить</span>
                  <img src='~@/assets/images/icons/send.svg' alt=''>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Chat -->
    <div class='personal-details chat d-none'>
      <div class='chat-content expert-chat'>
        <div class='top-line'>
          <div class='left'>
            <div class='d-flex align-items-center title-block'>
              <div class='flag'>
                <img src='../../../../assets/images/flags/de.png' alt=''>
              </div>
              <div>
                <div class='tabs-title'>
                  Чат с Экспертом
                </div>
                <div class='subtitle'>
                  Немецкий язык
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='content'>
          <ul>
            <li class='received'>
              <div class='avatar'>
                <img src='~@/assets/images/China.png' alt=''>
              </div>
              <div class='general'>
                <div class='username'>
                  <span>Имя преподавателя</span>
                  <span class='date'>01.04.2020 | 15:22</span>
                </div>
                <div class='message'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                  rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                  tincidunt cursus.
                </div>
              </div>
            </li>
            <li class='received'>
              <div class='avatar'>
                <img src='~@/assets/images/China.png' alt=''>
              </div>
              <div class='general'>
                <div class='username'>
                  <span>Имя преподавателя</span>
                  <span class='date'>01.04.2020 | 15:22</span>
                </div>
                <div class='message'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                  rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                  tincidunt cursus.
                </div>
              </div>
            </li>
            <li class='received'>
              <div class='avatar'>
                <img src='~@/assets/images/China.png' alt=''>
              </div>
              <div class='general'>
                <div class='username'>
                  <span>Имя преподавателя</span>
                  <span class='date'>01.04.2020 | 15:22</span>
                </div>
                <div class='message'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                  rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                  tincidunt cursus.
                </div>
              </div>
            </li>
            <li class='sent'>
              <div class='general'>
                <div class='username'>
                  <span class='date'>01.04.2020 | 15:22</span>
                  <span>User_nicname/login</span>
                </div>
                <div class='message'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                  rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                  tincidunt cursus.
                </div>
              </div>
              <div class='avatar'>
                <img src='~@/assets/images/China.png' alt=''>
              </div>
            </li>
            <li class='received'>
              <div class='avatar'>
                <img src='~@/assets/images/China.png' alt=''>
              </div>
              <div class='general'>
                <div class='username'>
                  <span>Имя преподавателя</span>
                  <span class='date'>01.04.2020 | 15:22</span>
                </div>
                <div class='message'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                  rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                  tincidunt cursus.
                </div>
              </div>
            </li>
            <li class='sent'>
              <div class='general'>
                <div class='username'>
                  <span class='date'>01.04.2020 | 15:22</span>
                  <span>User_nicname/login</span>
                </div>
                <div class='message'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                  rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                  tincidunt cursus.
                </div>
              </div>
              <div class='avatar'>
                <img src='~@/assets/images/China.png' alt=''>
              </div>
            </li>
            <li class='sent'>
              <div class='general'>
                <div class='username'>
                  <span class='date'>01.04.2020 | 15:22</span>
                  <span>User_nicname/login</span>
                </div>
                <div class='message'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                  rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                  tincidunt cursus.
                </div>
              </div>
              <div class='avatar'>
                <img src='~@/assets/images/China.png' alt=''>
              </div>
            </li>
            <li class='received'>
              <div class='avatar'>
                <img src='~@/assets/images/China.png' alt=''>
              </div>
              <div class='general'>
                <div class='username'>
                  <span>Имя преподавателя</span>
                  <span class='date'>01.04.2020 | 15:22</span>
                </div>
                <div class='message'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                  rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                  tincidunt cursus.
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class='new-message'>
          <input type='text' placeholder='Написать сообщение'>
          <button type='button' class='send-btn'>
            <span>отправить</span>
            <img src='~@/assets/images/icons/send.svg' alt=''>
          </button>
        </div>
      </div>
    </div>
    <!-- No Chat -->
    <div class='personal-details chat video-chat filled d-none'>
      <div class='chat-content expert-chat'>
        <div class='top-line'>
          <div class='left'>
            <div class='d-flex align-items-center title-block'>
              <div class='flag'>
                <img src='../../../../assets/images/flags/de.png' alt=''>
              </div>
              <div>
                <div class='tabs-title'>
                  Видео занятие с Экспертом
                </div>
                <div class='subtitle'>
                  Немецкий язык
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='no-chat'>
          <img src='../../../../assets/images/no_chat.png' alt='No chat'>
          <p>Ожидайте видеовызов от Эксперта.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getPermissions } from '../../../../helpers'

export default {
  name: 'ChatExpert',
  data () {
    return {
      isFocusMyself: true,
      callPlaced: false,
      callPartner: null,
      mutedAudio: false,
      mutedVideo: false,
      videoCallParams: {
        users: [],
        stream: null,
        receivingCall: false,
        caller: null,
        callerSignal: null,
        callAccepted: false,
        channel: null,
        peer1: null,
        peer2: null
      },
      messages: [],
      formData: {}
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    incomingCallDialog () {
      if (
        this.videoCallParams.receivingCall &&
        this.videoCallParams.caller !== this.authuserid
      ) {
        return true
      }
      return false
    },

    callerDetails () {
      if (
        this.videoCallParams.caller &&
        this.videoCallParams.caller !== this.authuserid
      ) {
        const incomingCaller = this.allusers.filter(
          (user) => user.id === this.videoCallParams.caller
        )

        return {
          id: this.videoCallParams.caller,
          name: `${incomingCaller[0].name}`
        }
      }
      return null
    }
  },
  created () {
    this.getMessages({ id: this.$route.params.id }).then((res) => {
      this.messages = res.data
    }).catch(err => {
      console.log(err)
    })
    this.listenForChatExpertNewMessage(this.$route.params.userCourseID)
    this.initializeChannel() // this initializes laravel echo
    this.initializeCallListeners() // subscribes to video presence channel and listens to video events
  },
  methods: {
    ...mapActions({
      sendMessage: 'chatExpert/sendMessage',
      callExpert: 'chatExpert/callExpert',
      acceptExpertCall: 'chatExpert/acceptCall',
      getMessages: 'chatExpert/getMessages'
    }),

    initializeChannel () {
      this.videoCallParams.channel = window.Echo.join('expert-video-chat-channel')
    },

    getMediaPermission () {
      return getPermissions()
        .then((stream) => {
          this.videoCallParams.stream = stream
          if (this.$refs.userVideo) {
            this.$refs.userVideo.srcObject = stream
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },

    initializeCallListeners () {
      this.videoCallParams.channel.here((users) => {
        this.videoCallParams.users = users
      })

      this.videoCallParams.channel.joining((user) => {
        // check user availability
        const joiningUserIndex = this.videoCallParams.users.findIndex(
          (data) => data.id === user.id
        )
        if (joiningUserIndex < 0) {
          this.videoCallParams.users.push(user)
        }
      })

      this.videoCallParams.channel.leaving((user) => {
        const leavingUserIndex = this.videoCallParams.users.findIndex(
          (data) => data.id === user.id
        )
        this.videoCallParams.users.splice(leavingUserIndex, 1)
      })
      // listen to incomming call
      this.videoCallParams.channel.listen('StartExpertVideoChat', ({ data }) => {
        if (data.type === 'incomingCall') {
          // add a new line to the sdp to take care of error
          const updatedSignal = {
            ...data.signalData,
            sdp: `${data.signalData.sdp}\n`
          }

          this.videoCallParams.receivingCall = true
          this.videoCallParams.caller = data.from
          this.videoCallParams.callerSignal = updatedSignal
        }
      })
    },
    async placeVideoCall () {
      this.callPlaced = true
      await this.getMediaPermission()
      this.videoCallParams.peer1 = new Peer({
        initiator: true,
        trickle: false,
        stream: this.videoCallParams.stream,
        config: {
          iceServers: [
            {
              url: 'turn:numb.viagenie.ca',
              credential: 'muazkh',
              username: 'webrtc@live.com'
            }
          ]
        }
      })

      this.videoCallParams.peer1.on('signal', (data) => {
        this.callExpert({
          signal_data: data
        }).then((res) => {
          console.log(res)
        }).catch((error) => {
          console.log(error)
        })
      })

      this.videoCallParams.peer1.on('stream', (stream) => {
        console.log('call streaming')
        if (this.$refs.partnerVideo) {
          this.$refs.partnerVideo.srcObject = stream
        }
      })

      this.videoCallParams.peer1.on('connect', () => {
        console.log('peer connected')
      })

      this.videoCallParams.peer1.on('error', (err) => {
        console.log(err)
      })

      this.videoCallParams.peer1.on('close', () => {
        console.log('call closed caller')
      })

      this.videoCallParams.channel.listen('StartExpertVideoChat', ({ data }) => {
        if (data.type === 'callAccepted') {
          if (data.signal.renegotiate) {
            console.log('renegotating')
          }
          if (data.signal.sdp) {
            this.videoCallParams.callAccepted = true
            const updatedSignal = {
              ...data.signal,
              sdp: `${data.signal.sdp}\n`
            }
            this.videoCallParams.peer1.signal(updatedSignal)
          }
        }
      })
    },

    async acceptCall () {
      this.callPlaced = true
      this.videoCallParams.callAccepted = true
      await this.getMediaPermission()
      this.videoCallParams.peer2 = new Peer({
        initiator: false,
        trickle: false,
        stream: this.videoCallParams.stream,
        config: {
          iceServers: [
            {
              url: 'turn:numb.viagenie.ca',
              credential: 'muazkh',
              username: 'webrtc@live.com'
            }
          ]
        }
      })
      this.videoCallParams.receivingCall = false
      this.videoCallParams.peer2.on('signal', (data) => {
        this.acceptExpertCall({
          signal: data,
          to: this.videoCallParams.caller
        })
          .then(() => {
          })
          .catch((error) => {
            console.log(error)
          })
      })

      this.videoCallParams.peer2.on('stream', (stream) => {
        this.videoCallParams.callAccepted = true
        this.$refs.partnerVideo.srcObject = stream
      })

      this.videoCallParams.peer2.on('connect', () => {
        console.log('peer connected')
        this.videoCallParams.callAccepted = true
      })

      this.videoCallParams.peer2.on('error', (err) => {
        console.log(err)
      })

      this.videoCallParams.peer2.on('close', () => {
        console.log('call closed accepter')
      })

      this.videoCallParams.peer2.signal(this.videoCallParams.callerSignal)
    },
    toggleCameraArea () {
      if (this.videoCallParams.callAccepted) {
        this.isFocusMyself = !this.isFocusMyself
      }
    },
    getUserOnlineStatus (id) {
      const onlineUserIndex = this.videoCallParams.users.findIndex(
        (data) => data.id === id
      )
      if (onlineUserIndex < 0) {
        return 'Offline'
      }
      return 'Online'
    },
    declineCall () {
      this.videoCallParams.receivingCall = false
    },

    toggleMuteAudio () {
      if (this.mutedAudio) {
        this.$refs.userVideo.srcObject.getAudioTracks()[0].enabled = true
        this.mutedAudio = false
      } else {
        this.$refs.userVideo.srcObject.getAudioTracks()[0].enabled = false
        this.mutedAudio = true
      }
    },

    toggleMuteVideo () {
      if (this.mutedVideo) {
        this.$refs.userVideo.srcObject.getVideoTracks()[0].enabled = true
        this.mutedVideo = false
      } else {
        this.$refs.userVideo.srcObject.getVideoTracks()[0].enabled = false
        this.mutedVideo = true
      }
    },

    stopStreamedVideo (videoElem) {
      const stream = videoElem.srcObject
      const tracks = stream.getTracks()
      tracks.forEach((track) => {
        track.stop()
      })
      videoElem.srcObject = null
    },
    endCall () {
      // if video or audio is muted, enable it so that the stopStreamedVideo method will work
      if (!this.mutedVideo) this.toggleMuteVideo()
      if (!this.mutedAudio) this.toggleMuteAudio()
      this.stopStreamedVideo(this.$refs.userVideo)
      if (this.authuserid === this.videoCallParams.caller) {
        this.videoCallParams.peer1.destroy()
      } else {
        this.videoCallParams.peer2.destroy()
      }
      this.videoCallParams.channel.pusher.channels.channels[
        'presence-expert-video-chat-channel'
      ].disconnect()

      setTimeout(() => {
        this.callPlaced = false
      }, 3000)
    },
    listenForChatExpertNewMessage (userCourseID) {
      window.Echo.join(`chat-expert.${userCourseID}`)
        .here((users) => {
          console.log(users)
        })
        .joining((user) => {
          console.log(user)
        })
        .leaving((user) => {
          console.log(user)
        })
        .listen('ChatExpertMessageSent', (e) => {
          this.messages.push(e.data)
        })
    },
    leaveChatExpert () {
      window.Echo.leave('chat-expert.' + this.selectedChatId)
    },
    submitChatExpertForm () {
      if (this.formData !== '') {
        this.formData.course_id = this.$route.params.id
        this.sendMessage(this.formData).then((res) => {
          this.formData = {}
          this.messages.push(res.data)
        }).catch(() => {
          this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
        })
      }
    }
  }
}
</script>
<style>
  .video-container .user-video {
    width: 30%;
    position: absolute;
    right: 16px;
    bottom: 85px;
    border: 1px solid #fff;
    border-radius: 6px;
    z-index: 2;
  }
  .video-container .partner-video {
    width: 100%;
    height: 100%;
    border: 2px solid;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    margin: 0;
    padding: 0;
  }
</style>
