import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import i18n, { loadLanguageAsync } from './localization/i18n'
import './scss/app.scss'
import { BootstrapVue } from 'bootstrap-vue'
import Toasted from 'vue-toasted'
import VueAudio from 'vue-audio-better'
import { ValidationProvider, ValidationObserver, configure, extend, localize } from 'vee-validate'
import VueMasonry from 'vue-masonry-css'
import en from 'vee-validate/dist/locale/en.json'
import ru from 'vee-validate/dist/locale/ru.json'
import * as rules from 'vee-validate/dist/rules'
import { VueStars } from 'vue-stars'
import VueMeta from 'vue-meta'
import VueDraggable from 'vue-draggable'
import VueSocialSharing from 'vue-social-sharing'
import VueChatScroll from 'vue-chat-scroll'
import vClickOutside from 'v-click-outside'
import SvgSprite from 'vue-svg-sprite'
import '@fortawesome/fontawesome-free/css/all.min.css'
import VueEcho from 'vue-echo-laravel'
import Pusher from 'pusher-js'
import GoogleSignInButton from 'vue-google-signin-button-directive'
import { http } from './api'
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(VueChatScroll)
Vue.use(VueSocialSharing)
Vue.use(VueDraggable)
Vue.use(vClickOutside)
Vue.use(SvgSprite)
Vue.use(Vue2TouchEvents)
Vue.use(VueEcho, {
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  encrypted: true,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        http
          .post(process.env.VUE_APP_SOCKET_AUTH, {
            socket_id: socketId,
            channel_name: channel.name
          })
          .then(response => {
            callback(false, response.data)
          })
          .catch(error => {
            callback(true, error)
          })
      }
    }
  }
})

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.component('vue-stars', VueStars)

Vue.use(VueMasonry)

// Vue audio
Vue.use(VueAudio)

// Vue moment
const moment = require('moment')
require('moment/locale/ru')
Vue.use(require('vue-moment'), { moment })

Vue.config.productionTip = false

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
localize({
  ru,
  en
})
localize('ru')

configure({
  classes: {
    invalid: 'is-invalid error-form'
  }
})
// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match'
})

Vue.use(BootstrapVue)
Vue.use(Toasted, {
  type: ['success', 'error']
})

router.beforeEach((to, from, next) => {
  loadLanguageAsync(i18n.locale).then(() => {
    next()
  })
})

const vue = new Vue({
  GoogleSignInButton,
  i18n,
  store,
  render: h => h(App),
  router: router
}).$mount('#app')
window.$echo = vue.$echo
