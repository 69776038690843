<template>
  <TaskBase :task="task" :check-task="checkComponetText" :correct-answer="correctAnswer">
    <template #desktop>
      <WordModal :words="answerArr" :show.sync="showModal">
        <Words :words="answerArr">
          <template #list="{word,index}">
              <span :class="{'words-used': findHoldWords(index) !== false}">
                {{ index === (answerArr.length - 1) ? `${word}.` : `${word},` }}
              </span>
          </template>
        </Words>
        <template v-slot:list="{word,index}">
              <span class="status-word-border" :class="{
                'success-word':findHoldWords(index) && success[findHoldWords(index)] ||findHoldWords(index) !== false && errors[findHoldWords(index)] !== true,
                'error-word': findHoldWords(index) && errors[findHoldWords(index)],
              }">
                  <span class="status-word"></span>
              </span>
          <span style="text-transform: none !important;" :data-index="index" :data-answer="true"
                @click="($event) => pickWordModal($event.target,index)">{{ word }}</span>
        </template>
        <div v-drag-and-drop:options="options">
          <div class="missing-words">
            <template v-for="(word, index) in textArr">
              <div class="char-parent space" v-if="index === 0 || index === textArr.length - 1">
                <div class="char"></div>
              </div>
              <Break v-if="breaks[index]" :breaks="breaks[index]" class="char-parent br"/>

              <div v-else-if="!breaks[index + 1]" :key="index" class="char-parent" :class="{
              'space': word === ' ',
            }">
                <div class="text-block dragzone dragzone-item d-flex justify-content-center position-relative wordable"
                     @added="added(index, $event)"
                     style="text-transform: none !important;"
                     v-if="word === '|'"
                     @click="($event) => dragText(index, $event.target, word)"
                     :data-index="index"
                     :class="{
                     'primary-word':endTextArr[index] && endTextArr[index].length,
                     'success-word':success[index],
                     'error-word':errors[index] && (!isMobile || isMobile && holdWords[index] !== undefined),
                     'active-text': activeBlock === index && holdWords[index] !== undefined
                   }">
                  <img v-if="!endTextArr[index]" class="position-absolute only-mob"
                       src="@/assets/images/tasks/pick-word.png"/>
                  {{ endTextArr[index] }}
                  <span>{{ words[wordsIndex.findIndex(value => value === index)][0] }}</span>
                </div>
                <div v-else class="char">
                  {{ word }}
                </div>
              </div>
            </template>
          </div>
          <div class="d-flex flex-wrap justify-content-start composer-answers cursor-pointer">
            <div
              v-for="(answer, index) in answerArr"
              :key="index"
              :class="{'active-text': activeText === index && !inactiveAnswers[index],
              'used': inactiveAnswers[index]
              }"
              class="dragzone-item word-item text-uppercase mb-4"
              style="text-transform: none !important;"
              @click="($event) => selectAnswer(index, $event.target)"
              :data-index="index"
              :data-answer="true"
            >
              {{ answer }}
            </div>
          </div>
        </div>
      </WordModal>
    </template>
  </TaskBase>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import TaskAnswerModal from '../../../../components/TaskAnswerModal'
import VideoAudioGallery from '../../../../components/VideoAudioGallery'
import { VueDraggableDirective } from 'vue-draggable'
import BreakMixin from '../../../../../mixins/BreakMixin'
import Break from '../../../../components/Break'
import ArrayShuffleMixin from '../../../../../mixins/ArrayShuffleMixin'
import TaskBase from '../../../../components/TaskBase'
import TaskCheckParamsMixin from '../../../../../mixins/TaskCheckParamsMixin'
import WordModal from '../../../../components/Tasks/WordModal'
import CheckWidthMixin from '../../../../../mixins/CheckWidthMixin'
import Words from '../../../../components/Tasks/Words'

export default {
  name: 'ComposeText',
  mixins: [BreakMixin, ArrayShuffleMixin, TaskCheckParamsMixin, CheckWidthMixin],
  directives: {
    dragAndDrop: VueDraggableDirective
  },
  data () {
    return {
      activeBlock: '',
      openPrompt: false,
      activeText: '',
      textArr: [],
      endTextArr: [],
      answerArr: [],
      wordsIndex: [],
      options: {
        dropzoneSelector: '.dragzone',
        draggableSelector: '.dragzone-item',
        showDropzoneAreas: true
      },
      inactiveAnswers: {},
      holdWords: {},
      errors: [],
      success: [],
      wrapped: false,
      showModal: false,
      activeWord: {
        index: null,
        element: null
      },
      activeElement: null,
      words: []
    }
  },
  computed: {
    ...mapState({
      task: state => state.task.task,
      nextTask: state => state.course.nextTask
    })
  },
  components: {
    Words,
    WordModal,
    TaskBase,
    VideoAudioGallery,
    TaskAnswerModal,
    Break
  },
  beforeRouteUpdate (to, from, next) {
    if (to.fullPath !== from.fullPath) {
      Object.assign(this.$data, this.$options.data())
      this.taskInit(to.params.taskId)
    }
    next()
  },
  created () {
    this.taskInit(this.$route.params.taskId)
  },
  watch: {
    textArr () {
      this.$nextTick(() => {
        this.wrapSpace()
      })
    }
  },
  methods: {
    ...mapActions({
      getTask: 'task/getTask',
      missingTask: 'course/missingTask',
      getMissingTask: 'course/getMissingTask',
      missComposeTextTask: 'task/missComposeTextTask',
      setTaskAnswerModal: 'task/setTaskAnswerModal'
    }),
    correctAnswer () {
      this.task.task.words.map((item, index) => {
        const word = item.split(';')[0]
        this.$set(this.endTextArr, this.wordsIndex[index], word)
        this.$set(this.holdWords, this.wordsIndex[index], this.answerArr.findIndex((answer, index) => word === answer && !this.findHoldWords(index)))
        this.$set(this.inactiveAnswers, index, true)
      })
    },
    usedCheck (word, index) {
      return this.findHoldWords(index) && errors[this.findHoldWords(index)] === false
    },
    taskInit (taskId) {
      const query = {}
      if (this.$route.query.type) {
        query.type = this.$route.query.type
      }

      this.getTask({
        id: taskId,
        query
      }).then(res => {
        this.words = this.task.task.words
        this.words.map(item => {
          item.split(';').forEach(word => {
            this.answerArr.push(word)
          })
        })
        this.answerArr = this.shuffle(this.answerArr)
        this.textArr = this.task.task.missing_words_text.split('')
        this.endTextArr = []
        this.textArr = this.textArr.filter(item => item !== '')

        this.textArr.map((item, index) => {
          this.pushBreaks(item)
          if (item === '|') {
            this.endTextArr.push('')
            this.wordsIndex.push(index)
            this.success[index] = false
            this.errors[index] = false
          } else {
            this.endTextArr.push(item)
          }

        })
      })
    },
    addAnswer (answerIndex, wordIndex, isAnswer) {
      if (this.inactiveAnswers[answerIndex]) {
        return false
      }

      const oldWordIndex = this.holdWords[wordIndex]

      if (oldWordIndex !== undefined && oldWordIndex !== null) {
        this.$set(this.inactiveAnswers, oldWordIndex, false)
      }

      this.$set(this.endTextArr, wordIndex, this.answerArr[answerIndex])
      this.$set(this.holdWords, wordIndex, answerIndex)
      this.$set(this.inactiveAnswers, answerIndex, true)
      return true
    },
    added (index, event) {
      const element = event.detail.items[0]
      const answerIndex = element.getAttribute('data-index')
      this.moveWord(answerIndex, index, element.dataset.answer)
    },
    moveWord (answerIndex, index, isAnswer) {

      if (this.holdWords[answerIndex] !== undefined && !isAnswer) {

        const oldWord = this.endTextArr[answerIndex]
        this.$set(this.endTextArr, answerIndex, this.endTextArr[index])
        this.$set(this.endTextArr, index, oldWord)

        const oldHideWordIndex = this.holdWords[answerIndex]
        const oldHideWordIndexTwo = this.holdWords[index]

        if (oldHideWordIndex !== undefined && oldHideWordIndex !== null) {
          this.$set(this.holdWords, index, oldHideWordIndex)
        }

        if (oldHideWordIndex !== undefined && oldHideWordIndex !== null) {
          this.$set(this.holdWords, answerIndex, oldHideWordIndexTwo)
        }

      } else {
        this.addAnswer(answerIndex, index, isAnswer)
      }
    },
    findHoldWords (index) {
      for (const key in this.holdWords) {
        if (this.holdWords[key] == index) {
          return key
        }
      }
      return false
    },
    wrapSpace () {
      if (this.wrapped) {
        return
      }

      const elements = document.getElementsByClassName('char-parent')
      let arrays = []
      let stop = 0
      elements.forEach((item, index) => {
        if (item.classList.contains('space') || item.classList.contains('br')) {
          arrays.push([])
          stop++
        } else {
          arrays[stop - 1].push(item)
        }
      })
      arrays.forEach(arr => {
        if (arr.length === 0) {
          return
        }
        const wrapper = document.createElement('div')
        wrapper.classList.add('d-flex')
        this.wrap(arr, wrapper)
      })
      this.wrapped = true
    },
    wrap (el, wrapper) {
      el[0].parentNode.insertBefore(wrapper, el[0])
      el.forEach(item => {
        wrapper.appendChild(item)
      })
    },
    checkAnswers () {
      this.task.task.words.forEach((word, index) => {
        const key = this.wordsIndex[index]
        if (word.split(';').includes(this.endTextArr[key])) {
          this.$set(this.success, key, true)
          this.$set(this.errors, key, false)
        } else {
          this.$set(this.errors, key, true)
          this.$set(this.success, key, false)
        }
      })
    },
    missTask () {
      this.missingTask({ task_id: this.$route.params.taskId })
    },
    dragText (index, element, word) {
      if (this.isMobile) {
        this.pickWord(element, index)
        return
      }

      const isAnswer = this.activeElement?.dataset?.answer

      if (this.activeText !== '') {
        this.moveWord(this.activeText, index, isAnswer)
        this.activeText = ''
      } else if (this.activeBlock !== '' && this.activeBlock !== index) {
        this.moveWord(this.activeBlock, index, isAnswer)
        this.activeBlock = ''
      } else if (this.activeBlock === index) {
        this.activeBlock = ''
      } else {
        if (this.holdWords[index]) {
          this.activeBlock = index
        }
      }
      this.activeElement = null
    },
    pickWord (element, index) {
      this.activeElement = {
        element,
        index
      }

      this.showModal = true
    },
    pickWordModal (element, index) {
      const findIndex = this.findHoldWords(index)
      let isAnswer = true

      if (findIndex) {
        index = findIndex
        isAnswer = false
      }

      this.moveWord(index, this.activeElement.index, isAnswer)
      this.showModal = !this.showModal
    },
    checkComponetText () {
      const words = []
      this.wordsIndex.forEach(word => {
        words.push(this.endTextArr[word])
      })
      this.checkAnswers()
      this.missComposeTextTask({
        composeText: this.endTextArr,
        words,
        ...this.checkParams()
      }).then(res => {
        if (res.data && res.data.answer) {
          this.setTaskAnswerModal(res.data).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        } else {
          this.setTaskAnswerModal(false).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        }
      })
    },
    selectAnswer (index, element) {
      if (this.inactiveAnswers[index]) return false

      this.activeElement = element

      if (this.activeText === index) {
        this.activeText = ''
      } else {
        this.activeText = index
        this.activeBlock = ''
      }
    }
  },
}
</script>

<style scoped lang="scss">

.composer-answers {
  display: none !important;

  @media (min-width: 768px) {
    display: flex !important;
  }

}

.status-word-border {
  height: 20px;
  width: 20px;
  border: 2px solid #E7EAFD;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 30px;
  background-color: transparent !important;

  .status-word {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: transparent;
    display: block !important;
  }

  &.error-word {
    border: 2px solid #CD3B45;

    .status-word {
      background-color: #CD3B45;
    }
  }

  &.success-word {
    border: 2px solid #00a651;

    .status-word {
      background-color: #00a651;
    }
  }
}

.word-item {
  min-height: 60px;
  min-width: 240px;
  margin-right: 9px;
  border-radius: 10px;
  background-color: #e6eafc;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 35px;

  @media (max-width: 767px) {
    width: 100%;
    font-size: 12px;
  }
}

.used {
  background-color: transparent;
  border: 1px #b6bbcc solid;
  cursor: no-drop;
  color: transparent;
}

.text-block {
  min-width: 180px;
  min-height: 50px;
  background: transparent;
  cursor: pointer;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px dashed #b6bbcc;
  margin-bottom: 5px;

  span {
    visibility: hidden;
  }
}

.primary-word {
  background-color: #e6eafc;
  color: black;
}

.success-word {
  background-color: #00a651;
}

.error-word {
  background-color: #ff000b;
}

.success-word, .error-word, .primary-word {
  border: 1px solid transparent;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;

  span {
    display: none;
  }
}

.error-word, .success-word {
  color: white;
}

.fa-arrows-alt {
  color: #828282;
}

.missing-words {
  width: 100%;
  padding: 20px 23px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 55px;
  font-weight: 600;

  @media (max-width: 991px) {
    font-size: 20px;
  }

  @media (max-width: 767px) {
    font-weight: normal;
    font-size: 14px;
    line-height: 42px;
    padding: 0;
    .text-block {
      min-width: 150px;
    }
  }

  .char {
    min-width: 9px;
  }
}

.char-parent {
  margin-left: 0.03rem !important;
}

</style>
