<template>
  <TaskBase :task="task" :correct-answer="correctAnswer" :check-task="checkQuestion">
    <template #desktop>
      <div v-if="taskPresentation.content_output_method === 0">
        <div v-for="(task, index) in taskList" :key="index" class="mb-5">
          <TestTemplate
            :task="task"
            :answer="getTaskAnswer(task.id)"
            @selectAnswer="selectAnswer($event, task.id)"
          />
          <TaskButtons
            :showCheckButton="true"
            :buttonDisabled="buttonDisabled(task.id)"
            :hasAnswer="hasAnswer(task.id)"
            @chechButtonClick="checkAnswer(task.id)"
          />
        </div>
      </div>
      <div v-else-if="taskPresentation.content_output_method === 1">
        <VueSlickCarousel
          v-if="taskList.length"
          v-bind="settings"
          @beforeChange="beforeChange"
          ref="carouselTask"
          class="task-choose-translation-cards"
        >
          <div v-for="(num, outterIndex) in sliderPageCount" :key="outterIndex">
            <div v-for="(task, index) in getItemsSlide(outterIndex)" :key="index" class="mb-5">
              <TestTemplate
                :task="task"
                :answer="getTaskAnswer(task.id)"
                @selectAnswer="selectAnswer($event, task.id)"
              />
              <TaskButtons
                :showCheckButton="true"
                :buttonDisabled="buttonDisabled(task.id)"
                :hasAnswer="hasAnswer(task.id)"
                @chechButtonClick="checkAnswer(task.id)"
              />
            </div>
            <TaskButtons
              class="d-flex flex-row justify-content-end"
              :showSkipButton="sliderPageCount > 1"
              :hasAnswer="calculateCarouselListAnswers[outterIndex].answered"
              @skipButtonClick="changePage"
            />
          </div>
        </VueSlickCarousel>
        <DotsTemplate
          :pageCount="sliderPageCount"
          :currentIndex="carouselIndex"
          :answers="calculateCarouselListAnswers"
          @changePage="changePage($event)"
        />
      </div>
      <div v-else-if="taskPresentation.content_output_method === 2">
        <VueSlickCarousel
          v-if="taskList.length"
          v-bind="settings"
          @beforeChange="beforeChange"
          ref="carouselTask"
          class="task-choose-translation-cards"
        >
          <div
            v-for="(task, index) in taskList"
            :key="index"
            class="d-flex flex-column align-items-center"
          >
            <TestTemplate
              :task="task"
              :answer="getTaskAnswer(task.id)"
              :centerItem="true"
              @selectAnswer="selectAnswer($event, task.id)"
            />
            <TaskButtons
              :showSkipButton="true"
              :showCheckButton="true"
              :buttonDisabled="buttonDisabled(task.id)"
              :hasAnswer="hasAnswer(task.id)"
              @chechButtonClick="checkAnswer(task.id)"
              @skipButtonClick="changePage"
            />
          </div>
        </VueSlickCarousel>
        <DotsTemplate
          :pageCount="taskList.length"
          :currentIndex="carouselIndex"
          :answers="answers"
          @changePage="changePage($event)"
        />
      </div>
    </template>
  </TaskBase>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapActions, mapMutations, mapState } from 'vuex'
import TaskBase from '../../../../components/TaskBase'
import TaskCheckParamsMixin from '../../../../../mixins/TaskCheckParamsMixin'
import TaskButtons from '../../../../components/Tasks/TaskButtons'
import TestTemplate from './TestTemplate.vue'
import DotsTemplate from './DotsTemplate.vue'

export default {
  name: 'Test',
  mixins: [TaskCheckParamsMixin],
  components: {
    TaskBase,
    VueSlickCarousel,
    TaskButtons,
    TestTemplate,
    DotsTemplate
  },
  data() {
    return {
      carouselIndex: 0,
      answers: [],
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: false
      }
    }
  },
  computed: {
    ...mapState({
      task: state => state.task.task,
      taskList: state => state.task.task?.task || [],
      nextTask: state => state.course.nextTask,
      taskPresentation: state => state.task.taskPresentation
    }),
    lastQuestion() {
      return this.answers.filter(answer => !answer.answered).length === 0
    },
    sliderPageCount() {
      return Math.ceil(this.taskList.length / this.taskPresentation.content_output_count)
    },
    calculateCarouselListAnswers() {
      const res = []
      if (this.answers?.length === 0) {
        return
      }
      for (let i = 0; i < this.sliderPageCount; i++) {
        const contentOutputCount = this.taskPresentation.content_output_count
        const start = i * contentOutputCount
        const end = start + contentOutputCount
        const sliderAnswers = this.answers.slice(start, end)

        const allAnswered = !sliderAnswers.find(el => !el.answer_id) // find one false
        const hasFalseSuccess = allAnswered ? !sliderAnswers.find(el => !el.success) : false // find one false
        const hasError = sliderAnswers.find(el => el.error) // find one true

        res.push({ answered: allAnswered, success: hasFalseSuccess, error: hasError })
      }
      return res
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath !== from.fullPath) {
      Object.assign(this.$data, this.$options.data())
      this.getTask({ id: to.params.taskId }).then(res => {
        this.answersInit()
      })
    }
    next()
  },
  created() {
    this.loadData().then(res => {
      this.data = res.data
      if (this.taskList) {
        this.answersInit()
      }
    })
  },
  methods: {
    ...mapActions({
      getTask: 'task/getTask',
      missingTask: 'course/missingTask',
      getMissingTask: 'course/getMissingTask',
      getBoughtCourse: 'course/getBoughtCourse',
      checkTestTask: 'task/checkTestTask',
      missTestTask: 'task/missTestTask',
      setTaskAnswerModal: 'task/setTaskAnswerModal'
    }),
    ...mapMutations({
      setTry: 'task/setTry'
    }),
    loadData() {
      const query = {}
      if (this.$route.query.type) {
        query.type = this.$route.query.type
      }
      return this.getTask({
        id: this.$route.params.taskId,
        query
      })
    },
    beforeChange(prevIndex, newIndex) {
      this.carouselIndex = newIndex
    },
    changePage(index) {
      if (typeof index === 'number') {
        this.$refs.carouselTask.goTo(index)
      } else {
        this.$refs.carouselTask.next()
      }
    },
    getItemsSlide(slideIndex) {
      const startIndex = slideIndex * this.taskPresentation.content_output_count
      const endIndex = startIndex + this.taskPresentation.content_output_count
      return this.taskList.slice(startIndex, endIndex)
    },
    buttonDisabled(questionId) {
      const answer = this.answers.find(el => el.question_id === questionId)
      return answer?.answer_id ? answer.success : true
    },
    getTaskAnswer(questionId) {
      return this.answers.find(el => el.question_id === questionId)
    },
    hasAnswer(questionId) {
      const answer = this.getTaskAnswer(questionId)
      return !!answer?.answer_id
    },
    answersInit() {
      this.taskList.forEach(question => {
        this.answers.push({
          question_id: question.id,
          answer_id: null,
          answered: false,
          success: false,
          error: false,
          try: 1
        })
      })
    },
    selectAnswer(answerId, questionId) {
      const currentAnswer = this.getTaskAnswer(questionId)
      if (currentAnswer.success) {
        return
      }
      this.$set(currentAnswer, 'answer_id', answerId)
      this.$set(currentAnswer, 'error', false)
      this.$set(currentAnswer, 'answered', true)
    },
    missTask() {
      this.missingTask({ task_id: this.$route.params.taskId })
    },
    correctAnswer() {
      //   const answer = this.answers[this.carouselIndex]
      //   if (answer.try < 3) {
      //     return
      //   }
      //   const correctAnswer = this.taskList[this.carouselIndex].answers.find(item => item.correct_answer === true)
      // this.$set(this.answers, this.carouselIndex, { ...answer, success: true, error: false, answered: true, answer_id: correctAnswer.id })
      //   this.clickedPrompt = true
    },
    checkQuestion() {
      this.checkTestTask({
        answers: this.answers,
        finish: this.lastQuestion,
        clickedPrompt: this.clickedPrompt,
        type_model: 'TaskQuestion',
        uuid: this.task.uuid,
        check_all: 1,
        ...this.checkParams()
      }).then(res => {
        if (res.data && res.data.answer) {
          this.answers.forEach((answer, index) => {
            this.$set(this.answers, index, { ...answer, success: true, error: false })
          })

          this.setTaskAnswerModal(res.data).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
          this.loadData()
        } else {
          const errors = res.data.errors
          for (const key in errors) {
            this.$set(this.answers, key, { ...this.answers[key], error: errors[key] })
          }
          const success = res.data.success
          for (const key in success) {
            this.$set(this.answers, key, { ...this.answers[key], success: success[key] })
          }
          this.setTaskAnswerModal(false).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        }
      })
    },
    checkAnswer(questionId) {
      const isLastWrongAnswer = this.answers.filter(answer => !answer.success).length === 1
      if (isLastWrongAnswer) {
        this.checkQuestion()
        return
      }
      const currentAnswerIndex = this.answers.findIndex(el => el.question_id === questionId)
      const currentAnswer = this.answers[currentAnswerIndex]

      this.checkTestTask({
        task_id: this.taskId,
        question_id: currentAnswer.question_id,
        answer_id: currentAnswer.answer_id,
        finish: this.lastQuestion,
        type_model: 'TaskQuestion',
        uuid: this.task.uuid,
        ...this.checkParams()
      }).then(res => {
        const answer = {
          answered: true,
          selected: false
        }
        if (res.data.answer) {
          answer.success = true
          answer.error = false
        } else {
          answer.error = true
          answer.success = false
          answer.try = res.data.try
          this.setTry(res.data.try)
        }
        this.$set(this.answers, currentAnswerIndex, { ...currentAnswer, ...answer })
        if (this.taskPresentation.content_output_method === 2) {
          if (res.data.answer) {
            if (this.carouselIndex < this.taskList.length) {
              setTimeout(() => this.$refs.carouselTask.next(), 500)
              return
            }

            const unanswered = this.answers.findIndex(answer => !answer.answered || answer.error)
            if (unanswered !== -1) {
              this.$refs.carouselTask.goTo(unanswered)
            }
          }
        }
      })
    }
  }
}
</script>
