<template>
  <div
    v-if="notification.data.data.type === 'invite_chat' || notification.data.data.type == 'start_video_call'"
    class="support-content chat-content">
    <span class="date">{{ notification.created_at | moment('DD.MM.YYYY | hh:mm') }}</span>
    <div class="info">Пользователь
      <a href="#" @click.prevent="">{{ notification.data.data.user.login }}</a>
      {{ notification.data.data.message }}
    </div>
    <div class="actions">
      <button :disabled="acceptLoader" type="button"
              @click.prevent="acceptChatInvitation()">
        ПРИСОЕДЕНИТЬСЯ
      </button>
      <button :disabled="acceptLoader" type="button"
              @click.prevent="declineChatInvitation()">
        ОТКАЗАТЬСЯ
      </button>
    </div>
  </div>
  <div v-else class="notification-content">
    <div class="right-side">
      <span class="date">{{ notification.created_at | moment('DD.MM.YYYY') }}</span>
      <div class="content">
        <template v-if="notification.data.data.type === 'accepted_invite'">
          {{ notification.data.data.message }}
        </template>
        <template v-else>
          {{ $t('notifications.messages.' + notification.data.data.type) }}
          "{{
            notification.data.data.type === 'birthday' ? '' : notification.data.data.course.name
          }}"
        </template>
      </div>
    </div>
    <div class="right-side">
      <label class="checkbox-container cursor-pointer mr-0">
        <input v-model="notification.read_at" type="checkbox" id="info-tooltip"/>
        <span class="checkbox"/>
      </label>
      <b-tooltip target="info-tooltip" no-fade custom-class="info-tooltip" triggers="hover"
                 placement="left">
        Отметить как {{ notification.read_at ? 'непрочитанное' : 'прочитанное' }}
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import VideoCallMixin from '../../mixins/VideoCallMixin'

export default {
  name: 'NotificationItem',
  mixins: [VideoCallMixin],
  props: {
    notification: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      acceptLoader: false
    }
  },
  computed: {
    ...mapState({
      notifications: state => state.user.notifications,
      chatList: state => state.courseChat.chatList,
      peer: state => state.courseChat.peer
    })
  },
  methods: {
    ...mapActions({
      acceptInvitation: 'courseChat/acceptInvitation',
      declineInvitation: 'courseChat/declineInvitation',
    }),
    ...mapMutations({
      setVideoChat: 'courseChat/setVideoChat',
      setVideoChatKey: 'courseChat/setVideoChatKey',
      setVideoChatUsers: 'courseChat/setVideoChatUsers',
    }),
    acceptChatInvitation () {
      const {
        type,
        peer_id,
        user,
        chat,
        video_chat_key,
      } = this.notification.data.data

      this.setVideoChatKey(video_chat_key)

      this.acceptLoader = true

      const params = {
        notify_id: this.notification.id,
        type,
        peer_id: this.peer.id,
        video_chat_key
      }

      this.acceptInvitation({
        id: chat.id,
        params
      }).then((res) => {
        this.notifications.splice(this.index, 1)
        this.$toasted.success('You have accepted this invitation!').goAway(1500)
        if (type === 'start_video_call') {
          this.closeCall()

          this.setVideoChatUsers(res.data.users_video_chat)

          if (res.data.users_video_chat.length === 0) {
            return false
          }

          this.setVideoChat({
            peer_id,
            course_chat_id: chat.id,
            user
          })
          if (this.$route.name !== 'chat') {
            this.$router.push(`/profile/chat?open_chat=${chat.id}`)
          }
        } else {
          this.chatList.push(res.data.chat)
        }
      }).catch(() => {
        this.$toasted.error('Something went wrong!').goAway(1500)
      }).finally(() => {
        this.acceptLoader = false
      })
    },
    declineChatInvitation () {
      this.acceptLoader = true
      this.declineInvitation({
        id: this.notification.data.data.chat.id,
        params: { notify_id: this.notification.id }
      }).then(() => {
        this.notifications.splice(this.index, 1)
        this.$toasted.success('You have declined this invitation!').goAway(1500)
      }).catch(() => {
        this.$toasted.error('Something went wrong!').goAway(1500)
      }).finally(() => {
        this.acceptLoader = false
      })
    },
  }
}
</script>

<style scoped>

</style>
