<template>
  <div>
    <!-- When there is no notofication   -->
    <div class="support personal-details" v-if="supportTicketList && !supportTicketList.length">
      <div class="tabs-title">
        Техническая поддержка
      </div>
      <div class="tabs-info">
        У вас еще не было обращений в техническую поддержку
      </div>
      <router-link to="/profile/technical-support/new-template" class="new-template">СОЗДАТЬ НОВОЕ ОБРАЩЕНИЕ</router-link>
      <div class="support-content">
        <img src="@/assets/images/profile/technical-support.svg" class="support-content-no-ticket" alt="">
      </div>
    </div>
    <div class="support personal-details" v-else>
      <div class="d-flex align-items-center justify-content-between">
        <div class="text-part">
          <div class="tabs-title">
            Техническая поддержка
          </div>
          <div class="tabs-info">
            Если у вас возникли вопросы или проблемы обратитесь в нашу тех. поддержку
          </div>
        </div>
        <router-link to="/profile/technical-support/new-template" class="new-template mt-0">СОЗДАТЬ НОВОЕ ОБРАЩЕНИЕ</router-link>
      </div>
      <div class="support-content">
        <div class="item new" v-for="(ticket, index) in supportTicketList" v-bind:key="index">
          <div class="top">
            <div class="left">
              <div class="number" v-if="ticket.unread_messages">{{ticket.unread_messages}}</div>
              <span>Тема: {{ticket.support_ticket_category.name[$i18n.locale]}}</span>
            </div>
            <div class="right">
              <span class="date">{{ticket.created_at  | moment("DD.MM.YYYY")}}</span>
            </div>
          </div>
          <div class="body">{{ticket.support_ticket_messages[ticket.support_ticket_messages.length - 1].message}}</div>
          <div class="bottom">
            <router-link :to="'/profile/technical-support/template/' + ticket.id">
              <span>ПЕРЕЙТИ К ПЕРЕПИСКЕ</span>
              <div class="image"></div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="mt-3" v-if="supportListPagination.count_pages > 1">
        <b-pagination
          v-model="currentPage"
          :total-rows="supportListPagination.count_pages"
          :per-page="1"
          first-number
          last-number
          prev-text="«"
          next-text="»"
          @input="clickPagination"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Support',
  data () {
    return {
      currentPage: 1
    }
  },
  computed: {
    ...mapState({
      supportTicketList: state => state.supportTicket.supportTicketList,
      supportListPagination: state => state.supportTicket.supportListPagination
    })
  },
  created () {
    this.getSupport()
  },
  methods: {
    ...mapActions({
      getSupportList: 'supportTicket/getSupportList'
    }),
    getSupport () {
      this.getSupportList({ page: this.currentPage }).catch(() => {
        this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
      })
    },
    clickPagination () {
      this.getSupport().then(() => {
        window.scrollTo(0, 0)
      })
    }
  }
}
</script>
