<template>
  <div class="personal-details free-lesson">
    <router-link tag="div" :to="{name: 'ProfileCourseLessons', params: {id: $route.params.course_id}}" class="back-to">
      <img src="~@/assets/images/profile/back-icon.svg" alt="">
      <span>НАЗАД КО ВСЕМ УРОКАМ</span>
    </router-link>
    <div class="free-lesson-title">
      <img v-if="lesson.course.teaching_language.flag_path" :src="lesson.course.teaching_language.flag_path" alt="flag">
      <div class="text">
        Урок {{$route.query.number}}
        <span>{{lesson.name[$i18n.locale]}}</span>
      </div>
    </div>
    <div class="free-lesson-content">
      <div class="video">
        <div v-if="lesson.video_iframe">
          <div v-if="lesson && lesson.video_iframe !== null" v-html="lesson.video_iframe" class="video-iframe"></div>
        </div>
        <div v-if="lesson.video">
          <video
            width="100%"
            :src="lesson.video"
            controls
          >
          </video>
        </div>
        <div class="actions">
          <GoToTasks :lesson="lesson" />
        </div>
      </div>
      <div class="description">
        <div class="content" v-html="lesson.description[$i18n.locale]">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import GoToTasks from '../../../components/Lessons/GoToTasks'

export default {
  name: 'CourseLessons',
  components: { GoToTasks },
  computed: {
    ...mapState({
      lesson: state => state.course.lesson
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.getLesson({ id: to.params.id }).then(res => {})
    next()
  },
  created () {
    this.getLesson({ id: this.$route.params.id }).then(res => {})
  },
  methods: {
    ...mapActions({
      getLesson: 'course/getLesson'
    })
  }
}
</script>
