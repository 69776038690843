<template>
  <TaskBase :task="task" :check-task="missAlignWords" :correct-answer="correctAnswer">
    <template #desktop>
      <div class="align-words-container">
        <WordModal
          :words="currentWords"
          :is-zebra="type === 'text'"
          :list-style="type === 'text' ? {height: '100%', paddingTop: '20px', paddingBottom: 0}: {}"
          :show.sync="showAnswers"
          :positions="positions"
        >
          <template #list="{word,index}">
            <div
              class="d-flex justify-content-between w-100 align-items-center mobile-words"
              @click="wordSwap(((page - 1) * 4) + index, word)"
            >
              <div>
                <StatusBox
                  :success="word.success !== false && (word.success || positions[word.id] && positions[word.id].draggedName === 'answers')"
                  :error="word.success === false"
                />
              </div>

              <AlignWordItem
                v-if="type !== 'text'"
                :type="type"
                :phrase="word"
                :is-main="true"
                :key="index"
                @playVideo="play"
                :data-index="i"
                :class="{'answers': type !=='text'}"
              />
              <div v-else class="ml-2 d-flex justify-content-center w-100">
                {{word.value}}
              </div>
            </div>
          </template>
          <template #afterList>
            <div class="d-flex justify-content-center slide-navs" v-if="pages > 1">
              <span class="slide-nav" v-for="n in pages" :key="n" @click="() => page = n">
                <span :class="{'active': n === page}">

                </span>
              </span>
            </div>
          </template>
          <Words :words="mobileAnswers" title="Доступные переводы">
            <template #list="{word,index}">
              <WordStatus
                :word="type === 'text' ? word.value : types[word.type]"
                :used="positions[word.id] && positions[word.id].draggedName === 'answers'"
                :number="index"
                :length="mobileAnswers.length"
              />
            </template>
          </Words>
          <div  class="align-words d-flex flex-column flex-md-row align-items-center align-items-md-start"
                :class="{'only-text': type === 'text'}">
            <div class="words-row">
              <template v-for="(phrase,i) in phrases">
                <AlignWordItem
                  :type="type"
                  ref="words"
                  :phrase="phrase"
                  :is-main="true"
                  :key="i"
                  @playVideo="play"
                  :height="heights[i]"
                  :data-index="i"
                />
                <div class="mobile-word-item wordable" @click="pickWord(i, 'answers', answers[i])">
                  <AlignWordItem
                    class="mobile-answer"
                    :ref="answers"
                    :type="type"
                    :phrase="answers[i]"
                    :is-answer="true"
                    :data-index="i"
                    :key="i"
                    :success="phrase.success"
                    :invalid="phrase.invalid"
                    :height="heights[i]"
                  />
                  <MobileIcon/>
                </div>
              </template>
            </div>
            <div class="words-row only-pc">
              <draggable
                class="plan-content"
                v-bind="dragOptions"
                group="answers"
                :move="handleMove"
                @end="handleDragEnd"
                v-model="answers"
              >
                <transition-group
                  type="transition"
                  tag="div"
                  :name="'flip-list'"
                >
                  <AlignWordItem
                    data-name="answers"
                    :type="type"
                    ref="answers"
                    v-for="(phrase,i) in answers"
                    :is-answer="true"
                    :phrase="phrase"
                    :data-index="i"
                    :key="i"
                    :success="phrase.success"
                    :invalid="phrase.invalid"
                    :height="heights[i]"
                    @playVideo="play"
                  />
                </transition-group>
              </draggable>

            </div>
            <div class="words-row only-pc">
              <draggable
                class="plan-content"
                v-model="translations"
                v-bind="dragOptions"
                @mousemove.native="log"
                group="answers"
                :move="handleMove"
                @end="handleDragEnd"
              >
                <transition-group
                  type="transition"
                  tag="div"
                  :name="'flip-list'"
                >
                  <AlignWordItem
                    v-for="(phrase,i) in translations"
                    :is-answer="true"
                    data-name="translations"
                    :data-index="i"
                    ref="translations"
                    :type="type"
                    :i="i"
                    :phrase="phrase"
                    :is-translate="true"
                    :key="i"
                    @playVideo="play"
                    :height="heights[i]"
                  />
                </transition-group>
              </draggable>
            </div>
          </div>
          <VideoModal
            :show.sync="showModal"
            :video="activePhrase.value"
            :type="activePhrase.type"
          />
        </WordModal>
      </div>

    </template>
  </TaskBase>
</template>
<script>
import draggable from 'vuedraggable'
import TaskAnswerModal from '../../../../components/TaskAnswerModal'
import { mapActions, mapGetters, mapState } from 'vuex'
import VideoAudioGallery from '../../../../components/VideoAudioGallery'
import TaskBase from '../../../../components/TaskBase'
import TaskCheckParamsMixin from '../../../../../mixins/TaskCheckParamsMixin'
import AlignWordItem from '../../../../components/Tasks/AlignWords/AlignWordItem'
import ScrollMixins from '../../../../../mixins/ScrollMixins'
import VideoModal from '../../../../components/Videos/VideoModal'
import MobileIcon from '../../../../components/Tasks/MobileIcon'
import Words from '../../../../components/Tasks/Words'
import WordModal from '../../../../components/Tasks/WordModal'
import Template from '../../TechnicalSupport/Template'
import StatusBox from '../../../../components/Tasks/Common/StatusBox'
import CheckWidthMixin from '../../../../../mixins/CheckWidthMixin'
import WordStatus from '../../../../components/Tasks/Common/WordStatus'

export default {
  name: 'AlignWords',
  mixins: [TaskCheckParamsMixin, ScrollMixins, CheckWidthMixin],
  data () {
    return {
      data: null,
      openPrompt: false,
      dragOptions: {
        animation: 200,
        disabled: false,
      },
      words: [],
      phrases: [],
      translations: [],
      answers: [],
      heights: [],
      draggedContext: null,
      relatedContext: null,
      dragged: null,
      related: null,
      showModal: false,
      activePhrase: {},
      mobileAnswers: [],
      showAnswers: false,
      positions: {},
      activeItem: {},
      page: 1,
      types: {
        'image': 'Изображение',
        'text': 'Слово',
        'video': 'Видео',
        'iframe': 'Видео',
        'audio': 'Аудио'
      },
      type: null
    }
  },
  components: {
    WordStatus,
    StatusBox,
    Template,
    WordModal,
    Words,
    MobileIcon,
    VideoModal,
    AlignWordItem,
    VideoAudioGallery,
    TaskAnswerModal,
    draggable,
    TaskBase
  },
  computed: {
    ...mapState({
      task: state => state.task.task,
      nextTask: state => state.course.nextTask,
    }),
    ...mapGetters({
      storeLoading: 'task/taskLoading'
    }),
    pages () {
      return Math.ceil((this.mobileAnswers.length / 4))
    },
    currentWords () {
      return this.mobileAnswers.filter((answer, index) => (index + 1) > ((this.page - 1) * 4) && (index + 1) <= (this.page * 4))
    },
  },
  beforeRouteUpdate (to, from, next) {
    if (to.fullPath !== from.fullPath) {
      Object.assign(this.$data, this.$options.data())
      this.taskInit(to)
    }
    next()
  },
  created () {
    this.taskInit(this.$route)
  },
  mounted () {
    this.$nextTick(() => {
      this.heightFixed()
    })
  },
  watch: {
    storeLoading () {
      this.$nextTick(() => {
        this.heightFixed()
      })
    },
    answers () {
      this.$nextTick(() => {
        this.heightFixed()
      })
    }
  },
  methods: {
    ...mapActions({
      getTask: 'task/getTask',
      missingTask: 'course/missingTask',
      getMissingTask: 'course/getMissingTask',
      missAlignWordsTask: 'task/missAlignWordsTask',
      setTaskAnswerModal: 'task/setTaskAnswerModal'
    }),
    log(e){

    },
    setType(){
      const phrase = this.phrases.find(item => item.type !== 'text')
      const translation = this.translations.find(item => item.type !== 'text')

      if (phrase || translation) {
        this.type = 'image'
        return
      }

      this.type = 'text'
    },
    taskInit (route) {
      const query = {}
      if (route.query.type) {
        query.type = route.query.type
      }
      this.answers = []
      this.getTask({
        id: route.params.taskId,
        query
      }).then(res => {
        this.data = res.data
        this.words = this.task.task.task_phrases.map(phrase => {
          this.answers.push({
            value: '',
            type: 'empty',
            default: '',
          })
          return phrase.value
        })
        this.phrases = this.task.task.task_phrases
        this.translations = this.task.task.task_translations

        this.mobileAnswers = this.translations.map(item => {
          return {
            ...item,
            success: null
          }
        })
        this.setType()
      })
    },
    checkStatus (id, key) {
      const answer = this.answers.find(answer => {
        return answer.id && answer.id === id
      })
      return answer && answer[key]
    },
    pickWord (index, draggedName, word) {
      if (!this.isMobile) return

      this.activeItem = {
        index,
        draggedName,
        id: word.id || null
      }

      this.showAnswers = true
    },
    wordSwap (relatedIndex, word) {
      let relatedName = 'translations'

      const {
        index: draggedIndex,
        draggedName,
        id,
      } = this.activeItem

      if (this.positions[word.id]) {
        relatedIndex = this.positions[word.id].index
        relatedName = this.positions[word.id].draggedName

        if (id === word.id) return
      }

      if (id) {
        this.$set(this.positions, id, {
          draggedName: relatedName,
          index: relatedIndex
        })
      }

      this.$set(this.positions, word.id, {
        draggedName,
        index: draggedIndex
      })

      this.swapItem(relatedName, draggedName, draggedIndex, relatedIndex)
    },
    play (phrase) {
      this.activePhrase = phrase
      this.showModal = true
    },
    handleDragEnd () {
      const futureIndex = this.related.dataset.index
      this.swapItem(this.related.dataset.name, this.dragged.dataset.name, this.draggedContext.index, futureIndex)
    },
    swapItem (relatedName, draggedName, draggedIndex, futureIndex) {
      const relatedObject = this[relatedName]
      const draggedObject = this[draggedName]

      const tempData = relatedObject[futureIndex]

      this.$set(relatedObject, futureIndex, draggedObject[draggedIndex])
      this.$set(draggedObject, draggedIndex, tempData)

    },
    handleMove (e) {
      this.dragged = e.dragged
      this.related = e.related
      this.draggedContext = e.draggedContext
      this.relatedContext = e.relatedContext
      return false
    },
    heightFixed () {
      if (this.type !== 'text') return
      const {
        answers,
        words,
        translations
      } = this.$refs

      if (!answers) {
        return
      }

      words.forEach((word, index) => {
        const wordHeight = word.$refs.content.getBoundingClientRect().height
        const answerHeight = answers[index].$refs.content.getBoundingClientRect().height
        const transHeight = translations[index].$refs.content.getBoundingClientRect().height

        const max = Math.max(transHeight, answerHeight, wordHeight)

        this.$set(this.heights, index, max)
      })
    },
    correctAnswer () {
      const data = this.task.task.translations_data
      const answers = []
      const phrases = []
      const translations = []
      data.forEach((value, index) => {
        translations.push({
          type: 'empty',
          value: '',
          default: '',
        })

        answers.push({
          value: value.translation,
          type: value.translation_type,
          default: this.mobileAnswers.find(answer => answer.key === value.key).default,
          success: true,
          id: value.id,
          key: value.key
        })

        this.$set(this.positions, value.id, {
          index,
          draggedName: 'answers'
        })

        this.$set(this.mobileAnswers, index, {
          ...this.mobileAnswers[index],
          success: true
        })

        phrases.push({
          value: value.phrase,
          type: value.phrase_type,
          default: this.phrases.find(answer => answer.key === value.key).default,
          key: value.key
        })
      })

      this.translations = translations
      this.phrases = phrases
      this.answers = answers
    },
    missTask () {
      this.missingTask({ task_id: this.$route.params.taskId })
    },
    missAlignWords () {
      this.missAlignWordsTask({
        word: this.phrases.map(phrase => phrase.key),
        translations: this.answers.map(phrase => phrase.key),
        ...this.checkParams()
      }).then(res => {
        if (res.data && res.data.answer) {
          this.answers = this.answers.map(answer => {
            return {
              ...answer,
              success: true
            }
          })
          this.mobileAnswers = this.mobileAnswers.map(answer => {
            return {
              ...answer,
              success: true
            }
          })
          this.setTaskAnswerModal(res.data).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        } else {
          const {
            success,
            invalids,
            results
          } = res.data

          success.forEach(value => {
            this.$set(this.answers, value, {
              ...this.answers[value],
              success: true,
              invalid: false
            })
          })

          invalids.forEach(value => {
            this.$set(this.answers, value, {
              ...this.answers[value],
              invalid: true,
              success: false
            })
          })

          this.mobileAnswers = this.mobileAnswers.map(answer => {
            if (results.includes(answer.id)) {
              return {
                ...answer,
                success: true
              }
            }
            return {
              ...answer,
              success: false
            }
          })

          this.setTaskAnswerModal(false).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../../scss/variables";

.align-words-container{

}
.card-translation {
  height: 169.31px !important;
  display: flex;
  align-items: center;
  padding: 0 !important;
  background: transparent !important;
}

.answers {
  width: 200px;
  height: 120px;
}

.mobile-word-item {
  width: 280px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $md) {
    display: none;
  }
}

.mobile-answer {
  width: 200px;
  height: 120px;
}

.only-text{
  .mobile-answer{
    width: 220px;
    height: auto;
  }
}

.words-row {
  @media (min-width: $md) {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.word-image {
  transform: none !important;
}

.mobile-words {
  padding: 0 0 0 15px
}

.slide-navs {
  margin-top: 30px;
  margin-bottom: 82px;

  .slide-nav {
    border-radius: 50%;
    border: 2px solid #E7EAFD;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      margin-right: 10px;
    }

    .active {
      border-radius: 50%;
      background: #E7EAFD;
      height: 12px;
      width: 12px;
    }
  }
}


</style>
