<template>
  <div v-if="data.length">
    <VueSlickCarousel v-bind="settings" v-if="data.length > 4">
      <template v-for="image in data" >
        <GalleryItem
          v-if="image[keyValue]"
          :image="image[value]"
          :key="image[keyValue]"
        />
      </template>
    </VueSlickCarousel>
    <div v-else class="row">
      <template v-for="image in data" >
        <GalleryItem
          v-if="image[keyValue]"
          :image="image[value]"
          :key="image[keyValue]"
          class="col-md-3"
        />
      </template>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import GalleryItem from './GalleryItem'

export default {
  components: { GalleryItem, VueSlickCarousel },
  props: {
    data: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      default: 'image'
    },
    keyValue: {
      type: String,
      default: 'id'
    }
  },
  data () {
    return {
      settings: {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1440,
            settings: {
              arrows: false,
              dots: true,
              infinite: true,
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 360,
            settings: {
              arrows: false,
              dots: true,
              infinite: true,
              slidesToShow: 2,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>
