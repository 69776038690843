<template>
  <div class="col-12 home">
    <Description @openVideoModal="openVideoModal" />

    <CourseList />

    <div class="home-delusions">
      <div class="page-container">
        <div class="how-work-container-title">
          Хватит наступать на те же грабли!
        </div>
        <div class="home-delusions__text">
          Если вы хоть раз задумывались над переездом за рубеж, получением престижной должности,
          поиском иностранных партнеров для бизнеса или семейной жизни, то понимаете, что первый шаг
          - это овладение иностранным языком.
        </div>
        <div class="how-work-container-subtitle blue-content">
          <div class="blue">
            Вероятно, вы хотели или даже начинали изучение языка, но каждый раз сталкивались с одним
            и тем же:
          </div>
        </div>
        <div class="home-delusions__items">
          <div class="home-delusions__item">
            <div class="home-delusions__item-top">
              <img
                class="home-delusions__item-img"
                src="@/assets/images/delusions-img-1.png"
                alt="img"
              />
              <div class="home-delusions__item-title">
                Обучение по расписанию
              </div>
            </div>
            <div class="home-delusions__item-text">
              Занятия, сдача и проверка домашних заданий проходят по жесткому графику.
            </div>
          </div>
          <div class="home-delusions__item">
            <div class="home-delusions__item-top">
              <img
                class="home-delusions__item-img"
                src="@/assets/images/delusions-img-2.png"
                alt="img"
              />
              <div class="home-delusions__item-title">Устаревшая методика</div>
            </div>
            <div class="home-delusions__item-text">
              Многократное повторение и однотипные упражнения затрудняют усвоение материала.
            </div>
          </div>
          <div class="home-delusions__item">
            <div class="home-delusions__item-top">
              <img
                class="home-delusions__item-img"
                src="@/assets/images/delusions-img-3.png"
                alt="img"
              />
              <div class="home-delusions__item-title">Групповые занятия</div>
            </div>
            <div class="home-delusions__item-text">
              Вы тратите время и деньги на ожидания и теряете фокусировку и интерес к обучению.
            </div>
          </div>
        </div>
        <p class="home-delusions__bottom-text">
          В результате длительность обучения затягивается, увеличиваются расходы, а энтузиазм и
          желания постепенно сходят на нет.<b>
            И ваша мечта снова откладывается на неопределенное время. Но всё можем быть иначе …</b
          >
        </p>
      </div>
    </div>

    <Presentation />

    <div class="row">
      <div class="page-container">
        <div class="destiny">
          <div class="benefits-container-title">Для кого мы создали L2G?</div>
          <div class="how-work-container-subtitle blue-content">
            <div class="blue">
              L2G подойдет всем, кто хочет за короткий срок качественно изменить свою жизнь, освоив
              иностранный язык
            </div>
          </div>
          <div class="destiny__items">
            <div class="destiny__item">
              <img class="destiny__item-img" src="@/assets/images/destiny-item-1.png" alt="img" />
              <div class="destiny__item-title">Школьники</div>
              <div class="destiny__item-text">
                Сдать выпускные экзамены или поступить в отечественный или зарубежный ВУЗ
              </div>
            </div>
            <div class="destiny__item">
              <img class="destiny__item-img" src="@/assets/images/destiny-item-2.png" alt="img" />
              <div class="destiny__item-title">Студенты</div>
              <div class="destiny__item-text">
                Переехать на обучение в другую страну или найти высокооплачиваемую работу
              </div>
            </div>
            <div class="destiny__item">
              <img class="destiny__item-img" src="@/assets/images/destiny-item-3.png" alt="img" />
              <div class="destiny__item-title">Эмигранты</div>
              <div class="destiny__item-text">
                Адаптироваться в новой стране, создать конкурентное преимущество и трудоустроиться
              </div>
            </div>
            <div class="destiny__item">
              <img class="destiny__item-img" src="@/assets/images/destiny-item-4.png" alt="img" />
              <div class="destiny__item-title">Невесты</div>
              <div class="destiny__item-text">
                Создать счастливую семью с иностранцем и интегрироваться в новое общество
              </div>
            </div>
            <div class="destiny__item">
              <img class="destiny__item-img" src="@/assets/images/destiny-item-5.png" alt="img" />
              <div class="destiny__item-title">Бизнесмены</div>
              <div class="destiny__item-text">
                Завести полезные знакомства среди иностранцев и масштабировать бизнес
              </div>
            </div>
            <div class="destiny__item">
              <img class="destiny__item-img" src="@/assets/images/destiny-item-6.png" alt="img" />
              <div class="destiny__item-title">Путешественники</div>
              <div class="destiny__item-text">
                Свободно общаться и находить новых друзей, решать бытовые вопросы и спорные ситуации
              </div>
            </div>
          </div>
        </div>

        <Benefits />
        <ReviewsCarousel />
      </div>
      <!-- <div class="read" v-if="!isAuthenticated">
        <ContactUs />
      </div> -->
      <!-- <div class="news-carousel page-container">
        <div class="news-carousel-container">
          <div class="news-carousel-container-top-block">
            <div>
              <div class="news-carousel-container-top-block-title">
                {{ $t('home.news_title') }}
              </div>
              <div class="news-carousel-container-top-block-subtitle blue">
                {{ $t('home.news_sub_title') }}
              </div>
            </div>
            <div>
              <router-link
                tag="button"
                to="/news"
                type="button"
                class="btn-purple-blue-gradient-outline"
                >{{ $t('buttons.all_news') }}</router-link
              >
            </div>
          </div>
          <div class="news-carousel-container-carousel" v-if="allNews && allNews.length">
            <VueSlickCarousel v-bind="carouselSettings">
              <NewsCard v-for="news in allNews" :key="news.id" :news="news" />
            </VueSlickCarousel>
          </div>
        </div>
      </div> -->
    </div>

    <b-modal hide-footer hide-header ref="promoVideoModal" content-class="video-modal video-full">
      <div class="close-modal" @click="closeVideoModal">
        <img src="../../../assets/images/icons/close.svg" alt="Close" />
      </div>
      <div class="modal-block p-1" v-if="settings.promo_video">
        <div class="video">
          <div
            class="video"
            v-if="settings.promo_video"
            v-html="JSON.parse(this.settings.promo_video).video_iframe"
          ></div>
          <div class="video" v-if="settings.promo_video.video_path">
            <video width="100%" :src="videoModal.promo_video.video_path" controls></video>
          </div>
        </div>
      </div>
    </b-modal>

    <CongratulationModal id="con-modal" />
  </div>
</template>

<script>
import Benefits from '../../components/Benefits'
import ReviewsCarousel from '../../components/ReviewsCarousel'
import FadeTitle from '../../components/FadeTitle'
import Description from './Description'
import Presentation from './Presentation'
import CourseList from './CourseList'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapActions, mapGetters, mapState } from 'vuex'
import CongratulationModal from '../../components/CongratulationModal'
import NewsCard from '../../components/NewsCard'

export default {
  name: 'Home',
  data() {
    return {
      carouselSettings: {
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 730,
            settings: {
              arrows: false,
              dots: true,
              infinite: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },
      settingsHeader: {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1460,
            settings: {
              arrows: false,
              dots: true,
              infinite: true,
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1200,
            settings: {
              arrows: false,
              dots: true,
              infinite: true,
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 520,
            settings: {
              arrows: false,
              dots: true,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  components: {
    Description,
    Presentation,
    CourseList,
    // -----------
    NewsCard,
    CongratulationModal,
    Benefits,
    ReviewsCarousel,
    VueSlickCarousel,
    FadeTitle
  },
  computed: {
    ...mapState({
      teachinglangs: state => state.teachingLang.teachinglangs,
      allNews: state => state.news.allNews,
      settings: state => state.setting.settings
    }),
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated'
    })
  },
  created() {
    if (this.$route.query.ref) {
      localStorage.ref = this.$route.query.ref
    }
    this.getSettings().then(() => {
      this.getNews({ count: this.settings.news_slider_count })
    })
  },
  metaInfo() {
    const title = this.$t('home.title')
    return {
      title: title,
      meta: [
        {
          name: 'og:title',
          content: title
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getNews: 'news/getNews',
      getSettings: 'setting/getSettings',
      setLoginModal: 'auth/setLoginModal'
    }),
    openVideoModal() {
      this.$refs.promoVideoModal.show()
    },
    closeVideoModal() {
      this.$refs.promoVideoModal.hide()
    }
  }
}
</script>
