<template>
  <ValidationProvider tag="div" v-slot="{ classes, errors }" :name="fieldName" rules="required" class="form-group position-relative">
    <label for="reg-password">{{ label }}</label>
    <div class="position-relative" :class="inputDivClass">
      <input
        :type="showPassword?'text':'password'"
        pattern="^[a-zA-Z0-9\-#?!@$%^&*_+=]+$"
        :value="value"
        @input="$emit('input', $event.target.value)"
        id="reg-password"
        :class="classes"
        placeholder="Заполнить поле">
      <slot name="afterInput" :errors="errors"/>
      <span @click="showPassword = !showPassword" class="cursor-pointer eye-icon" :class="eyeIconClass">
      <i :class="`fas fa-eye${showPassword?'-slash':''}`"/>
    </span>
    </div>

  </ValidationProvider>
</template>

<script>
export default {
  name: 'PasswordInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    inputDivClass: {
      type: String,
      default: 'input-width-100'
    },
    eyeIconClass: {
      type: String,
      default: ''
    },
    fieldName: {
      type: String,
      default: 'Password'
    }
  },
  data () {
    return {
      showPassword: false,
    }
  },
}
</script>

<style scoped lang="scss">
input {
  padding-right: 53px !important;
}
.input-width-100{
  input{
    width: 100%;
  }
}
.eye-icon {
  position: absolute;
  top: 37%;
  right: 24px;
  font-size: 20px;
}
</style>
