<template>
  <div class="d-flex flex-column" :class="{ 'align-items-center': centerItem }">
    <div class="image__container mb-4" v-if="task.image">
      <img class="image" :src="task.image" alt="image" />
    </div>

    <div class="video mb-4" v-if="task.video || task.video_iframe">
      <VideoPlayer :video="{ video_path: task.video, video_iframe: task.video_iframe }" />
    </div>

    <div class="audio mb-4" v-if="task.audio">
      <mini-audio :audio-source="task.audio"></mini-audio>
    </div>

    <div class="question">
      {{ task.question[$i18n.locale] }}
    </div>

    <div class="answer-list">
      <TaskItem
        v-for="taskAnswer in task.answers"
        :text="taskAnswer.answer[$i18n.locale]"
        :key="taskAnswer.id"
        :selected="answer.answer_id === taskAnswer.id"
        :success="(answer.success && answer.answer_id) === taskAnswer.id"
        :error="(answer.error && answer.answer_id) === taskAnswer.id"
        @click="$emit('selectAnswer', taskAnswer.id)"
      />
    </div>
  </div>
</template>

<script>
import TaskItem from '../../../../components/Tasks/TaskItem.vue'
import VideoPlayer from '../../../../components/VideoPlayer.vue'

export default {
  name: 'TestTemplate',
  computed: {},
  props: {
    task: {
      type: Object,
      default() {
        return {}
      }
    },
    answer: {
      type: Object,
      default() {
        return {}
      }
    },
    centerItem: { type: Boolean, default: false }
  },
  components: { TaskItem, VideoPlayer }
}
</script>

<style lang="scss" scoped>
.answer-list {
  display: flex;
  flex-direction: row;
  gap: 14px;
  flex-wrap: wrap;
  margin-bottom: 35px;
}
.question {
  margin-bottom: 50px;

  @media (max-width: 767px) {
    font-size: 16px;
    font-weight: 600;
  }
}

.br-30 {
  border-radius: 30px !important;
}
.image__container {
  max-width: 600px;
  max-height: 600px;
  .image {
    width: 100%;
    object-fit: cover;
  }
}

.audio {
  max-width: 500px;
  max-height: 500px;
  width: 100%;
}
.video {
  max-width: 800px;
  max-height: 800px;
  width: 100%;
}
</style>
