<template>
  <div class="presentation flex-container page-container">
    <div class="flex-container__content presentation__img">
      <img src="../../../assets/images/index-page/index-presentation.png" alt="img" />
    </div>

    <div class="flex-container__content presentation__content">
      <div class="text-20">
        Language to go (L2G) – полностью автоматизированная онлайн-школа иностранных языков.
      </div>
      <div class="text-48 text-bold">Используйте современные методы обучения</div>
      <div class="border"></div>
      <div class="text-20">
        Вы сами выбираете режим и время прохождения заданий. А еще, современная эффективная методика
        обучения гарантирует освоение языка на максимально высоком уровне за короткий срок. И
        никаких домашних заданий.
      </div>
      <div class="text-20 text-bold">
        Полная автоматизация позволяет нам предоставлять самую низку цену - в 2, 3, а то и в 5 раз
        дешевле других языковых школ.
      </div>

      <div>
        <button class="button button_outline" @click="clickAboutUs">
          Подробнее о нас
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { indexRoutes } from '../../../router/routes'

export default {
  name: 'Presentation',
  methods: {
    clickAboutUs() {
      this.$router.push(indexRoutes.about_us)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables/media';
.presentation {
  margin-top: 80px;

  &__content {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    grid-gap: var(--spacing-16);
    gap: var(--spacing-32);
  }

  &__img {
    display: grid;
    justify-content: center;
    align-items: center;
    img {
      pointer-events: none;
    }
  }

  .button {
    min-height: 65px;
    padding: var(--spacing-8) var(--spacing-32);
    opacity: 0.5;
  }
}
@media (max-width: $media-s) {
  .presentation {
    margin-top: 40px;
    &__content {
      display: grid;
      flex-direction: column;
      grid-gap: var(--spacing-8);
      gap: var(--spacing-16);
    }
    &__img {
      img {
        max-height: 320px;
      }
    }

    .button {
      width: 100%;
    }
  }
}
</style>
