<template>
  <div class="support personal-details">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <div>
        <div class="tabs-title">
          Техническая поддержка
        </div>
        <div class="tabs-info">
          Если у вас возникли вопросы или проблемы обратитесь в нашу тех. поддержку
        </div>
      </div>
      <router-link to="/profile/technical-support/new-template" type="button" class="new-template mt-0">СОЗДАТЬ НОВОЕ
        ОБРАЩЕНИЕ
      </router-link>
    </div>
    <div class="support-content">
      <router-link to="/profile/technical-support" type="button" class="new-template new-btn sp-button">
        <img src="~@/assets/images/profile/back-icon.svg" alt="">
        НАЗАД КО ВСЕМ ОБРАЩЕНИЯМ 
      </router-link>
      <div class="theme">Тема: {{ supportTicketItem && supportTicketItem.support_ticket_category && supportTicketItem.support_ticket_category.name[$i18n.locale] }}</div>
      <div class="tp-chat-container" v-chat-scroll="{always: false, smooth: true}">
        <div v-for="(message, index) in supportTicketItem.support_ticket_messages" v-bind:key="index">
          <div class="user" v-if="message.sender_is_user">
            <div class="avatar">
              <img v-if="message.user.avatar" :src="message.user.avatar_path" alt="">
              <img v-else src="~@/assets/images/profile/add-image.svg" alt="avatar">
            </div>
            <div class="content">
              <div class="username">{{ message.user.first_name }} {{ message.user.last_name }}</div>
              <div class="subject">
                {{ message.message }}
              </div>
              <div class="date">{{ message.created_at  | moment('DD.MM.YYYY | HH:mm') }}</div>
            </div>
          </div>
          <div class="answer" v-else>
            <div class="name">
              {{ message.admin.name }}/{{ message.admin.role.name }}
            </div>
            <div class="subject">
              {{ message.message }}
            </div>
            <div class="date">{{ message.created_at  | moment('DD.MM.YYYY | HH:mm') }}</div>
          </div>
        </div>
      </div>
    </div>
    <ValidationObserver ref="replyForm" id="replyForm" tag="form" @submit.prevent="replyMessage()">
      <ValidationProvider rules="required|max:1000" tag="div" v-slot="{ classes }" class="form-group">
        <label>Сообщения</label>
        <textarea class="form-control w-100" v-model="formData.message" :class="[classes, 'reply-form-textarea']"
                  placeholder="Ваше сообщение"></textarea>
      </ValidationProvider>
      <button type="submit" class="send-btn">ОТПРАВИТЬ</button>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'Template',
  data () {
    return {
      formData: {},
      buttonDisable: false
    }
  },
  computed: {
    ...mapState({
      supportTicketItem: state => state.supportTicket.supportTicketItem
    })
  },
  created () {
    this.getSupportItem()
    this.pusherInit()
  },
  methods: {
    ...mapActions({
      getSupportTicketItem: 'supportTicket/getSupportTicketItem',
      replySupportTicket: 'supportTicket/replySupportTicket',
      messagesSeen: 'supportTicket/messagesSeen'
    }),
    ...mapMutations({
      setNewMessage: 'supportTicket/setMessageList'
    }),
    pusherInit () {
      const channel = this.$echo.join(`support-chat.${this.$route.params.id}`)
        .here((users) => {
          console.log('strep', users)
        })
        .leaving((user) => {
          console.log('leaving', user)
        })
        .joining((user) => {
          console.log('joining', user)
        })
        .listen('MessageSent', e => {
          console.log('MessageSent', e)
          this.setNewMessage(e.message)
        })
      console.log('channel', channel)
    },
    getSupportItem () {
      this.getSupportTicketItem({ id: this.$route.params.id }).then(() => {
        this.messagesSeen({ id: this.$route.params.id })
      }).catch(() => {
        this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
      })
    },
    replyMessage () {
      this.$refs.replyForm.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          this.replySupportTicket({
            formData: this.formData,
            id: this.supportTicketItem.id
          }).then(() => {
            this.$toasted.success('Message send successfully !').goAway(1500)
            this.$refs.replyForm.reset()
            this.formData = {}
          }).catch(() => {
            this.$toasted.error('Something went wrong!').goAway(1500)
          }).finally(() => {
            this.buttonDisable = false
          })
        } else {
          this.$toasted.error('There are uncompleted fields').goAway(1500)
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.tp-chat-container {
  max-height: 40vh;
  overflow-y: auto;
}

.reply-form-textarea {
  max-width: 100% !important;
}

.new-template {
  border-width: 3px !important;
  font-weight: 800 !important;
}

.theme, .username {
  font-weight: 700 !important;
}
</style>
