export default {
  data(){
    return{
      breaks: []
    }
  },
  methods: {
    pushBreaks(value){
      this.breaks.push(value.match(/\n/gi))
    }
  }
}
