import { http } from '../../../api'

const setting = {
  namespaced: true,
  state: {
    settings: {},
    currency: [],
    selectedCurrency: localStorage.selectedCurrency ? JSON.parse(localStorage.selectedCurrency) : {}
  },
  actions: {
    getSettings: ({ commit }) => {
      return http.get('project-setting').then(res => {
        commit('setSettings', res.data)
      })
    },
    getCurrency: ({ commit }) => {
      return http.get('currency').then(res => {
        commit('setCurrency', res.data)
      })
    }
  },
  mutations: {
    setSettings (state, data) {
      state.settings = data.data
      state.settings.email = JSON.parse(state.settings.email)
      state.settings.phone = JSON.parse(state.settings.phone)
    },
    setCurrency (state, data) {
      state.currency = data.data
    },
    setSelectedCurrency(state, data){
      console.log('data', data)
      state.selectedCurrency = data
      localStorage.setItem('selectedCurrency', JSON.stringify(data))
    }
  },
  getters: {
    settings: state => state.settings,
    currency: state => state.currency,
    selectedCurrency: state => state.selectedCurrency
  }
}

export default setting
