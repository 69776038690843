<template>
  <div v-if="pageCount > 1" class="slick-dots__container">
    <ul class="slick-dots">
      <li
        v-for="(num, index) in pageCount"
        :key="index"
        @click="$emit('changePage', index)"
        :class="{
          'slick-active': currentIndex === index,
          'slick-selected': answers[index].has_answer,
          'slick-correct': answers[index].success,
          'slick-incorrect': answers[index].error
        }"
      >
        <button>{{ index }}</button>
      </li>
    </ul>
    <div class="task-choose-translation-cards-div"></div>
  </div>
</template>

<script>
export default {
  name: 'DotsTemplate',
  props: {
    pageCount: { type: Number, default: 0 },
    currentIndex: { type: Number, default: 0 },
    errors: {
      type: Array,
      default() {
        return []
      }
    },
    answers: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>
