<template>
  <!-- Video chat -->
  <div class="personal-details chat video-chat filled">
    <div class="chat-items">
      <div class="top-line">
        <div class="d-flex align-items-center justify-content-between">
          <div class="name">Чаты</div>
          <button class="add-btn" type="button">+</button>
        </div>
        <div class="search">
          <input type="text" placeholder="Поиск чата">
          <img src="~@/assets/images/icons/search.svg" alt="search">
        </div>
      </div>
      <div class="chat-list">
        <ul class="list">
          <router-link to="/profile/chat" tag="li">
            <p>Китайский для новичков</p><span class="badge">2</span>
          </router-link>
          <router-link to="/profile/video" tag="li">
            <p>Чат видео название</p>
          </router-link>
          <router-link to="/profile/video-chat" tag="li">
            <p>Чат видео + переписка</p>
          </router-link>
          <li>
            <p>Название чата</p>
          </li>
          <li>
            <p>Название чата</p>
          </li>
          <li>
            <p>Название чата</p>
          </li>
          <li>
            <p>Название чата</p>
          </li>
          <li>
            <p>Название чата</p>
          </li>
          <li>
            <p>Название чата</p>
          </li>
          <li>
            <p>Название чата</p>
          </li>
          <li>
            <p>Название чата</p>
          </li>
          <li>
            <p>Название чата</p>
          </li>
          <li>
            <p>Название чата</p>
          </li>
          <li>
            <p>Название чата</p>
          </li>
          <li>
            <p>Название чата</p>
          </li>
          <li>
            <p>Название чата</p>
          </li>
          <li>
            <p>Название чата</p>
          </li>
          <li>
            <p>Название чата</p>
          </li>
          <li>
            <p>Название чата</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="chat-content">
      <div class="top-line">
        <div class="left">
          <div class="mobile-visible">
            <a href="#">
              <img src="~@/assets/images/icons/menu-burger.svg" alt="menu burger">
            </a>
          </div>
          <div class="wrapper">
            <div class="chat-name">Чат видео название</div>
            <div class="people-number">4 человека</div>
          </div>
        </div>
        <div class="right">
          <a href="#">
            <img src="~@/assets/images/icons/video.svg" alt="">
          </a>
        </div>
        <div class="right">
          <a href="#">
            <img src="~@/assets/images/icons/out.svg" alt="">
          </a>
        </div>
      </div>
      <div class="content video-chat-content">
        <div class="left-side">
          <div class="person">
            <img src="~@/assets/images/user.png" alt="">
            <div class="top-overlay">
              <div class="bottom">
                <div class="bottom-actions">
                  <a href="#" class="item">
                    <img src="~@/assets/images/icons/microphone.svg" alt="">
                  </a>
                  <a href="#" class="item">
                    <img src="~@/assets/images/icons/video-white.svg" alt="">
                  </a>
                </div>
                <div class="name">User_nicname/login</div>
              </div>
            </div>
          </div>
          <div class="person">
            <span>Нет видео</span>
            <div class="top-overlay">
              <div class="bottom">
                <div class="bottom-actions">
                  <a href="#" class="item">
                    <img src="~@/assets/images/icons/microphone.svg" alt="">
                  </a>
                  <a href="#" class="item">
                    <img src="~@/assets/images/icons/video-white.svg" alt="">
                  </a>
                </div>
                <div class="name">User_nicname/login</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-side">
          <div class="chat-content">
            <div class="content">
              <ul>
                <li class="received">
                  <div class="avatar">
                    <img src="~@/assets/images/China.png" alt="">
                  </div>
                  <div class="general">
                    <div class="username">
                      <span>User_nicname/login</span>
                      <span class="date">01.04.2020 | 15:22</span>
                    </div>
                    <div class="message">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                      rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                      tincidunt cursus.
                    </div>
                  </div>
                </li>
                <li class="received">
                  <div class="avatar">
                    <img src="~@/assets/images/China.png" alt="">
                  </div>
                  <div class="general">
                    <div class="username">
                      <span>User_nicname/login</span>
                      <span class="date">01.04.2020 | 15:22</span>
                    </div>
                    <div class="message">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                      rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                      tincidunt cursus.
                    </div>
                  </div>
                </li>
                <li class="received">
                  <div class="avatar">
                    <img src="~@/assets/images/China.png" alt="">
                  </div>
                  <div class="general">
                    <div class="username">
                      <span>User_nicname/login</span>
                      <span class="date">01.04.2020 | 15:22</span>
                    </div>
                    <div class="message">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                      rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                      tincidunt cursus.
                    </div>
                  </div>
                </li>
                <li class="sent">
                  <div class="general">
                    <div class="username">
                      <span>User_nicname/login</span>
                      <span class="date">01.04.2020 | 15:22</span>
                    </div>
                    <div class="message">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                      rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                      tincidunt cursus.
                    </div>
                  </div>
                  <div class="avatar">
                    <img src="~@/assets/images/China.png" alt="">
                  </div>
                </li>
                <li class="received">
                  <div class="avatar">
                    <img src="~@/assets/images/China.png" alt="">
                  </div>
                  <div class="general">
                    <div class="username">
                      <span>User_nicname/login</span>
                      <span class="date">01.04.2020 | 15:22</span>
                    </div>
                    <div class="message">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                      rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                      tincidunt cursus.
                    </div>
                  </div>
                </li>
                <li class="sent">
                  <div class="general">
                    <div class="username">
                      <span>User_nicname/login</span>
                      <span class="date">01.04.2020 | 15:22</span>
                    </div>
                    <div class="message">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                      rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                      tincidunt cursus.
                    </div>
                  </div>
                  <div class="avatar">
                    <img src="~@/assets/images/China.png" alt="">
                  </div>
                </li>
                <li class="sent">
                  <div class="general">
                    <div class="username">
                      <span>User_nicname/login</span>
                      <span class="date">01.04.2020 | 15:22</span>
                    </div>
                    <div class="message">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                      rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                      tincidunt cursus.
                    </div>
                  </div>
                  <div class="avatar">
                    <img src="~@/assets/images/China.png" alt="">
                  </div>
                </li>
                <li class="received">
                  <div class="avatar">
                    <img src="~@/assets/images/China.png" alt="">
                  </div>
                  <div class="general">
                    <div class="username">
                      <span>User_nicname/login</span>
                      <span class="date">01.04.2020 | 15:22</span>
                    </div>
                    <div class="message">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero ac tellus gravida
                      rhoncus. Morbi dignissim pellentesque dui, ut consectetur tellus venenatis at. Phasellus efficitur
                      tincidunt cursus.
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="new-message">
              <input type="text" placeholder="Написать сообщение">
              <button type="button" class="send-btn">
                <span>отправить</span>
                <img src="~@/assets/images/icons/send.svg" alt="">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoAndChat'
}
</script>
