<template>
  <div class="personal-details courses dashboard lessons">
    <div class="tabs-title mb-4">
      Доступные курсы
    </div>
    <div class="personal-details-tabs">
      <b-tabs :class="{'mobile-tabs': is_mobile}" v-if="!is_mobile">
        <b-tab @click="$router.push('/profile/courses/main')" :active="$route.params.id === 'main'">
          <template #title>
            <span>Основные курсы</span>
          </template>
          <b-tabs v-if="languages.length" class="sub-tabs">
            <b-tab @click="availableLanguage = 'all'" :active="availableLanguage === 'all'">
              <template #title>
                <span>ВСЕ КУРСЫ</span>
              </template>
            </b-tab>

            <b-tab v-for="(language, index) in languages" :key="language.id"
                   @click="availableLanguage = language.id" :active="availableLanguage == language.id">
              <template #title>
                <img :src="language.flag" width="30" alt="">
                <span class="text-uppercase">{{ language.name[$i18n.locale] }}</span>
              </template>
            </b-tab>

            <div class="personal-details-tabs-main">
              <div class="courses-language-to-go">
                <div class="text-part">
                  <div class="sub-title">
                    Основные языковые курсы
                  </div>
                  <div class="tabs-info blue-color">
                    Перед покупкой курса Вам нужно будет пройти тест на определение уровня знаний языка
                  </div>
                </div>
                <Loading v-if="loading"/>
                <div class="content" v-else>
                  <div class="course-item-language-carousel" v-if="boughtCoursesList"
                       v-for="(course, index) in allCoursesList" :key="index">
                    <div class="course-item" :class="{'upcoming': course.in_developing}">
                      <div class="upcoming-cover">
                        <img src="../../../../assets/images/upcoming-icon.png" alt="upcoming">
                        <span class="upcoming-cover-title">В разработке</span>
                      </div>
                      <div class="video">
                        <template v-if="course.announcement.video || course.announcement.video_iframe">
                          <video width="320" height="240" controls
                                 v-if="course.announcement && course.announcement.video">
                            <source :src="course.announcement.video" type="video/mp4">
                            Your browser does not support the video tag.
                          </video>
                          <div class="video" v-if="course.announcement && course.announcement.video_iframe">
                            <div v-if="course.announcement && course.announcement.video_iframe !== null"
                                 v-html="course.announcement.video_iframe" class="video-iframe"></div>
                          </div>
                        </template>
                        <template v-else-if="course.image_path">
                          <img :src="course.image_path" alt="photo" class="img-fluid"/>
                        </template>

                      </div>
                      <div class="text-content">
                        <div class="top-line">
                          <div class="flag flag-c">
                            <img :src="course.teaching_language.flag" alt="flag-de">
                          </div>
                          <div class="name">{{ course.name[$i18n.locale] }}</div>
                        </div>
                        <div class="description" v-html="course.description[$i18n.locale]">

                        </div>
                        <router-link :to="'/profile/course/' + course.id" class="details">
                          {{ $t('buttons.more_about_course') }} »
                        </router-link>
                        <button v-if="course.is_free_lesson && course.lesson_id" class="pass-course-btn"
                                @click="$router.push('/profile/free-lesson/' + course.lesson_id)" type="button">ПРОЙТИ
                          БЕСПЛАТНЫЙ УРОК
                        </button>
                        <p v-else class="no-free-lesson">Нет бесплатного урока</p>
                      </div>
                      <div class="bottom-action cursor-pointer" @click="openModalBuy(course)">
                        <a href="#">купить курс</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tabs>
        </b-tab>
        <b-tab @click="$router.push('/profile/courses/all')" :active="$route.params.id !== 'main'" v-if="hasAdditional">
          <template #title>
            <span>Дополнительные курсы</span>
          </template>
          <b-tabs class="sub-tabs">
            <b-tab @click="$router.push('/profile/courses/all')" :active="$route.params.id === 'all'">
              <template #title>
                <span>ВСЕ КУРСЫ</span>
              </template>
            </b-tab>
            <b-tab v-for="(language, index) in languages" :key="language.id"
                   @click="$router.push('/profile/courses/' + language.id)" :active="$route.params.id == language.id">
              <template #title>
                <img :src="language.flag" width="30" alt="">
                <span class="text-uppercase">{{ language.name[$i18n.locale] }}</span>
              </template>
            </b-tab>
            <div class="personal-details-tabs-main">
              <div class="courses-language-to-go">
                <Loading v-if="loading"/>
                <div class="content" v-else>
                  <div class="course-item-language-carousel" v-for="(course, index) in allCoursesList" :key="index">
                    <div class="course-item">
                      <div class="video">
                        <template v-if="course.announcement.video || course.announcement.video_iframe">
                          <video width="320" height="240" controls
                                 v-if="course.announcement && course.announcement.video">
                            <source :src="course.announcement.video" type="video/mp4">
                            Your browser does not support the video tag.
                          </video>
                          <div class="video" v-if="course.announcement && course.announcement.video_iframe">
                            <div v-if="course.announcement && course.announcement.video_iframe !== null"
                                 v-html="course.announcement.video_iframe" class="video-iframe"></div>
                          </div>
                        </template>
                        <template v-else-if="course.image_path">
                          <img :src="course.image_path" alt="photo" class="img-fluid"/>
                        </template>
                      </div>
                      <div class="text-content">
                        <div class="top-line">
                          <div class="flag">
                            <img :src="course.teaching_language.flag" alt="flag-de">
                          </div>
                          <div class="name">{{ course.name[$i18n.locale] }}</div>
                        </div>
                        <div class="description" v-html="course.description[$i18n.locale]"></div>
                        <router-link :to="'/profile/course/' + course.id" class="details">
                          {{ $t('buttons.more_about_course') }}
                        </router-link>
                        <button v-if="course.is_free_lesson && course.lesson_id" class="pass-course-btn"
                                @click="$router.push('/profile/free-lesson/' + course.lesson_id)" type="button">ПРОЙТИ
                          БЕСПЛАТНЫЙ УРОК
                        </button>
                        <p v-else class="no-free-lesson">Нет бесплатного урока</p>
                      </div>
                      <div class="bottom-action cursor-pointer" @click="openModalBuy(course)">
                        <a href="#">купить курс</a>
                      </div>
                    </div>
                  </div>
                  <!--                  <div class="course-item-language-carousel in-process">-->
                  <!--                    <div class="course-item">-->
                  <!--                      <div class="background">-->
                  <!--                        <img src="~@/assets/images/italy.png" alt="">-->
                  <!--                      </div>-->
                  <!--                      <div class="text-content">-->
                  <!--                        <div class="top-line flex-column align-items-center justify-content-center">-->
                  <!--                          <div class="flag">-->
                  <!--                            <img src="~@/assets/images/flags/cn.png" alt="flag-de">-->
                  <!--                          </div>-->
                  <!--                          <div class="name">-->
                  <!--                            Школа-->
                  <!--                            Итальянского языка-->
                  <!--                          </div>-->
                  <!--                        </div>-->
                  <!--                      </div>-->
                  <!--                      <div class="overlay">-->
                  <!--                        В РАЗРАБОТКЕ-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </b-tabs>
        </b-tab>
      </b-tabs>
      <div :class="{'mobile-accordion': is_mobile}" v-else>
        <div class="accordion" role="tablist">
          <div class="mb-1">
            <div role="tab">
              <b-button @click="scrollOn" block v-b-toggle.accordion-1 class="accordion-btn">
                <template>
                  <span>Основные курсы</span>
                </template>
              </b-button>
            </div>
            <b-collapse id="accordion-1" class="accordion-lessons pp-accordion-lessons" visible accordion="my-accordion" role="tabpanel">
              <div class="personal-details-tabs-main">
                <div class="courses-language-to-go">
                  <div class="text-part mt-2">
                    <div class="sub-title">
                      Основные языковые курсы
                    </div>
                    <div class="tabs-info mt-2">
                      Перед покупкой курса Вам нужно будет пройти тест на определение уровня знаний языка
                    </div>
                  </div>
                  <Loading v-if="loading"/>
                  <div class="content" v-else>
                    <div class="course-item-language-carousel" v-for="(course, index) in allCoursesList" :key="index">
                      <div class="course-item">
                        <div class="video">
                          <img :src="course.image_path" alt="photo" class="img-fluid"/>
                          <!--                          <video width="320" height="240" controls-->
                          <!--                                 v-if="course.announcement && course.announcement.video">-->
                          <!--                            <source :src="course.announcement.video" type="video/mp4">-->
                          <!--                            Your browser does not support the video tag.-->
                          <!--                          </video>-->
                          <!--                          <div class="video" v-if="course.announcement && course.announcement.video_iframe">-->
                          <!--                            <div v-if="course.announcement && course.announcement.video_iframe !== null"-->
                          <!--                                 v-html="course.announcement.video_iframe" class="video-iframe"></div>-->
                          <!--                          </div>-->
                        </div>
                        <div class="text-content">
                          <div class="top-line">
                            <div class="flag">
                              <img :src="course.teaching_language.flag" alt="flag-de">
                            </div>
                            <div class="name">{{ course.name[$i18n.locale] }}</div>
                          </div>
                          <div class="description" v-html="course.description[$i18n.locale]"></div>
                          <router-link :to="'/profile/course/' + course.id" class="details">
                            {{ $t('buttons.more_about_course') }}
                          </router-link>
                          <button v-if="course.is_free_lesson && course.lesson_id" class="pass-course-btn"
                                  @click="$router.push('/profile/free-lesson/' + course.lesson_id)" type="button">ПРОЙТИ
                            БЕСПЛАТНЫЙ УРОК
                          </button>
                        </div>
                        <div class="bottom-action cursor-pointer" @click="openModalBuy(course)">
                          <a href="#">купить курс</a>
                        </div>
                      </div>
                    </div>
                    <div class="course-item-language-carousel in-process">
                      <div class="course-item">
                        <div class="background">
                          <img src="~@/assets/images/italy.png" alt="">
                        </div>
                        <div class="text-content">
                          <div class="top-line flex-column align-items-center justify-content-center">
                            <div class="flag">
                              <img src="~@/assets/images/flags/cn.png" alt="flag-de">
                            </div>
                            <div class="name">
                              Школа
                              Итальянского языка
                            </div>
                          </div>
                        </div>
                        <div class="overlay">
                          В РАЗРАБОТКЕ
                        </div>
                      </div>
                    </div>
                    <div class="course-item-language-carousel in-process">
                      <div class="course-item">
                        <div class="background">
                          <img src="~@/assets/images/italy.png" alt="">
                        </div>
                        <div class="text-content">
                          <div class="top-line flex-column align-items-center justify-content-center">
                            <div class="flag">
                              <img src="~@/assets/images/flags/cn.png" alt="flag-de">
                            </div>
                            <div class="name">
                              Школа
                              Итальянского языка
                            </div>
                          </div>
                        </div>
                        <div class="overlay">
                          В РАЗРАБОТКЕ
                        </div>
                      </div>
                    </div>
                    <div class="course-item-language-carousel in-process">
                      <div class="course-item">
                        <div class="background">
                          <img src="~@/assets/images/italy.png" alt="">
                        </div>
                        <div class="text-content">
                          <div class="top-line flex-column align-items-center justify-content-center">
                            <div class="flag">
                              <img src="~@/assets/images/flags/cn.png" alt="flag-de">
                            </div>
                            <div class="name">
                              Школа
                              Итальянского языка
                            </div>
                          </div>
                        </div>
                        <div class="overlay">
                          В РАЗРАБОТКЕ
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="mb-1">
            <div role="tab">
              <b-button @click="scrollOn" block v-b-toggle.accordion-2 class="accordion-btn">
                <template>
                  <span>Дополнительные курсы</span>
                </template>
              </b-button>
            </div>
            <b-collapse id="accordion-2" class="accordion-lessons" visible accordion="my-accordion" role="tabpanel">
              <b-tabs class="sub-tabs sub-tabs-mini">
                <b-tab @click="$router.push('/profile/courses/all')" :active="$route.params.id === 'all'">
                  <template #title>
                    <span>ВСЕ КУРСЫ</span>
                  </template>
                </b-tab>
                <b-tab v-for="(language, index) in languages" :key="index"
                       @click="$router.push('/profile/courses/' + language.id)"
                       :active="$route.params.id === language.id">
                  <template #title>
                    <img class="sub-tabs-mini-image" :src="language.flag" width="30" alt="">
                    <span class="text-uppercase sub-tabs-mini-name">{{ language.name[$i18n.locale] }}</span>
                  </template>
                </b-tab>
                <div class="personal-details-tabs-main">
                  <div class="courses-language-to-go">
                    <Loading v-if="loading"/>
                    <div class="content" v-else>
                      <div class="course-item-language-carousel" v-for="(course, index) in allCoursesList" :key="index">
                        <div class="course-item">
                          <div class="video">
                            <img :src="course.image_path" alt="photo" class="img-fluid"/>
                            <!--                            <template v-if="course.announcement.video || course.announcement.video_iframe">-->
                            <!--                              <video width="320" height="240" controls-->
                            <!--                                     v-if="course.announcement && course.announcement.video">-->
                            <!--                                <source :src="course.announcement.video" type="video/mp4">-->
                            <!--                                Your browser does not support the video tag.-->
                            <!--                              </video>-->
                            <!--                              <div class="video" v-if="course.announcement && course.announcement.video_iframe">-->
                            <!--                                <div v-if="course.announcement && course.announcement.video_iframe !== null"-->
                            <!--                                     v-html="course.announcement.video_iframe" class="video-iframe"></div>-->
                            <!--                              </div>-->
                            <!--                            </template>-->
                            <!--                            <template v-else-if="course.image_path">-->
                            <!--                              <img :src="course.image_path"  alt="photo" class="img-fluid"/>-->
                            <!--                            </template>-->
                          </div>
                          <div class="text-content">
                            <div class="top-line">
                              <div class="flag">
                                <img :src="course.teaching_language.flag" alt="flag-de">
                              </div>
                              <div class="name">{{ course.name[$i18n.locale] }}</div>
                            </div>
                            <div class="description" v-html="course.description[$i18n.locale]"></div>
                            <router-link :to="'/profile/course/' + course.id" class="details">
                              {{ $t('buttons.more_about_course') }}
                            </router-link>
                            <button v-if="course.is_free_lesson && course.lesson_id" class="pass-course-btn"
                                    @click="$router.push('/profile/free-lesson/' + course.lesson_id)" type="button">
                              ПРОЙТИ БЕСПЛАТНЫЙ УРОК
                            </button>
                          </div>
                          <div class="bottom-action cursor-pointer" @click="openModalBuy(course)">
                            <a href="#">купить курс</a>
                          </div>
                        </div>
                      </div>
                      <div class="course-item-language-carousel in-process">
                        <div class="course-item">
                          <div class="background">
                            <img src="~@/assets/images/italy.png" alt="">
                          </div>
                          <div class="text-content">
                            <div class="top-line flex-column align-items-center justify-content-center">
                              <div class="flag">
                                <img src="~@/assets/images/flags/cn.png" alt="flag-de">
                              </div>
                              <div class="name">
                                Школа
                                Итальянского языка
                              </div>
                            </div>
                          </div>
                          <div class="overlay">
                            В РАЗРАБОТКЕ
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tabs>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3" v-if="pagination && pagination.count_pages > 1 && !loading">
      <b-pagination
        v-model="currentPage"
        :total-rows="pagination.count_pages"
        :per-page="1"
        first-number
        last-number
        prev-text="«"
        next-text="»"
        @input="clickPagination"
      ></b-pagination>
    </div>
    <ModalBuyingCourse ref="chooseCoursePlan" :course="course"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Loading from '../../../components/Loading'
import CheckBuyTariffMixin from '../../../../mixins/CheckBuyTariffMixin'
import VClamp from 'vue-clamp'
import ModalBuyingCourse from '../../../components/ModalBuyingCourse'

export default {
  name: 'ProfileCourses',
  mixins: [CheckBuyTariffMixin],
  data () {
    return {
      course: {},
      tariffActive: {
        name: {}
      },
      paramsId: '',
      currentPage: 1,
      loading: false,
      formData: {
        course_tariff_id: ''
      },
      courseTariffError: '',
      availableLanguage: 'all',
      hasAdditional: false,
      languages: []
    }
  },
  components: {
    ModalBuyingCourse,
    Loading,
    VClamp
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.id) {
      if (to.query.page) {
        this.currentPage = to.query.page
      } else {
        this.currentPage = 1
      }
      if (to.params.id === 'main') {
        this.setAvailableCourses()
      } else {
        this.loading = true
        this.getAvailableCourses({
          language: to.params.id,
          course_type: 'additional',
          count: 12,
          page: this.currentPage
        }).then(res => {
          this.hasAdditional = res.hasAdditional
          this.languages = res.languages
        }).finally(() => {
          this.loading = false
        })
      }
    }
    next()
  },
  computed: {
    ...mapState({
      allCoursesList: state => state.course.allCoursesList,
      pagination: state => state.course.pagination,
    }),
    is_mobile () {
      const isMobile = window.matchMedia('only screen and (max-width: 576px)')
      return !!isMobile.matches
    }
  },
  created () {
    if (this.$route.query.lang) {
      this.availableLanguage = this.$route.query.lang
    }

    if (this.$route.params.id === 'main') {
      this.setAvailableCourses()
    } else {
      this.getAvailableCourses({
        language: this.$route.params.id,
        course_type: 'additional',
        count: 12,
        page: this.currentPage
      }).then(res => {
        this.hasAdditional = res.hasAdditional
        this.languages = res.languages
      }).finally(() => {
        this.loading = false
      })
    }
  },
  watch: {
    availableLanguage(){
      this.setAvailableCourses()
    }
  },
  methods: {
    ...mapActions({
      getAvailableCourses: 'course/getAvailableCourses',
    }),
    scrollOn() {
      document.getElementsByTagName('body')[0].style.overflowY = 'scroll'
    },
    setAvailableCourses(){
      this.loading = true
      this.getAvailableCourses({
        language: this.availableLanguage,
        course_type: 'main',
        count: 12,
        page: this.currentPage
      }).then(res => {
        this.hasAdditional = res.hasAdditional
        this.languages = res.languages
      }).finally(() => {
        this.loading = false
      })
    },
    openModalBuy (course) {
      this.course = course
      this.$refs.chooseCoursePlan.$children[0].show()
    },
    clickPagination () {
      this.$router.push('/profile/courses/' + this.$route.params.id + '?page=' + this.currentPage)
      window.scrollTo(0, 0)
    }
  }
}
</script>
