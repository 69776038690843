<template>
  <transition name="flip">
    <div class="flip-card" :class="showFront ? 'front' : 'back'" :key="showFront">
      <div class="flip-card-inner" :class="{
                  'success-card': success,
                  'error-card': error,
                }">
        {{ showFront ? text: '' }}
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Card',
  props: {
    showFront: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.flip-card {
  cursor: pointer;
  height: 113px;
  border-radius: 16px;
  will-change: transform;
  background-color: white;
  font-size: 18px;
  padding: 10px;

  .flip-card-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }

  .success-card {
    border: 3px solid #00a651;
  }

  .error-card {
    border: 3px solid #CF454E;
  }
}

.back {
  background-image: url("../../../assets/images/tasks/back-flip-card.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter, .flip-leave {
  transform: rotateY(180deg);
  opacity: 0;

}
</style>
