<template>
  <div class="personal-details free-lesson">
    <div class="tabs-title mb-4">
      Бесплатные уроки
    </div>
    <router-link :to="{name: 'ProfileLessons', params:{id: 'all'}}" tag="div" class="back-to">
      <img src="~@/assets/images/profile/back-icon.svg" alt="">
      <span>НАЗАД КО ВСЕМ БЕСПЛАТНЫМ УРОКАМ</span>
    </router-link>
    <div class="free-lesson-title">
      <img v-if="lesson.course_module" :src="lesson.course_module.course.teaching_language.flag_path" alt="">
      <div class="text">
        {{ lesson.name[$i18n.locale] }}
        <span>{{ lesson.short_description[$i18n.locale] }}</span>
      </div>
    </div>
    <div class="free-lesson-content">
      <div class="video">
        <div width="100%" height="380" v-html="lesson.video_iframe" controls>
        </div>
        <div class="actions">
          <button type="button" @click="$router.push('/profile/course/' + lesson.course_module.course.id)">ПОЛУЧИТЬ ВЕСЬ
            КУРС
          </button>
          <GoToTasks :lesson="lesson" />
        </div>
      </div>
      <div class="description" v-if="lesson.video_iframe || lesson.description || lesson.video_file">
        <VideoPlayer :video="{video_iframe: lesson.video_iframe, video_path: lesson.video_file}"/>
        <div class="content" v-html="lesson.description[$i18n.locale]">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VideoPlayer from '../../../components/VideoPlayer'
import GoToTasks from '../../../components/Lessons/GoToTasks'

export default {
  name: 'FreeLesson',
  components: { GoToTasks, VideoPlayer },
  computed: {
    ...mapState({
      lesson: state => state.lesson.lesson
    })
  },
  created () {
    this.getLessonFree({ id: this.$route.params.id }).then(res => {
    })
  },
  methods: {
    ...mapActions({
      getLessonFree: 'lesson/getLessonFree'
    })
  }
}
</script>
