<template>
  <StatisticsBase
    :language="statisticsTest.language"
    sub-title="Подробная статистика тестов."
  >
    <div class="task-content">
      <div class="tasks">
        <template v-for="data in statisticsTest.data">
          <TaskColumn
            :key="`${data.id}${data.name}`"
            :task-name="`Тест: ${data.name} ${$route.query.module?$route.query.module:''}`"
            :point="data.point + ' балл'"
          />
          <TaskColumn
            v-for="test in data.tests"
            :key="`test${test.id}`"
            :task-name="`Тест: ${data.name} ${$route.query.module?$route.query.module:''}`"
            :point="test.point + ' балл' "
          />
        </template>

      </div>
    </div>
  </StatisticsBase>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import StatisticsBase from '../../../components/StatisticsBase'
import LessonTaskButton from '../../../components/LessonTaskButton'
import TaskColumn from '../../../components/TaskColumn'

export default {
  name: 'StatisticsTest',
  components: { TaskColumn, LessonTaskButton, StatisticsBase },
  data () {
    return {
      subTitle: ''
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.getStats()
    next()
  },
  created () {
    this.getStats()
  },
  computed: {
    ...mapState({
      statisticsTest: state => state.statistics.statisticsTest
    })
  },
  methods: {
    ...mapActions({
      getStatisticsTest: 'statistics/getStatisticsTest'
    }),
    getStats () {
      const params = {}
      if (this.$route.query.lesson_id) {
        params.lesson_id = this.$route.query.lesson_id
      } else if (this.$route.query.course_id) {
        params.course_id = this.$route.query.course_id
      } else if (this.$route.query.module_id) {
        params.module_id = this.$route.query.module_id
      }
      this.getStatisticsTest(params)
    }
  }
}
</script>

