import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'
import Vuex from 'vuex'
import auth from './modules/auth'
import language from './modules/language'
import faqCategory from './modules/faqCategory'
import news from './modules/news'
import videos from './modules/videos'
import faq from './modules/faq'
import dynamicPage from './modules/dynamicPage'
import review from './modules/review'
import teachingLang from './modules/teachingLang'
import interesting from './modules/interesting'
import setting from './modules/setting'
import user from './modules/user'
import supportTicket from './modules/supportTicket'
import note from './modules/note'
import course from './modules/course'
import book from './modules/book'
import audio from './modules/audio'
import teachingVideo from './modules/teachingVideo'
import paymentMethod from './modules/paymentMethod'
import otherMaterial from './modules/otherMaterial'
import paymentHistory from './modules/paymentHistory'
import courseChat from './modules/courseChat'
import task from './modules/task'
import lesson from './modules/lesson'
import chatExpert from './modules/chatExpert'
import statistics from './modules/statistics'
import courseModuleTest from './modules/courseModuleTest'
import search from './modules/search'
import subscribe from './modules/subscribe'
import block from './modules/block'
import vocabulary from './modules/vocabulary'
import nextTaskId from './modules/nextTaskId'
import courseModule from './modules/courseModule'
import loading from './modules/loading'
import dictionary from './modules/dictionary'
import phones from './modules/phones'
import coupon from './modules/coupon'
import referralCoupon from './modules/referralCoupon'
import tariff from './modules/tariff'
import contact from './modules/contact'

Vue.use(Vuex)

const dataState = createPersistedState({
  paths: ['auth']
})

export default new Vuex.Store({
  modules: {
    auth: auth,
    language: language,
    faqCategory: faqCategory,
    dynamicPage: dynamicPage,
    faq: faq,
    news: news,
    videos: videos,
    review: review,
    teachingLang: teachingLang,
    setting: setting,
    interesting: interesting,
    user: user,
    supportTicket: supportTicket,
    note: note,
    course: course,
    book: book,
    teachingVideo: teachingVideo,
    audio: audio,
    paymentMethod: paymentMethod,
    courseChat: courseChat,
    otherMaterial: otherMaterial,
    paymentHistory: paymentHistory,
    task: task,
    chatExpert: chatExpert,
    lesson: lesson,
    statistics: statistics,
    search: search,
    courseModuleTest: courseModuleTest,
    subscribe: subscribe,
    block: block,
    vocabulary: vocabulary,
    nextTaskId: nextTaskId,
    courseModule,
    loading,
    dictionary,
    phones,
    coupon,
    referralCoupon,
    tariff,
    contact
  },
  plugins: [dataState]
})
