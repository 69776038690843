<template>
  <div class="position-relative" :key="$route.params.taskId">
    <div class="personal-details statistics">
      <div>
        <div class="top-line">
          <div class="title-block">
            <div
              class="d-flex align-items-center justify-content-between"
              v-if="task.course_teaching_language"
            >
              <div class="d-flex align-items-center">
                <div class="flag">
                  <img :src="task.course_teaching_language.flag_path" alt="" />
                </div>
                <div>
                  <div class="tabs-title mb-0">
                    <span class="red-text">Урок {{ task.lesson_number }}:</span>
                    {{ task.data.lesson_name }}
                  </div>
                  <div class="subtitle">
                    {{ task.course_teaching_language.name[$i18n.locale] }}
                  </div>
                </div>
              </div>
              <button v-if="showMissButton" type="button" class="skip-button" @click="missTask">
                ПРОПУСТИТЬ ЗАДАНИЕ
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                  <g id="hover" transform="translate(-1040 -500)">
                    <circle cx="15" cy="15" r="15" transform="translate(1040 500)" fill="#ffffff" />
                    <g>
                      <g transform="matrix(0, -1, 1, 0, 765.814, 1056.932)">
                        <g>
                          <path
                            d="M541.953,292.157a.789.789,0,0,1-.606-.246q-1.577-1.572-3.158-3.142a.748.748,0,0,1,0-1.129l.193-.192a.759.759,0,0,1,1.142,0l2.312,2.3c.117.117.117.117.235,0l2.3-2.291a.759.759,0,0,1,1.149,0l.208.207a.744.744,0,0,1,0,1.1q-1.59,1.583-3.181,3.164A.776.776,0,0,1,541.953,292.157Z"
                            fill="#000"
                          />
                        </g>
                      </g>
                      <path
                        d="M1053.454,518.741l-.192-.193a.758.758,0,0,1,0-1.142l2.3-2.312c.116-.117.116-.117,0-.235l-2.291-2.3a.76.76,0,0,1,0-1.15l.208-.208a.744.744,0,0,1,1.1,0l3.163,3.182a.776.776,0,0,1,.24.566v.035a.789.789,0,0,1-.247.606q-1.571,1.577-3.142,3.158a.748.748,0,0,1-1.13,0Z"
                        fill="#000"
                      />
                    </g>
                  </g>
                </svg>
              </button>
            </div>
            <div class="row no-gutters mt-5">
              <div
                class="task-container p-16 d-flex align-items-center justify-content-between col-lg-9 font-weight-bold"
              >
                <div class="title w-75 h-100 d-flex flex-column">
                  <slot>
                    <div>
                      <span class="red-text task-number">Задание {{ task.task_number }}</span> /
                      <span class="task-number">{{ task.lesson_task_count }}</span>
                    </div>
                    <span class="task-description" v-if="task.data">{{
                      task.data.name[$i18n.locale]
                    }}</span>
                  </slot>
                </div>
                <div class="lesson-progress">
                  <span
                    >Баллы: <span class="red-text">{{ task.lesson_points }}</span></span
                  >
                  <button v-if="isDone" class="repeat-btn" type="button" @click="repeatTaskHandler">
                    {{ repeatButtonText }}
                    <img src="../../assets/images/icons/arrow-right.svg" alt="" />
                  </button>
                </div>
              </div>
              <div class="col-lg-3 d-flex align-items-center support-buttons">
                <div class="d-flex align-items-center ml-2 ml-md-5 support-item">
                  <DictionaryModal
                    :language_name="
                      task.course_teaching_language &&
                        task.course_teaching_language.name[$i18n.locale]
                    "
                    :lesson_id="task.data && task.data.user_task.lesson_id"
                    :lesson_number="task && task.lesson_number"
                    :lesson_name="task.data && task.data.lesson_name"
                  />
                  <span class="d-md-none">Грамматика</span>
                </div>
                <div class="d-flex align-items-center support-item">
                  <CircleButton class="ml-md-3">
                    <Notes img-height="30px" img-width="30px" />
                  </CircleButton>
                  <span class="d-md-none">Мой словарь</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loading v-if="$store.getters['task/taskLoading']" />
        <div v-else class="statistics-content">
          <div class="task-choose-translation">
            <VideoAudioGallery v-if="task" :data="task.data" class="mb-100" />
            <div
              class="task-description mt-4 mb-5"
              v-html="task.data.description[$i18n.locale]"
            ></div>
            <Translateable
              :lang="task.course_teaching_language"
              :lesson_id="task.data.user_task.lesson_id"
            >
              <slot name="desktop" />
            </Translateable>
          </div>
        </div>
        <div class="task-choose-translation-button-block btn-tasks">
          <b-tooltip
            v-if="promptTooltip"
            target="showPrompt"
            title="Tooltip title"
            triggers="hover"
          >
            {{ promptTooltip }}
          </b-tooltip>
          <button
            id="showPrompt"
            class="task-find-button-block-button-left"
            type="button"
            @click="showPrompt"
          >
            ПОКАЗАТЬ ПОДСКАЗКУ
          </button>
          <button
            v-if="checkTask && showCheckButton"
            class="task-choose-translation-button-block-button-right"
            :class="{ 'repeat-btn__bottom': isDone }"
            type="button"
            @click="isDone ? repeatTaskHandler() : checkTask()"
          >
            {{ isDone ? repeatButtonText : checkButtonText }}
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
              <g id="hover" transform="translate(-1040 -500)">
                <circle
                  cx="15"
                  cy="15"
                  r="15"
                  transform="translate(1040 500)"
                  :fill="isDone ? '#169F00' : '#cc3d44'"
                />
                <g>
                  <g transform="matrix(0, -1, 1, 0, 765.814, 1056.932)">
                    <g>
                      <path
                        d="M541.953,292.157a.789.789,0,0,1-.606-.246q-1.577-1.572-3.158-3.142a.748.748,0,0,1,0-1.129l.193-.192a.759.759,0,0,1,1.142,0l2.312,2.3c.117.117.117.117.235,0l2.3-2.291a.759.759,0,0,1,1.149,0l.208.207a.744.744,0,0,1,0,1.1q-1.59,1.583-3.181,3.164A.776.776,0,0,1,541.953,292.157Z"
                        fill="#040404"
                      />
                    </g>
                  </g>
                  <path
                    d="M1053.454,518.741l-.192-.193a.758.758,0,0,1,0-1.142l2.3-2.312c.116-.117.116-.117,0-.235l-2.291-2.3a.76.76,0,0,1,0-1.15l.208-.208a.744.744,0,0,1,1.1,0l3.163,3.182a.776.776,0,0,1,.24.566v.035a.789.789,0,0,1-.247.606q-1.571,1.577-3.142,3.158a.748.748,0,0,1-1.13,0Z"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <TaskAnswerModal ref="taskModal" />
  </div>
</template>
<script>
import TaskAnswerModal from './TaskAnswerModal'
import { mapActions, mapGetters } from 'vuex'
import VideoAudioGallery from './VideoAudioGallery'
import Loading from './Loading'
import Translateable from './Translateable'
import DictionaryModal from './DictionaryModal'
import Notes from '../components/Notes'
import CircleButton from './CircleButton'

export default {
  name: 'TaskBase',
  components: {
    CircleButton,
    Notes,
    DictionaryModal,
    Translateable,
    VideoAudioGallery,
    TaskAnswerModal,
    Loading
  },
  props: {
    checkTask: {
      type: [Function, Boolean],
      default: false
    },
    task: {
      type: [Object, Array],
      default: null
    },
    correctAnswer: {
      type: Function,
      required: true
    },
    checkTry: {
      type: Boolean,
      default: false
    },
    showCheckButton: {
      type: Boolean,
      default: true
    },
    checkButtonText: {
      type: String,
      default: 'ПРОВЕРИТЬ ЗАДАНИЕ'
    },
    repeatButtonText: {
      type: String,
      default: 'Пройти ещё раз'
    },
    promptTooltip: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      openPrompt: false
    }
  },
  computed: {
    ...mapGetters({
      try: 'task/try'
    }),
    showButtonPrompt() {
      if (!this.task.task.prompt) {
        return false
      }
      if (!this.task.task.prompt[this.$i18n.locale]) {
        return false
      }
      return true
    },
    hideProgressInfo() {
      return this.$route.query.type !== 'reply'
    },
    showMissButton() {
      const { next_task: nextTask } = this.task
      return (
        (nextTask.next_task_id || nextTask.next_block_id) &&
        this.hideProgressInfo &&
        nextTask.next_lesson_id === parseInt(this.$route.query.lesson)
      )
    },
    isDone() {
      console.log(this.task?.data?.user_task?.status)
      return this.task?.data?.user_task?.status === 'done'
    }
  },
  methods: {
    ...mapActions({
      repeatTask: 'task/repeatTask'
    }),
    missTask() {
      const { next_task: nextTask } = this.task
      if (nextTask.next_task_id) {
        this.$router.push({
          path: `/profile/task/${nextTask.next_task_type_id}/${nextTask.next_task_id}`,
          query: {
            lesson: nextTask.next_lesson_id,
            course: nextTask.next_course_id,
            module: nextTask.next_module_id,
            type: this.$route?.query?.type
          }
        })
      } else if (nextTask.next_block_id) {
        this.$router.push({
          name: 'block',
          params: { blockId: nextTask.next_block_id }
        })
      }
    },
    repeatTaskHandler() {
      this.repeatTask({ id: this.task.data.id }).then(res => {
        if (res) {
          location.reload()
        }
      })
    },
    showPrompt() {
      if (this.checkTry || this.try >= 3) {
        this.correctAnswer()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.task-find-button-block-button-left {
  height: 56px !important;
  padding: 20px;
  display: block;
}

.title {
  gap: 26px;
}
.repeat-btn {
  background: var(--green);
  border-radius: 24px;
  color: var(--white);
  padding: 6px 14px;
  font-size: 18px;
  border: 2px solid var(--green);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &__bottom {
    border: 2px solid var(--green);
    color: var(--green);
  }
  &__bottom:hover {
    background: var(--green);
  }

  img {
    border-radius: 16px;
    background: white;
    padding: 0;
    width: 24px;
    height: 24px;
  }
}

.mb-100 {
  margin-bottom: 100px !important;
}

$media-md: 991px;
$media-sm: 767px;

.p-16 {
  padding: 16px !important;
}

.statistics-content {
  margin-bottom: 150px !important;

  @media (max-width: 1440px) {
    margin-bottom: 100px !important;
  }

  @media (max-width: 1999px) {
    margin-bottom: 50px !important;
  }
}

.task-container {
  font-size: 18px;
  border-radius: 32px;
  @media (max-width: $media-md) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media (max-width: $media-sm) {
    flex-direction: column;
    justify-content: start !important;
    align-items: start !important;

    div {
      width: auto !important;
    }
  }

  .task-number {
    font-size: 16px;

    @media (max-width: $media-md) {
      font-size: 14px;
    }

    @media (max-width: $media-sm) {
      font-size: 12px;
    }
  }

  .task-description {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    @media (max-width: $media-md) {
      font-size: 16px;
      margin-top: 10px;
    }
    @media (max-width: $media-sm) {
      font-size: 14px;
      margin-top: 5px;
      margin-bottom: 50px;
    }
  }

  .lesson-progress {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-style: normal;
    height: 100%;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: $media-md) {
      flex-direction: column;
    }

    @media (max-width: $media-sm) {
      flex-direction: row;
      justify-content: start;
    }

    div {
      &:nth-child(1) {
        border-right: 1px solid #e2e2e2;
        padding-right: 15px;
        margin-right: 15px;

        @media (max-width: $media-md) {
          border-right: none;
          padding-right: 0;
          margin-right: 0;
          border-bottom: 1px solid #e2e2e2;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }

        @media (max-width: $media-sm) {
          border-right: 1px solid #e2e2e2;
          padding-right: 30px;
          margin-right: 30px;
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: 0;
        }
      }
    }
  }
}

.support-buttons {
  @media (max-width: $media-md) {
    background-color: white;
    border-bottom-left-radius: 44px;
    border-bottom-right-radius: 44px;
    border-top: 2px solid #eff0f6;
    justify-content: space-between;
  }

  .support-item {
    @media (max-width: $media-md) {
      width: 50%;
      padding: 0 20px 0 5px;
      font-size: 12px;
      font-weight: bold;
      justify-content: start;
      text-transform: uppercase;

      &:first-child {
        border-right: 1px solid #eff0f6;
      }

      &:last-child {
        border-left: 1px solid #eff0f6;
      }
    }

    @media (max-width: $media-sm) {
      justify-content: center;
      span {
        display: none;
      }
    }
  }
}

.red-text {
  color: var(--secondery);
}

.task-choose-translation {
  font-size: 24px;
  font-weight: 600;
}

.skip-button {
  background-color: #e6eafc;
  border-radius: 25px;
  padding: 7px 7px 7px 20px;
  border: none;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: linear-gradient(268.08deg, #e7eafd 0%, #f4f4f4 100%);
  }
}

.btn-tasks {
  margin-bottom: 50px;

  @media (max-width: $media-sm) {
    flex-direction: column;
  }

  button {
    @media (max-width: $media-sm) {
      width: 100%;

      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
