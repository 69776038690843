<template>
  <div class="w-100 search-page-wrapper">
    <bread-crumbs :pages="pages" />
    <div class="search-page page-container">
      <div class="d-flex w-100 search-form">
        <input
          type="text"
          placeholder="Введите что хотите найти…"
          v-on:keyup.enter="search"
          v-model="filterData.searchValue"
        />
        <button type="button" @click="search">
          <img src="@/assets/images/icons/search-white.png" alt="search" />
        </button>
      </div>
      <div class="search-answer-block" v-if="searchAnswersList">
        <div class="item" v-for="(item, index) in searchAnswersList" :key="index">
          <div class="title">
            {{ item.type === 'course' ? item.name[$i18n.locale] : item.title[$i18n.locale] }}
          </div>
          <!-- <div class="subtitle">{{item.type === 'course' ? 'Курсы' : item.type === 'interesting' ? 'Интересное' : 'Новости'}}</div> -->
          <div class="text">
            {{
              item.type === 'course'
                ? item.description[$i18n.locale]
                : item.short_description[$i18n.locale]
            }}
          </div>
          <router-link :to="'/' + item.type + '/' + item.id" class="more"
            >ЧИТАТЬ ДАЛЬШЕ<span>&raquo;</span></router-link
          >
        </div>
      </div>
      <div v-else class="d-flex justify-content-center align-items-center h3">
        Поиск не дал результатов
      </div>
      <div class="search-pagination" v-if="pagination && pagination.count_pages > 1">
        <b-pagination
          v-model="currentPage"
          :total-rows="pagination.count_pages"
          :per-page="1"
          first-number
          last-number
          prev-text="«"
          next-text="»"
          @input="clickPagination"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BreadCrumbs from '../../components/BreadCrumbs'
export default {
  name: 'Search',
  data() {
    return {
      currentPage: 1,
      pages: [
        { path: '/', rout: 'Главная', active: false },
        { path: '', rout: 'Поиск', active: true }
      ],
      filterData: {
        page: 1,
        searchValue: ''
      }
    }
  },
  components: {
    BreadCrumbs
  },
  computed: {
    ...mapState({
      searchAnswersList: state => state.search.searchAnswersList,
      pagination: state => state.search.pagination
    })
  },
  mounted() {
    if (this.$route.query.value) {
      this.filterData.searchValue = this.$route.query.value
      this.getSearchAnswersList(this.filterData).then(() => {})
    }
  },
  methods: {
    ...mapActions({
      getSearchAnswersList: 'search/getSearchAnswersList'
    }),
    search() {
      this.getSearchAnswersList(this.filterData).then(() => {})
    },
    clickPagination() {
      this.filterData.page = this.currentPage
      this.getSearchAnswersList(this.filterData).then(() => {})
    }
  }
}
</script>

<style scoped>
.search-page-wrapper {
  min-height: 300px;
}
</style>
