import { http } from '../../../api'
import { commit } from 'lodash/seq'

const teachingLang = {
  namespaced: true,
  state: {
    teachinglangs: [],
    langs: []
  },
  actions: {
    getTeachingLangs: ({ commit }) => {
      return http.get('/teaching-languages').then(res => {
        commit('setTeachingLangs', res.data.data)
      })
    },
    get({ commit }){
      return http.get('/teaching-languages/get').then(res => {
        commit('setLangs', res.data.data)
      })
    }
  },
  mutations: {
    setTeachingLangs (state, data) {
      state.teachinglangs = data
    },
    setLangs(state,data){
      state.langs = data
    }
  },
  getters: {
    teachinglangs: state => state.teachinglangs,
    langs: state => state.langs
  }
}

export default teachingLang
