<template>
  <div class="video col-12 px-0">
    <bread-crumbs :pages="pages"/>
    <div class="video-block page-container">
      <div class="video-block-title-block">
        <div class="video-block-title-block-title">Новости Language to go</div>
        <div class="video-block-title-block-subtitle subtitle-blue">Будьте в курсе наших событий. Следите за нашими новостями</div>
      </div>
      <div class="video-block-videos row" v-if="allNews.length > 0">
        <NewsVideoCard v-for="news in allNews" :key="news.id" :news="news"/>
      </div>
      <div class="mt-3" v-if="pagination.count_pages > 1">
        <b-pagination
          v-model="currentPage"
          :total-rows="pagination.count_pages"
          :per-page="1"
          first-number
          last-number
          prev-text="«"
          next-text="»"
          @input="clickPagination"
        ></b-pagination>
      </div>
    </div>
    <div class="news-form">
      <div class="page-container news-form-container">
        <div class="title">Хотите быть в курсе новостей?</div>
        <div class="subtitle">Подпишитесь на рассылку наших новостей.</div>
        <ValidationObserver ref="subscribeAddForm" id="subscribeAddForm" tag="div" class="form-block">
          <div class="form-group row">
            <div class="col-8 row">
              <ValidationProvider name="name" rules="required" tag="div" v-slot="{ classes }" class="col-12 col-sm">
                <input class="col-12 col-sm" type="text" v-model="formData.name" name="name" :class="classes" autocomplete="off" placeholder="Ваше имя">
              </ValidationProvider>
              <ValidationProvider name="email" rules="required|email" tag="div" v-slot="{ classes }" class="col-12 col-sm">
                <input class="col-12 col-sm" v-model="formData.email" type="email" name="email" :class="classes" autocomplete="off" placeholder="E-mail">
              </ValidationProvider>
            </div>
            <button type="button" class="btn-white-outline col-4" @click="formSubmit" :disabled="disabledSubmitBtn" :class="disabledSubmitBtn ? 'btn-disabled' : ''">подписаться на новости</button>
          </div>
          <ValidationProvider v-slot="{ errors, validate }" tag="div" rules="required"  class="checkbox">
            <label for="privacy" class="remember">
              <input :true-value="1" :false-value="0" @change="validate(false)" v-model="formData.privacy" type="checkbox" id="privacy" name="checkbox">
              <span></span>
              <div>Отправляя заявку, я соглашаюсь с <a target="_blank" href="/privacy-policy">политикой конфиденциальности</a> и <a target="_blank" href="/terms-of-use">пользовательским соглашением</a> сайта</div>
            </label>
            <small v-if="errors[0] || formData.privacy === 0" class="text-danger font-italic position-absolute d-conte">Вы не согласились с Правилами</small>
          </ValidationProvider>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>

import BreadCrumbs from '../../components/BreadCrumbs'
import { mapState, mapActions } from 'vuex'
import NewsVideoCard from '../../components/NewsVideoCard'

export default {
  name: 'News',
  components: {
    NewsVideoCard,
    BreadCrumbs
  },
  data () {
    return {
      formData: {
        name: '',
        email: ''
      },
      disabledSubmitBtn: false,
      currentPage: 1,
      pages: [
        { path: '/', rout: 'Главная', active: false },
        { path: '', rout: 'Новости', active: true }
      ]
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.query.page) {
      this.currentPage = to.query.page
      this.getNews({ page: to.query.page, count: 12 })
    } else {
      this.currentPage = 1
      this.getNews({ page: 1, count: 12 })
    }
    next()
  },
  created () {
    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page
      this.getNews({ page: this.$route.query.page, count: 12 })
    } else {
      this.getNews({ page: this.currentPage, count: 12 })
    }
  },
  computed: {
    ...mapState({
      allNews: state => state.news.allNews,
      pagination: state => state.news.pagination
    })
  },
  metaInfo () {
    const title = this.$t('news.title')
    return {
      title: title,
      meta: [
        {
          name: 'og:title',
          content: title
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getNews: 'news/getNews',
      subscribe: 'subscribe/subscribe'
    }),
    formSubmit () {
      this.$refs.subscribeAddForm.validate().then(success => {
        if (success) {
          this.disabledSubmitBtn = true
          if (!this.formData.privacy) {
            return false
          }
          this.subscribe(this.formData).then(() => {
            this.$toasted.success('Подписка оформлена успешно!').goAway(1500)
            this.formData = {}
            this.$refs.subscribeAddForm.reset()
          }).catch(err => {
            if (err.response.status === 422 && err.response.data.email) {
              const data = err.response.data.email
              const keys = Object.keys(data)
              const msg = data[keys[0]]
              this.$toasted.error(msg).goAway(2000)
              return false
            } else {
              this.$toasted.error('Server error!').goAway(2000)
            }
          }).finally(() => {
            this.disabledSubmitBtn = false
          })
        } else {
          this.$toasted.error('Есть незаполненные поля').goAway(1500)
        }
      })
    },
    clickPagination () {
      this.$router.push('/news?page=' + this.currentPage)
      window.scrollTo(0, 0)
    }
  }
}
</script>
