<template>
  <!-- TODO: correct class -->
  <div
    class="main mb-5"
    :class="{
      'margin-auto': taskPresentation === 2,
      'align-items-center': taskPresentation === 2
    }"
  >
    <div class="img mb-4" v-if="question.image">
      <img class="image" :src="question.image" alt="image" />
    </div>

    <div class="video mb-4" v-if="question.video || question.video_iframe">
      <VideoPlayer :video="{ video_path: question.video, video_iframe: question.video_iframe }" />
    </div>

    <div class="main-audio mb-4" v-if="question.audio">
      <mini-audio :audio-source="question.audio"></mini-audio>
    </div>

    <div class="mb-4">{{ question.prompt }}</div>

    <div class="d-flex flex-row gap mb-4">
      <div v-if="question.on_sound === 1" class="mr-4">
        <PlayerButton
          color="var(--primary)"
          :answer="question.answer"
          :lang="lang"
          :height="'40px'"
          :width="'40px'"
        />
      </div>
      <div v-if="hasSelectBtn" class="btn btn__container d-flex flex-row">
        <div
          class="btn btn__item"
          :class="{ active: activeBlock === 'text' }"
          @click="changeCollapse('text')"
        >
          Написать текстом
        </div>
        <div
          class="btn btn__item"
          :class="{ active: activeBlock === 'voice' }"
          @click="changeCollapse('voice')"
        >
          Записать аудио
        </div>
      </div>
    </div>

    <div
      class="d-flex align-items-start w-100"
      :class="{ 'justify-content-center': taskPresentation === 2 }"
    >
      <input
        v-if="activeBlock === 'text'"
        :class="{
          'custom-input': true,
          success: answer.success,
          error: answer.error
        }"
        :value="answer.text_answer"
        @input="changeText($event)"
        placeholder="Запишите ответ"
      />

      <div
        v-if="activeBlock === 'voice'"
        class="audio__container w-100 d-flex align-items-start "
        :class="{ 'justify-content-center': taskPresentation === 2 }"
      >
        <div class="audio__wrapper">
          <div
            v-if="hasRecord"
            class="btn-voice btn-voice_primary d-flex align-items-start justify-content-start position-absolute zindex-0"
            :class="{
              success: answer.success && hasRecord,
              error: answer.error
            }"
          >
            <img class="mr-4" src="~@/assets/images/icons/music.svg" alt="music" />
            Записано
          </div>
          <div
            class="btn-voice cursor-pointer d-flex align-items-start justify-content-start"
            :class="{
              active: isSpeaking,
              'has-record': hasRecord && !isSpeaking
            }"
            @mousedown="start"
            @mouseup="stop"
            v-touch:start="start"
            v-touch:end="stop"
          >
            <img
              v-if="!isSpeaking"
              class="mr-4"
              src="~@/assets/images/icons/microphone-primary.svg"
              alt="microphone"
            />
            <img
              v-if="isSpeaking"
              class="mr-4"
              src="~@/assets/images/icons/pause.svg"
              alt="pause"
            />
            {{ isSpeaking ? 'Остановить' : 'Записать' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from '../../../../components/VideoPlayer.vue'
import PlayerButton from '../../../../components/PlayerButton'

export default {
  name: 'RepeatTextTemplate',
  components: {
    PlayerButton,
    VideoPlayer
  },
  props: {
    question: {
      type: Object
    },
    answer: {
      type: Object
    },
    lang: {
      type: String,
      default: 'ru'
    },
    // TODO: привести к единому виду
    taskPresentation: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isSpeaking: false,
      recognition: null,
      hasRecord: false,
      activeBlock: 'text' // text || voice
    }
  },
  created() {
    this.recognitionInit()
    this.changeCollapse(this.question.can_text_input ? 'text' : 'voice')
  },
  computed: {
    hasSelectBtn() {
      return this.question.can_text_input && this.question.can_voice_recording
    }
  },
  methods: {
    changeCollapse(type) {
      this.activeBlock = type
    },
    start() {
      if (!this.recognition) {
        return false
      }

      this.isSpeaking = true
      this.hasRecord = false
      this.error = false

      this.recognition.addEventListener('result', e => {
        this.answer.voice_answer = Array.from(e.results)
          .map(result => result[0])
          .map(result => result.transcript)
          .join('')
      })

      this.recognition.onend = () => {
        setTimeout(() => {
          this.isSpeaking = false
        }, 1000)
      }

      this.recognition.start()
    },
    stop() {
      this.recognition.abort()
      this.hasRecord = !!this.answer.voice_answer
      this.answer.has_answer = !!this.answer.voice_answer || !!this.answer.text_answer
    },
    recognitionInit() {
      if (!window.SpeechRecognition) {
        if (!window.webkitSpeechRecognition) {
          return null
        }

        window.SpeechRecognition = window.webkitSpeechRecognition
      }

      this.recognition = new window.SpeechRecognition()
      this.recognition.interimResults = true
      this.recognition.continuous = true
      this.recognition.lang = this.lang
    },
    changeText(event) {
      const text = event.target.value
      this.answer.text_answer = text
      this.answer.has_answer = !!this.answer.voice_answer || !!this.answer.text_answer
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../../scss/variables';
.main {
  padding: 0 5px;
  display: flex;
  flex-direction: column;
}
.margin-auto {
  margin: auto;
}
.custom-input {
  background: transparent;
  border: 1px solid var(--gray);
  padding: 19px 17px;
  border-radius: 16px;
  font-size: 18px;
  max-width: 720px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    border: 2px solid var(--primary);
    padding: 18px 16px;
  }

  &.success {
    background: var(--success-light);
    border: 2px solid var(--success);
  }

  &.error {
    background: var(--error-light);
    border: 2px solid var(--error);
    color: var(--black);
  }
}

.audio {
  &__container {
    position: relative;
  }
  &__wrapper {
    position: relative;
    max-width: 410px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
  }
}

.btn-voice {
  background-color: var(--light-blue);
  font-size: 18px;
  font-weight: 600;
  height: 64px;
  width: 100%;
  color: var(--primary);
  border-radius: 24px;
  padding: 20px 16px;
  &_primary {
    background-color: var(--primary);
    color: var(--white);
  }

  &.active {
    background-color: var(--primary);
    color: var(--white);
  }

  &.has-record {
    flex-basis: 87%;
    z-index: 1;
  }

  &.success {
    background-color: var(--success);
  }

  &.error {
    background-color: var(--error);
  }
}

.btn {
  border-radius: 24px;
  &__container {
    background-color: var(--light-blue-2);
    padding: 2px;
  }
  &__item {
    background-color: transparent;
    display: flex;
    align-items: center;
  }
  .active {
    background-color: var(--white);
  }
}
.image,
.main-audio {
  height: auto;
  width: 100%;
  max-width: 600px;
  max-height: 600px;
}
.video {
  max-width: 800px;
  max-height: 800px;
  width: 100%;
}
</style>
