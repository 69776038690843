<template>
    <div class="container-fluid auth-user">
      <div v-click-outside="hide">
        <ProfileHeader/>
        <ProfileSidebar />
      </div>
      <main class="main-content" id="main">
          <router-view/>
          <ProfileFooter/>
      </main>
    </div>
</template>

<script>
import ProfileHeader from '../components/Profile/Header'
import ProfileFooter from '../components/Profile/Footer'
import ProfileSidebar from '../components/Profile/Sidebar'
import ClickOutside from 'vue-click-outside'
import EventBus from '../../../utility/event-bus'

export default {
  name: 'AuthLayout',
  data () {
    return {
      isOpenMenu: false
    }
  },
  methods: {
    hide () {
      EventBus.$emit('toggleMenu', this.isOpenMenu = false)
    }
  },
  directives: {
    ClickOutside
  },
  components: {
    ProfileFooter,
    ProfileHeader,
    ProfileSidebar
  },
  data () {
    return {
      isOpenMenu: false
    }
  },
  methods: {
    hide () {
      EventBus.$emit('toggleMenu', this.isOpenMenu = false)

      // document.getElementsByTagName('body')[0].style.overflowY = 'hidden'
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
<style>
</style>
