<template>
  <TaskBase :task="task" :check-task="searchLetter" :correct-answer="correctAnswer">
    <template #desktop>
      <div class="row gallows-wrapper">
        <div class="lg-lg-6 lg-md-12">
          <div class="letters-input d-flex">
            <div class="d-flex justify-content-start flex-wrap">
              <Item
                v-for="(letter, index) in wordEmptyArray"
                :key="index"
                :dashed="letter === null"
                :letter="letter"
                class="mr-2 mt-2"
              />
            </div>
          </div>
          <div class="letters">
            <Item
              class="mr-2 mt-2"
              v-for="(letter, index) in letters"
              :key="index"
              @click.native="searchLetter(letter)"
              :letter="letter"
              :success="verifiedLetters.includes(letter) && wordEmptyArray.includes(letter)"
              :error="wrongLetters.includes(letter)"
            />
          </div>
        </div>
        <div class="game game-task-letter lg-lg-5 lg-md-12">
          <div class="image gallows-image gallows-margin">
            <div class="part1" :class="{'bg-black':wrongLetters.length > 0}"></div>
            <div class="part2" :class="{'bg-black':wrongLetters.length > 1}"></div>
            <div class="part3" :class="{'bg-black':wrongLetters.length > 2}"></div>
            <div class="part4" :class="{'bg-black':wrongLetters.length > 3}"></div>
            <div class="part5" :class="{'bg-black':wrongLetters.length > 4}"></div>
            <div class="part6" :class="{'bg-black':wrongLetters.length > 5}"></div>
            <div class="part7" :class="{'bg-black':wrongLetters.length > 6}"></div>
            <div class="part8" :class="{'bg-black':wrongLetters.length > 7}"></div>
            <div class="part9" :class="{'bg-black':wrongLetters.length > 8}"></div>
            <div class="part10" :class="{'bg-black':wrongLetters.length > 9}"></div>
            <div class="part11" :class="{'bg-black':wrongLetters.length > 10}"></div>
            <!--            <div class="part12" :class="{'bg-black':wrongLetters.length > 11}"><img-->
            <!--              src="../../../../../assets/images/task-images/gallows_smile.svg" alt=""></div>-->
          </div>
        </div>
      </div>
    </template>
  </TaskBase>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TaskAnswerModal from '../../../../components/TaskAnswerModal'
import VideoAudioGallery from '../../../../components/VideoAudioGallery'
import TaskBase from '../../../../components/TaskBase'
import TaskCheckParamsMixin from '../../../../../mixins/TaskCheckParamsMixin'
import Item from '../../../../components/Tasks/Gallows/Item'

export default {
  name: 'Game',
  mixins: [TaskCheckParamsMixin],
  data () {
    return {
      data: null,
      openPrompt: false,
      gameOver: false,
      letters: '',
      word: 'word',
      wordEmptyArray: [],
      wordArray: [],
      verifiedLetters: [],
      wrongLetters: [],
      buttonDisabled: false
    }
  },
  computed: {
    ...mapState({
      task: state => state.task.task,
      nextTask: state => state.course.nextTask
    })
  },
  components: {
    Item,
    TaskBase,
    VideoAudioGallery,
    TaskAnswerModal
  },
  beforeRouteUpdate (to, from, next) {
    if (to.fullPath !== from.fullPath) {
      Object.assign(this.$data, this.$options.data())
      this.getTask({ id: to.params.taskId }).then(res => {
        this.wordEmptyArray = this.task.task.word_array
      })
    }
    next()
  },
  created () {
    this.getData()
  },
  methods: {
    ...mapActions({
      getTask: 'task/getTask',
      missingTask: 'course/missingTask',
      getMissingTask: 'course/getMissingTask',
      checkGallowsLetter: 'task/checkGallowsLetter',
      setTaskAnswerModal: 'task/setTaskAnswerModal'
    }),
    getData () {

      const query = {}
      if (this.$route.query.type) {
        query.type = this.$route.query.type
      }

      this.getTask({
        id: this.$route.params.taskId,
        query
      }).then(res => {
        this.data = res.data
        this.wordEmptyArray = []
        this.verifiedLetters = []
        this.wrongLetters = []
        this.gameOver = false
        this.letters = this.task.course_teaching_language.letters_array
        this.wordEmptyArray = this.task.task.word_array
      })
    },
    missTask () {
      this.missingTask({ task_id: this.$route.params.taskId })
    },
    correctAnswer () {
      this.wordEmptyArray = this.task.task.word.toUpperCase().split('')
      this.wordEmptyArray.forEach(word => {
        if (!this.verifiedLetters.includes(word)) {
          this.verifiedLetters.push(word)
        }
      })
    },
    searchLetter (letter) {
      if (!this.buttonDisabled) {
        if (!this.gameOver) {
          if (!this.verifiedLetters.includes(letter)) {
            this.buttonDisabled = true
            this.checkGallowsLetter({
              letter: letter,
              word_arr: this.wordEmptyArray,
              ...this.checkParams(),
            }).then(res => {
              this.verifiedLetters.push(letter)
              console.log('letter_before')
              if (res.data.data.letter_includes) {
                console.log('letter_includes')
                this.wordEmptyArray = res.data.data.word_array
                if (!this.wordEmptyArray.includes(null)) {
                  console.log('modal')
                  this.setTaskAnswerModal(res.data).then(() => {
                    this.$root.$emit('bv::show::modal', 'modal-task')
                  })
                  this.gameOver = 'win'
                }
              } else {
                this.wrongLetters.push(letter)
                if (this.wrongLetters.length > 11) {
                  this.setTaskAnswerModal(false).then(() => {
                    this.$root.$emit('bv::show::modal', 'modal-task')
                  })
                  this.getData()
                  this.gameOver = 'lose'
                }
              }
            }).finally(() => {
              this.buttonDisabled = false
            })
          }
        } else {
          if (this.gameOver === 'win') {
            this.setTaskAnswerModal(1).then(() => {
              this.$root.$emit('bv::show::modal', 'modal-task')
            })
          } else {
            this.setTaskAnswerModal(false).then(() => {
              this.$root.$emit('bv::show::modal', 'modal-task')
            })
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
.gallows-margin {
  @media (min-width: 1440px) {
    margin-left: 168px;
  }

  @media (min-width: 1920px) {
    margin-left: 280px;
  }

}

.letters-input{
  flex-wrap: wrap;
  @media (max-width: 1439px) {
    justify-content: center;
  }
}

.gallows-wrapper {
  margin: 0 !important;

  @media (max-width: 1439px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 767px) {
    padding-left: 23px;
    padding-right: 22px;
  }

  @media (min-width: 1440px) {
    flex-direction: row;
  }
}

.game-task-letter {
  @media (max-width: 1439px) {
    display: flex;
    justify-content: center;
  }
}


.gallows-image {
  div {
    background-color: #E9EAF0;
  }

  .bg-black {
    background-color: black;
    border-color: black !important;
  }
}
</style>
