<template>
  <div class="position-relative" ref="parent">
    <slot/>
    <transition name="fade">
      <div class="words-modal" :class="{'images-modal': isImages}" v-if="open" :style="{top, maxHeight}" ref="modal">
        <ul v-if="type === 'text'">
          <li
            v-for="(word, index) in words"
            :key="index"
            :style="listStyle"
            :class="{'zebra':isZebra}"
          >
            <slot :word="word" :index="index" name="list">
              <StatusBox
                class="statuses"
                :success=" word[success] || checkUsed(word,index)"
                :error="word[error] === false"
              />
              <span @click="() => $emit('pickWord', word, index)">{{ word[label] }}</span>
            </slot>
          </li>
        </ul>
        <div v-else class="d-flex flex-wrap justify-content-between images-content">
          <CategorizeItem
            v-for="(word, index) in words"
            @click.native="() => $emit('pickWord', word, index)"
            :key="word.id"
            :success="word[success] || positions[word.id] && word[error] !== false"
            :error="word[error] === false && word[label]"
            type="images"
            :image="word[label]"
            class="mb-15"
          />
        </div>
        <slot name="afterList"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import CategorizeItem from './Categorize/CategorizeItem'
import CheckWidthMixin from '../../../mixins/CheckWidthMixin'
import StatusBox from './Common/StatusBox'

export default {
  name: 'WordModal',
  mixins: [CheckWidthMixin],
  components: { StatusBox, CategorizeItem },
  props: {
    words: {
      type: [Array, Object],
      default: []
    },
    label: {
      type: String,
      default: 'name'
    },
    success: {
      type: String,
      default: 'success'
    },
    error: {
      type: String,
      default: 'error'
    },
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    used: {
      type: String,
      default: 'used'
    },
    positions: {
      type: [Object, Array],
      required: false
    },
    groupValue: {
      type: [String, Number],
      default: ''
    },
    group: {
      type: String,
      default: ''
    },
    listStyle: {
      type: Object,
      required: false
    },
    isZebra: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isImages () {
      return this.type === 'images'
    }
  },
  data () {
    return {
      top: 0,
      open: false,
      maxHeight: '100%'
    }
  },
  mounted () {
    this.listener()
  },
  watch: {
    show (newVal) {
      if(!this.isMobile) return

      if (newVal) {
        this.modalOpen()
      } else {
        this.modalClose()
      }
    },
  },
  methods: {
    groupCheck (position) {
      if (!position[this.group]) return true

      return position[this.group] === this.groupValue
    },
    checkUsed (word, index) {
      return this.positions[word.id] && this.groupCheck(this.positions[word.id]) && word[this.error] !== false
    },
    getOffsetSum (elem) {
      let top = 0,
        left = 0

      while (elem) {
        top = top + parseInt(elem.offsetTop)
        left = left + parseInt(elem.offsetLeft)
        elem = elem.offsetParent
      }

      return {
        top: top,
        left: left
      }
    },
    modalClose () {
      this.open = false
      document.getElementById('main').classList.remove('overflow-hidden')
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden')
      this.$emit('update:show', false)
    },
    modalOpen () {
      document.getElementById('main').classList.add('overflow-hidden')
      document.getElementsByTagName('body')[0].classList.add('overflow-hidden')
    },
    listener () {
      if (!this.isMobile) return

      const wordsParent = this.$refs.parent

      if (!wordsParent) {
        return
      }

      wordsParent.addEventListener('click', e => {
        const el = e.target

        if (!el.classList.contains('wordable') && !el.closest('.wordable') && !el.classList.contains('slide-nav') && !el.closest('.slide-nav')) {
          this.modalClose()
          return false
        }

        let targetElement = el.classList.contains('wordable') ? el : el.closest('.wordable')

        this.top = targetElement.offsetTop + targetElement.offsetHeight + 'px'

        this.$nextTick(() => {
          this.open = true

          this.$nextTick(() => {
            const el = document.querySelector('#main')
            const scrollTop = el.scrollTop === 0 ? document.body.scrollTop : el.scrollTop
            const currentBottom = el.offsetTop + scrollTop + window.outerHeight
            const modalPosition = this.getOffsetSum(this.$refs.modal)
            this.maxHeight = currentBottom - modalPosition.top - 120 + 'px'
          })
        })
      })
    },
  }
}
</script>

<style scoped lang="scss">
.mb-15 {
  margin-bottom: 15px;
}

.statuses{
  position: absolute;
  left: 30px;
}

.fade-enter-active, .fade-leave-active {
  transition: transform 0.2s ease;
}

.fade-enter, .fade-leave-to {
  transform: translateY(100%);
  transition: all 150ms ease-in 0s
}

.images-content {
  border-radius: 30px;
  padding: 26px 20px;
  background-color: white;
}

.images-modal {
  border-radius: 30px;
  background-color: transparent !important;
  padding: 0 0 0 20px;

  @media (max-width: 576px) {
    width: calc(100% + 40px);
    margin-left: -20px
  }

  @media (max-width: 767px) {
    width: calc(100% + 55px);
    margin-left: -40px;
  }
}

.words-modal {
  background: #FFFFFF;
  position: absolute;
  width: 100%;
  z-index: 999;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 576px) {
    width: calc(100% + 40px);
    margin-left: -20px
  }

  @media (max-width: 767px) {
    width: calc(100% + 55px);
    margin-left: -40px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      height: 60px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      text-transform: uppercase;
      padding: 20px 30px;
      position: relative;

      &.zebra{
        &:nth-of-type(2n) {
          background: #F5F6FB;
        }
      }
    }
  }
}
</style>
