import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      boughtCoursesList: 'course/boughtCoursesList'
    }),
  },
  methods: {
    showTariff (course_id, tariff_id) {
      const course = this.boughtCoursesList.find(course => course.course.id === course_id)
      if (!course) {
        return true
      }
      return course.course_tariff_id !== tariff_id;
    },
  }
}
