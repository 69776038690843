<template>
  <div class="course personal-details" v-if="course">
    <div class="tabs-title mb-4">
      Доступные курсы
    </div>
    <router-link to="/profile/courses/main" class="back-to">
      <img src="~@/assets/images/profile/back-icon.svg" alt="">
      <span>НАЗАД КО ВСЕМ БЕСПЛАТНЫМ УРОКАМ</span>
    </router-link>
    <div class="top-details">
      <div class="title">
        <img v-if="course.teaching_language" :src="course.teaching_language.flag" alt="">
        <span>{{ course.name && course.name[$i18n.locale] }}</span>
      </div>
      <div class="action">
        <!--  v-b-modal.packages-prices open packages and prices modal -->
        <button type="button" class="buy-course-btn" @click="showBuyingCourseModal" v-if="!course.buy">купить курс</button>
      </div>
    </div>
    <div class="course-header">
      <div class="course-video">
        <template v-if="course.announcement && (course.announcement.video || course.announcement.video_iframe)">
          <video class="video" width="100%" height="380" controls
                 v-if="course.announcement && course.announcement.video">
            <source :src="course.announcement.video" type="video/mp4">
            Your browser does not support the video tag.
          </video>
          <div class="video" v-if="course.announcement && course.announcement.video_iframe">
            <div v-if="course.announcement && course.announcement.video_iframe !== null"
                 v-html="course.announcement.video_iframe" class="video-iframe"></div>
          </div>
          <div class="test-action mobile-invisible"
               v-if="course.course_test && course.course_test.length && course.course_test[0].tasks.length">
            <button type="button" class="buy-course-btn" v-b-modal.pass-test>
              <img src="~@/assets/images/profile/test.svg" alt="">
              <span>ПРОЙТИ ТЕСТ НА УРОВЕНЬ ЗНАНИЙ</span>
            </button>
          </div>
        </template>
        <template v-else>
            <img v-if="course.image_path" :src="course.image_path" alt="poster" class="mx-auto d-block">
        </template>
      </div>
      <div class="course-block-container-description-block">
        <div class="course-block-container-description-block-description"
             v-html="course.description && course.description[$i18n.locale]"></div>
      </div>
      <div v-if="course.course_test && course.course_test.length && course.course_test[0].tasks.length" class="test-action mobile-visible">
        <button type="button" class="buy-course-btn" v-b-modal.pass-test>
          <img src="~@/assets/images/profile/test.svg" alt="">
          <span>ПРОЙТИ ТЕСТ НА УРОВЕНЬ ЗНАНИЙ</span>
        </button>
      </div>
    </div>
    <div class="course-block-container">
      <div class="course-block-container-category">
        <div class="course-title">Для кого этот курс?</div>
        <div class="course-subtitle">Мы разделили курсы на уровни, чтобы любой мог начать с того уровня знаний который
          ему подходит
        </div>
        <div class="course-block-container-category-cards">
          <div class="course-block-container-category-cards-card" v-if="course.course_level_id === 1">
            <img class="course-block-container-category-cards-card-image" src="~@/assets/images/icons/junior.png"
                 alt="">
            <div class="course-block-container-category-cards-card-title">Начинающий</div>
            <div class="course-block-container-category-cards-card-subtitle">Для тех кто только хочет начать обучение
              языка
            </div>
            <div class="course-block-container-category-cards-card-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam augue elit, consequat sit amet aliquam eu,
              tincidunt quis ipsum. Sed non libero turpis. Nam metus est, ultricies tempus viverra vel, ornare eu purus.
              Proin eu justo suscipit, blandit neque a, cursus leo. Vestibulum ultrices lacus euismod eros efficitur,
              quis consequat massa hendrerit.
            </div>
            <div class="bottom-action">
              <router-link :to="'/profile/free-lesson/' + course.lesson_id" :disabled="!course.is_free_lesson"
                           :event="course.is_free_lesson ? 'click' : ''">ПРОЙТИ БЕСПЛАТНЫЙ УРОК
              </router-link>
            </div>
          </div>
          <div class="course-block-container-category-cards-card" v-else-if="course.course_level_id === 2">
            <img class="course-block-container-category-cards-card-image" src="~@/assets/images/icons/middle.png"
                 alt="">
            <div class="course-block-container-category-cards-card-title">Средний</div>
            <div class="course-block-container-category-cards-card-subtitle">Для тех кто хочет продолжить обучение
              языка
            </div>
            <div class="course-block-container-category-cards-card-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam augue elit, consequat sit amet aliquam eu,
              tincidunt quis ipsum. Sed non libero turpis. Nam metus est, ultricies tempus viverra vel, ornare eu purus.
              Proin eu justo suscipit, blandit neque a, cursus leo. Vestibulum ultrices lacus euismod eros efficitur,
              quis consequat massa hendrerit.
            </div>
            <div class="bottom-action">
              <router-link :to="'/profile/free-lesson/' + course.lesson_id" :disabled="!course.is_free_lesson"
                           :event="course.is_free_lesson ? 'click' : ''">ПРОЙТИ БЕСПЛАТНЫЙ УРОК
              </router-link>
            </div>
          </div>
          <div class="course-block-container-category-cards-card" v-else>
            <img class="course-block-container-category-cards-card-image" src="~@/assets/images/icons/profi.png" alt="">
            <div class="course-block-container-category-cards-card-title">Продвинутый</div>
            <div class="course-block-container-category-cards-card-subtitle">Для тех кто хочет усовершенствовать свои
              знания
            </div>
            <div class="course-block-container-category-cards-card-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam augue elit, consequat sit amet aliquam eu,
              tincidunt quis ipsum. Sed non libero turpis. Nam metus est, ultricies tempus viverra vel, ornare eu purus.
              Proin eu justo suscipit, blandit neque a, cursus leo. Vestibulum ultrices lacus euismod eros efficitur,
              quis consequat massa hendrerit.
            </div>
            <div class="bottom-action">
              <router-link :to="'/profile/free-lesson/' + course.lesson_id" :disabled="!course.is_free_lesson"
                           :event="course.is_free_lesson ? 'click' : ''">ПРОЙТИ БЕСПЛАТНЫЙ УРОК
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="how-work-container">
        <div class="how-work-container-title">Как проходит обучение?</div>
        <div class="how-work-container-subtitle">Несколько простых шагов для изучения иностранного языка</div>
        <div class="how-work-container-group">
          <div class=" how-work-container-group-card">
            <div class="how-work-container-group-card-content">
              <div class="how-work-container-group-card-content-image">
                <img src="~@/assets/images/lessons.png" alt="registration">
              </div>
              <div class="how-work-container-group-card-content-info-block">
                <div class="how-work-container-group-card-content-info-block-title">Уроки</div>
                <div class="how-work-container-group-card-content-info-block-text">
                  Ежедневно проходите обучение по видео урокам от наших экспертов
                </div>
              </div>
              <div class="how-work-container-group-card-content-number">1</div>
            </div>
          </div>
          <div class=" how-work-container-group-card">
            <div class="how-work-container-group-card-content">
              <div class="how-work-container-group-card-content-image">
                <img src="~@/assets/images/dictionaries.png" alt="free-lessons">
              </div>
              <div class="how-work-container-group-card-content-info-block">
                <div class="how-work-container-group-card-content-info-block-title">Словари</div>
                <div class="how-work-container-group-card-content-info-block-text">
                  Изучайте новые слова и словосочетания. Формируйте свой словарь самостоятельно.
                </div>
              </div>
              <div class="how-work-container-group-card-content-number line-to-top line-to-topa">3</div>
            </div>
          </div>
          <div class=" how-work-container-group-card">
            <div class="how-work-container-group-card-content">
              <div class="how-work-container-group-card-content-image">
                <img src="~@/assets/images/independent_work.png" alt="training">
              </div>
              <div class="how-work-container-group-card-content-info-block">
                <div class="how-work-container-group-card-content-info-block-title">Самостоятельная работа</div>
                <div class="how-work-container-group-card-content-info-block-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div>
              <div class="how-work-container-group-card-content-number line-to-top line-to-bottoma">5</div>
            </div>
          </div>
          <div class=" how-work-container-group-card">
            <div class="how-work-container-group-card-content">
              <div class="how-work-container-group-card-content-image">
                <img src="~@/assets/images/tasks.png" alt="language-selection">
              </div>
              <div class="how-work-container-group-card-content-info-block">
                <div class="how-work-container-group-card-content-info-block-title">Задания</div>
                <div class="how-work-container-group-card-content-info-block-text">
                  Выполняйте задания, мы позаботились о том чтоб они были интересными
                </div>
              </div>
              <div class="how-work-container-group-card-content-number line-to-bottom line-to-bottoma">2</div>
            </div>
          </div>
          <div class=" how-work-container-group-card">
            <div class="how-work-container-group-card-content">
              <div class="how-work-container-group-card-content-image">
                <img src="~@/assets/images/grammar.png" alt="payment">
              </div>
              <div class="how-work-container-group-card-content-info-block">
                <div class="how-work-container-group-card-content-info-block-title">Грамматика</div>
                <div class="how-work-container-group-card-content-info-block-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div>
              <div class="how-work-container-group-card-content-number line-to-bottom line-to-topa">4</div>
            </div>
          </div>
        </div>
        <div class="button">
          <button type="button" class="btn-buy-course" @click="showBuyingCourseModal" v-if="!course.buy">купить курс</button>
        </div>
      </div>
    </div>
    <TariffPlan :course="course" @buyCourse="showBuyingCourseModal" />
    <b-modal id="pass-test">
      <template #modal-header="{ close }">
        <b-button @click="close()">
          X
        </b-button>
        <h3>Пройти тест</h3>
      </template>
      <div class="test-content">
        <div class="info">
          Перед покупкой курса пройдите тест на определение уровня знаний, чтобы приобрести курс соответствующий вашим
          знаниям.
        </div>
        <router-link v-if="course.course_test && course.course_test.length && course.course_test[0].tasks.length" tag="button" type="button"
                     :to="course.course_test && course.course_test.length ? '/profile/task/' + course.course_test[0].tasks[0].task_type_id + '/' + course.course_test[0].tasks[0].id + '?course=' +  course.id : ''"
                     class="test-btn">ПРОЙТИ ТЕСТ
        </router-link>
      </div>
    </b-modal>
    <ModalBuyingCourse ref="chooseCoursePlan" :course="course" :tariff="tariffActive"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ModalBuyingCourse from '../../../components/ModalBuyingCourse'
import TariffPlan from '../../../components/Courses/Tariffs/TariffPlan'
import BuyingCourseMixin from '../../../../mixins/BuyingCourseMixin'

export default {
  name: 'ProfileCourseDetails',
  mixins: [BuyingCourseMixin],
  components: { TariffPlan, ModalBuyingCourse },
  computed: {
    ...mapState({
      course: state => state.course.course
    }),
    is_mobile () {
      const isMobile = window.matchMedia('only screen and (max-width: 1000px)')
      return !!isMobile.matches
    }
  },
  created () {
    this.getCourse({ id: this.$route.params.id }).then(res => {
    })
  },
  methods: {
    ...mapActions({
      getCourse: 'course/getCourse',
      buyCourse: 'course/buyCourse',
      getBoughtCoursesList: 'course/getBoughtCoursesList'
    }),
  }
}
</script>
<style lang="scss" scoped>
  .course-video{
    img {
      height: 100%;
    }
  }
</style>
