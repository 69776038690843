<template>
  <div>
    <!-- When there is content  -->
    <div class="personal-details books additional-materials">
      <div class="additional">
        ДОПОЛНИТЕЛЬНЫЕ МАТЕРИАЛЫ
      </div>
      <div class="tabs-title">
        Другие материалы
      </div>
      <div class="tabs-info" v-if="!loading">
        {{otherMaterialList.length ? 'Другие материалы для обучения': 'Данный раздел доступен только после покупки курса'}}
      </div>
      <div class="action" v-if="!loading">
        <button @click="openModal" type="button" class="add-course">ВЫБРАТЬ КУРС</button>
      </div>
      <loading v-if="loading" />
      <div v-else class="books-content">
        <img v-if="!otherMaterialList.length" src="~@/assets/images/profile/additional-materials.svg" alt="additional-materials">
        <div v-else class="books-content-column">
          <div class="books-content-item" v-for="(material, index) in otherMaterialList" :key="index">
            <div class="books-item-title">{{material.name[$i18n.locale]}}</div>
            <div class="item-content">
              <div class="description">
                <div class="text">{{material.description[$i18n.locale]}}</div>
              </div>
            </div>
            <div class="additional-materials-content" v-if="material.gallery && material.gallery.length && material.gallery.length > 1">
              <div class="material-item cursor-pointer" @click="openImageModal(material.name, image)" v-for="(image, index) in material.gallery" :key="index">
                <img :src="image.file_path.medium" alt="additional">
                <div class="add">
                  <img src="~@/assets/images/profile/plus.svg" alt="plus">
                </div>
              </div>
            </div>
            <div class="additional-materials-content" v-if="material.gallery && material.gallery.length && material.gallery.length === 1">
              <div   @click="openImageModal(material.name, material.gallery[0])" class="full-image cursor-pointer">
                <img :src="material.gallery[0].file_path.full" class="image" alt="material">
                <div class="add">
                  <img src="~@/assets/images/profile/plus.svg" alt="plus">
                </div>
              </div>
            </div>
            <div class="actions mt-2" v-if="material.files_list && material.files_list.length">
              <div @click="downloadFiles(material)" class="action-item cursor-pointer">
                <div>
                  <img src="~@/assets/images/icons/download.svg" alt="download">
                </div>
                <span>СКАЧАТЬ ФАЙЛ</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3" v-if="pagination.count_pages > 1">
        <b-pagination
          v-model="currentPage"
          :total-rows="pagination.count_pages"
          :per-page="1"
          first-number
          last-number
          prev-text="«"
          next-text="»"
          @input="clickPagination"
        ></b-pagination>
      </div>
    </div>

    <b-modal id="additional-material-modal" ref="additionalMaterialModal">
      <template #modal-header="{ close }">
        <b-button @click="close()">
          X
        </b-button>
        <h3 v-if="modalMaterial && modalMaterial.name">{{modalMaterial.name[$i18n.locale]}}</h3>
      </template>
      <div class="material-image" v-if="modalMaterial && modalMaterial.image">
        <img :src="modalMaterial.image" alt="material">
      </div>
    </b-modal>
    <CoursesModal :show="showModal" v-model="activeCourseId" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { http } from '../../../../api'
import Loading from '../../../components/Loading'
import CoursesModal from '../../../components/Materials/CoursesModal'
export default {
  name: 'AdditionalMaterials',
  components: { CoursesModal, Loading },
  data () {
    return {
      currentPage: 1,
      modalMaterial: {},
      activeCourseId: null,
      loading: false,
      showModal: false
    }
  },
  computed: {
    ...mapState({
      otherMaterialList: state => state.otherMaterial.otherMaterialList,
      pagination: state => state.otherMaterial.pagination,
      courses: state => state.course.boughtCoursesList
    })
  },
  watch: {
    activeCourseId(course_id){
      this.loading = true
      this.showModal = false
      this.getOtherMaterialList({course_id})
      .finally(() => {
        this.loading = false
      })
    }
  },
  methods: {
    ...mapActions({
      getOtherMaterialList: 'otherMaterial/getOtherMaterialList',
      downloadOtherMaterialFiles: 'otherMaterial/downloadOtherMaterialFiles',
      getCourses: 'course/getBoughtCoursesList'
    }),
    openModal(){
      this.showModal = true
    },
    openImageModal (name, image) {
      this.modalMaterial = {}
      this.modalMaterial.name = name
      this.modalMaterial.image = image.file_path.full
      this.$refs.additionalMaterialModal.show()
    },
    clickPagination () {
      this.getOtherMaterialList({ page: this.currentPage }).then(() => {
        window.scrollTo(0, 0)
      })
    },
    downloadFiles (material) {
      const url = 'other-material/' + material.id + '/download-files'
      http.get(url, {
        responseType: 'blob'
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/zip' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = material.name[this.$i18n.locale].replace(/\s/g, '_')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch(() => {
        this.$toasted.error('Download failed')
      })
    }
  }
}
</script>
