<template>
  <div>
    <b-modal hide-footer hide-header id="login-modal" ref="loginModal" content-class="log-in">
      <div class="modal-block">
        <div class="button-group flex">
          <button type="button" class="login" :class="!registration ? 'active-btn' : ''" @click="openLoginModal">Вход в
            кабинет
          </button>
          <button type="button" class="register" :class="registration ? 'active-btn' : ''"
                  @click="openLoginModalRegistration">Регистрация
          </button>
        </div>
        <ValidationObserver class="login-block" v-if="!registration" tag="form" ref="loginForm"
                            @submit.prevent="loginFormSubmit()">
          <div class="close-modal" @click="closeLoginModal">
            <img src="../../assets/images/icons/close.svg" alt="Close">
          </div>
          <div class="title">Вход в кабинет</div>
          <div class="form-block">
            <ValidationProvider v-slot="{ classes }" rules="required" class="form-group">
              <label for="username">Логин или email *</label>
              <input v-model="loginFormData.username" autocomplete="off" :class="[classes, 'login-input']"
                     name="username" type="text"
                     id="username" placeholder="Логин">
            </ValidationProvider>
            <ValidationProvider v-slot="{ classes }" rules="required|min:6" class="form-group form-group-remember">
              <label for="password">Пароль *</label>
              <input v-model="loginFormData.password" autocomplete="off" :class="[classes, 'login-input']"
                     name="password"
                     type="password" id="password" placeholder="Пароль">
            </ValidationProvider>
          </div>
          <button type="button" @click="openForgotPasswordModal" class="forgot-password-btn">Забыли пароль?</button>
          <div class="checkbox">
            <label for="save" class="remember">
              <input type="checkbox" id="save" name="checkbox">
              <span></span>
              <div>Запомнить меня</div>
            </label>
          </div>
          <button class="submit" type="submit" :disabled="disabledSubmitBtn"
                  :class="disabledSubmitBtn ? 'btn-disabled' : ''">войти
          </button>
          <div class="text-center mt-1">
            <span class="text-danger font-italic">{{ errorMsg }}</span>
          </div>
          <div class="social-media">
            <div class="social-title">или войдите через соц. сети</div>
            <div class="social-media-icons">
              <div @click="loginFb">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 96.124 96.123"
                     style="enable-background:new 0 0 96.124 96.123" xml:space="preserve"><g><path fill="#2e90d1" d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z"/></g></svg>
              </div>
              <div @click="loginVk">
                <svg id="Bold" enable-background="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path fill="#2e90d1"
                        d="m19.915 13.028c-.388-.49-.277-.708 0-1.146.005-.005 3.208-4.431 3.538-5.932l.002-.001c.164-.547 0-.949-.793-.949h-2.624c-.668 0-.976.345-1.141.731 0 0-1.336 3.198-3.226 5.271-.61.599-.892.791-1.225.791-.164 0-.419-.192-.419-.739v-5.105c0-.656-.187-.949-.74-.949h-4.126c-.419 0-.668.306-.668.591 0 .622.945.765 1.043 2.515v3.797c0 .832-.151.985-.486.985-.892 0-3.057-3.211-4.34-6.886-.259-.713-.512-1.001-1.185-1.001h-2.625c-.749 0-.9.345-.9.731 0 .682.892 4.073 4.148 8.553 2.17 3.058 5.226 4.715 8.006 4.715 1.671 0 1.875-.368 1.875-1.001 0-2.922-.151-3.198.686-3.198.388 0 1.056.192 2.616 1.667 1.783 1.749 2.076 2.532 3.074 2.532h2.624c.748 0 1.127-.368.909-1.094-.499-1.527-3.871-4.668-4.023-4.878z"/>
                </svg>
              </div>
              <GoogleLogin type="button" ref="google" :params="params" class="google-button" :onSuccess="loginGoogle"
                           :onFailure="onFailure">
                <div>
                  <svg focusable="false" class="svg-inline--fa fa-google fa-w-16" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 488 512">
                    <path fill="currentColor"
                          d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path>
                  </svg>
                </div>
              </GoogleLogin>
            </div>
          </div>
        </ValidationObserver>
        <ValidationObserver tag="form" class="register-block login-block" v-else ref="registerForm"
                            @submit.prevent="registerFormSubmit()">
          <div class="close-modal" @click.prevent="closeLoginModal">
            <img src="../../assets/images/icons/close.svg" alt="Close">
          </div>
          <div class="title">Регистрация</div>
          <div class="form-block">
            <ValidationProvider v-slot="{ classes }" rules="required" class="form-group">
              <label for="lastName">Фамилия *</label>
              <input name="last_name" v-model="registerFormData.last_name" maxlength="30" autocomplete="off"
                     :class="[classes, 'login-input']" type="text" id="lastName" placeholder="Заполнить поле">
            </ValidationProvider>
            <ValidationProvider v-slot="{ classes }" rules="required" class="form-group">
              <label for="firstName">Имя *</label>
              <input name="first_name" v-model="registerFormData.first_name" maxlength="30" autocomplete="off"
                     :class="[classes, 'login-input']" type="text" id="firstName" placeholder="Заполнить поле">
            </ValidationProvider>
            <ValidationProvider v-slot="{ classes }" rules="required" class="form-group">
              <label for="email">E-mail *</label>
              <input v-model="registerFormData.email" :class="[classes, 'login-input']" type="email" id="email"
                     name="email"
                     placeholder="Заполнить поле">
            </ValidationProvider>
            <PhoneInput :country-code.sync="registerFormData.country_code" @onValid="(val) => validPhone = val"
                        class="form-group auth-phone-input" v-model="registerFormData.phone" label="Телефон *"
                        placeholder="Заполнить поле"/>
            <ValidationProvider v-slot="{ classes }" rules="required" class="form-group">
              <label for="login">Логин *</label>
              <input v-model="registerFormData.login" :class="[classes, 'login-input']" pattern="^[a-zA-Z0-9\-]+$"
                     type="text" id="login"
                     name="login" placeholder="Заполнить поле">
            </ValidationProvider>
            <ValidationProvider v-slot="{ classes }" rules="required" class="form-group">
              <label for="birthday">Дата рождения *</label>
              <b-datepicker v-model="registerFormData.birthday" class="birthday-input" id="birthday"
                     name="birthday" locale="ru" placeholder="Заполнить поле" initial-date="1990-01-01" right></b-datepicker>
            </ValidationProvider>
            <ValidationProvider v-if="refInputHide" v-slot="{ classes }"  class="form-group">
              <label for="coupon">Реферальный купон</label>
              <input v-model="registerFormData.coupon" :class="[classes, 'login-input']"
                     type="text" id="coupon"
                     name="coupon" placeholder="Заполнить поле">
            </ValidationProvider>
            <ValidationProvider tag="div" rules="required|oneOf:male,female" class="radio-gender">
              <div class="remember">Пол:</div>
              <div class="radio">
                <label for="male">
                  <input type="radio" id="male" v-model="registerFormData.gender" value="male">
                  <span></span>
                  <div>Мужской</div>
                </label>
              </div>
              <div class="radio">
                <label for="female">
                  <input type="radio" id="female" v-model="registerFormData.gender" value="female">
                  <span></span>
                  <div>Женский</div>
                </label>
              </div>
            </ValidationProvider>
            <PasswordInput label="Пароль *" v-model="registerFormData.password"/>
            <PasswordInput label="Повторить пароль *" v-model="registerFormData.password_confirmation"/>
          </div>
          <ValidationProvider tag="div" rules="required" v-slot="{ classes }" class="checkbox-reg">
            <label for="terms" class="remember">
              <input type="checkbox" id="terms" name="terms" v-model="registerFormData.checked" value="1">
              <span :class="[classes, 'login-input']"></span>
              <div>Отправляя заявку, я соглашаюсь с <a target="_blank" href="/privacy-policy">политикой
                конфиденциальности</a> и <a target="_blank" href="/terms-of-use">пользовательским соглашением</a> сайта
              </div>
            </label>
          </ValidationProvider>
          <button class="submit" type="submit" :disabled="disabledSubmitBtn"
                  :class="disabledSubmitBtn ? 'btn-disabled' : ''">Зарегистрироваться
          </button>
          <div class="text-center">
            <span class="text-danger font-italic">{{ registerErrorMsg }}</span>
          </div>
          <div class="social-media">
            <div class="social-title">или войдите через соц. сети</div>
            <div class="social-media-icons">
              <div @click="loginFb">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 96.124 96.123"
                     style="enable-background:new 0 0 96.124 96.123" xml:space="preserve"><g><path fill="#2e90d1" d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z"/></g></svg>
              </div>
              <div @click="loginVk">
                <svg id="Bold" enable-background="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path fill="#2e90d1"
                        d="m19.915 13.028c-.388-.49-.277-.708 0-1.146.005-.005 3.208-4.431 3.538-5.932l.002-.001c.164-.547 0-.949-.793-.949h-2.624c-.668 0-.976.345-1.141.731 0 0-1.336 3.198-3.226 5.271-.61.599-.892.791-1.225.791-.164 0-.419-.192-.419-.739v-5.105c0-.656-.187-.949-.74-.949h-4.126c-.419 0-.668.306-.668.591 0 .622.945.765 1.043 2.515v3.797c0 .832-.151.985-.486.985-.892 0-3.057-3.211-4.34-6.886-.259-.713-.512-1.001-1.185-1.001h-2.625c-.749 0-.9.345-.9.731 0 .682.892 4.073 4.148 8.553 2.17 3.058 5.226 4.715 8.006 4.715 1.671 0 1.875-.368 1.875-1.001 0-2.922-.151-3.198.686-3.198.388 0 1.056.192 2.616 1.667 1.783 1.749 2.076 2.532 3.074 2.532h2.624c.748 0 1.127-.368.909-1.094-.499-1.527-3.871-4.668-4.023-4.878z"/>
                </svg>
              </div>
              <GoogleLogin ref="google" :params="params" class="google-button" :onSuccess="loginGoogle"
                           :onFailure="onFailure">
                <div>
                  <svg focusable="false" class="svg-inline--fa fa-google fa-w-16" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 488 512">
                    <path fill="currentColor"
                          d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path>
                  </svg>
                </div>
              </GoogleLogin>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </b-modal>
    <b-modal hide-footer hide-header ref="forgotPasswordModal" content-class="forgot-password">
      <div class="close-modal" @click.prevent="closeForgotPasswordModal">
        <img src="../../assets/images/icons/close.svg" alt="Close">
      </div>
      <ValidationObserver class="modal-block" tag="form" ref="forgotPasswordForm" @submit.prevent="resetPassword">
        <div class="title">Восстановить пароль</div>
        <div class="text">
          Введите e-mail адрес который вы указывали при регистрации,
          чтобы восстановить пароль. Вы получите письмо с инструкциями по восстановлению пароля.
          Если у вас возникли проблемы с восстановлением пароля,
          <a @click.prevent="openContacts" href="#">свяжитесь с нами</a>
        </div>
        <div class="form-block">
          <div class="form-group">
            <ValidationProvider class="form-group" tag="div" v-slot="{ classes }" rules="required">
              <label for="e-mail">E-mail</label>
              <input type="email" id="e-mail" :class="[classes, 'login-input']" v-model="forgotPasswordFormData.email"
                     placeholder="E-mail">
            </ValidationProvider>
          </div>
        </div>
        <button class="submit" type="submit" :disabled="forgotPasswordDisabledSubmitBtn"
                :class="forgotPasswordDisabledSubmitBtn ? 'btn-disabled' : ''">восстановить пароль
        </button>
        <div class="text-center">
          <span class="text-danger font-italic" v-if="errorForgotMsg">{{ errorForgotMsg }}</span>
        </div>
        <div class="login-registration">
          <div class="login-text" @click="openLoginModal">Войти</div>
          <div class="registration-text">Нет аккаунта?
            <div @click="openLoginModalRegistration">Зарегистрироваться</div>
          </div>
        </div>
      </ValidationObserver>
    </b-modal>
    <b-modal hide-footer hide-header ref="congratulationsModal" content-class="congratulation">
      <div class="close-modal" @click.prevent="closeCongratulationsModal">
        <img src="../../assets/images/icons/close.svg" alt="Close">
      </div>
      <div class="modal-block">
        <div class="title">Поздравляем с регистрацией</div>
        <div class="text">
          На ваш e-mail адрес было отправлено письмо для подтверждения вашего e-mail адреса.
          Подтвердить e-mail адрес можно в любой момент, сделав запрос в личном кабинете.
          Подтверждение e-mail адреса необходимо для возможности восстановить
          свой аккаунт в дальнейшем для необходимости.
        </div>
        <button type="button" @click="goToOffice">перейти в кабинет</button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import GoogleLogin from 'vue-google-login'
import { mapActions, mapState } from 'vuex'
import PasswordInput from './PasswordInput'
import PhoneInput from './PhoneInput'

export default {
  name: 'AuthModal',
  data () {
    return {
      params: {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID
      },
      disabledSubmitBtn: false,
      forgotPasswordDisabledSubmitBtn: false,
      forgotPasswordFormData: {},
      loginFormData: {},
      registerFormData: {
        gender: 'male',
        country_code: 'RU',
        checked: false,
      },
      birthdayDay: '',
      year: '',
      birthdayMonth: '',
      birthdayYear: '',
      errorMsg: '',
      errorForgotMsg: '',
      registerErrorMsg: '',
      passwordRules: {
        required: true,
        regex: '/\^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/'
      },
      validPhone: false,
      ref: localStorage.ref || null
    }
  },
  mounted () {
    this.year = new Date().getFullYear()
  },
  created () {
    if (this.ref || this.$route.query.ref){
      this.registerFormData.coupon = this.ref || this.$route.query.ref
    }
  },
  components: {
    PhoneInput,
    PasswordInput,
    GoogleLogin
  },
  computed: {
    ...mapState({
      registration: state => state.auth.registration
    }),
    refInputHide(){
      return !this.ref && !this.$route.query.ref && !localStorage.ref
    }
  },
  methods: {
    ...mapActions({
      fbLogin: 'auth/fbLogin',
      vkLogin: 'auth/vkLogin',
      googleLogin: 'auth/googleLogin',
      login: 'auth/login',
      register: 'auth/register',
      forgotPassword: 'auth/forgotPassword',
      setLoginModal: 'auth/setLoginModal'
    }),
    openContacts () {
      this.closeForgotPasswordModal()
      this.$router.push('/contacts')
    },
    loginGoogle (googleUser) {
      const data = {
        id: googleUser.it.sT,
        first_name: googleUser.it.HU,
        last_name: googleUser.it.YS,
        email: googleUser.it.Tt,
      }
      this.googleLogin(data).then(() => {
        this.$refs.loginModal.hide()
        this.$router.push('/profile/profile-details')
      })
    },
    OnGoogleAuthSuccess (idToken) {
      console.log(idToken)
      // Receive the idToken and make your magic with the backend
    },
    OnGoogleAuthFail (error) {
      console.log(error)
    },
    onFailure (googleUser) {
      console.log('failure', googleUser)
    },
    loginFb () {
      const _this = this
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            _this.fbLogin({
              token: response.authResponse.accessToken,
              userId: response.authResponse.userID
            }).then(() => {
              _this.$refs.loginModal.hide()
              _this.$router.push('/profile/profile-details')
            }).catch(err => {
              if (err.response.status === 403) {
                const errorMsg = err.response.data.message
                _this.$toasted.error(errorMsg).goAway(1500)
              } else {
                _this.$toasted.error('Something went wrong!').goAway(1500)
              }
            })
          } else {
            console.log('User cancelled login or did not fully authorize.')
          }
        },
        {
          scope: 'email',
          return_scopes: true
        }
      )
    },
    loginVk () {
      const _this = this
      const VK = window.VK
      VK.Auth.login(function (response) {
        if (response.session) {
          _this.vkLogin(response.session).then(() => {
            _this.$refs.loginModal.hide()
            _this.$router.push('/profile/profile-details')
          }).catch(err => {
            _this.$toasted.error(err.response.data.message).goAway(1500)
          })
          if (response.settings) {
            console.log(response.settings)
            /* Выбранные настройки доступа пользователя, если они были запрошены */
          }
        }
      })
    },
    openLoginModal () {
      this.closeForgotPasswordModal()
      this.setLoginModal(false).then(() => {
        this.$refs.loginModal.show()
        this.$refs.loginForm.reset()
      })
    },
    resetPassword () {
      this.errorForgotMsg = ''
      this.$refs.forgotPasswordForm.validate().then(success => {
        if (success) {
          this.forgotPasswordDisabledSubmitBtn = true
          this.forgotPassword(this.forgotPasswordFormData).then(() => {
            this.$refs.forgotPasswordModal.hide()
            this.$toasted.success('На ваш почтовый ящик было отправлено письмо для востановления пароля!').goAway(1500)
          }).catch(err => {
            if (err.response.data.error === 'not_found') {
              this.errorForgotMsg = 'Неверная электронная почта'
              return false
            } else {
              this.errorForgotMsg = 'Что то пошло не так!'
            }
          }).finally(() => {
            this.forgotPasswordDisabledSubmitBtn = false
          })
        }
      })
    },
    goToOffice () {
      this.$refs.congratulationsModal.hide()
      this.$router.push('/profile/profile-details')
    },
    openLoginModalRegistration () {
      this.closeForgotPasswordModal()
      this.setLoginModal(true).then(() => {
        this.$refs.loginModal.show()
        this.$refs.registerForm.reset()
      })
    },
    closeLoginModal () {
      this.$refs.loginModal.hide()
      this.$refs.registerForm.reset()
      this.$refs.loginForm.reset()
    },
    openForgotPasswordModal () {
      this.$refs.loginModal.hide()
      this.$refs.forgotPasswordModal.show()
    },
    openCongratulationsModal () {
      this.$refs.loginModal.hide()
      this.$refs.congratulationsModal.show()
    },
    closeCongratulationsModal () {
      this.$refs.congratulationsModal.hide()
    },
    closeForgotPasswordModal () {
      this.$refs.forgotPasswordModal.hide()
    },
    loginFormSubmit () {
      this.errorMsg = ''
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.disabledSubmitBtn = true
          this.login(this.loginFormData).then((res) => {
            this.$refs.loginModal.hide()
            if (res.data.user.roles.findIndex(role => role.name === 'customer') !== -1) {
              this.$router.push('/profile/profile-details')
            } else {
              this.$router.push({
                name: 'chat',
                query: { chat_with: 'expert_chat' }
              })
            }
          }).catch(err => {
            if (err.response.data.message) {
              this.errorMsg = err.response.data.message
              return false
            } else {
              this.errorMsg = 'Что то пошло не так!'
            }
          }).finally(() => {
            this.disabledSubmitBtn = false
          })
        }
      })
    },
    registerFormSubmit () {
      this.registerErrorMsg = ''
      this.$refs.registerForm.validate().then(success => {
        if (!this.registerFormData.checked) {
          this.$toasted.error('Согласие с политикой конфиденциальности и пользовательским соглашением сайта обязательно').goAway(2000)
          return
        }
        if (success && this.validPhone) {
          this.disabledSubmitBtn = true
          // data.birthday = this.birthdayYear + '/' + this.birthdayMonth + '/' + this.birthdayDay
          this.register(this.registerFormData).then(() => {
            this.openCongratulationsModal()
          }).catch(err => {
            if (err.response.status === 400) {
              const data = err.response.data
              const keys = Object.keys(data)
              const msg = data[keys[0]][0]
              this.registerErrorMsg = msg
            } else {
              this.registerErrorMsg = 'Server error!'
            }
          }).finally(() => {
            this.disabledSubmitBtn = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">

#login-modal {
  .remember {
    .dirty, .untouched {
      border: 1px solid red;
    }
  }

  @media (max-width: 576px) {
    .modal-dialog {
      width: 100% !important;

    }

    .log-in, .login, .register {
      border-radius: 0;
    }
    .register, .login {
      font-size: 12px;
      padding: 8px 0;
    }

    .login-block {
      padding: 30px 20px;

      .title {
        font-size: 24px;
      }

      input {
        padding: 15px 0 15px 25px;
      }

      .submit {
        padding: 15px;
      }
    }
  }
}

.google-button {
  background: transparent;
  border: none;
  padding: 0;
}
.login-input::placeholder {
  font-style: italic !important;
}

.form-group-remember {
  margin-bottom: 10px !important;
}
</style>
