<template>
  <div class="col-12 p-0">
    <bread-crumbs :pages="pages"/>
    <div class="privacy-policy page-container">
      <div class="title" v-if="page.dynamic_page">{{page.dynamic_page.title[$i18n.locale]}}</div>
      <div class="subtitle">Политика в отношении обработки персональных данных</div>
      <div class="text w-100 word-break-all" v-if="page.description">
        <div v-html="page.description[$i18n.locale]"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BreadCrumbs from '../../components/BreadCrumbs'

export default {
  name: 'PrivacyPolicy',
  components: {
    BreadCrumbs
  },
  data () {
    return {
      pages: [
        { path: '/', rout: 'Главная', active: false },
        { path: '', rout: 'Политика конфиденциальности', active: true }
      ]
    }
  },
  computed: {
    ...mapState({
      page: state => state.dynamicPage.page
    })
  },
  mounted () {
    this.dynamicPageByKey({ key: 'privacy_policy' })
  },
  methods: {
    ...mapActions({
      dynamicPageByKey: 'dynamicPage/dynamicPageByKey'
    })
  }
}
</script>
