<template>
  <div class="news">
    <div class="news-image">
      <img
        :src="news.image_paths.medium"
        :alt="news.image_alt"
        v-if="news.image_paths"
      />
      <img
        src="../../assets/images/defaul-image.png"
        alt="No Image"
        v-else
      />
    </div>
    <div class="news-block">
      <div class="title">{{ news.title[$i18n.locale] }}</div>
      <div class="subtitle blue-color">
        {{ news.created_at | moment("MM.DD.YYYY") }}
      </div>
      <div class="text">
        <v-clamp :max-lines="6">{{ news.short_description[$i18n.locale] }}</v-clamp>
      </div>
      <router-link to="/news/11" class="more"
      >{{ $t("buttons.read_more") }}
        <span>&raquo;</span></router-link
      >
    </div>
  </div>
</template>


<script>
import VClamp from 'vue-clamp'
export default {
  name: 'NewsCard',
  components: {VClamp},
  props: {
    news: {
      type: Object,
      default: {}
    }
  }
}
</script>

<style scoped>

</style>
