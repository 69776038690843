<template>
  <div class="courses col-12 px-0">
    <bread-crumbs :pages="pages" />
    <div class="page-container">
      <div class="lang-courses-section">
        <div class="lang-courses-section-title font-weight-bold">
          Языковые курсы
        </div>
        <div class="lang-courses-section-subtitle">
          Доступные курсы Language to go
        </div>
        <div class="lang-courses-section-items d-flex flex-wrap">
          <template v-for="course in allCoursesList">
            <div
              class="language-course col-xxl-4"
              :class="{ upcoming: course.in_developing === 1 }"
            >
              <div class="upcoming-cover" v-if="course.in_developing === 1">
                <img src="../../../assets/images/upcoming-icon.png" alt="upcoming" />
                <span class="upcoming-cover-title">В разработке</span>
              </div>
              <div class="language-course-background">
                <img :src="course.image_path" :alt="course.image_path" v-if="course.image_path" />
                <img src="../../../assets/images/defaul-image.png" alt="No Image" v-else />
              </div>
              <div class="language-course-external-content w-100">
                <div class="language-course-external-content-header align-items-center d-flex">
                  <div class="flag">
                    <img :src="course.teaching_language.flag" alt="Cn_flag" />
                  </div>
                  <div
                    class="course-title font-weight-bold d-flex flex-column justify-content-start pl-3"
                  >
                    <div class="course-title-primary">{{ course.name[$i18n.locale] }}</div>
                  </div>
                </div>
                <div class="language-course-external-content-info">
                  <div
                    class="language-course-external-content-info-description"
                    v-html="course.description[$i18n.locale]"
                  ></div>
                  <router-link
                    :to="'/course/' + course.id"
                    class="language-course-external-content-info-more-about-course cursor-pointer font-weight-bold text-uppercase"
                  >
                    {{ $t('buttons.more_about_course') }} <span class="quotes">&#8250;&#8250;</span>
                  </router-link>
                  <div
                    class="watch-teaser d-flex align-items-center cursor-pointer"
                    @click="openVideoModal(course.announcement)"
                  >
                    <div class="watch-teaser-play">
                      <img src="@/assets/images/icons/play-blue.svg" alt="logo" />
                    </div>
                    <div class="watch-teaser-title font-weight-bold pl-4 text-uppercase">
                      посмотреть анонс
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="mt-3" v-if="pagination.count_pages > 1">
        <b-pagination
          v-model="currentPage"
          :total-rows="pagination.count_pages"
          :per-page="1"
          first-number
          last-number
          prev-text="«"
          next-text="»"
          @input="clickPagination"
        ></b-pagination>
      </div>
    </div>
    <ModalAnons
      :courseAnnouncement="courseAnnouncement"
      :show="showModal"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BreadCrumbs from '../../components/BreadCrumbs'
import ModalAnons from '../../components/ModalAnons'

export default {
  name: 'Courses',
  data() {
    return {
      currentPage: 1,
      pages: [
        { path: '/', rout: 'Главная', active: false },
        { path: '', rout: 'Курсы', active: true }
      ],
      courseAnnouncement: {},
      showModal: false
    }
  },
  computed: {
    ...mapState({
      allCoursesList: state => state.course.courses,
      pagination: state => state.course.pagination
    })
  },
  components: {
    BreadCrumbs,
    ModalAnons
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.id) {
      let page = 1
      if (to.query.page) {
        page = to.query.page
      }
      this.getAllCoursesList({ page, language: to.params.id }).then(res => {})
    }
    next()
  },
  created() {
    if (this.$route.params.id) {
      this.getAllCoursesList({ language: this.$route.params.id }).then(res => {
        console.log('allCoursesList', this.allCoursesList)
        if (this.allCoursesList.length) {
          document.title = this.pages[1].rout =
            this.$route.params.id === 'all'
              ? 'Все курсы'
              : this.allCoursesList[0].teaching_language.name[this.$i18n.locale]
        }
      })
    } else {
      this.getAllCoursesList({}).then(res => {})
    }
  },
  metaInfo() {
    const title = this.$t('courses.title')
    return {
      title: title,
      meta: [
        {
          name: 'og:title',
          content: title
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getAllCoursesList: 'course/getCourses'
    }),
    openVideoModal(announcement) {
      console.log(this.showModal)
      console.log(announcement)
      this.courseAnnouncement = announcement
      this.showModal = true
      // this.$refs.anonsVideoModal.show()
    },
    // closeVideoModal () {
    //   this.$refs.anonsVideoModal.hide()
    // },
    clickPagination() {
      this.$router.push('/courses/' + this.$route.params.id + '?page=' + this.currentPage)
      window.scrollTo(0, 0)
    }
  },
  updated() {
    console.log('pages', this.pages)
  }
}
</script>
<style>
.courses .lang-courses-section-items .language-course-external-content-info .watch-teaser:hover {
  border-color: #78258d !important;
}
</style>
