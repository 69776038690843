<template>
  <div class="contacts col-12 px-0">
    <bread-crumbs :pages="pages" />
    <div class="page-container col-12 contacts-container">
      <div class="contacts-block">
        <div class="title ">Контакты</div>
        <div class="subtitle">С вами на связи в любое удобное время</div>
        <div class="contacts-block-contacts row">
          <div class="col-lg-4 col-md-5 col-sm-12">
            <!-- <div class="contact">
              <div class="image">
                <img src="../../../assets/images/contacts/phone.png" alt="Phone">
              </div>
              <div class="data text-left">
                <div class="text-left">Телефоны</div>
                <a :href="'tel:' + phone" v-for="(phone, index) in settings.phone" v-bind:key="index">{{phone}}</a>
              </div>
            </div> -->
            <div class="contact">
              <div class="image">
                <img src="../../../assets/images/contacts/e-mail.png" alt="E-mail" />
              </div>
              <div class="data text-left">
                <div class="text-left">E-mail</div>
                <a
                  :href="'mailto:' + email"
                  v-for="(email, index) in settings.email"
                  v-bind:key="index"
                  >{{ email }}</a
                >
              </div>
            </div>
            <!-- <div class="contact">
              <div class="image">
                <img src="../../../assets/images/contacts/address.png" alt="Address">
              </div>
              <div class="data text-left">
                <div class="text-left">Адрес</div>
                <p>{{settings.address}}</p>
              </div>
            </div>-->
          </div>
          <!-- <div class="map-section col-lg-7 col-md-7 col-sm-12">
            <div class="maps" v-html="settings.coordinates"></div>
          </div> -->
        </div>
      </div>
    </div>
    <ContactsForm />
  </div>
</template>

<script>
import BreadCrumbs from '../../components/BreadCrumbs'
import ContactsForm from '../../components/ContactsForm'
import { mapState } from 'vuex'

export default {
  name: 'Contacts',
  data() {
    return {
      pages: [
        { path: '/', rout: 'Главная', active: false },
        { path: '', rout: 'Контакты', active: true }
      ]
    }
  },
  components: {
    BreadCrumbs,
    ContactsForm
  },
  computed: {
    ...mapState({
      settings: state => state.setting.settings
    })
  },
  metaInfo() {
    const title = this.$t('contacts.title')
    return {
      title: title,
      meta: [
        {
          name: 'og:title',
          content: title
        }
      ]
    }
  }
}
</script>
<style scoped>
.contacts-block {
  padding-bottom: 100px;
}
.subtitle {
  font-weight: bold !important;
}
</style>
