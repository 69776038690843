<template>
  <div class="col-12 p-0">
    <bread-crumbs :pages="pages" />
    <div class="privacy-policy page-container">
      <div class="title" v-if="page.dynamic_page">{{ page.dynamic_page.title[$i18n.locale] }}</div>
      <div class="subtitle">
        Настоящий Интернет-сайт принадлежит и предоставлен ООО «Language to go»
      </div>
      <div class="text w-100 word-break-all" v-if="page.description">
        <div v-html="page.description[$i18n.locale]"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BreadCrumbs from '../../components/BreadCrumbs'

export default {
  name: 'LegalDisclaimer',
  components: {
    BreadCrumbs
  },
  data() {
    return {
      pages: [{ path: '/', rout: 'Главная', active: false }]
    }
  },
  computed: {
    ...mapState({
      page: state => state.dynamicPage.page
    })
  },
  mounted() {
    this.dynamicPageByKey({ key: 'legal_disclaimer' })
  },
  methods: {
    ...mapActions({
      dynamicPageByKey: 'dynamicPage/dynamicPageByKey'
    })
  }
}
</script>
