import { http } from '../../../api'

const interesting = {
  namespaced: true,
  state: {
    allInterestings: [],
    interestingCategories: [],
    bestInterestings: [],
    news: {},
    interesting: null,
    pagination: {}
  },
  actions: {
    getInterestings: ({ commit }, params) => {
      const q = new URLSearchParams(params)
      return http.get('interestings?' + q.toString()).then(res => {
        commit('setInterestings', res.data)
        return res.data
      })
    },
    getInterestingCategories: ({ commit }, params) => {
      const q = new URLSearchParams(params)
      return http.get('category-interesting?' + q.toString()).then(res => {
        commit('setInterestingCategories', res.data)
        return res.data
      })
    },
    getInteresting: ({ commit }, id) => {
      return http.get('interestings/' + id).then(res => {
        commit('setInteresting', res.data.data)
      })
    },
    getBestInterestings: ({ commit }, params) => {
      const q = new URLSearchParams(params)
      return http.get('best-interestings?' + q.toString()).then(res => {
        commit('setBestInterestings', res.data)
        return res.data
      })
    }
  },
  mutations: {
    setInterestings (state, data) {
      state.allInterestings = data.data
      state.pagination = data.pagination
    },
    setBestInterestings (state, data) {
      state.bestInterestings = data.data
    },
    setInterestingCategories (state, data) {
      state.interestingCategories = data.data
    },
    setInteresting (state, data) {
      state.interesting = data
    }
  },
  getters: {
    allInterestings: state => state.allInterestings,
    interestingCategories: state => state.interestingCategories,
    pagination: state => state.pagination,
    interesting: state => state.interesting,
    bestInterestings: state => state.bestInterestings
  }
}

export default interesting
