<template>
  <TaskBase :check-task="readyTask" :task="task" :correct-answer="correctAnswer">
    <template #desktop>
      <word-modal
        :words="activeItem.offerIndex !== null ? mobileOffers[activeItem.offerIndex] : []"
        :activeItem="activeItem"
        label="word"
        :positions="positions"
        group="groupIndex"
        :group-value="0"
        :show.sync="showModal"
        @pickWord="pickWordModal"
        class="make-offer"
        error="success"
      >
        <div class="block mb-5" v-for="(offerGroup, offerIndex) in offerGroups" :key="`offerGroups${offerIndex}`">
          <Words
            :positions="positions"
            :words="mobileOffers[offerIndex]"
            group="groupIndex"
            :group-value="0"
          />
          <div class="drag-container " v-drag-and-drop:options="options">
            <ul class="drag-list">
              <li class="drag-column"
                  v-for="(group, index) in offerGroup.groups"
                  :key="index"
                  :data-offer-index="offerIndex">
                <vue-draggable-group
                  :groups="offerGroup.groups"
                  :data-id="group.id"
                  v-model="activeArrId"
                >
                  <ul class="drag-inner-list owner-items mb-4" v-if="index === 0">
                    <li class="drag-inner-list dragable-item mb-4 owner-item"
                        v-for="(item, i) in group.items"
                        :key="i"
                        :data-group-id="group.id"
                        :data-group-index="index"
                        :data-index="i"
                        :data-id="item.id"
                        v-if="item.word_select === 1 || item.id"
                    >
                      <TaskItem
                        :text="item.word"
                        class="offer-item wordable"
                        :class="{'offer-main': !item.id}"
                        :used="!item.id"
                        :success="results[offerIndex][i]"
                        :error="results[offerIndex][i] === false"
                        :invisible="!item.id"
                        :mobHideText="true"
                        @click.native="() => pickWord(item, offerIndex, i)"
                      >
                        <MobileIcon v-if="!item.id" />
                      </TaskItem>
                    </li>
                  </ul>
                  <ul v-else class="word-list align-items-start p-0 only-pc" :data-id="group.id">
                    <li class="dragable-item drag-inner-list mb-4 owner-item"
                        v-for="(item, i) in group.items" :key="i" :data-id="item.id"
                        :data-group-index="index"
                        :data-index="i"
                    >
                      <TaskItem
                        :text="item.word"
                        class="offer-item word"
                        :used="!item.id"
                      />
                    </li>
                  </ul>
                </vue-draggable-group>
              </li>
            </ul>
          </div>
        </div>
      </word-modal>
    </template>
  </TaskBase>
</template>
<script>
import { VueDraggableDirective } from 'vue-draggable'
import TaskAnswerModal from '../../../../components/TaskAnswerModal'
import { mapActions, mapState } from 'vuex'
import VideoAudioGallery from '../../../../components/VideoAudioGallery'
import TaskBase from '../../../../components/TaskBase'
import TaskCheckParamsMixin from '../../../../../mixins/TaskCheckParamsMixin'
import TaskItem from '../../../../components/Tasks/TaskItem'
import WordModal from '../../../../components/Tasks/WordModal'
import Words from '../../../../components/Tasks/Words'
import MobileIcon from '../../../../components/Tasks/MobileIcon'
import CheckWidthMixin from '../../../../../mixins/CheckWidthMixin'

export default {
  name: 'MakeAnOffer',
  mixins: [TaskCheckParamsMixin, CheckWidthMixin],
  data () {
    return {
      activeArrId: [],
      offerGroups: [],
      options: {
        dropzoneSelector: '.drag-inner-list',
        draggableSelector: '.dragable-item',
        onDragend: this.onDragEnd
      },
      results: [],
      activeGroup: null,
      showModal: false,
      positions: {},
      activeItem: {
        offerIndex: null,
        itemIndex: null
      },
      mobileOffers: []
    }
  },
  components: {
    MobileIcon,
    Words,
    WordModal,
    TaskItem,
    TaskBase,
    VideoAudioGallery,
    TaskAnswerModal
  },
  computed: {
    ...mapState({
      task: state => state.task.task,
    }),
  },
  beforeRouteUpdate (to, from, next) {
    if (to.fullPath !== from.fullPath) {
      Object.assign(this.$data, this.$options.data())
      this.taskInit(to.params.taskId)
    }
    next()
  },
  created () {
    this.taskInit(this.$route.params.taskId)
  },
  methods: {
    ...mapActions({
      getTask: 'task/getTask',
      missingTask: 'course/missingTask',
      getMissingTask: 'course/getMissingTask',
      offerTask: 'task/offerTask',
      setTaskAnswerModal: 'task/setTaskAnswerModal'
    }),
    pickWordModal (word, itemIndex) {
      this.showModal = !this.showModal
      let {
        offerIndex,
        index
      } = this.activeItem

      let wordGroupIndex,
        activeGroupIndex,
        tempData

      if (this.positions[word.id]) {

        const {
          groupIndex,
          index: itemPosIndex
        } = this.positions[word.id]

        tempData = this.offerGroups[offerIndex].groups[0].items[index]
        this.$set(this.offerGroups[offerIndex].groups[groupIndex].items, itemPosIndex, tempData)
        this.$set(this.offerGroups[offerIndex].groups[0].items, index, word)
        wordGroupIndex = 0
        activeGroupIndex = groupIndex
        itemIndex = itemPosIndex
      } else {
        tempData = this.offerGroups[offerIndex].groups[0].items[index]
        this.$set(this.offerGroups[offerIndex].groups[0].items, index, word)
        this.$set(this.offerGroups[offerIndex].groups[1].items, itemIndex, tempData)
        wordGroupIndex = 0
        activeGroupIndex = 1
      }

      this.setPositions(word, offerIndex, wordGroupIndex, index)
      this.setPositions(tempData, offerIndex, activeGroupIndex, itemIndex)
    },
    pickWord (word, offerIndex, index) {
      if (!this.isMobile) return false
      this.activeItem = {
        offerIndex,
        index
      }
      this.showModal = true
    },
    correctAnswer () {
      this.task.task.offers.map((offer, index) => {
        this.offerGroups[index].groups[0].items = offer.words.filter(item => item.word_select === 1).sort((a, b) => {
          if (a.id < b.id) {
            return -1
          } else if (a.id > b.id) {
            return 1
          }
          return 0
        })

        this.offerGroups[index].groups[0].items.forEach((item, i) => {
          this.setPositions(item, index, 0, i)
          this.mobileOffers[index] = this.mobileOffers[index].map(item => {
            return {
              ...item,
              success: true
            }
          })
        })

        this.offerGroups[index].groups[1].items = offer.words.map((item) => {
          if (item.word_select === 1) {
            return {
              word: item.word,
              id: '',
              temp_id: item.id,
              word_select: 1
            }
          } else {
            return item
          }

        })

        this.results[index] = this.results[index].map(() => true)

      })
    },
    async taskInit (taskId) {
      const query = {}
      if (this.$route.query.type) {
        query.type = this.$route.query.type
      }
      await this.getTask({
        id: taskId,
        query
      })
      this.task.task.offers.forEach((offer, offerIndex) => {

        this.results.push([])

        this.mobileOffers = {
          ...this.mobileOffers,
          [offerIndex]: []
        }
        this.offerGroups.push({
          groups: [
            {
              offer_id: offer.id,
              id: 1,
              items: offer.words.map(word => {
                this.results[offerIndex].push(null)
                return {
                  word: word.word,
                  id: '',
                  word_select: word.word_select,
                  temp_id: word.id,
                }
              })
            },
            {
              id: 2,
              items: offer.words.map(word => {
                this.mobileOffers[offerIndex].push({
                  ...word,
                  success: null
                })
                return word
              })
            }
          ],
        })

      })

    },
    validationTask () {
      let ready = true
      this.offerGroups.forEach(offer => {
        if (offer.groups[0].items.length === 0) {
          ready = false
        }
      })
      return ready
    },
    readyTask () {
      if (this.validationTask()) {
        this.offerTask({
          offers: this.offerGroups,
          ...this.checkParams()
        }).then(res => {
          if (res.data && res.data.answer) {
            this.results = this.results.map(result => result.map(() => true))
            this.setTaskAnswerModal(res.data).then(() => {
              this.$root.$emit('bv::show::modal', 'modal-task')
            })
          } else {
            this.results = res.data.results
            this.setTaskAnswerModal(false).then(() => {
              this.$root.$emit('bv::show::modal', 'modal-task')
            })
          }

          this.results.forEach((result, i) => {
            result.forEach((answer, j) => {
              const item = this.offerGroups[i].groups[0].items[j]

              if (item && this.positions[item.id]) {
                const mobileOffer = this.mobileOffers[i].find(offer => offer.id === item.id)
                this.$set(mobileOffer, 'success', answer)
              }
            })
          })
        })
      } else {
        this.$toasted.error('Заполните поле!').goAway(1500)
      }
    },
    onGroupsChange (e) {
      this.activeArrId = []
      this.offerGroups.forEach(offer => {
        offer.groups[0].items.map(item => {
          this.activeArrId.push(item.id)
        })
      })
    },
    setPositions (data, offerIndex, groupIndex, index) {
      if (!data.id) return false

      this.$set(this.positions, data.id, {
        groupIndex,
        index,
        offerIndex,
      })

    },
    onDragEnd (e) {
      const dragged = e.items[0]
      const related = e.droptarget

      if (!related) {
        e.stop()
      }

      const offerIndex = e.owner.parentElement.dataset.offerIndex
      const {
        groupIndex,
        index
      } = dragged.dataset

      const tempData = this.offerGroups[offerIndex].groups[groupIndex].items[index]
      const relatedData = this.offerGroups[offerIndex].groups[related.dataset.groupIndex].items[related.dataset.index]
      this.$set(this.offerGroups[offerIndex].groups[groupIndex].items, index, relatedData)
      this.$set(this.offerGroups[offerIndex].groups[related.dataset.groupIndex].items, related.dataset.index, tempData)

      this.setPositions(relatedData, offerIndex, related.dataset.groupIndex, related.dataset.index)
      this.setPositions(tempData, offerIndex, groupIndex, index)

      e.stop()
    },
    resetDrag (id, offerIndex) {
      this.offerGroups[offerIndex].groups[0].items = this.offerGroups[offerIndex].groups[0].items.filter(item => item.id !== id)
      this.activeArrId = this.activeArrId.filter(item => item !== id)
    }
  },
  directives: {
    dragAndDrop: VueDraggableDirective
  }
}
</script>

<style scoped lang="scss">
.offer-item {
  border-radius: 4px;
  min-width: 160px;
}

.owner-item {
  @media (max-width: 767px) {
    margin-right: 9px;
  }
}

.word-list {
  display: none !important;
  @media (min-width: 768px) {
    display: flex !important;
  }
}

.offer-main {
  border: 1px dashed #b6bbcc;
}

.owner-items {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }

  .owner-item {
    width: 46%;

    @media (min-width: 768px) {
      width: auto;
    }

    .word-item {
      width: 100%;
      min-width: 50%;
      height: 60px;
      border-radius: 10px;
      @media (min-width: 768px) {
        width: auto;
        height: auto;
        border-radius: 4px;
        min-width: 160px;
      }
    }
  }

}
</style>
