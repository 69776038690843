<template>
  <div>
    <b-navbar id="auth-header">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <div class="profile-header">
          <div class="profile-header-left">
            <div class="profile-header-logo">
              <router-link to="/">
                <img src="@/assets/images/logo.png" alt="logo" class="scroll-on">
              </router-link>
            </div>
            <div class="profile-header-left-currency mobile-hidden" v-if="currency && currency.length">
              <div class="toggle-item" v-for="(item, index) in currency" :key="index">
                <input @click="selectCurrency(item)" type="radio" name="currency"
                       :class="item.id === selectedCurrency.id ? 'checked' : ''"/>
                <div class="profile-header-left-currency-item active">
                  {{ item.symbol }}
                </div>
              </div>
            </div>
          </div>
          <div class="profile-header-right">
            <ul class="nav mobile-hidden header-nav">
              <li class="nav-item" v-if="can('access_notes')">
                <Notes />
              </li>
              <li class="nav-item">
                <a href="" class="nav-link">
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                      <img src="~@/assets/images/profile/message.png" alt="notes">
                    </template>
                    <div class="top-title">
                      ЧАТЫ
                    </div>
                    <b-dropdown-item>
                      <div class="p-3">
                        {{ chatNotifications && chatNotifications.length === 0 ? 'У вас еще нет Сообщения' : '' }}
                      </div>
                      <div class="support-content chat-content" v-for="notification in chatNotifications"
                           v-bind:key="notification.id">
                        <span class="date">{{ notification.created_at | moment('DD.MM.YYYY | hh:mm') }}</span>
                        <div class="info">
                          {{ notification.data.data.message }}
                        </div>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item to="/profile/chat" active-class="active-more">
                      <span>ПЕРЕЙТИ К ЧАТАМ</span>
                      <img src="~@/assets/images/gray-go-to.svg" alt="">
                    </b-dropdown-item>
                  </b-dropdown>
                </a>
              </li>
              <li class="nav-item">
                <div class="nav-link">
                  <b-dropdown variant="link" toggle-class="text-decoration-none" ref="formDropdown" no-caret>
                    <template #button-content>
                      <img src="~@/assets/images/profile/settings.png" alt="notes">
                    </template>
                    <div class="top-title">
                      ТЕХНИЧЕСКАЯ ПОДДЕРЖКА
                    </div>
                    <b-dropdown-form>
                      <div class="support-content">
                        <div class="info">Возникли вопросы или трудности с работой на сайте? Напишите нам.</div>
                        <ValidationObserver action="" ref="supportAdd" id="support-add" tag="form"
                                            @submit.prevent="sendSupport()">
                          <div class="form-group select">
                            <label>Тема обращения</label>
                            <span class="arrow"></span>
                            <select class="form-control" v-model="supportFormData.support_ticket_category_id">
                              <option :value="supportCategory.id"
                                      v-for="(supportCategory, index) in supportTicketCategoryList" v-bind:key="index">
                                {{ supportCategory.name[$i18n.locale] }}
                              </option>
                            </select>
                            <p class="text-danger mt-2 ml-3" v-if="errorCategoryMessage">{{ errorCategoryMessage }}</p>
                          </div>
                          <ValidationProvider rules="required" tag="div" v-slot="{ classes }" class="form-group">
                            <label>Сообщения</label>
                            <textarea placeholder="Ваше сообщения" v-model="supportFormData.message" :class="classes"
                                      class="form-control"></textarea>
                          </ValidationProvider>
                          <b-button class="support-send-btn" type="submit" :disabled="buttonDisable"
                                    :class="buttonDisable ? 'btn-disabled' : ''">отправить
                          </b-button>
                        </ValidationObserver>
                      </div>
                    </b-dropdown-form>
                    <b-dropdown-item to="/profile/technical-support" active-class="active-more">
                      <span>СМОТРЕТЬ ВСЕ ОБРАЩЕНИЯ</span>
                      <img src="~@/assets/images/gray-go-to.svg" alt="">
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </li>
              <li class="nav-item">
                <div class="nav-link">
                  <b-dropdown variant="link" toggle-class="text-decoration-none" ref="notifications" no-caret>
                    <template #button-content>
                      <div @click="markNotificationsAsRead">
                        <img src="~@/assets/images/profile/notifications.png" alt="notes">
                        <div class="badge" v-if="unreadNotificationsCount">{{ unreadNotificationsCount }}</div>
                      </div>
                    </template>
                    <div class="top-title">
                      УВЕДОМЛЕНИЯ
                    </div>
                    <b-dropdown-group>
                      <div class="p-3">
                        {{ notifications && notifications.length === 0 ? 'У вас еще нет уведомлений' : '' }}
                      </div>
                      <div class="dropdown-item no-active" v-for="(notification, index) in notifications" :key="index">
                        <NotificationItem :notification="notification" :index="index" :key="notification.id"/>
                      </div>
                    </b-dropdown-group>
                    <b-dropdown-item to="/profile/notifications" active-class="active-more">
                      <span>СМОТРЕТЬ ВСЕ</span>
                      <img src="~@/assets/images/gray-go-to.svg" alt="">
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </li>
            </ul>
            <div class="profile-header-right-auth-user">
              <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <div class="profile-header-right-auth-user-avatar scroll-on">
                    <img v-if="user && user.avatar" :src="user.avatar_path" alt="avatar">
                    <img v-else src="~@/assets/images/profile/add-image.svg" alt="avatar">
                  </div>
                  <div class="profile-header-right-auth-user-name scroll-on">
                    <span v-if="user">{{ user.login }}</span>
                    <img src="~@/assets/images/carret.svg" alt="carret">
                  </div>
                </template>
                <b-dropdown-item to="/profile/profile-details">
                  <span>Персональные данные</span>
                </b-dropdown-item>
                <b-dropdown-item to="/profile/payment-history">
                  <span>История оплат</span>
                </b-dropdown-item>
                <b-dropdown-item @click="signOut">
                  <span>Выход из кабинета</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="profile-header-right-language">
              <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <div class="profile-header-right-language-code scroll-on">
                    <span>{{ $i18n.locale }}</span>
                    <img src="~@/assets/images/carret-purple.svg" alt="carret">
                  </div>
                </template>
                <b-dropdown-item v-for="(language, i) in languages" :key="i" @click="changeLanguage(language.code)">
                  <span class="text-uppercase">{{ language.code }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="mobile-menu">
              <a href="#" :class="['toggle', 'pp-toggle']" @click.prevent="toggleMenu(isOpenMenu = !isOpenMenu)">
                <svg v-if="isOpenMenu" version="1.1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                  <path d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="19" viewBox="0 0 24 19">
                  <g id="menu_burgerr" data-name="menu burgerr" transform="translate(-350 -21)">
                    <rect id="Прямоугольник_3_копия" data-name="Прямоугольник 3 копия" width="16" height="3.19" rx="1.58" transform="translate(350 28.91)" fill="#040404"/>
                    <rect id="Прямоугольник_3_копия_3" data-name="Прямоугольник 3 копия 3" width="24" height="3.16" rx="1.58" transform="translate(350 36.84)" fill="#040404"/>
                    <rect id="Прямоугольник_3_копия_2" data-name="Прямоугольник 3 копия 2" width="24" height="3.16" rx="1.58" transform="translate(350 21)" fill="#040404"/>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </b-collapse>
    </b-navbar>
    <div class="mobile-visible-content">
      <div class="profile-header-left-currency" v-if="currency && currency.length">
        <div class="toggle-item" v-for="(item, index) in currency" :key="index">
          <input @click="selectCurrency(item)" type="radio" name="currency"
                 :class="item.id === selectedCurrency.id ? 'checked' : ''"/>
          <div class="profile-header-left-currency-item active">
            {{ item.symbol }}
          </div>
        </div>
      </div>
      <ul class="nav header-nav pp-header-nav">
        <li class="nav-item" v-if="can('access_notes')">
          <Notes />
        </li>
        <li class="nav-item">
          <a href="" class="nav-link">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <img src="~@/assets/images/profile/message.png" alt="notes">
              </template>
              <div class="top-title">
                ЧАТЫ
              </div>
              <b-dropdown-item>
                <!--                <div class="support-content chat-content">-->
                <!--                  <div class="date">15.04.2020 | 10:20</div>-->
                <!--                  <div class="info">Пользователь-->
                <!--                    <a href="#">nikname</a>-->
                <!--                    приглашает Вас присоедениться к чату-->
                <!--                  </div>-->
                <!--                  <div class="actions">-->
                <!--                    <button type="button">ПРИСОЕДЕНИТЬСЯ</button>-->
                <!--                    <button type="button">ОТКАЗАТЬСЯ</button>-->
                <!--                  </div>-->
                <!--                </div>-->
              </b-dropdown-item>
              <b-dropdown-item>
                <span>ПЕРЕЙТИ К ЧАТАМ</span>
                <img src="~@/assets/images/gray-go-to.svg" alt="">
              </b-dropdown-item>
            </b-dropdown>
          </a>
        </li>
        <li class="nav-item">
          <div class="nav-link">
            <b-dropdown variant="link" toggle-class="text-decoration-none" ref="formDropdown" no-caret>
              <template #button-content>
                <img src="~@/assets/images/profile/settings.png" alt="notes">
              </template>
              <div class="top-title">
                ТЕХНИЧЕСКАЯ ПОДДЕРЖКА
              </div>
              <b-dropdown-form>
                <div class="support-content">
                  <div class="info">Возникли вопросы или трудности с работой на сайте? Напишите нам.</div>
                  <ValidationObserver action="" ref="supportAdd" id="supportAdd" tag="form"
                                      @submit.prevent="sendSupport()">
                    <div class="form-group select">
                      <label>Тема обращения</label>
                      <span class="arrow"></span>
                      <select class="form-control" v-model="supportFormData.support_ticket_category_id">
                        <option :value="supportCategory.id"
                                v-for="(supportCategory, index) in supportTicketCategoryList" v-bind:key="index">
                          {{ supportCategory.name[$i18n.locale] }}
                        </option>
                      </select>
                      <p class="text-danger mt-2 ml-3" v-if="errorCategoryMessage">{{ errorCategoryMessage }}</p>
                    </div>
                    <ValidationProvider rules="required" tag="div" v-slot="{ classes }" class="form-group">
                      <label>Сообщения</label>
                      <textarea placeholder="Ваше сообщения" v-model="supportFormData.message" :class="classes"
                                class="form-control"></textarea>
                    </ValidationProvider>
                    <b-button class="support-send-btn" type="submit" :disabled="buttonDisable"
                              :class="buttonDisable ? 'btn-disabled' : ''">отправить
                    </b-button>
                  </ValidationObserver>
                </div>
              </b-dropdown-form>
              <b-dropdown-item to="/profile/technical-support" active-class="active-more">
                <span>СМОТРЕТЬ ВСЕ ОБРАЩЕНИЯ</span>
                <img src="~@/assets/images/gray-go-to.svg" alt="">
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <img src="~@/assets/images/profile/notifications.png" alt="notes">
                <div class="badge" v-if="unreadNotificationsCount">{{ unreadNotificationsCount }}</div>
              </template>
              <div class="top-title">
                УВЕДОМЛЕНИЯ
              </div>
              <b-dropdown-item v-for="(notification, index) in notifications" :key="index">
                <NotificationItem :notification="notification" :index="index" :key="notification.id"/>
              </b-dropdown-item>
              <b-dropdown-item to="/profile/notifications">
                <span>СМОТРЕТЬ ВСЕ</span>
                <img src="~@/assets/images/gray-go-to.svg" alt="">
              </b-dropdown-item>
            </b-dropdown>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import Swal from 'sweetalert2'
import EventBus from '../../../../utility/event-bus'
import i18n, { loadLanguageAsync } from '../../../localization/i18n'
import { getPermissions } from '../../../helpers'
import NotificationItem from '../NotificationItem'
import Notes from '../../components/Notes'
import HasPermissionMixin from '../../../mixins/HasPermissionMixin'

export default {
  name: 'ProfileHeader',
  mixins: [HasPermissionMixin],
  components: {
    Notes,
    NotificationItem
  },
  data () {
    return {
      isOpenMenu: false,
      supportFormData: {},
      buttonDisable: false,
      errorCategoryMessage: '',
      acceptLoader: false,
      noteFormData: {}
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      notifications: state => state.user.notifications,
      chatNotifications: state => state.user.chatNotifications,
      unreadNotificationsCount: state => state.unreadNotificationsCount,
      user: state => state.auth.user,
      supportTicketCategoryList: state => state.supportTicket.supportTicketCategoryList,
      currency: state => state.setting.currency,
      selectedCurrency: state => state.setting.selectedCurrency,
      users: state => state.user.users,
      chatList: state => state.courseChat.chatList,
      videoChat: state => state.courseChat.videoChat,
      peer: state => state.courseChat.peer,
      stream: state => state.courseChat.stream,
      videoChatUsers: state => state.courseChat.videoChatUsers,
    })
  },
  mounted () {
    this.scrollOn()
    this.menuClickListener()
    this.getNotifications().then(() => {
    }).catch(() => {
      this.$toasted.error('Something went wrong!').goAway(1500)
    })
    this.getChatNotifications().then(() => {
    }).catch(() => {
      this.$toasted.error('Something went wrong!').goAway(1500)
    })
    this.getUnreadNotificationsCount().then(() => {
    }).catch(() => {
      this.$toasted.error('Something went wrong!').goAway(1500)
    })
    this.listenForNotification()
  },
  created () {
    this.getLanguages().catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
    this.getCurrency().then(() => {
      if (!this.selectedCurrency) {
        for (let i = 0; i < this.currency.length; i++) {
          if (this.currency[i].code === 'rub') {
            this.setSelectedCurrency(this.currency[i])
          } else {
            this.setSelectedCurrency(this.currency[0])
          }
        }
      }
    }).catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
    this.getSupportTicketCategoryList().catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
    this.getTeachingLangs().then(res => {
    }).catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
    this.onlineUser()
    this.peerInit()
  },
  methods: {
    ...mapActions({
      getLanguages: 'language/getLanguages',
      logOut: 'auth/logOut',
      getSupportTicketCategoryList: 'supportTicket/getSupportTicketCategoryList',
      newSupportTicket: 'supportTicket/newSupportTicket',
      getCurrency: 'setting/getCurrency',
      getUnreadNotificationsCount: 'user/getUnreadNotificationsCount',
      markAsReadNotifications: 'user/markAsReadNotifications',
      getNotifications: 'user/getNotifications',
      getChatNotifications: 'user/getChatNotifications',
      acceptInvitation: 'courseChat/acceptInvitation',
      declineInvitation: 'courseChat/declineInvitation',
      getTeachingLangs: 'teachingLang/getTeachingLangs',
    }),
    ...mapMutations({
      setUsers: 'user/setUsers',
      setVideoChat: 'courseChat/setVideoChat',
      setPeer: 'courseChat/setPeer',
      setStream: 'courseChat/setStream',
      setVideoCallUsers: 'courseChat/setVideoCallUser',
      setMediaConnection: 'courseChat/setMediaConnection',
      setNotificationChannel: 'user/setChannel',
      setSelectedCurrency: 'setting/setSelectedCurrency'
    }),
    menuClickListener () {
      const elements = document.getElementsByClassName('profile-sidebar-menu-item')
      Array.from(elements).forEach((element) => {
        element.addEventListener('click', () => {
          this.toggleMenu(this.isOpenMenu = false)
        })
      })
    },
    scrollOn() {
      this.toggleMenu(false)
      const elements = document.getElementsByClassName('scroll-on')
      Array.from(elements).forEach((element) => {
        element.addEventListener('click', () => {
          document.getElementsByTagName('body')[0].style.overflowY = 'scroll'
        })
      })
    },
    echoInit () {
      this.$echo.options.auth.headers = {
        ...this.$echo.options.auth.headers,
        Authorization: `Bearer ${localStorage.access_token}`
      }
    },
    peerInit () {
      this.setPeer()
      this.peer.on('call', async call => {
        let userStream = this.stream
        if (!userStream) {
          userStream = await getPermissions()
        }
        this.setStream(userStream)
        this.setMediaConnection(call)
        call.answer(userStream)

        call.on('stream', stream => {
          const tempObject = {
            stream,
            ...this.videoChatUsers[call.peer]
          }
          this.setVideoCallUsers({
            data: tempObject,
            type: 'push'
          })
        })
        call.on('close', e => {
          console.log('streamClose', e)
        })
      })
    },
    changeLanguage (lang) {
      this.$i18n.locale = lang
      loadLanguageAsync(i18n.locale).then(() => localStorage.setItem('lang', lang))
    },
    listenForNotification () {
      const channel = this.$echo.private('App.Models.User.' + this.user.id).notification((notification) => {
        if (notification.type === 'invite_chat') {
          this.notifications.unshift({
            created_at: notification.data.chat.created_at,
            id: notification.id,
            data: {
              data: {
                user: notification.data.user,
                type: notification.type,
                chat: notification.data.chat
              }
            }
          })
        } else if (notification.type === 'accepted_invite') {
          this.notifications.unshift({
            created_at: notification.data.chat.created_at,
            id: notification.id,
            data: {
              data: {
                message: notification.data.message,
                type: notification.type
              }
            }
          })

          this.chatList.push(notification.data.chat)
        } else if (notification.type === 'start_video_call') {
          this.notifications.unshift({
            created_at: notification.data.chat.created_at,
            id: notification.id,
            data: {
              data: {
                message: notification.data.message,
                type: notification.type,
                peer_id: notification.data.peer_id,
                user: notification.data.user,
                chat: notification.data.chat,
                video_chat_key: notification.data.video_chat_key
              }
            }
          })
        }
        if (notification.type !== 'new_message') {
          this.$refs.notifications.show()
        }
      })

      this.setNotificationChannel(channel)
    },
    markNotificationsAsRead () {
      this.markAsReadNotifications()
    },
    toggleMenu (toggle) {
      EventBus.$emit('toggleMenu', toggle)
      EventBus.$on('toggleMenu', data => {
        this.isOpenMenu = data
        if (this.isOpenMenu) {
          document.getElementsByTagName('body')[0].style.overflowY = 'hidden'
        } else {
          document.getElementsByTagName('body')[0].style.overflowY = 'scroll'
        }
      })
    },
    signOut () {
      Swal.fire({
        text: this.$t('messages.warning.signOut'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.logOut().then(() => {
            this.$router.push('/')
          })
        }
      })
    },
    sendSupport () {
      if (!this.supportFormData.support_ticket_category_id) {
        this.errorCategoryMessage = 'Select the subject of the appeal'
        return false
      } else {
        this.errorCategoryMessage = ''
      }
      this.$refs.supportAdd.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          this.newSupportTicket(this.supportFormData).then(() => {
            this.$toasted.success('Новое сообщение создано успешно!').goAway(1500)
            this.supportFormData = {}
            this.$refs.supportAdd.reset()
            this.$refs.formDropdown.hide(true)
          }).catch(() => {
            this.$toasted.error('Something went wrong!').goAway(1500)
          }).finally(() => {
            this.buttonDisable = false
          })
        } else {
          this.$toasted.error('There are uncompleted fields').goAway(1500)
        }
      })
    },
    onlineUser () {
      console.log('users', this.users)
      this.$echo.join(`online`)
        .here((users) => {
          console.log('onlineHere', users.map((user => user.user)))
          this.setUsers({
            data: users,
            type: 'here'
          })
        })
        .leaving((user) => {
          console.log('onlineleaving', user)
          this.setUsers({
            data: user,
            type: 'leaving'
          })
        })
        .joining((user) => {
          console.log('Onlinejoining', user)
          this.setUsers({
            data: user,
            type: 'joining'
          })

        })
    },
    selectCurrency (currency) {
      this.setSelectedCurrency(currency)
    }
  }
}
</script>
<style lang="scss">
.dropdown-item.active {
  background-color: #78258D !important;
  color: #fff !important;
}

</style>
