<template>
  <div class="support personal-details">
    <div class="tabs-title">
      Техническая поддержка
    </div>
    <div class="support-content">
      <router-link to="/profile/technical-support" type="button" class="new-template new-btn">
        <img src="~@/assets/images/profile/back-icon.svg" alt="">
        НАЗАД КО ВСЕМ ОБРАЩЕНИЯМ
      </router-link>
      <div class="sub-title">Создать новое обращение</div>
      <div class="tabs-info">
        Возникли вопросы или трудности с работой на сайте? Напишите нам.
      </div>
    </div>
    <ValidationObserver ref="supportAddForm" id="supportAddForm" tag="form" @submit.prevent="formSubmit()">
      <label>Тема обращения</label>
      <div class="b-dropdown">
        <b-dropdown class="select-dropdown" :text="Object.keys(category.name).length > 0?category.name[$i18n.locale]:'Выберите один из вариантов'">
          <b-dropdown-item href="#" @click="selectCategory(supportCategory)" v-for="(supportCategory, index) in supportTicketCategoryList" v-bind:key="index">{{supportCategory.name[$i18n.locale]}}</b-dropdown-item>
        </b-dropdown>
      </div>
      <p class="text-danger mt-2 ml-3" v-if="errorCategoryMessage">{{errorCategoryMessage}}</p>
      <ValidationProvider rules="required" tag="div" v-slot="{ classes }" class="form-group">
        <label>Сообщения</label>
        <textarea class="form-control w-100" v-model="formData.message" :class="classes" placeholder="Ваше сообщение"></textarea>
      </ValidationProvider>
      <button type="submit" :disabled="buttonDisable" class="send-btn" :class="buttonDisable ? 'btn-disabled' : ''">ОТПРАВИТЬ</button>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'NewTemplate',
  data () {
    return {
      category: {
        name: {}
      },
      formData: {},
      buttonDisable: false,
      errorCategoryMessage: ''
    }
  },
  computed: {
    ...mapState({
      supportTicketCategoryList: state => state.supportTicket.supportTicketCategoryList
    })
  },
  methods: {
    ...mapActions({
      newSupportTicket: 'supportTicket/newSupportTicket'
    }),
    selectCategory (category) {
      this.errorCategoryMessage = ''
      this.category = category
      this.formData.support_ticket_category_id = category.id
    },
    formSubmit () {
      if (!this.formData.support_ticket_category_id) {
        this.errorCategoryMessage = 'Выберите тему обращения'
        return false
      } else {
        this.errorCategoryMessage = ''
      }
      this.$refs.supportAddForm.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          this.newSupportTicket(this.formData).then(({id}) => {
            this.$toasted.success('Новое сообщение создано успешно!').goAway(1500)
            this.$refs.supportAddForm.reset()
            this.formData = {}
            this.$router.push({name: 'template', params: {id}})
          }).catch(() => {
            this.$toasted.error('Something went wrong!').goAway(1500)
          }).finally(() => {
            this.buttonDisable = false
          })
        } else {
          this.$toasted.error('Есть незаполненные поля').goAway(1500)
        }
      })
    }
  }
}
</script>
