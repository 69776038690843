import { http } from '../../../api'

const auth = {
  namespaced: true,
  state: {
    user: {},
    isAuthenticated: false,
    token: null,
    registration: true
  },
  actions: {
    login: ({ commit }, params) => {
      return http.post('/login', params).then(res => {
        commit('userLogin', res.data)
      })
    },
    fbLogin: ({ commit }, params) => {
      return http.post('/login-fb', params).then(res => {
        commit('userLogin', res.data)
      })
    },
    vkLogin: ({ commit }, params) => {
      return http.post('/login-vk', params).then(res => {
        commit('userLogin', res.data)
      })
    },
    googleLogin: ({ commit }, params) => {
      return http.post('/login-google', params).then(res => {
        commit('userLogin', res.data)
      })
    },
    logOut: ({ commit }) => {
      return http.get('/logout').then(() => {
        commit('userLogout')
      })
    },
    authUser: ({ commit }) => {
      return http.get('/me').then((res) => {
        commit('setUser', res.data)
      })
    },
    register: ({ commit }, params) => {
      return http.post('/register', params).then((res) => {
        commit('userLogin', res.data)
        return res
      })
    },
    resetPassword: ({ commit }, params) => {
      return http.post('/password/reset', params).then(res => {
        return res
      })
    },
    forgotPassword: ({ commit }, params) => {
      return http.post('/forgot-password', params).then((res) => {
        return res
      })
    },
    setLoginModal: ({ commit }, params) => {
      commit('setModal', params)
    }
  },
  mutations: {
    setModal (state, data) {
      state.registration = data
    },
    setUser (state, data) {
      state.user = {...state.user, ...data}
    },
    userLogin (state, data) {
      const accessToken = data.access_token
      localStorage.setItem('access_token', accessToken)
      localStorage.setItem('user', JSON.stringify(data.user))
      http.defaults.headers.common.Authorization = accessToken
      state.isAuthenticated = true
      state.token = accessToken
      state.user = data.user
    },
    userLogout (state) {
      localStorage.removeItem('access_token')
      localStorage.removeItem('user')
      http.defaults.headers.common.Authorization = ''
      state.isAuthenticated = false
      state.access_token = null
      state.user = null
    }
  },
  getters: {
    user: state => state.user,
    isAuthenticated: state => state.isAuthenticated,
    registration: state => state.registration
  }
}

export default auth
