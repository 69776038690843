<template>
  <ValidationProvider tag="div" class="contact-form__line phone-input-custom" name="Телефон" rules="required"
                      v-slot="{ classes, errors }">
    <label>{{ label }}</label>
    <VuePhoneNumberInput :no-example="false"
                         :default-country-code="countryCode"
                         :error="!valid"
                         :translations="translations"
                         v-model="phone"
                         @update="update"
                         :only-countries="onlyCountries"
    />
  </ValidationProvider>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PhoneInput',
  components: { VuePhoneNumberInput },
  props: {
    submit: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    inputClass: {
      type: String,
      default: ''
    },
    countryCode: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      phoneValid: false,
      onlyCountries: [],
      translations: {
        countrySelectorLabel: 'Код страны',
        phoneNumberLabel: 'Номер телефона',
        example: 'Пример :'
      },
      inputOptions: {
        placeholder: this.placeholder
      },
      phone: this.value,
      valid: true,
      validCount: 0
    }
  },
  watch: {
    value (newVal) {
      this.phone = newVal
    },
    valid (newVal){
      this.$emit('onValid', newVal)
    }
  },
  computed:{
    ...mapGetters({
      phones: 'phones/phones'
    })
  },
  created () {
    this.getPhones().then(() => {
      this.onlyCountries = this.phones.map(value => value.country_code)
    })
  },
  methods: {
    ...mapActions({
      getPhones: 'phones/get'
    }),
    update (event) {
      if (this.validCount !== 0) {
        this.valid = event.isValid
      }
      const doc = document.querySelector('.input-tel__label.text-danger')
      if (doc){
        console.log('country_code', this.countryCode)
        doc.innerHTML = this.phones.filter(value => value.country_code === this.countryCode)[0].example
      }
      console.log('update', event.countryCode)
      this.$emit('update:countryCode', event.countryCode)
      this.$emit('input', event.formattedNumber)
      this.$emit('valid', event.isValid)
      this.validCount++
    },
  }
}
</script>

<style lang="scss">

.phone-input-custom {

  input {
    border-radius: 0 30px 30px 0 !important;

  }

  .country-selector__input {
    border-radius: 30px 0 0 30px !important;
  }
}

.about-phone-input {
  margin-bottom: 30px;

  input {
    height: 64px !important;
  }
}

.auth-phone-input {
  margin-bottom: 45px !important;

  .country-selector {
    height: 74px !important;
    .country-selector__country-flag {
      top: 30px !important;
      left: 16px !important;
    }
    .country-selector__input{
      padding-left: 40px !important;
    }

  }

  input {
    height: 74px !important;
  }
}

.profile-phone-input {
  .input-tel__input {
    width: 390px !important;
  }

  .country-selector__input {
    width: 100% !important;
    padding-top: 20px !important;
  }
}
.country-selector__label {
  top: 5px !important;
  left: 22px !important;
}

.vti__dropdown.open, .vti__dropdown:hover {
  background-color: #f3f3f3;
  border-radius: 0 0 0 30px;
}

.vti__dropdown {
  background-color: white;
  border-radius: 0 0 0 30px;
}

.vti__dropdown-item {
  color: black;
}
.country-selector__input, .input-tel__input {
  border: none !important;
}

</style>
