<template>
  <div class="col-12 col-md-6 news-wrapper">
    <div class="news-container">
      <div class="news-image cursor-pointer d-flex justify-content-center align-items-center"
           @click="$router.push('/news/' + news.id)" :style="`background-image: url(${image});`">
      </div>
      <div class="news-block">
        <div @click="$router.push('/news/' + news.id)" class="title cursor-pointer">{{ news.title[$i18n.locale] }}</div>
        <div class="subtitle">{{ news.created_at | moment('MM.DD.YYYY') }}</div>
        <div class="text">
          <v-clamp :max-lines="6">{{ news.short_description[$i18n.locale] }}</v-clamp>
        </div>
        <router-link :to="'/news/' + news.id" class="more">{{ $t('buttons.read_more') }} <span>&raquo;</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import VClamp from 'vue-clamp'

export default {
  name: 'NewsVideoCard',
  components: { VClamp },
  props: {
    news: {
      type: Object,
      default: {}
    }
  },
  computed: {
    image () {
      return  this.news.image_paths?.medium || require('@/assets/images/default.png')
    }
  },
  mounted () {
    console.log('news', this.news)
  },
  updated () {
    console.log('image', this.image)
  }
}
</script>

<style scoped>

</style>
