var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main mb-5",class:{
    'margin-auto': _vm.taskPresentation === 2,
    'align-items-center': _vm.taskPresentation === 2
  }},[(_vm.question.image)?_c('div',{staticClass:"img mb-4"},[_c('img',{staticClass:"image",attrs:{"src":_vm.question.image,"alt":"image"}})]):_vm._e(),(_vm.question.video || _vm.question.video_iframe)?_c('div',{staticClass:"video mb-4"},[_c('VideoPlayer',{attrs:{"video":{ video_path: _vm.question.video, video_iframe: _vm.question.video_iframe }}})],1):_vm._e(),(_vm.question.audio)?_c('div',{staticClass:"main-audio mb-4"},[_c('mini-audio',{attrs:{"audio-source":_vm.question.audio}})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.question.prompt))]),_c('div',{staticClass:"d-flex flex-row gap mb-4"},[(_vm.question.on_sound === 1)?_c('div',{staticClass:"mr-4"},[_c('PlayerButton',{attrs:{"color":"var(--primary)","answer":_vm.question.answer,"lang":_vm.lang,"height":'40px',"width":'40px'}})],1):_vm._e(),(_vm.hasSelectBtn)?_c('div',{staticClass:"btn btn__container d-flex flex-row"},[_c('div',{staticClass:"btn btn__item",class:{ active: _vm.activeBlock === 'text' },on:{"click":function($event){return _vm.changeCollapse('text')}}},[_vm._v(" Написать текстом ")]),_c('div',{staticClass:"btn btn__item",class:{ active: _vm.activeBlock === 'voice' },on:{"click":function($event){return _vm.changeCollapse('voice')}}},[_vm._v(" Записать аудио ")])]):_vm._e()]),_c('div',{staticClass:"d-flex align-items-start w-100",class:{ 'justify-content-center': _vm.taskPresentation === 2 }},[(_vm.activeBlock === 'text')?_c('input',{class:{
        'custom-input': true,
        success: _vm.answer.success,
        error: _vm.answer.error
      },attrs:{"placeholder":"Запишите ответ"},domProps:{"value":_vm.answer.text_answer},on:{"input":function($event){return _vm.changeText($event)}}}):_vm._e(),(_vm.activeBlock === 'voice')?_c('div',{staticClass:"audio__container w-100 d-flex align-items-start ",class:{ 'justify-content-center': _vm.taskPresentation === 2 }},[_c('div',{staticClass:"audio__wrapper"},[(_vm.hasRecord)?_c('div',{staticClass:"btn-voice btn-voice_primary d-flex align-items-start justify-content-start position-absolute zindex-0",class:{
            success: _vm.answer.success && _vm.hasRecord,
            error: _vm.answer.error
          }},[_c('img',{staticClass:"mr-4",attrs:{"src":require("@/assets/images/icons/music.svg"),"alt":"music"}}),_vm._v(" Записано ")]):_vm._e(),_c('div',{directives:[{name:"touch",rawName:"v-touch:start",value:(_vm.start),expression:"start",arg:"start"},{name:"touch",rawName:"v-touch:end",value:(_vm.stop),expression:"stop",arg:"end"}],staticClass:"btn-voice cursor-pointer d-flex align-items-start justify-content-start",class:{
            active: _vm.isSpeaking,
            'has-record': _vm.hasRecord && !_vm.isSpeaking
          },on:{"mousedown":_vm.start,"mouseup":_vm.stop}},[(!_vm.isSpeaking)?_c('img',{staticClass:"mr-4",attrs:{"src":require("@/assets/images/icons/microphone-primary.svg"),"alt":"microphone"}}):_vm._e(),(_vm.isSpeaking)?_c('img',{staticClass:"mr-4",attrs:{"src":require("@/assets/images/icons/pause.svg"),"alt":"pause"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.isSpeaking ? 'Остановить' : 'Записать')+" ")])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }