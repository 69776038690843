<template>
  <div class="col-12 about px-0">
    <bread-crumbs :pages="pages" />
    <div class="about-text">
      <div class="about-text-container page-container" v-if="page">
        <div class="title" v-if="page.title">{{ page.title[$i18n.locale] }}</div>
        <div class="subtitle">Online школа иностранных языков Language to Go</div>
        <div class="text w-100 word-break-all" v-if="page.dynamic_page_texts">
          <div v-html="page.dynamic_page_texts[0].description[$i18n.locale]"></div>
        </div>
      </div>
    </div>
    <div class="about-section justify-content-center">
      <div class="about-section-content col-11">
        <div class="about-section-content-info-top">online школа иностранных языков</div>
        <div class="about-section-content-title">Language to Go</div>
        <div class="about-section-content-info-bottom">
          Посмотрите наш промо ролик чтобы познакомиться с нами поближе!
        </div>
        <div class="about-section-content-button">
          <button class="btn-promo-video btn-promo-video-about" @click="openVideoModal">
            <img src="@/assets/images/icons/play.svg" alt="logo" />
            {{ $t('buttons.watch_promo_video') }}
          </button>
        </div>
      </div>
    </div>
    <div class="about-founder">
      <div class="about-founder-container page-container">
        <div class="about-founder-container-image">
          <img src="../../../assets/images/founder.png" alt="founder" />
        </div>
        <div class="about-founder-container-about">
          <div class="about-founder-container-about-name">Фамилия Имя</div>
          <div class="about-founder-container-about-subtitle">Основатель Language to Go</div>
          <div class="about-founder-container-about-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis faucibus mauris ac
            sagittis tincidunt. Nulla pharetra et mi in tincidunt. Nunc eleifend, ex sed elementum
            venenatis, arcu sapien interdum magna, non feugiat sem ante id elit. Mauris eget
            ultricies neque, elementum mollis felis. Morbi est elit, vestibulum eu molestie id,
            suscipit sodales massa. Sed semper ligula nibh. Curabitur sit amet nibh vel ligula
            posuere malesuada vitae at odio. Sed ac ante elit. Nullam vitae luctus nisi, ac
            consequat justo. Ut accumsan purus non tellus mattis pharetra sit amet in enim. Nullam a
            urna eu nisl semper porta a sed nisi. Mauris libero arcu, egestas at congue vel, pretium
            ac sapien. Nam rhoncus sem ac augue lobortis laoreet. Morbi sit amet nunc vel felis
            tempor accumsan. Vestibulum a diam sed ipsum tincidunt congue pretium non libero. Mauris
            dictum porttitor libero ut cursus.
          </div>
        </div>
      </div>
    </div>
    <div class="page-container">
      <Benefits />
      <HowWork />
      <ReviewsCarousel />
    </div>
    <b-modal hide-footer hide-header ref="promoVideoModal" content-class="video-modal video-full">
      <div class="close-modal" @click="closeVideoModal">
        <img src="../../../assets/images/icons/close.svg" alt="Close" />
      </div>
      <div class="modal-block p-1" v-if="settings.promo_video">
        <div class="video">
          <div
            class="video"
            v-if="settings.promo_video"
            v-html="JSON.parse(this.settings.promo_video).video_iframe"
          ></div>
          <div class="video" v-if="settings.promo_video.video_path">
            <video width="100%" :src="videoModal.promo_video.video_path" controls></video>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Benefits from '../../components/Benefits'
import HowWork from '../../components/HowWork'
import BreadCrumbs from '../../components/BreadCrumbs'
import ReviewsCarousel from '../../components/ReviewsCarousel'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AboutUs',
  components: {
    Benefits,
    BreadCrumbs,
    ReviewsCarousel,
    HowWork
  },
  data() {
    return {
      pages: [
        { path: '/', rout: 'Главная', active: false },
        { path: '', rout: 'О нас', active: true }
      ]
    }
  },
  computed: {
    ...mapState({
      page: state => state.dynamicPage.page,
      settings: state => state.setting.settings
    })
  },
  metaInfo() {
    const title = this.$t('about.title')
    return {
      title: title,
      meta: [
        {
          name: 'og:title',
          content: title
        }
      ]
    }
  },
  mounted() {
    this.dynamicPageByKey({ key: 'about_us' })
  },
  methods: {
    ...mapActions({
      dynamicPageByKey: 'dynamicPage/dynamicPageByKey'
    }),
    openVideoModal() {
      this.$refs.promoVideoModal.show()
    },
    closeVideoModal() {
      this.$refs.promoVideoModal.hide()
    }
  }
}
</script>
