<template>
  <div>
    <div class="personal-details statistics">
      <div>
        <div>
          <div class="title-block">
            <div class="d-flex align-items-center" v-if="language">
              <div class="flag">
                <img :src="language.flag_path" alt="flag">
              </div>
              <div>
                <div class="tabs-title">
                  Задания
                </div>
                <div class="subtitle">
                  {{ language.name[$i18n.locale] }}
                </div>
              </div>
            </div>
            <div class="tabs-info" v-if="title">
              {{ title }}
            </div>
          </div>
        </div>
        <!--No Tasks-->
        <Loading v-if="$store.getters['loading/loading']"/>
        <div v-else class="statistics-content">
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading'

export default {
  name: 'ReplyTasksBase',
  components: { Loading },
  props: {
    language: {
      type: [Object, null],
      required: true
    },
    title: {
      type: String,
      default: null
    },
  }
}
</script>
