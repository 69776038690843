<template>
  <div class="news-detail col-12 px-0">
    <bread-crumbs :pages="pages"/>
    <div class="page-container">
      <loading v-if="loading"/>
      <div class="news-detail-block row" v-else>
        <div class="news-detail-block-left-section col-12 col-lg-8">
          <div class="news-detail-block-left-section">
            <div ref="title" class="news-detail-block-left-section-title">{{ news.title[$i18n.locale] }}</div>
            <div class="news-detail-block-left-section-subtitle">{{ news.created_at  | moment('MM.DD.YYYY') }}</div>
            <div class="news-detail-block-left-section-news">
              <div class="image" v-if="news.image_paths">
                <img :src="news.image_paths.full" :alt="news.image_alt">
              </div>
              <div class="text" v-html="news.description[$i18n.locale]"></div>
              <div class="video" v-if="news.video_path">
                <video
                  width="100%"
                  :src="news.video_path"
                  controls
                >
                </video>
              </div>
              <div class="video" v-if="news.video_iframe">
                <div class="video" v-if="news.video_iframe" v-html="news.video_iframe"></div>
              </div>
              <div class="social-media">
                <div class="social-media-share">Поделитесь публикацией в соц сетях</div>
                <div class="social-media-icons">
                  <ShareNetwork
                    network="facebook"
                    :url="url"
                    :title="news.title[$i18n.locale]"
                    :description="news.description[$i18n.locale]"
                    :quote="news.title[$i18n.locale]"
                    hashtags="languageToGo"
                  >
                    <div>
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                           xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 96.124 96.123"
                           style="enable-background:new 0 0 96.124 96.123;" xml:space="preserve"><g><path fill="#2e90d1" d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z"/></g></svg>
                    </div>
                  </ShareNetwork>
                  <ShareNetwork
                    network="pinterest"
                    :url="url"
                    :title="news.title[$i18n.locale]"
                    :description="news.description[$i18n.locale]"
                    :quote="news.title[$i18n.locale]"
                    hashtags="languageToGo"
                  >
                    <div>
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                           xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 511.977 511.977"
                           style="enable-background:new 0 0 511.977 511.977;" xml:space="preserve"><g><g><path fill="#2e90d1" d="M262.948,0C122.628,0,48.004,89.92,48.004,187.968c0,45.472,25.408,102.176,66.08,120.16c6.176,2.784,9.536,1.6,10.912-4.128c1.216-4.352,6.56-25.312,9.152-35.2c0.8-3.168,0.384-5.92-2.176-8.896c-13.504-15.616-24.224-44.064-24.224-70.752c0-68.384,54.368-134.784,146.88-134.784c80,0,135.968,51.968,135.968,126.304c0,84-44.448,142.112-102.208,142.112c-31.968,0-55.776-25.088-48.224-56.128c9.12-36.96,27.008-76.704,27.008-103.36c0-23.904-13.504-43.68-41.088-43.68c-32.544,0-58.944,32.224-58.944,75.488c0,27.488,9.728,46.048,9.728,46.048S144.676,371.2,138.692,395.488c-10.112,41.12,1.376,107.712,2.368,113.44c0.608,3.168,4.16,4.16,6.144,1.568c3.168-4.16,42.08-59.68,52.992-99.808c3.968-14.624,20.256-73.92,20.256-73.92c10.72,19.36,41.664,35.584,74.624,35.584c98.048,0,168.896-86.176,168.896-193.12C463.62,76.704,375.876,0,262.948,0z"/></g></g></svg>
                    </div>
                  </ShareNetwork>
                  <ShareNetwork
                    network="twitter"
                    :url="url"
                    :title="news.title[$i18n.locale]"
                    :description="news.description[$i18n.locale]"
                    :twitter-user="news.title[$i18n.locale]"
                    hashtags="languageToGo"
                  >
                    <div>
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                           xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                           style="enable-background:new 0 0 512 512;" xml:space="preserve"><path fill="#2e90d1" d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568C480.224,136.96,497.728,118.496,512,97.248z"/></svg>
                    </div>
                  </ShareNetwork>
                  <ShareNetwork
                    network="odnoklassniki"
                    :url="url"
                    :title="news.title[$i18n.locale]"
                    :description="news.description[$i18n.locale]"
                    :quote="news.title[$i18n.locale]"
                    hashtags="languageToGo">
                    <div>
                      <svg id="Bold" enable-background="new 0 0 24 24" viewBox="0 0 24 24"
                           xmlns="http://www.w3.org/2000/svg">
                        <path fill="#2e90d1"
                              d="m4.721 12.881c-.613 1.205.083 1.781 1.671 2.765 1.35.834 3.215 1.139 4.413 1.261-.491.472 1.759-1.692-4.721 4.541-1.374 1.317.838 3.43 2.211 2.141l3.717-3.585c1.423 1.369 2.787 2.681 3.717 3.59 1.374 1.294 3.585-.801 2.226-2.141-.102-.097-5.037-4.831-4.736-4.541 1.213-.122 3.05-.445 4.384-1.261l-.001-.001c1.588-.989 2.284-1.564 1.68-2.769-.365-.684-1.349-1.256-2.659-.267 0 0-1.769 1.355-4.622 1.355-2.854 0-4.622-1.355-4.622-1.355-1.309-.994-2.297-.417-2.658.267z"/>
                        <path fill="#2e90d1"
                              d="m11.999 12.142c3.478 0 6.318-2.718 6.318-6.064 0-3.36-2.84-6.078-6.318-6.078-3.479 0-6.319 2.718-6.319 6.078 0 3.346 2.84 6.064 6.319 6.064zm0-9.063c1.709 0 3.103 1.341 3.103 2.999 0 1.644-1.394 2.985-3.103 2.985s-3.103-1.341-3.103-2.985c-.001-1.659 1.393-2.999 3.103-2.999z"/>
                      </svg>
                    </div>
                  </ShareNetwork>
                  <ShareNetwork
                    network="vk"
                    :url="url"
                    :title="news.title[$i18n.locale]"
                    :description="news.description[$i18n.locale]"
                    :quote="news.title[$i18n.locale]"
                    hashtags="languageToGo"
                  >
                    <div>
                      <svg id="Bold" enable-background="new 0 0 24 24" viewBox="0 0 24 24"
                           xmlns="http://www.w3.org/2000/svg">
                        <path fill="#2e90d1"
                              d="m19.915 13.028c-.388-.49-.277-.708 0-1.146.005-.005 3.208-4.431 3.538-5.932l.002-.001c.164-.547 0-.949-.793-.949h-2.624c-.668 0-.976.345-1.141.731 0 0-1.336 3.198-3.226 5.271-.61.599-.892.791-1.225.791-.164 0-.419-.192-.419-.739v-5.105c0-.656-.187-.949-.74-.949h-4.126c-.419 0-.668.306-.668.591 0 .622.945.765 1.043 2.515v3.797c0 .832-.151.985-.486.985-.892 0-3.057-3.211-4.34-6.886-.259-.713-.512-1.001-1.185-1.001h-2.625c-.749 0-.9.345-.9.731 0 .682.892 4.073 4.148 8.553 2.17 3.058 5.226 4.715 8.006 4.715 1.671 0 1.875-.368 1.875-1.001 0-2.922-.151-3.198.686-3.198.388 0 1.056.192 2.616 1.667 1.783 1.749 2.076 2.532 3.074 2.532h2.624c.748 0 1.127-.368.909-1.094-.499-1.527-3.871-4.668-4.023-4.878z"/>
                      </svg>
                    </div>
                  </ShareNetwork>
                </div>
              </div>
            </div>
          </div>
          <div class="news-detail-block-left-section-leaf-over">
            <router-link :to="'/news/' + news.previous.id" class="back cursor-pointer text-decoration-none"
                         v-if="news.previous">
              <div>&laquo;</div>
              <p>{{ news.previous.title[$i18n.locale] }}</p>
            </router-link>
            <router-link :to="'/news/' + news.next.id" class="next cursor-pointer text-decoration-none"
                         v-if="news.next">
              <p>{{ news.next.title[$i18n.locale] }}</p>
              <div>&raquo;</div>
            </router-link>
          </div>
        </div>
        <div class="news-detail-block-right-section col-12 col-lg-4">
          <div class="news">
            <div class="title">Другие новости</div>
            <div class="block" v-if="!loading">
              <div v-for="(news, index) in allNews" :key="index" @click="$router.push('/news/' + news.id)">
                <p>{{ news.title[$i18n.locale] }}</p>
                <span>{{ news.created_at  | moment('DD.MM.YYYY') }}</span>
              </div>
            </div>
          </div>
          <div class="news news-interesting">
            <div class="title">Вам это будет интересно</div>
            <div class="block">
              <div v-for="(interesting, index) in bestInterestings" :key="index"
                   @click="$router.push('/interesting-detail/' + interesting.id)">
                <p>{{ interesting.title[$i18n.locale] }}</p>
                <span>{{ interesting.category_interesting.title[$i18n.locale] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="news-form">
      <div class="page-container news-form-container">
        <div class="title">Хотите быть в курсе новостей?</div>
        <div class="subtitle">Подпишитесь на рассылку наших новостей.</div>
        <ValidationObserver ref="subscribeAddForm" id="subscribeAddForm" tag="div" class="form-block">
          <div class="form-group row">
            <div class="col-8 row">
              <ValidationProvider rules="required" tag="div" v-slot="{ classes }" class="col-12 col-sm">
                <input class="col-12 col-sm" v-model="formData.name" type="text" name="name" :class="classes"
                       autocomplete="off" placeholder="Ваше имя">
              </ValidationProvider>
              <ValidationProvider rules="required|email" tag="div" v-slot="{ classes }" class="col-12 col-sm">
                <input class="col-12 col-sm" v-model="formData.email" type="email" name="email" :class="classes"
                       autocomplete="off" placeholder="E-mail">
              </ValidationProvider>
            </div>
            <button type="button" class="btn-white-outline col-4" @click="formSubmit" :disabled="disabledSubmitBtn"
                    :class="disabledSubmitBtn ? 'btn-disabled' : ''">подписаться на новости
            </button>
          </div>
          <ValidationProvider v-slot="{ errors, validate }" tag="div" rules="required" class="checkbox">
            <label for="privacy" class="remember">
              <input :true-value="1" :false-value="0" @change="validate(false)" v-model="formData.privacy"
                     type="checkbox" id="privacy" name="checkbox">
              <span></span>
              <div>Отправляя заявку, я соглашаюсь с <a target="_blank" href="/privacy-policy">политикой
                конфиденциальности</a> и <a target="_blank" href="/terms-of-use">пользовательским соглашением</a> сайта
              </div>
            </label>
            <small v-if="errors[0] || formData.privacy === 0" class="text-danger font-italic position-absolute d-conte">Вы
              не согласились с Правилами</small>
          </ValidationProvider>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>

import BreadCrumbs from '../../components/BreadCrumbs'
import { mapActions, mapState } from 'vuex'
import Loading from '../../components/Loading'

export default {
  name: 'NewsDetail',
  components: {
    Loading,
    BreadCrumbs
  },
  data () {
    return {
      formData: {
        name: '',
        email: ''
      },
      loading: true,
      disabledSubmitBtn: false,
      pages: [
        {
          path: '/',
          rout: 'Главная',
          active: false
        },
        {
          path: '/news',
          rout: 'Новости',
          active: false
        },
        {
          path: '',
          rout: '',
          active: true
        }
      ],
      url: null
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.id) {
      this.newsInit(to)
    }
    next()
  },
  watch: {
    news(newVal){
      this.metaInfo()
    }
  },
  created () {
    this.newsInit(this.$route)
  },
  computed: {
    ...mapState({
      news: state => state.news.news,
      allNews: state => state.news.allNews,
      bestInterestings: state => state.interesting.bestInterestings
    })
  },
  mounted () {
    this.scrollToElement()
  },
  methods: {
    ...mapActions({
      getNewsDetail: 'news/getNewsDetail',
      getNews: 'news/getNews',
      subscribe: 'subscribe/subscribe',
      getBestInterestings: 'interesting/getBestInterestings'
    }),
    setMeta (property, value) {
      document.querySelector(`meta[property="og:${property}"]`).setAttribute('content', value)
    },
    metaInfo () {
      let title = ''
      if (this.news.seo_title) {
        title = this.news.seo_title[this.$i18n.locale]
      } else {
        title = this.news.title[this.$i18n.locale]
      }

      if (this.news.seo_description) {
        this.setMeta('description', this.news.seo_description[this.$i18n.locale])
      }

      document.title = title
      this.setMeta('url', window.location.href)
      this.setMeta('title', title)
    },
    scrollToElement (options = {}) {
      const el = this.$refs.title.$el
      console.log('scrollToElement', el)
      //
      if (el) {
        el.scrollIntoView(options)
      }
    },
    newsInit (route) {
      this.loading = true
      this.getNewsDetail({ id: route.params.id }).then(() => {
        this.pages[2].rout = this.news.title[this.$i18n.locale]
      })
      this.getNews({
        count: 5,
        except_news: route.params.id
      }).finally(() => {
        this.loading = false
      })
      this.getBestInterestings({ count: 5 })
      this.url = window.location.href
    },
    formSubmit () {
      this.$refs.subscribeAddForm.validate().then(success => {
        if (success) {
          this.disabledSubmitBtn = true
          if (!this.formData.privacy) {
            return false
          }
          this.subscribe(this.formData).then(() => {
            this.$toasted.success('Подписка оформлена успешно!').goAway(1500)
          }).catch(err => {
            if (err.response.status === 422 && err.response.data.email) {
              const data = err.response.data.email
              const keys = Object.keys(data)
              const msg = data[keys[0]]
              this.$toasted.error(msg).goAway(2000)
              return false
            } else {
              this.$toasted.error('Server error!').goAway(2000)
            }
          }).finally(() => {
            this.disabledSubmitBtn = false
          })
        } else {
          this.$toasted.error('Есть незаполненные поля').goAway(1500)
        }
      })
    }
  }
}
</script>
