import axios from 'axios'

const baseURL = process.env.VUE_APP_WEB_API
console.log('baseUrl', baseURL)
const http = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json'
  }
})

http.interceptors.request.use((config) => {
  config.headers = {
    'X-Socket-ID': window.$echo.socketId(),
    ...config.headers
  }
  if (localStorage.access_token) {
    config.headers.Authorization = `Bearer ${localStorage.access_token}`
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

http.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    window.location = '/'
    localStorage.clear()
  }
  // if (error.response.status === 404) {
  //   window.location = '/404'
  // }
  return Promise.reject(error)
})

export {
  http
}
