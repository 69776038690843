<template>
  <div
    :class="{
    'inactive':inactive,
    'success-word':success,
    'error-word':error,
    'selected':selected,
    'used': used,
    'dashed': dashed,
    'datted': datted,
    'vb': activeVB && !text
  }"
    @click="() => $emit('click')"
    class="word-item cursor-pointer"
  >
    <img v-if="activeVB && !text" src="@/assets/images/tasks/choose.png" alt="choose" />
    <span :class="{'invisible':invisible, 'mob-hide-text': canHideText}">{{text}}</span>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TaskItem',
  props: {
    inactive: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    },
    used: {
      type: Boolean,
      default: false
    },
    invisible: {
      type: Boolean,
      default: false
    },
    dashed: {
      type: Boolean,
      default: false
    },
    datted: {
      type: Boolean,
      default: false
    },
    mobHideText: {
      type: Boolean,
      default: false
    },
    activeVB: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    canHideText () {
      return this.mobHideText && this.used
    }
  }
}
</script>

<style scoped lang="scss">
.word-item {
  min-width: 240px;
  border-radius: 32px;
  padding: 16px 32px;
  // TODO: fonts
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  background-color: var(--light-blue-2);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 991px) {
    min-width: none;
    font-size: 14px;
  }

  @media (max-width: 767px) {
    width: 100%;
    font-size: 12px;
  }
}

.used, .dashed {
  background-color: transparent;
  cursor: no-drop;
  color: transparent;
}
// TODO: colors
.used{
  border: 1px #b6bbcc solid;
}

.dashed{
  border: 1px #b6bbcc dashed;
}

.success-word {
  background-color: var(--success);
}

.error-word {
  background-color: var(--error);
}

.success-word, .error-word, .primary-word {
  border: 2px solid transparent !important;
}

.error-word, .success-word {
  color: var(--white);
}

.selected{
  border: 2px solid var(--primary);
  padding: 14px 30px;
}

.datted{
  border: 1px dashed var(--success) !important;
}
.mob-hide-text{
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}
.invisible{
  .span{
    visibility: hidden;
  }
}

.vb{
  cursor: pointer;
  background-color: var(--success);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--success) !important;
}

</style>
