<template>
  <div class="list-words only-mob">
    <div class="list-words-title">{{ title }}</div>
    <div class="words"  v-if="type === 'text'">
      <template  v-for="(word, index) in words">
        <slot name="list" :word="word" :index="index">
         <span
           :key="word.id"
           :class="{'words-used': positions[word.id] && groupCheck(positions[word.id])}"
         >
                {{ index === (words.length - 1) ? `${word[label]}.` : `${word[label]},` }}
              </span>
        </slot>
      </template>
    </div>
    <div class="d-flex flex-wrap justify-content-between" v-else>
      <CategorizeItem
        v-for="(word, index) in words"
        class="small mb-20"
        type="images"
        :success="word.success"
        :error="word.success === false && word.id"
        :key="word.id"
        :image="word[label]"
        :zoom="true"
      />
    </div>
  </div>
</template>

<script>
import CategorizeItem from './Categorize/CategorizeItem'
import Template from '../../pages/Profile/TechnicalSupport/Template'
export default {
  name: 'Words',
  components: {
    Template,
    CategorizeItem
  },

  props: {
    words: {
      type: [Array],
      default: []
    },
    title: {
      type: String,
      default: 'Доступные слова:'
    },
    positions: {
      type: Object,
      required:false
    },
    group: {
      type: String,
      default: ''
    },
    groupValue: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: 'word'
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  methods: {
    groupCheck (position) {
      if (!position[this.group]) return true

      return position[this.group] === this.groupValue
    },
  }
}
</script>

<style scoped lang="scss">
.mb-20{
  margin-bottom: 20px;
}
.list-words {
  margin-bottom: 30px;

  &-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-bottom: 10px;
  }

  .words {
    display: flex;
    flex-wrap: wrap;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #333333;

    &-used {
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 100%;
        border-top: 1px solid #333333;
        position: absolute;
        top: 50%;
      }
    }

    span {
      margin-right: 5px;
    }
  }
}
</style>
