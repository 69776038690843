import { http } from '../../../api'

const nextTaskId = {
  namespaced: true,
  state: {
    nextTaskId: null,
  },
  mutations: {
    setNextTaskId (state, data) {
      state.nextTaskId = data
    },
  },
  getters: {
    nextTaskId: state => state.nextTaskId,
  }
}

export default nextTaskId
