<template>
  <div class="read-consultation row">
    <div class="image col-lg-5 col-cl-5 col-sm-12">
      <img src="../../assets/images/contacts/read-consultation.png" alt=""/>
    </div>
    <div class="input-block input-block-pg col-lg-7 col-sm-12">
      <div class="ipnut-block-container-pg">
        <div class="input-block-container-title">
          Есть вопросы или хотите получить консультацию?
        </div>
        <div class="input-block-container-subtitle">
          <span></span>Оставьте свои контакты и наш менеджер свяжется с вами в
          ближайшее время
        </div>
        <ValidationObserver ref="contactForm" tag="form" class="contact-form-pg" @submit.prevent="onSubmit">
          <div class="contact-form-inputs">
            <div class="contact-form-input-wrapper">
              <ValidationProvider name="Ваше имя" v-slot="{classes, errors}" tag="div" rules="required"
                                  class="contact-provider contact-provider-input">
                <input
                  v-model="form.name"
                  type="text"
                  class="contact-form-input"
                  placeholder="Ваше имя"
                  :class="classes"
                />
                <div class="invalid-feedback">{{ errors[0] }}</div>
              </ValidationProvider>
              <ValidationProvider name="Телефон" v-slot="{classes, errors}" rules="required" tag="div"
                                  class="contact-provider contact-provider-input">
                <input
                  v-model="form.phone_number"
                  type="tel"
                  class="contact-form-input"
                  placeholder="Телефон"
                  :class="classes"
                />
                <div class="invalid-feedback">{{ errors[0] }}</div>
              </ValidationProvider>
              <ValidationProvider name="E-mail" v-slot="{classes, errors}" rules="required|email" tag="div"
                                  class="contact-provider contact-provider-input">
                <input
                  type="email"
                  class="contact-form-input"
                  placeholder="E-mail"
                  v-model="form.email"
                  :class="classes"
                />
                <div class="invalid-feedback">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
            <ValidationProvider name="Текст сообщения" v-slot="{classes, errors}" rules="required" tag="div"
                                class="contact-provider contact-provider-textarea">
              <textarea
                v-model="form.text"
                class="contact-form-textarea contact-form-input"
                placeholder="Текст сообщения"
                :class="classes"
              ></textarea>
              <div class="invalid-feedback">{{ errors[0] }}</div>
            </ValidationProvider>
          </div>
          <ValidationProvider v-slot="{classes, errors}" name="Пользовательское соглашение" rules="" tag="label"
                              class="contact-form-checkbox">
            <input :class="classes" v-model="form.agree" type="checkbox" id="terms" name="terms" value="1"/>
            <span class="untouched dirty valid validated required passed login-input"></span>
            <div>
              Отправляя заявку, я соглашаюсь с <a target="_blank" href="/privacy-policy">политикой
              конфиденциальности</a> и
              <a target="_blank" href="/terms-of-use">пользовательским соглашением</a> сайта
            </div>
            <div class="invalid-feedback">{{ errors[0] }}</div>
          </ValidationProvider>
          <button :disabled="disabled" type="submit" class="contact-form-btn">отправить заявку</button>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      form: {
        name: null,
        email: null,
        text: null,
        phone_number: null,
        agree: null,
      },
      disabled: false
    }
  },
  methods: {
    ...mapActions({
      store: 'contact/store'
    }),
    async onSubmit () {
      const success = await this.$refs.contactForm.validate()

      if (!success) return

      if (!this.form.agree){
        this.$toasted.error('Поля Пользовательское соглашение обязательно').goAway(1500)
        return
      }

      this.disabled = true

      try {
        const response = await this.store(this.form)
        this.$refs.contactForm.reset()
        this.form = {}
        this.$toasted.success('Вы успешно отправили сообщение').goAway(1500)

      } catch (e) {
        this.$toasted.error('Что-то пошло не так, повторите позже').goAway(1500)
      }

      this.disabled = false

    }
  }
}
</script>
