<template>
  <div>
    <div class="personal-details books videos">
      <div class="title-block">
        <div class="d-flex align-items-center">
          <div class="flag">
            <img :src="courseTeachingLanguage.flag_path" alt="">
          </div>
          <div>
            <div class="tabs-title">
              Самостоятельная работа
            </div>
            <div class="subtitle">
              {{ courseTeachingLanguage.name[$i18n.locale] }}
            </div>
          </div>
        </div>
        <div class="tabs-info mt-2">
          Список заданий
        </div>
      </div>
      <loading v-if="loading" />
      <div v-else class="independent-content">
        <div class="independent-content-column w-100">
          <TaskColumn
            v-for="task in tasks"
            :task-name="task.name[$i18n.locale]"
            :type-task="task.type.name[$i18n.locale]"
            point="Пройти задание"
            :action="`/profile/task/${task.task_type_id}/${task.id}?type=reply`"
            :key="task.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TaskColumn from '../../../components/TaskColumn'
import Loading from '../../../components/Loading'

export default {
  name: 'IndependentWork',
  components: { Loading, TaskColumn },
  data () {
    return {
      currentPage: 1,
      modalVideo: {}
    }
  },
  computed: {
    ...mapGetters({
      tasks: 'task/tasks',
      courseTeachingLanguage: 'course/courseTeachingLanguage',
      loading: 'loading/loading'
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.id !== from.params.id) {
      this.taskInit(to.params.id)
    }
    next()
  },
  created () {
    this.taskInit(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      getCourseTeachingLanguage: 'course/getCourseTeachingLanguage',
      getTasks: 'task/getTasks'
    }),
    taskInit (course_id) {
      this.getCourseTeachingLanguage({ id: course_id })
      this.getTasks({
        course_id,
        by: 'work'
      })
    }
  }
}
</script>
