<template>
  <div class="container d-flex justify-content-center puzzel-wrapper" ref="puzzle">
    <div id="leftBox" ref="box">
      <table id="puzzel" :style="`zoom:${zoom}%`" ref="table">
        <tr v-for="(arr, index) in letters" :key="index">
          <td v-for="(letter, i) in arr" :key="i">
            <template v-if="letter && letter.numbers">
              <span
                v-for="number in letter.numbers"
                v-if="number.isStart"
                class="word-number"
                :class="{'x': number.x && number.isStart, 'y': number.y && number.isStart}"
              >{{ number.number }}
            </span>
            </template>

            <input ref="inputs" :name="letter.name" v-if="letter" type="text" autocomplete="off" class="inputBox dashed"
                   maxlength="1" :id="letter.id" @focus="textInputFocus(letter.id)"
                   :class="{'success':checkNumber(success, letter.numbers)}">
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CrosswordGame',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    zoom: {
      type: Number,
      default: 100
    },
    isCheckClicked: {
      type: Boolean,
      required: false,
      default: false
    },
    wordArray: {
      type: Array,
      default: []
    },
    correctAnswer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      letters: [],
      wordArrInput: [],
      board: [],
      wordArr: [],
      wordBank: [],
      wordsActive: [],
      currentTextInput: '',
      puzzelArrayData: '',
      mode: true,
      bounds: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      success: [],
      errors: []
    }
  },
  mounted () {
    this.create()
    this.play()
    setTimeout(() => {
      this.initializeScreen()
      this.$nextTick(() => {
        const height = this.$refs.puzzle.offsetHeight + 'px'
        this.$refs.puzzle.style.minHeight = height
        this.$refs.puzzle.style.maxHeight = height
        const difference = this.$refs.table.clientWidth - this.$refs.puzzle.clientWidth

        if (difference <= 0) return

        this.$emit('update:zoom', 100 - (difference * 100 / this.$refs.table.clientWidth))
        this.$refs.inputs.forEach(input => {
          input.addEventListener('input', e => {
            e.target.value = e.target.value.replace(/^\s+/, '').replace(/\s+$/, '')
          })
        })
      })
    }, 1000)
  },
  methods: {
    initializeScreen () {
      if (this.letters.length) {
        this.letters = []
      } else {
        this.puzzelArrayData = this.board.filter(items => {
          const values = items.filter(item => item !== null)
          if (values.length > 0) {
            return values
          }
          return false
        })
        for (var i = 0; i < this.puzzelArrayData.length; i++) {
          var rowData = this.puzzelArrayData[i]
          this.letters.push([])
          for (var j = 0; j < rowData.length; j++) {
            if (rowData[j] !== null) {
              var txtID = String('txt_' + i + '_' + j)
              var txtName = String('text_' + rowData[j].id + '[]')
              this.letters[i].push({
                id: txtID,
                name: txtName,
                rowDataIndex: rowData[j].index,
                rowDataId: rowData[j].id,
                numbers: rowData[j].numbers
              })
            } else {
              this.letters[i].push(null)
            }
          }
        }
      }
      this.$emit('ready')
    },
    textInputFocus (txtID123) {
      this.currentTextInput = txtID123
    },
    checkTask () {
      this.errors = this.success = []
      const incorrectAnswers = []
      for (var i = 0; i < this.puzzelArrayData.length; i++) {
        var rowData = this.puzzelArrayData[i]
        for (var j = 0; j < rowData.length; j++) {
          if (rowData[j] !== 0) {
            const id = 'txt' + '_' + i + '_' + j
            var selectedInputTextElement = window.document.getElementById(id)
            if (selectedInputTextElement !== null) {
              if (selectedInputTextElement.value.toLowerCase() === this.puzzelArrayData[i][j].char.toLowerCase()) {
                this.pushSuccess(this.puzzelArrayData[i][j].numbers)
              } else {
                this.pushError(this.puzzelArrayData[i][j].numbers)
                incorrectAnswers.push(1)
              }
            }
          }
        }
      }

      this.$emit('onResults', {
        errors: this.errors,
        success: this.success
      })

      return incorrectAnswers.length === 0
    },
    checkClicked () {
      if (!this.checkTask()) {
        this.$emit('answersIncorrect')
      } else {
        this.$emit('answersCorrect')
      }
    },
    showAnswers () {
      for (var i = 0; i < this.puzzelArrayData.length; i++) {
        var rowData = this.puzzelArrayData[i]
        for (var j = 0; j < rowData.length; j++) {
          if (rowData[j] !== 0) {
            const id = 'txt' + '_' + i + '_' + j
            var selectedInputTextElement = window.document.getElementById(id)
            if (selectedInputTextElement !== null) {
              selectedInputTextElement.value = this.puzzelArrayData[i][j].char
            }
          }
        }
      }
    },
    pushSuccess (numbers) {
      if (this.checkNumber(this.errors, numbers)) {
        return
      }

      this.pushNotExists(this.success, numbers)
    },
    pushError (numbers) {
      numbers.forEach(({ number }) => {
        this.success = this.success.filter(value => value !== number)
        this.pushArray(this.errors, number)
      })
    },
    pushNotExists (array, numbers) {
      numbers.forEach(({ number }) => {
        this.pushArray(array, number)
      })
      return array
    },
    pushArray (array, number) {
      if (!array.includes(number)) {
        array.push(number)
      }
    },
    checkNumber (array, numbers) {
      return numbers.find(({ number }) => {
        return array.includes(number)
      })
    },
    update (x, y) {
      this.bounds.top = Math.min(y, this.bounds.top)
      this.bounds.right = Math.max(x, this.bounds.right)
      this.bounds.bottom = Math.max(y, this.bounds.bottom)
      this.bounds.left = Math.min(x, this.bounds.left)
    },
    clean () {
      this.bounds.top = 999
      this.bounds.right = 0
      this.bounds.bottom = 0
      this.bounds.left = 999
    },
    play () {
      this.mode = false
      this.toggleInputBoxes(false)
    },
    toggleInputBoxes (active) {
      const w = window.document.getElementsByClassName('word')
      const d = window.document.getElementsByClassName('clue')

      for (let i = 0; i < w.length; i++) {
        if (active === true) {
          this.removeClass(w[i], 'hide')
          this.removeClass(d[i], 'clueReadOnly')
          d[i].disabled = ''
        } else {
          this.addClass(w[i], 'hide')
          this.addClass(d[i], 'clueReadOnly')
          d[i].disabled = 'readonly'
        }
      }
    },
    addClass (ele, classStr) {
      ele.className = ele.className.replaceAll(' ' + classStr, '') + ' ' + classStr
    },
    removeClass (ele, classStr) {
      ele.className = ele.className.replaceAll(' ' + classStr, '')
    },
    create () {
      if (!this.mode) {
        this.toggleInputBoxes(true)
        this.mode = true
      } else {
        this.wordArr = this.wordArray
        for (var i = 0, isSuccess = false; i < 10 && !isSuccess; i++) {
          this.cleanVars()
          isSuccess = this.populateBoard()
        }
      }
    },
    boardToHtml () {
      let str = ' '
      for (let i = this.bounds.top - 1; i < this.bounds.bottom + 2; i++) {
        str += '<div class=\'row\'>'
        for (let j = this.bounds.left - 1; j < this.bounds.right + 2; j++) {
          str += this.boardCharToElement(this.board[j][i])
        }
        str += '</div>'
      }
      return str
    },
    getWordsFromInput () {
      this.wordArr = []
      for (let i = 0, val, w = document.getElementsByClassName('word'); i < w.length; i++) {
        val = w[i].value.toUpperCase()
        if (val !== null && val.length > 1) {
          this.wordArr.push(val)
        }
      }
    },
    cleanVars () {
      this.clean()
      this.wordBank = []
      this.wordsActive = []
      this.board = []
      for (let i = 0; i < 29; i++) {
        this.board.push([])
        for (let j = 0; j < 29; j++) {
          this.board[i].push(null)
        }
      }
    },
    populateBoard () {
      this.prepareBoard()
      for (var i = 0, isOk = true, len = this.wordBank.length; i < len && isOk; i++) {
        isOk = this.addWordToBoard()
      }
      return isOk
    },
    prepareBoard () {
      this.wordBank = []
      for (var i = 0, len = this.wordArr.length; i < len; i++) {
        this.wordBank.push({
          id: this.wordArr[i].id,
          string: this.wordArr[i].word,
          char: this.wordArr[i].word.split(''),
          number: this.wordArr[i].number,
          totalMatches: 0,
          effectiveMatches: 0,
          successfulMatches: []
        })
      }
      for (let i = 0; i < this.wordBank.length; i++) {
        for (let j = 0, wA = this.wordBank[i]; j < wA.char.length; j++) {
          for (let k = 0, cA = wA.char[j]; k < this.wordBank.length; k++) {
            // eslint-disable-next-line no-unmodified-loop-condition
            for (let l = 0, wB = this.wordBank[k]; k !== i && l < wB.char.length; l++) {
              wA.totalMatches += (cA === wB.char[l]) ? 1 : 0
            }
          }
        }
      }
    },
    addWordToBoard () {
      let i
      let len
      let curIndex
      let curWord
      let curChar
      let testWord
      let testChar
      const minMatchDiff = 9999
      let curMatchDiff

      if (this.wordsActive.length < 1) {
        curIndex = 0
        for (i = 0, len = this.wordBank.length; i < len; i++) {
          if (this.wordBank[i].totalMatches < this.wordBank[curIndex].totalMatches) {
            curIndex = i
          }
        }
        this.wordBank[curIndex].successfulMatches = [{
          x: 12,
          y: 12,
          dir: 0
        }]
      } else {
        curIndex = -1

        for (i = 0, len = this.wordBank.length; i < len; i++) {
          curWord = this.wordBank[i]
          curWord.effectiveMatches = 0
          curWord.successfulMatches = []
          for (let j = 0, lenJ = curWord.char.length; j < lenJ; j++) {
            curChar = curWord.char[j]
            for (let k = 0, lenK = this.wordsActive.length; k < lenK; k++) {
              testWord = this.wordsActive[k]
              for (let l = 0, lenL = testWord.char.length; l < lenL; l++) {
                testChar = testWord.char[l]
                if (curChar === testChar) {
                  curWord.effectiveMatches++
                  const curCross = {
                    x: testWord.x,
                    y: testWord.y,
                    dir: 0
                  }
                  if (testWord.dir === 0) {
                    curCross.dir = 1
                    curCross.x += l
                    curCross.y -= j
                  } else {
                    curCross.dir = 0
                    curCross.y += l
                    curCross.x -= j
                  }
                  let isMatch = true
                  for (var m = -1, lenM = curWord.char.length + 1; m < lenM; m++) {
                    const crossVal = []
                    if (m !== j) {
                      if (curCross.dir === 0) {
                        const xIndex = curCross.x + m

                        if (xIndex < 0 || xIndex > this.board.length) {
                          isMatch = false
                          break
                        }

                        crossVal.push(this.board[xIndex][curCross.y])
                        crossVal.push(this.board[xIndex][curCross.y + 1])
                        crossVal.push(this.board[xIndex][curCross.y - 1])
                      } else {
                        const yIndex = curCross.y + m

                        if (yIndex < 0 || yIndex > this.board[curCross.x].length) {
                          isMatch = false
                          break
                        }

                        crossVal.push(this.board[curCross.x][yIndex])
                        crossVal.push(this.board[curCross.x + 1][yIndex])
                        crossVal.push(this.board[curCross.x - 1][yIndex])
                      }

                      if (m > -1 && m < lenM - 1) {
                        if (crossVal[0] !== curWord.char[m]) {
                          if (crossVal[0] !== null) {
                            isMatch = false
                            break
                          } else if (crossVal[1] !== null) {
                            isMatch = false
                            break
                          } else if (crossVal[2] !== null) {
                            isMatch = false
                            break
                          }
                        }
                      } else if (crossVal[0] !== null) {
                        isMatch = false
                        break
                      }
                    }
                  }
                  if (isMatch === true) {
                    curWord.successfulMatches.push(curCross)
                  }
                }
              }
            }
          }
          curMatchDiff = curWord.totalMatches - curWord.effectiveMatches
          if (curMatchDiff < minMatchDiff && curWord.successfulMatches.length > 0) {
            curMatchDiff = minMatchDiff
            curIndex = i
          } else if (curMatchDiff <= 0) {
            return false
          }
        }
      }
      if (curIndex === -1) {
        return false
      }

      const spliced = this.wordBank.splice(curIndex, 1)

      this.wordsActive.push(spliced[0])

      const pushIndex = this.wordsActive.length - 1
      const rand = Math.random()
      const matchArr = this.wordsActive[pushIndex].successfulMatches
      const matchIndex = Math.floor(rand * matchArr.length)
      const matchData = matchArr[matchIndex]

      this.wordsActive[pushIndex].x = matchData.x
      this.wordsActive[pushIndex].y = matchData.y
      this.wordsActive[pushIndex].dir = matchData.dir

      for (i = 0, len = this.wordsActive[pushIndex].char.length; i < len; i++) {
        let xIndex = matchData.x
        let yIndex = matchData.y
        if (matchData.dir === 0) {
          xIndex += i

          const number = {
            number: this.wordsActive[pushIndex].number,
            x: true,
            isStart: i === 0,
          }

          if (this.board[xIndex][yIndex]) {
            this.board[xIndex][yIndex].numbers.push(number)
          } else {
            this.board[xIndex][yIndex] = {
              index: i,
              id: this.wordsActive[pushIndex].id,
              char: this.wordsActive[pushIndex].char[i],
              numbers: [number],
            }
          }

        } else {
          yIndex += i

          const number = {
            number: this.wordsActive[pushIndex].number,
            y: true,
            isStart: i === 0,
          }

          if (this.board[xIndex][yIndex]) {
            this.board[xIndex][yIndex].numbers.push(number)
          } else {
            this.board[xIndex][yIndex] = {
              index: i,
              char: this.wordsActive[pushIndex].char[i],
              id: this.wordsActive[pushIndex].id,
              numbers: [number],
            }
          }

        }
        this.update(xIndex, yIndex)
      }
      return true
    },
    boardCharToElement (c) {
      const arr = (c) ? ['square', 'letter'] : ['square']
      return this.eleStr('div', [{
        a: 'class',
        v: arr
      }], c)
    },
    eleStr (e, c, h) {
      h = !h ? '' : h
      let s = '<' + e + ' '
      for (let i = 0; i < c.length; i++) {
        s += c[i].a + '=\'' + this.arrayToString(c[i].v, ' ') + '\' '
      }
      return (s + '>' + h + '</' + e + '>')
    },
    arrayToString (a, s) {
      if (a === null || a.length < 1) return ''
      if (s === null) s = ','
      let r = a[0]
      for (let i = 1; i < a.length; i++) {
        r += s + a[i]
      }
      return r
    }
  },
  watch: {
    isCheckClicked: function (newVal, oldVal) {
      this.checkClicked()
    },
    correctAnswer (newVal) {
      if (newVal) {
        this.showAnswers()
        this.checkTask()
      }
    }
  }
}
</script>

<style scoped>
html {
  height: 100%
}

.puzzel-wrapper {
  padding: 50px;
  overflow: hidden;
  background: #fff;
  margin-right: 50px;
  margin-left: 0 !important;
  max-width: 700px;
  border-radius: 10px;
}

body {
  height: 100%;
  min-width: 400px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container {
  text-align: center
}

.center {
  margin: 0 auto
}

.line {
  height: 2em
}

.word, .clue {
  display: inline-block;
  height: 1.5em;
  padding: 0 5px
}

.word {
  text-align: right;
  width: 100px;
}

.clue {
  width: 500px
}

.crossword {
  display: block;
  background-color: rgb(32, 32, 32)
}

.square {
  margin: 0 1px 1px 0;
  display: inline-block;
  font: 24px Calibri;
  width: 1.25em;
  height: 1.25em;
  line-height: 1.25em;
  vertical-align: middle;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.letter {
  background-color: rgb(255, 255, 255);

  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text
}

.char:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(255, 32, 32, 1);
  -moz-box-shadow: 0 0 0 2px rgba(255, 32, 32, 1);
  box-shadow: inset 0 0 0 2px rgba(255, 32, 32, 1)
}

.char {
  font-size: 24px;
  text-transform: uppercase;
  outline: 0;
  border: 0;
  padding: 0;
  margin: -1px 0 0 -1px;
  width: 1.35em;
  height: 1.35em;
  text-align: center;
  background: none
}

.hide {
  visibility: hidden
}

#cross {
  text-align: center;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border: 1px solid white;
}

#buttons {
  width: 30%;
  float: right;
}

tr {
  margin: 0;
  padding: 0;
  border-collapse: collapse;
}

td {
  position: relative;
  height: 60px !important;
  width: 60px !important;
}

#leftBox {
  float: left;
}

#rightBox {
  float: left;
  clear: left;
}

.butt {
  height: 50px;
  width: 107px;
}

#puzzel {
  text-align: center;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
}

.inputBox {
  width: 50px !important;
  height: 50px !important;
  border: 1px solid black;
  text-align: center;
  border-radius: 4px;
  margin: 5px;
  font-size: 18px;
  font-weight: bold;
}

.dashed {
  border: 1px dashed #b6bbcc;
}

#hintsTable {
  width: 480px;
  float: left;
  clear: left;
}

.clueReadOnly {
  border: 0;
  outline: 0;
  color: #303030 !important;
  background: none
}

.word-number {
  position: absolute;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.06em;
  color: black;
}

.x {
  top: -15px;
  left: 28px;
}

.y {
  left: -10px;
  top: 37%;
}

.success {
  background-color: #219653;
  color: white !important;
  border: 1px solid;
}

@media (max-width: 768px) {
  .puzzel-wrapper {
    margin-right: 0;
  }
}
</style>
