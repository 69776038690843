<template>
  <div class="gallows-item text-lowercase" :class="{
    'dashed': dashed,
    'success': success,
    'error': error,
  }">
    {{ letter }}
  </div>
</template>

<script>
export default {
  name: 'Item',
  props: {
    letter: {
      type: String,
      default: ''
    },
    dashed: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style scoped lang="scss">
.gallows-item {
  height: 50px;
  width: 50px;
  border-radius: 4px;
  background-color: #e6eafc;
  border: 1px solid transparent;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    height: 30px;
    width: 30px;
  }
}

.dashed {
  background-color: transparent;
  border: 1px dashed #b6bbcc;
}

.success {
  color: white !important;
  background-color: #219653;
}

.error {
  color: white !important;
  background-color: #CD3B45;
}

</style>
