import { http } from '../../../api'

const subscribe = {
  namespaced: true,
  state: {
    user: {},
    isAuthenticated: false,
    token: null,
    registration: true
  },
  actions: {
    subscribe: ({ commit }, params) => {
      return http.post('/subscribe', params).then(res => {
        return res
      })
    }
  },
  mutations: {
  },
  getters: {
  }
}

export default subscribe
