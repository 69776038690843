<template>
  <div class="reviews-carousel">
    <div class="reviews-carousel-container" :class="{'p-0':isAuth}">
      <div class="reviews-carousel-container-top-block mp-reviews-carousel-container-top-block">
        <div>
          <div class="reviews-carousel-container-top-block-title">{{$t('reviews.title')}}</div>
          <div class="reviews-carousel-container-top-block-subtitle">Изучайте иностранные языки вместе с... участникам со всего мира</div>
        </div>
        <div class="mp-reviews-btn">
          <router-link tag="button" to="/reviews" type="button" class="btn-purple-outline p-0">{{$t('reviews.all_reviews')}}</router-link>
        </div>
      </div>
      <div class="reviews-carousel-container-carousel mp-reviews-carousel-container-carousel" v-if="reviews && reviews.length">
        <VueSlickCarousel v-bind="settings">
          <div class="review" v-for="(review, index) in reviews" :key="review.id">
            <div class="review-top">
              <div class="name">
                <div>{{review.full_name}}</div>
              </div>
              <div class="stars">
                <template v-for="(number, i) in [1, 2 , 3 , 4 , 5]">
                  <img v-if="number <= review.rating" v-bind:key="i" alt="review" src="../../assets/images/icons/review.svg">
                  <img v-else alt="review" v-bind:key="i" src="../../assets/images/icons/empty-review.svg">
                </template>
              </div>
            </div>
            <div class="text"><VClamp :max-lines="8">{{review.text}}</VClamp></div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapActions, mapState } from 'vuex'
import VClamp from 'vue-clamp'

export default {
  name: 'ReviewsCarousel',
  data () {
    return {
      settings: {
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              arrows: false,
              dots: true,
              infinite: false,
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 570,
            settings: {
              arrows: false,
              dots: true,
              infinite: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  components: {
    VueSlickCarousel,
    VClamp
  },
  mounted () {
    this.getReviews({ count: 8 })
  },
  computed: {
    ...mapState({
      reviews: state => state.review.reviews,
      isAuth: state => state.auth.isAuthenticated
    })
  },
  methods: {
    ...mapActions({
      getReviews: 'review/getReviews'
    })
  }
}
</script>
