export const indexRoutes = {
  home: '/',
  search: '/search',
  courses: '/courses/:id',
  courses_all: '/courses/all',
  course_detail: '/course/:id',
  course: '/course',
  about_us: '/about',
  video: '/video',
  news: '/news',
  news_detail: '/news/:id',
  reviews: '/reviews',
  contacts: '/contacts',
  payments: '/payments',
  privacy_policy: '/privacy-policy',
  terms_of_use: '/terms-of-use',
  legal_disclaimer: '/legal-disclaimer',
  interesting: '/interesting/:id',
  interesting_detail: '/interesting-detail/:id',
  faq: '/faq',
  reset_password: '/reset-password',
  email_verified: '/email-verified',
  not_found: '/404'
}
