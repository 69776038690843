var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TaskBase',{attrs:{"task":_vm.task,"check-task":_vm.checkComponetText,"correct-answer":_vm.correctAnswer},scopedSlots:_vm._u([{key:"desktop",fn:function(){return [_c('WordModal',{attrs:{"words":_vm.answerArr,"show":_vm.showModal},on:{"update:show":function($event){_vm.showModal=$event}},scopedSlots:_vm._u([{key:"list",fn:function(ref){
var word = ref.word;
var index = ref.index;
return [_c('span',{staticClass:"status-word-border",class:{
              'success-word':_vm.findHoldWords(index) && _vm.success[_vm.findHoldWords(index)] ||_vm.findHoldWords(index) !== false && _vm.errors[_vm.findHoldWords(index)] !== true,
              'error-word': _vm.findHoldWords(index) && _vm.errors[_vm.findHoldWords(index)],
            }},[_c('span',{staticClass:"status-word"})]),_c('span',{staticStyle:{"text-transform":"none !important"},attrs:{"data-index":index,"data-answer":true},on:{"click":function ($event) { return _vm.pickWordModal($event.target,index); }}},[_vm._v(_vm._s(word))])]}}])},[_c('Words',{attrs:{"words":_vm.answerArr},scopedSlots:_vm._u([{key:"list",fn:function(ref){
            var word = ref.word;
            var index = ref.index;
return [_c('span',{class:{'words-used': _vm.findHoldWords(index) !== false}},[_vm._v(" "+_vm._s(index === (_vm.answerArr.length - 1) ? (word + ".") : (word + ","))+" ")])]}}])}),_c('div',{directives:[{name:"drag-and-drop",rawName:"v-drag-and-drop:options",value:(_vm.options),expression:"options",arg:"options"}]},[_c('div',{staticClass:"missing-words"},[_vm._l((_vm.textArr),function(word,index){return [(index === 0 || index === _vm.textArr.length - 1)?_c('div',{staticClass:"char-parent space"},[_c('div',{staticClass:"char"})]):_vm._e(),(_vm.breaks[index])?_c('Break',{staticClass:"char-parent br",attrs:{"breaks":_vm.breaks[index]}}):(!_vm.breaks[index + 1])?_c('div',{key:index,staticClass:"char-parent",class:{
            'space': word === ' ',
          }},[(word === '|')?_c('div',{staticClass:"text-block dragzone dragzone-item d-flex justify-content-center position-relative wordable",class:{
                   'primary-word':_vm.endTextArr[index] && _vm.endTextArr[index].length,
                   'success-word':_vm.success[index],
                   'error-word':_vm.errors[index] && (!_vm.isMobile || _vm.isMobile && _vm.holdWords[index] !== undefined),
                   'active-text': _vm.activeBlock === index && _vm.holdWords[index] !== undefined
                 },staticStyle:{"text-transform":"none !important"},attrs:{"data-index":index},on:{"added":function($event){return _vm.added(index, $event)},"click":function ($event) { return _vm.dragText(index, $event.target, word); }}},[(!_vm.endTextArr[index])?_c('img',{staticClass:"position-absolute only-mob",attrs:{"src":require("@/assets/images/tasks/pick-word.png")}}):_vm._e(),_vm._v(" "+_vm._s(_vm.endTextArr[index])+" "),_c('span',[_vm._v(_vm._s(_vm.words[_vm.wordsIndex.findIndex(function (value) { return value === index; })][0]))])]):_c('div',{staticClass:"char"},[_vm._v(" "+_vm._s(word)+" ")])]):_vm._e()]})],2),_c('div',{staticClass:"d-flex flex-wrap justify-content-start composer-answers cursor-pointer"},_vm._l((_vm.answerArr),function(answer,index){return _c('div',{key:index,staticClass:"dragzone-item word-item text-uppercase mb-4",class:{'active-text': _vm.activeText === index && !_vm.inactiveAnswers[index],
            'used': _vm.inactiveAnswers[index]
            },staticStyle:{"text-transform":"none !important"},attrs:{"data-index":index,"data-answer":true},on:{"click":function ($event) { return _vm.selectAnswer(index, $event.target); }}},[_vm._v(" "+_vm._s(answer)+" ")])}),0)])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }