import { http } from '../../../api'

const faq = {
  namespaced: true,
  state: {
    faqList: []
  },
  actions: {
    getFaqList: ({ commit }, params) => {
      let url = '/faq'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setFaqList', res.data.data)
      })
    },
    sendApplication: ({ commit }, params) => {
      return http.post('/application', params).then((res) => {
        return res
      })
    }
  },
  mutations: {
    setFaqList (state, data) {
      state.faqList = data
    }
  },
  getters: {
    faqList: state => state.faqList
  }
}

export default faq
