<template>
  <div class="benefits row">
    <div class="benefits-container col-12">
      <div class="benefits-container-title">Переключайся на Language to go</div>
      <div class="benefits-container-subtitle my-subtitle">
        Language to go - это современно, быстро, интересно и практично
      </div>
      <div class="benefits-container-text">
        <span
          >Мы начинаем знакомиться с иностранными языками с детства и продолжаем
          «грызть гранит» после окончания школы и ВУЗа. Только задумайтесь, всю
          жизнь зубрим слова и грамматику, но оказавшись в языковой среде с
          трудом можем представиться или заказать себе кофе.</span
        >
        <div class="benefits-container-text-bold">
          Старые методики показали свою неэффективность.
        </div>
      </div>
      <div class="benefits-container-text-purple">
        А еще, Language to go - это:
      </div>
      <div class="benefits-container-card-group row">
        <div class="col-12 col-xl-6 benefits-container-content">
          <div class="benefits-container-card-group-card">
            <div class="benefits-container-card-group-card-image">
              <img
                src="../../assets/images/benefits/convenient.svg"
                alt="convenient"
              />
            </div>
            <div class="benefits-container-card-group-card-info-block">
              <div class="benefits-container-card-group-card-info-block-title">
                Удобный формат обучения
              </div>
              <div class="benefits-container-card-group-card-info-block-text">
                Полностью автоматический процесс обучения на одной платформе с
                доступом на любом устройстве
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6 benefits-container-content">
          <div class="benefits-container-card-group-card">
            <div class="benefits-container-card-group-card-image">
              <img src="../../assets/images/benefits/tasks.svg" alt="tasks" />
            </div>
            <div class="benefits-container-card-group-card-info-block">
              <div class="benefits-container-card-group-card-info-block-title">
                Интересные уроки
              </div>
              <div class="benefits-container-card-group-card-info-block-text">
                Короткие, но насыщенные уроки до 45 минут. Например,
                фокусируйтесь, занимаясь 3 раза по 15 минут или составляйте свой
                график
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6 benefits-container-content">
          <div class="benefits-container-card-group-card">
            <div class="benefits-container-card-group-card-image">
              <img
                src="../../assets/images/benefits/fast-results.svg"
                alt="fast-results"
              />
            </div>
            <div class="benefits-container-card-group-card-info-block">
              <div class="benefits-container-card-group-card-info-block-title">
                Высокий уровень обучения
              </div>
              <div class="benefits-container-card-group-card-info-block-text">
                Более глубокий объем знаний, чем в других школах и даже чем
                предусмотрено международными стандартами
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6 benefits-container-content">
          <div class="benefits-container-card-group-card">
            <div class="benefits-container-card-group-card-image">
              <img src="../../assets/images/benefits/item-4.png" alt="img">
            </div>
            <div class="benefits-container-card-group-card-info-block">
              <div class="benefits-container-card-group-card-info-block-title">
                Полный доступ
              </div>
              <div class="benefits-container-card-group-card-info-block-text">
                Неограниченное использование материалов и многократное
                выполнение заданий в рамках доступа к курсу
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6 benefits-container-content">
          <div class="benefits-container-card-group-card">
            <div class="benefits-container-card-group-card-image">
              <img src="../../assets/images/benefits/item-5.png" alt="img">
            </div>
            <div class="benefits-container-card-group-card-info-block">
              <div class="benefits-container-card-group-card-info-block-title">
                Общение с другими учащимися
              </div>
              <div class="benefits-container-card-group-card-info-block-text">
                Внутренний видеочат для общения с единомышленниками и отработки
                материала. Завести новых друзей по интересам просто.
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6 benefits-container-content">
          <div class="benefits-container-card-group-card benefits-container-card-group-card-purple">
            <div class="benefits-container-card-group-card-image">
              <img src="../../assets/images/benefits/item-6.png" alt="img">
            </div>
            <div
              class="benefits-container-card-group-card-info-block"
            >
              <div class="benefits-container-card-group-card-info-block-title">
                <span>
                  Язык - это всего лишь инструмент для ваших целей!
                </span>
                <span>
                  Мы помогаем овладеть им быстро и с интересом
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Benefits"
};
</script>
