<template>
  <div class="personal-details reviews">
    <div class="d-flex align-items-center justify-content-between top">
      <div>
        <div class="tabs-title">
          Отзывы о ресурсе
        </div>
        <div class="tabs-info">
          За что пользователи любят Languages to Go
        </div>
      </div>
      <div class="action">
        <button type="button" class="add-new-note" v-b-modal.create-review>ОСТАВИТЬ ОТЗЫВ</button>
        <b-modal id="create-review" ref="createReview">
          <template #modal-header="{ close }">
            <b-button @click="close()">
              X
            </b-button>
            <h3>Написать отзыв</h3>
          </template>
          <ValidationObserver @submit.prevent="sendReview" tag="form" ref="reviewForm">
            <div class="review-form">
              <div class="left">
                <div class="rate">
                  <p>Поставьте оценку</p>
                  <ValidationProvider name="Оценка" tag="div" class="stars"  v-slot="{ classes, errors }" rules="required">
                    <vue-stars
                      name="demo"
                      v-model="userReviewFormData.rating"
                      :max="5"
                      class="stars-content"
                      :class="classes"
                      :stars-size="100"
                    >
                      <img slot="activeLabel" class="review-star" src="../../../../assets/images/icons/review.svg" alt="review">
                      <img slot="inactiveLabel" class="review-star" src="../../../../assets/images/icons/empty-review.svg" alt="review">
                    </vue-stars>
                    <div class="invalid-feedback">{{errors[0]}}</div>
                  </ValidationProvider>
                </div>
                <div class="inputs">
                  <ValidationProvider tag="div" class="form-group" v-slot="{ classes }">
                    <input type="text" class="form-control" placeholder="Ваше имя"
                           :value="`${user.first_name} ${user.last_name}`" readonly autocomplete="off" name="name"
                           :class="classes">
                  </ValidationProvider>
                  <ValidationProvider tag="div"  class="form-group" v-slot="{ classes }">
                    <input type="text" class="form-control" placeholder="E-mail" :value="user.email"
                           readonly autocomplete="off" name="email" :class="classes">
                  </ValidationProvider>
                </div>
              </div>
              <div class="right">
                <div class="form-group">
                  <ValidationProvider name="Отзыв" tag="div" rules="required" class="text-block" v-slot="{ classes, errors }">
                    <textarea name="review" class="form-control" id="review-textarea" v-model="userReviewFormData.text"
                              placeholder="Заполните поле" :class="[classes, 'review-textarea']"></textarea>
                    <div class="invalid-feedback">{{errors[0]}}</div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="actions">
              <button type="submit" :disabled="disabledSubmitBtn" class="create-review-btn">ОСТАВИТЬ ОТЗЫВ</button>
            </div>
          </ValidationObserver>
        </b-modal>
      </div>
    </div>
    <div class="reviews-block pt-0">
      <div class="reviews mt-0">
        <div class="review" v-for="(review, index) in myReviews" v-bind:key="index">
          <div class="review-top">
            <div class="name">
              <div>{{ review.full_name }}</div>
            </div>
            <div class="stars">
              <template v-for="(number, i) in [1, 2 , 3 , 4 , 5]">
                <img v-if="number <= review.rating" v-bind:key="i" alt="review"
                     src="../../../../assets/images/icons/review.svg">
                <img v-else alt="review" v-bind:key="i" src="../../../../assets/images/icons/empty-review.svg">
              </template>
            </div>
          </div>
          <div class="text">{{ review.text }}</div>
          <div class="review-answer" v-if="review.answer">
            <div class="answer-name">Аdmin</div>
            <div class="text">
              {{ review.answer }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3" v-if="myReviewsPagination.count_pages > 1">
        <b-pagination
          v-model="currentPage"
          :total-rows="myReviewsPagination.count_pages"
          :per-page="1"
          first-number
          last-number
          prev-text="«"
          next-text="»"
          @input="clickPagination"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'AuthReviews',
  data () {
    return {
      currentPage: 1,
      userReviewFormData: {},
      disabledSubmitBtn: false
    }
  },
  mounted () {
    this.userReviewFormData.email = this.user.email
    this.userReviewFormData.full_name = `${this.user.first_name} ${this.user.last_name}`
    this.getMyReviews({ page: this.currentPage })
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated,
      myReviews: state => state.review.myReviews,
      myReviewsPagination: state => state.review.myReviewsPagination
    }),
    ...mapGetters({
      user: 'auth/user'
    })
  },
  methods: {
    ...mapActions({
      addReview: 'review/addReview',
      getMyReviews: 'review/getMyReviews'
    }),
    sendReview () {
      this.$refs.reviewForm.validate().then(success => {
        if (success) {
          this.disabledSubmitBtn = true

          const params = {
            ...this.userReviewFormData,
            full_name:`${this.user.first_name} ${this.user.last_name}`,
            email: this.user.email
          }

          this.addReview(params).then(res => {
            this.$toasted.success('Ваш отзыв отправлен успешно!').goAway(2000)
            this.userReviewFormData = {}
            this.$refs.createReview.hide()
            this.$nextTick(() => {
              this.$refs.reviewForm.reset()
            })
          }).catch(err => {
            if (err.response.status === 400) {
              const data = err.response.data
              const keys = Object.keys(data)
              const msg = data[keys[0]][0]
              this.$toasted.error(msg).goAway(2000)
            } else {
              this.$toasted.error('Ошибка сервера!').goAway(2000)
            }
          }).finally(() => {
            this.disabledSubmitBtn = false
          })
        }
      })
    },
    clickPagination () {
      this.getMyReviews({ page: this.currentPage }).then(() => {
        window.scrollTo(0, 0)
      })
    }
  }
}
</script>
