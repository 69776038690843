<template>
  <TaskBase :task="task" :check-task="checkComponetText" :correct-answer="correctAnswer">
    <template #desktop>
      <div v-drag-and-drop:options="options" v-for="(texts, textIndex) in textArr">
        <div class="d-flex flex-wrap align-items-center task-typos mb-4">
          <template v-for="(word, index) in texts.text">
            <Break v-if="breaks[index]" :breaks="breaks[index]"/>
            <div v-else-if="!breaks[index + 1]" :key="index" class="space" :class="{'ml-2':word === ' '}">
              <div class="d-flex flex-wrap" v-if="word === '|'">
                <div
                  v-for="(answer, charIndex) in viewIncorrectAnswer[texts.id][index].incorrect_answer"
                  class="empty-word bg-neutral dragzone  dragzone-item char-item"
                  @added="added(texts.id,index,charIndex, viewIncorrectAnswer[texts.id][index].id, $event)"
                  :data-word-group="viewIncorrectAnswer[texts.id][index].id"
                  :data-index="index"
                  :data-char-index="charIndex"
                  :class="`${word === '|' &&  error(texts.id, index)}`"
                >
                  {{ answer }}
                </div>
              </div>
              <span v-else>{{ word  === ' '? '&nbsp;': word}}</span>
            </div>
          </template>
        </div>
      </div>
    </template>
  </TaskBase>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import TaskAnswerModal from '../../../../components/TaskAnswerModal'
import VideoAudioGallery from '../../../../components/VideoAudioGallery'
import { VueDraggableDirective } from 'vue-draggable'
import BreakMixin from '../../../../../mixins/BreakMixin'
import Break from '../../../../components/Break'
import TaskBase from '../../../../components/TaskBase'
import TaskCheckParamsMixin from '../../../../../mixins/TaskCheckParamsMixin'

export default {
  name: 'TaskTypos',
  mixins: [BreakMixin, TaskCheckParamsMixin],
  components: {
    TaskBase,
    VideoAudioGallery,
    TaskAnswerModal,
    Break
  },
  directives: {
    dragAndDrop: VueDraggableDirective
  },
  data () {
    return {
      data: null,
      openPrompt: false,
      textArr: [],
      options: {
        dropzoneSelector: '.dragzone',
        draggableSelector: '.dragzone-item',
        showDropzoneAreas: true
      },
      validateShow: false,
      errors: [],
      viewIncorrectAnswer: {},
    }
  },
  computed: {
    ...mapState({
      task: state => state.task.task,
      nextTask: state => state.course.nextTask
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.fullPath !== from.fullPath) {
      Object.assign(this.$data, this.$options.data())
      this.taskInit(to.params.taskId)
    }
    next()
  },
  async mounted () {
    await this.taskInit(this.$route.params.taskId)
  },
  created () {

  },
  watch: {
    task () {

    }
  },
  methods: {
    ...mapActions({
      getTask: 'task/getTask',
      missingTask: 'course/missingTask',
      missTyposTask: 'task/missTyposTask',
      setTaskAnswerModal: 'task/setTaskAnswerModal',
      getMissingTask: 'course/getMissingTask'
    }),
    correctAnswer () {
      for (let key in this.viewIncorrectAnswer) {
        for (let i in this.viewIncorrectAnswer[key]) {
          this.viewIncorrectAnswer[key][i].incorrect_answer = this.viewIncorrectAnswer[key][i].correct_answer.split('')
        }
      }
    },
    taskInit (taskId) {
      const query = {}
      if (this.$route.query.type) {
        query.type = this.$route.query.type
      }
      this.getTask({
        id: taskId,
        query
      }).then(res => {
        this.data = res.data

        this.textArr = this.task.task.texts.map((text, index) => {
          text.text = text.text.replaceAll(/\[.*?\]/ig, '|').split('')
          const incorrectAnswers = {}
          let i = 0
          text.text.forEach((item, wordIndex) => {
            this.pushBreaks(item)
            if (item === '|') {
              incorrectAnswers[wordIndex] = text.answers[i]
              incorrectAnswers[wordIndex].incorrect_answer = text.answers[i].incorrect_answer.split('')
              i++
            }
          })
          this.$set(this.viewIncorrectAnswer, text.id, incorrectAnswers)
          return text
        })
      })
      this.$set(this.textArr, 0, this.textArr[0])
    },
    error (textIndex, index) {
      if (this.errors[textIndex]) {
        if (this.errors[textIndex][index] === true) {
          return 'v-error'
        } else if (this.errors[textIndex][index] === false) {
          return 'bg-green'
        }
      }
      return ''
    },
    added (textIndex, index, charIndex, groupWord, event) {
      const element = event.detail.items[0]

      const dragenIndex = element.getAttribute('data-index')
      const dragenCharIndex = element.getAttribute('data-char-index')
      const attrGroupWords = element.getAttribute('data-word-group')

      if (attrGroupWords != groupWord) {
        return false
      }

      const tempChar = this.viewIncorrectAnswer[textIndex][index].incorrect_answer[charIndex]
      const changeValue = this.viewIncorrectAnswer[textIndex][dragenIndex].incorrect_answer[dragenCharIndex]

      this.$set(this.viewIncorrectAnswer[textIndex][index].incorrect_answer, charIndex, changeValue)
      this.$set(this.viewIncorrectAnswer[textIndex][dragenIndex].incorrect_answer, dragenCharIndex, tempChar)

    },
    missTask () {
      this.missingTask({ task_id: this.$route.params.taskId })
    },
    checkComponetText () {
      this.missTyposTask({
        answers: this.viewIncorrectAnswer,
        ...this.checkParams()
      }).then(res => {
        this.errors = res.data.errors
        if (res.data && res.data.answer) {
          this.validateShow = false
          this.setTaskAnswerModal(res.data).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        } else {

          this.setTaskAnswerModal(false).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        }
      }).catch(e => {
        this.setTaskAnswerModal(false).then(() => {
          this.$root.$emit('bv::show::modal', 'modal-task')
        })
      })
    },
  }
}
</script>

<style scoped lang="scss">
.word-item {
  margin-right: 9px;
}

.space {
  margin-left: 1px;

  @media (max-width: 991px) {
    margin-bottom: 10px;
  }
}

.task-typos{

  @media (max-width: 991px) {
    font-size: 16px !important;
    font-weight: 600;
  }
}

.empty-word {
  border: 1px solid #EBEDF2;
  border-radius: 5px;
  height: 32px;
  min-width: 32px;
  background: #FFFFFF;
  cursor: pointer;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;

  @media (max-width: 991px) {
    font-size: 16px !important;
    font-weight: bold;
    justify-content: start;
  }

  &:not(:first-child) {
    margin-left: 5px;
  }
}

.not-empty {
  background-color: #E6EAFF;
}

.bg-green {
  background-color: #01A852 !important;
  color: white !important;
}

.bg-neutral {
  background-color: #E7EAFD;
  color: black;
}

.v-error {
  background-color: red;
  color: white;
}

</style>
