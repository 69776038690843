var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"read-consultation row"},[_vm._m(0),_c('div',{staticClass:"input-block input-block-pg col-lg-7 col-sm-12"},[_c('div',{staticClass:"ipnut-block-container-pg"},[_c('div',{staticClass:"input-block-container-title"},[_vm._v(" Есть вопросы или хотите получить консультацию? ")]),_vm._m(1),_c('ValidationObserver',{ref:"contactForm",staticClass:"contact-form-pg",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"contact-form-inputs"},[_c('div',{staticClass:"contact-form-input-wrapper"},[_c('ValidationProvider',{staticClass:"contact-provider contact-provider-input",attrs:{"name":"Ваше имя","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"contact-form-input",class:classes,attrs:{"type":"text","placeholder":"Ваше имя"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"contact-provider contact-provider-input",attrs:{"name":"Телефон","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone_number),expression:"form.phone_number"}],staticClass:"contact-form-input",class:classes,attrs:{"type":"tel","placeholder":"Телефон"},domProps:{"value":(_vm.form.phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phone_number", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"contact-provider contact-provider-input",attrs:{"name":"E-mail","rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"contact-form-input",class:classes,attrs:{"type":"email","placeholder":"E-mail"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('ValidationProvider',{staticClass:"contact-provider contact-provider-textarea",attrs:{"name":"Текст сообщения","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.text),expression:"form.text"}],staticClass:"contact-form-textarea contact-form-input",class:classes,attrs:{"placeholder":"Текст сообщения"},domProps:{"value":(_vm.form.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "text", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('ValidationProvider',{staticClass:"contact-form-checkbox",attrs:{"name":"Пользовательское соглашение","rules":"","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.agree),expression:"form.agree"}],class:classes,attrs:{"type":"checkbox","id":"terms","name":"terms","value":"1"},domProps:{"checked":Array.isArray(_vm.form.agree)?_vm._i(_vm.form.agree,"1")>-1:(_vm.form.agree)},on:{"change":function($event){var $$a=_vm.form.agree,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="1",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "agree", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "agree", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "agree", $$c)}}}}),_c('span',{staticClass:"untouched dirty valid validated required passed login-input"}),_c('div',[_vm._v(" Отправляя заявку, я соглашаюсь с "),_c('a',{attrs:{"target":"_blank","href":"/privacy-policy"}},[_vm._v("политикой конфиденциальности")]),_vm._v(" и "),_c('a',{attrs:{"target":"_blank","href":"/terms-of-use"}},[_vm._v("пользовательским соглашением")]),_vm._v(" сайта ")]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('button',{staticClass:"contact-form-btn",attrs:{"disabled":_vm.disabled,"type":"submit"}},[_vm._v("отправить заявку")])],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image col-lg-5 col-cl-5 col-sm-12"},[_c('img',{attrs:{"src":require("../../assets/images/contacts/read-consultation.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-block-container-subtitle"},[_c('span'),_vm._v("Оставьте свои контакты и наш менеджер свяжется с вами в ближайшее время ")])}]

export { render, staticRenderFns }