<template>
  <div class="personal-details-tabs-account">
    <div class="tabs-title">
      Реферальная система
    </div>
    <div class="row mb-4" v-if="coupons.length">
      <div class="col-md-7">
        <CopyInput :label-col="3" :input-col="8" label="Реферальная ссылка:" :readonly="true" :value="refLink.code"/>
        <div class="coupons d-flex">
          <div>
            <div v-for="(code,index) in codes" class="position-relative">
              <CopyInput :label-col="5" :input-col="6" :value="code.code" :key="code.id"
                         :label="index === 0 ? 'Реферальный купон:': ''" :readonly="true"/>
              <i @click="Delete(code.id)" v-if="index > 0"
                 class="fas fa-window-close cursor-pointer position-absolute delete-coupon"></i>
            </div>
          </div>
          <div class="d-flex align-items-end btn-add-wrapper ml-3" v-if="codes.length < 5">
            <b-button @click="addCoupon" :disabled="disabled">Добавить купон</b-button>
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex justify-content-end">
        <template v-if="tariffs.length">
          <div id="tooltip-target-1" class="cursor-pointer">
            <img src="@/assets/images/profile/question.png" alt="question"/>
          </div>
          <b-tooltip target="tooltip-target-1" triggers="hover">
            Вы получаете бонусом процент скидки при
            первой покупки реферала. Процент зависит
            от тарифа курса:
            <span v-html="promptText"></span>
          </b-tooltip>
        </template>
      </div>
      <div class="col-12 d-flex justify-content-end referrals-data">
        <span class="referrals"> Рефералов <span
          class="text-success">{{ referrals.purchasedUsers.length }}</span>/{{
            referrals.registrationUsers.length
          }}</span>
        <span class="bonuses"> Бонусы:  <span class="bonuses-percent">{{ user.bonus }}%</span> </span>
      </div>
      <div class="col-12 mt-50" v-if="incomeBonuses.length">
        <div class="table-title">Начисление бонусов</div>
        <b-table striped hover :items="incomeBonuses" :fields="incomeFields"></b-table>
      </div>
      <div class="col-12 mt-50" v-if="referrals.registrationUsers.length">
        <div class="table-title">Зарегистрированные рефералы</div>
        <b-table striped hover :items="referrals.registrationUsers" :fields="fields"></b-table>
      </div>
      <div class="col-12 mt-50" v-if="outcomeBonuses.length">
        <div class="table-title">Списание бонусов</div>
        <b-table striped hover :items="outcomeBonuses" :fields="outcomeFields"></b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CopyInput from './CopyInput'
import moment from 'moment'
import ToastMixin from '../../../mixins/ToastMixin'

export default {
  name: 'ReferralCoupons',
  mixins: [ToastMixin],
  components: {
    CopyInput
  },
  data () {
    return {
      disabled: false,
      fields: [
        {
          label: 'Дата регистрации',
          key: 'created_at',
          formatter: (value) => {
            return moment(value).format('DD.MM.YYYY')
          }
        },
        {
          label: 'Пользователь',
          key: 'first_name',
          formatter: (value, key, item) => {
            return `${item.last_name} ${item.first_name}`
          }
        },
        {
          label: 'Способ регистрации',
          key: 'coupon',
          formatter: (value, key, item) => {
            return item.coupon === 'link' ? 'Ссылка' : 'Купон'
          }
        },
      ],
      incomeFields: [
        {
          label: 'Дата покупки',
          key: 'created_at',
        },
        {
          label: 'Пользователь',
          key: 'user',
        },
        {
          label: 'Комментарий',
          key: 'comment',
        },
        {
          label: 'Процент',
          key: 'percent',
          formatter: (value) => {
            return `${value}%`
          }
        },
      ],
      outcomeFields: [
        {
          label: 'Дата покупки',
          key: 'created_at',
        },
        {
          label: 'Комментарий',
          key: 'comment',
        },
        {
          label: 'Списание',
          key: 'percent',
          formatter: (value) => {
            return `${value}%`
          }
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      coupons: 'referralCoupon/coupons',
      tariffs: 'tariff/tariffs',
      user: 'auth/user',
      bonuses: 'referralCoupon/bonuses'
    }),
    incomeBonuses () {
      return this.bonuses.filter(bonus => bonus.type === 'accrual')
    },
    outcomeBonuses () {
      return this.bonuses.filter(bonus => bonus.type !== 'accrual')
    },
    refLink () {
      return this.coupons.find(coupon => coupon.type === 'link')
    },
    codes () {
      return this.coupons.filter(coupon => coupon.type === 'code')
    },
    referrals () {
      let registrationUsers = []
      let purchasedUsers = []

      this.coupons.forEach(coupon => {
        registrationUsers = [...registrationUsers, ...coupon.users.map(user => {
          return {
            ...user,
            coupon: coupon.type
          }
        })]
        purchasedUsers = [...purchasedUsers, ...coupon.users.filter(user => user.payments.length > 0)]
      })

      return {
        registrationUsers,
        purchasedUsers
      }
    },
    promptText () {
      return this.tariffs.map(tariff => {
        return `${tariff.name}: ${tariff.percent}`
      }).join('<br/>')
    }
  },
  created () {
    this.getCoupons()
    this.getTariffs()
    this.getAccrualBonuses()
  },
  methods: {
    ...mapActions({
      getCoupons: 'referralCoupon/getCoupons',
      addCoupon: 'referralCoupon/addCoupon',
      getTariffs: 'tariff/getTariffs',
      getAccrualBonuses: 'referralCoupon/getAccrualBonuses',
      deleteCoupon: 'referralCoupon/deleteCoupon'
    }),

    add () {
      this.disabled = true

      this.addCoupon().then(() => {
        this.$toasted.success('Успешно Добавлен').goAway(1500)
      }).catch((e) => {
        this.$toasted.error(e.message)
      }).finally(() => {
        this.disabled = false
      })

    },
    Delete (id) {
      this.ToastShow(
        'Вы точно хотите удалить?',
        'warning',
        () => this.deleteCoupon(id).then(() => {
          this.$toasted.success('Успешно удален').goAway(1500)
        }))
    }
  },
}
</script>

<style scoped lang="scss">
.coupons {
  margin-top: 31px;
}

.btn-add-wrapper {
  padding-bottom: 22px;
}

.referrals {
  margin-right: 31px;
}

.referrals-data {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bonuses {
  display: flex;
  align-items: center;
  justify-content: center;

  &-percent {
    background-color: #ECECEC;
    width: 147px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 23px;
  }
}

.prompt-icon {
  height: 68px;
  width: 68px;
}

.mt-50 {
  margin-top: 50px;
}

.delete-coupon {
  top: -7px;
  right: 7px;

  &:hover {
    color: red;
  }
}

.table-title {
  font-weight: bold;
  margin-bottom: 12px;
}
</style>
