<template>
  <TaskBase :task="task" :check-task="() => searchLetter(true, true)" :correct-answer="correctAnswer">
    <template #desktop>
      <div class="row task-content">
        <div class="fw-lg-6 fw-12 d-flex fortune-column">
          <FortuneWrapper>
            <FortuneBtn :style="{right: '0'}" @click.native="rotateWheel">
              <img src="@/assets/images/tasks/rotateWheel.png" alt="rotate"/>
            </FortuneBtn>
            <FortuneBtn :style="{left: '0'}" @click.native="showPrompt">
              <img src="@/assets/images/tasks/question.png" alt="rotate"/>
            </FortuneBtn>
            <FortuneWheel
              :rotate="rotate"
              :prizes="prizes"
              :useWeight="true"
              :re-draw-canvas="reDrawCanvas"
              :canvas="canvasOptions"
              @rotateEnd="rotateEnd"
            />
          </FortuneWrapper>
          <input class="field-dream-input input-margin input-fw" readonly v-model="taskPrompt"/>
        </div>
        <div class="fw-xl-5 fw-lg-6 col-md-12">
          <draggable
            class="mb-30 d-flex flex-wrap"
            v-model="wordEmptyArray"
            @start="() => false"
            :move="() => false"
            group="letter"
          >
            <TaskItem
              v-for="(letter, index) in wordEmptyArray"
              class="dream-item"
              :activeVB="activeVB"
              :text="letter.answered ? letter.letter: ''"
              :key="index"
              @click.native="() => pickHideLetter(index, letter.letter)"
              :dashed="!letter.answered"
              data-name="answers"
            />
          </draggable>

          <draggable
            class="d-flex flex-wrap mb-50"
            v-model="letters"
            group="letter"
            :move="handleMove"
            @end="handleDragEnd"
            draggable=".draggable"
          >
            <TaskItem
              v-for="(letter, index) in letters"
              class="dream-item"
              :class="{'draggable': !letter.success}"
              :text="letter.letter"
              :key="index"
              :success="letter.success"
              :error="letter.error"
              :datted="index === activeLetter"
              data-name="letters"
              @click.native="pickLetter(index)"
            />
          </draggable>
          <div>
            <input class="field-dream-input mb-20 input-fw-lg" readonly v-model="taskPrompt"/>

            <button class="btn-rotate show-only-pc" type="button" @click="rotateWheel">
              КРУТИТЬ КОЛЕСО
            </button>
          </div>
        </div>
      </div>
      <PromptModal ref="modal" v-model="promptModal" />
    </template>
  </TaskBase>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import draggable from 'vuedraggable'
import TaskAnswerModal from '../../../../components/TaskAnswerModal'
import VideoAudioGallery from '../../../../components/VideoAudioGallery'
import TaskBase from '../../../../components/TaskBase'
import TaskCheckParamsMixin from '../../../../../mixins/TaskCheckParamsMixin'
import TaskItem from '../../../../components/Tasks/TaskItem'
import FortuneWheel from '../../../../components/Tasks/fortuneWheel'
import ArrayShuffleMixin from '../../../../../mixins/ArrayShuffleMixin'
import FortuneWrapper from '../../../../components/Tasks/Fortune/FortuneWrapper'
import FortuneBtn from '../../../../components/Tasks/Fortune/FortuneBtn'
import Swal from 'sweetalert2'
import PromptModal from './components/PromptModal'

export default {
  name: 'Wheelfield-dreams',
  mixins: [TaskCheckParamsMixin, ArrayShuffleMixin],
  components: {
    PromptModal,
    FortuneBtn,
    FortuneWrapper,
    TaskItem,
    TaskBase,
    VideoAudioGallery,
    TaskAnswerModal,
    FortuneWheel,
    draggable
  },
  data () {
    return {
      letters: [],
      wordEmptyArray: [],
      reDrawCanvas: false,
      rotate: false,
      canvasOptions: {
        textDirection: 'horizontal',
        type: 'image',
        textRadius: 260
      },
      promptModal: false,
      prizes: [
        {
          color: '#ffffff',
          id: 13,
          index: 0,
          name: 'Б',
          probability: 6.25,
          weight: 1,
          image: require('../../../../../assets/images/tasks/dream_text.png')
        },
        {
          color: '#ffffff',
          id: 3,
          index: 0,
          name: 'СЛБ',
          probability: 6.25,
          weight: 1,
          image: require('../../../../../assets/images/tasks/dream_text_dashed.png')
        },
        {
          color: '#ffffff',
          id: 14,
          index: 0,
          name: 'Б',
          probability: 6.25,
          weight: 1,
          image: require('../../../../../assets/images/tasks/dream_text.png')
        },
        {
          color: '#ffffff',
          id: 1,
          index: 0,
          name: 'Х2СЛБ',
          probability: 6.25,
          weight: 1,
          image: require('../../../../../assets/images/tasks/dream_double_dashed.png')
        },
        {
          color: '#ffffff',
          id: 16,
          index: 0,
          name: 'Б',
          probability: 6.25,
          weight: 1,
          image: require('../../../../../assets/images/tasks/dream_text.png')
        },
        {
          color: '#ffffff',
          id: 4,
          index: 0,
          name: 'ВБ',
          probability: 6.25,
          weight: 1,
          image: require('../../../../../assets/images/tasks/dream_book.png')
        },
        {
          color: '#ffffff',
          id: 6,
          index: 0,
          name: 'Б',
          probability: 6.25,
          weight: 1,
          image: require('../../../../../assets/images/tasks/dream_text.png')
        },
        {
          color: '#ffffff',
          id: 2,
          index: 0,
          name: 'СЛБ',
          probability: 6.25,
          weight: 1,
          image: require('../../../../../assets/images/tasks/dream_text_dashed.png')
        },
        {
          color: '#ffffff',
          id: 6,
          index: 0,
          name: 'Б',
          probability: 6.25,
          weight: 1,
          image: require('../../../../../assets/images/tasks/dream_text.png')
        },
        {
          color: '#ffffff',
          id: 5,
          index: 0,
          name: 'ВБ',
          probability: 6.25,
          weight: 1,
          image: require('../../../../../assets/images/tasks/dream_book.png')
        },
      ],
      taskPrompts: {
        ['Б']: 'Укажите букву',
        ['Х2СЛБ']: 'Скрыть 2 лишние буквы из предложенных',
        ['СЛБ']: 'Скрыть лишнюю букву',
        ['ВБ']: 'Выбрать букву',
      },
      taskPrompt: 'Крутите барабан',
      draggedContext: null,
      relatedContext: null,
      dragged: null,
      related: null,
      stopDragged: true,
      activePrize: {},
      activeLetter: null,
      lettersArray: []
    }
  },
  computed: {
    ...mapState({
      task: state => state.task.task,
      nextTask: state => state.course.nextTask
    }),
    activeB () {
      return this.activePrize.name === 'Б'
    },
    activeVB () {
      return this.activePrize.name === 'ВБ'
    },
    activeCLB () {
      return this.activePrize.name === 'СЛБ'
    },
    activeX2CLB () {
      return this.activePrize.name === 'Х2СЛБ'
    },
    isFinish () {
      return this.wordEmptyArray.filter(letter => letter.answered === false).length === 0
    }

  },
  beforeRouteUpdate (to, from, next) {
    if (to.fullPath !== from.fullPath) {
      Object.assign(this.$data, this.$options.data())
      this.taskInit(to)
    }
    next()
  },
  watch: {
    activePrize (newVal) {
      if (this.isEmpty(newVal)) {
        this.taskPrompt = 'Крутите барабан'
      }
    }
  },
  created () {
    this.taskInit(this.$route)
  },
  methods: {
    ...mapActions({
      getTask: 'task/getTask',
      missingTask: 'course/missingTask',
      getMissingTask: 'course/getMissingTask',
      checkFieldDreamsLetter: 'task/checkFieldDreamsLetter',
      setTaskAnswerModal: 'task/setTaskAnswerModal'
    }),
    loadImage (url) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = () => resolve(image)
        image.onerror = reject
        image.src = url
      })
    },
    isEmpty (obj) {
      for (let key in obj) {
        // если тело цикла начнет выполняться - значит в объекте есть свойства
        return false
      }
      return true
    },
    showPrompt () {
      this.$refs['modal'].$refs['modal'].show()
    },
    taskInit (route) {
      const query = {}
      if (route.query.type) {
        query.type = route.query.type
      }
      this.getTask({
        id: route.params.taskId,
        query
      }).then(() => {
        this.lettersArray = this.task.course_teaching_language.letters_array

        this.letters = this.shuffle(this.generateLetters()).map(letter => {
          return {
            letter,
            success: false,
            error: false,
            temp: false
          }
        })

        this.task.task.word.split('').map(item => {
          this.wordEmptyArray.push({
            letter: item,
            answered: false
          })
        })
      })
    },
    generateLetters () {
      const wordLetter = this.task.task.word.split('')
      let letters = []
      let number = 3

      if (wordLetter.length >= 10) {
        number = 2
      }

      letters = wordLetter
      const countLetters = wordLetter.length * number
      for (let i = 0; i < countLetters; i++) {
        letters.push(this.lettersArray[this.getRandomIntInclusive(0, this.lettersArray.length - 1)])
      }
      return letters
    },
    getRandomIntInclusive (min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min //Максимум и минимум включаются
    },
    pickLetter (index) {
      if (this.activeB && !this.stopDragged) {
        this.activeLetter = index
      }
    },
    correctAnswer () {
      this.letters = this.letters.map(letter => {
        return {
          ...letter,
          success: false
        }
      })
      this.wordEmptyArray.map(letterNeedle => {
        this.$set(letterNeedle, 'answered', true)
        this.letters.filter(letter => letter.success === false).every(letter => {
          if (letter.letter.toLowerCase() === letterNeedle.letter.toLowerCase()) {
            this.$set(letter, 'success', true)
            this.$set(letter, 'error', false)
            return false
          }
          return true
        })
      })
    },
    showHideLetter (index) {
      this.$set(this.wordEmptyArray[index], 'answered', true)
    },
    pickHideLetter (index, letter) {
      if (!this.activeVB && !this.activeB) return false

      if (this.activeVB) {
        this.showHideLetter(index)

        for (let i = 0; i < this.letters.length; i++) {
          if (this.letters[i].letter.toLowerCase() === letter.toLowerCase()) {
            this.$set(this.letters[i], 'success', true)
            break
          }
        }
        this.stopDragged = false
      } else if (this.activeLetter !== null) {
        if (this.letters[this.activeLetter].letter.toLowerCase() === letter.toLowerCase()) {
          this.$set(this.letters[this.activeLetter], 'success', true)
          this.$set(this.letters[this.activeLetter], 'error', false)
          this.showHideLetter(index)
        } else {
          this.$set(this.letters[this.activeLetter], 'error', true)
          this.searchLetter(true)
        }
        this.stopDragged = true
      }

      if (this.activeLetter !== null || this.activeVB) {
        this.activeLetter = null
        this.activePrize = {}
        this.rotate = false
      }

    },
    handleDragEnd () {
      const isLetterGroup = this.related.dataset.name !== 'answers'
      if (this.stopDragged || !this.relatedContext || !this.activeB || isLetterGroup) return false

      const draggedLetter = this.draggedContext.element.letter.toLowerCase()
      if (draggedLetter === this.relatedContext.element.letter.toLowerCase()) {
        this.$set(this.letters[this.draggedContext.index], 'success', true)
        this.$set(this.letters[this.draggedContext.index], 'error', false)
        this.showHideLetter(this.relatedContext.index)
        this.searchLetter(this.isFinish)
      } else {
        if (this.wordEmptyArray.find(letter => letter.letter === draggedLetter)) {
          this.$set(this.letters[this.draggedContext.index], 'error', true)
        } else {
          this.$set(this.letters[this.draggedContext.index], 'error', true)
        }
        this.searchLetter(true)
      }

      this.activePrize = {}
      this.stopDragged = this.activeB
      this.rotate = false
    },
    handleMove (e) {
      const letter = this.letters[e.draggedContext.index]
      this.activeLetter = null
      if (letter.success) {
        this.stopDragged = true
        return false
      }
      this.dragged = e.dragged
      this.related = e.related
      this.draggedContext = e.draggedContext
      this.relatedContext = e.relatedContext
      return false
    },
    rotateWheel () {
      if (!this.rotate) {
        this.taskPrompt = ''
      }

      this.rotate = true
    },
    hideSuperfluousLetter (limit = 1) {
      let loop = 0
      this.letters.filter(letter => !letter.success && !letter.error).every(letter => {
        if (!this.wordEmptyArray.find(letterWord => {
          return !letterWord.answered && letter.letter.toLowerCase() === letterWord.letter.toLowerCase()
        })) {
          this.$set(letter, 'error', true)
          loop++
          if (loop === limit) {
            return false
          }

        }
        return true
      })
    },
    rotateEnd (prize) {
      this.taskPrompt = this.taskPrompts[prize.name]
      this.activePrize = prize

      if (this.activeB) {
        this.stopDragged = false
      }

      if (this.activeCLB) {
        this.hideSuperfluousLetter()
        this.rotate = false
      }

      if (this.activeX2CLB) {
        this.hideSuperfluousLetter(2)
        this.rotate = false
      }

    },
    missTask () {
      this.missingTask({ task_id: this.$route.params.taskId })
    },
    searchLetter (sendRequest, showErrorModal = false) {
      if (!sendRequest) return

      this.checkFieldDreamsLetter({
        finish: this.isFinish ? 1 : 0,
        uuid: this.task.uuid,
        ...this.checkParams()
      }).then(res => {
        if (res.data.answer) {
          this.setTaskAnswerModal(res.data).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        } else {
          if (!showErrorModal) return

          this.setTaskAnswerModal(false).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        }

      }).finally(() => {
        this.buttonDisabled = false
      })

    }
  }
}
</script>

<style lang="scss" scoped>
.dream-item {
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  font-size: 18px;
  font-weight: 700;
  text-transform: lowercase !important;
  padding: 0;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
  }
}

.fortune-column {
  align-items: center;
  flex-direction: column-reverse;
  margin-bottom: 32px;

  @media (min-width: 768px) {
    flex-direction: column;
    margin-bottom: 0;
  }

  @media (min-width: 1440px) {
    align-items: start;
  }
}

.task-content {
  @media (min-width: 768px) {
    padding: 0 53px;
  }

  @media (min-width: 1440px) {
    padding: 0
  }
}

.show-only-pc {
  display: none;

  @media (min-width: 1440px) {
    display: block;
  }
}

.field-dream-input {
  height: 50px;
  border-radius: 30px;
  border: none;
  background: #FFFFFF;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  padding: 18px 30px;
  width: 100%;
  color: #555555;
  text-transform: uppercase;

  @media (min-width: 768px) {
    height: 60px;
    font-size: 18px;
  }

  &.input-margin {
    @media (min-width: 768px) {
      margin-top: 34px;
      margin-bottom: 50px;
    }
  }

  &.input-fw {
    margin-bottom: 32px;

    @media (min-width: 768px) {
      display: block;
    }
    @media (min-width: 1440px) {
      display: none;
    }
  }

  &.input-fw-lg {
    display: none;
    @media (min-width: 1440px) {
      display: block;
    }
  }
}

.fw-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.fw-lg-6 {
  @media (min-width: 1440px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.fw-xl-5 {
  @media (min-width: 1920px) {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
}

.draggable {
  cursor: pointer;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}
</style>
