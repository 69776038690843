<template>
  <div>
    <div v-if="type === 'images'" class="categorize-item position-relative" :class="{
    'dashed':dashed,
    'success':success,
    'error':error,
  }">
      <template v-if="image">
        <img class="item-image" :src="image" :alt="name">
        <img @click="zoomImage" v-if="zoom" class="image-zoom" :class="zoomClass" src="@/assets/images/tasks/zoom.png" alt="zoom">
      </template>
      <img v-else class="only-mob pick-word-image" src="@/assets/images/tasks/pick-word.png" alt="image">

    </div>
    <div v-else class="categorize-mob">
      <TaskItem
        :text="name"
        :class="{
    'dashed':dashedCheck,
  }"
        :success="success"
        :error="error"
        :used="!name"
      />

      <MobileIcon class="ml-10" :name="icon"/>
    </div>

  </div>
</template>

<script>
import TaskItem from '../TaskItem'
import MobileIcon from '../MobileIcon'
import 'viewerjs/dist/viewer.css'
import { api as viewerApi } from 'v-viewer'

export default {
  name: 'CategorizeItem',
  components: {
    MobileIcon,
    TaskItem,
  },
  props: {
    image: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null
    },
    dashed: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    zoom: {
      type: Boolean,
      default: false
    },
    zoomClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      viewer: null
    }
  },
  computed: {
    dashedCheck () {
      return this.dashed && !this.success && !this.error
    },
    icon () {
      if (this.success) {
        return 'success'
      } else if (this.error) {
        return 'error'
      }

      return 'pick-word'
    }
  },

  methods: {
    zoomImage () {
      const $viewer = viewerApi({
        options: {
          toolbar: false,
        },
        images: [this.image]
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../scss/variables";

.word-item {
  margin-right: 0;
  height: 60px;

  @media (min-width: $md) {
    width: 220px;
    min-width: 220px;
  }

  @media (min-width: $lg) {
    width: 190px;
    min-width: 190px;
  }

  @media (min-width: $xl) {
    width: 220px;
    min-width: 220px;
  }
}

.categorize-mob {
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
  }
}

.categorize-item {
  height: 80px;
  width: 80px;
  border-radius: 10px;
  border: 1px solid #b6bbcc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &.small {
    height: 50px;
    width: 50px;
  }

  @media (min-width: $md) {
    height: 100px;
    width: 100px;
  }

  @media (min-width: $lg) {
    height: 150px;
    width: 150px;
  }

  @media (min-width: $xl) {
    height: 180px;
    width: 180px;
  }

  .item-image {
    height: 100%;
    width: 100%;
  }
}

.image-zoom {
  position: absolute;
  width: 28px;
  height: 28px;

  @media (min-width: $md) {
    width: 55px;
    height: 55px;
  }

}

.ml-10 {
  margin-left: 10px;
}

.dashed {
  border: 3px dashed #b6bbcc;
  background-color: transparent;
}

.error {
  border: 5px solid #CD3B45;
}

.success {
  border: 5px solid #219653;
}

</style>
