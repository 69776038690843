<template>
  <div>
    <!-- When there are notes -->
    <div class="personal-details notes" v-if="notesList && notesList.length">
      <div class="d-flex align-items-center justify-content-between top">
        <div class="tabs-title">
          Мои заметки
        </div>
        <div class="action">
          <button type="button" class="add-new-note" @click="openCreateUpdateModal('create')">СОЗДАТЬ НОВУЮ ЗАМЕТКУ</button>
        </div>
      </div>
      <div class="notes-content">
        <masonry
          style="width: 100%"
          columnClass="notes-column"
          :cols="{default: 3, 1440: 2, 991: 1}"
          :gutter="{default: '0'}">
            <div class="note-item" v-for="(note, index) in notesList" :key="index">
              <div class="top-line">
                <div class="title">
                  {{note.title}}
                </div>
                <div class="actions">
                  <a href="#" @click.prevent="openCreateUpdateModal('edit', note.id)">
                    <img src="~@/assets/images/profile/edit.svg" alt="edit">
                  </a>
                  <a href="#" @click.prevent="openDeleteModal(note.id)">
                    <img src="~@/assets/images/profile/remove.svg" alt="edit">
                  </a>
                </div>
              </div>
              <div class="note-content">
                {{note.text}}
              </div>
            </div>
        </masonry>
      </div>
    </div>
    <!-- When there is no note -->
    <div class="personal-details notes" v-else>
      <div class="tabs-title">
        Мои заметки
      </div>
      <div class="tabs-info">
        У вас еще нет заметок
      </div>
      <div class="action">
        <button type="button" class="add-new-note" @click="openCreateUpdateModal('create')">СОЗДАТЬ НОВУЮ ЗАМЕТКУ</button>
      </div>
      <div class="notes-content">
        <img src="~@/assets/images/profile/notes.svg" alt="notes">
      </div>
    </div>
    <b-modal id="remove-note" ref="removeNoteModal">
      <template #modal-header="{ close }">
        <b-button @click="close()">
          X
        </b-button>
        <h3>Удалить заметку</h3>
      </template>
      <div>
        Вы собираетесь удалить вашу заметку. После ее удаления
        отменить действие будет невозможным. Вы уверены что хотите покинуть чат?
      </div>
      <div class="actions">
        <button type="button" class="cancel" @click="closeDeleteModal()">Отмена</button>
        <button type="button" class="remove" @click="removeNote">Удалить</button>
      </div>
    </b-modal>

    <b-modal id="add-note" ref="addNote">
      <template #modal-header="{ close }">
        <b-button @click="close()">
          X
        </b-button>
        <h3 v-if="isCreate">Создать заметку</h3>
        <h3 v-else>Редактировать заметку</h3>
      </template>
      <ValidationObserver ref="noteAddForm" id="noteAddForm" tag="form" @submit.prevent="createUpdateNote()">
        <ValidationProvider rules="required" tag="div" v-slot="{ classes }" class="form-group">
          <label>Название заметки</label>
          <input type="text" maxlength="80" class="form-control" :class="classes" v-model="formData.title" name="title" placeholder="Моя заметка">
        </ValidationProvider>
        <ValidationProvider rules="required" tag="div" v-slot="{ classes }" class="form-group">
          <label>Заметка</label>
          <textarea class="form-control" :class="classes" maxlength="800" v-model="formData.text" name="text" placeholder="Заметка"></textarea>
        </ValidationProvider>
        <div class="actions">
          <button type="submit" :disabled="buttonDisable" :class="buttonDisable ? 'btn-disabled' : ''" class="add-note" v-if="isCreate">СОХРАНИТЬ</button>
          <button type="submit" :disabled="buttonDisable" :class="buttonDisable ? 'btn-disabled' : ''" class="add-note" v-else>РЕДАКТИРОВАТЬ ЗАМЕТКУ</button>
        </div>
      </ValidationObserver>
    </b-modal>
    <div class="mt-3" v-if="notesPagination.count_pages > 1">
      <b-pagination
        v-model="currentPage"
        :total-rows="notesPagination.count_pages"
        :per-page="1"
        first-number
        last-number
        prev-text="«"
        next-text="»"
        @input="clickPagination"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Notes',
  data () {
    return {
      isCreate: true,
      currentPage: 1,
      formData: {},
      buttonDisable: false,
      noteId: ''
    }
  },
  computed: {
    ...mapState({
      notesList: state => state.note.notesList,
      noteItem: state => state.note.noteItem,
      notesPagination: state => state.note.notesPagination
    })
  },
  mounted () {
    this.getNotesList({ count: 9 })
  },
  methods: {
    ...mapActions({
      getNotesList: 'note/getNotesList',
      newNote: 'note/newNote',
      updateNote: 'note/updateNote',
      getNoteItem: 'note/getNoteItem',
      deleteNote: 'note/deleteNote'
    }),
    openCreateUpdateModal (modalType, id) {
      this.formData = {}
      if (modalType === 'create') {
        this.isCreate = true
      } else {
        this.isCreate = false
        this.getNoteItem({ id }).then(() => {
          this.formData = this.noteItem
          this.noteId = id
        })
      }
      this.$refs.addNote.show()
    },
    openDeleteModal (id) {
      this.noteId = id
      this.$refs.removeNoteModal.show()
    },
    closeDeleteModal () {
      this.$refs.removeNoteModal.hide()
    },
    removeNote () {
      this.deleteNote({ id: this.noteId }).then(() => {
        this.getNotesList({ count: 9, page: this.currentPage }).then(() => {
          this.closeDeleteModal()
          this.$toasted.success('Заметка успешно удалена!').goAway(1500)
        })
      })
    },
    createUpdateNote () {
      this.$refs.noteAddForm.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          if (this.isCreate) {
            this.newNote(this.formData).then(() => {
              this.getNotesList({ count: 9, page: this.currentPage }).then(() => {
                this.$toasted.success('Заметка создано успешно!').goAway(1500)
                this.$refs.noteAddForm.reset()
                this.$refs.addNote.hide()
                this.formData = {}
              })
            }).catch(() => {
              this.$toasted.error('Что-то пошло не так!').goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateNote({ id: this.noteId, data: this.formData }).then(() => {
              this.$toasted.success('Заметка успешно обновлено!').goAway(1500)
              this.$refs.noteAddForm.reset()
              this.$refs.addNote.hide()
              this.formData = {}
            }).catch(() => {
              this.$toasted.error('Что-то пошло не так!').goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error('Есть незаполненные поля').goAway(1500)
        }
      })
    },
    clickPagination () {
      this.getNotesList({ count: 9, page: this.currentPage }).then(() => {
        window.scrollTo(0, 0)
      })
    }
  }
}
</script>
