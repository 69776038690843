<template>
  <div class="profile-footer">
    <div class="profile-footer-main">
      <div class="profile-footer-main-menu">
        <div class="profile-footer-main-menu-item">
          <router-link to="/privacy-policy">Политика конфиденциальности</router-link>
        </div>
        <div class="profile-footer-main-menu-item">
          <router-link to="/terms-of-use">Пользовательское соглашение</router-link>
        </div>
      </div>
      <div class="profile-footer-main-social">
        <ul class="profile-footer-main-social-link-list">
          <li v-if="settings.facbook">
            <a :href="settings.facbook">
              <img src="@/assets/images/icons/facebook.svg" alt="facebook" />
            </a>
          </li>
          <li v-if="settings.instagram">
            <a :href="settings.instagram">
              <img src="@/assets/images/icons/instagram.svg" alt="instagram" />
            </a>
          </li>
          <li v-if="settings.youtube">
            <a :href="settings.youtube">
              <img src="@/assets/images/icons/youtube.svg" alt="youtube" />
            </a>
          </li>
          <li v-if="settings.vk">
            <a :href="settings.vk">
              <img src="@/assets/images/icons/vk.svg" alt="vk" />
            </a>
          </li>
          <li v-if="settings.ok">
            <a :href="settings.ok">
              <img src="@/assets/images/icons/ok.svg" alt="ok" />
            </a>
          </li>
          <li v-if="settings.twitter">
            <a :href="settings.twitter">
              <img src="@/assets/images/icons/twitter.svg" alt="twitter" />
            </a>
          </li>
          <li v-if="settings.tiktok">
            <a :href="settings.tiktok">
              <img src="@/assets/images/icons/tiktok.svg" alt="tiktok" />
            </a>
          </li>
          <li v-if="settings.telegram">
            <a :href="settings.telegram">
              <img src="@/assets/images/icons/telegram.svg" alt="telegram" />
            </a>
          </li>
        </ul>
      </div>
      <div class="profile-footer-main-cards" v-if="paymentMethodList && paymentMethodList.length">
        <ul class="profile-footer-main-cards-list">
          <li v-for="(payment, index) in paymentMethodList" :key="index">
            <img :src="payment.footer_image" :alt="payment.name[$i18n.locale]" />
          </li>
        </ul>
      </div>
    </div>
    <div class="profile-footer-secondary">
      <div class="profile-footer-secondary-copyright">
        2020 | © Language to go | All rights reserved
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'ProfileFooter',
  computed: {
    ...mapState({
      paymentMethodList: state => state.paymentMethod.paymentMethodList
    }),
    ...mapGetters({
      settings: 'setting/settings'
    })
  },
  created() {
    this.getPaymentMethodList()
  },
  methods: {
    ...mapActions({
      getPaymentMethodList: 'paymentMethod/getPaymentMethodList'
    })
  }
}
</script>
