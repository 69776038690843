<template>
  <div class="personal-details lessons dashboard">
    <div class="tabs-title mb-4">
      Бесплатные уроки
    </div>
    <loading v-if="loading"/>
    <div v-else class="personal-details-tabs">
      <b-tabs :class="{'mobile-tabs': is_mobile}" v-if="!is_mobile">
        <b-tab @click="$router.push('/profile/lessons/all')" :active="paramsId !== 'additional'">
          <template #title>
            <span>Основные курсы</span>
          </template>
          <div class="personal-details-tabs-main">
            <div class="text-part">
              <div class="sub-title">
                Бесплатные уроки основных языковых курсов
              </div>
              <div class="tabs-info">
                Для ознакомления с нашим продуктом предоставляем вам доступ к первым урокам каждого доступного курса
              </div>
            </div>
          </div>
          <b-tabs class="sub-tabs" v-if="teachinglangs">
            <b-tab @click="activeLanguage = 'all'" :active="activeLanguage === 'all'">
              <template #title>
                <span>ВСЕ КУРСЫ</span>
              </template>
            </b-tab>
            <b-tab v-for="(language, index) in teachinglangs" :key="index" @click="activeLanguage = language.id"
                   :active="activeLanguage === language.id">
              <template #title>
                <img :src="language.flag" width="30" alt="">
                <span class="text-uppercase">{{ language.name[$i18n.locale] }}</span>
              </template>
            </b-tab>
            <div class="courses">
              <div class="language-courses">
                <div class="lang-courses-items">
                  <FreeLessonCard  v-for="(lesson, index) in lessons" :key="lesson.id" :lesson="lesson"/>
                </div>
              </div>
            </div>
          </b-tabs>
        </b-tab>
        <b-tab @click="$router.push('/profile/lessons/additional')" :active="$route.params.id === 'additional'"
               v-if="hasAdditional">
          <template #title>
            <span>Дополнительные курсы</span>
          </template>
          <div class="personal-details-tabs-main">
            <div class="text-part">
              <div class="sub-title">
                Бесплатные уроки дополнительных языковых курсов
              </div>
              <div class="tabs-info">
                Для ознакомления с нашим продуктом предоставляем вам доступ к первым урокам курсов,
                которые состоят из более чем 3 уроков
              </div>
            </div>
          </div>
          <b-tabs class="sub-tabs" v-if="teachinglangs">
            <b-tab @click="activeLanguage = 'all'" :active="activeLanguage === 'all'">
              <template #title>
                <span>ВСЕ КУРСЫ</span>
              </template>
            </b-tab>
            <b-tab v-for="(language, index) in teachinglangs" :key="index" @click="activeLanguage = language.id"
                   :active="activeLanguage === language.id">
              <template #title>
                <img :src="language.flag" width="30" alt="">
                <span class="text-uppercase">{{ language.name[$i18n.locale] }}</span>
              </template>
            </b-tab>
            <div class="courses">
              <div class="language-courses">
                <div class="lang-courses-items">
                  <FreeLessonCard v-for="(lesson, index) in lessons" :lesson="lesson" :key="lesson.id"/>
                </div>
              </div>
            </div>
          </b-tabs>
        </b-tab>
      </b-tabs>
      <div :class="{'mobile-accordion': is_mobile}" v-else>
        <div class="accordion" role="tablist">
          <div class="mb-1">
            <div role="tab">
              <b-button block @click="$router.push('/profile/lessons/all')" v-b-toggle.accordion-1
                        class="accordion-btn">
                <template>
                  <span>Основные курсы</span>
                </template>
              </b-button>
            </div>
            <b-collapse id="accordion-1" class="accordion-lessons" visible accordion="my-accordion" role="tabpanel">
              <div class="text-part mt-2">
                <div class="sub-title">
                  Бесплатные уроки основных языковых курсов
                </div>
                <div class="tabs-info mt-2">
                  Для ознакомления с нашим продуктом предоставляем вам доступ к первым урокам каждого доступного курса
                </div>
              </div>
              <b-tabs class="sub-tabs sub-tabs-mini" id="sub-tabs-mini">
                <b-tab @click="$router.push('/profile/lessons/all')" :active="$route.params.id === 'all'">
                  <template #title>
                    <span>ВСЕ КУРСЫ</span>
                  </template>
                </b-tab>
                <b-tab v-for="(language, index) in teachinglangs" :key="index"
                       @click="$router.push('/profile/lessons/' + language.id)"
                       :active="$route.params.id === language.id">
                  <template #title>
                    <img class="sub-tabs-mini-image" :src="language.flag" width="30" alt="">
                    <span class="text-uppercase sub-tabs-mini-name">{{ language.name[$i18n.locale] }}</span>
                  </template>
                </b-tab>
                <div class="personal-details-tabs-main">
                  <div class="courses">
                    <div class="language-courses">
                      <div class="lang-courses-items">
                        <FreeLessonCard  v-for="(lesson, index) in lessons" :key="lesson.id" :lesson="lesson"/>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tabs>
            </b-collapse>
          </div>
          <div class="mb-1" v-if="hasAdditional">
            <div role="tab">
              <b-button block v-b-toggle.accordion-2 @click="$router.push('/profile/lessons/additional')"
                        class="accordion-btn">
                <template>
                  <span>Дополнительные курсы</span>
                </template>
              </b-button>
            </div>
            <b-collapse id="accordion-2" class="accordion-lessons" visible accordion="my-accordion" role="tabpanel">
              <div class="personal-details-tabs-main">
                <div class="courses-language-to-go">
                  <div class="text-part">
                    <div class="sub-title">
                      Основные языковые курсы
                    </div>
                    <div class="tabs-info">
                      Перед покупкой курса Вам нужно будет пройти тест на определение уровня знаний языка
                    </div>
                  </div>
                  <div class="courses">
                    <div class="language-courses">
                      <div class="lang-courses-items">
                        <FreeLessonCard  v-for="(lesson, index) in lessons" :key="lesson.id" :lesson="lesson"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3" v-if="pagination && pagination.count_pages > 1 && !loading">
      <b-pagination
        v-model="currentPage"
        :total-rows="pagination.count_pages"
        :per-page="formData.page"
        first-number
        last-number
        prev-text="«"
        next-text="»"
        @input="clickPagination"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Loading from '../../../components/Loading'
import FreeLessonCard from '../../../components/Lessons/FreeLessonCard'

export default {
  name: 'ProfileLessons',
  components: { Loading, FreeLessonCard },
  data () {
    return {
      currentPage: 1,
      paramsId: '',
      formData: {
        page: 1
      },
      activeLanguage: 'all',
      hasAdditional: false
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.id) {
      if (to.query.page) {
        this.currentPage = to.query.page
      } else {
        this.currentPage = 1
      }

      if (to.params.id !== 'additional') {
        this.getLessons({
          course_type: 'main',
          language: this.activeLanguage,
          count: 12,
          page: this.currentPage
        }).then(res => {
          this.paramsId = to.params.id
        })
      } else {
        this.getLessons({
          course_type: 'additional',
          language: this.activeLanguage,
          count: 12,
          page: this.currentPage
        }).then(res => {
        })
      }
    }
    next()
  },
  computed: {
    ...mapState({
      teachinglangs: state => state.teachingLang.teachinglangs,
      lessons: state => state.lesson.availableLessons,
      pagination: state => state.lesson.pagination,
      loading: state => state.loading.loading
    }),
    is_mobile () {
      const isMobile = window.matchMedia('only screen and (max-width: 576px)')
      return !!isMobile.matches
    }
  },
  watch: {
    activeLanguage () {
      this.getLessonsByParams()
    }
  },
  created () {
    this.getLessons({
      course_type: 'main',
      language: this.$route.params.id,
      count: 12,
      page: this.currentPage
    }).then(res => {
      this.hasAdditional = res.hasAdditional
      this.paramsId = this.$route.params.id
    })
    this.getTeachingLangs().then(res => {
    })
  },
  methods: {
    ...mapActions({
      getLessons: 'lesson/getAvailableLessons',
      getTeachingLangs: 'teachingLang/getTeachingLangs'
    }),
    getLessonsByParams () {
      let course_type = 'additional'
      if (this.$route.params.id === 'all') {
        course_type = 'main'
      }
      this.getLessons({
        course_type,
        language: this.activeLanguage,
        count: 12,
        page: this.currentPage
      }).then(res => {
      })
    },
    clickPagination (page) {
      this.$router.push('/profile/lessons/' + this.$route.params.id + '?page=' + this.currentPage)
      window.scrollTo(0, 0)
    }
  }
}
</script>
