<template>
  <div v-if="show">
    <VideoPlayer
      v-for="(video, index) in videos"
      :key="index"
      class="mb-4"
      :video="video"/>
    <mini-audio
      v-if="file"
      class="mt-30"
      :audio-source="file"
    ></mini-audio>
    <Gallery v-if="gallery.length" class="mt-30" :data="gallery"/>
  </div>
</template>

<script>
import Gallery from '../components/Gallery'
import VideoPlayer from '../components/VideoPlayer'

export default {
  components: {
    VideoPlayer,
    Gallery
  },
  props: {
    data: {
      type: Object
    },
    videoMultiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      file: null,
      gallery: [],
      videos: []
    }
  },
  mounted () {
    this.file = this.data.audio

    this.videosInit()

    if (this.data.gallery) {
      this.data.gallery.forEach(item => {
        this.gallery.push({
          id: item.id,
          image: item.file_path.full
        })
      })
    }
  },
  computed: {
    show () {
      return (this.file || (this.gallery.length > 0) || (this.videos.length > 0)) &&
        (this.videos.find((video) => video.video_iframe || video.video_path))
    }
  },
  methods: {
    videosInit () {
      if (!this.videoMultiple) {
        this.videos.push({
          video_path: this.data.video,
          video_iframe: this.data.video_iframe
        })
      } else {
        this.data.videos.forEach(video => {
          this.videos.push(video)
        })
      }
    }
  }
}
</script>

<style scoped>
.mt-30 {
  margin-top: 30px
}
</style>
