import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { http } from '../api'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'ru',
  fallbackLocale: 'ru'
})

const loadedLanguages = []

function setI18nLanguage (lang) {
  i18n.locale = lang
  http.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang)
    return Promise.resolve()
  }
  return http.get(`/language/${lang}`).then(response => {
    const msgs = response.data
    loadedLanguages.push(lang)
    i18n.setLocaleMessage(lang, msgs.data)
    setI18nLanguage(lang)
  }).catch(err => {
    console.error(err.message)
    return err
  })
}

export default i18n
