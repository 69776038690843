<template>
  <div>
    <div v-b-modal.modal-dictionary class="rounded-circle book-icon bg-white d-flex justify-content-center align-items-center">
      <BookIcon/>
    </div>

    <b-modal hide-footer id="modal-dictionary">
      <template #modal-title>
        <span class="text-red main-word">Словарь</span>
        <span class="text-red">Урок {{lesson_number}}: </span>
        <span class="font-weight-bold">{{lesson_name}}</span>
        <div class="font-description">Вы добавили {{dictionaries.length}} слов по этому уроку</div>
      </template>
      <template #modal-header-close>
        <div class="close-modal">
          <img src="../../assets/images/icons/close.svg" alt="Close">
        </div>
      </template>
      <DictionaryTable
        :columns="dictionaries"
        :fields="fields"
        :example="example"
        @onDestroy="destroy"
      />
    </b-modal>
  </div>
</template>

<script>
import BookIcon from './icons/BookIcon'
import { mapActions, mapGetters } from 'vuex'
import DictionaryTable from './DictionaryTable'
export default {
  name: 'DictionaryModal',
  components: { DictionaryTable, BookIcon },
  props: {
    lesson_name: {
      type: String,
      default: null
    },
    lesson_id: {
      type: Number,
      default: null
    },
    lesson_number: {
      type: Number,
      default: 0
    },
    language_name: {
      type: String,
      default: null
    }
  },
  data(){
    return {
      fields: [
        {label:'', action: true, actionType: 'speak', key: 'word', language: 'language.code'},
        {label: this.language_name,  key: 'word'},
        {label: 'Русский',  key: 'translate'},
        {label: '', action: true, actionType: 'delete',  key: 'id'},
      ],
      example: {key: 'example', type:'array', wordKey: 'text', translateKey: 'translate'}

    }
  },
  computed: {
    ...mapGetters({
      dictionaries: 'dictionary/dictionaries'
    }),
    langName(){
      if (!this.dictionaries.length){
        return null
      }
      return  this.dictionaries.filter((value, index) => {
            return index === 0
      })[0].language.name[this.$i18n.locale]
    }
  },
   created () {
   this.get({lesson_id: this.lesson_id})
  },
  methods: {
    ...mapActions({
      get: 'dictionary/get',
      delete: 'dictionary/delete'
    }),
    destroy(id){
      this.delete({setter:'deleteDictionary', id})
    }
  }
}
</script>

<style  lang="scss">

.book-icon {
  height: 60px;
  width: 60px;
  cursor: pointer;
}

#modal-dictionary___BV_modal_content_{
  border-radius: 44px;
  padding: 20px;

  .modal-header{
    padding: 0 0 20px 0;

    .modal-title{
      margin-left: 20px;

      .font-description{
        font-size: 10px;
        font-weight: bold;
      }
    }

    .close-modal {
      height: 35px;
      width: 35px;
      img {
        height: 35px;
        width: 35px;
      }
    }
  }


  .text-red{
    color: #CA3C44;
    font-weight: bold;
  }
  .main-word{
    border-right: 2px solid #CA3C44;
    padding-right: 15px;
    margin-right: 15px;
  }
}
</style>
