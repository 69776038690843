<template>
   <img :src="iconPath" alt="book" height="30px" width="30px" />
</template>

<script>
import SvgIcon from '../SvgIcon'
export default {
  name: 'BookIcon',
  computed:{
    iconPath() {
      let icon = require(`@/assets/images/icons/book.svg`);

      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default;
      }

      return icon
    }
  }
}
</script>

<style scoped>

</style>
