<template>
  <div>
    <div class="dashboard personal-details">
      <div class="tabs-title">
        Личный кабинет
      </div>
      <div class="tabs-info">
        Добро пожаловать в Ваш личный кабинет. Выберите язык который вы хотите изучать:
      </div>
      <div class="languages">
        <div class="languages-list">
          <VueSlickCarousel v-bind="settingsHeader" v-if="teachinglangs && teachinglangs.length">
            <div class="list-item" v-for="(language, index) in teachinglangs" :key="index">
              <button @click="$router.push('/profile/courses/main?lang=' + language.id)">
                <img :src="language.flag" alt="flag-de">
                <div class="text">{{ language.name[$i18n.locale] }}</div>
              </button>
            </div>
          </VueSlickCarousel>
        </div>
      </div>

      <!-- New course available  -->
      <div class="courses">
        <div class="d-flex align-items-center justify-content-between">
          <div class="text-part">
            <div class="sub-title">
              Бесплатные уроки
            </div>
            <div class="tabs-info">
              Для ознакомления с нашим продуктом предоставляем вам доступ к первым урокам каждого доступного курса
            </div>
          </div>
          <button @click="$router.push('/profile/lessons/all')" type="button" class="all-courses-btn">все бесплатные
            уроки
          </button>
        </div>
        <div class="language-courses">
          <div class="lang-courses-items" v-if="!loadingLesson">
            <VueSlickCarousel v-bind="carouselSettings" v-if="lessons && lessons.length">
              <div class="course-item-carousel" v-for="(lesson) in lessons" :key="lesson.id">
                <div class="course-item">
                  <div class="background">
                    <img v-if="lesson.image" :src="lesson.image"
                         alt="img">
                    <img v-else src="../../../../assets/images/defaul-image.png" alt="image">
                  </div>
                  <div class="content">
                    <div class="flag">
                      <img :src="lesson.flag_path" alt="Cn_flag">
                    </div>
                    <div class="course-name">{{ lesson.name[$i18n.locale] }}</div>
                    <div class="short-description">{{ lesson.course_name[$i18n.locale] }}</div>
                    <div class="action">
                      <button type="button" @click="$router.push('/profile/free-lesson/' + lesson.id)">ПРОЙТИ УРОК
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
          </div>
          <button @click="$router.push('/profile/lessons/all')" type="button" class="all-courses-btn mobile-visible">все
            бесплатные уроки
          </button>
        </div>
      </div>
      <div class="courses-language-to-go">
        <div class="d-flex align-items-center justify-content-between">
          <div class="text-part">
            <div class="sub-title">
              Курсы Language to go
            </div>
            <div class="tabs-info blue-color">
              Перед покупкой курса Вам нужно будет пройти тест на определение уровня знаний языка
            </div>
          </div>
          <button @click="$router.push('/profile/courses/main')" type="button" class="all-courses-btn blue-color">ВСЕ КУРСЫ</button>
        </div>
        <div class="content" v-if="!loadingCourse">
          <VueSlickCarousel v-bind="coursesCarouselSettings" v-if="allCoursesList && allCoursesList.length">
            <div class="course-item-language-carousel" v-for="(course, index) in allCoursesList" :key="index">
              <div class="course-item" :class="{'upcoming': course.in_developing === 1}">
                <div class="upcoming-cover" v-if="course.in_developing === 1">
                  <img src="../../../../assets/images/upcoming-icon.png" alt="upcoming">
                  <span class="upcoming-cover-title">В разработке</span>
                </div>
                <div class="video">
                  <video width="320" height="240" controls v-if="course.announcement && course.announcement.video">
                    <source :src="course.announcement.video" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                  <div class="video" v-if="course.announcement && course.announcement.video_iframe">
                    <div v-if="course.announcement && course.announcement.video_iframe !== null"
                         v-html="course.announcement.video_iframe" class="video-iframe"></div>
                  </div>
                </div>
                <div class="text-content">
                  <div class="top-line">
                    <div class="flag">
                      <img :src="course.teaching_language.flag" alt="flag-de">
                    </div>
                    <div class="name">{{ course.name[$i18n.locale] }}</div>
                  </div>
                  <div class="description" v-html="course.description[$i18n.locale]"></div>
                  <router-link :to="'/profile/course/' + course.id" class="details">
                    {{ $t('buttons.more_about_course') }}
                  </router-link>
                  <button v-if="course.is_free_lesson && course.lesson_id" class="pass-course-btn"
                          @click="$router.push('/profile/free-lesson/' + course.lesson_id)" type="button">ПРОЙТИ
                    БЕСПЛАТНЫЙ УРОК
                  </button>
                </div>
                <div class="bottom-action" @click="openModalBuy(course)">
                  <a href="#">купить курс</a>
                </div>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
      <div class="news">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <div class="sub-title">
              Новости
            </div>
            <div class="tabs-info">
              Будьте в курсе наших событий. Следите за нашими новостями
            </div>
          </div>
          <button @click="$router.push('/profile/news/')" type="button" class="all-news-btn">
            {{ $t('buttons.all_news') }}
          </button>
        </div>
        <div class="video">
          <div class="video-block">
            <div class="video-block-videos d-block" v-if="!loadingNews">
              <VueSlickCarousel v-bind="newsCarouselSettings" v-if="allNews && allNews.length">
                <div class="news-item" v-for="(news, index) in allNews" :key="index">
                  <div class="news-container pp-news-container">
                    <div class="news-image">
                      <img :src="news.image_paths.full" :alt="news.image_alt" v-if="news && news.image_paths"
                           class="image-responsive">
                      <img src="../../../../assets/images/default.png" alt="No Image" v-else>
                    </div>
                    <div class="news-block">
                      <div class="title">{{ news.title[$i18n.locale] }}</div>
                      <div class="subtitle">{{ news.created_at  | moment('MM.DD.YYYY') }}</div>
                      <div class="text">{{ news.short_description[$i18n.locale] }}</div>
                      <router-link :to="'/profile/news/' + news.id" class="more">{{ $t('buttons.read_more') }} <span>&raquo;</span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </VueSlickCarousel>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="choose-course" ref="chooseCoursePlan">
      <template #modal-header="{ close }">
        <b-button @click="close()">
          X
        </b-button>
        <h3>Выберите способ опаты</h3>
      </template>
      <div class="content">
        <div class="form-group">
          <ValidationObserver tag="form" ref="buyCourseForm" @submit.prevent="submitBuyCourse">
            <div v-if="course.tariffs">
              <label>Пакет</label>
              <div class="b-dropdown">
                <b-dropdown class="select-dropdown" :text="tariffActive.name[$i18n.locale]">
                  <b-dropdown-item href="#" @click="selectTariff(tariff)" v-for="(tariff, index) in course.tariffs"
                                   :key="index">{{ tariff.name[$i18n.locale] }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <span class="text-danger" v-if="courseTariffError">{{ courseTariffError }}</span>
            </div>
            <div v-else>
              <label>Пакет</label>
              {{ courseTariffName }}
            </div>
            <div class="price-info">
              К оплате: <span>{{ tariffPrice }} руб</span>
            </div>
            <ValidationProvider v-slot="{ errors }" rules="required"
                                v-if="paymentMethodList && paymentMethodList.length">
              <div class="radio-container">
                <label>Способ оплаты:</label>
                <div class="form-group checkbox-group">
                  <label v-for="(paymentMethod, index ) in paymentMethodList" :key="index" class="checkbox-container">
                    <input v-model="formData.payment_method_id" :value="paymentMethod.id" type="radio"
                           name="Способ оплаты"/>
                    <span class="checkbox"/>
                    <span>{{ paymentMethod.name[$i18n.locale] }}</span>
                  </label>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            <div class="actions">
              <button type="submit" :disabled="btnDisableBuyCourseForm">ПЕРЕЙТИ К ОПЛАТЕ</button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    VueSlickCarousel
  },
  data () {
    return {
      loadingNews: true,
      loadingCourse: true,
      loadingLesson: true,
      formDataPage: {
        course_type: 'main',
        language: 'all',
        page: 1
      },
      tariffActive: {
        name: {}
      },
      course: {},
      courseTariff: true,
      courseTariffName: '',
      tariffPrice: '',
      btnDisableBuyCourseForm: false,
      courseTariffError: '',
      formData: {
        course_tariff_id: ''
      },
      carouselSettings: {
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1800,
            settings: {
              arrows: false,
              dots: true,
              infinite: false,
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1365,
            settings: {
              arrows: false,
              dots: true,
              infinite: false,
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 991,
            settings: {
              arrows: false,
              dots: true,
              infinite: false,
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 700,
            settings: {
              arrows: false,
              dots: true,
              infinite: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },
      settingsHeader: {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1460,
            settings: {
              arrows: false,
              dots: true,
              infinite: true,
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1200,
            settings: {
              arrows: false,
              dots: true,
              infinite: true,
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 520,
            settings: {
              arrows: false,
              dots: true,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },
      newsCarouselSettings: {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1440,
            settings: {
              arrows: false,
              dots: true,
              infinite: true,
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1200,
            settings: {
              arrows: false,
              dots: true,
              infinite: true,
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 520,
            settings: {
              arrows: false,
              dots: true,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      },
      coursesCarouselSettings: {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1440,
            settings: {
              arrows: false,
              dots: true,
              infinite: true,
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1200,
            settings: {
              arrows: false,
              dots: true,
              infinite: true,
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 520,
            settings: {
              arrows: false,
              dots: true,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      teachinglangs: state => state.teachingLang.teachinglangs,
      allNews: state => state.news.allNews,
      lessons: state => state.lesson.availableLessons,
      allCoursesList: state => state.course.allCoursesList,
      paymentMethodList: state => state.paymentMethod.paymentMethodList,
      settings: state => state.setting.settings
    }),
  },
  async created () {
    this.loadingCourse = this.loadingNews = this.loadingLesson = true
    this.getLessons(this.formDataPage).then(res => {
    }).finally(() => {
      this.loadingLesson = false
    })
    await this.getAllCoursesList({
      count: 6,
      course_type: 'main'
    }).then(res => {
    }).finally(() => {
      this.loadingCourse = false
    })

    if (this.settings) {
      await this.getSettings()
    }

    if (this.settings && this.settings.news_slider_count) {
      await this.getNews({ count: this.settings.news_slider_count })
      this.loadingNews = false
    }
  },
  methods: {
    ...mapActions({
      getAllCoursesList: 'course/getAllCoursesList',
      getNews: 'news/getNews',
      getLessons: 'lesson/getAvailableLessons',
      buyCourse: 'course/buyCourse',
      getBoughtCoursesList: 'course/getBoughtCoursesList',
      getSettings: 'setting/getSettings'
    }),
    selectTariff (tariff) {
      this.courseTariffError = ''
      this.tariffActive = tariff
      this.tariffPrice = tariff.price
      this.formData.course_tariff_id = tariff.id
    },
    openModalBuy (course) {
      this.tariffActive = {
        name: {}
      }
      this.tariffPrice = ''
      this.formData = {
        course_tariff_id: '',
        payment_method_id: ''
      }
      this.course = course
      this.$refs.chooseCoursePlan.show()
    },
    submitBuyCourse () {
      this.$refs.buyCourseForm.validate().then(success => {
        if (success) {
          this.courseTariffError = ''
          if (!this.formData.course_tariff_id) {
            this.courseTariffError = 'Выберите тариф'
            return false
          }
          this.btnDisableBuyCourseForm = true
          this.formData.course_id = this.course.id
          this.buyCourse(this.formData).then(res => {
            this.$refs.chooseCoursePlan.hide()
            this.getBoughtCoursesList().then(res => {
            })
          }).catch(() => {
            this.$toasted.error('Something went wrong!').goAway(1500)
          }).finally(() => {
            this.btnDisableBuyCourseForm = false
            this.formData = {}
          })
        }
      })
    }
  },
}
</script>
