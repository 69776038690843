import { http } from '../../../api'

const paymentMethod = {
  namespaced: true,
  state: {
    paymentMethodList: []
  },
  actions: {
    getPaymentMethodList: ({ commit }) => {
      return http.get('/payment-method').then(res => {
        commit('setPaymentMethodList', res.data)
      })
    }
  },
  mutations: {
    setPaymentMethodList (state, data) {
      state.paymentMethodList = data.data
    }
  },
  getters: {
    paymentMethodList: state => state.paymentMethodList
  }
}

export default paymentMethod
