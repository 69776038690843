<template>
  <TaskBase :task="task"
            :check-task="() => checkAnswer(stateAnswers[activeIndex] && stateAnswers[activeIndex].answer_id)"
            :correct-answer="correctAnswer">
    <template #desktop>
      <div class="row fortune-wrappper" v-if="prizes.length > 0">
        <div class="lg-lg-6 lg-md-12 lg-12 fortune-wheel-container">
          <FortuneWrapper class="fortune-wrapper position-wheel">
            <FortuneWheel
              class="fortune-wheel"
              :rotate="rotate"
              :prizes="prizes"
              :useWeight="true"
              :re-draw-canvas="reDrawCanvas"
              @rotateEnd="rotateEnd"
            />
          </FortuneWrapper>
        </div>
        <div class="lg-xl-3 lg-lg-5 lg-md-12 lg-12 position-wheel">
          <div class="only-mob fortune-question" v-if="activeIndex !== null">
              {{prizes[activeIndex].name}}
          </div>
          <button class="btn-rotate mb-4" type="button" @click="rotateClick">
            КРУТИТЬ КОЛЕСО
          </button>

          <div
            class="wf-answer"
            v-for="answer in answers"
            :class="{
              'wf-success':  stateAnswers[activeIndex].success  && stateAnswers[activeIndex].answer_id === answer.id,
              'error':  stateAnswers[activeIndex].error  && stateAnswers[activeIndex].answer_id === answer.id,
              }"
            :key="answer.id"
            v-if="!rotating"
            @click="checkAnswer(answer.id)"
          >
            {{ answer.answer[$i18n.locale] }}
          </div>

        </div>
      </div>
    </template>
  </TaskBase>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import TaskAnswerModal from '../../../../components/TaskAnswerModal'
import VideoAudioGallery from '../../../../components/VideoAudioGallery'
import TaskBase from '../../../../components/TaskBase'
import TaskCheckParamsMixin from '../../../../../mixins/TaskCheckParamsMixin'
import FortuneWheel from '../../../../components/Tasks/fortuneWheel'
import FortuneWrapper from '../../../../components/Tasks/Fortune/FortuneWrapper'

export default {
  name: 'WheelFortune',
  mixins: [TaskCheckParamsMixin],
  data () {
    return {
      rotate: false,
      refreshTask: '',
      prizes: [],
      answers: [],
      rotating: false,
      checkedAnswer: null,
      disabledButton: false,
      activeQuestion: null,
      answeredQuestions: [],
      reDrawCanvas: false,
      stateAnswers: [],
      activeIndex: null
    }
  },
  components: {
    FortuneWrapper,
    TaskBase,
    VideoAudioGallery,
    TaskAnswerModal,
    FortuneWheel
  },
  beforeRouteUpdate (to, from, next) {
    if (to.fullPath !== from.fullPath) {
      Object.assign(this.$data, this.$options.data())
      this.taskInit(to)
    }
    next()
  },
  created () {
    this.taskInit(this.$route)
  },
  computed: {
    ...mapState({
      task: state => state.task.task,
      nextTask: state => state.course.nextTask
    }),
    isFinish () {
      return this.prizes.filter(prize => prize.weight !== 0).length <= 1
    }
  },
  methods: {
    ...mapActions({
      getTask: 'task/getTask',
      missingTask: 'course/missingTask',
      checkTestTask: 'task/checkTestTask',
      getMissingTask: 'course/getMissingTask',
      setTaskAnswerModal: 'task/setTaskAnswerModal'
    }),
    correctAnswer () {
      let answer = this.stateAnswers[this.activeIndex]
      if (answer.try < 3) {
        return
      }
      const correctAnswer = this.task.task[this.activeIndex].answers.find(item => item.correct_answer === true)
      this.$set(this.stateAnswers, this.activeIndex, {
        ...answer,
        success: true,
        error: false,
        answer_id: correctAnswer.id
      })
      this.clickedPrompt = true
    },
    taskInit (route) {
      const query = {}
      if (route.query.type) {
        query.type = route.query.type
      }
      this.getTask({
        id: route.params.taskId,
        query
      }).then(() => {
        const probability = 100 / this.task.task.length
        this.task.task.forEach((question, index) => {

          this.stateAnswers.push({
            question_id: question.id,
            answer_id: null,
            success: false,
            error: false,
            try: 1
          })

          this.prizes.push({
            id: question.id,
            name: question.question[this.$i18n.locale],
            color: '#ffffff',
            probability,
            weight: 1,
            answers: question.answers,
            index
          })
        })
      })
    },
    rotateEnd (prize) {
      this.rotating = false
      this.answers = prize.answers
      this.activeQuestion = prize.id
      this.activeIndex = prize.index
    },
    missTask () {
      this.missingTask({ task_id: this.$route.params.taskId })
    },
    checkAnswer (answer_id = null) {
      if (this.disabledButton || !answer_id) return

      this.$set(this.stateAnswers[this.activeIndex], 'answer_id', answer_id)
      this.disabledButton = true
      this.checkQuestion()
    },
    changeSuccessAndError (state, tryNumber = null) {
      if (state) {
        this.$set(this.stateAnswers[this.activeIndex], 'success', true)
        this.$set(this.stateAnswers[this.activeIndex], 'error', false)
      } else {
        this.$set(this.stateAnswers[this.activeIndex], 'error', true)
      }

      if (tryNumber) {
        this.$set(this.stateAnswers[this.activeIndex], 'try', tryNumber)
      }
    },

    checkQuestion (finish = this.isFinish) {
      let params = {
        answer_id: this.stateAnswers[this.activeIndex].answer_id,
        question_id: this.activeQuestion,
        type_model: 'TaskWheelFortuneQuestion',
        uuid: this.task.uuid,
        ...this.checkParams(),
      }

      if (finish) {
        params = {
          ...params,
          answers: this.stateAnswers
        }
      }

      this.checkTestTask(params).then(res => {
        if (res.data.answer) {
          if (finish) {
            this.setTaskAnswerModal(res.data).then(() => {
              this.$root.$emit('bv::show::modal', 'modal-task')
            })
          } else {
            this.$toasted.success('Ответ был выбран правильно').goAway(1500)
          }
          this.$set(this.prizes[this.activeIndex], 'weight', 0)
          this.answeredQuestions.push({
            question_id: this.activeQuestion,
            answer_id: this.stateAnswers[this.activeIndex].answer_id
          })
          this.changeSuccessAndError(true)
          this.reDrawCanvas = true
          setTimeout(() => {
            this.reDrawCanvas = false
          }, 1000)
        } else {
          this.disabledButton = false
          this.changeSuccessAndError(false, res.data.try)
          this.setTaskAnswerModal(false).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        }
      }).finally(() => {

      })
    },
    rotateClick () {
      this.rotating = this.rotate = true
      this.disabledButton = false
      setTimeout(() => {
        this.rotate = false
      }, 1000)
    },
  }
}
</script>

<style lang="scss">
.fortune-wrappper {
  margin: 0 !important;
}
.btn-rotate {
  border-radius: 25px;
  border: 2px solid #cb3c44;
  font-size: 12px;
  font-weight: bold;
  color: #CE4850;
  width: 280px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  &:hover {
    border-color: #D14F58;
  }

  @media (min-width: 768px) {
    width: 320px;
  }
}

.fortune-question{
  min-height: 50px;
  width: 280px;
  background: #FFFFFF;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
  padding: 10px;
}
.position-wheel{
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1440px) {
    align-items: start;
  }
}

.wf-answer {
  width: 280px;
  min-height: 60px;
  background-color: #e6eafc;
  padding: 20px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  line-height: 19px;

  @media (min-width: 768px) {
    width: 320px;

  }
}

.fortune-wheel-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 22px;

  @media (min-width: 768px) {
    margin-bottom: 35px;
  }

  @media (min-width: 1440px) {
    align-items: start;
    margin-bottom: 0;
  }
}

.fortune-wrapper {

  @media (min-width: 768px) {
    width: 558.33px;
    height: 558.33px;
  }

  .fortune-wheel {

    @media (min-width: 768px) {
      width: 465.55px;
      height: 465.55px;
    }
  }
}

.wf-success, .error, .checked {
  color: white;
}

.wf-success{
  background-color: #219653;
}

.checked {
  background-color: #CD3B45;
}


.error {
  background-color: #CD3B45;
}

</style>
