<template>
  <div class="course col-12">
    <BuyFloatButton :course="course" />

    <bread-crumbs :pages="pages" />
    <div class="course-header justify-content-center position-relative" v-if="course" ref="bg">
      <Loading v-if="!imageLoaded" />
      <div v-else class="course-header-content page-container">
        <div class="course-header-content-language">
          <div
            class="course-header-content-language-flag"
            v-if="course && course.teaching_language"
          >
            <img :src="course.teaching_language.flag" alt="flag" />
          </div>
        </div>
        <div class="course-header-content-info-top" v-if="course.name">
          {{ course.name[$i18n.locale] }}
        </div>

        <p class="course-header-content-info-bottom">
          Посмотрите наш промо ролик чтобы познакомиться с нами поближе!
        </p>

        <div class="course-header-content-buttons">
          <button class="btn-promo-video" @click="openVideoModal()">
            <img src="@/assets/images/icons/play-ce3d.svg" alt="logo" />
            Смотреть анонс курса {{ course.name && course.name[$i18n.locale] }}
          </button>
        </div>
      </div>
    </div>
    <div class="course-block-container">
      <div class="course-block-container-description-block">
        <div
          class="course-block-container-description-block-description"
          v-if="course.description"
          v-html="course.description[$i18n.locale]"
        ></div>
        <button
          v-if="course.is_free_lesson && !course.bought"
          @click="getFreeLesson"
          type="button"
          class="course-button cp-course-button btn-red-gradient-outline"
        >
          {{ $t('buttons.get_free_lesson') }}
        </button>
      </div>
      <div
        class="course-block-container-category"
        v-if="
          course.beginner_description || course.advanced_description || course.average_description
        "
      >
        <div class="course-title">Для кого этот курс?</div>
        <div class="course-subtitle">
          Мы разделили курсы на уровни, чтобы любой мог начать с того уровня знаний который ему
          подходит
        </div>
        <div class="course-block-container-category-cards cards-flex-column ">
          <div
            class="course-block-container-category-cards-card"
            v-if="course.beginner_description"
          >
            <img
              class="course-block-container-category-cards-card-image"
              src="./../../../assets/images/icons/junior.png"
              alt=""
            />
            <div class="course-block-container-category-cards-card-title">Начинающий</div>
            <div class="course-block-container-category-cards-card-subtitle">
              Для тех кто только хочет начать обучение языка
            </div>
            <div class="course-block-container-category-cards-card-text">
              {{ course.beginner_description }}
            </div>
          </div>
          <div class="course-block-container-category-cards-card" v-if="course.average_description">
            <img
              class="course-block-container-category-cards-card-image"
              src="./../../../assets/images/icons/middle.png"
              alt=""
            />
            <div class="course-block-container-category-cards-card-title">Средний</div>
            <div class="course-block-container-category-cards-card-subtitle">
              Для тех кто хочет продолжить обучение языка
            </div>
            <div class="course-block-container-category-cards-card-text">
              {{ course.average_description }}
            </div>
          </div>
          <div
            class="course-block-container-category-cards-card"
            v-if="course.advanced_description"
          >
            <img
              class="course-block-container-category-cards-card-image"
              src="./../../../assets/images/icons/profi.png"
              alt=""
            />
            <div class="course-block-container-category-cards-card-title">Продвинутый</div>
            <div class="course-block-container-category-cards-card-subtitle">
              Для тех кто хочет усовершенствовать свои знания
            </div>
            <div class="course-block-container-category-cards-card-text">
              {{ course.advanced_description }}
            </div>
          </div>
        </div>
      </div>
      <div class="course-block-container-additional" v-if="additionalCourses.length">
        <div class="course-title">Дополнительные курсы</div>
        <div class="course-subtitle">По {{ course.teaching_language.name[$i18n.locale] }}</div>
        <div class="course-block-container-additional-courses">
          <div
            class="course-block-container-additional-courses-course w-50"
            v-for="(addCourse, index) in additionalCourses"
            :key="addCourse.id"
            :style="`background-image: url(${addCourse.image_path})`"
            :class="{ 'course-block-container-additional-courses-course-two': index === 1 }"
          >
            <div class="course-block-container-additional-courses-course-opacity">
              <div class="course-block-container-additional-courses-course-title">
                <div class="course-block-container-additional-courses-course-title-flag">
                  <img :src="course.teaching_language.flag" :alt="course.teaching_language.code" />
                </div>
                <div class="course-block-container-additional-courses-course-title-text">
                  {{ addCourse.name[$i18n.locale] }}
                </div>
              </div>
              <div class="course-block-container-additional-courses-course-content">
                <div class="text" v-html="addCourse.description[$i18n.locale]"></div>
                <a
                  href="#"
                  class="course-block-container-additional-courses-course-content-link content-link-max"
                >
                  Узнать цену и подробную информацию <span class="quotes">&#8250;&#8250;</span>
                </a>
                <a
                  href="#"
                  class="course-block-container-additional-courses-course-content-link content-link-min"
                >
                  подробнее <span class="quotes">&#8250;&#8250;</span>
                </a>
                <button class="course-block-container-additional-courses-course-content-button">
                  <img src="@/assets/images/icons/play-ce3d.svg" alt="logo" />
                  Смотреть анонс курса
                </button>
              </div>
            </div>
          </div>
        </div>
        <a href="#" class="course-block-container-additional-more-courses"
          >доступно еще {{ coursesCount }} дополнительных курса. развернуть
          <span class="quotes">&#8250;&#8250;</span></a
        >
      </div>
      <div class="how-work-container">
        <div class="how-work-container-title">Как проходит обучение?</div>
        <div class="how-work-container-subtitle">
          Несколько простых шагов для изучения иностранного языка
        </div>
        <div class="how-work-container-group">
          <div class=" how-work-container-group-card">
            <div class="how-work-container-group-card-content">
              <div class="how-work-container-group-card-content-image">
                <img src="../../../assets/images/lessons.png" alt="registration" />
              </div>
              <div class="how-work-container-group-card-content-info-block">
                <div class="how-work-container-group-card-content-info-block-title">Уроки</div>
                <div class="how-work-container-group-card-content-info-block-text">
                  Ежедневно проходите обучение по видео урокам от наших экспертов
                </div>
              </div>
              <div class="how-work-container-group-card-content-number">1</div>
            </div>
          </div>
          <div class=" how-work-container-group-card">
            <div class="how-work-container-group-card-content">
              <div class="how-work-container-group-card-content-image">
                <img src="../../../assets/images/dictionaries.png" alt="free-lessons" />
              </div>
              <div class="how-work-container-group-card-content-info-block">
                <div class="how-work-container-group-card-content-info-block-title">Словари</div>
                <div class="how-work-container-group-card-content-info-block-text">
                  Изучайте новые слова и словосочетания. Формируйте свой словарь самостоятельно.
                </div>
              </div>
              <div class="how-work-container-group-card-content-number ccn-line-to-bottom">3</div>
            </div>
          </div>
          <div class=" how-work-container-group-card">
            <div class="how-work-container-group-card-content">
              <div class="how-work-container-group-card-content-image">
                <img src="../../../assets/images/independent_work.png" alt="training" />
              </div>
              <div class="how-work-container-group-card-content-info-block">
                <div class="how-work-container-group-card-content-info-block-title">
                  Самостоятельная работа
                </div>
                <div class="how-work-container-group-card-content-info-block-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div>
              <div class="how-work-container-group-card-content-number ccn-line-to-bottom">5</div>
            </div>
          </div>
          <div class=" how-work-container-group-card">
            <div class="how-work-container-group-card-content">
              <div class="how-work-container-group-card-content-image">
                <img src="../../../assets/images/tasks.png" alt="language-selection" />
              </div>
              <div class="how-work-container-group-card-content-info-block">
                <div class="how-work-container-group-card-content-info-block-title">Задания</div>
                <div class="how-work-container-group-card-content-info-block-text">
                  Выполняйте задания, мы позаботились о том чтоб они были интересными
                </div>
              </div>
              <div class="how-work-container-group-card-content-number ccn-line-to-top">2</div>
            </div>
          </div>
          <div class=" how-work-container-group-card">
            <div class="how-work-container-group-card-content">
              <div class="how-work-container-group-card-content-image">
                <img src="../../../assets/images/grammar.png" alt="payment" />
              </div>
              <div class="how-work-container-group-card-content-info-block">
                <div class="how-work-container-group-card-content-info-block-title">Грамматика</div>
                <div class="how-work-container-group-card-content-info-block-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div>
              <div class="how-work-container-group-card-content-number ccn-line-to-top">4</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <ContactUs v-if="!isAuthenticated" /> -->
    <TariffPlan :course="course" @buyCourse="showBuyingCourseModal">
      <ReviewsCarousel />
      <!-- <template #footer>
        <div v-if="allInterestings.length" class="course-block-container-course-interesting">
          <div class="course-interesting-carousel-container">
            <div class="course-interesting-carousel-container-top-block">
              <div>
                <div class="course-title">Интересное о Германии</div>
                <div
                  class="course-subtitle course-block-container-knowledge-level-content-subtitle"
                >
                  Факты о странах и полезные материалы
                </div>
              </div>
              <div class="course-interesting-carousel-container-button-top">
                <router-link
                  tag="button"
                  to="/interesting/all"
                  type="button"
                  class="btn-red-gradient-outline"
                  >Все статьи
                </router-link>
              </div>
            </div>
            <div class="course-interesting-carousel-container-carousel">
              <VueSlickCarousel v-bind="carouselSettings">
                <div
                  class="interesting-carousel"
                  v-for="(interesting, index) in allInterestings"
                  :key="index"
                >
                  <div class="interesting-carousel-top">
                    <div class="interesting-icon" v-if="interesting.image">
                      <img :src="interesting.image" alt="image" />
                    </div>
                    <div class="interesting-carousel-top-title-block">
                      <div class="interesting-carousel-top-title-block-title">
                        {{ interesting.title[$i18n.locale] }}
                      </div>
                      <div class="interesting-carousel-top-title-block-subtitle">
                        {{ interesting.category_interesting.title[$i18n.locale] }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="interesting-carousel-text"
                    v-html="interesting.description[$i18n.locale]"
                  ></div>
                  <div class="interesting-carousel-more-link">
                    <router-link :to="'/interesting-detail/' + interesting.id"
                      >{{ $t('buttons.read_more') }}
                      <span class="quotes">&#8250;&#8250;</span></router-link
                    >
                  </div>
                </div>
              </VueSlickCarousel>
            </div>
            <div class="course-interesting-carousel-container-button-bottom">
               <router-link
                tag="button"
                to="/interesting/all"
                type="button"
                class="btn-red-gradient-outline"
                >Все статьи
              </router-link>
            </div>
          </div>
        </div>
      </template> -->
    </TariffPlan>

    <ModalAnons
      :courseAnnouncement="courseAnnouncement"
      :show="showModal"
      @close="showModal = false"
    />
    <ModalBuyingCourse ref="chooseCoursePlan" :course="course" :tariff="tariffActive" />
  </div>
</template>

<script>
import BreadCrumbs from '../../components/BreadCrumbs'
import ReviewsCarousel from '../../components/ReviewsCarousel'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapActions, mapState, mapGetters } from 'vuex'
import ModalBuyingCourse from '../../components/ModalBuyingCourse'
import BuyingCourseMixin from '../../../mixins/BuyingCourseMixin'
import TariffPlan from '../../components/Courses/Tariffs/TariffPlan'
import Loading from '../../components/Loading'
import ModalAnons from '../../components/ModalAnons'
import BuyFloatButton from './BuyFloatButton'

export default {
  name: 'CourseDetail',
  mixins: [BuyingCourseMixin],
  components: {
    Loading,
    TariffPlan,
    ModalBuyingCourse,
    BreadCrumbs,
    ReviewsCarousel,
    VueSlickCarousel,
    ModalAnons,
    BuyFloatButton
  },
  data() {
    return {
      imageLoaded: false,
      courseTariff: true,
      courseTariffName: '',
      btnDisableBuyCourseForm: false,
      formData: {
        course_tariff_id: ''
      },
      courseTariffError: '',
      pages: [
        {
          path: '/',
          rout: 'Главная',
          active: false
        },
        {
          path: '/courses/all',
          rout: 'Курсы',
          active: false
        },
        {
          path: '',
          rout: '',
          active: true
        }
      ],
      courseAnnouncement: {},
      showModal: false,
      carouselSettings: {
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              arrows: false,
              dots: true,
              infinite: false,
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 600,
            settings: {
              arrows: false,
              dots: true,
              infinite: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      course: state => state.course.course,
      settings: state => state.setting.settings,
      paymentMethodList: state => state.paymentMethod.paymentMethodList,
      allInterestings: state => state.interesting.allInterestings
    }),
    ...mapGetters({
      additionalCourses: 'course/additionalCourses',
      isAuthenticated: 'auth/isAuthenticated',
      coursesCount: 'course/additionalCoursesCount'
    }),
    is_mobile() {
      const isMobile = window.matchMedia('only screen and (max-width: 767px)')
      return !!isMobile.matches
    },
    slider_count() {
      return this.settings?.interesting_slider_count
    },
    canLoadInteresting() {
      return this.slider_count && this.course
    }
  },
  watch: {
    canLoadInteresting(newVal) {
      this.getInterestings({
        count: this.slider_count,
        lang_id: this.course.teaching_language?.id
      })
    },
    course(newVal) {
      if (!newVal.image_path) {
        this.imageLoaded = true
        return
      }

      this.load(newVal.image_path).then(() => {
        this.$refs.bg.style.backgroundImage = `url(${newVal.image_path})`
        this.imageLoaded = true
      })
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.taskId !== from.params.taskId) {
      this.courseInit(to.params.id)
    }
    next()
  },
  created() {
    this.courseInit(this.$route.params.id)
    document.addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    document.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions({
      buyCourse: 'course/buyCourse',
      getCourse: 'course/getCourse',
      getInterestings: 'interesting/getInterestings',
      getBoughtCoursesList: 'course/getBoughtCoursesList',
      setLoginModal: 'auth/setLoginModal',
      getAdditionalCourses: 'course/getAdditionalCourses',
      setCourseFreeLesson: 'course/setCourseFreeLesson'
    }),
    load(src) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', resolve)
        image.addEventListener('error', reject)
        image.src = src
      })
    },
    async getFreeLesson() {
      if (!this.isAuthenticated) {
        this.setLoginModal(false).then(() => {
          this.$root.$emit('bv::show::modal', 'login-modal')
        })
        return
      }
      try {
        // const response = await this.setCourseFreeLesson({ id: this.course.id })
        await this.$router.push({
          name: 'ProfileCourseLessons',
          params: { id: this.course.id }
        })
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$toasted.error(e.response.data.message).goAway(1500)
        } else {
          this.$toasted.error('Что-то пошло не так, повторите позже').goAway(1500)
        }
      }
    },
    courseInit(id) {
      this.getCourse({ id }).then(res => {
        this.pages[2].rout = this.course.name[this.$i18n.locale]
        this.getAdditionalCourses({ teaching_language_id: this.course.teaching_language.id })
      })
    },
    openVideoModal() {
      this.showModal = true
      this.courseAnnouncement = this.course.announcement
    }
  }
}
</script>
