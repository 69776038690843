<template>
  <StatisticsBase
    v-if="statisticsLessons.length"
    :language="statisticsLessons[0].course_module.course.teaching_language"
    :sub-title="subTitle"
  >
    <div class="statistics-lesson">
      <div class="item" v-for="(lesson, i) in statisticsLessons" :key="i">
        <div class="left-content">
          <div class="title">
            <div v-if="$route.query.lesson_id" key="queryLesson">Урок {{$route.query.lesson}}:</div>
            <div v-else  key="notQueryLesson">Урок {{ i + 1 }}:</div>
            <p>{{ lesson.name[$i18n.locale] }}</p>
          </div>
          <div class="info">
            <div>
              <p>Общие баллы за урок:</p>
              <span>{{ lesson.user_lesson.point }}</span>
            </div>
            <div>
              <p>Максимальное количество балов за урок:</p>
              <span>{{ lesson.max_point }}</span>
            </div>
          </div>
          <button type="button" class="more-info" @click="$router.push(`/profile/statistics-task?lesson_id=${lesson.id}&lesson=${i + 1}`)">
            подробная статистика заданий
            <img src="../../../../assets/images/icons/arrow-right.svg" alt="">
          </button>
        </div>
        <div class="right-content">
          <div class="progress-percent">
            <div class="progress-line">
              <div class="progress-active"
                   :style="'width:' + (Math.floor((lesson.tasks_done * 100) / lesson.max_point)) + '%'"></div>
            </div>
            <div class="percent">{{ Math.floor((lesson.tasks_done * 100) / lesson.max_point) }}%</div>
          </div>
          <div class="balls-info">
            <div class="row no-gutters mb-3" v-for="(block, index) in lesson.lesson_blocks" :key="index">
              <div class="col-md-8 d-flex justify-content-between align-items-center pr-4">
                <div class="font-weight-bold fs-18">{{ block.name[$i18n.locale] }}, баллы:</div>
                <div class="balls">{{ block.point }}</div>
              </div>
              <div class="col-md-4">
                <div class="d-flex">
                  <LessonTaskButton
                    :showed="block.show === 0 || block.user_showed"
                    icon="camera.png"
                    height-icon="auto"
                    width-icon="auto"
                  />
                  <LessonTaskButton
                    :showed="block.tasks.findIndex(task => task.user_task.status !== 'done') === -1"
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </StatisticsBase>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LessonTaskButton from '../../../components/LessonTaskButton'
import StatisticsBase from '../../../components/StatisticsBase'

export default {
  name: 'StatisticsLesson',
  components: {
    StatisticsBase,
    LessonTaskButton
  },
  data () {
    return {
      subTitle: ''
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.getStats()
    next()
  },
  created () {
    this.getStats()
  },
  computed: {
    ...mapState({
      statisticsLessons: state => state.statistics.statisticsLessons
    })
  },
  methods: {
    ...mapActions({
      getStatisticsLessons: 'statistics/getStatisticsLessons'
    }),
    getStats(){
      let params = {}

      if (this.$route.query.module_id) {
        this.subTitle = `Подробная статистика уроков, Модуля ${this.$route.query.module}`
        params = {
          module_id: this.$route.query.module_id
        }
      } else if (this.$route.query.lesson_id) {
        this.subTitle = `Подробная статистика урока ${this.$route.query.lesson}`
        params = {
          lesson_id: this.$route.query.lesson_id
        }
      } else {
        params = {
          course_id: this.$route.query.course_id
        }
      }

      this.getStatisticsLessons(params).then(res => {
        if (this.$route.query.course_id) {
          this.subTitle = `Подробная статистика уроков, Курса "${this.statisticsLessons[0].course_module.course.name[this.$i18n.locale]}"`
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-line {
  min-width: 450px !important;
  flex-basis: 90%;
}

.percent {
  min-width: 47px !important;
}

.balls {
  color: #CF464E;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
}

.fs-18 {
  font-size: 18px;
}
</style>
