export default {
  methods: {
    checkParams(){
      return  {
        id: this.task.task.id,
        task_id: this.$route.params.taskId,
        lesson_id: this.$route.query.lesson,
        lesson_next_id: this.$route.query.next_lesson,
        module: this.$route.query.module,
        course_id: this.$route.query.course,
        next_module: this.$route.query.next_module,
        next_module_lesson: this.$route.query.next_module_lesson,
        type: this.$route.query.type,
        next_task: this.task.next_task.next_task_id,
        is_free: this.$route.query.is_free
      }
    }
  }
}
