<template>
  <div class="d-flex justify-content-center">
    <div class="video-wrapper">
      <video
        v-if="video.video_path"
        width="100%"
        :src="video.video_path"
        controls
      >
      </video>
      <div class="iframe" v-else-if="video.video_iframe" v-html="video.video_iframe"></div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'VideoPlayer',
  props: {
    video: {
      type: Object,
      default () { return {} }
    }
  }
}
</script>

<style lang="scss" scoped>
.video-wrapper, .iframe{  
  width: 100%;
  max-width: 800px;
  max-height: 800px;
  display: flex;
  justify-content: center;   
}
</style>
