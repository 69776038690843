<template>
  <div>
    <div class="news-container">
      <router-link
        tag="div"
        :to="'/interesting-detail/' + interesting.id"
        class="news-image cursor-pointer"
        v-if="interesting && interesting.gallery && interesting.gallery.length && interesting.gallery[0] && interesting.gallery[0].medium"
        :style="`background-image: url(${interesting.gallery[0].medium.path});`"
      >
      </router-link>
      <div class="news-block">
        <div class="d-flex">
          <div class="interesting-icon">
            <div v-if="interesting.image">
              <img :src="interesting.image" alt="icon">
            </div>
          </div>
          <div class="dots">
            <router-link tag="div" :to="'/interesting-detail/' + interesting.id"
                         class="title dots cursor-pointer interesting-title">{{ interesting.title[$i18n.locale] }}
            </router-link>
            <div class="subtitle purple">{{ interesting.category_interesting.title[$i18n.locale] }}</div>
          </div>
        </div>
        <div class="text">
          {{ interesting.short_description[$i18n.locale] }}
        </div>
        <router-link :to="'/interesting-detail/' + interesting.id" class="more purple">
          {{ $t('buttons.read_more') }} <span>&raquo;</span></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InterestingCard',
  props: {
    interesting: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
