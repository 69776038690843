<template>
  <div class="fortune-bg position-relative d-flex align-items-center justify-content-center"
       :style="`background-image: url(${require('@/assets/images/fortune/border.png')});`">
    <img class="wf-selected" src="@/assets/images/fortune/selected.png" alt="selected"/>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'FortuneWrapper',
  props: {
    width: {
      type: String,
      default: '560px'
    },
    height: {
      type: String,
      default: '560px'
    },
  }
}
</script>

<style lang="scss">
.fortune-bg {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  width: 275.47px;
  height: 275.47px;

  @media (min-width: 768px) {
    width: 548.97px;
    height: 548.97px;
  }

  @media (min-width: 1440px) {
    width: 413.21px;
    height: 413.21px;
  }

  @media (min-width: 1920px) {
    height: 548.97px;
    width: 548.97px;
  }

  .fw-container {
    width: 233.47px;


    @media (min-width: 768px) {
      width: 450px;
    }

    @media (min-width: 1440px) {
      width: 350.21px;
    }

    @media (min-width: 1920px) {
      width: 450px;
    }
  }

  .wf-selected {
    position: absolute;
    z-index: 1;
    left: 20px;
    height: 21px;
    width: 18px;

    @media (min-width: 768px) {
      left: 38px;
      height: 40px;
      width: 40px;
    }

    @media (min-width: 1440px) {
      left: 28px;
    }

    @media (min-width: 1920px) {
      left: 38px;
    }
  }
}
</style>
