<template>
  <TaskBase :check-task="missingCheck" :task="task" :correct-answer="correctAnswer">
    <template #desktop>
      <div class="missing-words">
        <template v-for="(word, index) in textArr">
          <Break v-if="breaks[index]" :breaks="breaks[index]"/>
          <div v-else-if="!breaks[index + 1]" :key="index" class="space"
               :class="{'ml-2':word === ' ', 'input-wrapper':word === '|'}">
            <div v-if="word === '|'">
              <input
                :ref="`input${index}`"
                :class="{'success':results[index] === true, 'error':results[index] === false}"
                type="text"
                v-model="endTextArr[index]" placeholder="Напечатайте здесь"
                @input="changeInput(index, $event)"
              />
              <div class="buffer">{{ words[index] }}</div>
            </div>
            <span v-else>{{ word }}</span>
          </div>
        </template>
      </div>
    </template>
  </TaskBase>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import TaskAnswerModal from '../../../../components/TaskAnswerModal'
import VideoAudioGallery from '../../../../components/VideoAudioGallery'
import Template from '../../TechnicalSupport/Template'
import Break from '../../../../components/Break'
import BreakMixin from '../../../../../mixins/BreakMixin'
import TaskBase from '../../../../components/TaskBase'
import TaskCheckParamsMixin from '../../../../../mixins/TaskCheckParamsMixin'

export default {
  name: 'MissingWords',
  mixins: [BreakMixin, TaskCheckParamsMixin],
  data () {
    return {
      textArr: [],
      endTextArr: [],
      missingArr: [],
      words: {},
      wordsIndex: [],
      results: {}
    }
  },
  components: {
    Template,
    VideoAudioGallery,
    TaskAnswerModal,
    Break,
    TaskBase
  },
  computed: {
    ...mapState({
      task: state => state.task.task,
      nextTask: state => state.course.nextTask
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.fullPath !== from.fullPath) {
      Object.assign(this.$data, this.$options.data())
      this.taskInit(to.params.taskId)
    }
    next()
  },
  created () {
    this.taskInit(this.$route.params.taskId)
  },
  methods: {
    ...mapActions({
      getTask: 'task/getTask',
      missingTask: 'course/missingTask',
      getMissingTask: 'course/getMissingTask',
      missingWordTask: 'task/missingWordTask',
      setTaskAnswerModal: 'task/setTaskAnswerModal'

    }),
    checkTask () {
      this.wordsIndex.forEach(wordIndex => {
        const words = this.words[wordIndex].split(';')
        if (words.includes(this.endTextArr[wordIndex])) {
          this.$set(this.results, wordIndex, true)
        } else {
          this.$set(this.results, wordIndex, false)
        }
        this.$set(this.endTextArr, wordIndex, this.endTextArr[wordIndex])
      })
    },
    correctAnswer () {
      this.wordsIndex.forEach((word, index) => {
        this.$set(this.results, word, true)
        this.$set(this.endTextArr, word, this.words[word].split(';')[0])
      })
    },
    calcMinWidth () {
      for (const key in this.words) {
        const input = this.$refs[`input${key}`][0]
        const div = input.nextElementSibling
        input.style.minWidth = input.style.width = div.clientWidth + 'px'
        div.textContent = ''
      }
    },
    taskInit (taskId) {
      const query = {}
      if (this.$route.query.type) {
        query.type = this.$route.query.type
      }
      this.getTask({
        id: taskId,
        query
      }).then(async res => {
        this.data = res.data
        await this.getMissingTask(taskId)
        this.textArr = this.task.task.missing_words_text.split('')
        const words = this.task.task.text.match(/[^[\]]+(?=])/g)
        let i = 0
        this.textArr.map((item, index) => {
          this.pushBreaks(item)
          if (item === '|') {
            this.results[index] = null
            this.endTextArr.push('')
            this.words[index] = words[i]
            this.wordsIndex.push(index)
            i++
          } else {
            this.endTextArr.push(item)
          }
        })
      })
    },
    changeWidth (event) {
      const input = event.target
      input.nextElementSibling.innerHTML = input.value
      input.style.width = input.nextElementSibling.clientWidth + 'px'
    },
    missTask () {
      this.missingTask({ task_id: this.$route.params.taskId })
    },
    changeInput (index, event) {
      if (!this.missingArr.includes(index)) {
        this.missingArr.push(index)
      }
      this.changeWidth(event)
    },
    missingCheck () {

      const words = []
      this.wordsIndex.forEach(word => {
        words.push(this.endTextArr[word])
      })

      this.missingWordTask({
        words,
        ...this.checkParams()
      }).then(res => {
        this.checkTask()
        if (res.data && res.data.answer) {
          this.setTaskAnswerModal(res.data).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        } else {
          this.setTaskAnswerModal(false).then(() => {
            this.$root.$emit('bv::show::modal', 'modal-task')
          })
        }
      }).catch(e => {
        this.setTaskAnswerModal(false).then(() => {
          this.$root.$emit('bv::show::modal', 'modal-task')
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.buffer {
  font-family: Arial, Verdana, sans-serif;
  font-size: 17px !important;
  letter-spacing: 0.05em;
  position: absolute;
  top: -1000px;
  left: -1000px;
  visibility: hidden;
  white-space: nowrap;
}

.input-wrapper {
  display: flex;
  align-items: end;
  padding-bottom: 4px;

  @media (max-width: 767px) {
    font-size: 16px;
    width: 100%;
    div{
      width: 100%;
    }
  }
}

.missing-words {
  display: flex;
  flex-wrap: wrap;
  font-size: 24px;
  font-weight: 600;
  line-height: 55px;

  @media (max-width: 991px) {
    font-size: 20px;
    line-height: 40px;
  }

  @media (max-width: 767px) {
    font-weight: normal;
    font-size: 14px;
    line-height: 42px;
    padding: 0;
    .text-block {
      min-width: 150px;
    }
  }

  input {
    border: none;
    min-width: 272px;
    border-bottom: 1px solid #333333;
    font-size: 18px;
    font-style: italic;
    line-height: 30px;

    @media (max-width: 991px) {
      min-width: 230px;
    }

    @media (max-width: 767px) {
      font-size: 16px;
      width: 100%;
    }
  }

  .success {
    color: #219653;
    border-bottom: 1px solid #219653;
  }

  .error {
    color: #CD3B45;
    border-bottom: 1px solid #CD3B45;
  }

  .space {
    margin-left: 1px;
  }
}

</style>
