import Swal from 'sweetalert2'

export default {
  methods: {
    ToastShow(text, icon, callback){
      Swal.fire({
        text,
        icon,
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
            callback()
        }
      })
    }
  }
}
