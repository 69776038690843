<template>
  <b-modal
    hide-footer
    header-class="dream-modal-header"
    ref="modal"
    content-class="dream-modal"
    dialog-class="dream-dialog"
    modal-class="dream-modal-class"
  >
    <template #modal-header>Поле чудес</template>
    <div class="dream-modal-text">
      Такое же “Поле Чудес”, как Леонидом Аркадьевичем, с такими же понятными правилами.
    </div>
    <div class="dream-modal-text mt-11">
      Необходимо угадать слово по буквам, вращая барабан. На барабане есть символы, которые обозначают дейсвие хода.
    </div>

    <div class="d-flex flex-column dream-modal-icons mt-11">
      <div class="dream-modal-icon">
        <div class="dream-modal-icon-wrapper">
          <img src="@/assets/images/tasks/spin_btn.svg" alt="Вращение барабана"/>
        </div>
        <div>
          Вращение барабана
        </div>
      </div>
      <div class="dream-modal-icon">
        <div class="dream-modal-icon-wrapper">
          <img src="@/assets/images/tasks/book.svg" alt="Открыть любую букву в ответе"/>
        </div>
        <div>
          Открыть любую букву в ответе
        </div>
      </div>
      <div class="dream-modal-icon">
        <div class="dream-modal-icon-wrapper">
          <img src="@/assets/images/tasks/t.svg" alt="Указать букву в слове"/>
        </div>
        <div>
          Указать букву в слове
        </div>
      </div>
      <div class="dream-modal-icon">
        <div class="dream-modal-icon-wrapper">
          <img src="@/assets/images/tasks/code.svg" alt="Скрыть неиспользуемую букву"/>
        </div>
        <div>
          Скрыть неиспользуемую букву
        </div>
      </div>
      <div class="dream-modal-icon">
        <div class="dream-modal-icon-wrapper">
          <img src="@/assets/images/tasks/2xcode.svg" alt="Скрыть 2 неиспользуемые буквы"/>
        </div>
        <div>
          Скрыть 2 неиспользуемые буквы
        </div>
      </div>
    </div>
    <div class="dream-modal-text mt-11">
      Назвать слово целиком нельзя, как и выиграть автомобиль, но Леонид Аркадьевич желает вам удачи.
    </div>
    <div class="mt-11 d-flex justify-content-center justify-content-md-end">
      <button @click="closeModal" class="dream-modal-btn">ПОНЯТНО</button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'PromptModal',
  methods: {
    closeModal () {
      this.$refs.modal.hide()
    }
  }
}
</script>

<style lang="scss">

.dream-modal-class{
  .modal-dialog{
    @media (max-width: 768px ) {
      width: 100% !important;
      max-width: 100%;
    }
  }
}

.dream-modal {
  padding: 30px 27px;
  background: #FFFFFF;
  border-radius: 30px !important;

  &-header {
    border-bottom: none !important;
    font-weight: 700;
    font-size: 29px;
    padding: 0px 0px 11px 0;
  }

  &-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #030303;
  }

  &-icons {
    gap: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #030303;
  }

  &-icon {
    display: flex;
    align-items: center;

    &-wrapper{
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: start;
    }
  }

  &-btn{
    width: 280px;
    height: 60px;
    background: #E7EAFD;
    border-radius: 30px;
    border: none;
  }

  .mt-11 {
    margin-top: 11px;
  }
  .mr-18{
    margin-right: 18px;
  }

  .mrd-3{
    margin-right: 3px;
  }
}

</style>
