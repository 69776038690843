import { http } from '../../../api'

const coupon = {
  namespaced: true,
  state: {
    coupon: null,
  },
  actions: {
    accept ({ commit }, params) {
      return http.post('/coupons', params).then(res => {
          commit('setCoupon', res.data.data)
      })
    },
  },
  mutations: {
    setCoupon (state, data) {
      state.coupon = data
    },
  },
  getters: {
    coupon: state => state.coupon,
  }
}

export default coupon
