<template>
  <div v-if="!tariffBlockVisible && !!course && course.price" class="float-buy">
    <div class="float-buy__content">
      <div class="float-buy__info">
        <div v-if="course.discount" class="float-buy__discount-info">
          <span class="float-buy__price text-28">{{ course.price }}</span>
          <span class="float-buy__discount text-16 text-bold">-{{ course.discount }}%</span>
          <img class="arrow" src="@/assets/images/icons/full-arrow-right.svg" alt="arrow" />
        </div>

        <div class="float-buy__price-discount text-28">
          <span class="text-bold">{{
            course.discount ? course.price_discount : course.price
          }}</span>
          <span class="float-buy__currency">₽</span>
        </div>
      </div>

      <button class="button float-buy__button text-18" type="button" @click="scrollToTariffs()">
        Купить
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuyFloatButton',
  props: {
    course: {
      type: Object | null,
      default: null
    }
  },
  data() {
    return {
      tariffBlockVisible: false
    }
  },
  created() {
    document.addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    document.removeEventListener('scroll')
  },
  methods: {
    handleScroll(e) {
      if (!this.course || !this.course?.price) {
        return
      }
      const target = document.querySelector('#course-buy')
      if (!target) {
        return
      }
      const targetPositionTop = window.scrollY + target.getBoundingClientRect().top
      const windowPositionBottom = window.scrollY + document.documentElement.clientHeight
      this.tariffBlockVisible = targetPositionTop < windowPositionBottom
    },
    scrollToTariffs() {
      document.getElementById('course-buy').scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables/media';
.float-buy {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0);

  z-index: 100;

  &__content {
    padding: 16px;
    border-radius: 24px;
    background: var(--secondery);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    @media (max-width: $media-s) {
      flex-direction: column;
      gap: 8px;
    }
  }

  &__info {
    display: flex;
  }

  &__discount-info {
    display: flex;
    align-items: center;
  }

  &__price {
    color: var(--white);
    opacity: 0.5;
    text-decoration: line-through;
    margin-right: 8px;
  }

  &__discount {
    color: var(--secondery);
    background: var(--white);
    border-radius: 16px;
    padding: 4px 8px;
    height: 32px;

    display: flex;
    align-items: center;
  }

  &__price-discount {
    color: var(--white);
  }

  &__currency {
    color: var(--white);
    opacity: 0.5;
    margin-left: 8px;
  }

  &__button {
    padding: 16px 32px;
    border-radius: 32px;
    background: var(--white);
    color: var(--black);
    width: 100%;
    max-width: 240px;
  }

  .arrow {
    margin: 0 16px;
  }
}
</style>
