import { http } from '../../../api'

const block = {
  namespaced: true,
  state: {
    block: null,
    userShowedLessonBlocks: [],
  },
  actions: {
    getBlock: ({commit}, {id, params}) => {
      return http.get(`/lesson-block/${id}`, {params}).then(res => {
        commit('setBlock', res.data)
        commit('nextTaskId/setNextTaskId', res.data.nextTaskId, { root: true })
      })
    },
    getUserShowedLessonBlocks: ({commit}, params)  => {
      return http.get('/getUserShowedLessonBlocks', {params}).then(res => {
        commit('setUserShowedLessonBlocks', res.data)
      })
    },
    showedLessonBlock: (context, {id, params}) => {
      return http.post(`/showedLessonBlock/${id}`, params)
    }
  },
  mutations: {
    setBlock (state, data) {
      state.block = data
    },
    setUserShowedLessonBlocks (state, data) {
      state.userShowedLessonBlocks = data
    }
  },
  getters: {
    block: state => state.block,
    userShowedLessonBlocks: state => state.userShowedLessonBlocks,
  }
}

export default block
