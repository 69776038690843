<template>
  <a @click.prevent="playPauseSound"
     class="btn-circle rounded-circle cursor-pointer"
     :style="`background-color:${color};
     height:${height};
     width:${width};
     `">
    <template v-if="!play">
      <slot name="play">
        <img src="@/assets/images/icons/volume-up.svg" alt="play">
      </slot>
    </template>
    <template v-else>
      <slot name="pause">
        <img src="@/assets/images/icons/voice-pause.svg" alt="pause">
      </slot>
    </template>

  </a>
</template>

<script>
export default {
  name: 'PlayerButton',
  props: {
    answer: {
      type: String,
      default: ''
    },
    lang: {
      type: String,
      default: 'ru'
    },
    color: {
      type: String,
      default: '#EB0C08'
    },
    height: {
      type: String,
      default: '50px'
    },
    width: {
      type: String,
      default: '50px'
    }
  },
  data () {
    return {
      speech: null,
      play: false
    }
  },
  methods: {
    playPauseSound () {
      if (!this.answer || !this.answer.length) {
        return false
      }

      if (window.speechSynthesis.speaking) {
        window.speechSynthesis.pause()
        this.play = false
        return false
      }

      if (window.speechSynthesis.speaking && !window.speechSynthesis.paused) {
        window.speechSynthesis.cancel()
      }

      this.play = true
      this.speech.text = this.answer
      this.speech.lang = this.lang
      window.speechSynthesis.speak(this.speech)
      this.speech.onend = () => {
        this.play = false
      }
    }
  },
  created () {
    this.speech = new SpeechSynthesisUtterance()
  }
}
</script>

<style scoped>
.btn-circle {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
