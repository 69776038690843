<template>
  <div class="personal-details news">
    <div class="tabs-title">
      Новости Language to go
    </div>
    <div class="tabs-info">
      Будьте в курсе наших событий. Следите за нашими новостями
    </div>
    <loading v-if="loading"/>
    <div v-else class="news-content">
      <div class="video">
        <div class="video-block pt-0">
          <div class="video-block-videos" v-if="allNews && allNews.length">
            <div class="news-item" v-for="(news, index) in allNews" :key="index">
              <div class="news-container">
                <router-link tag="div" :to="'/profile/news/' + news.id" class="news-image cursor-pointer">
                  <img :src="news.image_paths.full" :alt="news.image_alt" v-if="news && news.image_paths">
                  <img src="../../../../assets/images/default.png" alt="No Image" v-else>
                </router-link>
                <div class="news-block">
                  <router-link tag="div" :to="'/profile/news/' + news.id" class="title cursor-pointer blue-hover">{{ news.title[$i18n.locale] }}</router-link>
                  <div class="subtitle blue-color">{{ news.created_at  | moment("MM.DD.YYYY") }}</div>
                  <div class="text" v-html="news.short_description[$i18n.locale]" ></div>
                  <router-link :to="'/profile/news/' + news.id" class="more blue-hover">{{$t('buttons.read_more')}} <span>&raquo;</span></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3" v-if="pagination.count_pages > 1 && !loading">
      <b-pagination
        v-model="currentPage"
        :total-rows="pagination.count_pages"
        :per-page="1"
        first-number
        last-number
        prev-text="«"
        next-text="»"
        @input="clickPagination"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Loading from '../../../components/Loading'

export default {
  name: 'ProfileNews',
  components: { Loading },
  data () {
    return {
      currentPage: 1
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.query.page) {
      this.currentPage = to.query.page
      this.getNews({ page: to.query.page, count: 12 })
    } else {
      this.currentPage = 1
      this.getAllVideos({ page: 1, count: 12 })
    }
    next()
  },
  created () {
    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page
      this.getNews({ page: this.$route.query.page, count: 12 })
    } else {
      this.getNews({ page: this.currentPage, count: 12 })
    }
  },
  computed: {
    ...mapState({
      allNews: state => state.news.allNews,
      pagination: state => state.news.pagination,
      loading: state => state.loading.loading
    })
  },
  methods: {
    ...mapActions({
      getNews: 'news/getNews'
    }),
    clickPagination () {
      this.$router.push('/profile/news?page=' + this.currentPage)
      window.scrollTo(0, 0)
    }
  }
}
</script>
