<template>
  <div>
    <div class="personal-details statistics">
      <div>
        <div class="top-line">
          <div class="">
            <div class="title-block">
              <div class="d-flex align-items-center">
                <div class="flag">
                  <img v-if="showStat" :src="statisticsCourse.course.teaching_language.flag_path" alt="">
                </div>
                <div>
                  <div class="tabs-title">
                    Статистика обучения
                  </div>
                  <div class="subtitle" v-if="showStat">
                    {{statisticsCourse.course.teaching_language.name[$i18n.locale]}}
                  </div>
                </div>
              </div>
              <!--No Statistics-->
              <div class="tabs-info" v-if="!showStat">
                Статистика отсутствует. Проходите уроки и выполняйте задания чтобы у вас появилась статистика.
              </div>
              <!--Statistics-->
              <div class="tabs-info" v-else>
                Общая статистика по курсу “{{statisticsCourse.course.name[$i18n.locale]}}“
              </div>
            </div>
          </div>
        </div>
        <Loading  v-if="$store.getters['statistics/statLoading']"/>
        <template v-else>
          <!--No Statistics-->
        <div class="statistics-content" v-if="!showStat">
          <img src="../../../../assets/images/statistics.png" alt="">
        </div>
          <!--Statistics-->
          <div class="statistics-content" v-else>
            <div class="course-statistics">
              <div class="statistics">
                <div class="title">Cтатистика за курс</div>
                <div class="statistics-info">
                  <div class="item">
                    <p>Пройдено модулей:</p>
                    <span>{{statisticsCourse.module_point_done}}</span>
                  </div>
                  <div class="item">
                    <p>Средний бал за курс:</p>
                    <span v-if="statisticsCourse">{{statisticsCourse.course_point}}</span>
                  </div>
                </div>
              </div>
              <Progress
                :route-params="routeParams"
                query-key="number"
                id-key="module_id"
                v-if="statisticsCourse"
                :progress-data="statisticsCourse.user_modules"
                :finishModule="statisticsCourse.module_point_done"
                :statisticsCount="statisticsCourse.module_point"/>
            </div>
            <div class="completed">
              <div class="item">
                <div class="item-info">
                  <div class="title">Пройдено уроков</div>
                  <p v-if="statisticsCourse"><span>{{statisticsCourse.lessons_point_done}}</span> из {{statisticsCourse.lessons_point}}</p>
                </div>
                <router-link :to="{name: 'StatisticsLesson', query:{course_id:$route.params.id}}">
                  <img src="../../../../assets/images/icons/arrow-red.svg" alt="">
                </router-link>
              </div>
              <div class="item">
                <div class="item-info">
                  <div class="title">Выполнено заданий</div>
                  <p v-if="statisticsCourse"><span>{{statisticsCourse.task_point_done}}</span> из {{statisticsCourse.task_point}}</p>
                </div>
                <router-link :to="{name: 'StatisticsTask', query:{course_id:$route.params.id}}">
                  <img src="../../../../assets/images/icons/arrow-red.svg" alt="">
                </router-link>
              </div>
              <div class="item">
                <div class="item-info">
                  <div class="title">Пройдено тестов</div>
                  <p><span>{{statisticsCourse.tests_point_done}}</span> из {{statisticsCourse.tests_point}}</p>
                </div>
                <router-link :to="{name: 'StatisticsTest', query:{course_id:$route.params.id}}">
                  <img src="../../../../assets/images/icons/arrow-red.svg" alt="">
                </router-link>
              </div>
              <div class="item">
                <div class="item-info">
                  <div class="title">Занятий с экспертом</div>
                  <p><span>1</span> из 4</p>
                </div>
              </div>
            </div>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import Progress from './../../../components/Profile/Progress'
import { mapActions, mapState } from 'vuex'
import Loading from '../../../components/Loading'
export default {
  name: 'Statistics',
  data () {
    return {
      routeParams: {name: 'StatisticsModule', query:{}, params:{}}
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.id) {
      this.getStatisticsCourse({ course_id: to.params.id }).then(res => {})
    }
    next()
  },
  created () {
    this.getStatisticsCourse({ course_id: this.$route.params.id }).then(res => {})
  },
  computed: {
    ...mapState({
      statisticsCourse: state => state.statistics.statisticsCourse
    }),
    showStat(){
      return this.statisticsCourse.task_point_done !== 0
    },
  },
  methods: {
    ...mapActions({
      getStatisticsCourse: 'statistics/getStatisticsCourse'
    })
  },
  components: {
    Loading,
    Progress
  }
}
</script>
