import { http } from '../../../api'
import store from './../../index'

const user = {
  namespaced: true,
  state: {
    notifications: null,
    chatNotifications: null,
    unreadNotificationsCount: null,
    vocabularies: [],
    users: [],
    channel: null,
  },
  actions: {
    updateUserPersonalData: ({ commit }, params) => {
      return http.put('user', params).then(res => {
        store.commit('auth/setUser', res.data.data)
        return res
      })
    },
    updatePassword: ({ commit }, params) => {
      return http.put('user/password', params).then(res => {
        return res
      })
    },
    updateAvatar: ({ commit }, params) => {
      return http.post('user/avatar', params).then(res => {
        store.commit('auth/setUser', res.data.data)
      })
    },
    veryficationEmail: ({ commit }, params) => {
      return http.get('/verify-email', params).then(res => {
        return res
      })
    },
    getNotifications: ({ commit }, params) => {
      return http.get('/notifications', params).then(res => {
        commit('setNotifications', res.data.data)
        return res
      })
    },
    getChatNotifications: ({ commit }, params) => {
      return http.get('/chat-notifications', params).then(res => {
        commit('setChatNotifications', res.data.data)
        return res
      })
    },
    getUnreadNotificationsCount: ({ commit }, params) => {
      return http.get('/unread-notifications-count', params).then(res => {
        commit('setUnreadNotificationsCount', res.data.data)
        return res
      })
    },
    markAsReadNotifications: ({ commit }, params) => {
      return http.get('/mark-as-read-notifications', params).then(res => {
        return res
      })
    },
    deleteUserAvatar: ({ commit }) => {
      return http.delete('user/avatar').then(res => {
        store.commit('auth/setUser', res.data.data)
      })
    },
    storeVocabulary: ({ commit }, params) => {
      return http.post('/storeVocabulary', params).then(res => {
        const data = {
          type: 'push',
          data: res.data.data
        }
        commit('setVocabularies', data)
      })
    },
    destroyVocabulary: ({ commit }, params) => {
      return http.delete('/destroyVocabulary', { params }).then(res => {
        commit('removeVocabularies', params.vocabulary_id)
      })
    },
    getVocabularies: ({ commit }, params) => {
      return http.get(`/getVocabularies`, { params }).then(res => {

        const data = {
          type: 'set',
          data: res.data.data
        }
        commit('setVocabularies', data)
      })
    }
  },
  mutations: {
    setNotifications (state, data) {
      state.notifications = data
    },
    setUsers (state, data) {
      if (data.type === 'here') {
        state.users = data.data
      } else if (data.type === 'leaving') {
        state.users = state.users.filter(user => user.user.id !== data.data.user.id)
      }else {
        state.users.push(data.data)
      }
    },
    setChatNotifications (state, data) {
      state.chatNotifications = data
    },
    setUnreadNotificationsCount (state, data) {
      state.unreadNotificationsCount = data
    },
    setVocabularies (state, data) {
      if (data.type === 'push') {
        state.vocabularies.push(data.data)
      } else {
        state.vocabularies = data.data
      }
    },
    removeVocabularies (state, id) {
      state.vocabularies = state.vocabularies.filter(item => item.id !== id)
    },
    setChannel(state, channel){
      state.channel = channel
    }
  },
  getters: {
    chatNotifications: state => state.chatNotifications,
    notifications: state => state.notifications,
    unreadNotificationsCount: state => state.unreadNotificationsCount,
    vocabularies: state => state.vocabularies,
    users: state => state.users,
    channel: state => state.channel,

  }
}

export default user
