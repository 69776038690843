<template>
  <div class="course-block-container course-block-container-bottom">
    <slot />
    <slot name="footer" />

    <div id="course-buy" class="course-buy" v-if="!!course && course.price">
      <div class="course-buy__title">Стоимость курса</div>

      <div class="course-buy__block">
        <div class="content">
          <div v-if="course.discount" class="course-buy__discount-info">
            <span class="course-buy__price">{{ course.price }}</span>
            <span class="course-buy__discount">-{{ course.discount }}%</span>
            <span class="course-buy__date">
              до {{ course.discount_end_date | moment('DD MMMM YYYY') }} г.
            </span>
          </div>
          <div class="course-buy__info">
            <div class="course-buy__price-discount">
              {{ course.discount ? course.price_discount : course.price }}
              <span class="course-buy__currency">₽</span>
            </div>
            <button class="course-buy__button" type="button" @click="buyCourse()">
              Купить
            </button>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import Template from '../../../pages/Profile/TechnicalSupport/Template'

export default {
  name: 'TariffPlan',
  components: { Template },
  props: {
    course: {
      type: Object
    }
  },
  methods: {
    buyCourse() {
      if (!this.course.link_to_course) {
        return
      }
      window.open(this.course.link_to_course, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables/media';
.course-buy {
  margin-bottom: 130px;
  &__title {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
  }

  &__block {
    max-width: 900px;
    width: 100%;
    border-radius: 24px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
    padding: 40px;

    border: 2px solid #ce3d45;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    grid-gap: 16px;
  }

  &__discount-info {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    gap: 8px;
    grid-gap: 8px;
    align-items: center;
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(2, max-content);

    justify-content: space-between;
  }

  &__price {
    color: #333;
    font-size: 24px;
    text-decoration-line: line-through;
  }

  &__discount {
    display: grid;
    align-items: center;
    width: fit-content;
    padding: 0px 8px;
    height: 30px;
    color: #fff;
    border-radius: 16px;
    background: #ce3d45;
  }

  &__date {
    color: #333;
    font-size: 16px;
    white-space: nowrap;
  }

  &__price-discount {
    font-size: 44px;
    color: #ce3d45;
    text-align: center;
    font-weight: 700;
  }

  &__currency {
    color: #333;
    font-weight: normal;
  }
  &__button {
    border-radius: 32px;
    background: #ce3d45;
    border: none;
    padding: 16px 32px;

    color: #fff;
    font-weight: 600;
    font-size: 18px;

    min-width: 240px;
  }
}

@media (max-width: $media-s) {
  .course-buy {
    &__block {
      padding: 20px;
    }
    &__info {
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      gap: 8px;
      grid-gap: 8px;
    }

    &__button {
      max-width: none;
      min-width: 0;
      width: 100%;
    }
  }
}
@media (max-width: $media-xs) {
  .course-buy {
    &__discount-info {
      justify-items: center;
      grid-template-columns: 1fr;

      text-align: center;
    }
  }
}
</style>
