var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"word-item cursor-pointer",class:{
  'inactive':_vm.inactive,
  'success-word':_vm.success,
  'error-word':_vm.error,
  'selected':_vm.selected,
  'used': _vm.used,
  'dashed': _vm.dashed,
  'datted': _vm.datted,
  'vb': _vm.activeVB && !_vm.text
},on:{"click":function () { return _vm.$emit('click'); }}},[(_vm.activeVB && !_vm.text)?_c('img',{attrs:{"src":require("@/assets/images/tasks/choose.png"),"alt":"choose"}}):_vm._e(),_c('span',{class:{'invisible':_vm.invisible, 'mob-hide-text': _vm.canHideText}},[_vm._v(_vm._s(_vm.text))]),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }