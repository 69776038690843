<template>
  <b-modal id="choose-course" ref="ModalBuyingCourse">
    <template #modal-header="{ close }">
      <b-button @click="close()">
        X
      </b-button>
      <h3>Выберите способ оплаты</h3>
    </template>
    <div class="content">
      <div class="form-group">
        <ValidationObserver tag="form" ref="buyCourseForm" @submit.prevent="submitBuyCourse">
          <div>
            <label>Пакет</label>
            <div class="b-dropdown">
              <b-dropdown class="select-dropdown" :text="tariffActive.name[$i18n.locale]">
                <b-dropdown-item href="#" @click="selectTariff(tariff)"
                                 v-for="(tariff, index) in course.tariffs" :key="index">
                  {{ tariff.name[$i18n.locale] }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <span class="text-danger" v-if="courseTariffError">{{ courseTariffError }}</span>
          </div>
          <ValidationProvider v-slot="{ errors }" tag="div" class="d-flex">
            <input v-model="code" type="text" id="code" class="form-control" placeholder="Промокод на скидку"
                   aria-label="Recipient's username" aria-describedby="basic-addon2">
            <div class="input-group-append ml-2 actions align-items-center mt-0">
              <button type="button" @click="acceptCoupon">Применить</button>
            </div>
          </ValidationProvider>
          <ValidationProvider name="Реферальный бонус" class="form-group" v-slot="{classes, errors}" tag="div" rules="numeric|max_value:99|min_value:1">
            <label for="bonus">Реферальный бонус</label>
            <input id="bonus" class="form-control" :class="classes" v-model="formData.bonus"  type="text" placeholder="Реферальный бонус"/>
            <div class="invalid-feedback">{{errors[0]}}</div>
          </ValidationProvider>
          <h4 v-if="coupon">
            <span class="badge badge-primary position-relative">{{ coupon.name }}
              <i @click="() => setCoupon(null)"
                 class="fa fa-window-close cursor-pointer position-absolute close-icon"></i>
            </span>
          </h4>
          <div class="price-info" v-if="tariffPrice">
            К оплате: <s v-if="coupon">{{ tariffPrice }} руб</s> <span>{{ parseInt(price) }} руб</span>
          </div>
          <ValidationProvider v-slot="{ errors }" rules="required"
                              v-if="paymentMethodList && paymentMethodList.length && tariffPrice > 0">
            <div class="radio-container">
              <label>Способ оплаты:</label>
              <div class="form-group checkbox-group">
                <label v-for="(paymentMethod, index ) in paymentMethodList" :key="index" class="checkbox-container">
                  <input v-model="formData.payment_method_id" :value="paymentMethod.id" type="radio"
                         name="Способ оплаты"/>
                  <span class="checkbox"/>
                  <span>{{ paymentMethod.name[$i18n.locale] }}</span>
                </label>
              </div>
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          <div class="actions">
            <button type="submit" :disabled="btnDisableBuyCourseForm">{{ textButton }}</button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'ModalBuyingCourse',
  props: {
    course: {
      type: Object,
      default: {}
    },
    tariff: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      tariffActive: {
        name: {}
      },
      courseTariffError: '',
      formData: {
        course_tariff_id: '',
        payment_method_id: null
      },
      btnDisableBuyCourseForm: false,
      tariffPrice: '',
      code: ''
    }
  },
  computed: {
    ...mapState({
      paymentMethodList: state => state.paymentMethod.paymentMethodList,
    }),
    ...mapGetters({
      coupon: 'coupon/coupon'
    }),

    firstPaymentId () {
      if (this.paymentMethodList.length) {
        return this.paymentMethodList[0].id
      }
      return null
    },
    textButton () {
      if (!this.tariffPrice) return 'ПЕРЕЙТИ К ОПЛАТЕ'

      return this.tariffPrice > 0 ? 'ПЕРЕЙТИ К ОПЛАТЕ' : 'Оформить'
    },
    price () {
      if (!this.coupon) {
        if (this.tariffPrice) {
          return this.tariffPrice
        }
      }

      if (this.coupon.is_percent) {
        const difference = this.tariffPrice * this.coupon.price / 100
        return this.tariffPrice - difference
      }

      return this.tariffPrice - this.coupon.price
    }
  },
  watch: {
    course (newVal) {
      this.tariffActive = {
        name: {}
      }
      this.formData = {
        course_tariff_id: '',
        payment_method_id: ''
      }
      this.tariffPrice = ''

      if (newVal && newVal.tariffs.length) {
        this.selectTariff(newVal.tariffs[0])
        this.formData.payment_method_id = this.firstPaymentId
      }

    },
    tariff (val) {
      this.selectTariff(val)
    },
    paymentMethodList () {
      this.formData.payment_method_id = this.firstPaymentId
    }
  },
  methods: {
    ...mapActions({
      buyCourse: 'course/buyCourse',
      getBoughtCoursesList: 'course/getBoughtCoursesList',
      accept: 'coupon/accept',
    }),
    ...mapMutations({
      setCoupon: 'coupon/setCoupon'
    }),
    async acceptCoupon () {
      try {
        const params = {
          code: this.code
        }

        await this.accept(params)

        this.$toasted.success('Купон успешно применен').goAway(1500)
      } catch (e) {
        this.setCoupon(null)
        if (e.response && e.response.data) {
          const {
            data,
            status
          } = e.response

          if (status === 422 || status === 400) {
            for (const key in data.errors) {
              this.$toasted.error(data.errors[key][0]).goAway(1500)
            }
          } else {
            this.$toasted.error(data.message).goAway(1500)
          }
        } else {
          this.$toasted.error('Что-то пошло не так, повторите позже').goAway(1500)
        }
      }
    },
    selectTariff (tariff) {
      this.courseTariffError = ''
      this.tariffActive = tariff
      this.tariffPrice = tariff.pivot.price || 0
      this.formData.course_tariff_id = tariff.id
    },
    submitBuyCourse () {
      this.$refs.buyCourseForm.validate().then(success => {
        if (success) {
          this.courseTariffError = ''
          if (!this.formData.course_tariff_id) {
            this.courseTariffError = 'Выберите тариф'
            return false
          }
          this.btnDisableBuyCourseForm = true
          this.formData.course_id = this.course.id

          if (this.coupon) {
            this.formData.coupon_id = this.coupon.id
          }

          this.buyCourse(this.formData).then(res => {
            this.getBoughtCoursesList().then(res => {
            })

            if (res.type === 'free') {
              return this.$router.push({
                name: 'ProfileCourseLessons',
                params: { id: this.course.id }
              })
            } else {
              window.location.href = res.url
            }

            this.formData = {}
            this.$refs.buyCourseForm.reset()
          }).catch((e) => {
            if (e.response.status === 403) {
              if (e.response.data.message !== 'Your email address is not verified.'){
                this.$toasted.error(e.response.data.message).goAway(2500)
              }else {
                this.$toasted.error('Подтвердите почту').goAway(2500)
              }
            } else {
              this.$toasted.error('Something went wrong!').goAway(1500)
            }
          }).finally(() => {
            this.btnDisableBuyCourseForm = false
          })
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.close-icon {
  top: 1px;
  right: 1px;
  font-size: 10px;

  &:hover {
    color: red;
  }
}
</style>
