<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-8">
        <div v-if="page">
          <h3 class="text-center" v-if="page.dynamic_page">{{page.dynamic_page.title[$i18n.locale]}}</h3>
          <div class="text w-100 word-break-all" v-if="page.description">
            <div v-html="page.description[$i18n.locale]"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'DynamicPage',
  computed: {
    ...mapState({
      page: state => state.dynamicPage.page
    })
  },
  data () {
    return {
    }
  },
  mounted () {
    this.dynamicPageByKey({ key: this.$route.params.name })
  },
  methods: {
    ...mapActions({
      dynamicPageByKey: 'dynamicPage/dynamicPageByKey'
    })
  }
}
</script>
