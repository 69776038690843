<template>
  <b-modal hide-footer hide-header ref="videoModal" content-class="video-modal">
    <div class="close-modal" @click="closeVideoModal">
      <img src="../../../assets/images/icons/close.svg" alt="Close">
    </div>
    <div class="modal-block">
      <div v-if="title" class="title">{{ title }}</div>
      <div class="video">
        <div class="video" v-if="type === 'video'">
          <video
            width="100%"
            :src="video"
            controls
          >
          </video>
        </div>
        <div class="video" v-else v-html="video"></div>
      </div>
      <div v-if="description" class="video-block-videos-video-container-modal-text" v-html="description"></div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'VideoModal',
  props: {
    title: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'video'
    },
    video: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show (newVal) {
      if (newVal) {
        this.openVideoModal()
      }
    }
  },
  methods: {
    openVideoModal () {
      this.$refs.videoModal.show()
    },
    closeVideoModal () {
      this.$refs.videoModal.hide()
      this.$emit('update:show', false)
    },
  }
}
</script>

<style scoped>

</style>
