<template>
  <div class="vocabulary">
    <b-card-body>
      <table class="w-100">
        <tr class="th-title">
          <th></th>
          <th>Слова</th>
          <th>Транскрипция</th>
          <th>Перевод</th>
          <th></th>
        </tr>
        <template v-for="(column, index) in columns">
          <tr :class="`${collapse[index]?'border-0': ''}`">
            <td>
              <div class="audio-video">
                <PlayerButton color="#d3d7e5" height="40px" width="40px" class="encyclopedia-audio-btn" :answer="column.word"
                              :lang="column.language.code">
                  <template #play>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 13 10">
                      <g transform="translate(-657 -678)">
                        <g transform="translate(495.144 568.243)">
                          <g>
                            <path
                              d="M168.378,114.773c0,1.393,0,2.786,0,4.18a.676.676,0,0,1-.346.7.638.638,0,0,1-.738-.166c-.82-.7-1.649-1.377-2.469-2.072a.675.675,0,0,0-.479-.175c-.592.01-1.186.006-1.779,0-.5,0-.712-.224-.713-.742q0-1.741,0-3.483c0-.518.214-.738.714-.741.593,0,1.186-.007,1.778,0a.676.676,0,0,0,.479-.177c.827-.7,1.663-1.389,2.491-2.09a.619.619,0,0,1,.716-.146.658.658,0,0,1,.344.669C168.374,111.948,168.378,113.361,168.378,114.773Zm-1.2-3.052c-.662.553-1.278,1.062-1.887,1.579a.875.875,0,0,1-.61.227c-.481-.008-.962,0-1.443-.005-.156,0-.206.048-.2.214.008.676.009,1.353,0,2.03,0,.178.048.233.218.23.49-.01.981,0,1.471,0a.816.816,0,0,1,.558.214c.566.483,1.139.957,1.71,1.433.052.044.1.126.188.085Z"
                              fill="#fff"/>
                            <path
                              d="M174.857,114.953a6.84,6.84,0,0,1-1.716,4.458,1.86,1.86,0,0,1-.2.2.567.567,0,0,1-.785-.032.632.632,0,0,1-.059-.818c.119-.16.265-.3.389-.454a5.62,5.62,0,0,0-.223-7.366.919.919,0,0,1-.274-.427.639.639,0,0,1,.278-.674.566.566,0,0,1,.736.118,6.539,6.539,0,0,1,1.482,2.491A7.691,7.691,0,0,1,174.857,114.953Z"
                              fill="#fff"/>
                            <path
                              d="M171.893,114.763a3.786,3.786,0,0,1-.888,2.5.605.605,0,0,1-.923.143.646.646,0,0,1,.036-.966,2.435,2.435,0,0,0-.016-3.388.645.645,0,0,1-.144-.788.59.59,0,0,1,.638-.3.68.68,0,0,1,.388.261A3.79,3.79,0,0,1,171.893,114.763Z"
                              fill="#fff"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </template>
                </PlayerButton>
                <div v-if="column.video || column.iframe" class="circle ml-2"
                     @click="openVideoModal(column.video || column.iframe)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                    <g transform="translate(-880 -446)">
                      <g transform="translate(888 454)">
                        <g>
                          <path
                            d="M23,18a1,1,0,0,1-.581-.187l-7-5a1,1,0,0,1,0-1.628l7-5A1,1,0,0,1,24,7V17a1,1,0,0,1-1,1Zm-5.279-6L22,15.056V8.943Z"
                            fill="#fff"/>
                        </g>
                        <g>
                          <path
                            d="M14,20H3a3,3,0,0,1-3-3V7A3,3,0,0,1,3,4H14a3,3,0,0,1,3,3V17A3,3,0,0,1,14,20ZM3,6A1,1,0,0,0,2,7V17a1,1,0,0,0,1,1H14a1,1,0,0,0,1-1V7a1,1,0,0,0-1-1Z"
                            fill="#fff"/>
                        </g>
                      </g>
                      <path
                        d="M891,474a3,3,0,0,1-3-3V461a3,3,0,0,1,3-3h11a3,3,0,0,1,3,3v3.056l5.419-3.871A1,1,0,0,1,912,461v10a1,1,0,0,1-1.581.813L905,467.943V471a3,3,0,0,1-3,3Zm-1-13v10a1,1,0,0,0,1,1h11a1,1,0,0,0,1-1V461a1,1,0,0,0-1-1H891A1,1,0,0,0,890,461Zm15.721,5L910,469.057v-6.113Z"
                        fill="#fff"/>
                    </g>
                  </svg>
                </div>
              </div>
            </td>
            <td>
              <div class="lang-text">{{ column.word }}</div>
            </td>
            <td>
              <div class="translate-text">{{ column.transliteration }}</div>
            </td>
            <td>
              <div class="translate-text">{{ column.translate }}</div>
            </td>
            <td>
              <div class="actions d-flex justify-content-end">
                <template v-if="enableStore">
                  <button v-if="!vocabularies.find(item => item.id === column.id)" class="btn btn-sm btn-success mr-20"
                          @click="store(column.id)"><i class="fas fa-plus-circle"></i></button>
                  <svg v-else  @click="destroy(column.id)" class="mr-20" xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20">
                    <g transform="translate(-1698 -857)">
                      <g transform="translate(1364.301 675.031)">
                        <g>
                          <path
                            d="M335.609,193.57c0-1.916-.008-3.831.007-5.747,0-.324-.1-.438-.429-.4a2.877,2.877,0,0,1-.533,0,.949.949,0,0,1-.955-.932.936.936,0,0,1,.952-.89c.681-.023,1.364-.009,2.046-.01.444,0,.89-.016,1.334.009.324.017.457-.068.433-.4a3.17,3.17,0,0,1,.06-1.059,3.017,3.017,0,0,1,2.9-2.167q1.779-.009,3.559,0a2.907,2.907,0,0,1,2.962,2.844c.007.775.007.775.827.775.934,0,1.869,0,2.8,0,.678,0,1.115.356,1.129.9s-.437.927-1.133.936c-.775.01-.775.01-.775.738,0,3.6-.047,7.21.021,10.813a3.117,3.117,0,0,1-3.119,2.991q-4.492-.1-8.986,0a3.088,3.088,0,0,1-3.116-2.992C335.669,197.177,335.609,195.372,335.609,193.57Zm1.894-.06q0,2.808,0,5.616c0,.759.254,1.008,1.028,1.009q4.67,0,9.339,0c.758,0,1.022-.251,1.023-.971,0-3.787,0-7.574.007-11.361,0-.292-.074-.384-.391-.383q-5.313.015-10.628,0c-.324,0-.388.1-.386.388C337.509,189.709,337.5,191.609,337.5,193.51Zm5.7-7.917h2c.859,0,.859,0,.851-.822-.008-.742-.262-.983-1.05-.985-1.05,0-2.1,0-3.151,0-1.4,0-1.512.122-1.5,1.454,0,.273.075.366.37.36C341.542,185.581,342.371,185.593,343.2,185.593Z"
                            fill="#d3d7e5"/>
                          <path
                            d="M342.243,193.834c0,.837,0,1.675,0,2.512,0,.66-.359,1.061-.934,1.065a.943.943,0,0,1-.957-1.049q0-2.6,0-5.194a.921.921,0,0,1,.941-1.016.9.9,0,0,1,.95,1C342.249,192.046,342.243,192.94,342.243,193.834Z"
                            fill="#d3d7e5"/>
                          <path
                            d="M344.154,193.765c0-.865,0-1.731,0-2.6a.909.909,0,0,1,.934-1.016.926.926,0,0,1,.957,1.042q0,2.6,0,5.194a.937.937,0,0,1-.94,1.023.923.923,0,0,1-.951-1.006C344.149,195.525,344.154,194.645,344.154,193.765Z"
                            fill="#d3d7e5"/>
                        </g>
                      </g>
                      <path
                        d="M1711.993,877q-4.492-.1-8.986,0a3.089,3.089,0,0,1-3.116-2.992c.079-1.8.019-3.6.019-5.405,0-1.916-.009-3.831.007-5.747,0-.324-.1-.437-.428-.4a2.936,2.936,0,0,1-.534,0,.95.95,0,0,1-.955-.932.935.935,0,0,1,.952-.89c.486-.016.974-.014,1.461-.012l.586,0c.444,0,.89-.016,1.333.009.324.017.457-.068.433-.4a3.182,3.182,0,0,1,.059-1.059,3.018,3.018,0,0,1,2.9-2.167q1.779-.008,3.559,0a2.907,2.907,0,0,1,2.962,2.844c.007.775.007.775.826.775.934,0,1.869,0,2.8,0,.678,0,1.115.356,1.129.9s-.437.927-1.133.936c-.776.01-.776.01-.776.738,0,3.605-.047,7.21.022,10.813a3.12,3.12,0,0,1-3.056,2.992Zm-10.2-14.16c.013,1.9.006,3.8.006,5.7q0,2.808,0,5.616c0,.759.254,1.008,1.029,1.009q4.67,0,9.338,0c.758,0,1.022-.251,1.023-.971,0-3.786,0-7.573.007-11.361,0-.292-.075-.384-.391-.383q-5.314.016-10.629,0h0C1701.858,862.45,1701.8,862.554,1701.8,862.839Zm4.349-4.023c-1.4,0-1.512.121-1.5,1.453,0,.273.076.366.37.36.828-.018,1.657-.006,2.486-.006h2c.859,0,.859,0,.85-.822-.008-.742-.262-.983-1.05-.985q-.63,0-1.26,0Zm3.25,13.626a.922.922,0,0,1-.939-1.006c-.007-.88,0-1.76,0-2.641,0-.865,0-1.731,0-2.6a.908.908,0,0,1,.934-1.016.928.928,0,0,1,.958,1.042q0,2.6,0,5.194a.937.937,0,0,1-.94,1.023Zm-3.794,0a.944.944,0,0,1-.95-1.049q0-2.6,0-5.194a.921.921,0,0,1,.941-1.016.9.9,0,0,1,.949,1c.007.894,0,1.788,0,2.682,0,.837,0,1.675,0,2.512,0,.661-.358,1.061-.934,1.066Z"
                        fill="#d3d7e5"/>
                    </g>
                  </svg>
                </template>
                <template v-else>
                  <svg  @click="destroy(column.id)" class="mr-20" xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20">
                    <g transform="translate(-1698 -857)">
                      <g transform="translate(1364.301 675.031)">
                        <g>
                          <path
                            d="M335.609,193.57c0-1.916-.008-3.831.007-5.747,0-.324-.1-.438-.429-.4a2.877,2.877,0,0,1-.533,0,.949.949,0,0,1-.955-.932.936.936,0,0,1,.952-.89c.681-.023,1.364-.009,2.046-.01.444,0,.89-.016,1.334.009.324.017.457-.068.433-.4a3.17,3.17,0,0,1,.06-1.059,3.017,3.017,0,0,1,2.9-2.167q1.779-.009,3.559,0a2.907,2.907,0,0,1,2.962,2.844c.007.775.007.775.827.775.934,0,1.869,0,2.8,0,.678,0,1.115.356,1.129.9s-.437.927-1.133.936c-.775.01-.775.01-.775.738,0,3.6-.047,7.21.021,10.813a3.117,3.117,0,0,1-3.119,2.991q-4.492-.1-8.986,0a3.088,3.088,0,0,1-3.116-2.992C335.669,197.177,335.609,195.372,335.609,193.57Zm1.894-.06q0,2.808,0,5.616c0,.759.254,1.008,1.028,1.009q4.67,0,9.339,0c.758,0,1.022-.251,1.023-.971,0-3.787,0-7.574.007-11.361,0-.292-.074-.384-.391-.383q-5.313.015-10.628,0c-.324,0-.388.1-.386.388C337.509,189.709,337.5,191.609,337.5,193.51Zm5.7-7.917h2c.859,0,.859,0,.851-.822-.008-.742-.262-.983-1.05-.985-1.05,0-2.1,0-3.151,0-1.4,0-1.512.122-1.5,1.454,0,.273.075.366.37.36C341.542,185.581,342.371,185.593,343.2,185.593Z"
                            fill="#d3d7e5"/>
                          <path
                            d="M342.243,193.834c0,.837,0,1.675,0,2.512,0,.66-.359,1.061-.934,1.065a.943.943,0,0,1-.957-1.049q0-2.6,0-5.194a.921.921,0,0,1,.941-1.016.9.9,0,0,1,.95,1C342.249,192.046,342.243,192.94,342.243,193.834Z"
                            fill="#d3d7e5"/>
                          <path
                            d="M344.154,193.765c0-.865,0-1.731,0-2.6a.909.909,0,0,1,.934-1.016.926.926,0,0,1,.957,1.042q0,2.6,0,5.194a.937.937,0,0,1-.94,1.023.923.923,0,0,1-.951-1.006C344.149,195.525,344.154,194.645,344.154,193.765Z"
                            fill="#d3d7e5"/>
                        </g>
                      </g>
                      <path
                        d="M1711.993,877q-4.492-.1-8.986,0a3.089,3.089,0,0,1-3.116-2.992c.079-1.8.019-3.6.019-5.405,0-1.916-.009-3.831.007-5.747,0-.324-.1-.437-.428-.4a2.936,2.936,0,0,1-.534,0,.95.95,0,0,1-.955-.932.935.935,0,0,1,.952-.89c.486-.016.974-.014,1.461-.012l.586,0c.444,0,.89-.016,1.333.009.324.017.457-.068.433-.4a3.182,3.182,0,0,1,.059-1.059,3.018,3.018,0,0,1,2.9-2.167q1.779-.008,3.559,0a2.907,2.907,0,0,1,2.962,2.844c.007.775.007.775.826.775.934,0,1.869,0,2.8,0,.678,0,1.115.356,1.129.9s-.437.927-1.133.936c-.776.01-.776.01-.776.738,0,3.605-.047,7.21.022,10.813a3.12,3.12,0,0,1-3.056,2.992Zm-10.2-14.16c.013,1.9.006,3.8.006,5.7q0,2.808,0,5.616c0,.759.254,1.008,1.029,1.009q4.67,0,9.338,0c.758,0,1.022-.251,1.023-.971,0-3.786,0-7.573.007-11.361,0-.292-.075-.384-.391-.383q-5.314.016-10.629,0h0C1701.858,862.45,1701.8,862.554,1701.8,862.839Zm4.349-4.023c-1.4,0-1.512.121-1.5,1.453,0,.273.076.366.37.36.828-.018,1.657-.006,2.486-.006h2c.859,0,.859,0,.85-.822-.008-.742-.262-.983-1.05-.985q-.63,0-1.26,0Zm3.25,13.626a.922.922,0,0,1-.939-1.006c-.007-.88,0-1.76,0-2.641,0-.865,0-1.731,0-2.6a.908.908,0,0,1,.934-1.016.928.928,0,0,1,.958,1.042q0,2.6,0,5.194a.937.937,0,0,1-.94,1.023Zm-3.794,0a.944.944,0,0,1-.95-1.049q0-2.6,0-5.194a.921.921,0,0,1,.941-1.016.9.9,0,0,1,.949,1c.007.894,0,1.788,0,2.682,0,.837,0,1.675,0,2.512,0,.661-.358,1.061-.934,1.066Z"
                        fill="#d3d7e5"/>
                    </g>
                  </svg>
                </template>

                <img @click="accordian(index)" v-b-toggle="`collapse-${index}`" class="arrow-bottom"
                     src="../../assets/images/icons/arrow-bottom.svg" alt="">
              </div>
            </td>
          </tr>
          <tr>
              <td colspan="5" v-show="collapse[index]">
                <b-collapse :id="`collapse-${index}`" class="w-100" v-html="column.example">
                </b-collapse>
              </td>
          </tr>
        </template>

      </table>
    </b-card-body>
    <b-modal id="video-modal" ref="videoModal">
      <template #modal-header="{ close }">
        <b-button @click="close()">
          X
        </b-button>
      </template>
      <div class="video-modal-content">
        <div class="video">
          <div class="video" v-if="video">
            <video
              width="100%"
              :src="video"
              controls
            >
            </video>
          </div>
          <div class="video" v-if="iframe" v-html="iframe">
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import PlayerButton from './PlayerButton'

export default {
  name: 'EncyclopediaTable',
  components: { PlayerButton },
  props: {
    columns: {
      type: Array,
      default: []
    },
    vocabularies: {
      type: Array,
      default: []
    },
    store: {
      type: Function,
    },
    destroy: {
      type: Function,
    },
    enableStore: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      iframe: null,
      video: null,
      collapse: [],
    }
  },
  mounted () {
    this.columns.forEach(() => {
      this.collapse.push(false)
    })
  },
  methods: {
    openVideoModal (video, iframe) {
      this.video = video
      this.iframe = iframe
      this.$refs.videoModal.show()
    },
    accordian (index) {
      this.collapse.splice(index, 1, !this.collapse[index])

    }
  }
}
</script>

<style scoped lang="scss">
.th-title {
  border-bottom: 2px solid #cc3c44;

  th {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #cc3c44;
    padding-bottom: 16px;
  }
}

tr {
  border-bottom: 1px solid #e6eafc;

  td {
    padding: 10px;

    .audio-video {
      display: flex;

      .circle {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #d3d7e5;
        cursor: pointer;

        &:hover{
          background-color: #cb3c44;
        }
      }
      .encyclopedia-audio-btn:hover{
        background-color: #cb3c44 !important;
      }
    }

    .arrow-bottom {
      cursor: pointer;
      padding-left: 20px;
      border-left: 1px solid #e6eafc;
    }
    .mr-20{
      margin-right: 20px;
    }
  }
  .actions{
    svg {
      cursor: pointer;
      &:hover{
        path{
          fill: #cb3c44 !important;
        }
      }
    }
  }
}


</style>
