<template>
  <div class="notifications personal-details" v-if="notifications">
    <div class="tabs-title">
      Уведомления
    </div>
    <div class="tabs-info">
      {{notifications.length === 0 ? 'У вас еще нет уведомлений' : 'Уведомления'}}
    </div>
    <!-- When there is no notofication   -->
    <div class="notifications-content" v-if="notifications.length === 0">
      <img src="~@/assets/images/profile/no-notifications.svg" class="notifications-content-no-notifications" alt="">
    </div>
    <!-- When there are notifications -->
    <div class="notifications-content" v-else>
      <div class="notifications-content-item" v-for="(notification, index) in notifications" v-bind:key="index" :class="{ 'birthday': notification.data.data.type === 'birthday', 'new': notification.read_at === null}">
        <div class="date">{{ notification.created_at | moment("DD.MM.YYYY") }}</div>
        <div class="content">
          {{ $t('notifications.messages.' + notification.data.data.type) }} "{{ notification.data.data.type === 'birthday' ? '' :  notification.data.data.course.name[$i18n.locale]}}"
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Notifications',
  computed: {
    ...mapState({
      notifications: state => state.user.notifications,
      unreadNotificationsCount: state => state.unreadNotificationsCount
    })
  },
  created () {
    this.markAsReadNotifications().then((res) => {
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    ...mapActions({
      markAsReadNotifications: 'user/markAsReadNotifications'
    })
  }
}
</script>
