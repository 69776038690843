import { http } from '../../../api'

const book = {
  namespaced: true,
  state: {
    allBooksList: [],
    pagination: {}
  },
  actions: {
    getAllBooksList: ({ commit }, params) => {
      commit('loading/setLoading', true, { root: true })
      const q = new URLSearchParams(params)
      return http.get('/teaching-book?' + q.toString()).then(res => {
        commit('setAllBooksList', res.data)
      }).finally(() => {
        commit('loading/setLoading', false, { root: true })
      })
    }
  },
  mutations: {
    setAllBooksList (state, data) {
      state.allBooksList = data.data
      state.pagination = data.pagination
    }
  },
  getters: {
    allBooksList: state => state.allBooksList,
    pagination: state => state.pagination
  }
}

export default book
